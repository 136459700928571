import { toast } from 'react-toastify';
import axios from 'axios';
import { apiRouts } from '../../constants';

class UploadFiles {
  upload = async (files, fileIds, entity) => {
    try {
      // transform data into formData
      const formData = new FormData();
      formData.append('entity', entity);
      files.forEach((e) => {
        formData.append('files', e);
      });
      fileIds.forEach((e) => {
        formData.append('fileids', e);
      });
      const { data } = await axios({
        method: 'POST',
        url: apiRouts.aeroUploads + '/documents/upload/',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: formData
      });
      return data;
    } catch (err) {
      toast.error(err?.response?.data.message || err.message);
      return null;
    }
  };
}

const Service = new UploadFiles();

export default Service;
export { Service as UploadFiles };
