import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionHeader from '../../../components/sectionHeader';
import { fetchInspectionAnswer } from '../redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';
import { fetchUserProfile } from '../../../components/topbar/profile/redux/actions';
import Map from './Map';
import Panel from '../../../components/panel/Panel';
import styles from './InspectionRoute.module.scss';
import HeaderBack from '../../../components/headerBack';
import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';

class InspectionRoute extends Component {
  componentDidMount() {
    const { actionFetch, match: { params: { id } }, shiftTypes, actionFetchShiftTypes, actionFetchUserProfile, profile } = this.props;
    actionFetch(id);
    if (!profile.length) actionFetchUserProfile();
    if (!shiftTypes.length) actionFetchShiftTypes();
  }

  render() {
    const { answer, user } = this.props;
    const mapList = answer.response.path_taken;
    let icon = '';
    if (answer.icon) {
      icon = answer.icon.includes('.png') ? require(`../../../icons/inspection-icons/${answer.icon}`)
        : require(`../../../icons/inspection-icons/${answer.icon}.svg`);
    }
    return (
        <>
          <div>
            <SectionHeader icon={icon} translationID="invalid" defaultTitle={answer.inspection}/>
          </div>
          <HeaderBack
            translationID="inspections.new.prev"
            translationDefault="Back"
            backRoute={INSPECTIONS_HOME_ROUTE}
          />
          <div className={`container ${styles.container}`}>
            <Panel title="invalid" defaultTitle="Inspection Route">
              {mapList && (
                <Map
                  loglist={mapList}
                  defaultLocation={user.airport.location.coordinates}
                />
              )}
            </Panel>
          </div>
       </>
    );
  }
}
const mapStateToProps = state => ({
  answer: state.inspection.answer,
  shiftTypes: state.settings.shiftTypes,
  profile: state.auth.profile,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
// Fetch inspection
  actionFetch: (id) => {
    dispatch(fetchInspectionAnswer(id));
  },
  actionFetchUserProfile: () => {
    dispatch(fetchUserProfile());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionRoute);
