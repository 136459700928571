import { CommentMessage, PostMessageType } from "../../../types";
import {
  // DeletePostProps,
  // PostsActionType,
  // SubTopicsCollection,
  TopicsCollection
} from "../posts/types";

export enum CommentsActionType {
  hide = "hide",
  show = "show",
  setCommentsList = "setCommentsList",
  setTopicsCollection = "setCommentCollection",
  updateTopicsCollection = "updateCommentTopicsCollection",
  setNewMessage = "setNewComment",
  setEditedMessage = "setEditedCommentMessage",
  updateMessage = "updateMessage",
  deleteComment = "deleteComment",
  updateCommentData = "updateCommentData",
  setDeletedCommentID = "setDeletedCommentID"
}
export interface CommentsState {
  visible: boolean;
  messageData: CommentMessage;
  editedComment: CommentMessage;
  postId: string | null;
  commentId: string | null;
  channelId: string | null;
  commentsList: CommentMessageObj;
  topicsCollectionCount: number;
  deletedCommentID: string;
  topicsConnected: boolean;
  newComments?: {
    [x: string]: CommentMessage[];
  };
}

export type CommentMessageObj = {
  items: CommentMessage[];
  nextToken: string;
};

export interface CommentsList {
  commentsList: CommentMessageObj;
  postId: string;
}

export interface CommentsProps {
  visible: boolean;
  messageData: PostMessageType;
  postId: string | null;
  channelId: string | null;
}

export interface SetComments {
  type: CommentsActionType.show;
  payload: CommentsProps;
}

export interface HideSidebar {
  type: CommentsActionType.hide;
}

export interface CommentProps {
  data: CommentMessage;
}

export interface EditedCommentIDProps {
  editedCommentID: string;
}

export interface UpdateCommentType {
  updateComment: CommentMessage;
}

export interface DeleteComment {
  commentId: string;
}

export interface DeletedMessage {
  deletedCommentID: string;
}

export type UpdateCommentDataProps = Pick<CommentsProps, "messageData">;

export interface TopicsCollectionAction {
  type: CommentsActionType.setTopicsCollection;
  payload: TopicsCollection;
}

export interface SubTopicsCollectionAction {
  type: CommentsActionType.updateTopicsCollection;
}

export interface CommentPropsAction {
  type: CommentsActionType.setNewMessage;
  payload: CommentProps;
}

export interface SetListComments {
  type: CommentsActionType.setCommentsList;
  payload: CommentsList;
}

export interface SetEditedCommentAction {
  type: CommentsActionType.setEditedMessage;
  payload: EditedCommentIDProps;
}

export interface UpdateCommentAction {
  type: CommentsActionType.updateMessage;
  payload: UpdateCommentType;
}

export interface DeleteCommentAction {
  type: CommentsActionType.deleteComment;
  payload: DeleteComment;
}

export interface UpdateCommentDataAction {
  type: CommentsActionType.updateCommentData;
  payload: UpdateCommentDataProps;
}

export interface DeletedMessageAction {
  type: CommentsActionType.setDeletedCommentID;
  payload: DeletedMessage;
}


export type IAction =
  | SetComments
  | HideSidebar
  | SetListComments
  | SubTopicsCollectionAction
  | TopicsCollectionAction
  | CommentPropsAction
  | UpdateCommentAction
  | DeleteCommentAction
  | UpdateCommentDataAction
  | DeletedMessageAction
  | SetEditedCommentAction;

export type IActionTypes = {
  show: (dispatch: CommentsDispatch, props: CommentsProps) => any;
  hide: (dispatch: CommentsDispatch) => any;
  setCommentsList: (dispatch: CommentsDispatch, props: CommentsList) => any;
  setTopicsCollection: (
    dispatch: CommentsDispatch,
    props: TopicsCollection
  ) => any;
  setDeletedCommentID: (dispatch: CommentsDispatch, props: DeletedMessage) => any;
  updateTopicsCollection: (
    dispatch: CommentsDispatch
  ) => any;
  setNewMessage: (dispatch: CommentsDispatch, props: CommentProps) => any;
  setEditedMessage: (
    dispatch: CommentsDispatch,
    props: EditedCommentIDProps
  ) => any;
  updateMessage: (dispatch: CommentsDispatch, props: UpdateCommentType) => any;
  deleteMessage: (dispatch: CommentsDispatch, props: DeleteComment) => any;
  updateCommentData: (
    dispatch: CommentsDispatch,
    props: UpdateCommentDataProps
  ) => any;
};

export type CommentsDispatch = <P extends {}>(action: Readonly<IAction>) => any;
