import { uiRouts } from '../../../../constants';

const { channels, directMessages, irrops, documents, createNewChannel, createNewMessage } = uiRouts;

export const links = [
  { url: channels, name: 'Channels' },
  { url: directMessages, name: 'Direct Messages' },
  // { url: irrops, name: 'IRROPs' },
  // { url: documents, name: 'Documents' }
];

export const headerConfig = {
  [channels]: {
    defaultTitle: 'Channels',
    defaultText: 'Create New Channel',
    createRoute: createNewChannel
  },
  [createNewChannel]: {
    defaultTitle: 'Channels',
    defaultText: 'Create New Channel',
    createRoute: createNewChannel
  },
  [directMessages]: {
    defaultTitle: 'Direct Messages',
    defaultText: 'New Direct Message',
    createRoute: createNewMessage
  },
  [createNewMessage]: {
    defaultTitle: 'Direct Messages',
    defaultText: 'New Direct Message',
    createRoute: createNewMessage
  },
  [irrops]: {
    defaultTitle: 'IRROPs',
    defaultText: '+ New Link',
    createRoute: irrops
  },
  [documents]: {
    defaultTitle: 'Documents',
    defaultText: '+ New Document',
    createRoute: documents
  }
};
