
import React from 'react';
import ReactLoading from 'react-loading';

import { Route } from 'react-router-dom';

import Auth from '../../utils/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.getInstance().isAuthenticated() === true
      ? <Component {...props} />
      : <ReactLoading type="spikes" height="20%" width="20%" color="#000000" />
  )}
  />
);


export default PrivateRoute;
