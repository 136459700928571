import PropTypes from 'prop-types';
import { Path, withLeaflet } from 'react-leaflet';
import L from './ShapeLeaflet';

class ShapeFile extends Path {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { data, updateFitBounds, map: _map, layerContainer: _lc, ...props } = this.props;
    this.leafletElement = L.shapefile(data, props);
    this.leafletElement.once('data:loaded', updateFitBounds);
  }

  createLeafletElement() {
    return this.leafletElement;
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      updateFitBounds,
      map: _map,
      layerContainer: _lc,
      ...props
    } = this.props;

    if (this.leafletElement && prevProps.data !== data) {
      this.leafletElement.clearLayers();
      this.leafletElement.initialize(data, props);
      this.leafletElement.once('data:loaded', updateFitBounds);
    }
  }
}

ShapeFile.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export default withLeaflet(ShapeFile);
