import { CommentsState } from './types';

export const initialState = (): CommentsState => ({
  visible: false,
  messageData: null,
  editedComment: null,
  postId: null,
  commentId: null,
  commentsList: {
    nextToken: null,
    items: []
  },
  topicsCollectionCount: null,
  newComments: null,
  channelId: null,
  topicsConnected: false,
  deletedCommentID: null
});
