import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useUnmount } from 'react-use';
import styles from './dashboard.module.scss';
import { fetchSurfaces, clear } from '../../opsSettings/Map/redux/actions';

import Toolbar from './Toolbar';
import Loading from '../../../components/loading';

export default function SurfaceTable() {
  const surfaces = useSelector(state => state.map.surfaces);
  const apiStatus = useSelector(state => state.map.list_action);

  const [calls, setcalls] = useState(false);
  const apidispatch = useDispatch();
  useEffect(() => {
    const call = async () => {
      await apidispatch(fetchSurfaces(6));
      setcalls(true);
    };
    if (!surfaces.length&& calls===false) call();
  }, [surfaces, apidispatch, calls]);

  useUnmount(() => apidispatch(clear()));

  return (
    <div className={styles.surfaceTable}>
      <Loading loadingStatus={apiStatus.loading} />
      <div>
        <Toolbar
          fiter={false}
          formatedMessage="section.toolbar.S"
          Message="Surfaces with issue"
        />
      </div>
      <table className={styles.table} style={{ border: 'None' }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="Asset.field.area" defaultMessage="AREA" />
            </th>
            <th>
              <FormattedMessage id="inspections.list.table.issues" defaultMessage="ISSUES" />
            </th>
          </tr>
        </thead>
        {surfaces.length>0 && surfaces.map(a => (
          <tr>
            <td>
              {a.name}
            </td>
            <td>
              {a.issues}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}
