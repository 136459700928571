import React, { useRef, useState } from 'react';
import { Wrapper } from './ChatBox.styles';
import { MessagesList } from '../MessagesList';
import { ChatMessageForm } from '../Forms';
import { ChatTypes } from '../../enums';
import { useChanelState } from '../../state/channelsState';
import { PostMessage } from '../../components';
import { PostCommentMessageObj, ChatBoxProps } from '../../types';

export type MessageArray = {
  [key: string]: PostCommentMessageObj;
};

const ChatBox = React.memo<ChatBoxProps>(
  ({
    type,
    topicsConnected,
    deletedMessageID,
    updateLoading,
    createMsg,
    savingMessage,
    updateMessages,
    deleteMessage,
    loadMore,
    hasMore,
    messagesList,
    editedMessageId,
    editedMessage,
    newPost,
    toggleLikes
  }) => {
    const [listHeight, setListHeight] = useState<number>(1);
    const messageRef = useRef<HTMLDivElement>(null);
    const isComment = type === ChatTypes.comment;
    const {
      state: {
        posts: {
          channelData: { channelId }
        },
        comments: { messageData },
        currentUser
      }
    } = useChanelState();

    return (
      <Wrapper>
        {isComment && (
          <div ref={messageRef}>
            <PostMessage
              type={type}
              commentedPost
              messageData={messageData}
              channelId={channelId}
              currentUser={currentUser}
            />
          </div>
        )}
        <MessagesList
          isEditedMessage={!!editedMessage}
          listHeight={listHeight}
          messagesList={messagesList}
          type={type}
          hasMore={hasMore}
          loadMore={loadMore}
          newPost={newPost}
          toggleLikes={toggleLikes}
          channelId={channelId}
          currentUser={currentUser}
          deleteMessage={deleteMessage}
          headerMessageHeight={messageRef.current?.scrollHeight}
          savingMessage={savingMessage}
          updateLoading={updateLoading}
          deletedMessageID={deletedMessageID}
          editedMessageId={editedMessageId}
        />
        <ChatMessageForm
          type={type}
          setListHeight={setListHeight}
          createMessages={createMsg}
          updateMessages={updateMessages}
          editedMessage={editedMessage}
          currentUser={currentUser}
          topicsConnected={!topicsConnected}
        />
      </Wrapper>
    );
  }
);

export default ChatBox;
export { ChatBox };
