import { ApolloError } from 'apollo-client';
import { useQuery } from '../../../graphql/hooks';
import GET_USER from '../queries/getUser';
import { UserType } from '../../types';

type UserDetails = {
  getUser: UserType;
};

type UserDetailsVars = {
  userId: string;
};

type UseGetUserDetails = {
  (userId: string): { data: UserType; error: ApolloError; loading: boolean };
};

export const useGetUserDetails: UseGetUserDetails = (userId) => {
  const { data, error, loading } = useQuery<UserDetails, UserDetailsVars>(
    GET_USER,
    {
      variables: {
        userId
      }
    }
  );

  return {
    data: data?.getUser || null,
    error,
    loading
  };
};
