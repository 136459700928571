const asyncActionType = type => ({
  pending: `${type}/PENDING`,
  success: `${type}/SUCCESS`,
  error: `${type}/ERROR`
});
export const FETCH_AIRPORT_INFORMATION = asyncActionType('aerosimple/airfieldconditionreporting/FETCH_AIRPORT_INFORMATION');
export const ADD_AIRPORT_INFORMATION = asyncActionType('aerosimple/airfieldconditionreporting/ADD_AIRPORTINFORMATION');
export const UPDATE_AIRPORT_INFORMATION = asyncActionType('aerosimple/airfieldconditionreporting/UPDATE_AIRPORTINFORMATION');
export const ADD_COMMENTS= asyncActionType('aerosimple/airfieldconditionreporting/ADD_COMMENTS');
export const FETCH_COMMENTS= asyncActionType('aerosimple/airfieldconditionreporting/FETCH_COMMENTS');
export const FETCH_RUNWAY_CONDITIONS = asyncActionType('aerosimple/airfieldconditionreporting/FETCH_RUNWAY_CONDITIONS');
export const FETCH_AIRPORT_RUNWAYS = asyncActionType('aerosimple/airfieldconditionreporting/FETCH_AIRPORT_RUNWAYS');
export const UPDATE_RUNWAY_CONDITIONS = asyncActionType('aerosimple/airfieldconditionreporting/UPDATE_RUNWAY_CONDITIONS');
export const CLEAR_AIRFIELD_ACTIONS = asyncActionType('aerosimple/airfieldconditionreporting/CLEAR_AIRFIELD_ACTIONS');
export const CREATE_USEFUL_LINKS = asyncActionType('aerosimple/airfieldconditionreporting/CREATE_USEFUL_LINKS');
export const UPDATE_USEFUL_LINKS = asyncActionType('aerosimple/airfieldconditionreporting/UPDATE_USEFUL_LINKS');
export const FETCH_USEFUL_LINKS = asyncActionType('aerosimple/airfieldconditionreporting/FETCH_USEFUL_LINKS');
export const CLEAR_AIRFIELD_USEFULLINKS_ACTIONS = asyncActionType('aerosimple/airfieldconditionreporting/CLEAR_AIRFIELD_USEFULLINKS_ACTIONS');
export const DELETE_USEFUL_LINK = asyncActionType('aerosimple/airfieldconditionreporting/DELETE_USEFUL_LINK');
export const DELETE_COMMENT = asyncActionType('aerosimple/airfieldconditionreporting/DELETE_COMMENT');
