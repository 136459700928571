import React from 'react';
import { Wrapper } from './DateLine.styles';

type DateLineProps = {
  currentMsgDate: string;
  isNew?: boolean;
};

const DateLine: React.FC<DateLineProps> = ({
  currentMsgDate,
  isNew = false
}) => (
  <Wrapper>
    <span className="message-date">{currentMsgDate}</span>
    {isNew && <span className="message-new">new messages</span>}
    <hr />
  </Wrapper>
);

export default DateLine;
export { DateLine };
