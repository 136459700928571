import { connect } from 'react-redux';
import React, { Component } from 'react';
import { HOC as Permissions } from 'react-redux-permissions';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { WILD_LIFE_LOG_HOME } from '../../../constants/RouterConstants';
import Forbidden from '../../Forbidden';
import HeaderBack from '../../../components/headerBack';
import SectionHeader from '../../../components/sectionHeader';
import FixedFields from './components/FixedFields';
import Panel from '../../../components/panel/Panel';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import styles from './WildLifeCreate.module.scss';
import icon from '../../../icons/wildlife.png';
import { createLog, clearActionResult, fetchLocationGrids } from '../redux/actions';
import { searchUser, fetchsummary } from '../../inspections/redux/actions';
import { GetWildLifeInfo, GetFileName, S3Uploads } from '../../services';


class WildLifeLogCreate extends Component {
  state = {
    info: {
      incident_date: moment().format(),
      dispersal: false,
      bird_strike: false,
      spotted_count: 0,
      killed_count: 0,
      attachments: []
    },
    showFormErrors: false,
    speiceError: false,
    loading: false,
    fieldErrors: {
      logged_by: false,
      incident_date: false,
      description: true,
      location: true,
      spotted_count: false,
      killed_count: false,
      wildlife_type: true,
      wildlife_species: true,
      wildlife_activity: true
    },
    speciesInfo: []
  }

  componentDidMount = async () => {
    const { actionFetchSummary, actionFetchGrids } = this.props;
    const info = await this.getWildLifeInfo();
    actionFetchSummary();
    actionFetchGrids();
    const list = [];
    info.forEach((e) => {
      e.Species.forEach((s) => {
        list.push(
          {
            type: e.Type,
            species: s.name,
            image: s.image
          }
        );
      });
    });
    this.setState({ speciesInfo: info, loadingStatus: true, speciesList: list });
  }

  componentWillUnmount() {
    const { ClearAction } = this.props;
    ClearAction();
  }


  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.user && props.user.id && !state.info.logged_by) {
      return {
        ...state,
        info: {
          ...state.info,
          logged_by: {
            id: props.user.id,
            fullname: props.user.fullname
          },
          location: [...props.user.airport.location.coordinates]
        }
      };
    }
    if (props.LogCreateAction && props.LogCreateAction.success) {
      props.history.push(WILD_LIFE_LOG_HOME);
      return { ...state, loading: false };
    }
    return state;
  }

  handleAnswerChanged = (section, answer, fieldId) => {
    const { speiceError } = this.state;
    if (speiceError) {
      this.setState({ speiceError: false });
    }
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  handleSearchForUser = (value) => {
    const { actionSearchUser } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  create = async () => {
    const { fieldErrors, info } = this.state;
    const { actionCreate, summary } = this.props;
    let speiceError = false;
    if (info.wildlife_species === '' && !fieldErrors.wildlife_species) {
      speiceError = true;
    }
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));
    if (noErrors && !speiceError) {
      this.setState({ loading: true });
      const data = { ...info };
      data.logged_by = data.logged_by && data.logged_by.id;
      const weather = {
        summary: summary && summary.weather && summary.weather.summary,
        temperature: summary && summary.weather && summary.weather.temperature
      };
      data.weather_conditions = JSON.stringify(weather);
      const location = {
        type: 'LineString',
        coordinates: data.location
      };
      data.location = JSON.stringify(location);
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k !== 'attachments') {
          formData.append(k, data[k]);
        }
      });
      let fileIds = '';
      if (data.attachments.length > 0) {
        fileIds = await this.getFileNames(data.attachments.length);
      }
      if (fileIds) {
        await this.doS3Upload(info.attachments, fileIds, 'wildlife_log')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });
            actionCreate(formData);
          });
      } else {
        actionCreate(formData);
      }
    } else {
      this.setState({ showFormErrors: true });
      if (speiceError) this.setState({ speiceError });
    }
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  getWildLifeInfo = async () => {
    const response = await GetWildLifeInfo.getWildLifeInfo();
    return response;
  };

  handleZoomLevel = (value) => {
    this.setState({ zoomLevel: value });
  }

  render() {
    const { info, showFormErrors, speciesInfo, speiceError, loading, speciesList } = this.state;
    const { userlist, summary, gridList } = this.props;
    const loadingStatus = true;
    return (
      <>
        <SectionHeader icon={icon} translationID="shortcuts.wildlife.FAAStrikeReport"
          defaultTitle="FAA Strike Report"
        />
        {(!summary || loading) && <Loading loadingStatus={loadingStatus} />}
        <HeaderBack
          translationID="workorder.settings.back"
          translationDefault="Back"
          backRoute={WILD_LIFE_LOG_HOME}
        />
        <div className={`container ${styles.form}`}>
          <Panel title="wildlife.form.title" defaultTitle="Wildlife activity & Patrol report">
            <div className={`${styles.content} ${styles.embedded}`}>
              {summary && gridList && (
              <FixedFields
                info={info}
                userlist={userlist}
                weather={summary && summary.weather}
                searchForUser={this.handleSearchForUser}
                handleAnswerChanged={this.handleAnswerChanged}
                handleFieldErrorChanged={this.handleFieldErrorChanged}
                handleZoomLevel={this.handleZoomLevel}
                showFormErrors={showFormErrors}
                speciesInfo={speciesInfo}
                speciesList={speciesList}
                speiceError={speiceError}
                gridList={gridList}
              />
              )}
            </div>
            <div className={`${styles.footer} ${styles.embedded}`}>
              <Button onClick={this.create} translationID="wildlife.form.submit"
                defaultText="Submit" action="secondary"
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

WildLifeLogCreate.propTypes = {
  actionSearchUser: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  actionCreate: PropTypes.func.isRequired,
  actionFetchGrids: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  LogCreateAction: PropTypes.shape({})
};

WildLifeLogCreate.defaultProps = {
  userlist: [],
  LogCreateAction: {}
};

const mapStateToProps = state => ({
  user: state.auth.profile,
  userlist: state.inspection.userlist,
  LogCreateAction: state.wildlife_log.LogCreateAction,
  summary: state.inspection.summary,
  gridList: state.wildlife_log.gridList
});

const mapDispatchToProps = dispatch => ({
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionCreate: (data) => {
    dispatch(createLog(data));
  },
  ClearAction: () => {
    dispatch(clearActionResult());
  },
  actionFetchSummary: () => {
    dispatch(fetchsummary());
  },
  actionFetchGrids: () => {
    dispatch(fetchLocationGrids());
  }
});


export default Permissions(['add_wildlifelogs'])(
  connect(mapStateToProps, mapDispatchToProps)(WildLifeLogCreate),
  <Forbidden />
);
