import styled from 'styled-components';

type WrapperTypes = {
  width?: number;
  borderRadius?: number;
}

export const Wrapper = styled.span<WrapperTypes>`
  .ui.primary.button {
    width: ${({ width }) => `${width}px` || '130px'};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '16.5px')};
    background-color: ${({ theme }) => theme.colors.background.blue};

    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.09px;
    text-align: center;
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;
