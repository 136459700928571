import React from 'react';
import { Image } from 'semantic-ui-react';
import UserImg from '../../icons/default_avatar.jpg';
import { Wrapper } from './Avatar.styles';

type AvatarProps = {
  src?: string;
  isActive?: boolean;
  size?: "small" | "standard";
  name?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  size = 'standard',
  isActive = false,
  name
}) => (
  <Wrapper size={size}>
    {isActive && <span className="active" />}
    <Image src={src?.trim() || UserImg} avatar inline title={name} />
  </Wrapper>
);

export default Avatar;
export { Avatar };
