import { SubscribeToMoreOptions } from 'apollo-client';
import { useCallback } from 'react';
import { useQuery } from '../../../graphql/hooks';
import { MessageArray } from '../../containers/ChatBox/ChatBox';
import { ChatTypes } from '../../enums';
import { SetDirectMessageListProps } from '../../state/directMessagesState/directMessages/types';
import GET_MESSAGES from '../queries/getMessages';
import { DirectMessageArrType, FetchMoreFunc } from '../../types';
import { fields } from './assets';

type Messages = {
  getMessages: DirectMessageArrType;
};

type MessagesVars = {
  roomId: string;
  limit: number;
  nextToken: string;
};
type UseGetMessagesArgs = {
  roomId: string;
  setListAction: (directMessageList: SetDirectMessageListProps) => any;
  type?: ChatTypes.directMessages;
};

type UseGetMessagesReturn<TSubsData, TSubsVars> = {
  subscribeToMore: <TSubsData, TSubsVars>(
    options: SubscribeToMoreOptions<Messages, TSubsVars, TSubsData>
  ) => () => void;
  // TODO fix fetchMore type
  fetchMore: FetchMoreFunc;
  loading: boolean;
};
interface UseGetMessages<TSubsData, TSubsVars> {
  (args: UseGetMessagesArgs): UseGetMessagesReturn<TSubsData, TSubsVars>;
}

export const useGetMessages: UseGetMessages<Messages, MessagesVars> = ({
  roomId,
  setListAction,
  type = ChatTypes.directMessages
}) => {
  const {
    loading,
    subscribeToMore,
    fetchMore: getMore
  } = useQuery<Messages, MessagesVars>(GET_MESSAGES, {
    variables: {
      roomId,
      limit: 10,
      nextToken: null
    },
    onCompleted: (messageArray: Messages) => {
      if (messageArray) {
        const {
          getMessages: { items, nextToken }
        } = messageArray;
        setListAction({
          directMessageList: {
            nextToken,
            items: [...items].reverse()
          }
        });
      }
    }
  });

  const fetchMore = useCallback(
    nextToken => getMore({
      variables: {
        nextToken
      },
      updateQuery: (prev: MessageArray, { fetchMoreResult }) => {
        if (!fetchMoreResult || !prev[fields[type].get].nextToken) return prev;

        return {
          ...prev,
          [fields[type].get]: {
            items: [
              ...prev[fields[type].get].items,
              ...fetchMoreResult[fields[type].get].items
            ],
            nextToken: fetchMoreResult[fields[type].get].nextToken
          }
        };
      }
    }).catch((e) => {
      console.error(e);
    }),
    [getMore, type]
  );

  return {
    subscribeToMore,
    fetchMore,
    loading
  };
};
