import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { fetchComments, clear, deleteComment } from '../../redux/action';
import Loading from '../../../../components/loading';
import Modal from '../../../../components/modal';
import styles from './comment.module.scss';
import { ReactComponent as Bin } from '../../../../icons/bin.svg';

const Comments = ({ actionFetchComments, comments, actionClear, commentAction, actionDelete }) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  useEffect(() => {
    actionFetchComments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (commentAction.loading === false && loading === true) {
      setLoading(false);
      actionFetchComments();
      actionClear();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentAction]);

  const remove = (id) => {
    setLoading(true);
    actionDelete(id);
    setConfirmModal(false);
  };

  const toggleConfirmDelete = () => {
    setConfirmModal(!confirmModal);
    setSelectedId(undefined);
  };

  const openConfirmModal = (id) => {
    setConfirmModal(true);
    setSelectedId(id);
  };

  return (
    <Fragment>
      <Loading status={loading} />
      <Col lg={12}>
        <Card className={styles.Card}>
          <CardTitle className={`${styles.commentsCardTitle}`}>
            <Col lg={12}>
              <Row>
                <Col className={styles.commentsText}>
                  <FormattedMessage
                    id="airfields.comments"
                    defaultText="Comments"
                  />
                </Col>
              </Row>
            </Col>
          </CardTitle>
          <hr className={styles.hrLine} />
          <CardBody className={styles.cardBody}>
            <Col lg={12} xs={9} className={styles.commentsCard}>
              {comments && comments.length
                ? comments.map(commt => (
                  <>
                    <div className={styles.comments}>
                      <div className={styles.commentList}>
                        <span className={styles.commentName}>{`${commt.first_name} ${commt.last_name}`}</span>
                        <span>
                          <span className={styles.commentTime}>{moment(commt.created_date).format('MM/DD/YY\xa0\xa0\xa0hh:MM A')}</span>
                          <span className={styles.deleteIcon}>
                            <Bin height="18px"
                              opacity="0.4"
                              onClick={() => openConfirmModal(commt.id)}
                            />
                          </span>
                        </span>
                      </div>
                      <div className={styles.commentData}>{commt.comments}</div>
                    </div>
                  </>
                ))
                : (
                  <div className={styles.noCommentsText}>
                    No Comments Available
                  </div>
                )}
            </Col>
          </CardBody>
        </Card>
        <Modal
          width="500px"
          minHeight="115px"
          showIn={confirmModal}
          centered
          onClose={toggleConfirmDelete}
        >
          <h3 style={{ padding: '15px 30px 5px 40px' }}>
            <FormattedMessage
              id="airfields.comments.removeComment"
              defaultText="Are you sure you want to delete this comment ?"
            />
          </h3>
          <hr className={styles.hrLine} />
          <div className={styles.footer}>
            <button type="button" onClick={() => toggleConfirmDelete()} className={`text-center ${styles.closedbutton}`}>Cancel</button>
            <button type="button" onClick={() => remove(selectedId)} className={`float-right ${styles.savebutton}`}>Delete</button>
          </div>
        </Modal>
      </Col>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  // Fetch Comments
  actionFetchComments: () => {
    dispatch(fetchComments());
  },

  //  Delete Comment
  actionDelete: (id) => {
    dispatch(deleteComment(id));
  },

  // Clear Redux Actions
  actionClear: () => {
    dispatch(clear());
  }
});

const mapStateToProps = state => ({
  comments: state.airfieldcondition.comments,
  commentAction: state.airfieldcondition.commentsAction
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Comments));
