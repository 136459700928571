import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchSafetySelfInspection } from '../../../inspections/redux/actions';
import Button from '../../../../components/button';
import styles from '../../../../components/filters/filteritem.module.scss';
import Clickable from '../../../../components/clickable/Clickable';
import Collapsible from '../../../../components/collapsible/Collapsible';
import FilterItem from '../../../../components/filters/FilterItem';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [
        { id: 1,
          name: 'Status',
          permissions: [{ id: 1, title: 'Maintenance' },
            { id: 2, title: 'Operations' },
            { id: 3, title: 'Completed' }]
        },
        { id: 2,
          name: 'Categories',
          permissions: []
        },
        {
          id: 3,
          name: 'Shifts',
          permissions: []
        }
      ],
      filterCategories: undefined,
      filterShifts: props.filterShifts
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.filterCategories === undefined && props.filterCategories) {
      return { ...state, filterCategories: [...props.filterCategories] };
    }
    return state;
  }

  componentDidMount() {
    const { selfInspection, schemas, actionGetSelfInpection, shifts } = this.props;

    if (Object.keys(schemas).length>0) {
      if (schemas.operations.disabled===true) {
        this.setState({
          filters: [
            { id: 1,
              name: 'Status',
              permissions: [{ id: 1, title: 'Maintenance' },
                { id: 3, title: 'Completed' }]
            },
            { id: 2,
              name: 'Categories',
              permissions: []
            },
            {
              id: 3,
              name: 'Shifts',
              permissions: []
            }
          ]
        });
      }
    }
    if (Object.keys(selfInspection).length === 0) {
      actionGetSelfInpection();
    } else {
      // Below condition executed when the prop values are initially loaded;
      const filterCategoryData = [];
      Object.keys(selfInspection).forEach((k) => {
        filterCategoryData.push({ id: k, title: selfInspection[k].title });
      });
      this.setState(prevState => ({
        filters: prevState.filters.map(
          obj => (obj.id === 2 ? Object.assign(obj, { permissions: filterCategoryData }) : obj)
        )
      }));
    }
    const filterShiftsData = [];
    Object.keys(shifts).forEach((k) => {
      filterShiftsData.push({ id: shifts[k].id, title: shifts[k].name, type: 'shifts' });
    });
    this.setState(prevState => ({
      filters: prevState.filters.map(
        obj => (obj.id === 3 ? Object.assign(obj, { permissions: filterShiftsData }) : obj)
      )
    }));
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { selfInspection } = this.props;
    if (prevProps.selfInspection !== selfInspection) {
      const filterCategoryData = [];
      Object.keys(selfInspection).forEach((k) => {
        filterCategoryData.push({ id: k, title: selfInspection[k].title });
      });
      this.setState(prevState => ({
        filters: prevState.filters.map(
          obj => (obj.id === 2 ? Object.assign(obj, { permissions: filterCategoryData }) : obj)
        )
      }));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { handleOutsideClickFilter } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      handleOutsideClickFilter();
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleFilterChange = (name, event) => {
    const { filterCategories, filterShifts } = this.state;
    const check = event.target.checked;
    const checkedValue = event.target.id;
    if (check) {
      if (name!=='Shifts') {
        this.setState({
          filterCategories: [...filterCategories, checkedValue]
        });
      }
      if (name==='Shifts') {
        if (filterShifts.indexOf(checkedValue)===-1) {
          this.setState({
            filterShifts: [...filterShifts, checkedValue]
          });
        }
      }
    } else {
      let index = filterCategories.indexOf(checkedValue);
      if (index > -1) {
        filterCategories.splice(index, 1);
        this.setState({ filterCategories });
      }
      if (filterShifts.indexOf(checkedValue)>-1) {
        if (name==='Shifts') {
          index = filterShifts.indexOf(checkedValue);
          filterShifts.splice(index, 1);
          this.setState({ filterShifts });
        }
      }
    }
  }

  clear = () => {
    this.setState({ filterCategories: [], filterShifts: [] });
  }

  render() {
    const { update, closePopUp } = this.props;
    const { filters, filterCategories, filterShifts } = this.state;
    return (
      <>
        <div className={styles.popup} ref={this.setWrapperRef}>
          <div className={styles.header}>
            <span className={styles.filterText}>Filters</span>
            <Clickable onClick={this.clear} className={styles.cancel} style={{ marginTop: '20px', marginLeft: '70px' }}>
              <FormattedMessage id="filters.form.clear" defaultMessage=" Clear" />
            </Clickable>
            <Clickable
              onClick={closePopUp}
              className={styles.cancel}
              style={{ marginTop: '20px' }}
            >
              <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
            </Clickable>
            <Button
              action="secondary"
              translationID="workorder.filter.apply"
              defaultText="Apply"
              onClick={() => update(undefined, filterCategories, filterShifts)}
            />
          </div>
          <div className={styles.list}>
            {filters.map(e => (
              <div key={e.id}>
                <Collapsible
                  title={e.name}
                  styleClasses={styles.rol}
                  autoheight={false}
                  openOnMount={e.name === 'Status'}
                >
                  <FilterItem
                    id={e.id}
                    filter={e}
                    handleChange={(id, targetId) => this.handleFilterChange(id, targetId)}
                    filterCategories={[...filterCategories, ...filterShifts]}
                  />
                </Collapsible>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

FilterForm.propTypes = {
  selfInspection: PropTypes.shape({})
};

FilterForm.defaultProps = {
  selfInspection: {}
};

const mapStateToProps = state => ({
  selfInspection: state.inspection.selfInspection
});

const mapDispatchToProps = dispatch => ({
  // Fetch Safety Self Inspection List
  actionGetSelfInpection: (id) => {
    dispatch(fetchSafetySelfInspection(id));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
