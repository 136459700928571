import gql from 'graphql-tag';

const LIST_CHATS = gql`
  query listChats($userId: String!, $limit: Int!, $airportId: String!, $nextToken: String) {
    listChats(userId: $userId, limit: $limit, airportId: $airportId, nextToken: $nextToken) {
      items {
        roomId
        fromUserId
        toUserId
        updatedAt
        airportId
        fromUser {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId
          airportId
        }
        toUser {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId
          airportId
        }
      }
      nextToken
    }
  }
`;
export default LIST_CHATS;
