import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import PulpoField from '../../../../../../../pulpo_visualizer/fields';
import Clickable from '../../../../../../../components/clickable/Clickable';
import FormattedMessageWithClass from '../../../../../../../components/formattedMessageWithClass';
import styles from '../../steps.module.scss';

const Documents = ({
  handleAnswerChanged,
  info,
  intl
}) => {
  const handleDrop = (dropped) => {
    const res = info.attachments || [];
    handleAnswerChanged([...res, ...dropped], 'attachments');
  };

  const handleRemoveImage = (r) => {
    const res = info.attachments.filter(e => e !== r);
    handleAnswerChanged(res, 'attachments');
    const removedAttachments = info.removedAttachments || '';
    handleAnswerChanged([r.id, ...removedAttachments], 'removedAttachments');
  };
  return (
   <>
     <div className={styles.stepTwo}>
       <div className={styles.displayFlex}>
         <PulpoField key="document_type" id="document_type" type="select"
           transationID="lease.tenants.steps.step3.document_type"
           title="Document Type"
           handleValueChange={(a, b) => handleAnswerChanged(a, b)}
           answer={info && info.document_type}
           handleFieldErrorChanged={() => {}}
           values={[
             { key: 'Certificate', value: intl.formatMessage({ id: 'lease.tenants.document_type_1' }) },
             { key: 'Exhibits', value: intl.formatMessage({ id: 'lease.tenants.document_type_2' }) },
             { key: 'Insurance', value: intl.formatMessage({ id: 'lease.tenants.document_type_3' }) },
             { key: 'Other', value: intl.formatMessage({ id: 'lease.tenants.document_type_4' }) }
           ]}
         />
         <PulpoField
           key="valid_date" id="valid_date" type="datetime"
           translationID="lease.tenants.steps.step3.valid_date"
           title="Valid Until" noDefault className={styles.datetime}
           handleValueChange={(a, b) => handleAnswerChanged(a, b)}
           answer={info && info.valid_date}
           handleFieldErrorChanged={() => {}}
         />
       </div>
       <div className={styles.displayFlex}>
         <PulpoField
           key="description" id="description" type="string"
           widget={{ type: 'textarea' }}
           translationID="operations.create.description"
           title="Description"
           handleValueChange={(a, b) => handleAnswerChanged(a, b)}
           answer={info && info.description}
           handleFieldErrorChanged={() => {}}
         />
         <div className={styles.halfInput}>
           <FormattedMessageWithClass
             className={styles.label} id="lease.tenants.steps.step3.attachment"
             defaultMessage="Attachments"
           />
           <Dropzone
             onDrop={handleDrop}
             disableClick
             className={styles.dropzone} accept="image/*, .pdf, .doc"
           >
             {({ open }) => (
              <>
                <p className={styles.browsetext}>
                  <FormattedMessage id="airport.logo.text1" defaultMessage="Drag an image here or" />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button" defaultMessage="browse" />
                  </button>
                  <FormattedMessage id="airport.logo.text2" defaultMessage="for an image to upload." />
                </p>
              </>
             )}
           </Dropzone>
         </div>
       </div>
       <div className={styles.fullInput}>
         {info && info.attachments && info.attachments.length > 0 && (
         <div className={styles.photos}>
           {info.attachments.map(e => (
             <div key={e.name} className={styles.wrapper}>
               <Clickable onClick={() => handleRemoveImage(e)}>&times;</Clickable>
               {(e.type || e.content_type).includes('image') ? (
                 <img src={e.preview || e.url} alt={e.name || e.file_name} />
               ) : <p>{e.name || e.file_name}</p>
             }
             </div>
           ))}
         </div>
         )}
       </div>
       <div className={styles.endingBorder} />
     </div>
    </>
  );
};

export default injectIntl(Documents);
