import React from 'react';
import Spinner from '../../../../components/spinner';
import { Wrapper } from './Loader.styles';

type LoaderProps = {};

const Loader: React.FC<LoaderProps> = () => (
  <Wrapper>
    <Spinner active />
  </Wrapper>
);

export default Loader;
export { Loader };
