import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import styles from './loading.module.scss';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      large: false
    };
  }

  toggleLarge = () => {
    const { large } = this.state;
    this.setState({ large: !large });
  }

  render() {
    const { loadingStatus, className, zindex } = this.props;
    return (
      <div>
        <Modal
          isOpen={loadingStatus}
          toggle={this.toggleLarge}
          zIndex={zindex || '9999'}
          centered
          className={'modal-lg ' + className + ' loading-model'}
        >
          <div className={styles.loader}>
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
            <span className={styles.loaderItem} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Loading;

Loading.defaultProps = {
  loadingStatus: false
};
