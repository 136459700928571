import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

class NumberFormats extends Component {
  constructor(props) {
    super(props);
    const { isRequired, updateFieldErrors, answer } = this.props;

    if (isRequired && (answer === '' || answer === undefined)) {
      updateFieldErrors(['pulpoforms.errors.not_blank']);
    }
  }

  handleAnswerChanged(value, fieldId) {
    const {
      handleValueChange, handleFieldErrorChanged, isRequired, updateFieldErrors
    } = this.props;
    handleValueChange(value.value, fieldId);
    let updatedErrors = [];

    if (value === '' || value === undefined) {
      handleFieldErrorChanged(fieldId, isRequired);
      updatedErrors = [
        ...updatedErrors,
        'pulpoforms.errors.not_blank'
      ];
    } else {
      handleFieldErrorChanged(fieldId, false);
    }
    updateFieldErrors(updatedErrors);
  }

  render() {
    const { fieldId, answer, placeHolderValue, thousandSeparator, prefix, suffix, format } = this.props;
    return (
      <NumberFormat
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        suffix={suffix}
        displayType="input"
        name={`format-field-${fieldId}`}
        onValueChange={e => this.handleAnswerChanged(e, fieldId)}
        value={answer}
        type="text"
        placeHolder={placeHolderValue}
        format={format}
      />
    );
  }
}


NumberFormats.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  answer: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  updateFieldErrors: PropTypes.func.isRequired
};

NumberFormats.defaultProps = {
  answer: ''
};

export default NumberFormats;
