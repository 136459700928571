import { DirectMessagesState, IAction, DMType } from './types';

export const reducer = (
  state: DirectMessagesState,
  action: IAction
): DirectMessagesState => {
  switch (action.type) {
    case DMType.setActiveChat: {
      const { listChats } = state;
      const {
        payload: { listChats: newListChats, chatId, chatName }
      } = action;

      const [currentChat] = (listChats || newListChats)?.items.filter(
          ({ roomId }) => chatId === roomId
        ) ?? [];

      if (newListChats) {
        return {
          ...state,
          activeChat: { chatId, chatName },
          listChats: newListChats,
          fromUser: currentChat?.fromUser
        };
      }

      return {
        ...state,
        activeChat: { chatId, chatName },
        fromUser: currentChat?.fromUser,
        loading: true
      };
    }
    case DMType.setDirectMessageList: {
      const {
        payload: { directMessageList }
      } = action;

      return {
        ...state,
        directMessageList,
        loading: false
      };
    }
    case DMType.setTopicsCollection: {
      const {
        payload: { topicsCollectionCount }
      } = action;

      return {
        ...state,
        topicsCollectionCount
      };
    }
    case DMType.updateTopicsCollection: {
      const { topicsCollectionCount } = state;

      const waitForConnection = topicsCollectionCount - 1;
      return {
        ...state,
        topicsCollectionCount: topicsCollectionCount - 1,
        topicsConnected: waitForConnection === 0
      };
    }
    case DMType.setNewMessage: {
      const {
        newDirectMessages,
        directMessageList: { items, nextToken }
      } = state;
      const {
        payload: { data }
      } = action;
      const { messageId } = data;

      return {
        ...state,
        [messageId]: [...(newDirectMessages?.[messageId] || []), data],
        directMessageList: {
          items: [...items, data],
          nextToken
        }
      };
    }
    case DMType.deleteMessage: {
      const {
        directMessageList: { items, nextToken }
      } = state;
      const {
        payload: { messageId }
      } = action;

      return {
        ...state,
        directMessageList: {
          nextToken,
          items: items.filter(item => item.messageId !== messageId)
        }
      };
    }
    case DMType.setEditedDM: {
      const {
        directMessageList: { items }
      } = state;
      const {
        payload: { editedDMID }
      } = action;
      const [editedDM] = items.filter(
        ({ messageId }) => messageId === editedDMID
      );
      return {
        ...state,
        editedDMID,
        editedDM
      };
    }
    default: {
      return state;
    }
  }
};
