import React from 'react';
import { FormattedMessage } from 'react-intl';
import PulpoField from '../../../../../../pulpo_visualizer/fields';
import styles from '../steps.module.scss';


const Step1 = ({
  handleFieldErrorChanged,
  showFormErrors,
  handleAnswerChanged,
  info,
  allTypes
}) => (
  <div className={styles.stepTwo}>
    <div className={styles.lease}>
      <FormattedMessage id="lease.tenants.steps.step1.title" defaultMessage="Tenant Information" />
    </div>
    <PulpoField
      key="name" id="name" type="string"
      translationID="tenants.new.name" className={styles.fullInput}
      title="Name" isRequired
      handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
      answer={info.name}
      showFieldErrors={showFormErrors}
      handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
    />
    <PulpoField key="alias" id="alias" type="string"
      translationID="tenants.new.alias" className={styles.fullInput}
      title="Alias"
      handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
      answer={info.alias}
      showFieldErrors={showFormErrors}
      handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
    />
    <div style={{ display: 'flex' }}>
      <PulpoField key="address" id="address" type="address"
        translationID="tenants.new.address"
        title="Address" isRequired
        handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
        answer={info.address}
        showFieldErrors={showFormErrors}
        handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
      />
      <PulpoField key="phone" id="phone" type="currency-phone"
        translationID="tenants.new.phone"
        title="Phone" isRequired format="+1 (###) ###-####"
        handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
        answer={info.phone}
        showFieldErrors={showFormErrors}
        handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
      />
    </div>
    <PulpoField key="business_type" id="business_type" type="select"
      transationID="tenants.new.businesstype" className={styles.fullInput}
      title="Business Type" isRequired
      handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
      answer={info.business_type}
      showFieldErrors={showFormErrors}
      handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
      values={allTypes ? allTypes.business_types.map(e => (
        ({ key: e.name, value: e.name })
      )): []}
    />
    <PulpoField key="category" id="category" type="select"
      translationID="tenants.new.category" className={styles.fullInput}
      title="Category" isRequired
      handleValueChange={(a, b) => handleAnswerChanged('answers', a, b)}
      answer={info.category}
      showFieldErrors={showFormErrors}
      handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('answerErrors', a, b)}
      values={allTypes ? allTypes.category_types.map(e => (
        ({ key: e.name, value: e.name })
      )): []}
    />
  </div>
);

Step1.defaultProps= {
  info: []
};

export default Step1;
