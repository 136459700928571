import React, { Component } from 'react';
import RecorderJS from 'recorder-js';

import { getAudioStream, exportBuffer } from './audio';
import Mic from '../../icons/mic.png';

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      recording: false,
      recorder: null
    };
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
  }

  async componentDidMount() {
    let stream;
    try {
      stream = await getAudioStream();
    } catch (error) {
      // Users browser doesn't support audio.
      // Add your handler here.
      console.log(error);
    }

    this.setState({ stream });
    this.botName = `${process.env.REACT_APP_PREFIX}Aerobot`;
  }

  startRecord() {

    const { stream } = this.state;
    const { changeSearchTextFieldReadOnlyStatus } = this.props;
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    if (stream === undefined) {
      console.log("Sream not set exiting");
      return;
    }
    const recorder = new RecorderJS(audioContext);
    recorder.init(stream);

    this.setState(
      {
        recorder,
        recording: true
      },
      () => {
        recorder.start();
      }
    );
    changeSearchTextFieldReadOnlyStatus(true);
  }

  async stopRecord() {
    const { recorder } = this.state;
    const { aeroBotRes } = this.state;
    const { buffer } = await recorder.stop();
    const audio = exportBuffer(buffer[0]);

    const params = {
      botAlias: '$LATEST',
      botName: this.botName,
      contentType: 'audio/x-l16; sample-rate=16000',
      accept: 'audio/mpeg',
      inputStream: audio
    };
    this.setState({
      recording: false
    });
    params.inputStream = audio;
    aeroBotRes(params, 'voice');
  }

  render() {
    const { recording } = this.state;
    // Don't show record button if their browser doesn't support it.
    // if (!stream) {
    //   return null;
    // }

    return (
      <div>
        {recording
          ? <img src={Mic} style={{ position: 'absolute', top: '587px', height: '25px', cursor: 'pointer', right: '9%'}} onClick={() => { this.stopRecord(); }} alt="" />
          : <img src={Mic} style={{ position: 'absolute', top: '587px', height: '25px', cursor: 'pointer', right: '9%'}} onClick={() => { this.startRecord(); }} alt="" />
        }
      </div>
    );
  }
}
export default Recorder;
