import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from '../../../../styles/general.module.scss';
import { INSPECTIONS_HOME_ROUTE, WORKORDERS_HOME_ROUTE } from '../../../../constants/RouterConstants'; 
import Clickable from '../../../../components/clickable/Clickable';

const LogTable = ({ onSelect, onSort, data, filterGroupBy }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (filterGroupBy.key === 1) {
      const element = document.getElementById('table-0');
      if (element !== null) {
        if (window.getComputedStyle(element, null).display === 'none') {
          element.style.display = '';
        }
      }
    }
    setTableData(data);
  }, [data, filterGroupBy]);

  const getLinkForSource = (source) => {
    let result = '';
    if (source) {
      switch (source.type) {
        case 'inspectionanswer':
          result = `${INSPECTIONS_HOME_ROUTE}${source.id}`;
          break;
        case 'Workorder':
          result = `${WORKORDERS_HOME_ROUTE}${source.workorder_id}/detail`;
          break;
        default:
          break;
      }
    }
    return result;
  };

  const hideSection = (value) => {
    const element = document.getElementById(value);
    if (window.getComputedStyle(element, null).display === 'none') {
      element.style.display = '';
    } else {
      element.style.display = 'none';
    }
  };

  return (
    <table className={`${styles.table} ${styles.evenWidth}`}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="operations.loglist.date" defaultMessage="Date" />
          </th>
          <th>
            <FormattedMessage id="operations.loglist.loggedby" defaultMessage="Logged By" />
          </th>
          <th>
            <FormattedMessage id="operations.loglist.category" defaultMessage="Type" />
            <button style={{ left: '9px', position: 'relative', bottom: '-12px' }}
              type="button" className={`${styles.hideArrow}`} onClick={() => onSort()}
            />
          </th>
          <th>
            <FormattedMessage id="operations.loglist.subcategory" defaultMessage="Sub Type" />
          </th>
          <th>
            <FormattedMessage id="operations.loglist.desc" defaultMessage="Description" />
          </th>
          <th width="80">
            <FormattedMessage id="operations.loglist.source" defaultMessage="Source" />
          </th>
          <th width="90" />
        </tr>
      </thead>
      {
        Object.keys(tableData).map(k => (
          <React.Fragment key={k}>
            <thead className={styles.sectionHeader}>
              <tr onClick={() => hideSection(`table-${k}`)}
                onKeyPress={() => hideSection(`table-${k}`)}
              >
                {tableData[k].entries.length >0 && (
                  <th colSpan="7">
                    <div className={styles.label}>
                      {tableData[k].label}
                    </div>
                  </th>
                ) }
              </tr>
            </thead>
            {tableData[k].entries && (
            <tbody id={`table-${k}`}>
                {tableData[k].entries.map(e => (
                  <tr key={e.id}>
                    <td className={styles.date}>{(moment(e.report_date)).format('MM/DD/YYYY hh:mm A')}</td>
                    <td>
                      {e.logged_by && e.logged_by.fullname}
                      <div className={styles.subtitle}>
                        {e.logged_by && e.logged_by.roles.length > 0 && e.logged_by.roles[0].name}
                      </div>
                    </td>
                    <td>{e.type}</td>
                    <td>{e.subtype}</td>
                    <td>{e.description}</td>
                    <td>
                      {e.source
                      && (
                      <Link style={{ opacity: 1 }} to={getLinkForSource(e.source)}>
                        <FormattedMessage id="operations.loglist.source" defaultMessage="Source" />
                      </Link>
                      )}
                    </td>
                    <td>
                      <Clickable className={styles.hlink} onClick={() => onSelect(e.id)}>
                        <FormattedMessage id="operations.loglist.details" defaultMessage="Details" />
                      </Clickable>
                    </td>
                  </tr>
                ))}
            </tbody>
            )}
          </React.Fragment>
        ))
      }
    </table>
  );
};

LogTable.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default LogTable;
