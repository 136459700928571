import { useCallback } from 'react';
import { useCreateDirectMessage, useGetMessages, useUpdateDM } from './index';
import { useRDetails } from './useRDetails';
// import { fields } from './assets';
import {
  CreateMessagesArgs,
  UseDirectMessages,
  DeleteDMVars,
  UpdateDMVars
} from './hooks.types';
import { ChatTypes } from '../../enums';
// import { useDeleteDMMutation } from './useDeleteDMMutation';

export const useDirectMessages: UseDirectMessages = ({
  type = ChatTypes.directMessages,
  roomId,
  dMActionsCb: { setListAction, deleteAction }
}) => {
  const { userId, airportId } = useRDetails();
  // const { deleteItem } = useDeleteDMMutation();

  const { createMutate } = useCreateDirectMessage();
  const { updateDM } = useUpdateDM();
  const { fetchMore, subscribeToMore: subscribeTo, loading } = useGetMessages({
    roomId,
    setListAction
  });

  const deleteMessage = useCallback(
    async (variables: { variables: DeleteDMVars }) => {
      try {
        // const { deleteID, dlt } = fields[type];

        // const { data } = await deleteItem(variables);

        // deleteAction({ [deleteID]: data[dlt][deleteID] });
      } catch (e) {
        console.log('e', e);
      }
    },
    []
  );

  const createMessages = useCallback(
    (args: CreateMessagesArgs) => {
      createMutate({
        variables: { fromUserId: userId, airportId, ...args }
      });
    },
    [createMutate, userId, airportId]
  );

  const updateMessage = useCallback(
    (
      variables: Pick<UpdateDMVars, "attachments" | "message" | "messageId">
    ) => {
      updateDM({
        variables
      }).then(({ data }) => {
        // TODO handle updateMessage
      });
    },
    [updateDM]
  );

  return {
    createMessages,
    subscribeTo,
    fetchMore,
    loading,
    updateMessage,
    deleteMessage
  };
};
