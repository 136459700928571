/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import drawLocales from 'leaflet-draw-locales';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import moment from 'moment/min/moment-with-locales';
import styles from '../workOrderList.module.scss';
import mapStyles from '../../../settings/Map/map.module.scss';
import iconAsset from '../../../../icons/assets/workOrder.svg';
import iconOperation from '../../../../icons/assets/operations.svg';
import iconCompleted from '../../../../icons/assets/completed.svg';
import axiosInstance from '../../../../config/axios';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import { getBackendBaseUrl } from '../../../../constants';


const language = localStorage.getItem('lang') ||'en';
drawLocales(language);

class Map extends Component {
  markers = L.markerClusterGroup({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderfyDistanceMultiplier: 1,
    maxClusterRadius: 50
  });

  state = {
    loadedData: [],
    zoomLevel: localStorage.getItem('workorderZoom')
  }

  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps) {
    const { workorders } = this.props;
    if (prevProps.workorders.length !== workorders.length) {
      if (this.initializeMap) {
        this.map.remove();
        this.initializeMap();
      }
    }
  }

  initializeMap = () => {
    const { defaultLocation, workorders, workorderList } = this.props;
    const { zoomLevel } = this.state;
    const bounds = [];
    const that = this;
    const mapZoomLevel = zoomLevel !== null ? zoomLevel : 15;

    this.markers.clearLayers();
    this.map = L.map('map', {
      center: [...defaultLocation].reverse(),
      zoom: mapZoomLevel,
      fullscreenControl: true
    });
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);
    const assets = {
      1: iconAsset,
      2: iconOperation,
      3: iconCompleted
    };
    if (workorders.length) {
      workorders.forEach((w) => {
        let locations = w.location;
        if (locations != null) {
          locations = [...locations];
          locations.forEach((loc) => {
            // eslint-disable-next-line new-cap
            const marker = new L.marker(loc, {
              icon: new L.Icon({
                iconUrl: assets[w.status],
                iconSize: [29, 35],
                iconAnchor: [15, 35]
              })
            });
            if (workorderList) {
            // eslint-disable-next-line consistent-return
              marker.on('click', async (e) => {
                const { loadedData } = that.state;
                const { paginatedWorkorders } = that.props;
                const customOptions = { className: mapStyles.tooltip, width: '200' };
                const isLoaded = paginatedWorkorders.filter(x => x.order_number === w.id).length > 0;
                const isDisplayed = loadedData.filter(x => x.order_number === w.id).length > 0;
                if (!workorderList) {
                  marker.unbindPopup();
                } else if (isLoaded) {
                  marker.openPopup();
                  const wo = that.props.paginatedWorkorders.filter(x => x.order_number === w.id);
                  const checkIfAdded = obj => obj.id === wo[0].id;
                  const Duplicate = that.state.loadedData.some(checkIfAdded);
                  if (!Duplicate) {
                    that.setState(prevState => ({
                      loadedData: [...prevState.loadedData, wo[0]]
                    }));
                  }
                  if (wo.length > 0) {
                    const custompopup = await that.addPopupToPoint(wo[0], wo[0].assets, marker);
                    marker.bindPopup(custompopup, customOptions).openPopup();
                  }
                  return marker;
                } else if (isDisplayed) {
                  const wo = that.state.loadedData.filter(x => x.order_number === w.id);
                  if (wo.length > 0) {
                    const custompopup = await that.addPopupToPoint(wo[0], wo[0].assets, marker);
                    marker.bindPopup(custompopup, customOptions).openPopup();
                  }
                } else {
                  marker.bindPopup(`<div class="text-center"><div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                  </div></div>`, customOptions).openPopup();
                  let wo = {};
                  const baseURL = getBackendBaseUrl();
                  const url = `${baseURL}/work_orders/${w.id}`;
                  axiosInstance({
                    method: 'get',
                    url
                  }).then(async (response) => {
                    wo = response.data;
                    if (wo.assets.length>0) {
                      wo.assets[0].type = wo.assets[0].asset_type.name;
                      wo.assets[0].icon = wo.assets[0].asset_type.icon;
                    }
                    that.setState(prevState => ({
                      loadedData: [...prevState.loadedData, wo]
                    }));
                    const popup = e.target.getPopup();
                    const custompopup = await that.addPopupToPoint(wo, wo.assets, marker);
                    popup.setContent(custompopup);
                    popup.update();
                  });
                }
              });
            }
            if (workorders.length === 1 && !workorderList) {
              bounds.push(loc.lat, loc.lon);

              if (workorders[0].shapes.length>0) {
                const drawnItems = new L.FeatureGroup();
                workorders[0].shapes.map((o) => {
                  let l = L.geoJSON(o.geometry)._layers;
                  [l] = Object.keys(l).map(ob => l[ob]);
                  const customlayer = this.addPopupToLayer(o, l);
                  this.map.addLayer(drawnItems.addLayer(customlayer));
                });
              }
              this.map.setView([...bounds], workorders[0].zoom_level ? workorders[0].zoomlevel : 18);
            }
            this.markers.addLayer(marker);
          });
        }
      });
      this.markers.addTo(this.map);
      if (workorderList) {
        that.map.on('zoomend', () => {
          const zoom = that.map.getZoom();
          localStorage.setItem('workorderZoom', zoom);
        });
      }
    }
  }

  handleZoomLevel = (zoomLevel) => {
    const { setZoomLevel } = this.props;
    setZoomLevel(zoomLevel);
  }

  addPopupToLayer = (surface, layer) => {
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: 'blue',
      opacity: 1,
      fillOpacity: 0.48
    });
    return customlayer;
  };

  addPopupToPoint = (wo, assets) => {
    const { category, subcategory, report_date, id, problem_description, logged_by } = wo;
    const firstAsset = {
      name: null, type: null, icon: null };
    if (assets.length > 0) {
      firstAsset.name = assets[0].name;
      firstAsset.type = assets[0].type;
      firstAsset.icon = assets[0].icon;
    }
    const maximumLength = 250;
    const problemDesc = problem_description.length > maximumLength ? `${problem_description.substring(0, maximumLength)}...` : problem_description;
    const { name, type, icon } = firstAsset;
    const customPopup = `
      <div class=${mapStyles.tooltipHeader}>
          <h4>${category}</h4>
          <a href="/ops/workorders/${wo.order_number ? wo.order_number : id}/detail"> view details </a>
      </div>
      <p><b>Sub Category</b> : ${subcategory}</p>
      <p><b>Created on</b> : ${moment(report_date).format('MM/DD/YYYY hh:mm A')}</p>
      <p><b>Created by</b>: ${logged_by.fullname}</p>
      <p><b>Description</b>: ${problemDesc}</p>

      ${name !== null || type !== null
      ? `<div><h5 class=${mapStyles.tooltipHeader}>Asset Details</h5>
      ${name!== null ? `<p><b>Name</b>: ${name} </p>` : ''}
      ${type!== null ? `<p><b>Type</b>: <img src=${icon} /> ${type} </p> </>` : ''}</div>` : ''
  }
    `;
    // specify popup options
    // const customOptions = { className: mapStyles.tooltip, width: '200' };
    return customPopup;
    // layer.bindPopup(customPopup, customOptions).openPopup();
  }

  render() {
    return <div id="map" className={styles.map} />;
  }
}

Map.propTypes = {
  workorders: PropTypes.arrayOf(PropTypes.shape({})),
  defaultLocation: PropTypes.arrayOf(PropTypes.number)
};

Map.defaultProps = {
  workorders: [],
  defaultLocation: [0, 0]
};

export default Map;
