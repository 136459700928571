import styled from 'styled-components';

type WrapperProps = {
  listHeight?: number;
};

export const Wrapper = styled.div<WrapperProps>`
  padding-top: 15px;
  height: calc(100vh - ${({ listHeight }) => `${listHeight}px`});
  overflow: auto;
  position: relative;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
