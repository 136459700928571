import React from 'react';
import moment from 'moment';
// import { debounce } from '../../../../libComponents/lodash';

import { Wrapper } from './MessageInfo.styles';
import { PostMessageType, ToggleLikesFunc } from '../../types';
import { commentsActions, useChanelState } from '../../state/channelsState';
// import { ReactComponent as InsertSmile } from '../../../../icons/insert-smile.svg';
// import { ReactComponent as SadEmoji } from '../../../../icons/sadmoli.svg';

type MessageInfoProps = {
  toggleLikes?: ToggleLikesFunc;
  channelId: string;
  messageData: PostMessageType;
};
// let count = 0;
const MessageInfo = React.memo<MessageInfoProps>(
  ({ messageData, toggleLikes, channelId }) => {
    const { dispatch } = useChanelState();
    const { comments, postId, lastReplay } = messageData;
    // const [likeButton, setLikeButton] = useState(Boolean);
    // const handelLiked = () => {
    //   setLikeButton(true);
    // };
    // if (likeButton === true && (likes || likes === 0)) {
    //   count += 1;
    //   if (count > 1 && count < 3) {
    //     count = 0;
    //     setLikeButton(false);
    //   }
    // }

    return (
      <Wrapper>
        <div className="post-footer">
          <span
            onClick={() => commentsActions.show(dispatch, {
              postId,
              channelId,
              messageData,
              visible: true
            })
            }
            className="replies"
          >
            {comments>0?`${comments || 0} replies`:''}
            <div dangerouslySetInnerHTML={{__html: ' &nbsp; &nbsp; &nbsp;'}} />
          {!!lastReplay && (
            <span className="last-reply" style={{paddingTop:'2px'}}>
              last reply
              {' '}
              {moment(lastReplay * 1000).fromNow()}
            </span>
          )}
          </span>
        </div>
        {/* <div className="smile-wrapper"> */}
          {/* <div className="smile">
            <span role="img" aria-label="emoji">
              😀
            </span>
            {likes || 0}
          </div>
          <div
            onClick={
              likeButton === false
                ? debounce(() => toggleLikes({ postId, channelId }, isLiked))
                : ''
            } */}
          {/* > */}
            {/* {likeButton === false && isLiked === false ? (
              <button
                className="smile"
                onClick={() => handelLiked()}
                disabled={likeButton}
              >
                <InsertSmile />
                {'+'}
              </button>
            ) : (
              <button
                className="smile"
                onClick={() => handelLiked()}
                disabled={likeButton}
              >
                <SadEmoji className="smile2" />
                <b>-</b>
              </button>
            )}
          </div> */}
        {/* </div> */}
      </Wrapper>
    );
  }
);

export default MessageInfo;
export { MessageInfo };
