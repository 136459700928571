import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, CardBody, CardTitle, Table, Col, Row } from 'reactstrap';
import styles from './notams.module.scss';
import Separator from '../../../../components/separator';
import { fetchNotams } from '../../../workorders/redux/actions';
import NotamTabFilters from './NotamTabFilters';

class Notams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notams: []
    };
  }


  static getDerivedStateFromProps(props, state) {
    if ((props.notamsList) && (!state.notams.length)) {
      return {
        ...state,
        notams: props.notamsList
      };
    }
    return state;
  }

  componentDidMount() {
    const { actionFetchNotams } = this.props;
    actionFetchNotams();
  }

    toggle = (tab) => {
      const { activeTab } = this.state;
      if (!activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
    };

    handleSortData = (data) => {
      const { notamsList }= this.props;
      let filteredNotams = [];
      if (data === 'ALL') {
        this.setState({ notams: notamsList });
      } else {
        filteredNotams = notamsList.filter(notam => notam.notam_report.split(' ')[0] === data);
        this.setState({ notams: filteredNotams });
      }
    }

    render() {
      const { notams } = this.state;
      return (
        <Col lg={12} className="mt-4">
          <Card className={styles.Card}>
            <CardTitle className={styles.airfieldscardtitle}>
              <Col lg={12}>
                <Row className={styles.notamsHead}>
                  <Col className={styles.notamText}>
                    <FormattedMessage
                      id="airfields.notams"
                      defaultText="NOTAMs"
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          <Col lg={12}>
                            <NotamTabFilters
                              onClick={this.handleSortData}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </CardTitle>
            <hr className={styles.hrLine} />
            <CardBody className={styles.cardBody}>
              <Col lg={12} className={styles.notamscard}>
                <>
                  <div className={styles.notams}>
                    <div>
                      <Table borderless>
                        <Col className={styles.notamstableColumn}>
                          <thead className={styles.tableHead}>
                            <th className={`float-left ${styles.notamslabelId}`}>ID</th>
                            <Separator />
                            <th className={`float-left ${styles.notamslabels}`}>REPORT</th>
                          </thead>
                        </Col>
                        <tbody>
                          <tr>
                            <Col className={styles.notamstableColumn}>
                              {notams && notams.length
                                ? notams.map(n => (
                          <>
                            <tr className={styles.tableRowBottom}>
                              <td className={styles.notamId}>{n.notam_id}</td>
                              <Separator />
                              <td className={styles.notamslabels}>{n.notam_text}</td>
                              <div className={`${styles.cardborderline}`} />
                            </tr>
                          </>
                                ))
                                : ''}
                            </Col>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
        </>
              </Col>
            </CardBody>
          </Card>
        </Col>


      );
    }
}


const mapStateToProps = state => ({
  notamsList: state.workorders.notams
});

const mapDispatchToProps = dispatch => ({
  // FETCH NOTAMS DATA
  actionFetchNotams: (query) => {
    dispatch(fetchNotams(query));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Notams));
