import React from 'react';
import PropTypes from 'prop-types';

import ClockChart from './ClockChart';

import styles from './clock.module.scss';

// outer radious
const radius = 90;

/**
 * Time Clock Component
 * @param  {start} number props               the start time of shift
 * @param  {end} number props                 the end time of shift
 * @param  {index} number props               the day or afternoon, or night depending on index
 * @param  {updateData} func props            the func to update parent component data
 */
class Clock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start: 0,
      end: 0,
      direction: ''
    };
  }

  // initial state
  componentDidMount() {
    const { start, end } = this.props;
    this.setState({ start, end });
  }

  // update state
  componentDidUpdate(prevProps) {
    const { start, end } = this.props;

    if (prevProps.start !== start || prevProps.end !== end) {
      this.setState({ start, end });
    }
  }

  // angle of start for clock chart
  getStartAngle = () => {
    const { start } = this.state;
    return -((start > 12 ? start - 12 : start) * 30) + 90;
  }

  // angle of end for clock chart
  getEndAngle = () => {
    const { start } = this.state;
    const startAngle = -((start > 12 ? start - 12 : start) * 30) + 90;
    return startAngle + 360;
  }

  // green area size in clock chart
  getTimeValue = () => {
    const { start, end } = this.state;
    return end > start ? end - start : end - start + 24;
  }

  // amount of angle for green area
  getPivotAngle = () => this.getStartAngle() - this.getTimeValue() * 30

  // generating chart data
  getChartData = () => [
    { name: 'shift 1', value: 12 - this.getTimeValue() },
    { name: 'shift 2', value: this.getTimeValue() }
  ]

  // highlight points of start time and end time
  getStartTranslatePointStyle = () => {
    const startAngle = this.getStartAngle();
    const startPointX = Math.round(Math.cos(startAngle / 180 * Math.PI) * radius * 100) / 100;
    const startPointY = -Math.round(Math.sin(startAngle / 180 * Math.PI) * radius * 100) / 100;
    return { transform: `translate(${startPointX}px, ${startPointY}px)` };
  }

  // position of labels of start time and end time
  getStartTranslateLabelStyle = () => {
    const startAngle = this.getStartAngle();
    const startPointX = Math.round(Math.cos(startAngle / 180 * Math.PI) * radius * 100) / 100;
    const startPointY = -Math.round(Math.sin(startAngle / 180 * Math.PI) * radius * 100) / 100;
    // label points of start time and end time
    const startLabelX = startPointX > 0 ? startPointX + 10 : startPointX - 50;
    const startLabelY = startPointY > 0 ? startPointY : startPointY - 30;
    return { transform: `translate(${startLabelX}px, ${startLabelY}px)` };
  }

  // position of start Points for start time and end time
  getEndTranslatePointStyle = () => {
    const pivotAngle = this.getPivotAngle();
    const endPointX = Math.round(Math.cos(pivotAngle / 180 * Math.PI) * radius * 100) / 100;
    const endPointY = -Math.round(Math.sin(pivotAngle / 180 * Math.PI) * radius * 100) / 100;
    return { transform: `translate(${endPointX}px, ${endPointY}px)` };
  }

  // position of end labels of start time and end time
  getEndTranslateLabelStyle = () => {
    const pivotAngle = this.getPivotAngle();
    const endPointX = Math.round(Math.cos(pivotAngle / 180 * Math.PI) * radius * 100) / 100;
    const endPointY = -Math.round(Math.sin(pivotAngle / 180 * Math.PI) * radius * 100) / 100;
    const endLabelX = endPointX > 0 ? endPointX + 10 : endPointX - 50;
    const endLabelY = endPointY > 0 ? endPointY : endPointY - 30;
    return { transform: `translate(${endLabelX}px, ${endLabelY}px)` };
  }

  // rotate angle of start time line
  getStartRotateStyle = () => {
    const startAngle = this.getStartAngle();
    return { transform: `rotate(${90 - startAngle}deg)` };
  }

  // rotate angle of end time line
  getEndRotateStyle = () => {
    const pivotAngle = this.getPivotAngle();
    return { transform: `rotate(${90 - pivotAngle}deg)` };
  }

  // determine which hand is dragging
  onDragStart = (event, direction) => {
    this.setState({ direction });
  }

  // calc the next time by drag
  onDrag = (event) => {
    event.preventDefault();

    const { start, end, direction } = this.state;

    if (event.pageX === 0 && event.pageY === 0) { return false; }

    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    const offsetX = event.pageX - currentTargetRect.left;


    const offsetY = event.pageY - currentTargetRect.top;

    const dAngle = direction === 'start' ? this.getStartAngle() : this.getPivotAngle();

    const startPointX = Math.round(Math.cos(dAngle / 180 * Math.PI) * radius * 100) / 100;
    const startPointY = -Math.round(Math.sin(dAngle / 180 * Math.PI) * radius * 100) / 100;
    const x = startPointX + offsetX;
    const y = startPointY + offsetY;

    const angle = -(Math.floor(Math.atan2(y, x) * 180 / Math.PI));

    if ((angle - dAngle) % 30 < 1) {
      const base = direction === 'start' ? start : end;
      let value = Math.floor((180 - angle) / 30) - 3;
      value = value < 0 ? value + 12 : value;
      if (base >= 11 && base < 23) {
        value += 12;
      }
      if (value < 24 && value >= 0) {
        direction === 'start' ? this.setState({ start: value }) : this.setState({ end: value });
      }
    }
  }

  // update values when end of dragging
  onDragEnd = (event) => {
    event.preventDefault();
    const { updateData, index } = this.props;
    const { direction, start, end } = this.state;
    updateData(null, index, direction, direction === 'start' ? start : end);
  }

  // component render
  render() {
    return (
      <div className={styles.clock}>
        <div className={styles['clock-wrapper']}>
          <div className={styles['clock-face']}>
            <ClockChart data={this.getChartData()}
              startAngle={this.getStartAngle()}
              endAngle={this.getEndAngle()}
            />

            <div className={`${styles.prime} ${styles.number12}`}>12</div>
            <div className={`${styles.prime} ${styles.number3}`}>3</div>
            <div className={`${styles.prime} ${styles.number6}`}>6</div>
            <div className={`${styles.prime} ${styles.number9}`}>9</div>

            <div className={styles['center-point']} />

            <div className={styles['start-point']} style={this.getStartTranslatePointStyle()} />
            <div className={styles['end-point']} style={this.getEndTranslatePointStyle()} />

            <div className={styles['start-hand']} style={this.getStartRotateStyle()} />
            <div className={styles['end-hand']} style={this.getEndRotateStyle()} />

            <div className={styles['start-label']} style={this.getStartTranslateLabelStyle()} draggable
              onDragStart={e => this.onDragStart(e, 'start')}
              onDrag={e => this.onDrag(e)}
              onDragEnd={e => this.onDragEnd(e)}
            >
Start

            </div>
            <div className={styles['end-label']} style={this.getEndTranslateLabelStyle()} draggable
              onDragStart={e => this.onDragStart(e, 'end')}
              onDrag={e => this.onDrag(e)}
              onDragEnd={e => this.onDragEnd(e)}
            >
Finish

            </div>
          </div>
        </div>
      </div>
    );
  }
}

Clock.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired
};

export default Clock;
