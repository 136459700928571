/* eslint-disable no-underscore-dangle */
import jwt_decode from 'jwt-decode'; // eslint-disable-line camelcase

import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { CrossStorageClient } from 'cross-storage';
import { setHeaders } from '../config/axios';


import { LOGIN_ROUTE, BASENAME } from '../constants/RouterConstants';

const isLocal = window.location.hostname.indexOf('local') === 0;
const hostName = window.location.hostname.split(':')[0];

const REFRESH_INTERVAL = 3000 * 1000; // Miliseconds
const SSO_ENABLED = process.env.REACT_APP_SSO_ENABLED === 'true' ? true : isLocal;
let instance = null;

export default class Auth {
  constructor() {
    if (!instance) {
      this.getAuthToken = this.getAuthToken.bind(this);
      this.setAuthToken = this.setAuthToken.bind(this);

      this.refreshUserToken = this.refreshUserToken.bind(this);
      this.refresherAction = this.refresherAction.bind(this);

      this.isAuthenticated = this.isAuthenticated.bind(this);
      this.isTokenExpired = this.isTokenExpired.bind(this);
      this.decodeToken = this.decodeToken.bind(this);
      this.csOnConnect = this.csOnConnect.bind(this);
      this.csOnReject = this.csOnReject.bind(this);

      this.logout = this.logout.bind(this);
      this.loginRoute = Auth.loginRoute.bind(this);

      this.storage = window.localStorage;
      this.crossStorage = new CrossStorageClient(`https://api.${isLocal ? 'dev.aerosimple.com' : hostName.slice(hostName.indexOf('.') + 1)}/hub`);
      this.crossStorage.onConnect().then(this.csOnConnect, this.csOnReject);
      this.refresher = null;

      this.userPool = new CognitoUserPool({
        UserPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
        ClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`
      });
      instance = this;
    }
    return instance;
  }

  static getInstance() {
    if (instance === null) {
      instance = new Auth();
    }
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
    return instance;
  }

  getUserPool() {
    return this.userPool;
  }

  /*
   Returns JWT for the backend authentication.
   @returns {String} JWT.
  */
  getAuthToken() {
    return this.storage.getItem('token');
  }

  /*
   Returns ID token
   @returns {String} IDToken.
  */
  getIDToken() {
    return this.storage.getItem('idtoken');
  }

  /*
   Stores the JWT in the browser.
   @returns {String} token.
  */
  _setAuthToken(newToken) {
    setHeaders(newToken);
    this.storage.setItem('token', newToken);
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
    return newToken;
  }

  /*
   Stores the IDToken in the browser.
   @returns {String} token.
  */
  setIDToken(newToken) {
    this.storage.setItem('idtoken', newToken);
    return newToken;
  }

  csOnReject() {
    if (this.isAuthenticated()) {
      // window.location.reload();
    }
    // } else {
    //   this.loginRoute();
    // }
  }

  csOnConnect() {
    this.crossStorage.get('token').then((res) => {
      if (SSO_ENABLED && res !== undefined
        && res !== this.getAuthToken() && !this.isTokenExpired(res)) {
        // eslint-disable-next-line no-underscore-dangle
        this._setAuthToken(res);
        window.location.reload();
      } else if (this.isAuthenticated()) {
        this.crossStorage.set('token', this.getAuthToken()).then();
      }
      // else {
      //   this.loginRoute();
      // }
    });
  }

  static loginRoute() {
    if (!window.location.href.endsWith(LOGIN_ROUTE)) {
      window.location.href = BASENAME + LOGIN_ROUTE;
    }
  }

  setAuthToken(newToken) {
    this._setAuthToken(newToken);
    this.crossStorage.set('token', newToken).then();
  }

  refreshUserToken() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          this.cleanStorage();
          clearInterval(this.refresher);
          return;
        }
        cognitoUser.refreshSession(session.getRefreshToken(), (error, newSession) => {
          if (error) {
            this.cleanStorage();
            clearInterval(this.refresher);
            return;
          }
          this.setAuthToken(newSession.getAccessToken().getJwtToken());
          this.setIDToken(newSession.getIdToken().getJwtToken());
        });
      });
    } else {
      this.cleanStorage();
    }
  }

  /* Indicates if a user is logged in.
   @returns {Boolean} Returns True if a user's token has not expired, and False otherwise.
  */
  isAuthenticated() {
    const res = !this.isTokenExpired();
    if (!res) {
      this.cleanStorage();
    }
    return res;
  }

  /*
   Returns True if the stored token has expired
   @returns {Boolean}
  */
  isTokenExpired(token=this.getAuthToken()) {
    const decoded = this.decodeToken(token);
    if (!decoded || typeof decoded.exp === 'undefined') {
      return true;
    }
    const d = new Date(0);
    d.setUTCSeconds(decoded.exp);
    if (d === null) {
      return true;
    }
    return ((new Date().valueOf()) > d.valueOf());
  }

  /*
   Decodes stored JWT.
   Does not validate signature.
   @returns {Object} Token decodificado.
  */
  decodeToken(token=this.getAuthToken()) {
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
      decoded = null;
    }
    return decoded;
  }

  /*
   Clears user information.
  */
  logout() {
    this.cleanStorage();
    clearInterval(this.refresher);
    this.crossStorage.del('token').then(this.loginRoute);
  }

  /*
  Clears token from storage
  */
  cleanStorage() {
    this.storage.removeItem('token');
    // this.crossStorage.del('token').then();
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
  }

  refresherAction() {
    if (this.isAuthenticated()) {
      this.refreshUserToken();
    }
  }


  startRefresher() {
    if (this.refresher === null) {
      const decoded = this.decodeToken();
      if (decoded !== null) {
        const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        const now = new Date();
        const secondsUntilExpiration = Math.floor((date - now) / 1000);
        if (secondsUntilExpiration < 600) {
          this.refresherAction();
        } else if (secondsUntilExpiration < REFRESH_INTERVAL / 1000) {
          this.refresher = setInterval(this.refresherAction, (secondsUntilExpiration - 120) * 1000);
        } else {
          this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
        }
      } else {
        this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
      }
    }
  }
}

Auth.getInstance().startRefresher();
