import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

import moment from 'moment/min/moment-with-locales';

import 'react-datetime/css/react-datetime.css';


class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      currentDate: moment(moment().format())
    }
    this.focused = false;

  }

  componentDidUpdate() {
    const { init, currentDate }= this.state;
    const { answer, noDefault } = this.props;
    if (!answer && currentDate && !init && !noDefault) {
      this.sendCurrentDate();
    }
  }

  sendCurrentDate = () => {
    const { fieldId, isRequired, handleValueChange, handleFieldErrorChanged, updateFieldErrors } = this.props;
    const { currentDate } = this.state;
    this.setState({ init: true });
    handleValueChange(currentDate.format(), fieldId);
    let updatedErrors = [];
    if (!currentDate) {
      handleFieldErrorChanged(fieldId, isRequired);
      updatedErrors = [...updatedErrors, 'pulpoforms.errors.not_blank'];
    } else {
      handleFieldErrorChanged(fieldId, false);
    }
    updateFieldErrors(updatedErrors);
  }

  handleAnswerChanged(value, fieldId) {
    const {
      handleValueChange, handleFieldErrorChanged, isRequired, updateFieldErrors
    } = this.props;
    handleValueChange(value.format(), fieldId);
    let updatedErrors = [];

    if (!value) {
      handleFieldErrorChanged(fieldId, isRequired);
      updatedErrors = [...updatedErrors, 'pulpoforms.errors.not_blank'];
    } else {
      handleFieldErrorChanged(fieldId, false);
    }
    updateFieldErrors(updatedErrors);
  }

  render() {
    const { fieldId, answer, onlyTime, noDefault, className } = this.props;
    const { currentDate } = this.state;
    return (
      <Datetime
        {...(onlyTime ? { dateFormat: false } : {})}
        defaultValue={answer ? moment(answer) : (!noDefault && currentDate)}
        inputProps={{ readOnly: true }}
        closeOnSelect
        onChange={e => this.handleAnswerChanged(e, fieldId)}
        id={`date-field-${fieldId}`}
        className={className}
      />
    );
  }
}


Date.defaultProps = {
  answer: undefined,
  onlyTime: false
};

Date.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  answer: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  updateFieldErrors: PropTypes.func.isRequired,
  onlyTime: PropTypes.bool
};

export default Date;
