import React from 'react';

import { Wrapper } from './UserList.styles';
import { OnUserRemoveFunc, UserType } from '../../types';
import { UserItem } from '../UserItem';

type UsersListRemoveProps = {
  items: UserType[];
  onRemove: OnUserRemoveFunc;
};

const UserList = React.memo<UsersListRemoveProps>(
  ({ items, onRemove }): React.ReactElement => (
    <Wrapper>
      {items.map(user => (
        <UserItem {...user} onRemove={onRemove} key={user.userId} />
      ))}
    </Wrapper>
  )
);

export default UserList;
export { UserList };
