import React from 'react';
import { FormattedMessage } from 'react-intl';
// import PropTypes from 'prop-types';
import styles from './toolbar.module.scss';
// import Separator from '../../../components/separator';
import DateFilter from '../../workorders/List/components/datefilter';

const OperationDashboardListFilter = ({
  intl,
  dates,
  range,
  onFilterChange,
  onDateChange,
  filter,
  formatedMessage,
  Message
}) => (
  <div style={{ display: 'flex' }} className={styles.toolbar}>

    <div className={styles.counter}>
      <div style={{ paddingLeft: '5px' }}>
        <FormattedMessage id={formatedMessage} defaultMessage={Message} />
      </div>
    </div>
    {filter&&(
    <div className={styles.dateFilter}>
      <DateFilter
        intl={intl}
        onDateChange={onDateChange}
        onFilterChange={onFilterChange}
        dates={dates}
        range={range}
      />
    </div>
    )}
  </div>
);

export default OperationDashboardListFilter;

OperationDashboardListFilter.propTypes = {
};
