import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './toolbar.module.scss';
import Separator from '../../../../components/separator';
import DateFilter from './datefilter';

const WorkorderListFilter = ({
  handleDropdown,
  filterCategories,
  workorderslistSummary,
  intl,
  date1,
  date2,
  dates,
  range,
  onFilterChange,
  onDateChange
}) => (
  <div style={{ display: 'flex' }} className={styles.toolbar}>

    <div className={styles.counter}>
      {workorderslistSummary && (
        <div style={{ paddingLeft: '5px' }}>
          <FormattedMessage id="section.toolbar.workorders" defaultMessage="WorkOrders " />
          (
          {workorderslistSummary.length}
          )
        </div>
      )
      }

    </div>
    <div className={styles.dateFilter}>
      <DateFilter
        intl={intl}
        onDateChange={onDateChange}
        onFilterChange={onFilterChange}
        dates={dates}
        range={range}
      />
    </div>
    <div className={styles.detailHeader}>
      <Separator />
      <span className={styles.actionsBtn} onClick={() => handleDropdown()} >
        <FormattedMessage id="workorders.list.filters" defaultMessage="Filters " />
        <span style={{ paddingLeft: '5px' }}>
          (
          {filterCategories.length}
          )
        </span>
      </span>
    </div>
  </div>
);

export default WorkorderListFilter;

WorkorderListFilter.propTypes = {
  handleDropdown: PropTypes.func.isRequired
};
