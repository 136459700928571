import {
  initialState as commentsState,
  types as commentsTypes
} from './comments';
import { initialState as postsState, types as postsTypes } from './posts';

import {
  CurrentUser,
  ToggleLikesFunc,
  UpdatePostFunc,
  UpdateCommentFunc,
  CreateMessagesFunc,
  CreateCommentFunc,
  DeleteCommentFunc,
  DeletePostFunc,
  FetchMoreFunc
} from '../../types';

export type GlobalState = {
  comments: commentsTypes.CommentsState;
  posts: postsTypes.PostsState;
  postList?: postsTypes.PostMessageObj;
  fetchMore?: FetchMoreFunc;
  fetchMoreComment?: FetchMoreFunc;
  deletePost?: DeletePostFunc;
  deleteComment?: DeleteCommentFunc;
  toggleLikes?: ToggleLikesFunc;
  updatePost?: UpdatePostFunc;
  updateComment?: UpdateCommentFunc;
  createMessages?: CreateMessagesFunc;
  createComment?: CreateCommentFunc;
  currentUser?: CurrentUser;
  channelsLoading?: boolean;
  loadingPost?: boolean;
  loadingComment?: boolean;
  savingPost?: boolean;
  savingComment?: boolean;
  updatePostLoading?: boolean;
  updateCommentLoading?: boolean;
};

export const globalState: GlobalState = {
  comments: commentsState(),
  posts: postsState(),
  currentUser: {
    userId: null
  }
};
