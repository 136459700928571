import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import PulpoField from '../../../../../../../pulpo_visualizer/fields';
import Clickable from '../../../../../../../components/clickable/Clickable';
import FormattedMessageWithClass from '../../../../../../../components/formattedMessageWithClass';
import styles from '../../steps.module.scss';

const FeeForm = ({
  handleAnswerChanged,
  info,
  intl,
  FeeDetail
}) => {
  const handleDrop = (dropped) => {
    const res = info.receipt_attachments || [];
    handleAnswerChanged([...res, ...dropped], 'receipt_attachments');
  };

  const handleRemoveImage = (r) => {
    const res = info.receipt_attachments.filter(e => e !== r);
    handleAnswerChanged(res, 'receipt_attachments');
    const removedReceipts = info.removedReceipts || '';
    handleAnswerChanged([r.id, ...removedReceipts], 'removedReceipts');
  };

  return (
    <>
      <div className={styles.stepTwo}>
        {!FeeDetail && (
        <div className={styles.sectionHead}>
          <FormattedMessage id="lease.tenants.steps.step3.misc_fee" defaultMessage="Misc Fees" />
        </div>
        )}
        <div className={styles.displayFlex}>
          <PulpoField key="fee_type" id="fee_type" type="select"
            transationID="lease.tenants.steps.step3.fee_type"
            title="Fee Type"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.fee_type}
            handleFieldErrorChanged={() => {}}
            values={[
              { key: 'Application Fee', value: intl.formatMessage({ id: 'lease.tenants.fee_type_1' }) },
              { key: 'Common Use Space', value: intl.formatMessage({ id: 'lease.tenants.fee_type_2' }) },
              { key: 'Customer Facility Charge', value: intl.formatMessage({ id: 'lease.tenants.fee_type_4' }) },
              { key: 'Freight Fees', value: intl.formatMessage({ id: 'lease.tenants.fee_type_5' }) },
              { key: 'Fuel Flowage Fee', value: intl.formatMessage({ id: 'lease.tenants.fee_type_6' }) },
              { key: 'Gross Exceeds of Annual Rent', value: intl.formatMessage({ id: 'lease.tenants.fee_type_7' }) },
              { key: 'Gross Receipts', value: intl.formatMessage({ id: 'lease.tenants.fee_type_8' }) },
              { key: 'Intercom Fee', value: intl.formatMessage({ id: 'lease.tenants.fee_type_9' }) },
              { key: 'Interest Charges', value: intl.formatMessage({ id: 'lease.tenants.fee_type_10' }) },
              { key: 'Office Space', value: intl.formatMessage({ id: 'lease.tenants.fee_type_11' }) },
              { key: 'Ramp Parking', value: intl.formatMessage({ id: 'lease.tenants.fee_type_12' }) },
              { key: 'Remote Parking', value: intl.formatMessage({ id: 'lease.tenants.fee_type_13' }) },
              { key: 'Rent in Lieu of Taxes', value: intl.formatMessage({ id: 'lease.tenants.fee_type_14' }) },
              { key: 'Security Deposit', value: intl.formatMessage({ id: 'lease.tenants.fee_type_15' }) }
            ]}
          />
          <PulpoField
            key="amount" id="amount" type="currency-phone"
            translationID="lease.tenants.steps.step3.amount"
            title="Amount" thousandSeparator
            placeHolderValue="$" suffix=".00" prefix="$"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.amount}
            handleFieldErrorChanged={() => {}}
          />
        </div>
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.label} id="lease.tenants.steps.step3.attach_receipt"
            defaultMessage="Receipt Attachments"
          />
          <Dropzone
            onDrop={handleDrop}
            disableClick
            className={styles.dropzone} accept="image/*, .pdf, .doc"
          >
            {({ open }) => (
              <>
                <p className={styles.browsetext}>
                  <FormattedMessage id="airport.logo.text1" defaultMessage="Drag an image here or" />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button" defaultMessage="browse" />
                  </button>
                  <FormattedMessage id="airport.logo.text2" defaultMessage="for an image to upload." />
                </p>
              </>
            )}
          </Dropzone>
          {info && info.receipt_attachments && info.receipt_attachments.length > 0 && (
          <div className={styles.photos}>
            {info.receipt_attachments.map(e => (
              <div key={e.name} className={styles.wrapper}>
                <Clickable onClick={() => handleRemoveImage(e)}>&times;</Clickable>
                {(e.type || e.content_type).includes('image') ? (
                  <img src={e.preview || e.url} alt={e.name || e.file_name} />
                ) : <p>{e.name || e.file_name}</p>
                }
              </div>
            ))}
          </div>
          )}
        </div>
        {!FeeDetail && (
        <div className={styles.endingBorder} />
        )}
      </div>
    </>
  );
};

export default injectIntl(FeeForm);
