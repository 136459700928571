import gql from 'graphql-tag';

const LIST_CHANNEL_USERS = gql`
  query listChannelUsers($channelId: String!, $limit: Int, $nextToken: String) {
    listChannelUsers(
      filter: { channelId: { eq: $channelId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        isActive
        userDetails {
          firstName
          lastName
          middleName
          profilePicture
          title
          userId
        }
      }
      nextToken
    }
  }
`;
export default LIST_CHANNEL_USERS;
