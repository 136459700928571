import React from 'react';
import { ActiveChatHeader } from '../../components';
import { ChatBoxProps, ListChannelUsersType } from '../../types';
import { ChatBox } from '../ChatBox';

import { Wrapper } from './ActiveChat.styles';

type ActiveChatProps = {
  listChannelUsers: ListChannelUsersType[];
  channelName?: string;
  channelId?: string;
} & ChatBoxProps;

const ActiveChat = React.memo<ActiveChatProps>(
  ({
    listChannelUsers,
    type,
    channelId,
    channelName,
    topicsConnected,
    updateLoading,
    createMsg,
    savingMessage,
    updateMessages,
    deletedMessageID,
    deleteMessage,
    loadMore,
    hasMore,
    messagesList,
    editedMessageId,
    toggleLikes,
    newPost,
    editedMessage
  }) => (
    <>
      <Wrapper>
        <ActiveChatHeader
          type={type}
          listChannelUsers={listChannelUsers}
          channelName={channelName}
          channelId={channelId}
        />
        <ChatBox
          type={type}
          topicsConnected={topicsConnected}
          deletedMessageID={deletedMessageID}
          updateLoading={updateLoading}
          createMsg={createMsg}
          savingMessage={savingMessage}
          updateMessages={updateMessages}
          deleteMessage={deleteMessage}
          loadMore={loadMore}
          hasMore={hasMore}
          messagesList={messagesList}
          editedMessageId={editedMessageId}
          editedMessage={editedMessage}
          toggleLikes={toggleLikes}
          newPost={newPost}
        />
      </Wrapper>
    </>
  )
);

export default ActiveChat;
export { ActiveChat };
