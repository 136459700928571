import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  width: 100%;

  div {
    height: 40px !important;
    width: 40px !important;
  }
`;
