/* eslint-disable react/no-did-update-set-state */
/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';
import ReactTelInput from 'react-telephone-input';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-toggle/style.css';
import './toggle.scss';
import 'react-telephone-input/lib/withStyles';
import UserImg from '../../../../icons/default_avatar.jpg';
import ImageUploader from './components/ImageUploader';
import Button from '../../../button';
import SectionHeader from '../../../sectionHeader';
import Shortcuts from '../../shortcuts/shortcuts';
import styles from './ProfileEdit.module.scss';
import UserIcon from '../../../../icons/inspection-icons/icon-1.svg';
import Modal from '../../../modal';
import flags from '../../../../icons/flags.png';


/** ******************************************************************
 *  Redux import
 * ************* */
import { editUser, fetchUserProfile, clearProfile } from '../redux/actions';
import { showConfirmModal, hideModal, changeCurrentPage } from '../../../../general_redux/actions';
import Loading from '../../../loading';

class ProfileEdit extends Component {
  links = [
    { url: '/', name: this.props.intl.formatMessage({ id: 'shortcuts.aerobot' }), key: 'aerobot' },
    { url: '/todo', name: this.props.intl.formatMessage({ id: 'shortcuts.todo' }), key: 'tasks' },
    { url: '/messenger', name: this.props.intl.formatMessage({ id: 'shortcuts.chat' }), key: 'messenger' },
    { url: '/settings/organization', name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }), permissions: ['can_modify_airport_settings'], key: 'settings' }
  ]

  constructor(props) {
    super(props);
    this.state = {
      validate: false,
      data: {
        first_name: '',
        last_name: '',
        phone: '',
        designation: ''
      },
      notifications: {},
      image: '',
      showErrorModal: false,
      loadingStatus: false,
      invalidNumber: false
    };
  }

  componentDidMount() {
    const { actionFetchUserProfile, actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('home');
    actionFetchUserProfile();
  }

  static getDerivedStateFromProps(props, state) {
    const isEmpty = Object.values(state.data).every(x => (x === null || x === ''));

    if (props.profile.id && isEmpty) {
      return { ...state,
        data: { first_name: props.profile.first_name,
          last_name: props.profile.last_name,
          phone: props.profile.phone,
          designation: props.profile.designation },
        notifications: Object.keys(props.profile.notification_preferences).length > 0
          ? props.profile.notification_preferences : { email: true, sms: true },
        image: props.profile.image ? props.profile.image : UserImg };
    }
    if (props.apiStatusUser.success) {
      if (props.currentPage === 'profile') {
        props.handleSignIn();
      }
      if (props.action.success !== state.success) {
        return { ...state,
          success: props.action.success,
          showErrorModal: props.apiStatusUser.success };
      }
      return state;
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { apiStatusUser, actionFetchUserProfile } = this.props;
    if (prevProps.apiStatusUser !== apiStatusUser) {
      actionFetchUserProfile();
    }
    if (apiStatusUser.success === true && prevProps.apiStatusUser.success === false) {
      this.setState({ loadingStatus: false });
    }
  }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  handleNotificationPreferences = (event) => {
    const { name } = event.target;
    const { notifications } = this.state;
    const check = event.target.checked;
    notifications[name] = check;
    this.setState({ ...notifications });
  }


  handleInputChange = (telNumber) => {
    const { invalidNumber } = this.state;
    if (telNumber && invalidNumber) {
      this.setState({ invalidNumber: false });
    }
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        phone: telNumber
      }
    }));
  }

  handleEdit = () => {
    const { data: { id, ...data } } = this.state;
    if (data.first_name === '' || data.last_name === '') {
      this.setState({ shouldShowErrors: true, loadingStatus: false });
    } else if (data.phone === null) {
      this.setState({ loadingStatus: false, invalidNumber: true });
    } else if (data.phone) {
      let number = data.phone;
      number = number.replace(/[^\d]/g, '');
      if (number.length < 10 || number.length > 16) {
        this.setState({ loadingStatus: false, invalidNumber: true });
      } else {
        this.setState({ loadingStatus: true, invalidNumber: false });
      }
    } else {
      this.setState({ loadingStatus: true, invalidNumber: false });
    }
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  }

  handleDrop = (image) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        image
      }
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { profile, actionEdit } = this.props;
    const { data, notifications } = this.state;
    const info = { id: profile.id, ...data };
    if (!data.first_name || !data.last_name || !data.phone) {
      return;
    }
    if (data.phone) {
      let number = data.phone;
      number = number.replace(/[^\d]/g, '');
      if (number.length < 10 || number.length > 16) {
        return;
      }
    }
    const formData = new FormData();
    if (info.first_name) {
      formData.append('first_name', info.first_name);
    }
    if (info.last_name) {
      formData.append('last_name', info.last_name);
    }
    if (info.phone) {
      const phonecontact = info.phone;
      let phone = '';
      for (let i=0; i<phonecontact.length; i+=1) {
        const phonevalid = phonecontact[i];
        if ((phonevalid >=0 && phonevalid <= 9) || (phonevalid === '+')) {
          phone = phone.trim() + phonevalid;
        }
      }
      formData.append('phone', phone);
    }
    if (info.image) {
      formData.append('image', info.image);
    }
    if (info.designation) {
      formData.append('designation', info.designation);
    }
    if (notifications) {
      formData.append('notification_preferences', JSON.stringify(notifications));
    }
    actionEdit(info.id, formData);
  }


  render() {
    const {
      validate,
      shouldShowErrors,
      notifications,
      data,
      image,
      invalidNumber,
      loadingStatus,
      showErrorModal } = this.state;
    const classes = [
      styles.form,
      validate && 'validated'
    ].filter(e => e);
    const { profile, currentPage, history } = this.props;
    const form = (
      <div>
        <form className={classes.join(' ')} onSubmit={this.handleSubmit} noValidate>
          <div className={styles.form}>
            <div className={styles.column}>
              <label htmlFor="firstname">
                <FormattedMessage id="settings.users.firstname" defaultMessage="First Name" />
                <input type="text" onChange={this.handleChange} name="first_name" required value={data.first_name} />
                <div className={styles.error}>
                  { shouldShowErrors && data.first_name === ''&&(
                    <FormattedMessage id="profile.form.invalidFirstname"
                      className="invalid-feedback" defaultText="Please provide a First Name"
                    />
                  )}
                </div>
              </label>
              <label htmlFor="lastName">
                <FormattedMessage id="settings.users.lastname" defaultMessage="Last Name" />
                <input type="text" onChange={this.handleChange} name="last_name" required value={data.last_name} />
                <div className={styles.error}>
                  { shouldShowErrors && !data.last_name && (
                  <span>
                    <FormattedMessage id="profile.form.invalidLastName"
                      className="invalid-feedback" defaultText="Please provide a Last Name"
                    />
                  </span>)
                  }
                </div>
              </label>
              <label htmlFor="phone">
                <FormattedMessage id="profile.phone" defaultMessage="Phone" />
                <ReactTelInput
                  type="text"
                  flagsImagePath={flags}
                  onChange={this.handleInputChange}
                  name="phone"
                  required value={data.phone}
                  defaultCountry="us"
                />
                { invalidNumber && !data.phone ? (
                  <div className={styles.error}>
                    <span>
                      <FormattedMessage id="profile.form.invalidPhoneEmpty"
                        className="invalid-feedback" defaultText="Please provide a Phone Number"
                      />
                    </span>
                  </div>) : null}
                { invalidNumber && data.phone ? (
                  <div className={styles.error}>
                    <span>
                      <FormattedMessage id="profile.form.invalidPhone"
                        className="invalid-feedback" defaultText="Please provide a valid Phone Number"
                      />
                    </span>
                  </div>) : null}
              </label>
              <label htmlFor="Designation">
                <FormattedMessage id="profile.designation" defaultMessage="Designation" />
                <input type="text" onChange={this.handleChange} name="designation" required value={data.designation} />
              </label>
              <label htmlFor="Notification Preferences">
                <FormattedMessage id="profile.notifications" defaultMessage="Notification Preferences" />
                <FormattedMessage id="profile.notifications.e-mail" defaultMessage="E-mail" />
                <Toggle
                  aria-label="E-mail"
                  name="email"
                  onChange={this.handleNotificationPreferences}
                  checked={notifications && notifications.email}
                />
                <FormattedMessage id="profile.notifications.sms" defaultMessage="SMS" />
                <Toggle
                  aria-label="SMS"
                  name="sms"
                  onChange={this.handleNotificationPreferences}
                  checked={notifications && notifications.sms}
                />
              </label>
              <Loading loadingStatus={loadingStatus} />
              <Button type="submit" onClick={this.handleEdit}
                translationID="profile.form.submit" defaultText="Save"
              />
            </div>
            <div className={styles.column}>
              <span className={styles.logo}>
                <FormattedMessage id="profile.logo.label" defaultMessage="Logo" />
              </span>
              <ImageUploader
                onChangeImage={this.handleDrop}
                image={image}
                profile={profile.first_name}
              />
            </div>
          </div>
        </form>
        <Modal
          onClose={() => this.setState({ showErrorModal: false })} showIn={showErrorModal} width="50%" minHeight="30%"
        >
          <div className={styles.modal}>
            <FormattedMessage id="profile.form.success" className={styles.success} defaultText="The information was successfully submitted" />
          </div>
          <div className={styles.btn}>
            <Button
              type="secondary"
              translationID="inspections.list.close"
              onClick={() => this.setState({ showErrorModal: false }, history.push('/profile'))}
              defaultText="Close"
            />
          </div>
        </Modal>
      </div>
    );
    return (
      <>
        { currentPage ? <div>{form}</div> :(
          <div>
            <Shortcuts links={this.links} />
            <div className={styles.list}>
              <SectionHeader icon={UserIcon} translationID="profile.title.edit" defaultTitle="Profile Edit" />
              <div className={`container ${styles.container}`}>
                <div className={styles.info}>
                  <div className={styles.header}>
                    <Link to="/profile"><FormattedMessage id="inspections.new.prev" defaultMessage="Back" /></Link>
                    {form}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

ProfileEdit.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  actionEdit: PropTypes.func.isRequired,
  actionFetchUserProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  action: state.profile.action,
  apiStatusUser: state.profile.apiStatusUser
});

const mapDispatchToProps = dispatch => ({
  // Fetch airport
  actionFetchUserProfile: () => {
    dispatch(fetchUserProfile());
  },
  actionEdit: (id, data) => {
    dispatch(editUser(id, data));
  },
  actionConfirm: (body, accept, cancel) => {
    dispatch(showConfirmModal(body, accept, cancel));
  },
  actionHideModal: (insp) => {
    dispatch(hideModal(insp));
  },
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  },
  // To clear Profile Reducer State
  actionClear: () => {
    dispatch(clearProfile());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ProfileEdit)));
