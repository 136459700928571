export const defaultMessage = {
  airportId: 'airportId',
  attachments: null,
  channelId: 'channelId',
  commentId: 'commentId',
  comments: null,
  content:
    "Keytar McSweeney's Williamsburg, Wes Anderson Banksy.",
  createdAt: 'default_createdAt',
  isLiked: null,
  likes: null,
  postId: 'default_postId',
  updatedAt: 'default_updatedAt',
  user: {
    firstName: 'firstName',
    lastName: 'lastName',
    profilePicture: null,
    isActive: null,
    roles: null,
    userId: 'default_userId',
    title: null,
    airportId: null,
    airportDetails: null
  },
  userId: 'default_userId',
  lastReplay: null,
  newMessage: true
};