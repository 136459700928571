import React, { useEffect, useRef } from 'react';

const Address = ({
  handleValueChange,
  handleFieldErrorChanged,
  isRequired,
  updateFieldErrors,
  fieldId,
  answer
}) => {
  let autoCompleteRef = useRef(null);

  const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const handleAnswerChanged = (value, id) => {
    handleValueChange(value, id);
    let updatedErrors = [];

    if (value === '' || value === undefined) {
      handleFieldErrorChanged(id, isRequired);
      updatedErrors = [
        ...updatedErrors,
        'pulpoforms.errors.not_blank'
      ];
    } else {
      handleFieldErrorChanged(id, false);
    }
    updateFieldErrors(updatedErrors);
  };

  async function handlePlaceSelect() {
    const addressObject = autoCompleteRef.getPlace();
    const address = addressObject.formatted_address;
    handleAnswerChanged(address, fieldId);
  }

  const handleScriptLoad = (autoCompleteParam) => {
    autoCompleteRef = new window.google.maps.places.Autocomplete(
      autoCompleteParam.current
    );
    autoCompleteRef.setFields(['address_components', 'formatted_address']);
    autoCompleteRef.addListener('place_changed', () => handlePlaceSelect());
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&libraries=places`,
      () => handleScriptLoad(autoCompleteRef)
    );
    if (isRequired && (answer === '' || answer === undefined)) {
      updateFieldErrors(['pulpoforms.errors.not_blank']);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      ref={autoCompleteRef}
      onChange={event => handleAnswerChanged(event.target.value, fieldId)}
      placeholder=""
      value={answer}
    />
  );
};

export default Address;
