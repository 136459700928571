import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../../components/button/button';
import styles from '../../../components/filters/filteritem.module.scss';
import Collapsible from '../../../components/collapsible/Collapsible';
import FilterItem from '../../../components/filters/FilterItem';
import { fetchLogFormSchema } from '../redux/actions';
import Clickable from '../../../components/clickable/Clickable';

const FilterForm = ({ handleOutsideClickFilter, update, types, subTypes,tags, actionFetchForm, cancel, filterCategories }) => {
  const [filters, setfilters] = useState([{ id: 1, name: 'Category', permissions: [] },
    { id: 2, name: 'Sub-Category', permissions: [] },{ id: 3, name: 'Tags', permissions: [] },
    { id: 4, name: 'Inspection Completed', subCategories: [{ id: 4, title: 'Inspection Completed' }] }]);
  const [selectedfilters, setselectedfilters] = useState([]);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      handleOutsideClickFilter();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    actionFetchForm();
  }, [actionFetchForm]);

  useEffect(() => {
    setselectedfilters([...filterCategories]);
  }, [filterCategories]);

  useEffect(() => {
    const ftypes = [];
    if (types) {
      types.forEach((k) => {
        ftypes.push({ id: k.id, title: k.name });
      });
    }
    if (filters) {
      filters.forEach(
        obj => (obj.id === 1 ? Object.assign(obj, { subCategories: ftypes }) : obj)
      );
    }
    setfilters(filters);
  }, [filters, types]);

  useEffect(() => {
    const subtypes = [];
    if (subTypes) {
      subTypes.forEach((s) => {
        subtypes.push({ id: s.id, title: s.name });
      });
    }
    if (filters) {
      filters.forEach(
        obj => (obj.id === 2 ? Object.assign(obj, { subCategories: subtypes }) : obj)
      );
    }
  }, [filters, subTypes]);

  useEffect(() => {
    const ftags = [];
    console.log(tags)
    if (tags) {
      tags.forEach((k) => {
        console.log(k)
        k.map(t => ftags.push({ title: t }))
      });
    }
    console.log(ftags)
    if (filters) {
      filters.forEach(
        obj => (obj.id === 3 ? Object.assign(obj, { subCategories: ftags }) : obj)
      );
    }
    setfilters(filters);
  }, [filters, tags]);

  const handleFilterChange = (name, event) => {
    const check = event.target.checked;
    const checkedValue = event.target.id;
    const filter = [...selectedfilters, checkedValue];
    if (check) {
      setselectedfilters(filter);
    } else {
      const filteredItems = selectedfilters.filter(i => i !== checkedValue);
      if (filteredItems.length > 0) {
        setselectedfilters(filteredItems);
      } else {
        setselectedfilters([]);
      }
    }
  };

  const handleClear = () => {
    setselectedfilters([]);
    const check_list = document.querySelectorAll('input[type=checkbox]');
    check_list.forEach((el) => { el.checked = false; });
  };

  return (
    <>
      <div className={styles.popup} ref={wrapperRef}>
        <div className={styles.header}>
          <span className={styles.filterText}>
            <FormattedMessage id="workorders.list.filters" defaultMessage="Filters " />
          </span>
          <Clickable onClick={handleClear} className={styles.cancel} style={{ marginTop: '20px', marginLeft: '70px' }}>
            <FormattedMessage id="filters.form.clear" defaultMessage=" Clear" />
          </Clickable>
          <Clickable onClick={cancel} className={styles.cancel} style={{ marginTop: '20px' }}>
            <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
          </Clickable>
          <Button
            action="secondary"
            translationID="workorder.filter.apply"
            defaultText="Apply"
            onClick={() => update(selectedfilters)}
          />
        </div>
        <div className={styles.list}>
          {filters && filters.map(e => (
            <div key={e.id}>
              <Collapsible title={e.name} styleClasses={styles.rol} autoheight={false} openOnMount={e.name === 'Category'}>
                <FilterItem
                  subCategories
                  id={e.id}
                  filter={e}
                  handleChange={(id, targetId) => handleFilterChange(id, targetId)}
                  filterCategories={selectedfilters}
                />
              </Collapsible>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};


FilterForm.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({})),
  subTypes: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.shape({}))
};
const mapStateToProps = state => ({
  types: state.opslogs.types,
  subTypes: state.opslogs.subtypes,
  tags: state.opslogs.tags
});

const mapDispatchToProps = dispatch => ({
  actionFetchForm: () => {
    dispatch(fetchLogFormSchema());
  }
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(FilterForm);

