import React from 'react';
import { Attachments } from '../../types';
import { ReactComponent as Bin } from '../../../../icons/bin.svg';

type PreviewOverlayProps = {
  setFilesToBlob: React.Dispatch<React.SetStateAction<Attachments[]>>;
  index: number;
};

const PreviewOverlay: React.FC<PreviewOverlayProps> = ({
  children,
  setFilesToBlob,
  index
}) => (
  <div className="image-preview">
    {children}
    <span className="delete-icon">
      <Bin
        onClick={() => setFilesToBlob(prevState => prevState.filter((_, i) => i !== index))
        }
      />
    </span>
  </div>
);

export default PreviewOverlay;
export { PreviewOverlay };
