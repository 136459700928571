import { CREATE_MAP, LOAD_MAP, CREATE_TYPE, LOAD_TYPE, UPDATE_MAP, REMOVE_MAP, CLEAR_SURFACE_ACTION
} from './types';

const BACKEND_API = 'BACKEND_API';

export const addSurface = surfaceData => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/surface_shapes/',
    data: surfaceData,
    ...CREATE_MAP
  }
});

export const editSurface = (id, surfaceData) => ({
  type: BACKEND_API,
  payload: {
    method: 'patch',
    url: `surface_shapes/${id}/`,
    data: surfaceData,
    ...UPDATE_MAP
  }
});

export const deleteSurface = id => ({
  type: BACKEND_API,
  payload: {
    method: 'delete',
    url: `/surface_shapes/${id}/`,
    ...REMOVE_MAP
  }
});

export const fetchSurfaces = withissues => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: `/surface_shapes/${withissues?'?withissues=':''}${withissues||''}`,
    ...LOAD_MAP
  }
});

export const addSurfaceType = surfaceData => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/surface_types/',
    data: surfaceData,
    ...CREATE_TYPE
  }
});

export const fetchSurfaceTypes = () => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: '/surface_types/',
    ...LOAD_TYPE
  }
});

export const clear = () => ({
  type: CLEAR_SURFACE_ACTION
});
