import React from 'react';

import './App.css';
import 'semantic-ui-css/semantic.min.css';

import { RouteComponentProps } from 'react-router-dom';
import { ChatNavigation } from './containers';
import { ChatHeader } from './components';


const Chat: React.FC<RouteComponentProps> = ({ history: { push, location } }) => (
  <>
    <ChatHeader push={push} location={location} />
    <ChatNavigation />
  </>
);
export default Chat;
