import { useState } from 'react';

import { useChanelState } from '../../state/channelsState';
import { GlobalDispatch } from '../../state/channelsState/types';

type Interface = {
  (): {
    handleClick: () => void;
    showMenu: boolean;
    dispatch: GlobalDispatch;
  };
};

export const useProfileState: Interface = () => {
  const chanelState = useChanelState();
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => {
    setShowMenu(prevState => !prevState);
  };

  return { dispatch: chanelState?.dispatch, showMenu, handleClick };
};
