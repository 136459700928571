import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import Button from '../../../button';
import styles from './ProfileEdit.module.scss';
import Auth from '../../../../utils/Auth';
import Shortcuts from '../../shortcuts/shortcuts';
import Collapsible from '../../../collapsible/Collapsible';
import { changeCurrentPage } from '../../../../general_redux/actions';

class ChangePasswordForm extends Component {
  links = [
    { url: '/', name: this.props.intl.formatMessage({ id: 'shortcuts.aerobot' }), key: 'aerobot' },
    { url: '/todo', name: this.props.intl.formatMessage({ id: 'shortcuts.todo' }), key: 'tasks' },
    { url: '/messenger', name: this.props.intl.formatMessage({ id: 'shortcuts.chat' }), key: 'messenger' },
    { url: '/settings/organization',
      name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }),
      permissions: ['can_modify_airport_settings'],
      key: 'settings' }
  ];

  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      oldPassword: '',
      passwordMatches: true,
      errors: {
        oldPassword: true,
        newPassword: true,
        confirmPassword: true
      },
      invalidOldPassword: false,
      invalidNewPassword: false
    };
  }

  componentDidMount() {
    const { actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('home');
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.setState(prevState => ({
      errors: Object.assign({}, prevState.errors, {
        [name]: true
      })
    })));
  }

  checkPasswordMatch = () => {
    const { newPassword, confirmPassword } = this.state;
    if (newPassword !== confirmPassword) {
      this.setState({
        passwordMatches: (newPassword === confirmPassword)
      });
    } else {
      this.setState({ passwordMatches: true });
    }
  }

  checkEmptyFields = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (oldPassword === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          oldPassword: false
        })
      }));
    } else if (newPassword === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          newPassword: false
        })
      }));
    } else if (confirmPassword === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          confirmPassword: false
        })
      }));
    }
  }

  handleErrors = () => {
    this.setState({ invalidOldPassword: true });
  }

  handleResetPassword = (e) => {
    e.preventDefault();
    const { oldPassword,
      newPassword,
      confirmPassword,
      passwordMatches } = this.state;
    const { profile, history } = this.props;

    this.checkPasswordMatch();
    if (!passwordMatches) {
      return;
    }

    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      this.checkEmptyFields();
      return;
    }

    const authDetails = new AuthenticationDetails({
      Username: profile.user.email,
      Password: oldPassword
    });

    const cognitoUser = new CognitoUser({
      Username: profile.user.email,
      Pool: Auth.getInstance().getUserPool()
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        Auth.getInstance().setAuthToken(result.getAccessToken().getJwtToken());
        Auth.getInstance().setIDToken(result.getIdToken().getJwtToken());
        axios.defaults.baseURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.location.hostname.indexOf('app.') === -1
          ? process.env.REACT_APP_BACKEND_HOST
          : `https://backend.${window.location.hostname}/api`;
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            this.setState({ invalidNewPassword: true });
            return;
          }
          history.push('/');
        });
      },
      onFailure: this.handleErrors
    });
  }

  render() {
    const { errors,
      invalidOldPassword,
      invalidNewPassword,
      newPassword,
      confirmPassword,
      passwordMatches } = this.state;
    return (
      <div>
        <Shortcuts links={this.links} />
        <form name="ChangePasswordForm" className={styles.form} onSubmit={this.handleResetPassword}>
          <div className={styles.form}>
            <div className={styles.column}>
              <label htmlFor="oldPassword">
                <FormattedMessage id="authentication.force_reset_password.old_password" defaultMessage="old password" />
                <input
                  type="password"
                  name="oldPassword"
                  onChange={this.handleChange}
                  onBlur={this.checkEmptyFields}
                />
              </label>
              {errors.oldPassword ? null : (
                <div className={styles.error}>
                  <small>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </small>
                </div>
              )}
              {invalidOldPassword ? (
                <div className={`${styles.error} ${styles.invalidPassword}`}>
                  <small>
                    <FormattedMessage id="authentication.login.error.invalid_password" defaultMessage="The password is invalid" />
                  </small>
                </div>
              ) : null}
              <label htmlFor="newPassword">
                <FormattedMessage id="authentication.force_reset_password.new_password" defaultMessage="New password" />
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={this.handleChange}
                  onBlur={this.checkEmptyFields}
                />
              </label>
              {errors.newPassword ? null : (
                <div className={styles.error}>
                  <small>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </small>
                </div>)
              }
              {invalidNewPassword ? (
                <div className={`${styles.error} ${styles.invalidPassword}`}>
                  <small>
                    <FormattedMessage id="authentication.force_reset_password.invalid_password" defaultMessage="The password is invalid" />
                  </small>
                </div>) : null}
              <label htmlFor="confirmPassword">
                <FormattedMessage id="authentication.force_reset_password.confirm_password" defaultMessage="Confirm password" />
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handleChange}
                  onBlur={this.checkPasswordMatch}
                />
              </label>
              {errors.confirmPassword ? null : (
                <div className={styles.error}>
                  <small>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </small>
                </div>)}
              {passwordMatches ? null : (
                <div className={styles.error}>
                  <small>
                    <FormattedMessage id="authentication.force_reset_password.password_mismatch"
                      defaultMessage="Password and confirmation do not match"
                    />
                  </small>
                </div>)}
              <Collapsible title="password_policy.title" styleClasses={styles.profile} autoheight={false}>
                <span>
                  <FormattedMessage id="password_policy.intro" defaultMessage="The password must contain all of the following characteristics" />
                </span>
                <ul>
                  <li>
                    <FormattedMessage id="password_policy.length" defaultMessage="At least 8 characters" />
                  </li>
                  <li>
                    <FormattedMessage id="password_policy.uppercase" defaultMessage="At least one uppercase character" />
                  </li>
                  <li>
                    <FormattedMessage id="password_policy.lowercase" defaultMessage="At least one lowercase character" />
                  </li>
                  <li>
                    <FormattedMessage id="password_policy.special_char" defaultMessage="At least one special character" />
                  </li>
                  <li>
                    <FormattedMessage id="password_policy.numbers" defaultMessage="At least one number" />
                  </li>
                </ul>
              </Collapsible>
              <br />
              <br />
              <Button type="submit" translationID="authentication.force_reset_password.header" defaultText="Reset Password" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  profile: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  profile: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChangePasswordForm));
