import React from 'react';
import { Link } from 'react-router-dom';
import { uiRouts } from '../../../../constants';
import { ChatTypes } from '../../enums';
import { ProfileWrapper } from './ActiveChatHeader.styles';

type EditOptionProps = {
  type: ChatTypes;
  editId: string;
};

const optionKey = {
  [ChatTypes.operations]: {
    state: 'channelId',
    pathname: uiRouts.editChannel,
      text: 'Edit Channel'
  },
  [ChatTypes.directMessages]: {
    state: 'roomId',
    // TODO create edit directMessages page
    pathname: '/messenger/direct-messages',
    text: 'Edit Direct Message'
  }
};

const EditOption: React.FC<EditOptionProps> = ({ editId, type }) => (
  <ProfileWrapper>
    <ul className="dropdown open">
      <li className="item">
        <Link
          to={{
            pathname: optionKey[type].pathname,
            state: {
              [optionKey[type]]: editId
            }
          }}
        >
            {optionKey[type].text}
        </Link>
      </li>
    </ul>
  </ProfileWrapper>
);

export default EditOption;
export { EditOption };
