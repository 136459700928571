import React from 'react';

import { Wrapper } from './Attachment.styles';
import { Attachments, AttachmentType } from '../../types';
import { DocumentCart } from '../DocumentCart';

type AttachmentProps = {
  attachments: Attachments[];
};

const Attachment = React.memo<AttachmentProps>(({ attachments }) => (
  <Wrapper>
    {attachments?.map((attachment) => {
      const { url, type } = attachment;
      if (type === AttachmentType.pdf) {
        return <DocumentCart {...attachment} key={url} />;
      }

      return (
        <img src={url} key={url} alt="post" className="post-image" />
      );
    })}
  </Wrapper>
));

export default Attachment;
export { Attachment };
