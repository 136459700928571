/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';

import Permissions from 'react-redux-permissions';
import Button from '../../../../components/button';
import ColorSelect from '../../../../components/colorSelect';
import Clickable from '../../../../components/clickable/Clickable';
import styles from '../assetBuilder.module.scss';
import dzstyles from '../../../workorders/Create/components/fixedFields.module.scss';
import PulpoField from '../../../../pulpo_visualizer/fields/PulpoField';
import FormattedMessageWithClass from '../../../../components/formattedMessageWithClass';

class AssetForm extends Component {
  handleDrop = (dropped) => {
    const { onInputChange, info } = this.props;
    const res = info.attachments || [];
    onInputChange([...res, ...dropped], 'attachments');
  }

  handleRemoveImage = (r) => {
    const { onInputChange, info } = this.props;
    const res = info.attachments.filter(e => e !== r);
    onInputChange(res, 'attachments', r.id);
  }

  render() {
    const {
      types,
      info,
      onInfoChange,
      onInputChange,
      onAnswerChange,
      onFieldErrorChanged,
      onSave,
      transition,
      shouldShowErrors,
      onDelete,
      onCancel,
      schemas,
      customFields,
      requiredMap,
      translation,
      spaceError,
      allDataSources } = this.props;
    return (
      <div className={styles.form} style={transition}>
        <div className={styles.head}>
          <Clickable onClick={onCancel} className={styles.cancel}>
            <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
          </Clickable>
          <div className={styles.actions}>
            <Permissions allowed={['add_asset']}>
              <Button translationID={info.id ? 'assets.save' : 'assets.create'}
                defaultText={info.id ? 'Save' : 'Create'}
                onClick={onSave} action="secondary"
              />
            </Permissions>
          </div>
        </div>
        <label className={styles.name}>
          <span className={styles.PaddingStyle}>
            <small style={{ color: 'red' }}>*</small>
            <FormattedMessage id="assets.label.name" defaultMessage="Name" />
          </span>
          <input type="text" id="name"
            placeholder={this.props.intl.formatMessage({ id: 'assets.label.name' })} value={info.name} className={styles.inputStyle}
            onChange={e => onInputChange(e.target.value, e.target.id)} autoComplete="off"
          />
          <br />
          {shouldShowErrors && !info.name && (
          <span className={styles.error}>
            <FormattedMessage id="assets.name.error"
              defaultMessage="Name cannot be empty"
            />
          </span>
          )}
          {spaceError && !info.name.trim() && (
            <span className={styles.error}>
              <FormattedMessage id="assets.name.space_error"
                defaultMessage="Spaces are not allowed"
              />
            </span>
          )}
        </label>
        <label className={styles.optionStyle}>
          <span className={styles.PaddingStyle} style={{ width: '100%' }}><FormattedMessage id="assets.label.type" defaultMessage="Type" /></span>
          <ColorSelect options={types} value={info.type} style={{ width: '100%' }}
            bordered onChange={onInfoChange}
          />
        </label>
        <br />
        <label>
          <span className={styles.labelArea}><FormattedMessage id="assets.label.area" defaultMessage="Area" /></span>
          {info.area && <span className={styles.area}>{info.area.name}</span>}
          {!info.area && (
          <input type="text" id="label" className={styles.inputStyle}
            onChange={e => onInputChange(e.target.value, e.target.id)}
            autoComplete="off" value={info.label}
          />
          )}
        </label>

        <div className={dzstyles.fullInput}>
          <FormattedMessageWithClass className={dzstyles.label}
            id="workorder.create.photos" defaultMessage="Photos"
          />
          <Dropzone onDrop={this.handleDrop} disableClick
            className={dzstyles.dropzone} accept="image/*"
          >
            {({ open }) => (
              <>
                <p className={`${dzstyles.browsetext} ${styles.browsetext}`}>
                  <FormattedMessage id="airport.logo.text1"
                    defaultMessage="Drag an image here or"
                  />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button"
                      defaultMessage="browse"
                    />
                  </button>
                  <FormattedMessage id="airport.logo.text2"
                    defaultMessage="for an image to upload."
                  />
                </p>
              </>
            )}
          </Dropzone>
          {info.attachments && info.attachments.length > 0 && (
          <div className={dzstyles.photos} key={info.id}>
            {info.attachments.map(e => (
              <div key={`${info.id}-${e.name || e.id}`} className={`${dzstyles.wrapper} ${styles.wrapper}`}>
                <Clickable onClick={() => this.handleRemoveImage(e)}>&times;</Clickable>
                <img src={e.preview || e.image} alt={e.name || e.id} />
              </div>
            ))}
          </div>
          )}
        </div>
        {schemas[info.type.category.toLowerCase()].schema.fields.map(field => (
          <PulpoField
            key={field.id}
            {...field}
            translation={translation && translation[field.title]}
            handleValueChange={(a, b) => onAnswerChange(a, b, 'customFields')}
            isRequired={requiredMap[field.id]} answer={customFields[field.id]}
            showFieldErrors={shouldShowErrors} handleFieldErrorChanged={onFieldErrorChanged}
            allDataSources={allDataSources}
          />

        ))}
        <div className={styles.deleteButton}>
          {info.id && (
            <Clickable onClick={onDelete} className={styles.delete}>
              <FormattedMessage id="assets.delete" defaultMessage="Delete" />
            </Clickable>
          )}
        </div>
      </div>
    );
  }
}


AssetForm.propTypes = {
  onInfoChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  info: PropTypes.shape({}).isRequired,
  schemas: PropTypes.shape({}).isRequired,
  answers: PropTypes.shape({}).isRequired,
  requiredMap: PropTypes.shape({}),
  onInputChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  transition: PropTypes.shape({}),
  shouldShowErrors: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldErrorChanged: PropTypes.func.isRequired
};

AssetForm.defaultProps = {
  shouldShowErrors: false,
  transition: undefined
};

export default injectIntl(AssetForm);
