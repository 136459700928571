import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

type UseRDetails = { userId: string; airportId: string; token: string };

export const useRDetails = (): UseRDetails => {
  const [userDetails, setUserDetails] = useState<UseRDetails>({
    token: null,
    userId: null,
    airportId: null
  });

  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : '';
  const userId: string = decodedToken === '' ? '' : decodedToken.username;
  const airportId: string = localStorage.getItem('airportId');

  useEffect(() => {
    setUserDetails({ token, userId, airportId });
  }, [airportId, token, userId]);

  return userDetails;
};
