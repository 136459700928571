import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';
import { FormattedMessage } from 'react-intl';
// Redux imports
import { connect } from 'react-redux';
import { fetchUserProfile } from '../profile/redux/actions';

import styles from './shortcuts.module.scss';

class Shortcuts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: props.links,
      airportPermissions: [],
      filteredShortcutLinks: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    // condition to check whether profile is loaded and airportPermission array is empty
    if (props.profile.id && !state.airportPermissions.length > 0) {
      return {
        airportPermissions: props.profile.airport_permissions.modules
      };
    }
    return state;
  }

  componentDidMount() {
    const { actionFetchUserProfile } = this.props;
    actionFetchUserProfile();
  }

  componentDidUpdate() {
    const { airportPermissions, filteredShortcutLinks } = this.state;
    if (airportPermissions && !filteredShortcutLinks.length > 0) {
      this.validateLinks();
    }
  }

  validateLinks = () => {
    const { airportPermissions, links } = this.state;
    const Data = [];
    links.forEach((link) => {
      const d = airportPermissions.filter(permission => (link.key === permission.key));
      if (d.length > 0) {
        if (d[0].enabled === 'true') {
          Data.push(link);
        }
      } else if (link.key!=='operations_dashboard') {
        Data.push(link);
      }
    });
    this.setState({ filteredShortcutLinks: Data });
  }

  render() {
    const { filteredShortcutLinks } = this.state;
    return (
      <div className={`${styles.shortcuts} main-header`}>
        {filteredShortcutLinks && filteredShortcutLinks.map(l => (
          // eslint-disable-next-line no-nested-ternary
          l.redirect && l.permissions ? (
            <Permissions key={l.name} allowed={[...l.permissions]}>
              <a href={l.url} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id={l.name} defaultMessage={l.key} />
              </a>
            </Permissions>
          ) : (
            l.permissions ? (

              <Permissions key={l.name} allowed={[...l.permissions]}>
                <Link key={l.name} to={l.url}>{l.name}</Link>
              </Permissions>
            )
              : <Link key={l.name} to={l.url}>{l.name}</Link>
          )
        ))}
      </div>
    );
  }
}
Shortcuts.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};


const mapStateToProps = state => ({
  profile: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionFetchUserProfile: () => {
    dispatch(fetchUserProfile());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shortcuts);
