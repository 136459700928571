import gql from 'graphql-tag';

const NEW_POST_COMMENT = gql`
  subscription onCreateComment(
    $channelId: String
    $iropsId: String
    $postId: String!
  ) {
    onCreateComment(channelId: $channelId, iropsId: $iropsId, postId: $postId) {
      airportId
      channelId
      commentId
      content
      createdAt
      iropsId
      likes
      parentCommentId
      postId
      updatedAt
      userId
      user {
        firstName
        lastName
        profilePicture
      }
    }
  }
`;
export default NEW_POST_COMMENT;
