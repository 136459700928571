import {
  FETCH_WILDLIFELOGS,
  ADD_WILDLIFELOGS,
  CLEAR_WILDLIFELOG,
  FETCH_WILDLIFELOGS_LIST,
  ADD_LOCATIONGRIDS,
  FETCH_LOCATIONGRIDS,
  ADD_LOCATIONGRIDIMAGE,
  FETCH_LOCATIONGRIDIMAGE,
  GET_WILDLIFELOG
} from './types';

const BACKEND_API = 'BACKEND_API';

export const fetchLogs = (page, start, end, query, filters) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/wildlife_logs/?
${page ? 'page=' : ''}${page || ''}
${start ? '&s=' : ''}${start || ''}
${end ? '&f=' : ''}${end || ''}
${query ? '&query=' : ''}${query || ''}
${filters ? '&filters=' : ''}${filters || ''}`,
    ...FETCH_WILDLIFELOGS
  }
});

export const createLog = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/wildlife_logs/',
    ...ADD_WILDLIFELOGS
  }
});

export const getLog = id => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/wildlife_logs/${id}`,
    ...GET_WILDLIFELOG
  }
});


export const createGrids = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/wildlife_logs/upload_grids/',
    ...ADD_LOCATIONGRIDS
  }
});

export const createGridImage = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/wildlife_logs/upload_grid_image/',
    ...ADD_LOCATIONGRIDIMAGE
  }
});

export const fetchLocationGrids = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/wildlife_logs/getLocationGrids',
    ...FETCH_LOCATIONGRIDS
  }
});

export const fetchLocationGridImage = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/wildlife_logs/getLocationGridImage',
    ...FETCH_LOCATIONGRIDIMAGE
  }
});

export const fetchMapLogList = (start, end) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/wildlife_logs/getMapList/?
${start ? 's=' : ''}${start || ''}
${end ? '&f=' : ''}${end || ''}`,
    ...FETCH_WILDLIFELOGS_LIST
  }
});

export const clearActionResult = () => ({
  type: CLEAR_WILDLIFELOG
});
