import { GraphQLError } from "graphql";
import { useCallback } from "react";
import { useMutation } from "../../../graphql/hooks";
import UPDATE_CHANNEL from "../mutations/updateChannel";
import { useRDetails } from "./useRDetails";

type UseUpdateChanel = {
  (): {
    error: GraphQLError;
    updateChannel: ({ ...rest }: any) => void;
    saving: boolean;
  };
};

export const useUpdateChanel: UseUpdateChanel = () => {
  const {
    error: closeError,
    mutate: closeGQL,
    saving: closeSaving
  } = useMutation(UPDATE_CHANNEL);
  const { userId } = useRDetails();

  const withIDs = useCallback(({ ...rest }) => {
    closeGQL({
      variables: {
        userId,
        ...rest
      }
    });
  }, [closeGQL, userId]);

  return {
    error: closeError,
    updateChannel: withIDs,
    saving: closeSaving
  };
};
