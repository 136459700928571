import { ApolloError } from 'apollo-client';
import { useMemo } from 'react';
import { useQuery } from '../../../graphql/hooks';
import LIST_CHATS from '../queries/listChats';
import { useRDetails } from './useRDetails';
import { ListChatArrType } from '../../types';

type ListChatsProps = {
  listChats: ListChatArrType;
};

type ListChatsVars = {
  userId?: string;
  fromUserId?: string;
  toUserId?: string;
  limit: number;
  airportId: string;
  nextToken: string;
};

type UseListChatsType = {
  (onCompleted: (data: ListChatsProps) => void): {
    listChats: ListChatArrType;
    error: ApolloError;
    loading: boolean;
    currentUser: { userId: string };
  };
};

export const useListChats: UseListChatsType = (onCompleted) => {
  const { userId, airportId } = useRDetails();

  const { data, error, loading } = useQuery<ListChatsProps, ListChatsVars>(
    LIST_CHATS,
    {
      variables: {
        userId,
        airportId,
        limit: 100,
        nextToken: null
      },
      onCompleted
    }
  );
  const currentUser = useMemo(() => ({ userId }), [userId]);

  return {
    listChats: data?.listChats || null,
    error,
    loading,
    currentUser
  };
};
