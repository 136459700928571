import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Multiselect } from 'multiselect-react-dropdown';
/** ******************************************************************
 *  Components import
 * ***************** */
import EditableText from './EditableText';

/** ******************************************************************
 *  Assets import
 * ***************** */
import styles from '../steps.module.scss';
import handle from '../../../../../icons/inspection-icons/Droper.svg';


const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

const shiftsOptions = ['Day', 'Afternoon', 'Night'];

const DragHandle = SortableHandle(() => <img className={styles.handle} src={handle} alt="" />);

const AccordionItem = ({ onItemClick, active, onAddEntry,
  onChangeEntry, item, onChangeChecklistName, intl, onSelectShifts, shifts }) => (
    <div className={`${styles.cheitem} ${active ? styles.active : ''}`}>
      <div className={styles.cheitemInner} role="button" tabIndex="0" onClick={onItemClick}
        onKeyDown={(e) => { if (e.keyCode === 13) onItemClick(e); }}
      >
        <DragHandle />
        <EditableText additionalStyles={{ topText: -40, left:34 }} ins inspections item={item} changeField={onChangeChecklistName} />
        <div className={styles.counter}>{`${item.checklist.length} ${intl.formatMessage({ id: 'inspections.step3.items' })}`}</div>
      </div>
      <Transition in={active} timeout={0} unmountOnExit>
        {state => (
          <ul className={styles.chelist} style={transitionStyles[state]}>
            {item.checklist.map(i => (
              <ChecklistItem key={i.key} itemId={item.id} desc={i} onChangeEntry={onChangeEntry} onSelectShifts={onSelectShifts} shifts={shifts} />
            ))}
            <li>
              <div className={styles.addNew}>
                <button type="button" onClick={() => onAddEntry(item.id)}>
                  <FormattedMessage id="inspections.step3.addNewItem" defaultMessage="Add a new item" />
                </button>
              </div>
            </li>
          </ul>
        )}
      </Transition>
    </div>
);

AccordionItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func.isRequired,
  onChangeEntry: PropTypes.func.isRequired,
  onChangeChecklistName: PropTypes.func.isRequired,
  active: PropTypes.bool,
  onSelectShifts: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.string)
};

AccordionItem.defaultProps = {
  active: false
};

export default SortableElement(injectIntl(AccordionItem));


const ChecklistItem = ({ itemId, desc, onChangeEntry, onSelectShifts, shifts }) => (
  <li className={styles.chItem}>
    <div className={styles.Item}>
      <EditableText
        inspections item={{ id: desc.key, title: desc.value }}
        changeField={(id, val) => onChangeEntry(itemId, id, val)} placeholder="with a placeholder" className={styles.editable}
      />
    </div>
    <div className={styles.Shifts}>
      <ShiftItems
        selectedValues={desc.shifts ? desc.shifts : []}
        onSelectShifts={event => onSelectShifts(itemId, desc.key, event)}
        onRemoveShifts={event => onSelectShifts(itemId, desc.key, event)}
        shifts={shifts}
      />
    </div>
  </li>
);

ChecklistItem.propTypes = {
  desc: PropTypes.shape({}).isRequired,
  itemId: PropTypes.string.isRequired,
  onChangeEntry: PropTypes.func.isRequired,
  onSelectShifts: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.string)
};

const ShiftItems = ({ onSelectShifts, selectedValues, shifts }) => (
  <Multiselect
    selectedValues={selectedValues}
    options={shifts ? setData(shifts) : shiftsOptions}
    onSelect={onSelectShifts}
    onRemove={onSelectShifts}
    displayValue="Shifts"
    closeIcon="cancel"
    placeholder={selectedValues.length >2 ? '' : 'Select Shift'}
    isObject={false}
    style={
      {
        option: {
          paddingTop: '.5%',
          color: '#3a61a8',
        },
        chips: { // To change css chips(Selected options)
          background: '#3a61a8'
        }
      }
    }
  />
);

ShiftItems.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onSelectShifts: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.string)
};

const setData = (shiftTypes) => {
  const shifts = shiftTypes.map(shift => shift.name);
  return shifts;
};
