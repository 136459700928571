import { ChatTypes } from "../../enums";

enum OperationsFields {
  id = "channelId",
  get = "getChannelPosts",
  set = "postList",
  update = "updatePost",
  dlt = "deletePost",
  deleteID = "postId"
}

enum CommentFields {
  id = "postId",
  get = "getPostComments",
  set = "commentsList",
  update = "updateComment",
  dlt = "deleteComment",
  deleteID = "commentId"
}

enum DirectMessagesFields {
  id = "messageId",
  get = "getMessages",
  set = "messageList",
  update = "updateMessage",
  dlt = "deleteMessage",
  deleteID = "messageId"
}

export const fields = {
  [ChatTypes.operations]: OperationsFields,
  [ChatTypes.comment]: CommentFields,
  [ChatTypes.directMessages]: DirectMessagesFields,
};
