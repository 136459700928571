import styled from 'styled-components';

type WrapperProps = {
    uploadFileMode: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  padding: ${({ uploadFileMode }) => (uploadFileMode ? 'unset' : '0 20px')};

  form {
    width: 100%;
    display: flex;
    .text-area {
      width: 100%;
      position: relative;
      padding-bottom: 1.3%;
      path {
        fill: #3a61a8;
      }
    }

    textarea {
      border: 1px solid ${({ theme }) => theme.colors.border.blue};
      font-size: 15px;
      ::placeholder {
        color: ${({ theme }) => theme.colors.text.light};
      }
    }

    .options {
      position: absolute;
      bottom: 8px;
      right: 0px;
      width: 100%;
      border: .1px solid ${({ theme }) => theme.colors.border.blue};
      display: flex;
      border-top-style: none;
      padding:6px 0px 6px 0px;
      flex-direction: row-reverse;
      transition: background-color .2s;
      svg {
        cursor: pointer;
      margin: 0 10px;
      }
    }
    .submit-btn {
      left: -25px;
      // top: 2px;
      cursor: pointer;
    }
  }
`;
