/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './scheduler.module.scss';
import ColorSelect from '../colorSelect';

const MonthYearSelector = ({ values, type, onSelect, intl, fullWidth }) => {
  const setposOptions = [
    { key: 1, name: intl.formatMessage({ id: 'schedule.frequency.First' }) },
    { key: 2, name: intl.formatMessage({ id: 'schedule.frequency.Second' }) },
    { key: 3, name: intl.formatMessage({ id: 'schedule.frequency.Third' }) },
    { key: 4, name: intl.formatMessage({ id: 'schedule.frequency.Fourth' }) },
    { key: -1, name: intl.formatMessage({ id: 'schedule.frequency.Last' }) }
  ];

  const bydayOptions = [
    { key: 'SU', name: intl.formatMessage({ id: 'schedule.frequency.Sunday' }) },
    { key: 'MO', name: intl.formatMessage({ id: 'schedule.frequency.Monday' }) },
    { key: 'TU', name: intl.formatMessage({ id: 'schedule.frequency.Tuesday' }) },
    { key: 'WE', name: intl.formatMessage({ id: 'schedule.frequency.Wednesday' }) },
    { key: 'TH', name: intl.formatMessage({ id: 'schedule.frequency.Thursday' }) },
    { key: 'FR', name: intl.formatMessage({ id: 'schedule.frequency.Friday' }) },
    { key: 'SA', name: intl.formatMessage({ id: 'schedule.frequency.Saturday' }) },
    { key: 'SU,MO,TU,WE,TH,FR,SA', name: intl.formatMessage({ id: 'schedule.frequency.Day' }) },
    { key: 'MO,TU,WE,TH,FR', name: intl.formatMessage({ id: 'schedule.frequency.Weekday' }) },
    { key: 'SU,SA', name: intl.formatMessage({ id: 'schedule.frequency.Weekend day' }) }
  ];

  const bymonthOptions = [
    { key: 1, name: intl.formatMessage({ id: 'schedule.frequency.Jan' }) }, { key: 2, name: intl.formatMessage({ id: 'schedule.frequency.Feb' }) }, { key: 3, name: intl.formatMessage({ id: 'schedule.frequency.Mar' }) },
    { key: 4, name: intl.formatMessage({ id: 'schedule.frequency.Apr' }) }, { key: 5, name: intl.formatMessage({ id: 'schedule.frequency.May' }) }, { key: 6, name: intl.formatMessage({ id: 'schedule.frequency.Jun' }) },
    { key: 7, name: intl.formatMessage({ id: 'schedule.frequency.Jul' }) }, { key: 8, name: intl.formatMessage({ id: 'schedule.frequency.Aug' }) }, { key: 9, name: intl.formatMessage({ id: 'schedule.frequency.Sep' }) },
    { key: 10, name: intl.formatMessage({ id: 'schedule.frequency.Oct' }) }, { key: 11, name: intl.formatMessage({ id: 'schedule.frequency.Nov' }) }, { key: 12, name: intl.formatMessage({ id: 'schedule.frequency.Dec' }) }
  ];

  // hooks
  const [option, setOption] = useState('day');
  const [setpos, updateSetpos] = useState(setposOptions[0]);
  const [byMonthDay, setByMonthDay] = useState(1);
  const [byday, setByday] = useState(bydayOptions[0]);
  const [bymonth, setBymonth] = useState(bymonthOptions[0]);

  useEffect(() => {
    if (values) {
      if (values.monthDay) {
        setOption('day'); setByMonthDay(values.monthDay);
      } else {
        setOption('on_the');
      }
      if (values.days) {
        const selectedDay = Object.keys(values.days).find(d => values.days[d] === true);
        setByday(bydayOptions.find(op => op.key === selectedDay));
      }
      if (values.setpos) {
        updateSetpos(setposOptions.find(op => op.key === parseInt(values.setpos, 10)));
      }
      if (values.month) {
        setBymonth(bymonthOptions.find(op => op.key === parseInt(values.month, 10)));
      }
    }
  }, []);

  useEffect(() => {
    let result = '';
    if (option === 'day') {
      if (byMonthDay) result += `BYMONTHDAY:${byMonthDay};`;
    } else {
      if (setpos) result += `BYSETPOS:${setpos.key};`;
      if (byday) result += `BYWEEKDAY:${byday.key};`;
    }
    if (type === 'YEARLY' && bymonth) result += `BYMONTH:${bymonth.key};`;
    onSelect(result);
  });
  return (
    <div className={`${fullWidth ? styles.fullWidth: ''}`}>
    <div className={styles.month}>
      <div className={styles.selector}>
        <label>
          <input type="radio" name="day" value="day"
            checked={option === 'day'} onChange={e => setOption(e.target.value)}
          />
          <FormattedMessage id="schedule.repeat.On day" defaultMessage="On day" />
        </label>
        <label>
          <input type="radio" name="on_the" value="on_the"
            checked={option === 'on_the'} onChange={e => setOption(e.target.value)}
          />
          <FormattedMessage id="schedule.repeat.On the" defaultMessage="On the" />
        </label>
      </div>
      {option === 'day' && (
        <label className={styles.daysCombos}>
          <input type="number" min="1" max="31" value={byMonthDay}
            onChange={e => setByMonthDay(e.target.value)}
          />
          {type === 'YEARLY' && (
          <ColorSelect value={bymonth} options={bymonthOptions}
            onChange={setBymonth} bordered fullWidth={fullWidth ? true : undefined} 
          />
          )}
        </label>
      )}
      {option === 'on_the' && (
        <div className={styles.daysCombos}>
          <ColorSelect value={setpos} options={setposOptions}
            onChange={updateSetpos} bordered fullWidth={fullWidth ? true : undefined}
          />
          <ColorSelect value={byday} options={bydayOptions}
            onChange={setByday} bordered fullWidth={fullWidth ? true : undefined}
          />
          {type === 'YEARLY' && (
          <ColorSelect value={bymonth} options={bymonthOptions}
            onChange={setBymonth} bordered fullWidth={fullWidth ? true : undefined}
          />
          )}
        </div>
      )}
    </div>
    </div>
  );
};

MonthYearSelector.propTypes = {
  onSelect: PropTypes.func.isRequired
};
export default injectIntl(MonthYearSelector);
