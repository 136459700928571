import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './fields.module.scss';
import { fetchDataSoureValues, clearDataSourceValues } from '../../modules/inspections/redux/actions';

class PulpoField extends Component {
  constructor(props) {
    super(props);

    const { type: fieldType } = props;

    /* eslint-disable-next-line */
    let component = require(`./${fieldType}`);
    if (component.default) {
      component = component.default;
    }
    this.component = component;
    this.state = { errors: [], dataSourceValues: undefined };

    this.updateFieldErrors = this.updateFieldErrors.bind(this);
  }

  componentDidMount() {
    const { actionDataSource, allDataSources, dataSourceID, type } = this.props;
    if (type === 'dataSource' || type === 'dataSourceMultiselect') {
      const source = allDataSources.filter(e => (e.name === dataSourceID));
      if (source.length > 0) {
        actionDataSource(source[0].metadata.method, undefined, source[0].source_url);
      }
    }
  }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.dataSourceValues && props.dataSourceValues && props.dataSourceValues.length >= 0) {
      return { ...state, dataSourceValues: props.dataSourceValues.map(e => ({ key: e.name, value: e.name })) };
    }
    return state;
  }

  updateFieldErrors(errors) {
    this.setState({ errors });
  }

  render() {
    const {
      id: fieldId,
      type: fieldType,
      title,
      isRequired,
      showFieldErrors,
      className,
      translationID,
      translation,
      dataSourceID,
      ...fieldProps
    } = this.props;
    const { errors, dataSourceValues } = this.state;
    return (
      <div className={`${styles.field} ${className}`}>
        <span className={styles.title}>
          { isRequired && <small style={{ color: 'red' }}> * </small> }
          {translation || <FormattedMessage id={translationID} defaultMessage={title} />}
        </span>
        { (fieldType !== 'dataSource' && fieldType !== 'dataSourceMultiselect') && React.createElement(
          this.component,
          {
            ...fieldProps,
            fieldId,
            fieldType,
            isRequired,
            updateFieldErrors: this.updateFieldErrors
          }, null
        )}
        { (fieldType === 'dataSource' || fieldType === 'dataSourceMultiselect') && dataSourceValues && React.createElement(
          this.component,
          {
            ...fieldProps,
            fieldId,
            fieldType,
            isRequired,
            values: dataSourceValues,
            updateFieldErrors: this.updateFieldErrors
          }, null
        )}
        {showFieldErrors && errors.map(e => (
          <small key={e}>
            <FormattedMessage id={e} defaultMessage="There is an error in this field" />
          </small>
        ))}
      </div>
    );
  }
}


PulpoField.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  handleValueChange: PropTypes.func.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  showFieldErrors: PropTypes.bool.isRequired,
  className: PropTypes.string,
  translationID: PropTypes.string
};

PulpoField.defaultProps = {
  className: '',
  translationID: 'invalid',
  isRequired: false
};

const mapStateToProps = state => ({
  dataSourceValues: state.inspection.dataSourceValues
});

const mapDispatchToProps = dispatch => ({
  actionDataSource: (methodName, data, sourceURL) => {
    dispatch(fetchDataSoureValues(methodName, data, sourceURL));
  },
  actionClear: () => {
    dispatch(clearDataSourceValues());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PulpoField);
