import AWS from 'aws-sdk';
import Auth from '../../utils/Auth';
import { toast } from 'react-toastify';

class S3Uploads {
  /*
   * Upload files to S3 bucket
   */

  async getAwsConfig() {
    return new Promise(function (resolve, reject) {
        AWS.config.getCredentials(async function(err) {
            if (err) {
                toast.error(err && err.message);
                return reject(undefined);
            } else {
              return resolve(AWS.config.credentials);
            }
        });
    });
  }

  async s3uploadFile (s3, params) {
    return new Promise((resolve, reject) => {
      s3.putObject(params, async function(err, data) {
        if (err) {
          toast.error(err && err.message);
          return reject(undefined);
        } else {
          return resolve(data)
        }
      });
    });
   }

  uploadFiles = async (files, fileIds, entity) => {
    try {
      const sub = localStorage.getItem('userId');
      const key = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;

      AWS.config.update({
        region: process.env.REACT_APP_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          Logins: {
            [key]: Auth.getInstance().getIDToken()
          }
        })
      });
      let response = [];
      const credentials = await this.getAwsConfig();
      if(credentials) {
        const s3 = new AWS.S3({
          apiVersion: "2006-03-01",
          accessKeyId: AWS.config.credentials.accessKeyId,
          secretAccessKey: AWS.config.credentials.secretAccessKey,
          sessionToken: AWS.config.credentials.sessionToken
        });
        for (var index = 0; index < files.length; index++) {
          let fileName = 'private/' + sub + '/' + fileIds[index]; 
          let file = {};
          let fileSize = this.getAeroFileSize(files[index].size);
          const params = {
              Bucket: `${process.env.REACT_APP_S3PREFIX}uploads`,
              Key: fileName,
              Body: files[index],
              ContentType : files[index].type,
              Metadata: {
                'name': files[index].name,
                'size': files[index].size.toString(),
                'id': fileIds[index],
                'entity': entity
              }
          }
          const up = await this.s3uploadFile(s3, params);
          if(up) {
            file['name'] = files[index].name;
            file['size'] = fileSize;
            file['entity'] = entity;
            file['id'] = fileIds[index];
            response.push(file);
          }            
        }
        return response;
      } else {
        return response;
      }
    } catch (err) {
      toast.error(err?.response?.data.message || err.message);
      return null;
    }
  };

  /*
   * Get file size in KB or MB
   */
  getAeroFileSize = (filesize) => {
    if (filesize > 1024){
      return (filesize / 1024 / 1024).toFixed(2) + ' KB';
    } else {
      return (filesize / 1024).toFixed(2) + ' KB';
    }
  };

}

const Service = new S3Uploads();

export default Service;
export { Service as S3Uploads };
