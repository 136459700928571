import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react';
import { ChatTypes } from '../../enums';
import { useDirectMessages, useListChats } from '../../graphql/hooks';
import { ListChatArrType } from '../../types';
import { useCreateSubscriptionUpdater } from '../common';
import { directMessagesActions } from './actions';
import { SetDirectMessageListProps, DeleteDMProps } from './directMessages/types';
import { globalState, GlobalState } from './globalState';
import { reducer } from './reducers';
import { GlobalDispatch } from './types';

type ContextValue = {
  state: GlobalState;
  dispatch: GlobalDispatch;
};

export const StateContext = createContext<ContextValue>(null);

type OnCreateMessageArgs = {
  // fromUserId: string;
  // toUserId: string;
  roomId: string;
};

export const StateProvider: React.FC = ({ children }) => {
  const [{ directMessages }, dispatch] = useReducer(reducer, globalState);

  const {
    subscribe: messageSubscriptionUpdater
  } = useCreateSubscriptionUpdater(dispatch);

  const dMActionsCb = useMemo(
    () => ({
      setListAction: (directMessageList: SetDirectMessageListProps) => directMessagesActions.setDirectMessageList(dispatch, directMessageList),
      deleteAction: (messageId: DeleteDMProps) => directMessagesActions.deleteMessage(dispatch, messageId)
    }),
    []
  );

  const {
    createMessages,
    subscribeTo,
    loading: loadingDM,
    fetchMore,
    updateMessage,
    deleteMessage
  } = useDirectMessages({
    roomId: directMessages?.activeChat?.chatId,
    dMActionsCb
  });

  const onCompleted = useCallback((data: { listChats: ListChatArrType }) => {
    if (!data) {
      return;
    }
    const { listChats } = data;
    if (listChats?.items.length) {
      const { roomId: currentRoomId, fromUser } = listChats.items[0];
      const { lastName, firstName } = fromUser;
      directMessagesActions.setActiveChat(dispatch, {
        chatName: `${firstName} ${lastName}`,
        chatId: currentRoomId,
        listChats
      });

      const varsArr = listChats.items.map(({ roomId }) => ({
        roomId
      }));

      directMessagesActions.setTopicsCollection(dispatch, {
        topicsCollectionCount: varsArr.length
      });

      varsArr.forEach((variables) => {
        messageSubscriptionUpdater<OnCreateMessageArgs>({
          type: ChatTypes.directMessages,
          subscribeTo,
          variables
        });
      });
    }
  }, [messageSubscriptionUpdater, subscribeTo]);

  const { currentUser, loading: listChatsLoading } = useListChats(onCompleted);

  const contextValue = useMemo(
    () => ({
      state: {
        directMessages,
        currentUser,
        createMessages,
        listChatsLoading,
        loadingDM,
        fetchMore,
        updateMessage,
        deleteMessage
      },
      dispatch
    }),
    [
      directMessages,
      currentUser,
      createMessages,
      listChatsLoading,
      loadingDM,
      fetchMore,
      updateMessage,
      deleteMessage
    ]
  );

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export const useDirectMessagesState = () => useContext<ContextValue>(StateContext);
