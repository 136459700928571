/* global localStorage */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { createStaticRanges } from 'react-date-range/src/defaultRanges';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';
import * as rdrLocales from 'react-date-range/dist/locale';
import { FormattedMessage, injectIntl } from 'react-intl';
import Collapsible from '../../../../components/collapsible/Collapsible';
import Button from '../../../../components/button';
import Separator from '../../../../components/separator';
import styles from './filter.module.scss';
import { formatDate } from '../../../inspections/List/components/Filter/Filter';
import Clickable from '../../../../components/clickable/Clickable';
import Select from "../../../../components/select";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLast30Days: addDays(new Date(), -29),
  endOfLast30Days: endOfDay(new Date())
};

const Filter = ({ dates, onDateChange, intl, listSummary, handleDropdown, groupByFilterOptions, handleChangeGroupBy}) => {
  // Hooks
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(dates[0]),
    endDate: new Date(dates[1]),
    key: 'selection'
  });

  const [title, setTitle] = useState('Last 30 days');
  const [selectedGroupBy , setSelectedGroupBy] = useState(groupByFilterOptions[0])
  const child = useRef(null);
  useEffect(() => {
    let theTitle = localStorage.getItem('log_range');
    if (!theTitle) {
      if (dates[0].format('MMMM DD, YYYY') === dates[1].format('MMMM DD, YYYY')) {
        theTitle = `${dates[0].format('MMMM DD, YYYY')}`;
      } else {
        theTitle = `${dates[0].format('MMMM DD, YYYY')} -  ${dates[1].format('MMMM DD, YYYY')}`;
      }
    }
    setTitle(theTitle);
  }, [dates]);

  const equalDates = (d1, d2) => (
    d1.getFullYear() === d2.getFullYear()
    && d1.getDate() === d2.getDate()
    && d1.getMonth() === d2.getMonth()
  );

  const applyFilters = () => {
    const therange = document.getElementsByClassName('rdrStaticRange rdrStaticRangeSelected')[0];
    let rangeName;

    if (therange) {
      rangeName = therange.childNodes[0].textContent;
    } else {
      const sd = formatDate(selectionRange.startDate);
      const ed = formatDate(selectionRange.endDate);
      if (equalDates(selectionRange.startDate, selectionRange.endDate)) {
        rangeName = sd;
      } else {
        rangeName = `${sd} -  ${ed}`;
      }
    }

    setTitle(rangeName);

    onDateChange(
      moment(selectionRange.startDate),
      moment(selectionRange.endDate),
      therange ? therange.childNodes[0].textContent : undefined
    );
    child.current.onItemClick();
  };

  const handleDateChange = (ranges) => {
    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: 'selection'
    });
  };
  const language = localStorage.getItem('lang') ||'en';
  const staticRanges = createStaticRanges([

    {
      label: intl.formatMessage({ id: 'todo.today' }),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.Yesterday' }),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },

    {
      label: intl.formatMessage({ id: 'todo.thisWeek' }),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.LastWeek' }),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.ThisMonth' }),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.LastMonth' }),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.ThisYear' }),
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear
      })
    },
    {
      label: intl.formatMessage({ id: 'todo.lastThirtyDays' }),
      range: () => ({
        startDate: defineds.startOfLast30Days,
        endDate: defineds.endOfLast30Days
      })
    }
  ]);
  const inputRanges=[
    {
      label: intl.formatMessage({ id: 'todo.days up to today' }),
      range(value) {
        return {
          startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
          endDate: defineds.endOfToday
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
        if (!range.startDate) return '∞';
        return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
      }
    },
    {
      label: intl.formatMessage({ id: 'todo.days starting today' }),
      range(value) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1)
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
        if (!range.endDate) return '∞';
        return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
      }
    }
  ];


  const handleGroupBy = (e) => {
    setSelectedGroupBy(e)
    handleChangeGroupBy(e);
  }

  return (
    <div style={{ display: 'flex' }} className={styles.toolbar}>
       <div className={styles.filter}>
       <div className={styles.leftFilters}>
          <div className={styles.counter}>
            <div className={styles.count}>
              <FormattedMessage id="operations.loglist.count" defaultMessage="All Logs " />
              <span>
                (
                {listSummary}
                )
              </span>
            </div>
          </div>
          {groupByFilterOptions &&
          <div className={styles.groupByFilter}>
            <label>Group by:</label>
            <div className={styles.test}>
              <Select
                  minWidth
                  value={selectedGroupBy}
                  options={groupByFilterOptions}
                  onChange={e=>handleGroupBy(e)}
              />
            </div>
          </div>
          }
        </div>
       <div className={styles.right}>
            <Collapsible ref={child} styleClasses={styles.dateHeader} title={title} dateFilter>
              <div className={styles.pickerWrapper}>
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleDateChange}
                    rangeColors={['rgba(58, 97, 168, 0.67)']}
                    color="rgba(58, 97, 168, 0.67)"
                    inputRanges={inputRanges}
                    locale={rdrLocales[language]}
                    staticRanges={staticRanges}
                />
                <Button onClick={applyFilters}
                        translationID="inspections.filter.apply"
                        defaultText="Apply"
                        action="secondary"
                />
              </div>
            </Collapsible>
            <Separator />
            <Clickable className={styles.actionsBtn} onClick={() => handleDropdown()}>
              <FormattedMessage id="workorders.list.filters" defaultMessage="Filters " />
            </Clickable>
          </div>
       </div>
    </div>
  );
};

Filter.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default injectIntl(Filter);
