import {
  FETCH_WILDLIFELOGS,
  ADD_WILDLIFELOGS,
  CLEAR_WILDLIFELOG,
  FETCH_WILDLIFELOGS_LIST,
  ADD_LOCATIONGRIDS,
  FETCH_LOCATIONGRIDS,
  ADD_LOCATIONGRIDIMAGE,
  FETCH_LOCATIONGRIDIMAGE,
  GET_WILDLIFELOG
} from './types';

import { actionForState } from '../../../utils/helpers';

const INITIAL_STATE = {
  error: {},
  action: {
    loading: false,
    success: undefined
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_WILDLIFELOGS.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_WILDLIFELOGS.success:
      return {
        ...state,
        loglist: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_WILDLIFELOGS.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_WILDLIFELOGS_LIST.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_WILDLIFELOGS_LIST.success:
      return {
        ...state,
        mapList: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_WILDLIFELOGS_LIST.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_WILDLIFELOGS.pending:
      return {
        ...state,
        LogCreateAction: actionForState(state.action, 'pending')
      };
    case ADD_WILDLIFELOGS.success:
      return {
        ...state,
        LogCreateAction: actionForState(state.action, 'success')
      };
    case ADD_WILDLIFELOGS.error:
      return {
        ...state,
        LogCreateAction: actionForState(state.action, 'error')
      };
    case ADD_LOCATIONGRIDS.pending:
      return {
        ...state,
        gridAction: actionForState(state.action, 'pending')
      };
    case ADD_LOCATIONGRIDS.success:
      return {
        ...state,
        gridAction: actionForState(state.action, 'success')
      };
    case ADD_LOCATIONGRIDS.error:
      return {
        ...state,
        gridAction: actionForState(state.action, 'error')
      };
    case ADD_LOCATIONGRIDIMAGE.pending:
      return {
        ...state,
        gridImageAction: actionForState(state.action, 'pending')
      };
    case ADD_LOCATIONGRIDIMAGE.success:
      return {
        ...state,
        gridImageAction: actionForState(state.action, 'success')
      };
    case ADD_LOCATIONGRIDIMAGE.error:
      return {
        ...state,
        gridImageAction: actionForState(state.action, 'error')
      };
    case FETCH_LOCATIONGRIDS.pending:
      return {
        ...state,
        gridAction: actionForState(state.action, 'pending')
      };
    case FETCH_LOCATIONGRIDS.success:
      return {
        ...state,
        gridList: action.payload,
        gridAction: actionForState(state.action, 'success')
      };
    case FETCH_LOCATIONGRIDS.error:
      return {
        ...state,
        gridAction: actionForState(state.action, 'error')
      };
    case FETCH_LOCATIONGRIDIMAGE.pending:
      return {
        ...state,
        gridAction: actionForState(state.action, 'pending')
      };
    case FETCH_LOCATIONGRIDIMAGE.success:
      return {
        ...state,
        gridMapImage: action.payload,
        gridAction: actionForState(state.action, 'success')
      };
    case FETCH_LOCATIONGRIDIMAGE.error:
      return {
        ...state,
        gridAction: actionForState(state.action, 'error')
      };
    case GET_WILDLIFELOG.success:
      return {
        ...state,
        log: action.payload,
        logAction: actionForState(state.action, 'success')
      };
    case CLEAR_WILDLIFELOG:
      return {
        ...state,
        LogCreateAction: {},
        gridAction: {},
        loglist: undefined,
        summary: undefined,
        gridList: undefined,
        mapList: undefined,
        log: undefined
      };
    default:
      return state;
  }
}
