/* eslint-disable camelcase */
/* global localStorage */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Permissions from 'react-redux-permissions';
import AmazonDateParser from 'amazon-date-parser';
import { Transition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import Modal from '../../../components/modal';
import { getDatesFromRange, getShiftTypeByDate } from '../../../utils/helpers';
import { fetchInspectionAnswers, fetchsummary, clearInspectionOnly } from '../redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';
import { fetchUserProfile } from '../../../components/topbar/profile/redux/actions';
/** ******************************************************************
 *  Components import
 * ***************** */

import Button from '../../../components/button';
import IconButton from '../../../components/iconButton';
import SectionHeader from '../../../components/sectionHeader';
// import Filter from './components/Filter/Filter';
import Table from './components/Table/Table';
import Clickable from '../../../components/clickable/Clickable';

/** ******************************************************************
 *  Assets import
 * ************* */

import settings from '../../../icons/settings.svg';
// import search from '../../../icons/search.svg';
import Search from '../../../components/search/Search';
import preset from '../../../icons/Inspections.png';

import styles from '../inspections.module.scss';
// import styles from '../../workorders/List/workOrderList.module.scss';
import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';
import Loading from '../../../components/loading';
import FilterForm from './components/Filter/FilterForm';
import Filter from './components/Filter/Filter';
import { uiRouts } from '../../../constants';
// import { getShiftTypeByDate } from '../../../../src/utils/helpers';

class InspectionList extends Component {
  constructor(props) {
    super(props);
    let startDate = localStorage.getItem('startdate') ? localStorage.getItem('startdate'): localStorage.getItem('start') || new Date().setDate(new Date().getDate() - 29)
    let endDate = localStorage.getItem('enddate')? localStorage.getItem('enddate'):localStorage.getItem('end') || new Date()
    this.state = {
      date1: undefined,
      date2: undefined,
      filter: undefined,
      showModal: false,
      loadingStatus: false,
      filterPopup: false,
      searchQuery: '',
      filterCategories: [],
      filterInspections: [],
      filterShifts: [],
      filterFields:[],
      selectionRange: {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      },
      range: localStorage.getItem('range'),
      filterGroupBy: { key:0 , name:"Shift" }
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.inspection_list.loading && state.loadingStatus) {
      return {
        ...state,
        loadingStatus: false
      };
    }
    if (!props.inspection_list.success && state.loadingStatus && props.inspection_list.message === 'failed') {
      return {
        ...state,
        loadingStatus: false
      };
    }
    return state;
  }

  async componentDidMount() {
    const { actionFetchSummary, actionFetch, actionfetchProfile, answers, actionFetchShiftTypes, shiftTypes } = this.props;
    const { profile, history: { location: { state } } } = this.props;
    if (profile.id===''||profile.id===' ') await actionfetchProfile();
    if (!shiftTypes.length) actionFetchShiftTypes();
    actionFetchSummary();
    if (state && state.intent === 'ViewInspections') {
      const dates = AmazonDateParser(state.slots.date);
      this.handleDateChange(dates.startDate, dates.endDate);
    } else {
      const range = localStorage.getItem('range');
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      const end = new Date(start.getTime());
      end.setHours(23, 59, 59, 999);
      let date1 = start.toISOString();
      let date2 = end.toISOString();
      if (range) {
        [date1, date2] = getDatesFromRange(range);
        date1 = date1.toISOString();
        date2 = date2.toISOString();
      } else {
        const s = localStorage.getItem('start_date') || localStorage.getItem('startdate');
        const f = localStorage.getItem('end_date') || localStorage.getItem('enddate');
        if (s && f) {
          date1 = s;
          date2 = f;
        } else {
          date1= new Date().setDate(new Date().getDate() - 29);
          date2= new Date();
        }
        date1 = new Date(date1);
        date2 = new Date(date2);
        date1.setHours(0, 0, 0, 0);
        date2.setHours(23, 59, 59, 999);
        date1 = date1.toISOString();
        date2 = date2.toISOString();
      }

      this.setState({ date1, date2, range });
      let categories_filter;
      const selected_category_filter = [];
      const selected_isues_filter = [];
      const selected_shifts_filter = [];
      const selected_fields_filter = [];
      if (localStorage.getItem('inspection_filter')) {
        categories_filter = localStorage.getItem('inspection_filter');
        let filter = categories_filter;
        if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
        filter = filter.split(',');
        selected_category_filter.push(...filter);
        this.setState({ filterInspections: filter });
      }
      let shifts_filter;
      if (localStorage.getItem('inspection_shifts')) {
        shifts_filter = localStorage.getItem('inspection_shifts');
        let filter = shifts_filter;
        if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
        filter = filter.split(',');
        selected_shifts_filter.push(...filter);
        this.setState({ filterShifts: filter });
      }
      let issues_filter;
      if (localStorage.getItem('inspection_issues_filter')) {
        issues_filter = localStorage.getItem('inspection_issues_filter');
        let filter = issues_filter;
        if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
        filter = filter.split(',');
        selected_isues_filter.push(...filter);
      }
      let custom_fields_filter;
      if (localStorage.getItem('inspection_custom_fields')) {
        custom_fields_filter = localStorage.getItem('inspection_custom_fields');
        let filter = custom_fields_filter;
        if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
        filter = filter.split(',');
        selected_fields_filter.push(...filter);
        this.setState({ filterFields: filter });
      }
      if (localStorage.getItem('start_date') || localStorage.getItem('range')) {
          const startdate = localStorage.getItem('start')!=null?localStorage.getItem('start'):date1;
          const enddate = localStorage.getItem('end')!=null?localStorage.getItem('end'):date2;
          actionFetch(startdate, enddate, undefined, issues_filter, categories_filter, shifts_filter, custom_fields_filter);
      } else if (categories_filter || issues_filter || shifts_filter || custom_fields_filter) {
        const sd = localStorage.getItem('start')!=null?localStorage.getItem('start'):date1;
        const fd = localStorage.getItem('end')!=null?localStorage.getItem('end'):date2;
        actionFetch(sd, fd, undefined, issues_filter, categories_filter, shifts_filter, custom_fields_filter);
      } else {
        actionFetch(date1, date2);
      }
      this.setState({
        filterCategories: [...selected_category_filter, ...selected_isues_filter, ...selected_shifts_filter, ...selected_fields_filter] });
      if (answers.length === 0) {
        this.setState({ loadingStatus: true });
      }
    }
  }

  getCheckPermissions= (constrains) => {
    const { profile, answers }= this.props;
    if (profile.id!==''&&constrains==='view') {
      const permis=['view_inspectionanswer'];
      for (let io=0; io<profile.roles.length; io+=1) {
        for (let ro=0; ro<profile.roles[io].permissions.length; ro+=1) {
          if (permis.indexOf(profile.roles[io].permissions[ro].codename)>=0) {
            return true;
          }
        }
      }
      if (answers.length > 0) {
        return true;
      }
    }
  }

    handleDateChange = (s, f, range) => {
      const { actionFetch } = this.props;
      const { filterCategories, filterInspections, filterShifts } = this.state;
      localStorage.setItem('start_date', s);
      localStorage.setItem('end_date', f);
      if (range) {
        localStorage.setItem('range', range);
      } else {
        localStorage.removeItem('range');
      }
      let start_date = new Date(s);
      let end_date = new Date(f);
      start_date.setHours(0, 0, 0, 0);
      end_date.setHours(23, 59, 59, 999);
      start_date = start_date.toISOString();
      end_date = end_date.toISOString();
      localStorage.setItem('start', start_date);
      localStorage.setItem('end', end_date);
      this.setState({ date1: start_date, date2: end_date });
      if (filterCategories.length > 0 || filterInspections.length > 0 || filterShifts.length > 0) {
        this.updateFilter(start_date, end_date);
      } else {
        this.setState({ loadingStatus: true });
        actionFetch(start_date, end_date);
      }
    }

    updateFilter = (s, f) => {
      const { filterCategories, searchQuery, filterShifts, filterInspections, filterFields, date1, date2 } = this.state;
      const { actionFetch } = this.props;
      this.setState({ loadingStatus: true });
      const filterCategoriess = filterCategories;
      for (let i=0; i<filterShifts.length; i+=1) {
        if (filterCategoriess.includes(filterShifts[i])) {
          const index = filterCategoriess.indexOf(filterShifts[i]);
          filterCategoriess.splice(index, 1);
        }
      }
      localStorage.setItem('filters', filterCategories);
      let result = filterCategoriess.join(',');
      let result1= '';
      if (filterCategoriess.length === 1) {
        result= `${filterCategoriess[0]},`;
      }

      for (let i=0; i<filterInspections.length; i+=1) {
        if (filterCategoriess.includes(filterInspections[i])) {
          const index = filterCategoriess.indexOf(filterInspections[i]);
          filterCategoriess.splice(index, 1);
        }
      }

      for (let i=0; i<filterFields.length; i+=1) {
        if (filterCategoriess.includes(filterFields[i])) {
          const index = filterCategoriess.indexOf(filterFields[i]);
          filterCategoriess.splice(index, 1);
        }
      }

      let result2= '';
      result = filterCategoriess.join(',');
      if (filterCategoriess.length === 1) {
        result= `${filterCategoriess[0]},`;
      }
      localStorage.setItem('inspection_issues_filter', result);
      result1 = filterShifts.join(',');
      if (filterShifts.length === 1) {
        result1= `${filterShifts[0]},`;
      }

      result2 = filterInspections.join(',');
      if (filterInspections.length === 1) {
        result2= `${filterInspections[0]},`;
      }

      let result3= '';
      result3 = filterFields.join(',');
      if (filterFields.length === 1) {
        result3= `${filterFields[0]},`;
      }
      localStorage.setItem('inspection_custom_fields', result3);
      localStorage.setItem('inspection_filter', result2);
      localStorage.setItem('inspection_shifts', result1);
      if (filterCategories.length > 0 || filterInspections.length > 0 || filterShifts.length > 0 || filterFields.length > 0) {
        if (s === undefined || f === undefined) {
          const sd = localStorage.getItem('start')!=null?localStorage.getItem('start'):date1;
          const fd = localStorage.getItem('end')!=null?localStorage.getItem('end'):date2;
          actionFetch(sd, fd, searchQuery, result, result2, result1, result3);
        } else {
          actionFetch(s, f, searchQuery, result, result2, result1,result3);
        }
      } else {
        actionFetch(date1, date2);
      }
      this.setState({ filterPopup: false, filterCategories: [...filterCategories, ...filterShifts, ...filterInspections, ...filterFields ] });
    }


  showWeatherModal = () => {
    this.setState({ showModal: true });
  };

  handleFilterChange = (name, event) => {
    const { filterCategories, filterShifts, filterInspections, filterFields } = this.state;
    const check = event.target.checked;
    const checkedValue = event.target.id;
    if (check) {
      this.setState({
        filterCategories: [...filterCategories, checkedValue]
      });
      if (name==='Shifts') {
        this.setState({
          filterShifts: [...filterShifts, checkedValue]
        });
      } else if (name==='Type of Inspections') {
        this.setState({
          filterInspections: [...filterInspections, checkedValue]
        });
      } else if (name==='Custom Fields') {
        this.setState({
          filterFields: [...filterFields, checkedValue]
        });
      }
    } else {
      let index = filterCategories.indexOf(checkedValue);
      if (index > -1) {
        filterCategories.splice(index, 1);
        this.setState({ filterCategories });
        if (name==='Shifts') {
          index = filterShifts.indexOf(checkedValue);
          filterShifts.splice(index, 1);
          this.setState({ filterShifts });
        } else if (name==='Type of Inspections') {
          index = filterInspections.indexOf(checkedValue);
          filterInspections.splice(index, 1);
          this.setState({ filterInspections });
        } else if (name==='Custom Fields') {
          index = filterFields.indexOf(checkedValue);
          filterFields.splice(index, 1);
          this.setState({ filterFields });
        }
      }
    }
  }

  handleRemoveFilter = (val) => {
    const { filterCategories, filterShifts, filterInspections, filterFields } = this.state;
    if (filterShifts.includes(val)) {
      const filteredItems = filterShifts.filter(item => item !== val);
      this.setState({ filterShifts: filteredItems }, () => {
        this.updateFilter();
      });
    }
    if (filterInspections.includes(val)) {
      const filteredItems = filterInspections.filter(item => item !== val);
      this.setState({ filterInspections: filteredItems }, () => {
        this.updateFilter();
      });
    }
    if (filterFields.includes(val)) {
      const filteredItems = filterFields.filter(item => item !== val);
      this.setState({ filterFields: filteredItems }, () => {
        this.updateFilter();
      });
    }
    const filteredItems = filterCategories.filter(item => item !== val);
    this.setState({ filterCategories: filteredItems }, () => {
      this.updateFilter();
    });
  }

  handleChange = (option) => {
    this.setState({ filter: option });
  }

  handleChangeGroupBy = (filterGroupBy) => {
    this.setState({ filterGroupBy });
  }

  handleFiltersPopup = () => {
    const { filterPopup } = this.state;
    this.setState({ filterPopup: !filterPopup });
  }

  handleClear = () => {
    this.setState({ filterCategories: [], filterShifts: [], filterInspections: [], filterFields: [] });
  }

  handleCancel = () => {
    const category_filter = [];
    const isues_filter = [];
    const shiftss_filter = [];
    const fields_filter = [];
    let categories_filter;
    if (localStorage.getItem('inspection_filter')) {
      categories_filter = localStorage.getItem('inspection_filter');
      let filter = categories_filter;
      if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
      filter = filter.split(',');
      category_filter.push(...filter);
      this.setState({ filterInspections: filter });
    }
    let shifts_filter;
    if (localStorage.getItem('inspection_shifts')) {
      shifts_filter = localStorage.getItem('inspection_shifts');
      let filter = shifts_filter;
      if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
      filter = filter.split(',');
      shiftss_filter.push(...filter);
      this.setState({ filterShifts: filter });
    }
    let issues_filter;
    if (localStorage.getItem('inspection_issues_filter')) {
      issues_filter = localStorage.getItem('inspection_issues_filter');
      let filter = issues_filter;
      if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
      filter = filter.split(',');
      isues_filter.push(...filter);
    }
    let custom_fields_filter;
    if (localStorage.getItem('inspection_custom_fields')) {
      custom_fields_filter = localStorage.getItem('inspection_custom_fields');
      let filter = custom_fields_filter;
      if (filter.slice(-1) === ',') filter = filter.slice(0, -1);
      filter = filter.split(',');
      fields_filter.push(...filter);
    }
    this.setState({ filterPopup: false, filterCategories: [...category_filter, ...isues_filter, ...shiftss_filter, ...fields_filter] });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  handleSearchQuery = (query) => {
    const { actionFetch } = this.props;
    const { date1, date2 } = this.state;
    actionFetch(date1, date2, query);
    this.setState({ searchQuery: query });
  }

  handleGotoTodo= () => {
    localStorage.setItem('todo_view', 'time');
    localStorage.setItem('todo_filters', 'Inspections');
  }

  handleNewInspection= () => {
    const { history, actionClear } = this.props;
    history.push(`${INSPECTIONS_HOME_ROUTE}start`);
    actionClear();
  }

  render() {
    const { history, answers, summary, intl, shiftTypes } = this.props;
    const { filterPopup: popup, selectionRange, filterShifts, filterGroupBy }=this.state;
    const { range, filter, showModal, filterCategories, filterInspections, filterFields } = this.state;
    const { loadingStatus }=this.state;
    this.filterOptions = [
      { key: '', name: intl.formatMessage({ id: 'inspections.allinspection' }) },
      ...Array.from(new Set(answers===undefined ? [] : answers.map(item => item.inspection))).map(
        (a, i) => ({ key: `k${i}`, name: a })
      )];
    const filteredAnswers = filter && filter.key
      ? answers.filter(e => e.inspection === filter.name)
      : answers;
    let issues = 0;
    filteredAnswers.forEach((answer) => {
      issues += answer.issues;
    });
    filteredAnswers.forEach((e) => {
      let shifts = '';
      shifts = getShiftTypeByDate((moment(e.inspection_date)).format('YYYY-MM-DDTHH:mm:ssz'), shiftTypes).name;
      e.shift_name = shifts;
    });
    const next_inspection = summary && summary.task && summary.task !== '' ? summary.task : '-';
    const weather = summary && summary.weather && summary.weather.summary ? `${summary.weather.summary}, ${summary.weather.temperature}` : '-º';
    const weather_information = summary && summary.weather && summary.weather.summary ? summary.weather : '-';
    const groupByFilterOptions = [
      { key: 0, name: 'Shift' },
      { key: 1, name: 'None' }
    ];
    return (
      <>
        <Loading loadingStatus={loadingStatus} />
        <SectionHeader icon={preset} translationID="inspections.title" defaultTitle="Inspections">
          <div className={styles.detailHeader}>
            <Search onSubmit={query => this.handleSearchQuery(query)} />
            <Permissions allowed={['add_inspectionparent', 'change_inspection']}>
              <IconButton icon={settings} onClick={() => { history.push(`${INSPECTIONS_HOME_ROUTE}new`); }} />
            </Permissions>
            <Separator />
            <Button translationID="inspections.newInspection" defaultText="New Inspection"
              onClick={this.handleNewInspection}
            />
            <Separator />
          </div>

        </SectionHeader>
        {this.getCheckPermissions('view') === true || (filterShifts && filterShifts.length >0)
          ? <>
            <Filter
              filterOptions={this.filterOptions}
              filterShifts={this.filterShifts}
              onDateChange={this.handleDateChange}
              onFilterChange={this.handleChange}
              // onShiftFilterChange={this.handleShiftFilterChange}
              dates={[moment(selectionRange.startDate), moment(selectionRange.endDate)]} range={range}
              handleDropdown={this.handleFiltersPopup}
              updateFilter={this.updateFilter}
              filterCategories={filterCategories}
              groupByFilterOptions={groupByFilterOptions}
              handleChangeGroupBy={this.handleChangeGroupBy}
            />
            <div className={styles.filter}>
              {filterCategories.map(category => (
                <span className={styles.user} key={category}>
                  {category}
                  <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
                    onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
                  />
                </span>
              ))}
            </div>

            {popup && (
              <Transition in={popup} timeout={200} unmountOnExit>
                {state => (
                  <FilterForm
                    handleOutsideClickFilter={this.handleCancel}
                    transition={this.slideStyles[state]}
                    update={this.updateFilter}
                    handleFilterChange={this.handleFilterChange}
                    filterCategories={filterCategories}
                    filterInspections={filterInspections}
                    filterFields={filterFields}
                    shifts={shiftTypes}
                    cancel={this.handleCancel}
                    clear={this.handleClear}
                  />
                )}
              </Transition>
            )}
          </> : ''}
        <div className={styles.container}>
          <div className={styles.datasources}>
            <div className={styles.column}>
              <h5>{next_inspection}</h5>
              <FormattedMessage tagName="p" id="inspections.list.next_inspection" defaultMessage="NEXT INSPECTION" />
              <Link onClick={this.handleGotoTodo} to={{ pathname: uiRouts.todo }}>{intl.formatMessage({ id: 'inspections.list.view' })}</Link>
            </div>
            <div className={styles.column}>
              <h5 dangerouslySetInnerHTML={{ __html: weather }} />
              <FormattedMessage tagName="p" id="inspections.list.weather_insformation" defaultMessage="WEATHER INFORMATION" />
              <Clickable className={styles.active} onClick={() => (summary && summary.weather && summary.weather.summary ? this.showWeatherModal() : '')}>
                <FormattedMessage id="inspections.list.view" defaultMessage="View" />
              </Clickable>
            </div>
            <div className={styles.column}>
              <h2 className={styles.danger}>{issues}</h2>
              <FormattedMessage tagName="p" id="inspections.list.open_issues" defaultMessage="PENDIENTES" />
            </div>
          </div>
          {this.getCheckPermissions('view') === true || (filterShifts && filterShifts.length > 0)
            ? <>
              <Modal
                onClose={() => this.closeModal()}
                showIn={showModal}
                width="30%"
                height="80%"
                minHeight="45%"
              >
                <div className={styles.modal}>
                  <table className={styles.table}>
                    <tr>
                      <td><FormattedMessage id="inspections.list.summary" defaultMessage="SUMMARY" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.summary }} /></td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="inspections.list.temperature" defaultMessage="TEMPERATURE" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.temperature }} /></td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="inspections.list.humidity" defaultMessage="HUMIDITY" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.humidity }} /></td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="inspections.list.pressure" defaultMessage="PRESSURE" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.pressure }} /></td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="inspections.list.visibility" defaultMessage="VISIBILITY" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.visibility }} /></td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="inspections.list.wind" defaultMessage="WIND" /></td>
                      <td><h5 dangerouslySetInnerHTML={{ __html: weather_information.wind }} /></td>
                    </tr>
                  </table>
                  <br />
                </div>
                <div className={`${styles.btn} ${styles.center}`}>
                  <Button type="secondary" translationID="inspections.list.ok" onClick={() => this.closeModal()} defaultText="OK" />
                </div>
                <br />
                <br />
              </Modal>
              <Table allinspections={filteredAnswers} data={filterGroupBy.key === 1 ?
                [{ label: null, entries: filteredAnswers }] : shiftTypes.map(
                  e => ({ label: e.name, entries: filteredAnswers.filter(f => f.shift_name === e.name)})
                )} filterGroupBy={filterGroupBy}
              />
              </>
            :''}
        </div>
      </>
    );
  }
}

InspectionList.propTypes = {
  inspection_list: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  answers: state.inspection.answers,
  inspection_list: state.inspection.inspection_list,
  profile: state.auth.profile,
  summary: state.inspection.summary,
  shiftTypes: state.settings.shiftTypes
});

const mapDispatchToProps = dispatch => ({
  // Fetch inspection
  actionFetch: (day, day2, query, result3, result1, result2, result) => {
    dispatch(fetchInspectionAnswers(day, day2, query, result3, result1, result2, result));
  },
  actionFetchSummary: () => {
    dispatch(fetchsummary());
  },
  actionfetchProfile: () => {
    dispatch(fetchUserProfile());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  actionClear: () => {
    dispatch(clearInspectionOnly());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InspectionList));

const Separator = () => <div className={styles.separator} />;
