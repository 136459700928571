import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import React from 'react';
import * as types from './types';

const INITIAL_STATE = {
  airportInfo: '',
  comments: [],
  runwayConditions: [],
  airportRunways: [],
  usefulLinks: [],
  toastId: null,
  editAction: { success: false, loading: false, message: '' },
  fetchAction: { success: false, loading: false, message: '' },
  createAction: { success: false, loading: false, message: '' },
  updateAction: { success: false, loading: false, message: '' },
  usefulLinksAction: { success: false, loading: false, message: '' },
  commentsAction: { success: false, loading: false, message: '' }
};

const actionForState = (action, state) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*        AIRPORT INFORMATION       */
    /* ******************************** */
    case types.FETCH_AIRPORT_INFORMATION.pending:
      return {
        ...state,
        fetchAirportInfoAction: actionForState(state.action, 'pending')
      };
    case types.FETCH_AIRPORT_INFORMATION.success:
      return {
        ...state,
        airportInfo: action.payload.results,
        fetchAirportInfoAction: actionForState(state.action, 'success')
      };
    case types.FETCH_AIRPORT_INFORMATION.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        fetchAirportInfoAction: actionForState(state.action, 'failed')
      };
    }
    /* ******************************** */
    /*      ADD AIRPORT INFORMATION       */
    /* ******************************** */
    case types.ADD_AIRPORT_INFORMATION.pending:
      return {
        ...state,
        createAction: actionForState(state.action, 'pending')
      };
    case types.ADD_AIRPORT_INFORMATION.success:
      return {
        ...state,
        airportInformation: action.payload,
        createAction: actionForState(state.action, 'success')
      };
    case types.ADD_AIRPORT_INFORMATION.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        createAction: actionForState(state.action, 'failed')
      };
    }

    /* ******************************** */
    /*      UPDATE AIRPORT INFORMATION       */
    /* ******************************** */
    case types.UPDATE_AIRPORT_INFORMATION.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case types.UPDATE_AIRPORT_INFORMATION.success:
      return {
        ...state,
        airportInformation: action.payload,
        updateAction: actionForState(state.action, 'success')
      };
    case types.UPDATE_AIRPORT_INFORMATION.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        action: actionForState(state.action, 'failed')
      };
    }
    /* ******************************** */
    /*        ADD COMMENTS       */
    /* ******************************** */
    case types.ADD_COMMENTS.pending:
      return {
        ...state,
        createAction: actionForState(state.action, 'pending')
      };
    case types.ADD_COMMENTS.success:
      return {
        ...state,
        createAction: actionForState(state.action, 'success')
      };
    case types.ADD_COMMENTS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        createAction: actionForState(state.action, 'failed')
      };
    }
    /* ******************************** */
    /*        FETCH COMMENTS       */
    /* ******************************** */
    case types.FETCH_COMMENTS.pending:
      return {
        ...state,
        createCommentsAction: actionForState(state.action, 'pending')
      };
    case types.FETCH_COMMENTS.success:
      return {
        ...state,
        comments: action.payload.results,
        createCommentsAction: actionForState(state.action, 'success')
      };
    case types.FETCH_COMMENTS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        createCommentsAction: actionForState(state.action, 'failed')
      };
    }


    /* ******************************** */
    /*      DELETE USEFUL_LINKS       */
    /* ******************************** */
    case types.DELETE_COMMENT.pending:
      return {
        ...state,
        commentsAction: { success: false, loading: true, message: 'pending' }
      };
    case types.DELETE_COMMENT.success:
      return {
        ...state,
        commentsAction: { success: true, loading: false, message: 'success' }
      };
    case types.DELETE_COMMENT.error: {
      return {
        ...state,
        commentsAction: { success: true, loading: false, message: 'failed' }
      };
    }

    /* ******************************** */
    /*        FETCH RUNWAY_CONDITIONS       */
    /* ******************************** */
    case types.FETCH_RUNWAY_CONDITIONS.pending:
      return {
        ...state,
        fetchAction: actionForState(state.action, 'pending')
      };
    case types.FETCH_RUNWAY_CONDITIONS.success:
      return {
        ...state,
        runwayConditions: action.payload.results,
        fetchAction: actionForState(state.action, 'success')
      };
    case types.FETCH_RUNWAY_CONDITIONS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        fetchAction: actionForState(state.action, 'failed')
      };
    }

    case types.FETCH_AIRPORT_RUNWAYS.pending:
      return {
        ...state,
        fetchAirportRunways: actionForState(state.action, 'pending')
      };
    case types.FETCH_AIRPORT_RUNWAYS.success:
      return {
        ...state,
        airportRunways: action.payload,
        fetchAirportRunways: actionForState(state.action, 'success')
      };
    case types.FETCH_AIRPORT_RUNWAYS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        fetchAirportRunways: actionForState(state.action, 'failed')
      };
    }

    case types.UPDATE_RUNWAY_CONDITIONS.pending:
      return {
        ...state,
        editAction: actionForState(state.action, 'pending')
      };
    case types.UPDATE_RUNWAY_CONDITIONS.success:
      return {
        ...state,
        updateRunway: action.payload,
        editAction: actionForState(state.action, 'success')
      };
    case types.UPDATE_RUNWAY_CONDITIONS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        editAction: actionForState(state.action, 'failed')
      };
    }

    /* ******************************** */
    /*        FETCH USEFULLINKS       */
    /* ******************************** */
    case types.FETCH_USEFUL_LINKS.pending:
      return {
        ...state,
        usefulLinksAction: actionForState(state.action, 'pending')
      };
    case types.FETCH_USEFUL_LINKS.success:
      return {
        ...state,
        usefulLinks: action.payload.results,
        usefulLinksAction: actionForState(state.action, 'success')
      };
    case types.FETCH_USEFUL_LINKS.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        usefulLinksAction: actionForState(state.action, 'failed')
      };
    }

    /* ******************************** */
    /*        ADD USEFULLINKS       */
    /* ******************************** */
    case types.CREATE_USEFUL_LINKS.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.assetdeletedprogress" defaultMessage="UsefulLink create in Progress." />, { type: toast.TYPE.INFO });
      return {
        ...state,
        usefulLinksAction: actionForState(state.action, 'pending')
      };
    case types.CREATE_USEFUL_LINKS.success:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.assetdeleted" defaultMessage="UsefulLink created Successfully." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        usefulLinksAction: actionForState(state.action, 'success')
      };
    case types.CREATE_USEFUL_LINKS.error: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.erroroccured" defaultMessage="Error occured while creating UsefulLink." />,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        errors: action.payload.response.data.errors,
        usefulLinksAction: actionForState(state.action, 'failed')
      };
    }

    /* ******************************** */
    /*      UPDATE USEFUL_LINKS       */
    /* ******************************** */
    case types.UPDATE_USEFUL_LINKS.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.assetdeletedprogress" defaultMessage="UsefulLink edit in Progress." />, { type: toast.TYPE.INFO });
      return {
        ...state,
        usefulLinksAction: { success: false, loading: true, message: 'pending' }
      };
    case types.UPDATE_USEFUL_LINKS.success:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.assetdeleted" defaultMessage="UsefulLink Updated Successfully." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        usefulLinksAction: { success: true, loading: false, message: 'success' }
      };
    case types.UPDATE_USEFUL_LINKS.error: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.erroroccured" defaultMessage="Error occured while editing UsefulLink." />,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        errors: action.payload.response.data.errors,
        usefulLinksAction: { success: true, loading: false, message: 'failed' }
      };
    }

    /* ******************************** */
    /*      DELETE USEFUL_LINKS       */
    /* ******************************** */
    case types.DELETE_USEFUL_LINK.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.assetdeletedprogress" defaultMessage="Useful Link delete in Progress." />, { type: toast.TYPE.INFO });
      return {
        ...state,
        usefulLinksAction: { success: false, loading: true, message: 'pending' }
      };
    case types.DELETE_USEFUL_LINK.success:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.assetdeleted" defaultMessage="Useful Link Deleted." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        usefulLinksAction: { success: true, loading: false, message: 'success' }
      };
    case types.DELETE_USEFUL_LINK.error: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.erroroccured" defaultMessage="Error occured while usefulLink deleting." />,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        usefulLinksAction: { success: true, loading: false, message: 'failed' }
      };
    }

    case types.CLEAR_AIRFIELD_ACTIONS:
      return {
        ...state,
        editAction: { success: false, loading: false, message: '' },
        fetchAction: { success: false, loading: false, message: '' },
        createAction: { success: false, loading: false, message: '' },
        commentsAction: { success: false, loading: false, message: '' }
      };

      /* ******************************** */
      /*      CLEAR USEFUL_LINKS       */
      /* ******************************** */

    case types.CLEAR_AIRFIELD_USEFULLINKS_ACTIONS:
      return {
        ...state,
        usefulLinksAction: { success: false, loading: false, message: '' }
      };
    default:
      return state;
  }
}
