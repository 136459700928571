import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import drawLocales from 'leaflet-draw-locales';
import { Transition } from 'react-transition-group';
import { HOC as Permissions } from 'react-redux-permissions';
import Forbidden from '../../Forbidden';
import * as utils from '../../opsSettings/Map/mapUtils';
import SectionHeader from '../../../components/sectionHeader';
import IconButton from '../../../components/iconButton';
import Seperator from '../../../components/separator';
import Modal from '../../../components/modal';
import Button from '../../../components/button';
import icon from '../../../icons/Properties.png';
import settings from '../../../icons/settings.svg';
import styles from '../../opsSettings/Map/map.module.scss';
import Toolbar from './components/Toolbar';
import Form from './components/Form';
import { fetchProperties, addProperty, updateProperty, clear, deleteProperty, fetchPropertyTypes } from '../redux/actions';
import { GetFileName, S3Uploads } from '../../services';
import Loading from '../../../components/loading';
import { LEASE_HOME } from '../../../constants/RouterConstants';
import ModalStyles from './components/form.module.scss';

let drawControl;
let map = {};
const drawnItems = new L.FeatureGroup();
const language = localStorage.getItem('lang') ||'en';
drawLocales(language);

const locale = drawLocales(language);
locale.draw.toolbar.buttons.polygon = 'Draw a New Property!';
L.drawLocal = locale;

class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      layer: {},
      editProperty: {},
      counter: 0,
      info: {
        attachments: []
      },
      fieldErrors: {
        name: true
      },
      showFormErrors: false,
      allProperties: undefined,
      loadingStatus: false,
      delPopup: false,
      tenantPopup: false
    };

    this.changeView = this.changeView.bind(this);
    this.addPopupToLayer = this.addPopupToLayer.bind(this);

    this.defaultConfig = {
      marker: false,
      circle: false,
      rectangle: false,
      polyline: false,
      circlemarker: false
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  componentDidMount() {
    const { user, actionFetchProperties, fetchTypes } = this.props;
    this.setState({ loadingStatus: true });
    if (user.id) {
      actionFetchProperties();
      fetchTypes();
      this.initializeMap();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.propertyTypes && props.allTypes && props.allProperties) {
      return { ...state, propertyTypes: props.allTypes, allProperties: props.allProperties, loadingStatus: false };
    }
    return state;
  }


  componentDidUpdate(prevProps) {
    const { allProperties, createAction, updateAction, deleteAction, actionClear, actionFetchProperties } = this.props;
    if (createAction.success === true && prevProps.createAction.success === false) {
      this.setState({
        popup: false,
        editProperty: {},
        info: { attachments: [] },
        fieldErrors: {
          name: true
        },
        allProperties: undefined,
        loadingStatus: true
      }, () => {
        actionClear();
        actionFetchProperties();
      });
    }

    if (updateAction.success === true && prevProps.updateAction.success === false) {
      this.setState({
        popup: false,
        editProperty: {},
        info: { attachments: [] },
        fieldErrors: {
          name: true
        },
        allProperties: undefined,
        loadingStatus: true
      }, () => {
        actionClear();
        actionFetchProperties();
      });
      window.location.reload();
    }

    if (deleteAction.success === true && prevProps.deleteAction.success === false) {
      this.setState({
        popup: false,
        editProperty: {},
        info: { attachments: [] },
        fieldErrors: {
          name: true
        },
        allProperties: undefined,
        loadingStatus: true
      }, () => {
        actionClear();
        actionFetchProperties();
      });
    }
    if (prevProps.allProperties !== allProperties && allProperties.length) {
      allProperties.forEach((o) => {
        // eslint-disable-next-line no-underscore-dangle
        let l = L.geoJSON(o.geometry)._layers;
        [l] = Object.keys(l).map(ob => l[ob]);
        const customlayer = this.addPopupToLayer('transparent', l);
        map.addLayer(customlayer.bindTooltip(o.name, {
          permanent: true,
          className: styles.customLabel
        }));
        const permission = this.checkPermission('change_leaseproperties');
        if (permission) {
          l.on('click', (e) => {
            map.layer = e.target;
            map.eachLayer((layer) => {
              if (layer.editing && layer.editing.enabled()) {
                layer.editing.disable();
              }
              // eslint-disable-next-line no-underscore-dangle
              if (e.target._leaflet_id === layer._leaflet_id) {
                layer.closeTooltip();
              } else {
                layer.openTooltip();
              }
            });
            const result = {
              id: o.id,
              name: o.name,
              space_size: o.space_size || '',
              description: o.description || '',
              attachments: o.attachments,
              plan_attachment: o.plan_attachment[0],
              property_type: o.property_type || '',
              tenant: o.tenant
            };
            this.setState({
              popup: true,
              info: result,
              editProperty: e.target,
              fieldErrors: {
                name: false
              }
            }, () => e.target.editing.enable());
          });
        }
      });
      this.setState({ counter: allProperties.length, loadingStatus: false, allProperties });
    }
  }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  showDelPopUp=() => {
    const { delPopup, info } = this.state;
    if (info.tenant) {
      this.setState({
        tenantPopup: true
      });
    } else {
      this.setState({
        delPopup: !delPopup
      });
    }
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  drawNewLayer = (e) => {
    drawnItems.addLayer(e.layer);
    map.addLayer(e.layer);
    utils.toggleZooming(map, 'disable');
    this.setState({ popup: true, layer: e.layer });
  }

  addPopupToLayer = (color, layer) => {
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: color,
      color,
      opacity: 0
    });
    return customlayer;
  };


  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  handleDelete = () => {
    const { actionDeleteProperty } = this.props;
    const { info, delPopup } = this.state;
    actionDeleteProperty(info.id);
    map.eachLayer((layer) => {
      if (layer.editing && layer.editing.enabled()) {
        map.removeLayer(layer);
        map.layer.closeTooltip();
      }
    });
    this.setState({ popup: false, delPopup: !delPopup });
  }

  handleOnEdit = async () => {
    const { info, fieldErrors } = this.state;
    utils.toggleZooming(map, 'enable');
    const { editProperty } = this.state;
    const { actionUpdateProperty } = this.props;

    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));

    if (noErrors) {
      const data = { ...info };
      this.setState({ loadingStatus: true });
      // eslint-disable-next-line no-underscore-dangle
      const coordinates = editProperty._latlngs[0].map(o => [o.lng, o.lat]);
      coordinates.push(coordinates[0]);
      const location = {
        type: 'polygon',
        coordinates
      };
      data.geometry = JSON.stringify(location);
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k === 'removedAttachments') {
          formData.append(k, data[k]);
        } else if (k !== 'attachments' && k !== 'plan_attachment') {
          formData.append(k, data[k]);
        }
      });
      if (data.plan_attachment && data.plan_attachment.name) {
        let attachmentIds = '';
        attachmentIds = await this.getFileNames(1);
        if (attachmentIds) {
          await this.doS3Upload([info.plan_attachment], attachmentIds, 'property_floor_plan')
            .then(() => {
              attachmentIds.filename.forEach((id) => {
                formData.append('plan_attachment', id.toString());
              });
            });
        }
      }
      const newAttachments = [];
      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((e) => {
          if (!e.id) {
            newAttachments.push(e);
          }
        });
      }
      let fileIds = '';
      if (newAttachments.length > 0) {
        fileIds = await this.getFileNames(newAttachments.length);
      }
      if (fileIds) {
        await this.doS3Upload(newAttachments, fileIds, 'lease_properties')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });
            actionUpdateProperty(info.id, formData);
          });
      } else {
        actionUpdateProperty(info.id, formData);
      }
    } else {
      this.setState({ showFormErrors: true });
    }
    utils.disableEditMarkers(map);
  }


  handleSave = async () => {
    const { info, fieldErrors } = this.state;
    utils.toggleZooming(map, 'enable');
    const { layer } = this.state;
    const { actionCreateProperty } = this.props;
    layer.setStyle({
      fillColor: 'transparent',
      opacity: 0
    });
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));

    if (noErrors) {
      const data = { ...info };
      this.setState({ loadingStatus: true });
      // eslint-disable-next-line no-underscore-dangle
      const coordinates = layer._latlngs[0].map(o => [o.lng, o.lat]);
      coordinates.push(coordinates[0]);
      const location = {
        type: 'polygon',
        coordinates
      };
      data.geometry = JSON.stringify(location);
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k !== 'attachments' && k !== 'plan_attachment') {
          formData.append(k, data[k]);
        }
      });
      if (data.plan_attachment) {
        let attachmentIds = '';
        attachmentIds = await this.getFileNames(1);
        if (attachmentIds) {
          await this.doS3Upload([info.plan_attachment], attachmentIds, 'lease_floor_plan')
            .then(() => {
              attachmentIds.filename.forEach((id) => {
                formData.append('plan_attachment', id.toString());
              });
            });
        }
      }
      let fileIds = '';
      if (data.attachments.length > 0) {
        fileIds = await this.getFileNames(data.attachments.length);
      }
      if (fileIds) {
        await this.doS3Upload(info.attachments, fileIds, 'lease_properties')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });
            actionCreateProperty(formData);
          });
      } else {
        actionCreateProperty(formData);
      }
    } else {
      this.setState({ showFormErrors: true });
    }
    utils.disableEditMarkers(map);
  }

  handleCancel = () => {
    const { layer, info } = this.state;
    this.setState({
      editProperty: {},
      removeMarker: true,
      popup: false,
      info: {
        attachments: []
      }
    });
    map.removeLayer(layer);
    if (info.id) map.layer.openTooltip();
    utils.disableEditMarkers(map);
  }

  handleAnswerChanged = (section, answer, fieldId) => {
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [fieldId]: answer
      }
    }));
  }

  checkPermission = (code_name) => {
    const { user } = this.props;
    let status1 = false;
    for (let i = 0; i < user.roles.length; i+=1) {
      for (let j = 0; j < user.roles[i].permissions.length; j+=1) {
        if (user.roles[i].permissions[j].codename === code_name) {
          status1 = true;
        }
      }
    }
    return status1;
  };

  drawNewControl() {
    this.setState({ editProperty: {}, layer: {} });
    return new L.Control.Draw({
      edit: { featureGroup: drawnItems,
        remove: false,
        edit: false
      },
      draw: {
        ...this.defaultConfig,
        polygon: {
          shapeOptions: {
            color: '#3a61a8',
            opacity: 1,
            fillOpacity: 0.48
          },
          icon: new utils.CustomIcon({ color: '#3a61a8' })
        }
      }
    });
  }

  changeView(editMode) {
    if (!editMode) {
      drawControl = this.drawNewControl();
      map.addControl(drawControl);
    } else {
      map.removeControl(drawControl);
    }
  }

  initializeMap() {
    const { user } = this.props;
    map = L.map('map', {
      center: [...user.airport.location.coordinates].reverse(),
      zoom: 15,
      editable: true
    });
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(map);

    // creation callback for triggering form.
    const that = this;
    map.on(L.Draw.Event.CREATED, (e) => {
      that.drawNewLayer(e);
    });
  }

  render() {
    const { popup, counter, info, showFormErrors, loadingStatus, propertyTypes,
      delPopup, tenantPopup } = this.state;
    const { history, user } = this.props;
    const settings_permission = this.checkPermission('change_leaseproperties');
    return (
    <>
      <SectionHeader icon={icon} translationID="shortcuts.lease.properties" defaultTitle="Properties">
        <div className={styles.detailHeader}>
          <Seperator />
          {user.id!=='' && settings_permission === true ? (
            <IconButton icon={settings} onClick={() => { history.push(`${LEASE_HOME}/properties/settings`); }} />
          ):''}
        </div>
        <Seperator />
      </SectionHeader>
      <Loading loadingStatus={loadingStatus} />
      <div className={styles.surfaceMap}>
        <Toolbar
          changeView={this.changeView}
          propertyCounter={counter}
          checkPermission={this.checkPermission}
        />
        <div id="map" className={styles.map} />
        {popup
          && (
          <Transition in={popup} timeout={200} unmountOnExit>
            { state => (
              <Form
                info={info}
                onSave={this.handleSave}
                handleAnswerChanged={this.handleAnswerChanged}
                handleFieldErrorChanged={this.handleFieldErrorChanged}
                onDelete={this.showDelPopUp}
                onEdit={this.handleOnEdit}
                transition={this.slideStyles[state]}
                propertyTypes={propertyTypes}
                onCancel={this.handleCancel}
                showFormErrors={showFormErrors}
              />
            )}
          </Transition>
          )}
        <Modal
          width="500px"
          minHeight="150px"
          showIn={delPopup}
          contentStyles={{
            padding: ' 15px 30px 5px 40px'
          }}
          centered
          onClose={this.showDelPopUp}
        >
          <h3 style={{ paddingTop: '20px' }}>
            <FormattedMessage
              id="lease.properties.delete_confirmation"
              defaultText="Are you sure you want to delete the property "
            />
            <b style={{ color: 'red' }}>
              <br />
              {info.name}
              {' '}
            </b>
              ?
          </h3>
          <div className={ModalStyles.footer}>
            <Button onClick={this.showDelPopUp}
              translationID="roles.footer.No"
              defaultText="No" action="tertiary"
            />
            <Button onClick={this.handleDelete}
              translationID="roles.footer.Yes"
              defaultText="Yes"
              action="danger"
              style={{ minHeight: '10px' }}
            />
          </div>
        </Modal>

        <Modal
          width="500px"
          minHeight="150px"
          showIn={tenantPopup}
          contentStyles={{
            padding: ' 15px 30px 5px 40px'
          }}
          centered
          onClose={() => this.setState({ tenantPopup: false })}
        >
          <div className={ModalStyles.confirmContent}>
            <FormattedMessage
              id="lease.properties.tenant_error"
              defaultText="You cannot delete this record. {property} is currently attached to {tenant} Tenant."
              values={{ property: info.name, tenant: info.tenant }}
            />
          </div>
          <div className={ModalStyles.btn}>
            <Button type="secondary"
              translationID="inspections.list.ok"
              onClick={() => this.setState({ tenantPopup: false })} defaultText="OK"
            />
          </div>
        </Modal>
      </div>
    </>
    );
  }
}

Properties.propTypes = {
  history: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  allProperties: PropTypes.shape({})
};

Properties.defaultProps = {
  allProperties: {}
};

const mapStateToProps = state => ({
  user: state.auth.profile,
  allProperties: state.lease.allProperties,
  createAction: state.lease.createPropertyAction,
  updateAction: state.lease.updatePropertyAction,
  deleteAction: state.lease.deletePropertyAction,
  allTypes: state.lease.allTypes
});

const mapDispatchToProps = dispatch => ({
  actionFetchProperties: () => {
    dispatch(fetchProperties());
  },
  actionCreateProperty: (data) => {
    dispatch(addProperty(data));
  },
  actionUpdateProperty: (id, data) => {
    dispatch(updateProperty(id, data));
  },
  actionDeleteProperty: (id) => {
    dispatch(deleteProperty(id));
  },
  fetchTypes: () => {
    dispatch(fetchPropertyTypes());
  },
  // Clear Result
  actionClear: () => {
    dispatch(clear());
  }

});

export default Permissions(['view_leaseproperties'])(
  connect(mapStateToProps, mapDispatchToProps)(Properties),
  <Forbidden />
);
