/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-polylinedecorator';
import styles from './InspectionRoute.module.scss';

class Map extends Component {
  defaultConfig = {
    marker: false,
    circle: false,
    rectangle: false,
    polyline: false,
    circlemarker: false,
    polygon: false
  };

  componentDidMount() {
    this.initializeMap();
  }

  
  initializeMap = () => {
    const { defaultLocation, loglist } = this.props;
    const zoomLevel = localStorage.getItem('wildlifelog_Zoom');
    this.map = L.map('map', {
      center: [...defaultLocation].reverse(),
      zoom: zoomLevel !== null ? zoomLevel : 15,
      fullscreenControl: true
    });

    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);
    if (loglist && loglist.length>0) {
      const coordinates= [];
      // eslint-disable-next-line array-callback-return
      Object.values(loglist).map((o) => {
        coordinates.push([o.location.lat, o.location.lon]);
      });
      const polyline = L.polyline(coordinates, { color: '#f1592a' }).addTo(this.map);
      const decorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
              pixelSize: 15,
              polygon: false,
              pathOptions: { color: '#f1592a', stroke: true, fillOpacity: 0, weight: 7 }
            })
          }
        ]
      }).addTo(this.map);
      this.map.fitBounds(polyline.getBounds());
      this.map.addLayer(decorator);
    }
  }

  handleZoomLevel = (zoomLevel) => {
    const { setZoomLevel } = this.props;
    setZoomLevel(zoomLevel);
  }

  render() {
    return <div id="map" className={styles.map} />;
  }
}

Map.propTypes = {
  loglist: PropTypes.arrayOf(PropTypes.shape({})),
  defaultLocation: PropTypes.arrayOf(PropTypes.number)
};

Map.defaultProps = {
  loglist: [],
  defaultLocation: [0, 0]
};

export default Map;
