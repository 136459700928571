import { SetStateAction, Dispatch } from "react";
import { ChatTypes } from "./enums";
import {
  CreateMessagesArgs,
  ComposePostArgs,
  ComposeCommentArgs,
  UpdateCommentArgs,
  UpdatePostArgs,
  DeleteCommentArgs,
  DeletePostArgs,
  ToggleLikesArgs,
  UpdateDMVars
} from "./graphql/hooks/hooks.types";
import { ApolloQueryResult } from "apollo-client";
import { MessageArray } from "./containers/ChatBox/ChatBox";
import { PostMessageObj } from "./state/channelsState/posts/types";
import { CommentMessageObj } from "./state/channelsState/comments/types";

type Roles = {
  name: string;
  id: number;
  permissions: { name: string };
};

export type UserType = {
  firstName: string;
  lastName: string;
  middleName: string | null;
  profilePicture: string;
  title: string;
  userId: string;
  isActive?: boolean;
  roles: Roles;
  airportId: string;
  langauge: string;
  phone: string;
  updatedAt: string;
  createdAt: string;
  email: string;
  isNotifications: string;
  airportDetails: AirportDetails[];
};

export type ChannelsType = {
  content: string;
  createdAt: string;
  createdBy: string;
  name: string;
  type: boolean;
};

export type ChannelType = {
  channelId: string;
  channelUsersId: string;
  channels: ChannelsType;
  createdAt: string;
  unreadCount: number;
  userId: string;
};

export type ChatListUser = Pick<UserType, 'airportId'> & SearchResult;

export interface ListChatType {
  roomId: string;
  fromUserId: string;
  toUserId: string;
  updatedAt: string;
  airportId: string;
  fromUser: ChatListUser;
  toUser: ChatListUser;
}

export type ChannelsGroupTypes = {
  [ChatTypes.operations]?: ChannelType[];
  [ChatTypes.directMessages]?: ListChatType[];
};

export type MenuItemTypes = {
  [ChatTypes.operations]?: ChannelType;
  [ChatTypes.directMessages]?: ListChatType;
};

export type SortedChannels = {
  privateChannels: ChannelType[];
  publicChannels: ChannelType[];
};

export type SearchResult = Pick<
  UserType,
  "firstName" | "lastName" | "userId" | "title" | "profilePicture" | "isActive"
>;

export type AirportUsersListVar = {
  airportId: string;
  limit: number;
  nextToken: null;
};

export type ChannelsVar = {
  userId: string;
  airportId: string;
  limit: number;
  nextToken: string;
};

export type ChannelMessageVar = {
  channelId?: string;
  postId?: string;
  userId: string;
  limit: number;
  nextToken: null;
};

// export type PostCommentsVar = {
//   postId: string;
//   userId: string;
//   limit: number;
//   nextToken: null;
// };

export type User = Pick<
  UserType,
  | "firstName"
  | "lastName"
  | "profilePicture"
  | "isActive"
  | "roles"
  | "userId"
  | "title"
  | "airportId"
  | "airportDetails"
>;

export enum AttachmentType {
  pdf = "application/pdf",
  svg = "image/svg+xml",
  jpeg = "image/jpeg",
  png = "image/png",
  gif = "image/gif"
}

export type Attachments = {
  name: string | null;
  type: AttachmentType | string;
  url: string | null;
  size: string | null;
};

export interface PostMessageType {
  airportId: string;
  attachments: Attachments[] | null;
  channelId: string;
  comments: number;
  content: string;
  createdAt: string;
  isLiked: boolean;
  likes: number;
  postId: string;
  updatedAt: string;
  user: User;
  userId: string;
  newMessage?: boolean;
  lastReplay?: number;
}

export interface DirectMessageType {
  createdAt: string;
  fromUserId: string;
  message: string;
  messageId: string;
  readStatus: boolean;
  fromRead: boolean;
  toRead: boolean;
  fromDelete: boolean;
  toDelete: boolean;
  toUserId: string;
  updatedAt: string;
  attachments: Attachments[] | null;
  fromUser: User;
  toUser: User;
  newMessage?: boolean;
}

export type NewPostsType = {
  [x: string]: PostMessageType[];
}

export type NewDirectMessagesType = {
  [x: string]: DirectMessageType[];
}

export interface ListChatArrType {
  items: ListChatType[];
  nextToken: string;
}

export interface DirectMessageArrType {
  items: DirectMessageType[];
  nextToken: string;
}

export interface CommentMessage extends PostMessageType {
  commentId?: string;
  parentCommentId?: string;
}

export type MessageUnionType = PostMessageType | CommentMessage | DirectMessageType;

export interface FormValues {
  name: string;
  privacy: string;
  content: string;
  invitedPeople: string | null;
}

export type MessageFormValues = {
  content: string;
  // attachments: FileList | null;
};

export type ChannelArray = {
  listUserChannels: {
    items: ChannelType[];
  };
};

export type UsersArray = {
  listUsers: {
    items: UserType[];
  };
};

export type ListChannelUsersType = {
  userDetails: User;
  userId: string;
  isActive: boolean;
};

export type ListChannelUsers = {
  listChannelUsers: {
    items: ListChannelUsersType[];
  };
};

export type ListChannelUsersVar = {
  channelId: string;
  limit: number;
  nextToken: null;
};

export type ChannelDetails = {
  airportId: string;
  channelId: string;
  content: string;
  createdAt: string;
  createdBy: string;
  name: string;
  type: string;
  updatedAt: string;
  userDetails: UserType;
};

export type EditLocationState = {
  channelId: string;
};

export type EditLocation = {
  state: EditLocationState;
};

export type CurrentUser = {
  userId: string;
};
export interface EditChannelFormValues {
  channelId: string;
  name: string;
  privacy: string;
  content: string;
  invitedPeople: string | null;
}

export type AirportDetails = {
  code: string;
  name: string;
  logo: string;
};

export type PostCommentMessageObj = PostMessageObj | CommentMessageObj;

export type MessageCollection =
  | PostMessageType
  | CommentMessage
  | DirectMessageType;

interface FuncArgs<T> {
  (args: T): void;
}

export interface ToggleLikesFunc {
  (args: ToggleLikesArgs, isLiked: boolean): Promise<void>;
}

export interface UpdatePostFunc {
  (args: UpdatePostArgs, postID?: string): void;
}

export interface OnUserRemoveFunc {
  (userID: string): void
}
export interface OnUserSelectFunc {
  (userID: string | string[]): void
}

export type SetListHeightFunc = FuncArgs<SetStateAction<number>>;
export type SetAttachmentArrFunc = FuncArgs<SetStateAction<File[]>>;

export type SetFilesType = Dispatch<SetStateAction<FileList>>;
export type SetOpenModalType = Dispatch<SetStateAction<boolean>>;
export interface ActiveChannelFunc {
  (name: string, id: string): void;
}

export type UpdateCommentFunc = FuncArgs<UpdateCommentArgs>;
export type CreateMessagesFunc = FuncArgs<ComposePostArgs>;
export type CreateCommentFunc = FuncArgs<ComposeCommentArgs>;
export type DeleteCommentFunc = FuncArgs<DeleteCommentArgs>;
export type DeletePostFunc = FuncArgs<DeletePostArgs>;
export type UpdateDMFunc = FuncArgs<UpdateDMVars>;

export interface FetchMoreFunc {
  (nextToken: string): Promise<void | ApolloQueryResult<MessageArray>>;
}
export type DeleteDMFunc = FuncArgs<any>;

export interface FetchMoreFunc {
  (nextToken: string): Promise<void | ApolloQueryResult<MessageArray>>;
}

type MessageUtilsTypes<O, C, D> = {
  [ChatTypes.operations]?: FuncArgs<O>;
  [ChatTypes.comment]?: FuncArgs<C>;
  [ChatTypes.directMessages]?: FuncArgs<D>;
};

export type CreateMessagesTypes = MessageUtilsTypes<
  ComposePostArgs,
  ComposeCommentArgs,
  CreateMessagesArgs
>;

export type DeleteMessagesTypes = {
  [ChatTypes.operations]?: DeletePostFunc;
  [ChatTypes.comment]?: DeleteCommentFunc;
  [ChatTypes.directMessages]?: DeleteDMFunc;
};

export type UpdateMessagesTypes = {
  [ChatTypes.operations]?: UpdatePostFunc;
  [ChatTypes.comment]?: UpdateCommentFunc;
  [ChatTypes.directMessages]?: UpdateDMFunc;
};

export type ChanelTypes = ChatTypes.comment | ChatTypes.operations;
export type MessengerModeTypes =
  | ChatTypes.operations
  | ChatTypes.directMessages;


export type ChatBoxProps = {
  type: ChanelTypes;
  topicsConnected: boolean;
  deletedMessageID?: string;
  editedMessageId?: string;
  updateLoading?: boolean;
  savingMessage?: boolean;
  hasMore: string;
  newPost?: number;
  createMsg: CreateMessagesTypes;
  updateMessages: UpdateMessagesTypes;
  deleteMessage?: DeleteMessagesTypes;
  // loadMore: FetchMoreFunc;
  loadMore: () => void;
  messagesList?: MessageCollection[];
  editedMessage?: PostMessageType | CommentMessage;
  toggleLikes?: ToggleLikesFunc;
};

export type DirectChatBoxProps = {
  type?: ChatTypes.directMessages;
  createMsg: CreateMessagesTypes;
  currentUser?: CurrentUser;
  toUser?: ChatListUser;
  messagesList?: DirectMessageType[];
  channelId?: string;
  topicsConnected: boolean;
  loadMore: () => void;
  deleteMessage?: DeleteMessagesTypes;
  hasMore: string;
  editedMessageId?: string;
  editedMessage?: DirectMessageType;
  updateMessage: UpdateDMFunc;
}
