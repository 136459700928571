/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { HOC as Permissions } from 'react-redux-permissions';
import { fetchLogFormSchema, createLog, fetchLog, updateLog, clear } from '../redux/actions';
/** ******************************************************************
 *  Component import
 * ****************** */
import SectionHeader from '../../../components/sectionHeader';
import HeaderBack from '../../../components/headerBack';
import Button from '../../../components/button';
import Loading from '../../../components/loading';
import PulpoField from '../../../pulpo_visualizer/fields';
import DefaultFields from '../List/components/DefaultFields';
import { getShiftTypeByDate } from '../../../utils/helpers';
import { fetchShiftTypes } from '../../settings/redux/actions';

/** ******************************************************************
 *  Assets import
 * ************* */

import styles from '../../workorders/Create/workOrderCreate.module.scss';
import Panel from '../../../components/panel';
import { searchUser, fetchDataSourceList } from '../../inspections/redux/actions';
import preset from '../../../icons/Preset.svg';
import { LOGS_HOME_ROUTE } from '../../../constants/RouterConstants';
import Forbidden from '../../Forbidden';
import { GetFileName, S3Uploads } from '../../services';

const OperationLogCreate = ({
  actionFetchForm,
  saveAction,
  actionCreateLog,
  actionSearchUser,
  actionFetchShiftTypes,
  actionClear,
  actionFetchLog,
  actionUpdateLog,
  history,
  match,
  shiftTypes,
  version, userlist,
  types, subTypes,
  tags,
  log,
  user,
  translations,
  allDataSources,
  fetchDataSources
}) => {
  // State Hooks
  const [requiredMap, setRequiredMap] = useState({});
  const [fieldErrors, setFieldErrors] = useState({
    logged_by: false,
    report_date: false,
    shift_name: false,
    description: !match.params.id,
    type: true,
    subtype: false,
    tags: false
  });


  let shift =getShiftTypeByDate(moment().format(), shiftTypes);
  const [answers, setAnswers] = useState({
    report_date: moment().format(),
    shift_name: shift ? shift.name : '',
    tags: []
  });
  if (answers.shift_name === '' && shift !== undefined) {
    setAnswers({ ...answers, shift_name: shift.name });
  }
  const [dynamicAnswers, setDynamicAnswers] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [schema, setSchema] = useState({});
  const [removedAttachments] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  let timeout;
  // Once Component mounts.
  useEffect(() => {
    actionClear();
    if (match.params.id) actionFetchLog(match.params.id);
    actionFetchForm();
    fetchDataSources();
    if (!shiftTypes.length) actionFetchShiftTypes();
    actionSearchUser(user.fullname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (schema && schema.fields) {
      let reqMap = {}; let fErrors = { ...fieldErrors };

      schema.fields.forEach((f) => {
        reqMap = { ...reqMap, [f.id]: f.required };
        fErrors = { ...fErrors, [f.id]: (match.params.id ? false : f.required) };
      });
      setRequiredMap(reqMap);
      setFieldErrors(fErrors);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  useEffect(() => {
    if (!match.params.id) setSchema(version.schema);
  }, [match.params.id, version]);

  useEffect(() => {
    if (match.params.id) {
      setAnswers({});
      if (log) {
        const fetchedAnswers = {
          logged_by: log.logged_by && log.logged_by.id,
          report_date: log.report_date,
          type: log.type,
          shift_name: log.shift_name,
          subtype: log.subtype,
          description: log.description,
          currentValue: log.logged_by && log.logged_by.fullname,
          tags: log.tags,
          attachments: log.attachments
        };
        setFieldErrors({ ...fieldErrors, type: false });
        setAnswers(fetchedAnswers);
        if (answers.shift_name === '' && shift !== undefined) {
          setAnswers({ ...answers, shift_name: shift.name });
        }
        setDynamicAnswers(log.response);
        setSchema(log.form.schema);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log, shift]);

  // fills the form with the log fetched
  // Redirects to list when save action finishes.
  useEffect(() => {
    if (saveAction.success) {
      history.push('/ops/logs');
      actionClear();
      setLoadingStatus(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAction]);


  // Handles the search for user's Autocomplete
  const handleSearchForUser = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  };

  const setanswers =(a, b) => {
    if (b==='report_date') {
      shift= getShiftTypeByDate(a, shiftTypes);
      setAnswers({ ...answers, shift_name: shift.name, [b]: a });
    } else if (b === 'removedAttachments') {
      removedAttachments.push(a);
    } else {
      setAnswers({ ...answers, [b]: a });
    }
  };

  const getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  const doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  // Handles the api call for create new Log
  const handleCreate = async () => {
    const areErrors = Object.values(fieldErrors).every(a => a);
    const noErrors = Object.keys(fieldErrors).every(k => (fieldErrors[k] === false));
    if (noErrors) {
      const subtype = subTypes.filter(s => s.activity_type.name === answers.type);
      if (subtype.length > 0 && !answers.subtype) {
        setShowErrors(true);
      } else if (areErrors) {
        setShowErrors(true);
      }
      setLoadingStatus(true);
      const data = answers;
      data.logged_by = answers.logged_by.id;
      if (removedAttachments.length > 0) {
        data.removedAttachments = removedAttachments;
      }
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k !== 'attachments') {
          formData.append(k, data[k]);
        } else if (k === 'removedAttachments') {
          formData.append(k, data[k]);
        }
      });

      let fileIds = '';
      const newAttachments = [];
      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((e) => {
          if (!e.id) {
            newAttachments.push(e);
          }
        });
      }
      if (newAttachments.length > 0) {
        fileIds = await getFileNames(newAttachments.length);
      } else {
        delete answers.attachments;
      }

      if (fileIds) {
        await doS3Upload(newAttachments, fileIds, 'operations_log')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });

            if (dynamicAnswers) {
              formData.append('response', JSON.stringify(dynamicAnswers));
            }
            if (answers.tags) {
              formData.append('tags', JSON.stringify(answers.tags));
            }
            if (match.params.id) {
              actionUpdateLog(match.params.id, formData);
            } else {
              actionCreateLog(formData);
            }
          });
      } else if (match.params.id) {
        actionUpdateLog(match.params.id, { ...answers, response: { ...dynamicAnswers } });
      } else {
        actionCreateLog({ ...answers, response: { ...dynamicAnswers } });
      }
    } else {
      setShowErrors(true);
    }
  };

  const errorsChanged = (a, b) => {
    setFieldErrors({ ...fieldErrors, [a]: b });
  };

  const translationsMap = translations ? translations[user.language] : {};
  const title = match.params.id ? 'operations.edit.title' : 'operations.create.formtitle';
  const buttonTitle = match.params.id ? 'operations.edit.update' : 'inspections.new.create';
  return (
    <div className={styles.newlog}>
      <SectionHeader icon={preset} translationID="operations.loglist.title"
        defaultTitle="Operations Log"
      />
      <HeaderBack
        translationID="inspections.new.prev"
        translationDefault="Back to Operations Log"
        backRoute={LOGS_HOME_ROUTE}
      />
      <Loading loadingStatus={loadingStatus} />
      <div className={`container ${styles.form}`}>
        <Panel title={title} defaultTitle="Create Log">
          <div className={`${styles.content} ${styles.embedded}`}>
            {(!match.params.id || (match.params.id && Object.keys(answers).length > 0)) && (
            <DefaultFields
              info={answers}
              userlist={userlist}
              searchForUser={handleSearchForUser}
              types={types}
              subtypes={subTypes}
              tags={tags}
              showFieldErrors={showErrors}
              handleAnswerChanged={(a, b) => setanswers(a, b)}
              handleFieldErrorChanged={errorsChanged}
              user={user}
            />
            )}
            <div className={styles.separator} />
            {Object.keys(requiredMap).length > 0 && allDataSources
                && schema.fields.map(field => (
                  <PulpoField
                    key={field.id} {...field}
                    translation={translationsMap && translationsMap[field.title]}
                    handleValueChange={(a, b) => setDynamicAnswers({ ...dynamicAnswers, [b]: a })}
                    isRequired={requiredMap[field.id]}
                    answer={dynamicAnswers[field.id]}
                    showFieldErrors={showErrors}
                    handleFieldErrorChanged={errorsChanged}
                    allDataSources={allDataSources}
                  />
                ))
                  }
          </div>

          <div className={`${styles.footer} ${styles.embedded}`}>
            <div className={styles.errors}>
              {fieldErrors.length > 0 && (
                fieldErrors.map(e => (
                  <>
                    <span>{`${e.id}: `}</span>
                    <FormattedMessage key={e.id} id={e.message}
                      defaultMessage="this field is required"
                    />
                  </>
                ))
              )}
            </div>
            
            <Button onClick={handleCreate} translationID={buttonTitle}
              defaultText="Create" action="secondary"
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

OperationLogCreate.propTypes = {
  actionFetchForm: PropTypes.func.isRequired,
  actionCreateLog: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  saveAction: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  subTypes: PropTypes.shape({}),
  // actionCreate: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
  version: state.opslogs.schema,
  log: state.opslogs.log,
  types: state.opslogs.types,
  subTypes: state.opslogs.subtypes,
  tags: state.opslogs.tags,
  saveAction: state.opslogs.saveAction,
  userlist: state.inspection.userlist,
  translations: state.auth.translations,
  user: state.auth.profile,
  shiftTypes: state.settings.shiftTypes,
  allDataSources: state.inspection.allDataSources
});

const mapDispatchToProps = dispatch => ({
  // Fetch form Schema
  actionFetchForm: () => {
    dispatch(fetchLogFormSchema());
  },
  // Fetch form Schema
  actionFetchLog: (id) => {
    dispatch(fetchLog(id));
  },
  // Fetch form Schema
  actionCreateLog: (data) => {
    dispatch(createLog(data));
  },
  // Fetch form Schema
  actionUpdateLog: (id, data) => {
    dispatch(updateLog(id, data));
  },
  // Fetch form Schema
  actionClear: () => {
    dispatch(clear());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  // Fetch form Schema
  actionSearchUser: (data) => {
    dispatch(searchUser(data));
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});

export default Permissions(['view_log'])(
  connect(mapStateToProps, mapDispatchToProps)(OperationLogCreate),
  <Forbidden />
);
