import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    padding: 15px;
    font-size: 18px;
    font-weight: 900;
    color: #000000;
  }

  .add-file-btn {
    cursor: pointer;
    font-size: 15px;
    line-height: 1.73;
    color: #3a61a8;
    padding: 12px;
  }

  .share-with {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 10px 0;
  }

  .upload-wrapper {
    display: flex;
  }
  
  .ui.search>.results{
    position: unset; 
  }
`;
