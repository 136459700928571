
const parseRRule = (
  predefinedRules,
  frequencies,
  rrule
) => {
  if (!rrule) return {};
  if (Object.keys(rrule).length === 0) {
    return { key: 'None', name: 'None' };
  }
  const result = {};
  const params = rrule.params && rrule.params.split(';');
  if (params) {
    params.forEach((p) => {
      const parts = p.split(':');
      switch (parts[0]) {
        case 'BYWEEKDAY': {
          const days = parts[1].split(',');
          result.days = {
            SU: days.find(d => d === 'SU') !== undefined,
            MO: days.find(d => d === 'MO') !== undefined,
            TU: days.find(d => d === 'TU') !== undefined,
            WE: days.find(d => d === 'WE') !== undefined,
            TH: days.find(d => d === 'TH') !== undefined,
            FR: days.find(d => d === 'FR') !== undefined,
            SA: days.find(d => d === 'SA') !== undefined
          };
          break;
        }
        case 'INTERVAL':
          [, result.interval] = parts;
          break;
        case 'BYMONTHDAY':
          [, result.monthDay] = parts;
          break;
        case 'BYSETPOS':
          [, result.setpos] = parts;
          break;
        case 'BYMONTH':
          [, result.month] = parts;
          break;
        default:
          break;
      }
    });
  }

  result.frequency = frequencies.find(r => r.key === rrule.frequency);
  // const rule = predefinedRules.find(r => r.key === rrule.key);
  let key = result.frequency && result.frequency.key.toLowerCase();
  if (typeof key === 'string') {
    key = key.charAt(0).toUpperCase() + key.slice(1);
  }
  // eslint-disable-next-line no-nested-ternary
  result.rule = rrule && rrule.id < 3 ? { id: rrule.id, name: rrule.name, key: rrule.name, frequency: rrule.frequency }
    : result.interval > 1 ? { key: 'custom', name: 'custom' }
      : { name: result.frequency && result.frequency.name, key, frequency: rrule.frequency };
  return result;
};
export default parseRRule;
