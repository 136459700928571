/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import styles from '../Create/steps/steps.module.scss';
import popupStyles from '../Detail/TenantsDetail.module.scss';

class PropertiesMap extends Component {
  defaultConfig = {
    marker: false,
    circle: false,
    rectangle: false,
    polyline: false,
    circlemarker: false,
    polygon: false
  };

  componentDidMount() {
    this.initializeMap();
  }


  initializeMap = () => {
    const { defaultLocation, allProperties, handleAnswerChanged,
      handleCloseMap, handleFieldErrorChanged } = this.props;
    const that = this;
    this.map = L.map('map', {
      center: [...defaultLocation].reverse(),
      zoom: 15,
      fullscreenControl: true
    });

    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);

    if (allProperties && allProperties.length) {
      allProperties.forEach((o) => {
        // eslint-disable-next-line no-underscore-dangle
        let l = L.geoJSON(o.geometry)._layers;
        [l] = Object.keys(l).map(ob => l[ob]);
        const customlayer = this.addPopupToLayer('#a6e50f', l);
        this.map.addLayer(customlayer);
        if (handleAnswerChanged) {
          l.on('click', () => {
            handleAnswerChanged('lease', o.id, 'lease_type');
            handleFieldErrorChanged('lease_type', false);
            handleCloseMap();
          });
        } else {
          l.on('click', () => {
            l.openPopup();
            const customOptions = { className: popupStyles.tooltip, width: '200' };
            const custompopup = that.addPopup(o);
            l.bindPopup(custompopup, customOptions).openPopup();
          });
        }

        if (allProperties.length === 1) {
          this.map.fitBounds(l.getBounds());
        }
      });
    }
  }

  addPopupToLayer = (color, layer) => {
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: color,
      color,
      opacity: 1,
      fillOpacity: 0.48
    });
    return customlayer;
  };

  handleZoomLevel = (zoomLevel) => {
    const { setZoomLevel } = this.props;
    setZoomLevel(zoomLevel);
  }

  addPopup = (property) => {
    const customPopup = `
    <div class=${popupStyles.tooltipHeader}>
    <h4>${property.name}</h4>
    </div>
    <p><b></b>${property.space_size}<b></b>sq.ft</p>`;

    return customPopup;
  }

  render() {
    const { mapStyles } = this.props;
    return <div id="map" className={mapStyles || styles.map} />;
  }
}

PropertiesMap.propTypes = {
  allProperties: PropTypes.arrayOf(PropTypes.shape({})),
  defaultLocation: PropTypes.arrayOf(PropTypes.number)
};

PropertiesMap.defaultProps = {
  allProperties: [],
  defaultLocation: [0, 0]
};

export default PropertiesMap;
