import {
  FETCH_PROPERTIES,
  ADD_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
  FETCH_PROPERTY_TYPES,
  UPLOAD_PROPERTY_TYPES,
  CLEAR_PROPERTY_ACTION,
  FETCH_SCHEMA,
  UPDATE_SCHEMA,
  CLEAR_MAINTENANCE,
  FETCH_CATEGORIES,
  UPDATE_CATEGORIES,
  ADD_CATEGORIES,
  FETCH_WORKORDERS,
  ADD_WORKORDERS,
  GET_WORKORDER,
  ADD_MAINTENANCE,
  FETCH_ALL_TYPES,
  UPLOAD_TYPES,
  CLEAR_TENANT_TYPES,
  FETCH_TENANTS,
  GET_TENANT,
  ADD_TENANT,
  GET_TENANT_NAMES,
  UPDATE_TENANT
} from './types';

import { actionForState } from '../../../utils/helpers';

const INITIAL_STATE = {
  error: {},
  action: {
    loading: false,
    success: undefined
  },
  createPropertyAction: {
    success: false,
    loading: false,
    error: false
  },
  updatePropertyAction: {
    success: false,
    loading: false,
    error: false
  },
  deletePropertyAction: {
    success: false,
    loading: false,
    error: false
  },
  MaintenanceAction: {
    success: false,
    loading: false,
    error: false
  },
  createWorkorderAction: {
    success: false,
    loading: false,
    error: false
  },
  createTenantAction: {
    success: false,
    loading: false,
    error: false
  },
  TenantUpdateAction: {
    success: false,
    loading: false,
    error: false
  },
  TenantGetAction: {
    success: false,
    loading: false,
    error: false
  }
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*              TASKS               */
    /* ******************************** */
    case FETCH_PROPERTIES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_PROPERTIES.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allProperties: action.payload
      };
    }
    case FETCH_PROPERTIES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_PROPERTY.pending:
      return {
        ...state,
        createPropertyAction: actionForState(state.action, 'pending')
      };
    case ADD_PROPERTY.success:
      return {
        ...state,
        createPropertyAction: actionForState(state.action, 'success')
      };
    case ADD_PROPERTY.error:
      return {
        ...state,
        createPropertyAction: actionForState(state.action, 'error')
      };
    case UPDATE_PROPERTY.pending:
      return {
        ...state,
        updatePropertyAction: actionForState(state.action, 'pending')
      };
    case UPDATE_PROPERTY.success:
      return {
        ...state,
        updatePropertyAction: actionForState(state.action, 'success')
      };
    case UPDATE_PROPERTY.error:
      return {
        ...state,
        updatePropertyAction: actionForState(state.action, 'error')
      };
    case DELETE_PROPERTY.pending:
      return {
        ...state,
        deletePropertyAction: actionForState(state.action, 'pending')
      };
    case DELETE_PROPERTY.success:
      return {
        ...state,
        deletePropertyAction: actionForState(state.action, 'success')
      };
    case DELETE_PROPERTY.error:
      return {
        ...state,
        deletePropertyAction: actionForState(state.action, 'error')
      };
    case FETCH_PROPERTY_TYPES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_PROPERTY_TYPES.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allTypes: action.payload
      };
    }
    case FETCH_PROPERTY_TYPES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case UPLOAD_PROPERTY_TYPES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case UPLOAD_PROPERTY_TYPES.success: {
      return {
        ...state,
        saveAction: actionForState(state.action, 'success'),
        allTypes: action.payload
      };
    }
    case UPLOAD_PROPERTY_TYPES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_SCHEMA.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_SCHEMA.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        leaseMaintenance_schema: action.payload
      };
    }
    case FETCH_SCHEMA.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case UPDATE_SCHEMA.success: {
      return {
        ...state,
        schemaAction: actionForState(state.action, 'success')
      };
    }
    case FETCH_CATEGORIES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_CATEGORIES.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allCategories: action.payload
      };
    }
    case FETCH_CATEGORIES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_CATEGORIES.success: {
      return {
        ...state,
        createCategoryAction: actionForState(state.action, 'success'),
        addedCategory: action.payload
      };
    }
    case UPDATE_CATEGORIES.success: {
      return {
        ...state,
        updateCategoryAction: actionForState(state.action, 'success'),
        updatedCategory: action.payload
      };
    }
    case FETCH_WORKORDERS.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_WORKORDERS.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allWorkOrders: action.payload
      };
    }
    case FETCH_WORKORDERS.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_WORKORDERS.pending:
      return {
        ...state,
        createWorkorderAction: actionForState(state.action, 'pending')
      };
    case ADD_WORKORDERS.success: {
      return {
        ...state,
        createWorkorderAction: actionForState(state.action, 'success'),
        createdWorkorder: action.payload
      };
    }
    case ADD_WORKORDERS.error:
      return {
        ...state,
        createWorkorderAction: actionForState(state.action, 'error')
      };
    case GET_WORKORDER.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case GET_WORKORDER.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        leaseWorkorder: action.payload
      };
    }
    case GET_WORKORDER.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_MAINTENANCE.pending:
      return {
        ...state,
        MaintenanceAction: actionForState(state.action, 'pending')
      };
    case ADD_MAINTENANCE.success: {
      return {
        ...state,
        MaintenanceAction: actionForState(state.action, 'success'),
        LeaseMaintenance: action.payload
      };
    }
    case ADD_MAINTENANCE.error:
      return {
        ...state,
        MaintenanceAction: actionForState(state.action, 'error')
      };
    case FETCH_ALL_TYPES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_ALL_TYPES.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allTenantTypes: action.payload
      };
    }
    case FETCH_ALL_TYPES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case UPLOAD_TYPES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case UPLOAD_TYPES.success: {
      return {
        ...state,
        uploadAction: actionForState(state.action, 'success'),
        uploadedTypes: action.payload
      };
    }
    case UPLOAD_TYPES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_TENANTS.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_TENANTS.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        allTenants: action.payload
      };
    }
    case FETCH_TENANTS.error:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case GET_TENANT.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case GET_TENANT.success: {
      return {
        ...state,
        action: actionForState(state.action, 'success'),
        tenant: action.payload
      };
    }
    case GET_TENANT.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case ADD_TENANT.pending:
      return {
        ...state,
        createTenantAction: actionForState(state.action, 'pending')
      };
    case ADD_TENANT.success: {
      return {
        ...state,
        createTenantAction: actionForState(state.action, 'success')
      };
    }
    case ADD_TENANT.error:
      return {
        ...state,
        createTenantAction: actionForState(state.action, 'error')
      };
    case GET_TENANT_NAMES.pending:
      return {
        ...state,
        TenantGetAction: actionForState(state.action, 'pending')
      };
    case GET_TENANT_NAMES.success: {
      return {
        ...state,
        TenantGetAction: actionForState(state.action, 'success'),
        TenantLookupData: action.payload
      };
    }
    case GET_TENANT_NAMES.error:
      return {
        ...state,
        TenantGetAction: actionForState(state.action, 'error')
      };
    case UPDATE_TENANT.pending:
      return {
        ...state,
        TenantUpdateAction: actionForState(state.action, 'pending')
      };
    case UPDATE_TENANT.success: {
      return {
        ...state,
        TenantUpdateAction: actionForState(state.action, 'success'),
        updatedTenant: action.payload
      };
    }
    case UPDATE_TENANT.error:
      return {
        ...state,
        TenantUpdateAction: actionForState(state.action, 'error')
      };
    case CLEAR_MAINTENANCE:
      return {
        ...state,
        leaseMaintenance_schema: {},
        schemaAction: {},
        MaintenanceAction: { success: false, loading: false, error: false },
        leaseWorkorder: {},
        createCategoryAction: {},
        updateCategoryAction: {},
        createWorkorderAction: {
          success: false, loading: false, error: false
        }
      };
    case CLEAR_PROPERTY_ACTION:
      return {
        ...state,
        createPropertyAction: {
          success: false, loading: false, error: false
        },
        deletePropertyAction: {
          success: false, loading: false, error: false
        },
        updatePropertyAction: {
          success: false, loading: false, error: false
        },
        allProperties: undefined,
        allTypes: undefined,
        saveAction: {},
        action: {
          loading: false,
          success: undefined
        }
      };
    case CLEAR_TENANT_TYPES:
      return {
        ...state,
        uploadAction: {
          success: false,
          loading: false,
          message: ''
        },
        createTenantAction: {
          success: false, loading: false, error: false
        },
        TenantUpdateAction: {
          success: false, loading: false, error: false
        }
      };
    default:
      return state;
  }
}
