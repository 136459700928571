/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Datetime from 'react-datetime';
import moment from 'moment';
import styles from './scheduler.module.scss';
import ColorSelect from '../colorSelect/colorSelect';
import eventIcon from '../../icons/event.svg';
import Day from './Day';
import MonthYearSelector from './MonthYearSelector';
import Collapsible from '../collapsible/Collapsible';
import parseRRule from './utils';
// import '../topbar/profile/View/toggle.scss';

const Scheduler = ({ title, rules, onScheduleChange, selected, intl, fromInspection, taskEdit, tasks }) => {
  const frequencyOptions = [
    { key: 'DAILY', name: intl.formatMessage({ id: 'inspections.start_inspection.daily' }) },
    { key: 'WEEKLY', name: intl.formatMessage({ id: 'inspections.start_inspection.weekly' }) },
    { key: 'MONTHLY', name: intl.formatMessage({ id: 'inspections.start_inspection.monthly' }) },
    { key: 'YEARLY', name: intl.formatMessage({ id: 'inspections.start_inspection.yearly' }) }
  ];

  // Hooks
  const [rule, setRule] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const [frequency, setfrequency] = useState(frequencyOptions[1]);
  const [interval, setScheduleInterval] = useState(1);
  const [monthParams, setMonthParams] = useState();
  const [endPeriod, setEndPeriod] = useState();
  const endPeriodCollapse = useRef(null);
  const [daysMap, setDaysMap] = useState(
    { SU: false,
      MO: false,
      TU: false,
      WE: false,
      TH: false,
      FR: false,
      SA: false }
  );
  useEffect(() => {
    if (selected) {
      const defValues = parseRRule(rules, frequencyOptions, selected.rule);
      setDefaultValues(defValues);
      (defValues.rule) && setRule(defValues.rule);
      (defValues.frequency) && setfrequency(defValues.frequency);
      (defValues.interval) && setScheduleInterval(defValues.interval);
      (defValues.days) && setDaysMap(defValues.days);
      (selected.end_recurring_period) && setEndPeriod(
        moment.utc(selected.end_recurring_period)
      );
    }
  }, [fromInspection=== true ?selected: '']);
  const therules = [
    { key: 'None', name: intl.formatMessage({ id: 'Asset.settings.None' }) },
    { id: '1', key: 'Daily', name: intl.formatMessage({ id: 'inspections.start_inspection.daily' }), frequency: 'DAILY', params: '' },
    {
      id: '2',
      key: 'Weekday',
      name: intl.formatMessage({ id: 'schedule.frequency.Weekday' }),
      frequency: 'Weekday',
      params: 'BYWEEKDAY=MO,TU,WE,TH,FR;INTERVAL=1'
    },
    { key: 'Weekly', name: intl.formatMessage({ id: 'inspections.start_inspection.weekly' }), frequency: 'WEEKLY', params: '' },
    { key: 'Monthly', name: intl.formatMessage({ id: 'inspections.start_inspection.monthly' }), frequency: 'MONTHLY', params: '' },
    { key: 'Yearly', name: intl.formatMessage({ id: 'inspections.start_inspection.yearly' }), frequency: 'YEARLY', params: '' },
    { key: 'custom', name: intl.formatMessage({ id: 'schedule.frequency.custom' }) }];

  const daysOptions = [
    { id: 'SU', code: 'schedule.day.sunday.code' },
    { id: 'MO', code: 'schedule.day.monday.code' },
    { id: 'TU', code: 'schedule.day.tuesday.code' },
    { id: 'WE', code: 'schedule.day.wednesday.code' },
    { id: 'TH', code: 'schedule.day.thursday.code' },
    { id: 'FR', code: 'schedule.day.friday.code' },
    { id: 'SA', code: 'schedule.day.saturday.code' }
  ];
  // every time that one of [rule, daysMap, frequency] changes,
  // it calls the funciton.
  useEffect(() => {
    if (rule && rule.key === 'custom') {
      let params;
      if (frequency && frequency.key === 'WEEKLY') {
        const days = Object.keys(daysMap).filter(k => daysMap[k]).join(',');
        params = `BYWEEKDAY:${days};INTERVAL:${interval}`;
      } else {
        params = `${monthParams};INTERVAL:${interval}`;
      }
      onScheduleChange({ undefined, endPeriod: undefined, frequency: frequency.key, params });
    } else if (rule && rule.key !== 'None') {
      let param;
      if (rule && (rule.key === 'Daily' || rule.key === 'Weekday')) {
        if (endPeriod === "") {
          onScheduleChange({ id: rule.id, endPeriod: undefined, frequency: undefined, params: undefined });
        } else {
          onScheduleChange({ id: rule.id, endPeriod, frequency: undefined, params: undefined });
        }
      }
      if (rule && rule.key === 'Weekly') {
        const days = Object.keys(daysMap).filter(k => daysMap[k]).join(',');
        setScheduleInterval('1');
        param = `BYWEEKDAY:${days};INTERVAL:${interval}`;
        onScheduleChange({ undefined, endPeriod: undefined, frequency: rule.key.toUpperCase(), params: param });
      }
      if (rule && (rule.key === 'Monthly' || rule.key === 'Yearly')) {
        param = `${monthParams};INTERVAL:${interval}`;
        setScheduleInterval('1');
        onScheduleChange({ undefined, endPeriod: undefined, frequency: rule.key.toUpperCase(), params: param });
      }
    } else if (rule && rule.key === 'None') {
      onScheduleChange({ undefined, endPeriod: undefined, frequency: undefined, params: undefined });
    }
  }, [rule, daysMap, frequency, interval, monthParams, endPeriod, setEndPeriod]);

  const handleSetRule = (e) => {
    setRule(e);
    if (fromInspection === true && selected && selected.rule && e.name === selected.rule.name && selected.end_recurring_period !== "") {
      setEndPeriod(moment.utc(selected.end_recurring_period));
    } else {
      setEndPeriod('');
    }
  };

  return (
    <div className={styles.scheduler}>
      <div className={styles.title}>
        <FormattedMessage id={title} defaultMessage={title} />
      </div>
      <div className={styles.colSelectTime}>
        <label>
          <FormattedMessage id="schedule.title.time" defaultMessage="time to be repeated" />
          <ColorSelect value={rule && rule ? rule : therules[0]} options={therules}
            onChange={e => handleSetRule(e)} bordered
          />
        </label>
      </div>
      <div className={`${styles.col} ${styles.infoRepeat}`}>
        {rule ? (
          <>
            {rule && (rule.key === 'Daily' || rule.key === 'Weekday') && (
              <div>
                <span>
                  <img src={eventIcon} alt="event" />
                  {`${intl.formatMessage({ id: 'schedule.title.taskrepeated' })} ${rule.key} ${endPeriod ? 'until ' : ''}`}
                </span>
                <Collapsible ref={endPeriodCollapse} styleClasses={styles.endPeriod}
                  title={(endPeriod && endPeriod.format('MM/DD/YYYY')) || intl.formatMessage({ id: 'schedule.title.forever' })}
                >
                  <div>
                    <Datetime
                      input={false}
                      defaultValue={endPeriod}
                      open timeFormat={false}
                      closeOnSelect
                      isValidDate={(current) => {
                        const yesterday = Datetime.moment().subtract(1, 'day');
                        return current.isAfter(yesterday);
                      }}
                      onChange={(e) => {
                        setEndPeriod(e);
                        endPeriodCollapse.current.onItemClick();
                      }}
                      id="endPeriod"
                    />
                  </div>
                </Collapsible>
              </div>
            )}
            {rule && (rule.key === 'Weekly' || rule.frequency === 'WEEKLY') && (
              <label>
                <FormattedMessage id="schedule.title.Days" defaultMessage="Days" />
                <div className={styles.days}>
                  {daysOptions.map(d => (
                    <Day day={d} active={daysMap[d.id]}
                      onClick={id => setDaysMap({ ...daysMap, [id]: !daysMap[id] })}
                    />
                  ))}
                </div>
              </label>
            )}
            {rule && (rule.key === 'Yearly' || rule.key === 'Monthly') && (rule.frequency === 'YEARLY' || rule.frequency === 'MONTHLY') && (
              <MonthYearSelector values={defaultValues}
                type={rule.frequency} onSelect={setMonthParams}
                fullWidth={tasks ? true : undefined}
              />
            )}
          </>
        )
          : (
            <FormattedMessage tagName="p"
              id="schedule.repeat.none" defaultMessage="This task will not be repeated"
            />
          )}
      </div>
      {rule && rule.key === 'custom' && (
      <div className={styles.custom}>
        <div className={styles.col}>
          <label>
            <FormattedMessage id="schedule.repeat.title" defaultMessage="Repeat Every" />
            <div className={styles.inputs}>
              <input type="number" defaultValue={interval}
                onChange={e => setScheduleInterval(e.target.value)}
              />
              <ColorSelect value={frequency} options={frequencyOptions}
                onChange={setfrequency} bordered
              />
            </div>
          </label>
        </div>
        <div className={styles.col}>
          {frequency && frequency.key === 'WEEKLY' && (
          <label>
            <FormattedMessage id="schedule.title.Days" defaultMessage="Days" />
            <div className={styles.days}>
              {daysOptions.map(d => (
                <Day day={d} active={daysMap[d.id]}
                  onClick={id => setDaysMap({ ...daysMap, [id]: !daysMap[id] })}
                />
              ))}
            </div>
          </label>
          )}
          {frequency && (
            frequency.key === 'MONTHLY' || frequency.key === 'YEARLY') && (
            <MonthYearSelector values={defaultValues}
              type={frequency.key} onSelect={setMonthParams}
            />
          )}
        </div>
      </div>
      )}
    </div>
  );
};

Scheduler.propTypes = {
  title: PropTypes.string.isRequired
};
export default injectIntl(Scheduler);
