import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';
import styles from './map.module.scss';
import Button from '../../../components/button';
import ColorSelect from '../../../components/colorSelect';

class Toolbar extends Component {
  constructor(props) {
    super(props);
    const { types, allLayers } = this.props;
    this.state = {
      editMode: false,
      typeSelected: types[0],
      layerSelected: allLayers[0]
    };
    this.toggleView = this.toggleView.bind(this);
  }


  toggleView() {
    const { changeView } = this.props;
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }));
    const { editMode } = this.state;
    changeView(editMode);
  }

  render() {
    const { editMode, layerSelected, typeSelected } = this.state;
    const { onTypeChange, onLayerChange, types, allLayers, surfaceCounter } = this.props;
    if (editMode) {
      return (
        <div className={styles.toolbar}>
          <button type="button" onClick={this.toggleView} className={styles.button}>
            <FormattedMessage id="section.toolbar.airfield" defaultMessage="Back to Airfield Surfaces View" />
          </button>
          <div className={styles.typeSelector}>
            <ColorSelect options={types}
              value={typeSelected}
              onChange={(e) => { onTypeChange(e); this.setState({ typeSelected: e }); }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.toolbar}>
        <div className={styles.selector}>
          <ColorSelect options={allLayers}
            value={layerSelected}
            onChange={(e) => { onLayerChange(e); this.setState({ layerSelected: e }); }}
          />
          <div className={styles.counter}>
            <span>{`${surfaceCounter} `}</span>
            <FormattedMessage id="section.toolbar.surfaces" defaultMessage="Surfaces" />
          </div>
        </div>
        <Permissions allowed={['can_modify_airport_settings']}>
          <Button translationID="section.toolbar.create"
            className={styles.button}
            defaultText="Create New Surface"
            onClick={this.toggleView}
          />
        </Permissions>


      </div>
    );
  }
}

export default Toolbar;

Toolbar.propTypes = {
  allLayers: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.arrayOf(PropTypes.object),
  surfaceCounter: PropTypes.number,
  changeView: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onLayerChange: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
  allLayers: [],
  types: [],
  surfaceCounter: 0
};
