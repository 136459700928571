import React from 'react';
import {
  Button as LibButton,
  ButtonProps as LibButtonProps
} from 'semantic-ui-react';
import { Wrapper } from './Button.styles';

type ButtonProps = {
  width?: number;
  borderRadius?: number;
} & LibButtonProps;

const Button: React.FC<ButtonProps> = ({ width, borderRadius, ...restProps }) => (
  <Wrapper width={width} borderRadius={borderRadius}>
    <LibButton {...restProps} />
  </Wrapper>
);

export default Button;
export { Button };
