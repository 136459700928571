import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { validateMessage } from '../../validators/messageSchema';
import {
  Attachments,
  ChanelTypes,
  CommentMessage,
  CreateMessagesTypes,
  CurrentUser,
  MessageFormValues,
  PostMessageType,
  SetFilesType,
  SetListHeightFunc,
  SetOpenModalType,
  UpdateMessagesTypes
} from '../../types';
import { commentsActions, useChanelState } from '../../state/channelsState';
import { useGetUserDetails } from '../../graphql/hooks';
import { ChatTypes } from '../../enums';
import { UploadFile } from '../../services';
import Loading from '../../../../components/loading/index';
import { MessageForm as Form } from '../../components';
import { makeInitValues, onHeightChange } from './utils';
import { postActions } from '../../state/channelsState/actions';

type ChatMessageFormProps = {
  setListHeight: SetListHeightFunc;
  createMessages: CreateMessagesTypes;
  updateMessages: UpdateMessagesTypes;
  editedMessage?: PostMessageType | CommentMessage;
  currentUser: CurrentUser;
  type?: ChanelTypes;
  uploadFileMode?: boolean;
  setFiles?: SetFilesType;
  attachedFiles?: File[];
  setOpenModal?: SetOpenModalType;
  topicsConnected: boolean;
};

const initialValues: MessageFormValues = { content: '' };

const ChatMessageForm: React.FC<ChatMessageFormProps> = ({
  setListHeight,
  createMessages,
  updateMessages,
  editedMessage,
  currentUser,
  uploadFileMode = false,
  type,
  setOpenModal,
  attachedFiles: attachments,
  topicsConnected
}) => {
  const {
    state: {
      posts: {
        channelData: { channelId, channelName }
      },
      comments: { postId, channelId: commentChannelId, commentId, messageData }
    },
    dispatch
  } = useChanelState();
  const [attachmentArr, setAttachmentArr] = useState<File[]>(null);
  const [loading, setLoading] = useState(false);
  const { userId } = currentUser;
  const { data: userDetails } = useGetUserDetails(userId);

  if (!userDetails) {
    return <Loading loadingStatus />;
  }
  const isComment = type === ChatTypes.comment;

  const closeEdit = () => {
    if (isComment) {
      commentsActions.setEditedMessage(dispatch, {
        editedCommentID: null
      });
      return;
    }
    postActions.setEditedMessage(dispatch, {
      editedPostID: null
    });
  };

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    const { content }: { content: string } = values;
    const isValid = validateMessage(values);

    if (!isValid) {
      toast.error('Please enter message content');
      setSubmitting(false);
      return;
    }

    if (uploadFileMode && type === ChatTypes.operations) {
      setLoading(true);
      let filesWithUrls: Attachments[];

      if (attachments) {
        const formData = new FormData();

        attachments.forEach((fl) => {
          formData.append('file', fl);
        });
        filesWithUrls = await UploadFile.uploadFiles(formData, 'channel');
      }

      createMessages[type]({
        channelId,
        content,
        attachments: filesWithUrls
      });

      setOpenModal(false);
      setLoading(false);
      setAttachmentArr(() => null);
      resetForm();
      setSubmitting(false);
      return;
    }

    if (editedMessage && type === ChatTypes.comment) {
      updateMessages[type]({
        commentId,
        content
      });

      resetForm();
      setSubmitting(false);
      return;
    }

    if (type === ChatTypes.comment) {
      createMessages[type]({
        channelId: commentChannelId,
        content,
        parentCommentId: null,
        postId
      });
      resetForm();
      setSubmitting(false);
      return;
    }

    if (editedMessage && type === ChatTypes.operations) {
      updateMessages[type](
        {
          postId: editedMessage.postId,
          content,
          attachments
        },
        messageData?.postId
      );
      resetForm();
      setSubmitting(false);
      return;
    }

    createMessages[type]({
      channelId,
      content,
      attachments
    });

    if (uploadFileMode) {
      setOpenModal(false);
    }
    resetForm();
    setSubmitting(false);
  };

  const initValues = makeInitValues(initialValues, editedMessage);

  return (
    <Form
      onHeightChange={onHeightChange(setListHeight)}
      onSubmit={onSubmit}
      uploadFileMode={uploadFileMode}
      loading={loading}
      isComment={isComment}
      type={type}
      userDetails={userDetails}
      initValues={initValues}
      currentUser={currentUser}
      channelName={channelName}
      createMessages={createMessages}
      setAttachmentArr={setAttachmentArr}
      attachedFiles={attachmentArr}
      topicsConnected={topicsConnected}
      closeEdit={closeEdit}
      editedMessage={editedMessage}
    />
  );
};

export default ChatMessageForm;
export { ChatMessageForm };
