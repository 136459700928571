import { toast } from 'react-toastify';
import axios from 'axios';
import { apiRouts } from '../../constants';

class GetFileName {
  getUploadFileName = async (count) => {
    try {
      const { data } = await axios.get(
        apiRouts.getUploadFileName + '/upload/getfilename' + (count ? '?number='+count : ''),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      return data;
    } catch (err) {
      toast.error(err?.response?.data.message || err.message);
      return null;
    }
  };
}

const Service = new GetFileName();

export default Service;
export { Service as GetFileName };
