import styled from "styled-components";

type WrapperProps = {
  bordered: boolean;
  height?: number;
  minHeight?: number;
}


export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  textarea {
    width: 100%;
    border: ${({bordered, theme}) => bordered ? `solid 1px ${theme.colors.border.default}` : 'unset'};
    border-radius: 4px;
    min-height: ${({ minHeight }) => minHeight ? `${minHeight}px` : 'unset'};
    height: ${({ height }) => height ? `${height}px` : 'unset'};
    resize: none;
    line-height: 20px;
  }
`;
