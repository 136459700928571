import React from 'react';
import { shallow } from 'enzyme';
import Boolean from '../Boolean';

const props = {
  widget: 'checkbox',
  handleValueChange: jest.fn(),
  fieldId: 'test',
  answer: true,
  isRequired: true,
  handleFieldErrorChanged: jest.fn(),
  updateFieldErrors: jest.fn()
}

describe('Boolean', () => {
  it('should render the Boolean component correctly', () => {
    const wrapper = shallow(<Boolean {...props} />);
    expect(wrapper).toMatchSnapshot();
  });
});
