import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .input {
    width: 100%;
  }
`;
