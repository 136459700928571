import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import {
  EDIT_AIRPORT_INFO,
  FETCH_AIRPORT_INFO,
  CLEAR_AIRPORT_ACTION,
  FETCH_ROLES,
  FETCH_PRIVILEGES,
  CREATE_ROLE,
  EDIT_ROLE,
  CLEAR_SETTINGS_ACTION,
  FETCH_USERS,
  EDIT_USER,
  FETCH_SHIFT_TYPES,
  DELETE_USER,
  EDIT_SHIFT_TYPES
} from './types';

import { actionForState } from '../../../utils/helpers';

const INITIAL_STATE = {
  airport: {},
  roles: [],
  error: {},
  action: {
    loading: false,
    success: undefined
  },
  apiStatusUser: {
    loading: undefined,
    success: undefined,
    message: undefined
  },
  actionPrivileges: {
    loading: false,
    success: undefined
  },
  actionCreateRole: {
    loading: false,
    success: undefined
  },
  privileges: {},
  shiftTypes: [],
  editAirportInfo: {},
  toastId: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*          AIRPORT INFO            */
  /* ******************************** */
    case FETCH_AIRPORT_INFO.success:
      return {
        ...state,
        airport: action.payload
      };
    case FETCH_AIRPORT_INFO.error:
      return state;
    case EDIT_AIRPORT_INFO.pending:
      state.toastId = toast(<FormattedMessage id="airport.form.pending.message" defaultMessage="Airport information in Pending" />, { type: toast.TYPE.INFO });
      return {
        ...state,
        editAirportInfo: actionForState(state.action, 'pending')
      };
    case EDIT_AIRPORT_INFO.success: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="airport.form.success" defaultMessage="The information was successfully submitted" />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        airport: action.payload,
        editAirportInfo: actionForState(state.action, 'success')
      };
    }
    case EDIT_AIRPORT_INFO.error:
      toast.update(state.toastId, {
        render: <FormattedMessage id="airport.form.error.message" defaultMessage="Error occured while submitting." />,
        type: toast.TYPE.ERROR,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        editAirportInfo: actionForState(state.action, 'failed')
      };
    case CLEAR_AIRPORT_ACTION:
      return {
        ...state,
        action: {
          ...state.action,
          loading: false,
          success: undefined,
          message: ''
        }
      };
    case CLEAR_SETTINGS_ACTION:
      return {
        ...state,
        error: {},
        actionCreateRole: {
          success: undefined,
          loading: false,
          message: ''
        },
        apiStatusUser: {
          success: undefined,
          loading: undefined,
          message: undefined
        },
        actionPrivileges: {
          success: undefined,
          loading: false,
          message: ''
        },
        actionEditRole: {
          success: undefined,
          loading: false,
          message: ''
        },
        actionDeleteUser: {
          success: undefined,
          loading: false,
          message: ''

        }
      };
    /* ******************************** */
    /*              ROLES               */
    /* ******************************** */
    case FETCH_ROLES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_ROLES.success:
      return {
        ...state,
        roles: action.payload.results,
        action: actionForState(state.action, 'success')
      };
    case FETCH_ROLES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           PRIVILEGES             */
    /* ******************************** */
    case FETCH_PRIVILEGES.pending:
      return {
        ...state,
        actionPrivileges: actionForState(state.action, 'pending')
      };
    case FETCH_PRIVILEGES.success:
      return {
        ...state,
        privileges: action.payload,
        actionPrivileges: actionForState(state.action, 'success')
      };
    case FETCH_PRIVILEGES.error:
      return {
        ...state,
        actionPrivileges: actionForState(state.action, 'error')
      };

    /* ******************************** */
    /*           CREATE ROLE            */
    /* ******************************** */
    case CREATE_ROLE.pending:
      return {
        ...state,
        actionCreateRole: actionForState(state.action, 'pending')
      };
    case CREATE_ROLE.success:
      return {
        ...state,
        role: action.payload,
        actionCreateRole: actionForState(state.action, 'success')
      };
    case CREATE_ROLE.error:
      return {
        ...state,
        actionCreateRole: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*            EDIT ROLE             */
    /* ******************************** */
    case EDIT_ROLE.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.role.edit.pendingMessage" defaultMessage="Role Edit in Progress." />,
        { type: toast.TYPE.INFO });
      return {
        ...state,
        actionEditRole: actionForState(state.action, 'pending')
      };
    case EDIT_ROLE.success:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.role.edit.successMessage" defaultMessage="Role Edited successfully." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        role: action.payload,
        actionEditRole: actionForState(state.action, 'success')
      };
    case EDIT_ROLE.error:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.role.edit.errorMessage" defaultMessage="Error occured while editing Role." />,
        type: toast.TYPE.ERROR,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        actionEditRole: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           FETCH USERS            */
    /* ******************************** */
    case FETCH_USERS.pending:
      return {
        ...state,
        actionFetchUsers: actionForState(state.action, 'pending')
      };
    case FETCH_USERS.success:
      return {
        ...state,
        users: action.payload,
        actionFetchUsers: actionForState(state.action, 'success')
      };
    case FETCH_USERS.error:
      return {
        ...state,
        actionFetchUsers: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           UPDATE USER            */
    /* ******************************** */
    case EDIT_USER.pending:
      return {
        ...state,
        apiStatusUser: actionForState(state.action, 'pending')
      };
    case EDIT_USER.success:
      return {
        ...state,
        user: action.payload,
        apiStatusUser: actionForState(state.action, 'success')
      };
    case EDIT_USER.error:
      return {
        ...state,
        error: action.payload.response.data,
        apiStatusUser: actionForState(state.action, 'error')
      };
    default:
      return state;
    /* ******************************** */
    /*          DELETE USERS            */
    /* ******************************** */
    case DELETE_USER.pending:
      return {
        ...state,
        apiStatusUser: actionForState(state.action, 'pending')
      };
    case DELETE_USER.success:
      return {
        ...state,
        user: action.payload,
        apiStatusUser: actionForState(state.action, 'success')
      };
    case DELETE_USER.error:
      return {
        ...state,
        apiStatusUser: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           FETCH USERS            */
    /* ******************************** */
    case FETCH_SHIFT_TYPES.pending:
      return {
        ...state,
        actionFetchShiftTypes: actionForState(state.action, 'pending')
      };
    case FETCH_SHIFT_TYPES.success:
      return {
        ...state,
        shiftTypes: action.payload.results,
        actionFetchShiftTypes: actionForState(state.action, 'success')
      };
    case FETCH_SHIFT_TYPES.error:
      return {
        ...state,
        actionFetchShiftTypes: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           UPDATE SHIFT TYPES         */
    /* ******************************** */
    case EDIT_SHIFT_TYPES.pending:
      return {
        ...state,
        shiftUpdateStatus: actionForState(state.action, 'pending')
      };
    case EDIT_SHIFT_TYPES.success:
      return {
        ...state,
        shift: action.payload,
        shiftUpdateStatus: actionForState(state.action, 'success')
      };
    case EDIT_SHIFT_TYPES.error:
      return {
        ...state,
        error: action.payload.response.data,
        shiftUpdateStatus: actionForState(state.action, 'error')
      };
  }
}
