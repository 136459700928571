
export enum MENU_IDS {
  comment = "Comment",
  edit = "Edit",
  delete = "Delete",
  insertSmile = "Insert Smile"
}

export const menuItems = [
  {
    key: MENU_IDS.insertSmile,
    isPrivate: false,
  },
  {
    key: MENU_IDS.comment,
    isPrivate: false,

  },
  {
    key: MENU_IDS.edit,
    isPrivate: true
  },
  {
    key: MENU_IDS.delete,
    isPrivate: true
  }
];
