import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import PulpoField from '../../../../../../pulpo_visualizer/fields';
import Clickable from '../../../../../../components/clickable/Clickable';
import Modal from '../../../../../../components/modal/modal';
import Seperator from '../../../../../../components/separator/Separator';
import FormattedMessageWithClass from '../../../../../../components/formattedMessageWithClass';
import PropertiesMap from '../../../Map/PropertiesMap';
import styles from '../steps.module.scss';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMap: false
    };
  }

  handleDrop = (dropped) => {
    const { info, handleAnswerChanged } = this.props;
    const res = info.attachments || [];
    handleAnswerChanged('lease', [...res, ...dropped], 'attachments');
  };

  handleRemoveImage = (r) => {
    const { info, handleAnswerChanged } = this.props;
    const res = info.attachments.filter(e => e !== r);
    handleAnswerChanged('lease', res, 'attachments');
    handleAnswerChanged('lease', res.id, 'removedAttachments');
  };

  handleOpenMap = () => {
    this.setState({ viewMap: true });
  }

  handleCloseMap = () => {
    this.setState({ viewMap: false });
  }

  render() {
    const { allProperties, handleAnswerChanged, handleFieldErrorChanged,
      showFormErrors, user, info } = this.props;
    const { viewMap } = this.state;
    const property = info && info.lease_type && allProperties.filter(e => (e.id.toString() === info.lease_type.toString()));
    return (
    <>
      <div className={styles.stepTwo}>
        <div className={styles.lease}>
          <FormattedMessage id="lease.tenants.steps.step2.title" defaultMessage="Leases" />
        </div>
        { allProperties && allProperties.length > 0 && (
          <>
            <div className={styles.sectionHead}>
              <FormattedMessage id="lease.tenants.steps.step2.lease_detail" defaultMessage="Lease Details" />
            </div>
            <div className={styles.fullInput}>
              <Clickable className={styles.mapLink} onClick={() => this.handleOpenMap()}>
                <FormattedMessage id="lease.tenants.steps.step2.map_view" defaultMessage="Map view" />
              </Clickable>
            </div>
            <PulpoField key="lease_type" id="lease_type" type="select"
              transationID="tenants.new.leaseterm" className={styles.fullInput}
              title="Type of lease" isRequired
              handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
              answer={info && info.lease_type}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
              values={allProperties ? allProperties.map(e => (
                ({ key: e.id.toString(), value: e.name })
              )) : []}
            />
        </>
        )}
        {property && property.length > 0 && (
          <>
            <div className={styles.endingBorder} />
            <div className={styles.requestInfo}>
              <div className={styles.infoTable}>
                <div className={styles.infoRow}>
                  <div className={styles.column}>
                    <h2>{property[0].name}</h2>
                  </div>
                  <Seperator />
                  <div className={styles.column}>
                    <p>
                      <FormattedMessage id="lease.property.space" defaultMessage="Space Size" />
                    </p>
                    {property[0].space_size || '-'}
                  </div>
                  <Seperator />
                  <div className={styles.column}>
                    <p>
                      <FormattedMessage id="lease.property.floor_plan" defaultMessage="Floor Plan" />
                    </p>
                    {property[0].plan_attachment.length > 0 ? (
                      <a href={property[0].plan_attachment[0].url} target="_blank" rel="noopener noreferrer">
                        {property[0].plan_attachment[0].file_name}
                      </a>
                    ): '-' }
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.sectionHead}>
          <FormattedMessage id="lease.tenants.lease_terms" defaultMessage="Lease Terms" />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField
            key="annual_rent" id="annual_rent" type="currency-phone"
            translationID="tenants.new.annual_rent"
            title="Annual Rent" thousandSeparator
            placeHolderValue="$" suffix=".00" prefix="$"
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.annual_rent}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
          <PulpoField
            key="monthly_rent" id="monthly_rent" type="currency-phone"
            translationID="tenants.new.monthly_rent" thousandSeparator
            title="Monthly Rent" placeHolderValue="$" suffix=".00" prefix="$"
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.monthly_rent}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField
            key="start_date" id="start_date" type="datetime"
            translationID="tenants.new.start_date"
            title="Starting Date" noDefault className={styles.datetime}
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.start_date}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
          <PulpoField
            key="end_date" id="end_date" type="datetime"
            translationID="tenants.new.end_date"
            title="Ending Date" noDefault className={styles.datetime}
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.end_date}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField
            key="adjustment_date" id="adjustment_date" type="datetime"
            translationID="tenants.new.annual_adjustment"
            title="Annual Adjustment Date" noDefault className={styles.datetime}
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.adjustment_date}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
          <PulpoField
            key="renewal_options" id="renewal_options" type="string"
            translationID="lease.tenants.steps.step2.renewal"
            title="Renewal Options"
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.renewal_options}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
          />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField key="lease_term" id="lease_term" type="select"
            transationID="lease.tenants.lease_terms" className={styles.fullInput}
            title="Lease Terms"
            handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
            answer={info && info.lease_term}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
            values={[
              { key: 'Annual', value: 'Annual' },
              { key: 'Multi', value: 'Multi' },
              { key: 'Seasonal', value: 'Seasonal' }
            ]}
          />
        </div>
        <PulpoField
          key="description" id="description" type="string"
          widget={{ type: 'textarea' }}
          translationID="tenants.new.additional_remarks" className={styles.fullInput}
          title="Additional Remarks"
          handleValueChange={(a, b) => handleAnswerChanged('lease', a, b)}
          answer={info && info.description}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
        />
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.label} id="lease.tenants.steps.step2.lease_attachments"
            defaultMessage="Lease Attachments"
          />
          <Dropzone
            onDrop={this.handleDrop}
            disableClick
            className={styles.dropzone} accept="image/*, .pdf, .doc"
          >
            {({ open }) => (
              <>
                <p className={styles.browsetext}>
                  <FormattedMessage id="airport.logo.text1" defaultMessage="Drag an image here or" />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button" defaultMessage="browse" />
                  </button>
                  <FormattedMessage id="airport.logo.text2" defaultMessage="for an image to upload." />
                </p>
              </>
            )}
          </Dropzone>
          {info && info.attachments && info.attachments.length > 0 && (
          <div className={styles.photos}>
            {info.attachments.map(e => (
              <div key={e.name} className={styles.wrapper}>
                <Clickable onClick={() => this.handleRemoveImage(e)}>&times;</Clickable>
                {(e.type).includes('image') ? (
                  <img src={e.preview} alt={e.name} />
                ) : <p>{e.name}</p>
                }
              </div>
            ))}
          </div>
          )}
        </div>
      </div>
      {viewMap && (
        <Modal
          width="60%"
          minHeight="50%"
          showIn={viewMap}
          centered
          onClose={this.handleCloseMap}
        >
            {allProperties && user.airport.location.coordinates && (
            <PropertiesMap
              allProperties={allProperties}
              defaultLocation={user.airport.location.coordinates}
              handleAnswerChanged={handleAnswerChanged}
              handleFieldErrorChanged={(a, b) => handleFieldErrorChanged('leaseErrors', a, b)}
              handleCloseMap={this.handleCloseMap}
              mapStyles={styles.map}
            />
            )}
        </Modal>
      )}
    </>
    );
  }
}

export default Step2;
