import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HOC as Permissions } from 'react-redux-permissions';
import PulpoField from '../../../../pulpo_visualizer/fields/PulpoField';
import SectionHeader from '../../../../components/sectionHeader';
import HeaderBack from '../../../../components/headerBack/HeaderBack';
import Panel from '../../../../components/panel/Panel';
import Button from '../../../../components/button/button';
import Loading from '../../../../components/loading/loading';
import Forbidden from '../../../Forbidden';
import { LEASE_HOME } from '../../../../constants/RouterConstants';
import icon from '../../../../icons/WorkOrders.png';
import WorkorderStyles from '../../../workorders/Create/workOrderCreate.module.scss';
import FixedFeilds from './FixedFeilds';
import { S3Uploads, GetFileName } from '../../../services';
import { searchUser, fetchDataSourceList } from '../../../inspections/redux/actions';
import { fetchCategories, fetchWorkOrderSchema, createLeaseWokrorder, clearMaintenance,
  fetchProperties, getTenantLookup } from '../../redux/actions';

class MaintenanceCreate extends Component {
  state = {
    info: {
      report_date: moment().format(),
      attachments: [],
      logged_by: {},
      priority: '0'
    },
    answers: {},
    requiredMap: {},
    showFormErrors: false,
    fieldErrors: {
      logged_by: false,
      report_date: false,
      category_id: true,
      priority: false,
      problem_description: true,
      location: false
    },
    loadingStatus: false
  }

  firstTime = true;

  componentDidMount() {
    const { actionFetchCategories, actionFetchSchema, actionFetchProperties,
      fetchDataSources, actionFetchTenants } = this.props;
    actionFetchCategories();
    actionFetchSchema();
    fetchDataSources();
    actionFetchProperties();
    actionFetchTenants();
  }

  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.user.id && !state.info.logged_by.id) {
      return {
        ...state,
        info: {
          ...state.info,
          logged_by: {
            id: props.user.id,
            fullname: props.user.fullname
          }
        }
      };
    }
    if (props.createWorkorderAction && props.createWorkorderAction.success && state.loadingStatus) {
      props.actionClear();
      props.history.push('/lease/maintenance');
      return { ...state, loadingStatus: false };
    }
    return state;
  }

  componentDidUpdate() {
    const { leaseMaintenance_schema } = this.props;
    if (this.firstTime && leaseMaintenance_schema.workorder && leaseMaintenance_schema.workorder.id) {
      this.processInspectionForState(leaseMaintenance_schema.workorder);
      this.firstTime = false;
    }
  }


  processInspectionForState = (workorder) => {
    if (workorder) {
      const { schema } = workorder;
      let { fieldErrors, requiredMap } = this.state;

      schema.fields.forEach((f) => {
        requiredMap = { ...requiredMap, [f.id]: f.required };
        fieldErrors = { ...fieldErrors, [f.id]: f.required };
      });
      this.setState({ requiredMap, fieldErrors });
    }
  }

  handleAnswerChanged = (section, answer, fieldId) => {
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  handleSearchForUser = (value) => {
    const { actionSearchUser } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  create = async () => {
    const {
      actionCreate
    } = this.props;

    const {
      info,
      fieldErrors,
      answers
    } = this.state;

    if (info.priority) {
      fieldErrors.priority = false;
    }
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));

    if (noErrors) {
      this.setState({ loadingStatus: true });
      const data = { ...info };
      data.logged_by = data.logged_by.id;
      data.response = JSON.stringify(answers);
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k !== 'attachments') {
          formData.append(k, data[k]);
        }
      });
      let fileIds = '';
      if (data.attachments.length > 0) {
        fileIds = await this.getFileNames(data.attachments.length);
      }
      formData.append('email_date', moment().format());
      if (fileIds) {
        await this.doS3Upload(data.attachments, fileIds, 'work_orders')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });
            actionCreate(formData);
          });
      } else {
        actionCreate(formData);
      }
    } else {
      this.setState({ showFormErrors: true, loadingStatus: false });
    }
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  render() {
    const { userlist, translations, user, allCategories, allDataSources,
      leaseMaintenance_schema, allProperties, Tenants } = this.props;
    const { info, showFormErrors, requiredMap, answers, loadingStatus } = this.state;
    const translationMap = translations ? translations[user.language] : {};
    return (
    <>
      <SectionHeader icon={icon} translationID="shortcuts.lease.maintenance" defaultTitle="Maintenance" />
      <HeaderBack
        translationID="inspections.new.prev"
        translationDefault="Back"
        backRoute={`${LEASE_HOME}/maintenance`}
      />
      {loadingStatus && (<Loading loadingStatus={loadingStatus} />)}
      <div className={`container ${WorkorderStyles.form}`}>
        <Panel title="workorders.start.title">
          <div className={`${WorkorderStyles.content} ${WorkorderStyles.embedded}`}>
            {allCategories && (
            <FixedFeilds
              info={info}
              translation={translationMap}
              userlist={userlist}
              allProperties={allProperties}
              categories={allCategories}
              tenants={Tenants}
              searchForUser={this.handleSearchForUser}
              handleAnswerChanged={this.handleAnswerChanged}
              handleFieldErrorChanged={this.handleFieldErrorChanged}
              showFormErrors={showFormErrors}
            />
            )}
            <div className={WorkorderStyles.separator} />
            {Object.keys(requiredMap).length > 0 && allDataSources
                && leaseMaintenance_schema.workorder.schema.fields.map(field => (
                  <PulpoField key={field.id} {...field}
                    translation={translationMap && translationMap[field.title]}
                    handleValueChange={(a, b) => this.handleAnswerChanged('answers', a, b)}
                    isRequired={requiredMap[field.id]} answer={answers[field.id]}
                    showFieldErrors={showFormErrors}
                    handleFieldErrorChanged={this.handleFieldErrorChanged}
                    allDataSources={allDataSources}
                  />
                ))}
          </div>
          <div className={`${WorkorderStyles.footer} ${WorkorderStyles.embedded}`}>
            <Button onClick={this.create} translationID="inspections.new.create"
              defaultText="Create" action="secondary"
            />
          </div>
        </Panel>
      </div>
    </>
    );
  }
}

MaintenanceCreate.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  actionFetchCategories: PropTypes.func.isRequired,
  actionCreate: PropTypes.func.isRequired,
  aactionClear: PropTypes.func.isRequired,
  actionFetchTenants: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  leaseMaintenance_schema: PropTypes.arrayOf(PropTypes.shape({}))
};

MaintenanceCreate.defaultProps = {
  userlist: [],
  leaseMaintenance_schema: {}
};
const mapStateToProps = state => ({
  user: state.auth.profile,
  userlist: state.inspection.userlist,
  translations: state.auth.translations,
  allCategories: state.lease.allCategories,
  leaseMaintenance_schema: state.lease.leaseMaintenance_schema,
  allDataSources: state.inspection.allDataSources,
  createWorkorderAction: state.lease.createWorkorderAction,
  allProperties: state.lease.allProperties,
  Tenants: state.lease.TenantLookupData
});

const mapDispatchToProps = dispatch => ({
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionFetchCategories: () => {
    dispatch(fetchCategories());
  },
  actionFetchSchema: () => {
    dispatch(fetchWorkOrderSchema());
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  },
  actionCreate: (data) => {
    dispatch(createLeaseWokrorder(data));
  },
  actionFetchProperties: () => {
    dispatch(fetchProperties());
  },
  actionFetchTenants: () => {
    dispatch(getTenantLookup());
  },
  actionClear: () => {
    dispatch(clearMaintenance());
  }
});

export default Permissions(['add_leaseworkorder'])(
  connect(mapStateToProps, mapDispatchToProps)(MaintenanceCreate),
  <Forbidden />
);
