import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import InspectionList from './List';
import InspectionBuilder from './Builder';
import complete from './Complete';
import SubmissionDetail from './Detail/SubmissionDetail';
import InspectionRoute from './Detail/InspectionRoute';

const InspectionLayout = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/start`} component={complete.StartInspection} />
    <Route exact path={`${match.url}/new`} component={InspectionBuilder} />
    <Route exact path={`${match.url}/:id`} component={SubmissionDetail} />
    <Route exact path={`${match.url}/:id/edit`} component={InspectionBuilder} />
    <Route exact path={`${match.url}/:id/complete`} component={complete.CompleteInspection} />
    <Route exact path={`${match.url}/:id/:aid/edit-inspection`} component={complete.EditInspection} />
    <Route exact path={`${match.url}`} component={InspectionList} />
    <Route exact path={`${match.url}/:id/inspection-route`} component={InspectionRoute} />
  </Switch>
);

InspectionLayout.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default InspectionLayout;
