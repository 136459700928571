import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import { HOC as Permissions } from 'react-redux-permissions';
import Forbidden from '../../Forbidden';
import SectionHeader from '../../../components/sectionHeader/SectionHeader';
import icon from '../../../components/settingsHeader/icons/settings.svg';
import { createGrids, createGridImage } from '../redux/actions';
import Button from '../../../components/button';
import HeaderBack from '../../../components/headerBack';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import Clickable from '../../../components/clickable/Clickable';
import Panel from '../../../components/panel/Panel';
import attachmnetsIcon from '../../../icons/attachment_icon.png';
import { WILD_LIFE_LOG_HOME } from '../../../constants/RouterConstants';
import tableStyles from '../../../styles/general.module.scss';
import styles from './WildLifeLogBuilder.module.scss';
import { GetFileName, S3Uploads } from '../../services';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class WildLifeLogBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatus: false,
      InvalidData: false,
      successMessage: false,
      attachments: [],
      numPages: null,
      pageNumber: 1,
      section: 'ExcelImport'
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.loadingStatus && props.gridAction && props.gridAction.success) {
      return { ...state, loadingStatus: false, successMessage: true };
    }
    return state;
  }

  handleFile = (answer) => {
    this.setState({ InvalidData: false, file: answer });
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });

      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const range = XLSX.utils.decode_range(ws['!ref']);
      const gridList = [];

      const gridHeaders = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: range.s.r };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        const header = ws[cell_ref].w;
        gridHeaders.push(header.toUpperCase());
      }
      const letter = gridHeaders.filter(item => item === 'LETTER');
      const number = gridHeaders.filter(item => item === 'NUMBER');
      if (letter.length > 0 && number.length > 0) {
        let R = range.s.r + 1;
        for (R; R <= range.e.r; ++R) {
          const row_list = {};
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = { c: C, r: R };
            const cell_ref = XLSX.utils.encode_cell(cell_address);
            row_list[gridHeaders[C].toLowerCase()] = ws[cell_ref].w;
          }
          gridList.push(row_list);
        }
        this.setState({ gridList, gridHeaders });
      } else {
        this.setState({ InvalidData: true });
      }
    };
    reader.readAsBinaryString(answer);
  }

  handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ attachments: [e.target.result], pageNumber: 1 });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    this.setState({ pdfFile: event.target.files[0] });
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  handleMap = async () => {
    const { actionCreateImage } = this.props;
    const { attachments, pdfFile } = this.state;
    this.setState({ loadingStatus: true });
    let fileIds ='';
    const formData = new FormData();
    if (attachments.length > 0) {
      fileIds = await this.getFileNames(attachments.length);
    } else {
      return;
    }
    if (fileIds) {
      await this.doS3Upload([pdfFile], fileIds, 'wildlife_log')
        .then(() => {
          fileIds.filename.forEach((id) => {
            formData.append('attachments', id.toString());
          });
          actionCreateImage(formData);
        });
    }
  }

  handleExcel = () => {
    const { actionCreate } = this.props;
    const { gridList } = this.state;
    this.setState({ loadingStatus: true });
    actionCreate(gridList);
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  handleTabClick = (section) => {
    this.setState({ section });
  }

  render() {
    const { gridList, gridHeaders, InvalidData, loadingStatus, successMessage,
      attachments, numPages, pageNumber, file, pdfFile, section } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;
    const title = section === 'ExcelImport' ? 'Upload Location Grid data' : 'Upload Location Grid Map';
    return (
        <>
          <div>
            <Loading loadingStatus={loadingStatus} />
            <SectionHeader icon={icon} translationID="wildlife_log.settings" defaultTitle="WildLife Settings" />
            <HeaderBack
              translationID="workorder.settings.back"
              translationDefault="Back"
              backRoute={WILD_LIFE_LOG_HOME}
            />
          </div>
          <br />
          <br />
          <br />
          <div className="container">
            <div className={styles.navigation}>
              <Clickable className={section === 'ExcelImport' ? styles.active : ''}
                onClick={() => this.handleTabClick('ExcelImport')}
              >
                <FormattedMessage id="wildlife.settings.table" defaultMessage="Location Grid" />
              </Clickable>
              <Clickable className={section === 'GridMap' ? styles.active : ''}
                onClick={() => this.handleTabClick('GridMap')}
              >
                <FormattedMessage id="wildlife.settings.grid_map" defaultMessage="Location Grid Map" />
              </Clickable>
            </div>
            <br />
            <br />
            <br />
            <Panel title={title} defaulMessage="Upload Location Grid Data">
              <div>
                {section === 'ExcelImport' && (
                <div className={styles.toolbar}>
                  <div className={styles.col}>
                    <div className={styles.imageupload}>
                      <label htmlFor="file-input">
                        <img src={attachmnetsIcon} alt="" />
                        {!file && formatMessage({ id: 'todo.newTask.browse' })}
                      </label>
                      <input
                        id="file-input"
                        name="ImportExcel"
                        type="file"
                        onChange={e => this.handleFile(e.target.files[0])}
                        accept=".xlsx, .xls"
                      />
                      {file && file.name}
                    </div>
                  </div>
                  {InvalidData && (
                  <small style={{ color: 'red' }}>
                    <FormattedMessage id="wildlife.settings.invalid_data" defaulMessage="Invalid data" />
                  </small>
                  )}
                  <Button type="secondary" translationID="wildlife.form.submit" onClick={this.handleExcel} />
                </div>
                )}
                {section === 'GridMap' && (
                  <div className={styles.toolbar}>
                    <div className={styles.col}>
                      <div className={styles.imageupload}>
                        <label htmlFor="pdf-input">
                          <img src={attachmnetsIcon} alt="" />
                          {attachments.length === 0 && formatMessage({ id: 'todo.newTask.browse' })}
                        </label>
                        <input
                          id="pdf-input"
                          type="file"
                          onChange={e => this.handleImage(e)}
                          accept=".pdf"
                        />
                        {pdfFile && pdfFile.name}
                      </div>
                    </div>
                    <Button type="secondary" translationID="wildlife.form.submit" onClick={this.handleMap} />
                  </div>
                )}
              </div>
            </Panel>
            <br />
            <br />
            <br />
            {attachments.length > 0 && section === 'GridMap' && (
              <Panel title="wildlife.settings.pdf" defaulMessage="Uploaded PDF">
                <div className={styles.column}>
                  <Document
                    file={attachments[0]}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <div className={styles.pdf}>
                    <div className={styles.left}>
                      {pageNumber > 1 && (
                      <Clickable className={styles.prev} onClick={() => this.setState({ pageNumber: pageNumber-1 })}>
                        <FormattedMessage id="wildlife.settings.pdf.prev" defaulMessage="Prev" />
                      </Clickable>
                      )}
                      <h4>
                        <FormattedMessage id="wildlife.settings.pdf.page" defaulMessage="Page" />
                        {pageNumber}
                        {' /'}
                        {numPages}
                      </h4>
                    </div>
                    <div className={styles.right}>
                      {pageNumber < numPages && (
                      <Clickable className={styles.next} onClick={() => this.setState({ pageNumber: pageNumber+1 })}>
                        <FormattedMessage id="inspections.new.next" defaulMessage="Next" />
                      </Clickable>
                      )}
                    </div>
                  </div>
                </div>
              </Panel>
            )}
            {gridList && section === 'ExcelImport' && (
              <Panel title="wildlife.settings.excel" defaulMessage="Data read from uploaded file">
                  {gridList && (
                    <table className={tableStyles.table} style={{ overflow: 'scroll' }}>
                      <thead>
                        <tr>
                          {gridHeaders.map(h => (
                            <th>{h}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {gridList.map(obj => (
                          <tr>
                            {Object.keys(obj).map(e => (
                              <td>{obj[e]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
              </Panel>
            )}
            <Modal
              onClose={() => this.setState({ successMessage: false })}
              showIn={successMessage}
              width="40%"
              minHeight="30%"
            >
              <div className={styles.confirmContent}>
                <FormattedMessage id="wildlife.settings.success_message"
                  defaultMessage="Location grids are added successfully"
                />
              </div>
              <div className={styles.btn}>
                <Button type="secondary" translationID="inspections.list.ok" onClick={() => this.setState({ successMessage: false })} defaultText="OK" />
              </div>
            </Modal>
          </div>
        </>
    );
  }
}

WildLifeLogBuilder.propTypes = {
  actionCreate: PropTypes.func.isRequired,
  actionCreateImage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  gridAction: state.wildlife_log.gridAction
});

const mapDispatchToProps = dispatch => ({
  actionCreate: (data) => {
    dispatch(createGrids(data));
  },
  actionCreateImage: (data) => {
    dispatch(createGridImage(data));
  }
});

export default Permissions(['can_modify_airport_settings'])(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(WildLifeLogBuilder)),
  <Forbidden />
);
