import React from 'react';

import { Wrapper } from './DocumentCart.styles';
import { ReactComponent as Document } from '../../../../icons/document.svg';
import { ReactComponent as Download } from '../../../../icons/download.svg';
import { ReactComponent as MoreHoriz } from '../../../../icons/more-horiz.svg';
import { Attachments } from '../../types';

type DocumentCartProps = {
  isPreview?: boolean;
} & Attachments;

const DocumentCart = React.memo<DocumentCartProps>(
  ({ name, url, size = 30, isPreview = false }) => (
    <Wrapper style={{height:'auto'}}  >
      <Document />
      <div className="info">
        <span className="info-name">{name}</span>
        <span className="info-size">{`${size}kb`}</span>
      </div>
      {!isPreview && (
        <div className="actions">
          <a href={url} target="blank">
            <Download />
          </a>
          <MoreHoriz />
        </div>
      )}
    </Wrapper>
  )
);

export default DocumentCart;
export { DocumentCart };
