import { DirectMessagesState } from './types';

export const initialState = (): DirectMessagesState => ({
  visible: false,
  topicsConnected: false,
  loading: false,
  editedDM: null,
  editedDMID: null,
  topicsCollectionCount: null,
  activeChat: {
    chatName: null,
    chatId: null
  },
  listChats: null,
  fromUser: null,
  directMessageList: {
    items: null,
    nextToken: null
  },
  newDirectMessages: null
});
