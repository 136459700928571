import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
/* ***************************************
 *  Components import
 * ******************* */
import SectionHeader from '../../../../components/sectionHeader';
import Separator from '../../../../components/separator';
import Button from '../../../../components/button';
import IconButton from '../../../../components/iconButton';
/* ****************************************
*  Assets import
* ********************** * */
import settings from '../../../../icons/settings.svg';
import icon from '../../../../icons/Contracts.png';
import styles from './tenants.module.scss';
import Search from '../../../../components/search/Search';
import Paginator from '../../../../components/paginator/paginator';
import { TENANTS } from '../../../../constants/RouterConstants';
import Table from './components/Table';
import { fetchTenants } from '../../redux/actions';

class Tenants extends Component {
    state = {
      currentPage: 1
    }

    componentDidMount() {
      const { actionFetchTenants } = this.props;
      actionFetchTenants(1);
    }

    static getDerivedStateFromProps(props, state) {
      if (!state.tenants && props.allTenants && props.allTenants.results && props.allTenants.results.length) {
        return { ...state, tenants: props.allTenants };
      }
      return state;
    }

    componentDidUpdate(prevProps) {
      const { allTenants } = this.props;
      if ((prevProps.allTenants && prevProps.allTenants.results) !== (allTenants && allTenants.results)) {
        this.setState({
          tenants: allTenants
        });
      }
    }

  createNewTenant = () => {
    const { history } = this.props;
    history.push(`${TENANTS}/start`);
  };

  changePage = (page) => {
    const { actionFetchTenants } = this.props;
    const { searchQuery } = this.state;
    this.setState({ currentPage: page });
    actionFetchTenants(page, searchQuery);
  }

  handleQuery = (searchQuery) => {
    const { actionFetchTenants } = this.props;
    this.setState({ searchQuery });
    actionFetchTenants(1, searchQuery);
  };

  render() {
    const { currentPage, tenants } = this.state;
    const { history } = this.props;
    return (
      <>
        <SectionHeader
          icon={icon}
          translationID="shortcuts.lease.tenants"
          defaultTitle="Tenants"
        >
          <div className={styles.detailHeader}>
            <Search onSubmit={searchQuery => this.handleQuery(searchQuery)} />
            <div className={styles.settingIcon}>
              <Permissions allowed={['can_modify_airport_settings']}>
                <IconButton
                  icon={settings}
                  onClick={() => { history.push(`${TENANTS}/settings`); }}
                />
              </Permissions>
            </div>
            <Separator />
            <Permissions allowed={['add_tenants']}>
              <Button translationID="tenants.newtenant"
                defaultText="New Tenant" onClick={this.createNewTenant}
              />
            </Permissions>
            <Separator />
          </div>
        </SectionHeader>
        <div className={styles.filter} />
        <div className={`container ${styles.container}`}>
          {tenants && tenants.results && (
          <Table info={tenants.results} />
          )}
          {tenants && tenants.results && tenants.results.length > 0
            && (
              <Paginator
                page={currentPage}
                totalRecords={tenants.count}
                pageLimit={10}
                pageNeighbours={1}
                onPageChanged={page => this.changePage(page)}
              />
            )
          }
        </div>
        </>
    );
  }
}

Tenants.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionFetchTenants: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  allTenants: state.lease.allTenants
});

const mapDispatchToProps = dispatch => ({
  actionFetchTenants: (page, query, filters) => {
    dispatch(fetchTenants(page, query, filters));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tenants);
