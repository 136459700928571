import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import moment from 'moment';
import { HOC as Permissions } from 'react-redux-permissions';
import { fetchLeaseWorkorders, fetchCategories } from '../../redux/actions';
import SectionHeader from '../../../../components/sectionHeader';
import Clickable from '../../../../components/clickable/Clickable';
import Search from '../../../../components/search/Search';
import IconButton from '../../../../components/iconButton';
import Seperator from '../../../../components/separator/Separator';
import Button from '../../../../components/button/button';
import Paginator from '../../../../components/paginator/paginator';
import Forbidden from '../../../Forbidden';
import icon from '../../../../icons/WorkOrders.png';
import settings from '../../../../icons/settings.svg';
import styles from '../../../inspections/inspections.module.scss';
import Table from './components/Table';
import Filter from './components/Filter';
import FilterForm from './components/FilterForm';
import { getDatesFromRange } from '../../../../utils/helpers';

class MaintenanceList extends Component {
  state = {
    range: {},
    new_wo: 0,
    inProgress: 0,
    completed: 0,
    currentPage: 1,
    searchQuery: '',
    filterCategories: [],
    popup: false
  }

  slideStyles = {
    entered: { transform: 'translate3d(0px,0px)' }
  };

  componentDidMount() {
    const { actionFetchWorkOrders, actionFetchCategories } = this.props;
    const storedRange = localStorage.getItem('lease_maintenance_range');
    const filters = localStorage.getItem('lease_maintenance_filters');
    let s; let f;
    if (storedRange) {
      [s, f] = getDatesFromRange(storedRange);
      s = s.toISOString();
      f = f.toISOString();
    } else {
      s = localStorage.getItem('lease_maintenance_start_date');
      f = localStorage.getItem('lease_maintenance_end_date');
      if (s && f) {
        s = new Date(s);
        f = new Date(f);
      } else {
        s = new Date();
        s.setDate(s.getDate() - 30);
        f = new Date();
        localStorage.setItem('lease_maintenance_range', 'Last 30 days');
      }
      s.setHours(0, 0, 0, 0);
      f.setHours(23, 59, 59, 99);
      s = s.toISOString();
      f = f.toISOString();
    }
    if (filters) {
      this.setState({ filterCategories: filters.split(',') });
      actionFetchWorkOrders(1, undefined, undefined, undefined, filters);
    } else {
      actionFetchWorkOrders(1, s, f);
    }
    this.setState({ range: { s, f } });
    actionFetchCategories();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.workorders && props.allWorkOrders && props.allWorkOrders.results && props.allWorkOrders.results.length) {
      const new_wo = props.allWorkOrders.results.filter(e => e.status === 0);
      const inProgress = props.allWorkOrders.results.filter(e => e.status === 1);
      const completed = props.allWorkOrders.results.filter(e => e.status === 2);
      return {
        ...state,
        workorders: props.allWorkOrders.results,
        new_wo: new_wo.length,
        inProgress: inProgress.length,
        completed: completed.length
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { allWorkOrders } = this.props;
    if ((prevProps.allWorkOrders && prevProps.allWorkOrders.results) !== (allWorkOrders && allWorkOrders.results)) {
      const new_wo = allWorkOrders.results.filter(e => e.status === 0);
      const inProgress = allWorkOrders.results.filter(e => e.status === 1);
      const completed = allWorkOrders.results.filter(e => e.status === 2);
      this.setState({
        workorders: allWorkOrders.results,
        new_wo: new_wo.length,
        inProgress: inProgress.length,
        completed: completed.length
      });
    }
  }

  changePage = (page) => {
    const { actionFetchWorkOrders } = this.props;
    const { range, searchQuery, filterCategories } = this.state;
    this.setState({ currentPage: page });
    if (searchQuery) {
      actionFetchWorkOrders(page, undefined, undefined, searchQuery);
      return;
    }
    if (filterCategories.length > 0) {
      const filters = this.handleFiltersList(filterCategories);
      actionFetchWorkOrders(1, undefined, undefined, undefined, filters);
    } else {
      actionFetchWorkOrders(page, range.s, range.f);
    }
  }

  handleQuery = (searchQuery) => {
    const { actionFetchWorkOrders } = this.props;
    this.setState({ searchQuery });
    actionFetchWorkOrders(1, undefined, undefined, searchQuery);
  };

  handleDateChange = (s, f, r) => {
    const { actionFetchWorkOrders } = this.props;
    localStorage.setItem('lease_maintenance_start_date', s);
    localStorage.setItem('lease_maintenance_end_date', f);
    if (r) {
      localStorage.setItem('lease_maintenance_range', r);
    } else {
      localStorage.removeItem('lease_maintenance_range');
    }
    let start_date = new Date(s);
    let end_date = new Date(f);
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(23, 59, 59, 999);
    start_date = start_date.toISOString();
    end_date = end_date.toISOString();
    this.setState({ range: { s: start_date, f: end_date } });
    actionFetchWorkOrders(1, start_date, end_date);
  }

  handleFiltersList = (filters) => {
    let filterslist = '';
    filters.forEach((item) => {
      filterslist += `${item},`;
    });
    return filterslist;
  }

  handleFiltersPopup = () => {
    const { popup } = this.state;
    this.setState({ popup: !popup });
  }

  updateFilter = (filterCategories) => {
    const { actionFetchWorkOrders } = this.props;
    const { range } = this.state;
    this.setState({ filterCategories: [...filterCategories], currentPage: 1 });
    if (filterCategories.length > 0) {
      localStorage.setItem('lease_maintenance_filters', filterCategories);
      const filters = this.handleFiltersList(filterCategories);
      actionFetchWorkOrders(1, undefined, undefined, undefined, filters);
    } else {
      localStorage.removeItem('lease_maintenance_filters');
      actionFetchWorkOrders(1, range.s, range.f);
    }
    this.setState({ popup: false });
  }

  handleRemoveFilter = (val) => {
    const { filterCategories, range } = this.state;
    const { actionFetchWorkOrders } = this.props;
    const filteredItems = filterCategories.filter(item => item !== val);
    const page = 1;
    this.setState({ currentPage: page });
    if (filteredItems.length > 0) {
      this.setState({ filterCategories: filteredItems }, () => {
        this.updateFilter(filteredItems);
      });
      localStorage.setItem('lease_maintenance_filters', filteredItems);
    } else {
      this.setState({ filterCategories: filteredItems });
      localStorage.removeItem('lease_maintenance_filters');
      actionFetchWorkOrders(1, range.s, range.f);
    }
  }

  handleStatusFilter = (status) => {
    const { actionFetchWorkOrders } = this.props;
    actionFetchWorkOrders(1, undefined, undefined, status, undefined);
    this.setState({ searchQuery: status });
  }

  render() {
    const { history, allWorkOrders, allCategories } = this.props;
    const { new_wo, inProgress, completed, currentPage, workorders, range,
      filterCategories, popup } = this.state;
    return (
    <>
      <SectionHeader icon={icon} translationID="shortcuts.lease.maintenance" defaultTitle="Maintenance">
        <div className={styles.detailHeader}>
          <Search onSubmit={searchQuery => this.handleQuery(searchQuery)} />
          <IconButton icon={settings} onClick={() => history.push('/lease/maintenance/settings')} />
          <Seperator />
          <Button translationID="workorders.start.title"
            defaultText="Create Work Order"
            onClick={() => history.push('/lease/maintenance/start')}
          />
          <Seperator />
        </div>
      </SectionHeader>
      {range.s && (
      <Filter dates={[moment(range.s), moment(range.f)]}
        onDateChange={this.handleDateChange}
        listSummary={allWorkOrders && allWorkOrders.count}
        handleDropdown={this.handleFiltersPopup}
        filterCategories={filterCategories}
        StorageVariable="lease_maintenance_range"
        range={localStorage.getItem('lease_maintenance_range')}
      />
      )}
      <div className={styles.filter}>
        {filterCategories.map(category => (
          <span className={styles.user} key={category}>
            {category}
            <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
              onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
            />
          </span>
        ))}
      </div>
      <div className={styles.container}>
        <div className={styles.datasources}>
          <div className={styles.column}>
            <h2 className={styles.green}>{new_wo}</h2>
            <FormattedMessage tagName="p" id="lease.maintenance.list.new" defaultMessage="New Work Orders" />
            <Clickable className={styles.active} onClick={() => this.handleStatusFilter('new')}>
              <FormattedMessage id="inspections.list.view" defaultMessage="View" />
            </Clickable>
          </div>
          <div className={styles.column}>
            <h2 className={styles.orange}>{inProgress}</h2>
            <FormattedMessage tagName="p" id="lease.maintenance.list.progress" defaultMessage="In Progress" />
            <Clickable className={styles.active} onClick={() => this.handleStatusFilter('inprogress')}>
              <FormattedMessage id="inspections.list.view" defaultMessage="View" />
            </Clickable>
          </div>
          <div className={styles.column}>
            <h2 className={styles.danger}>{completed}</h2>
            <FormattedMessage tagName="p" id="lease.maintenance.list.closed" defaultMessage="Closed" />
            <Clickable className={styles.active} onClick={() => this.handleStatusFilter('closed')}>
              <FormattedMessage id="inspections.list.view" defaultMessage="View" />
            </Clickable>
          </div>
        </div>
        { allWorkOrders && workorders && (
        <Table info={workorders} />
        )}
        {allWorkOrders && workorders && Object.keys(workorders).length > 0
            && (
              <Paginator
                page={currentPage}
                totalRecords={allWorkOrders.count}
                pageLimit={20}
                pageNeighbours={1}
                onPageChanged={page => this.changePage(page)}
              />
            )
          }
      </div>
      {popup && allCategories && (
      <Transition in={popup} timeout={200} unmountOnExit>
        {state => (
          <FilterForm
            transition={this.slideStyles[state]}
            update={this.updateFilter}
            cancel={this.handleFiltersPopup}
            filterCategories={filterCategories}
            allCategories={allCategories}
          />
        )}
      </Transition>
      )}
    </>
    );
  }
}

MaintenanceList.propTypes = {
  actionFetchWorkOrders: PropTypes.func.isRequired,
  actionFetchCategories: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  allWorkOrders: state.lease.allWorkOrders,
  allCategories: state.lease.allCategories
});

const mapDispatchToProps = dispatch => ({
  actionFetchWorkOrders: (page, start, end, query, filters) => {
    dispatch(fetchLeaseWorkorders(page, start, end, query, filters));
  },
  actionFetchCategories: () => {
    dispatch(fetchCategories());
  }
});

export default Permissions(['view_leaseworkorder'])(
  connect(mapStateToProps, mapDispatchToProps)(MaintenanceList),
  <Forbidden />
);
