import { ChatTypes } from '../../enums';
import UPDATE_COMMENT from '../mutations/updateComment';
import UPDATE_POST from '../mutations/updatePost';
import { useMutation } from '../../../graphql/hooks';
import { UpdatePostType } from '../../state/channelsState/posts/types';

export const useUpdateMessageMutation = <T>(type: ChatTypes) => {
  const obj = {
    [ChatTypes.comment]: UPDATE_COMMENT,
    [ChatTypes.operations]: UPDATE_POST
  };
  const { error, mutate, saving: updateSaving } = useMutation<UpdatePostType, T>(obj[type]);

  return {
    error,
    updatePost: mutate,
    updateSaving
  };
};
