import { MutationFunctionOptions } from '@apollo/react-common';
import { GraphQLError } from 'graphql';
import { useMutation } from '../../../graphql/hooks';
import { UpdatePostType } from '../../state/channelsState/posts/types';
import { UpdateDMVars } from './hooks.types';
import UPDATE_DM from '../mutations/updateMessage';

type UseUpdateDM<TData> = {
  (): {
    error: GraphQLError;
    updateDM: (
      options?: MutationFunctionOptions<UpdatePostType, UpdateDMVars>
    ) => Promise<{ data?: TData; error?: GraphQLError }>;
    updateSaving: boolean;
  };
};

export const useUpdateDM: UseUpdateDM<UpdatePostType> = () => {
  const { error, mutate, saving: updateSaving } = useMutation<
    UpdatePostType,
    UpdateDMVars
  >(UPDATE_DM);

  return {
    error,
    updateDM: mutate,
    updateSaving
  };
};
