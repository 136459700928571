import React from 'react';

import { Wrapper } from './ActiveChatHeader.styles';

type ChatNameProps = {
  name: string;
  details?: string;
};

const ChatName: React.FC<ChatNameProps> = ({ name, details }) => (
  <Wrapper>
    <h3>{name}</h3>
    {details && <span className="people_count">{details}</span>}
  </Wrapper>
);

export default ChatName;
export { ChatName };
