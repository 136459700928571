/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { fetchShiftTypes, updateShiftTypes } from '../redux/actions';

// clock component
import Clock from './components/Clock';

import styles from './shifts.module.scss';

/**
 * Time Shift Component
 */
class Shifts extends Component {
  constructor(props) {
    super(props);
    this.updateCurrentTime = this.updateCurrentTime.bind(this);
    this.updateTimeData = this.updateTimeData.bind(this);
  }

  state = {
    data: [],
    currentIndex: 0,
    currentStart: 0,
    currentEnd: 0,
    editable: false
  }

  componentDidMount() {
    const { shiftTypes, actionFetch, shift } = this.props;
    const { data } = this.state;
    if (!shiftTypes.length) actionFetch();
    if (shift) actionFetch();
    if (!data.length && shiftTypes.length) this.setData(shiftTypes);
  }

  componentDidUpdate(prevProps) {
    const { shiftTypes } = this.props;

    if (JSON.stringify(prevProps.shiftTypes) !== JSON.stringify(shiftTypes)) {
      this.setData(shiftTypes);
    }
  }

  setData = (shiftTypes) => {
    const { currentIndex } = this.state;
    const currentStart = parseInt(shiftTypes[currentIndex].start, 10);
    const currentEnd = parseInt(shiftTypes[currentIndex].end, 10);
    const data = shiftTypes.map(item => ({ ...item, start: parseInt(item.start, 10), end: parseInt(item.end, 10) }));
    this.setState({ data, currentStart, currentEnd });
  }

  setEditableTime = () => {
    this.setState(prevState => ({
      editable: !prevState.editable
    }));
  }

  // set current active time shift
  updateCurrentTime = (currentIndex, start, end) => {
    const currentStart = parseInt(start || 0, 10);
    const currentEnd = parseInt(end || 0, 10);
    this.setState({ currentIndex, currentStart, currentEnd });
  }

  // update data
  updateTimeData = (event, index, key, value) => {
    const { data, currentIndex } = this.state;
    if (data.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      const event_value = isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10);
      const current = event ? event_value : value;
      data[index][key] = (current < 24) && (current >= 0) ? current : data[index][key];

      if (index === currentIndex) {
        this.setState({
          currentStart: parseInt(data[index].start, 10),
          currentEnd: parseInt(data[index].end, 10)
        });
      }
      this.setState({ data });
    }
  }

  handleUpdate = (index, start, end) => {
    const { data } = this.state;
    if (data.length > 0) {
      const { actionUpdate } = this.props;
      const shiftData = {
        start: `${start}:00:00`,
        end: `${end}:00:00`
      };
      actionUpdate(data[index].id, shiftData);
    }
  }

  render() {
    const { data, currentIndex, currentStart, currentEnd, editable } = this.state;
    return (
      <div className={styles.shifts}>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <Clock start={currentStart} end={currentEnd} index={currentIndex}
              updateData={this.updateTimeData}
            />
          </div>
          <div className={`${styles.column} ${styles.flex}`}>
            {
              data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={index === currentIndex ? `${styles.timepicker} ${styles.active}` : styles.timepicker}
                    onClick={e => this.updateCurrentTime(index, item.start, item.end)}
                  >

                    <span className={styles.label}>
                      <FormattedMessage id={`shifts.timepicker.${index}`} defaultMessage={`${item.name} Shift`} />
                    </span>

                    <span className={!editable ? styles.time : styles.editable}>
                      <input
                        type="text"
                        className={index === currentIndex ? styles.active : ''}
                        disabled={!editable} value={item.start < 12 ? `0${item.start}` : item.start}
                        onChange={e => this.updateTimeData(e, index, 'start')}
                      />
                        :00
                      {item.start < 12 ? 'AM' : 'PM'}
                        -
                      <input
                        type="text"
                        className={index === currentIndex ? styles.active : ''}
                        disabled={!editable} value={item.end < 12 ? `0${item.end}` : item.end}
                        onChange={e => this.updateTimeData(e, index, 'end')}
                      />
                        :00
                      {item.end < 12 ? 'AM' : 'PM'}
                    </span>
                    <span className={styles.edit} onClick={() => this.setEditableTime()}>
                      <FormattedMessage id="shifts.timepicker.edit" defaultMessage="Edit" />
                    </span>
                    <span className={styles.edit} onClick={() => this.handleUpdate(index, item.start, item.end)}>
                      <FormattedMessage id="shifts.timepicker.save" defaultMessage="Save" />
                    </span>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

Shifts.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  shiftTypes: PropTypes.arrayOf(PropTypes.shape())
};

const mapStateToProps = state => ({
  shiftTypes: state.settings.shiftTypes,
  shift: state.settings.shift
});

Shifts.defaultProps = {
  shiftTypes: {}
};

const mapDispatchToProps = dispatch => ({
  actionFetch: () => {
    dispatch(fetchShiftTypes());
  },
  actionUpdate: (id, data) => {
    dispatch(updateShiftTypes(id, data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shifts);
