import gql from 'graphql-tag';

const NEW_CHANNEL_POSTS = gql`
    subscription onCreatePost(
        $channelId: String!,
        $iropsId: String,
    ){
        onCreatePost(channelId: $channelId, iropsId: $iropsId) {
            content   
        }
    }
`;
export default NEW_CHANNEL_POSTS;
