import React from 'react';

import { Wrapper } from './UserItem.styles';
import { OnUserRemoveFunc, UserType } from '../../types';
import { ReactComponent as Remove } from '../../../../icons/remove.svg';
import { Avatar } from '../../../../components/avatar';

type UserItemProps = {
  onRemove: OnUserRemoveFunc;
};

const UserItem: React.FC<UserItemProps & UserType> = ({
  profilePicture,
  firstName,
  lastName,
  title,
  onRemove,
  userId
}): React.ReactElement => (
  <Wrapper>
    <span>
      <Avatar src={profilePicture} />
      <span className="user-name">{`${firstName} ${lastName}`}</span>
    </span>
    <span>
      <span className="user-info">{title}</span>
      <Remove onClick={() => onRemove(userId)} />
    </span>
  </Wrapper>
);

export default UserItem;
export { UserItem };
