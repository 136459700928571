import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { GlobalDispatch } from './types';
import { globalState, GlobalState } from './globalState';
import { reducer } from './reducers';
import { commentsActions, postActions } from './actions';
import { ChatTypes } from '../../enums';
import { useChannelPost, useChannels } from '../../graphql/hooks';
import { ChannelType } from '../../types';
import { useCreateSubscriptionUpdater } from '../common';
import {
  DeletePostProps,
  PostList,
  ToggleLikesType,
  UpdatePostType,
  UpdateReplies
} from './posts/types';
import {
  CommentsList,
  UpdateCommentType,
  DeleteComment,
  UpdateCommentDataProps,
} from './comments/types';
import {
  ComposeCommentArgs,
  ComposePostArgs,
  UpdateCommentArgs,
  UpdatePostArgs,
  DeletePostArgs,
  DeleteCommentArgs,
  ToggleLikesArgs
} from '../../graphql/hooks/hooks.types';

type ContextValue = {
  state: GlobalState;
  dispatch: GlobalDispatch;
};

type NewChannelPostsArgs = {
  channelId: string;
  postId: string;
};
type NewPostsArgs = {
  channelId: string;
};

export const StateContext = createContext<ContextValue>(null);

export const StateProvider: React.FC = ({ children }) => {
  const [{ posts, comments }, dispatch] = useReducer(reducer, globalState);
  const { subscribe: postSubscriptionUpdater } = useCreateSubscriptionUpdater(dispatch);

  const {
    subscribe: commentSubscriptionUpdater
  } = useCreateSubscriptionUpdater(dispatch);

  const postActionsCb = useMemo(
    () => ({
      setListAction: (postList: PostList) => postActions.setPostList(dispatch, postList),
      deleteAction: (postId: DeletePostProps) => postActions.deletePost(dispatch, postId),
      toggleLikesAction: (toggleLikesData: ToggleLikesType) => postActions.toggleLikes(dispatch, toggleLikesData),
      updateAction: (updatePost: UpdatePostType) => postActions.updatePost(dispatch, updatePost),
      updateCommentData: (updateCommentData: UpdateCommentDataProps) => commentsActions.updateCommentData(dispatch, updateCommentData)
    }),
    []
  );
  const commentActionsCb = useMemo(
    () => ({
      setListAction: (commentsList: CommentsList) => commentsActions.setCommentsList(dispatch, commentsList),
      deleteAction: (commentId: DeleteComment) => commentsActions.deleteMessage(dispatch, commentId),
      // toggleLikesAction: (toggleLikesData: ToggleLikesType) => postActions.toggleLikes(dispatch, toggleLikesData),
      updateAction: (updateComment: UpdateCommentType) => commentsActions.updateMessage(dispatch, updateComment),
      updateReplies: (updateReplies: UpdateReplies) => postActions.updateReplies(dispatch, updateReplies)
    }),
    []
  );
  // postActions.updateReplies(dispatch, { postId: data[responseData[type]].postId });
  const {
    subscribeToMore: subscribeToPost,
    fetchMore,
    toggleLikes,
    createMessages,
    deleteMessage: deletePost,
    updateMessage: updatePost,
    loading: loadingPost,
    savingMessage: savingPost,
    updateSaving: updatePostLoading
  } = useChannelPost<
    ComposePostArgs,
    UpdatePostArgs,
    DeletePostArgs,
    ToggleLikesArgs
  >(ChatTypes.operations, posts.channelData.channelId, postActionsCb);

  const {
    createMessages: createComment,
    subscribeToMore: subscribeToComment,
    fetchMore: fetchMoreComment,
    updateMessage: updateComment,
    deleteMessage: deleteComment,
    loading: loadingComment,
    savingMessage: savingComment,
    updateSaving: updateCommentLoading
  } = useChannelPost<
    ComposeCommentArgs,
    UpdateCommentArgs,
    DeleteCommentArgs,
    ToggleLikesArgs
  >(ChatTypes.comment, comments.postId, commentActionsCb);

  const onCompleted = useCallback(
    (data: { listUserChannels: { items: ChannelType[] } }) => {
      if (!data) {
        return;
      }
      const { listUserChannels } = data;
      if (listUserChannels?.items.length) {
        // gets first item from listUserChannels which is not deleted and set as active channel
        let chIndex = undefined;
        listUserChannels.items.forEach((item,index)=>{
        if(item.channels && chIndex === undefined) {
          chIndex =index
        }
      })
      const {
        channelId: userChannelId,
        channels
      } = chIndex? listUserChannels.items[chIndex]:listUserChannels.items[0];
          

        const userChannels = listUserChannels.items.filter(
          channelItem => !!channelItem.channels
        );

        if (channels) {
          const { name } = channels;
          postActions.setChannel(dispatch, {
            channelId: userChannelId,
            listUserChannels: userChannels,
            channelName: name
          });

          const chatIds = userChannels.map(({ channelId }) => channelId);

          postActions.setTopicsCollection(dispatch, {
            topicsCollectionCount: chatIds.length
          });

          chatIds.forEach((connectId) => {
            postSubscriptionUpdater<NewPostsArgs>({
              variables: {
                channelId: connectId
              },
              type: ChatTypes.operations,
              subscribeTo: subscribeToPost
            });
          });
        }
      }
    },
    [postSubscriptionUpdater, subscribeToPost]
  );

  useEffect(() => {
    if (!comments.postId) return;

    commentsActions.setTopicsCollection(dispatch, {
      topicsCollectionCount: 1
    });

    commentSubscriptionUpdater<NewChannelPostsArgs>({
      subscribeTo: subscribeToComment,
      type: ChatTypes.comment,
      variables: {
        channelId: posts.channelData.channelId,
        postId: comments.postId
      }
    });
  }, [commentSubscriptionUpdater, comments.postId, posts.channelData.channelId, subscribeToComment]);

  const { loading: channelsLoading, currentUser } = useChannels(
    {
      limit: 100,
      nextToken: null
    },
    onCompleted
  );

  const contextValue = useMemo(
    () => ({
      state: {
        comments,
        posts,
        fetchMore,
        fetchMoreComment,
        deletePost,
        updatePost,
        deleteComment,
        updateComment,
        toggleLikes,
        createMessages,
        createComment,
        currentUser,
        channelsLoading,
        loadingPost,
        loadingComment,
        savingPost,
        savingComment,
        updatePostLoading,
        updateCommentLoading
      },
      dispatch
    }),
    [
      comments,
      posts,
      fetchMore,
      fetchMoreComment,
      toggleLikes,
      createMessages,
      createComment,
      dispatch,
      currentUser,
      deletePost,
      deleteComment,
      updatePost,
      updateComment,
      channelsLoading,
      loadingPost,
      loadingComment,
      savingPost,
      savingComment,
      updatePostLoading,
      updateCommentLoading
    ]
  );

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export const useChanelState = () => useContext<ContextValue>(StateContext);
