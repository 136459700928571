import React, { useState } from "react";
import { Popup } from "semantic-ui-react";
import { Wrapper } from "./UploadFiles.styles";

import { ReactComponent as InsertAttach } from "../../../../icons/insert-attach.svg";
import { ReactComponent as Laptop } from "../../../../icons/laptop.svg";
import { ReactComponent as GoogleDrive } from "../../../../icons/google-drive.svg";
import { ReactComponent as Dropbox } from "../../../../icons/dropbox.svg";

import { UploadFromPcModal } from "./UploadFromPcModal";
import {CurrentUser, CreateMessagesTypes, SetAttachmentArrFunc} from "../../types";
import { ChatTypes } from "../../enums";

type UploadFilesProps = {
  createMessages: CreateMessagesTypes;
  currentUser: CurrentUser;
  type: ChatTypes;
  attachedFiles: File[];
  setAttachmentArr: SetAttachmentArrFunc;
};

enum UploadSource {
  dropbox = "Dropbox",
  googleDrive = "Google Drive",
  yourComputer = "Your Computer"
}

const assets = [
  { name: UploadSource.dropbox },
  { name: UploadSource.googleDrive },
  { name: UploadSource.yourComputer }
];

const icons = {
  [UploadSource.dropbox]: <Dropbox />,
  [UploadSource.googleDrive]: <GoogleDrive />,
  [UploadSource.yourComputer]: <Laptop />
};

const UploadFiles: React.FC<UploadFilesProps> = props => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const onSourceSelect = (key: UploadSource) => {
    switch (key) {
      case UploadSource.dropbox: {
        // TODO dropbox
        break;
      }
      case UploadSource.googleDrive: {
        // TODO googleDrive
        break;
      }
      case UploadSource.yourComputer: {
        setOpenPopup(false);
        setOpenModal(true);
        break;
      }
      default:
    }
  };

  return (
    <>
      <Popup
        on="click"
        onClose={() => setOpenPopup(false)}
        onOpen={() => setOpenPopup(true)}
        open={openPopup}
        trigger={<InsertAttach />}
      >
        <Wrapper>
          <div className="title">Add a file from</div>
          {assets.map(({ name }) => (
            <div
              key={name}
              className="menu-item"
              onClick={() => onSourceSelect(name)}
            >
              {icons[name]}
              <p>{name}</p>
            </div>
          ))}
        </Wrapper>
      </Popup>
      {openModal && (
        <UploadFromPcModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          {...props}
        />
      )}
    </>
  );
};

export default UploadFiles;
export { UploadFiles };
