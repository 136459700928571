import React from 'react';

import { PreviewOverlay } from './PreviewOverlay';
import { Wrapper } from './FilePreview.styles';
import { Attachments, AttachmentType } from '../../types';
import { DocumentCart } from '../DocumentCart';

type FilePreviewProps = {
  files: Attachments[];
  setFilesToBlob: React.Dispatch<React.SetStateAction<Attachments[]>>;
};

const FilePreview: React.FC<FilePreviewProps> = ({ files, setFilesToBlob }) => (
  <Wrapper>
    {files?.length ? (
      files.map((file, index) => {
        const { url, name, type } = file;
        if (type === AttachmentType.pdf) {
          return (
            <PreviewOverlay
              key={name}
              setFilesToBlob={setFilesToBlob}
              index={index}
            >
              <DocumentCart {...file} isPreview key={name} />
            </PreviewOverlay>
          );
        }

        return (
          <PreviewOverlay
            key={name}
            setFilesToBlob={setFilesToBlob}
            index={index}
          >
            <img src={url} alt="" />
          </PreviewOverlay>
        );
      })
    ) : (
      <h4>Please Select File</h4>
    )}
  </Wrapper>
);

export default FilePreview;
export { FilePreview };
