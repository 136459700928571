/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import SearchIcon from '../../../../icons/search.svg';
import sortIcon from '../../../../icons/inspection-icons/sort.svg';
import sortActiveIcon from '../../../../icons/inspection-icons/sort-active.svg';
import '../../../../components/topbar/profile/View/toggle.scss';
import styles from './fixedfields.module.scss';


class BrowseSpecies extends Component {
  state = {
    search: '',
    speciesList: undefined,
    currentSort: ''
  }

  static getDerivedStateFromProps(props, state) {
    if (props.speciesList && props.speciesList.length && !state.speciesList) {
      return {
        ...state,
        speciesList: props.speciesList
      };
    }
    return state;
  }

  handleSpeciesSearch = (e) => {
    const search = e.target.value;
    this.setState({ search });
  }

  handleSortData = (key) => {
    const { speciesList, currentSort } = this.state;
    if (key === 'type') {
      let sortList;
      let currentkey = key;
      if (currentSort === key) {
        sortList = speciesList.sort((a, b) => ((a.type < b.type) ? -1 : 1));
        currentkey = '';
      } else {
        sortList = speciesList.sort((a, b) => ((a.type > b.type) ? -1 : 1));
      }
      this.setState({ speciesList: sortList, currentSort: currentkey, sort: key });
    }
    if (key === 'species') {
      let currentkey = key;
      let sortList;
      if (currentSort === key) {
        sortList = speciesList.sort((a, b) => ((a.species < b.species) ? -1 : 1));
        currentkey = '';
      } else {
        sortList = speciesList.sort((a, b) => ((a.species > b.species) ? -1 : 1));
      }
      this.setState({ speciesList: sortList, currentSort: currentkey, sort: key });
    }
  }

  render() {
    const { showIn, onClose, SearchQuery, onSelect } = this.props;
    const { search, speciesList, sort } = this.state;
    return (
    <>
      {speciesList && showIn && (
      <Modal isOpen={showIn} toggle={onClose} className={styles.browseModal}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <FormattedMessage id="topbar.wildlife"
              defaultMessage="Wildlife"
            />
            <button onClick={onClose} type="button">&times;</button>
          </div>
          <div className={styles.toolbar}>
            <div className={styles.input_container}>
              <img src={SearchIcon} alt="" />
              <input type="text" name="SearchQuery" value={SearchQuery} onChange={e => this.handleSpeciesSearch(e)} />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="wildlife.from.browse_modal.image" defaultMessage="IMAGE" />
                </th>
                <th onClick={() => this.handleSortData('type')}>
                  <FormattedMessage id="wildlife.from.browse_modal.type" defaultMessage="TYPE" />
                  <img
                    src={sort === 'type' ? sortActiveIcon : sortIcon}
                    alt=""
                    onClick={() => this.handleSortData('type')}
                  />
                </th>
                <th onClick={() => this.handleSortData('species')}>
                  <FormattedMessage id="wildlife.from.browse_modal.species" defaultMessage="SPECIES" />
                  <img
                    src={sort === 'species' ? sortActiveIcon : sortIcon}
                    alt=""
                    onClick={() => this.handleSortData('species')}
                  />
                </th>
              </tr>
            </thead>
            {speciesList.map(e => (
              <>
                {search && e.image ? (
                  (e.type.toUpperCase()).includes(search.toUpperCase())
                        || (e.species.toUpperCase()).includes(search.toUpperCase())
                        || (search.toUpperCase()).includes(e.type.toUpperCase())
                        || (search.toUpperCase()).includes(e.species.toUpperCase())) && (
                        <tr onClick={() => onSelect(e)}>
                          <td><img src={e.image} alt="" /></td>
                          <td>{e.type}</td>
                          <td>{e.species}</td>
                        </tr>
                ) : (
                  <tr onClick={() => onSelect(e)}>
                    <td><img src={e.image} alt="" /></td>
                    <td>{e.type}</td>
                    <td>{e.species}</td>
                  </tr>
                )}
              </>
            ))}
          </table>
        </div>
      </Modal>
      )}
    </>
    );
  }
}

BrowseSpecies.propTypes = {
  showIn: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  speciesList: PropTypes.shape({})
};

const mapStateToProps = state => ({
  profile: state.auth.profile
});

BrowseSpecies.defaultProps = {
  showIn: false,
  speciesList: undefined
};

export default connect(
  mapStateToProps
)(BrowseSpecies);
