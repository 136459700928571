import {
  IActionTypes,
  PostsActionType,
  PostsPropsAction,
  ReadMessagesPropsAction,
  SubTopicsCollectionAction,
  // SubTopicsCollection,
  TopicsCollectionAction,
  PostListAction,
  DeletePostPropsAction,
  ToggleLikesTypeAction,
  SetEditedPostAction,
  UpdatePostAction,
  SetChannelAction,
  UpdateRepliesAction,
  DeletedPostAction
} from './types';

export const actions: IActionTypes = {
  setNewMessage: (dispatch, props) => dispatch<PostsPropsAction>({
    type: PostsActionType.setNewMessage,
    payload: props
  }),
  setReadMessages: (dispatch, props) => dispatch<ReadMessagesPropsAction>({
    type: PostsActionType.setReadMessages,
    payload: props
  }),
  setTopicsCollection: (dispatch, props) => dispatch<TopicsCollectionAction>({
    type: PostsActionType.setTopicsCollection,
    payload: props
  }),
  setDeletedPost: (dispatch, props) => dispatch<DeletedPostAction>({
    type: PostsActionType.setDeletedPostID,
    payload: props
  }),
  updateTopicsCollection: dispatch => dispatch<SubTopicsCollectionAction>({
    type: PostsActionType.updateTopicsCollection
  }),
  setPostList: (dispatch, props) => dispatch<PostListAction>({
    type: PostsActionType.setPostList,
    payload: props
  }),
  deletePost: (dispatch, props) => dispatch<DeletePostPropsAction>({
    type: PostsActionType.deletePost,
    payload: props
  }),
  toggleLikes: (dispatch, props) => dispatch<ToggleLikesTypeAction>({
    type: PostsActionType.toggleLikes,
    payload: props
  }),
  setEditedMessage: (dispatch, props) => dispatch<SetEditedPostAction>({
    type: PostsActionType.setEditedMessage,
    payload: props
  }),
  updatePost: (dispatch, props) => dispatch<UpdatePostAction>({
    type: PostsActionType.updatePost,
    payload: props
  }),
  setChannel: (dispatch, props) => dispatch<SetChannelAction>({
    type: PostsActionType.setChannel,
    payload: props
  }),
  updateReplies: (dispatch, props) => dispatch<UpdateRepliesAction>({
    type: PostsActionType.updateReplies,
    payload: props
  })
};
