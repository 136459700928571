import gql from 'graphql-tag';

const UPDATE_COMMENT = gql`
  mutation updateComment(
    $commentId: String!
    $userId: String!
    $content: String!
  ) {
    updateComment(
      input: { commentId: $commentId, userId: $userId, content: $content }
    ) {
      channelId
      commentId
      content
      createdAt
      iropsId
      likes
      parentCommentId
      postId
      updatedAt
      userId
      isLiked
      user {
        firstName
        lastName
        airportId
        profilePicture
        airportDetails {
          name
          code
          logo
        }
      }
    }
  }
`;

export default UPDATE_COMMENT;
