import React from 'react';
import PulpoField from '../../../../../../../pulpo_visualizer/fields';
import styles from '../../steps.module.scss';

const AircraftForm = ({
  handleAnswerChanged,
  info,
  allTypes
}) => (
    <>
      <div className={styles.stepTwo}>
        <div className={styles.displayFlex}>
          <PulpoField key="name" id="name" type="select"
            transationID="lease.tenants.steps.step4.aircraft_type"
            title="Aircraft Type" className={styles.fullInput}
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.name}
            handleFieldErrorChanged={() => {}}
            values={allTypes ? allTypes.aircraft_types.map(e => (
              ({ key: e.name, value: e.name })
            )): []}
          />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField
            key="model" id="model" type="string"
            translationID="lease.tenants.steps.step4.model"
            title="Make/Model"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.model}
            handleFieldErrorChanged={() => {}}
          />
          <PulpoField
            key="n_number" id="n_number" type="string"
            translationID="lease.tenants.steps.step4.number"
            title="N Number"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.n_number}
            handleFieldErrorChanged={() => {}}
          />
        </div>
        <div className={styles.displayFlex}>
          <PulpoField
            key="owner" id="owner" type="string"
            translationID="lease.tenants.steps.step4.owner"
            title="Registered Owner"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.owner}
            handleFieldErrorChanged={() => {}}
          />
          <PulpoField
            key="owner_address" id="owner_address" type="string"
            translationID="lease.tenants.steps.step4.owner_address"
            title="Registered Owner Address"
            handleValueChange={(a, b) => handleAnswerChanged(a, b)}
            answer={info && info.owner_address}
            handleFieldErrorChanged={() => {}}
          />
        </div>
        <div className={styles.endingBorder} />
      </div>
    </>
);

export default AircraftForm;
