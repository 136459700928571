import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/button';
import styles from '../../../../components/filters/filteritem.module.scss';
import Collapsible from '../../../../components/collapsible/Collapsible';
import FilterItem from '../../../../components/filters/FilterItem';
import Clickable from '../../../../components/clickable/Clickable';
import { GetWildLifeInfo } from '../../../services/GetWildLifeInfo';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [
        { id: 1,
          name: 'Types',
          permissions: []
        },
        {
          id: 2,
          name: 'Species',
          permissions: []
        }
      ],
      filterCategories: undefined
    };
  }

  componentDidMount = async () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    const info = await GetWildLifeInfo.getWildLifeInfo();
    const species = [];
    info.forEach((e) => {
      e.Species.forEach((s) => {
        species.push({ title: s.name, type: 'species' });
      });
    });
    const types = info.map(e => ({ title: e.Type, type: 'types' }));
    this.setState(prevState => ({
      filters: prevState.filters.map(
        obj => (obj.id === 2 ? Object.assign(obj, { permissions: species }) : Object.assign(obj, { permissions: types }))
      )
    }));
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.filterCategories === undefined && props.filterCategories) {
      return { ...state, filterCategories: [...props.filterCategories] };
    }
    return state;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { cancel } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      cancel();
    }
  }

  handleFilterChange = (name, event) => {
    const { filterCategories } = this.state;
    const check = event.target.checked;
    const checkedValue = event.target.id;
    if (check) {
      this.setState({
        filterCategories: [...filterCategories, checkedValue]
      });
    } else {
      const index = filterCategories.indexOf(checkedValue);
      if (index > -1) {
        filterCategories.splice(index, 1);
        this.setState({ filterCategories });
      }
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClear = () => {
    this.setState({ filterCategories: [] });
    document.querySelectorAll('input[type=checkbox]').forEach((el) => { el.checked = false; });
  }

  render() {
    const { update, cancel } = this.props;
    const { filters, filterCategories } = this.state;
    return (
      <>
        <div className={styles.popup} ref={this.setWrapperRef}>
          <div className={styles.header}>
            <span className={styles.filterText}>
              <FormattedMessage id="components.filters" defaultMessage="Filters" />
            </span>
            <Clickable onClick={this.handleClear} className={styles.cancel} style={{ marginTop: '20px', marginLeft: '70px' }}>
              <FormattedMessage id="filters.form.clear" defaultMessage="Clear" />
            </Clickable>
            <Clickable onClick={cancel} className={styles.cancel} style={{ marginTop: '20px' }}>
              <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
            </Clickable>
            <Button
              action="secondary"
              translationID="workorder.filter.apply"
              defaultText="Apply"
              onClick={() => update(filterCategories)}
            />
          </div>
          <div className={styles.list}>
            {filters.map(e => (
              <div key={e.id}>
                <Collapsible title={e.name} styleClasses={styles.rol} autoheight={false} openOnMount={e.name === 'Types'}>
                  <FilterItem
                    id={e.id}
                    filter={e}
                    handleChange={(id, targetId) => this.handleFilterChange(id, targetId)}
                    filterCategories={filterCategories}
                  />
                </Collapsible>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default FilterForm;
