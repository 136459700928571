import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HOC as Permissions } from 'react-redux-permissions';
import { FormattedMessage } from 'react-intl';

import {
  fetchWorkOrderSchema,
  updateWorkOrderSchema,
  clearMaintenance,
  fetchCategories
} from '../../redux/actions';

import { LEASE_HOME } from '../../../../constants/RouterConstants';

import SectionHeader from '../../../../components/sectionHeader/SectionHeader';
import Spinner from '../../../../components/spinner';
import HeaderBack from '../../../../components/headerBack';
import Button from '../../../../components/button';
import Clickable from '../../../../components/clickable/Clickable';
import Forbidden from '../../../Forbidden';
import workOrder from '../../../../components/settingsHeader/icons/settings.svg';
import styles from '../../../assets-management/Settings/assetSchemaBuilder.module.scss';
import MaintenanceSchemaBuilder from './components/MaintenanceSchemaBuilder';
import MaintenanceAsigneeBuilder from './components/MaintenanceAsigneeBuilder';

class MaintenanceBuilder extends Component {
  state = {
    modal: false,
    loadedFields: false,
    workorder: [],
    maintenance: [],
    maxIdWorkorder: 1,
    maxIdMaintenance: 1,
    view: 'fields',
    assign: {}
  }

  componentDidMount() {
    const { actionGetSchemas, leaseMaintenance_schema, actionGetCategories } = this.props;
    if (!leaseMaintenance_schema.length) actionGetSchemas();
    actionGetCategories();
  }

  componentWillUnmount() {
    const { clear, actionGetSchemas } = this.props;
    actionGetSchemas();
    clear();
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.leaseMaintenance_schema.workorder || state.loadedFields) return state;

    const {
      leaseMaintenance_schema: { workorder, maintenance }
    } = props;

    // Update the ids for new fields.
    const workorderMaxId = workorder.schema.fields.reduce(
      (max, i) => (Number(i.id.substr(3)) > max ? Number(i.id.substr(3)) : max),
      1
    );
    const maintenanceMaxId = maintenance.schema.fields.reduce(
      (max, i) => (Number(i.id.substr(3)) > max ? Number(i.id.substr(3)) : max),
      1
    );
    const workorderFields = workorder.schema.sections.length ? (
      workorder.schema.sections[0].fields) : [];
    const maintenanceFields = maintenance.schema.sections.length ? (
      maintenance.schema.sections[0].fields) : [];
    return {
      workorder: workorderFields.map(
        f => workorder.schema.fields.find(fi => fi.id === f)
      ),
      maintenance: maintenanceFields.map(
        f => maintenance.schema.fields.find(fi => fi.id === f)
      ),
      workorderMaxId,
      maintenanceMaxId,
      loadedFields: true
    };
  }

  handleFieldOrderChanged = (fields, form) => {
    this.setState({
      [`${form}`]: fields
    });
  }

  handleCreateField = (field, form) => {
    const {
      [`${form}`]: fields,
      [`${form}MaxId`]: maxId
    } = this.state;

    if (field.delete) {
      const newitems = fields.filter(e => e.id !== field.id);
      this.setState({ [`${form}`]: newitems });
      return;
    }

    if (field.id) {
      const i = fields.findIndex(e => e.id === field.id);
      fields[i] = field;
      this.setState({
        [`${form}`]: fields
      });
    } else {
      const newField = { ...field, id: `${form.substring(0, 3)}${maxId + 1}` };
      this.setState(prevState => ({
        [`${form}`]: [...fields, newField],
        [`${form}MaxId`]: prevState[`${form}MaxId`] + 1
      }));
    }
  }

  /**
   * Makes the redux action call to update the schemas
   * in backend
   */
  handleSaveSchemas = () => {
    const { actionSave, schemaAction } = this.props;
    if (schemaAction.loading) return;
    const {
      workorder,
      maintenance
    } = this.state;

    const workorderSchema = workorder.length ? ({
      id: '',
      version: 1,
      fields: [...workorder],
      sections: [{
        id: 'SEC1',
        fields: workorder.map(field => field.id),
        title: 'Work order'
      }],
      pages: [{
        id: 'PAGE1',
        sections: ['SEC1'],
        title: 'Work order'
      }]
    }) : { id: '', version: 1, fields: [], sections: [], pages: [] };

    const maintenanceSchema = maintenance.length ? ({
      id: '',
      version: 1,
      fields: [...maintenance],
      sections: [{
        id: 'SEC1',
        fields: maintenance.map(field => field.id),
        title: 'Maintenance'
      }],
      pages: [{
        id: 'PAGE1',
        sections: ['SEC1'],
        title: 'Maintenance'
      }]
    }) : { id: '', version: 1, fields: [], sections: [], pages: [] };

    actionSave({
      workorder: workorderSchema,
      maintenance: maintenanceSchema
    });
  }

  /**
   * Changes the view between fields and assignments
   * */
  handleViewChange = (value) => {
    const { clear } = this.props;
    this.setState({ view: value });
    clear();
  }

  render() {
    const {
      view,
      workorder,
      maintenance
    } = this.state;

    const { schemaAction, allCategories } = this.props;

    return (
      <div className={styles.builder}>
        <SectionHeader icon={workOrder} translationID="lease.maintenance.settings.title" defaultTitle="Maintenance Settings" />
        <HeaderBack
          translationID="Asset.settings.back"
          translationDefault="Back"
          backRoute={`${LEASE_HOME}/maintenance`}
        >
          <div className={styles.links}>
            <Clickable onClick={() => this.handleViewChange('fields')}
              className={view === 'fields' ? styles.active : ''}
            >
              <FormattedMessage id="lease.maintenance.settings.feilds" defaultMessage="Work Order Fields" />

            </Clickable>
            <Clickable onClick={() => this.handleViewChange('assign')}
              className={view === 'assign' ? styles.active : ''}
            >
              <FormattedMessage id="lease.maintenance.settings.asignee" defaultMessage="Asignee" />
            </Clickable>
          </div>
          <div className={styles.saveFormsBtn}>
            {schemaAction.success && <FormattedMessage tagName="h4" id="workorder.settings.saved" defaultMessage="Saved" />}
            <Spinner active={schemaAction.loading} />
            {view === 'fields' && (
            <Button action="secondary" onClick={this.handleSaveSchemas}
              translationID="asserts.settings.saveBtn" defaultText="Save Forms"
            />
            )}
          </div>
        </HeaderBack>
        {view === 'fields' && (
          <MaintenanceSchemaBuilder
            workorder={workorder}
            maintenance={maintenance}
            onFieldOrderChanged={this.handleFieldOrderChanged}
            onCreateField={this.handleCreateField}
          />
        )}
        {view === 'assign' && allCategories && (
        <MaintenanceAsigneeBuilder
          allCategories={allCategories}
        />
        )}
      </div>
    );
  }
}

MaintenanceBuilder.defaultProps = {
  leaseMaintenance_schema: {},
  schemaAction: {},
  allCategories: {}
};

MaintenanceBuilder.propTypes = {
  actionGetSchemas: PropTypes.func.isRequired,
  actionSave: PropTypes.func.isRequired,
  actionGetCategories: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  leaseMaintenance_schema: PropTypes.shape({}),
  schemaAction: PropTypes.shape({}),
  allCategories: PropTypes.shape({})
};


const mapStateToProps = state => ({
  leaseMaintenance_schema: state.lease.leaseMaintenance_schema,
  schemaAction: state.lease.schemaAction,
  allCategories: state.lease.allCategories
});

const mapDispatchToProps = dispatch => ({
  actionGetSchemas: () => {
    dispatch(fetchWorkOrderSchema());
  },
  actionSave: (data) => {
    dispatch(updateWorkOrderSchema(data));
  },
  actionGetCategories: () => {
    dispatch(fetchCategories());
  },
  clear: () => {
    dispatch(clearMaintenance());
  }
});

export default Permissions(['can_modify_airport_settings'])(
  connect(mapStateToProps, mapDispatchToProps)(MaintenanceBuilder),
  <Forbidden />
);
