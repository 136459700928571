import React, { useState } from 'react';

import { Wrapper } from './MessageContent.styles';
import { ChatTypes } from '../../../enums';
import { contentSubstring } from '../../../util/contentSubstring';

type MessageContentProps = {
  content: string;
  type: ChatTypes;
  commentedPost?: boolean;
  styles?:object;
};

const INIT_CHAR_COUNT = 138;

const MessageContent = React.memo<MessageContentProps>(
  ({ content, commentedPost = false, type,styles }) => {
    const [showMore, setShowMore] = useState(false);
    if (!commentedPost || content.length <= INIT_CHAR_COUNT) {
      return <Wrapper viewType={type} style={styles}>{content}</Wrapper>;
    }

    const message = !showMore
      ? contentSubstring({ content, cutTo: INIT_CHAR_COUNT })
      : content;

    return showMore ? (
      <Wrapper viewType={type}>
        {message}
        <button type="button" onClick={() => setShowMore(false)}>
          Show less
        </button>
      </Wrapper>
    ) : (
      <Wrapper viewType={type}>
        {message}
        <button type="button" onClick={() => setShowMore(true)}>
          Show more
        </button>
      </Wrapper>
    );
  }
);

export default MessageContent;
export { MessageContent };
