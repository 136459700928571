import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Clickable from '../../../../../components/clickable/Clickable';

import styles from './selector.module.scss';
import checklistStyles from '../../../Complete/components/InspectionChecklist/checklist.module.scss';

const Selector = ({ form, activeInspectionField, answers, shift, changeSelectedField }) => (
  <div className={styles.selector}>
    <div className={`${styles.item} ${styles.title}`}>
      <FormattedMessage
        id="inspections.answer_details.inspection_checklist"
        defaultMessage="Inspection Checklist"
      />
    </div>
    {form.sections[1].fields.map((fieldId) => {
      const checklistField = form.fields.find(field => (
        field.id === fieldId
      ));
      const checklist_length = Object.keys(checklistField.checklist.filter(
        item => !item.shifts || item.shifts.indexOf(shift) !== -1 || item.shifts.length === 0
      )).length;
      let allTrue = answers[checklistField.id] && Object.values(answers[checklistField.id]);
      if (allTrue && allTrue.length > 0) allTrue = allTrue.reduce((a, b) => a && b);
      const status = allTrue ? checklistStyles.success : checklistStyles.failure;
      let field = '';
      if (checklist_length > 0) {
        field = (
          <Clickable className={`${styles.item} ${checklistStyles.inspectionChecklist} 
            ${fieldId === activeInspectionField ? styles.active : ''}`}
            onClick={() => changeSelectedField(fieldId)} key={fieldId}
          >
            <span className={`${checklistStyles.statusDot} ${status}`} />
            <div className={styles.titleContainer}>
              {checklistField.title}
            </div>
          </Clickable>
        );
      }
      return (
        <>{ field } </>
      );
    })}
  </div>
);

Selector.propTypes = {
  form: PropTypes.shape().isRequired,
  activeInspectionField: PropTypes.string.isRequired,
  answers: PropTypes.shape({}).isRequired,
  changeSelectedField: PropTypes.func.isRequired
};

export default Selector;
