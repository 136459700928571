/* eslint-disable array-callback-return */
/* eslint no-underscore-dangle: 0 */
/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Leaflet Imports
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import drawLocales from 'leaflet-draw-locales';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import { addSurface, fetchSurfaces,
  fetchSurfaceTypes, addSurfaceType, deleteSurface, editSurface, clear } from './redux/actions';


import * as utils from './mapUtils';

import SurfaceForm from './mapForm';
import Toolbar from './toolbar';

import styles from './map.module.scss';

let drawControl;
let map = {};
const drawnItems = {};
const language = localStorage.getItem('lang') ||'en';
drawLocales(language);

const locale = drawLocales(language);
locale.draw.toolbar.buttons.polygon = 'Draw a New Surface!';
L.drawLocal = locale;

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      layer: {},
      counter: 0,
      typeSelected: undefined,
      allLayers: [{ key: -1, name: 'All Surfaces', color: '#CCCCCC' }],
      detail: false,
      editSurface: {},
      spaceError: false,
      shouldShowErrors: false,
      nameLengthError: false,
      info: {},
      surfaces: {},
      edit: true,
      removeMarker: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLayerChange = this.handleLayerChange.bind(this);
    this.changeView = this.changeView.bind(this);
    this.addPopupToLayer = this.addPopupToLayer.bind(this);

    this.defaultConfig = {
      marker: false,
      circle: false,
      rectangle: false,
      polyline: false,
      circlemarker: false
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.typeSelected) {
      props.types.map(({ key }) => {
        drawnItems[key] = new L.FeatureGroup();
        map.addLayer(drawnItems[key]);
        return true;
      });
      return { typeSelected: props.types[0] };
    }
    if (props.createAction.success === true || props.editAction.success === true) {
      // props.actionClear();
      return {
        ...state,
        popup: false,
        info: {
          ...state.info,
          id: '',
          name: '',
          geometry: ''
        }
      };
    }
    return state;
  }

  componentDidMount() {
    const { user, actionFetchTypes, actionFetch } = this.props;
    if (user.id) {
      actionFetch();
      actionFetchTypes();
      this.initializeMap();
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const {
      user,
      surfaces,
      types,
      createAction,
      actionClear,
      editAction,
      deleteAction,
      actionFetch
    } = this.props;
    const { allLayers, edit } = this.state;
    const that = this;

    if (createAction.success === true && prevProps.createAction.success === false) {
      this.setState({ popup: false, edit: true, surfaces: this.props.surfaces }, () => {
        window.location.reload();
      });
      actionClear();
    }

    if (editAction.success === true && prevProps.editAction.success === false) {
      this.setState({ popup: false, edit: true, surfaces: this.props.surfaces }, () => {
        actionClear();
      });
    }

    if (deleteAction.success === true && prevProps.deleteAction.success === false) {
      actionFetch();
      this.setState({ popup: false, edit: true, surfaces: this.props.surfaces }, () => {
        actionClear();
      });
    }

    if (prevProps.surfaces.length !== surfaces.length) {
      this.setState({ counter: surfaces.length})
    }

    if (!prevProps.user.id && user.id) {
      const { actionFetch, actionFetchTypes } = this.props;
      actionFetch();
      actionFetchTypes();
      this.initializeMap();
    }
    if ((allLayers.length === 1 && surfaces.length && types.length)) {
      let allLayers = [{ key: -1, name: this.props.intl.formatMessage({ id: 'surface.allsurfaces' }), color: '#CCCCCC' }];
      surfaces.forEach((o) => {
        let l = L.geoJSON(o.geometry)._layers;
        [l] = Object.keys(l).map(ob => l[ob]);
        const customlayer = this.addPopupToLayer(o, l);
        map.addLayer(drawnItems[o.surface_type.id].addLayer(customlayer));

        l.on('click', (e) => {
          let id = o.id;
          if (edit) {
            map.layer = e.target;
            map.eachLayer((layer) => {
              if (layer.editing && layer.editing.enabled()) {
                layer.editing.disable();
              }
            });
            let Surfaces = this.props.surfaces;
            // Here , in result we are trying to update the surface with latest edited results
            let result = Surfaces.find(function( obj ) { return obj.id === id; })
            e.target.editing.enable();
            that.setState({
              popup: true,
              detail: true,
              info: result,
              typeSelected: o.surface_type,
              editSurface: e.target
            });
          }
        });
        allLayers.push({
          key: o.surface_type.id,
          name: o.surface_type.name,
          color: o.surface_type.color
        });
      });
      allLayers = allLayers.filter(
        (l, index, self) => self.findIndex(
          t => t.key === l.key
        ) === index
      );
      this.setState({
        allLayers,
        counter: surfaces.length
      });
    }
  }

  async componentWillUnmount() {
    const { actionClear }= this.props;
    await actionClear();
  }

  addPopupToLayer = (surface, layer) => {
    const { surface_type: { color } } = surface;
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: color,
      color,
      opacity: 1,
      fillOpacity: 0.48
    });
    return customlayer;
  };

  initializeMap() {
    const { user } = this.props;
    map = L.map('map', {
      center: [...user.airport.location.coordinates].reverse(),
      zoom: 15,
      editable: true,
    });
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(map);

    // creation callback for triggering form.
    const that = this;
    map.on(L.Draw.Event.CREATED, (e) => {
      that.drawNewLayer(e)
    });
  }

  drawNewLayer = (e) => {
    drawnItems[this.state.typeSelected.key].addLayer(e.layer);
    utils.toggleZooming(map, 'disable');
    this.setState({ popup: true, layer: e.layer });
  }

  handleChange(type) {
    map.removeControl(drawControl);
    drawControl = this.drawNewControl(type);
    this.setState({ typeSelected: type });
    map.addControl(drawControl);
  }

  handleLayerChange(option) {
    Object.keys(drawnItems).forEach(k => (
      map.removeLayer(drawnItems[k])
    ));

    if (option.key === -1) {
      let counter = 0;
      Object.keys(drawnItems).forEach((k) => {
        counter += Object.keys(drawnItems[k]._layers).length;
        map.addLayer(drawnItems[k]);
      });
      this.setState({ counter });
    } else {
      map.addLayer(drawnItems[option.key]);
      this.setState({ counter: Object.keys(drawnItems[option.key]._layers).length });
    }
  }

  drawNewControl(controlType) {
    this.handleTypeChange(controlType, false);
    this.setState({ editSurface: {}, layer: {}, typeSelected: controlType });
    return new L.Control.Draw({
      edit: { featureGroup: drawnItems[controlType.key],
        remove: false,
        edit: false
      },
      draw: {
        ...this.defaultConfig,
        polygon: {
          shapeOptions: {
            color: controlType.color,
            opacity: 1,
            fillOpacity: 0.48
          },
          icon: new utils.CustomIcon({ color: controlType.color })
        }
      }
    });
  }

  changeView(editMode) {
    const { types } = this.props;
    this.handleCancel();
    if (!editMode) {
      drawControl = this.drawNewControl(types[0]);
      map.addControl(drawControl);
    } else {
      map.removeControl(drawControl);

      // update counter
      let counter = 0;
      Object.keys(drawnItems).forEach((k) => {
        counter += Object.keys(drawnItems[k]._layers).length;
      });
      this.setState({ counter });
    }
  }

  handleDelete = () => {
    const { actionDeleteSurface } = this.props;
    const { info } = this.state;
    actionDeleteSurface(info.id);
    map.eachLayer((layer) => {
      if (layer.editing && layer.editing.enabled()) {
        map.removeLayer(layer);
      }
    });
    this.setState({ popup: false });
  }

  handleCancel = () => {
    this.setState(prevState => ({
      editSurface: {},
      typeSelected: this.props.types[0],
      removeMarker: true,
      popup: false,
      info: {
        ...prevState.info,
        id: '',
        name: '',
        geometry: '',
        surface_type: this.props.types[0]
      }
    }));
    // map.removeControl(drawControl);
    utils.disableEditMarkers(map);
  }

  handleOnEdit = () => {
    const { info: { id, surface_type, ...data } } = this.state;
    utils.toggleZooming(map, 'enable');
    const { editSurface } = this.state;
    if (!data.name) {
      this.setState({ shouldShowErrors: true });
      this.setState({ spaceError: false });
      return;
    }
    if (!data.name.trim()) {
      this.setState({ spaceError: true });
      return;
    }
    if (data.name.length > 250) {
      this.setState({ nameLengthError: true });
      return;
    }
    const { actionEditSurface, user } = this.props;
    const coordinates = editSurface._latlngs[0].map(o => [o.lng, o.lat]);
    coordinates.push(coordinates[0]);
    const surfaceShape = {
      surface_type: surface_type.key,
      airport: user.airport.id,
      name: data.name,
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates]
      }
    };
    actionEditSurface(id, surfaceShape);
    this.setState({ editSurface: {}, layer: {} });
    utils.disableEditMarkers(map);
  }

  handleSave = () => {
    const { info: { id, surface_type, ...data }, typeSelected } = this.state;
    utils.toggleZooming(map, 'enable');

    const { key } = surface_type;
    const { layer } = this.state;
    const { actionSave, user } = this.props;

    if (key !== typeSelected.key) {
      drawnItems[typeSelected.key].removeLayer(layer);
      drawnItems[key].addLayer(layer);
    }
    if (!data.name) {
      this.setState({ shouldShowErrors: true });
      this.setState({ spaceError: false });
      return;
    }
    if (!data.name.trim()) {
      this.setState({ spaceError: true });
      return;
    }
    if (data.name.length > 250) {
      this.setState({ nameLengthError: true });
      return;
    }

    const coordinates = layer._latlngs[0].map(o => [o.lng, o.lat]);
    coordinates.push(coordinates[0]);
    const surfaceShape = {
      surface_type: surface_type.key,
      airport: user.airport.id,
      name: data.name,
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates]
      }
    };
    actionSave(surfaceShape);
    utils.disableEditMarkers(map);
  }

  handleTypeChange = (selected, changeSurface = true) => {
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        surface_type: selected
      }
    }));
  }

  handleInputChange = (value, id) => {
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        [id]: value
      }
    }));
  }


  render() {
    const { popup, counter, typeSelected, allLayers, detail, spaceError, shouldShowErrors, nameLengthError, info } = this.state;
    const { types } = this.props;
    return (

      <div className={styles.surfaceMap}>
        <Toolbar
          key={types[0] ? types[0].key : 0}
          onLayerChange={this.handleLayerChange}
          onTypeChange={this.handleChange}
          types={types}
          changeView={this.changeView}
          allLayers={allLayers}
          surfaceCounter={counter}
        />
        <div id="map" className={styles.map} />
        {popup
          && (
          <Transition in={popup} timeout={200} unmountOnExit>
            { state => (
              <SurfaceForm
                info={info}
                detail={detail}
                types={types}
                onSave={this.handleSave}
                onTypeChange={this.handleTypeChange}
                onInputChange={this.handleInputChange}
                defaultType={typeSelected}
                onDelete={this.handleDelete}
                onEdit={this.handleOnEdit}
                transition={this.slideStyles[state]}
                onCancel={this.handleCancel}
                spaceError={spaceError}
                shouldShowErrors={shouldShowErrors}
                nameLengthError={nameLengthError}
              />
            )}
          </Transition>
          )}
      </div>
    );
  }
}


Map.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({}).isRequired,
  // Dispatch redux functions
  actionSave: PropTypes.func.isRequired,
  actionFetch: PropTypes.func.isRequired,
  actionFetchTypes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  surfaces: state.map.surfaces,
  types: state.map.types,
  user: state.auth.profile,
  deleteAction: state.map.deleteAction,
  editAction: state.map.updateAction,
  createAction: state.map.createAction
});

const mapDispatchToProps = dispatch => ({
  // Fetch Surfaces
  actionFetch: (id) => {
    dispatch(fetchSurfaces(id));
  },
  // Add New Surface
  actionSave: (surface) => {
    dispatch(addSurface(surface));
  },
  // Fetch Surface Type
  actionFetchTypes: (id) => {
    dispatch(fetchSurfaceTypes(id));
  },
  // Save surface
  actionSaveType: (surface) => {
    dispatch(addSurfaceType(surface));
  },

  // Delete Surface
  actionDeleteSurface: (id) => {
    dispatch(deleteSurface(id));
  },

  // Edit Surface
  actionEditSurface: (id, surface) => {
    dispatch(editSurface(id, surface));
  },

  // Clear Result
  actionClear: () => {
    dispatch(clear());
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Map));
