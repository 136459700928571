import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .selection {
    width: 80%;
  }

  .text {
    padding: unset;
    max-width: unset;
    margin: unset;
    border-radius: unset;
    background-color: unset;
    color: unset;
    display: unset;
    margin: unset;
  }
`;
