/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { FormGroup, Label, Input } from 'reactstrap';
import Toggle from 'react-toggle';
import Modal from '../../../../../components/modal';
import { importAllImages } from '../../../../../utils/helpers';
import styles from '../steps.module.scss';
import Scheduler from '../../../../../components/scheduler/Scheduler';
import Assignee from '../../../../../components/assignee/Assignee';
import Button from '../../../../../components/button';
import PulpoField from '../../../../../pulpo_visualizer/fields';

const Step1 = ({
  recurrence,
  info,
  hasTemplateUpdate,
  hasVersionUpdate,
  onInfoChange,
  onUpdateVersion,
  assignees,
  userlist,
  form_access,
  handleOnclick,
  handleChangeOption,
  rules,
  intl,
  toggle,
  roles,
  errors,
  fielderror,
  assignee,
  handleChange,
  handleAssigneeChanged,
  handleAssigneeSelected,
  handleRoleChange,
  onAssignTypeChanged,
  showFieldErrors,
  sendEmail,
  handleEmailNotify,
  inspectionEmail,
  onEmailAssignTypeChanged,
  handleEmailAssigneeChanged,
  handleEmailRoleChange,
  handleEmailAssigneeSelected,
  userList,
  emailFieldError
}) => {
  const [modal, setModal] = useState(false);
  const images = importAllImages(require.context('../../../../../icons/inspection-icons', false, /\.(png|jpe?g|svg)$/));
  const selectIcon = (icon) => {
    setModal(false);
    onInfoChange('icon', icon);
  };
  const options1 = [];
  const options2 = [];
  // eslint-disable-next-line array-callback-return
  roles.map((e) => {
    options1.push({
      value: e.name,
      label: e.name,
      id: e.id,
      type: 'role',
      permissions: []
    });
  });
  // eslint-disable-next-line array-callback-return
  userlist.map((e) => {
    options2.push({
      value: e.fullname,
      label: e.fullname,
      id: e.id,
      type: 'user',
      permissions: []
    });
  });
  const groupedOptions = [
    {
      label: intl.formatMessage({ id: 'translation.form_access_role' }),
      options: options1
    },
    {
      label: intl.formatMessage({ id: 'translation.form_access_user' }),
      options: options2
    }
  ];
  return (
    <div className={styles.step1}>
      {hasTemplateUpdate && (
      <div className={styles.newVersion}>
        <FormattedMessage tagName="h5" id="inspections.step1.template"
          defaultMessage="There is a new version of this template, do you want to update it?"
        />

        <Button onClick={() => onUpdateVersion('template')}
          translationID="inspections.step1.update" defaultText="update" action="primary"
        />
      </div>
      )}
      {hasVersionUpdate && (
        <div className={styles.newVersion}>
          <FormattedMessage tagName="h5" id="inspections.step1.templateupdate"
            defaultMessage="There is a new version of the base template of this form, do you want to update it?"
          />
          <Button onClick={() => onUpdateVersion('version')}
            translationID="inspections.step1.update" defaultText="update" action="primary"
          />
        </div>
      )}
      <div className={styles.title}>
        <FormattedMessage tagName="h5" id="inspections.step1.title"
          defaultMessage="Inspection Overview"
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.infoStyle}>
        <FormattedMessage tagName="p" id="inspections.step1.subsection"
          defaultMessage="Inspection Overview"
        />
      </div>
      <div className={styles.info}>
        <label htmlFor="inspName">
          <small style={{ color: 'red' }}>*</small>
          <span className={styles.size}>
            <FormattedMessage
              id="inspections.step1.labelName"
              defaultMessage="Inspection Name"
            />
          </span>
          <input
            id="inspName"
            type="text"
            onChange={e => onInfoChange('name', e.nativeEvent.target.value)}
            className={styles.inputStyle}
            defaultValue={info.name}
          />
        </label>
        {errors &&(
          <p className={styles.error}>
            <FormattedMessage tagName="p" id="inspections.new.error" defaultMessage="Field 'Inspection Name' can't be blank" />

          </p>
        )}
        {images[`${info.icon}.svg`]
          ? (
            <div className={styles.iconSelector}>
              <FormattedMessage tagName="p" id="inspections.step1.labelIcon"
                defaultMessage="Inspection Icon"
              />
              <button onClick={() => setModal(true)} onKeyPress={() => setModal(true)} type="button">
                <img src={images[`${info.icon}.svg`]} alt="" />
              </button>
            </div>
          )
          : (
            <div className={styles.iconDisplay}>
              <FormattedMessage tagName="p" id="inspections.step1.labelIcon"
                defaultMessage="Inspection Icon"
              />
              <button onClick={() => setModal(true)} onKeyPress={() => setModal(true)} type="button">
                <img src={images[info.icon]} alt="" width="75px" height="75px" />
              </button>
            </div>
          ) }
      </div>
      <Scheduler fromInspection title="schedule.repeat.maintitle"
        selected={recurrence}
        onScheduleChange={e => onInfoChange('rule', e)}
        rules={rules.map(r => ({ key: `${r.id}`, name: r.name, frequency: r.frequency, params: r.params }))}
      />
      <div className={styles.assigneeSelector}>
        <PulpoField key="role" id="" type="select"
          title="Assigned to"
          translationID="todo.newTask.user"
          handleValueChange={onAssignTypeChanged}
          answer={assignee.assigneeType}
          values={assignees.map(r => ({ key: r.key, value: r.name }))}
          className={styles.assignee}
          handleFieldErrorChanged={() => ({})}
          showFieldErrors={showFieldErrors}
        />
        <Assignee
          typeSelected={assignee.assigneeType}
          updateUserValue={handleAssigneeChanged}
          onAssigneeSelected={handleAssigneeSelected}
          onRoleChange={handleRoleChange}
          userValue={assignee.currentUser}
          showFieldErrors={false}
          assignedRole={assignee.assigned_role}
          assignedUser={assignee.assigned_user}
          roleClassName={styles.roleLabel}
        />
        {fielderror && (
        <p className={styles.error}>
          <FormattedMessage tagName="p" id="inspections.new.fielderror" defaultMessage="This field can't be blank" />
        </p>)}
      </div>
      <FormGroup check>
        <Label check>
          <Input type="checkbox" checked={sendEmail} onChange={handleEmailNotify} />
          Send email when inspection is completed
        </Label>
      </FormGroup>
      {sendEmail
      && (
      <div className={styles.alignEmailNotify}>

        <PulpoField key="role" id="" type="select"
          title="Assigned to"
          translationID="todo.newTask.user"
          handleValueChange={onEmailAssignTypeChanged}
          answer={inspectionEmail.assigneeType}
          values={assignees.map(r => ({ key: r.key, value: r.name }))}
          className={styles.assignee}
          handleFieldErrorChanged={() => ({})}
          showFieldErrors={showFieldErrors}
        />
        { inspectionEmail.assigneeType === 'role'
          ? (
            <Assignee
              typeSelected={inspectionEmail.assigneeType}
              updateUserValue={handleEmailAssigneeChanged}
              onRoleChange={handleEmailRoleChange}
              showFieldErrors={false}
              assignedRole={inspectionEmail.assigned_role}
              roleClassName={styles.roleLabel}
            />
          )
          : (
            <Select
              isMulti
              placeholder="Select Users"
              value={inspectionEmail.assigned_user}
              onChange={handleEmailAssigneeSelected}
              className={styles.selectUsers}
              options={userList.map(e => ({
                value: e.id,
                label: e.fullname
              }))
            }
            />
          )
        }
        {emailFieldError && (
        <p className={styles.error}>
          <FormattedMessage tagName="p" id="inspections.new.fielderror" defaultMessage="Please select either users or role" />
        </p>)}
      </div>
      )}
      <div>
        <div className={styles.form_access}>
          <div className={styles.formAcess_title}>
            <FormattedMessage tagName="p" id="inspections.new.formAcess"
              defaultMessage="Form Access"
            />
          </div>
          <div styles="margin-left:22px">
            <span
              className={styles.align}
            >
              {toggle === false ? intl.formatMessage({ id: 'translation.form_access_open' }): intl.formatMessage({ id: 'translation.form_access_restricted' })}
            </span>
            <Toggle
              checked={toggle}
              icons={false}
              onChange={handleChange}
            />
          </div>
        </div>
        <hr />
        {toggle === true ? (
          <div>
              <>
                <Select
                  isMulti
                  value={form_access}
                  placeholder={intl.formatMessage({ id: 'translation.form_access_list' })}
                  onChange={handleChangeOption}
                  options={groupedOptions}
                  className={styles.select}
                />
                <table>
                  { toggle && form_access ? (
                    <>
                      <tr style={{ height: '35px' }}>
                        <th>
                          <FormattedMessage tagName="h5" className={styles.align}
                            id="inspections.new.formAcess.person"
                            defaultMessage="Person"
                          />
                        </th>
                        <th>
                          <FormattedMessage tagName="h5"
                            id="inspections.new.formAcess.permission"
                            defaultMessage="Permission Type"
                          />
                        </th>
                      </tr>
                      {form_access.map(e => (
                        <tr>
                          <>
                            <td><span className={styles.form_access_head1}>{e.label}</span></td>
                            <td className={styles.button_space}>
                              <Button onClick={() => handleOnclick('add', e.id, e.type)}
                                translationID="roles.privileges.actionAdd"
                                defaultMessage="Add"
                                action={e.permissions.find(o => o === 'add') ? 'secondary':'tertiary'}
                              />
                              <Button onClick={() => handleOnclick('view', e.id, e.type)}
                                translationID="roles.privileges.actionView" defaultMessage="View"
                                action={e.permissions.find(o => o === 'view') ? 'secondary':'tertiary'}
                              />
                              <Button onClick={() => handleOnclick('edit', e.id, e.type)}
                                translationID="roles.privileges.actionEdit" defaultMessage="Edit"
                                action={e.permissions.find(o => o === 'edit') ? 'secondary':'tertiary'}
                              />
                            </td>
                          </>
                        </tr>))}
                    </>
                  ):''}
                </table>
              </>
          </div>):''}
      </div>
      <Modal showIn={modal} onClose={() => setModal(false)}>
        <div className={styles.modalTitle}>
          <FormattedMessage tagName="h5" id="inspections.step1.modalTitle"
            defaultMessage="New Icon"
          />
        </div>
        <h2 className={styles.selectTitle}>
          <FormattedMessage id="inspections.step1.modalHeadline"
            defaultMessage="Select an icon for your Inspection"
          />
        </h2>
        <div className={styles.icons}>
          {Object.keys(images).map(i => (
            i.includes('.png') && (
            <button key={i} type="button" onClick={() => selectIcon(i)}>
              <img src={images[i]} alt="" width="70px" height="70px" />
            </button>
            )
          ))
          }
        </div>
      </Modal>
    </div>
  );
};

Step1.propTypes = {
  onInfoChange: PropTypes.func.isRequired,
  info: PropTypes.shape({}).isRequired
};

export default Step1;
