import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { ResponsiveBar, ResponsiveLine } from 'nivo';
import { ResponsivePie } from '@nivo/pie';
import SectionHeader from '../../../components/sectionHeader/SectionHeader';
import Panel from '../../../components/panel/Panel';
import icon from '../../../icons/wildlife.png';
import styles from './dashboard.module.scss';
import { fetchMapLogList, clearActionResult } from '../redux/actions';
import Map from './components/Map';
import Filter from '../List/components/Filter';
import { GetWildLifeInfo } from '../../services/GetWildLifeInfo';
import { getDatesFromRange } from '../../../utils/helpers';

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: {}
    };
    this.handlePiedata = this.handlePiedata.bind(this);
  }

  async componentDidMount() {
    const { actionFetch } = this.props;
    const info = await GetWildLifeInfo.getWildLifeInfo();
    this.setState({ speiceList: info });
    const storedRange = localStorage.getItem('log_dashboard_range');
    let s; let f;

    if (storedRange) {
      [s, f] = getDatesFromRange(storedRange);
      s = s.toISOString();
      f = f.toISOString();
    } else {
      s = localStorage.getItem('log_dashboard_start_date');
      f = localStorage.getItem('log_dashboard_end_date');
      if (s && f) {
        s = new Date(s);
        f = new Date(f);
      } else {
        s = new Date();
        s.setDate(s.getDate() - 30);
        f = new Date();
        localStorage.setItem('log_dashboard_range', 'Last 30 days');
      }
      s.setHours(0, 0, 0, 0);
      f.setHours(23, 59, 59, 99);
      s = s.toISOString();
      f = f.toISOString();
    }
    this.setState({ range: { s, f } });
    actionFetch(s, f);
  }

  componentDidUpdate(prevProps) {
    const { mapList } = this.props;
    const { speiceList } = this.state;
    if (speiceList && (JSON.stringify(prevProps.mapList) !== JSON.stringify(mapList))) {
      this.handlePiedata(mapList);
    }
  }

  handlePiedata = async (list) => {
    const { speiceList } = this.state;
    const Piedata = [];
    // chart for wildlife types
    speiceList.forEach((e) => {
      const data = list.filter(l => (e.Type === l.wildlife_type));
      if (data.length > 0) {
        Piedata.push({
          id: e.Type,
          label: e.Type,
          value: data.length
        });
      }
    });

    // graph by months
    let yearlyIncidents = [];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    months.forEach((e) => {
      const filter = list.filter(l => moment(l.incident_date).format('MMM') === e);
      yearlyIncidents.push({ x: e, y: filter.length });
    });
    const monthly = yearlyIncidents.filter(l => l.y > 0);
    if (monthly.length === 1) {
      yearlyIncidents = [];
      list.forEach((e) => {
        const filter = list.filter(l => moment(l.incident_date).format('DD-MMM-YYYY') === moment(e.incident_date).format('DD-MMM-YYYY'));
        const temp = yearlyIncidents.filter(l => l.x === moment(e.incident_date).format('DD-MMM-YYYY'));
        if (temp.length === 0) yearlyIncidents.push({ x: moment(e.incident_date).format('DD-MMM-YYYY'), y: filter.length });
      });
    }
    const yearsdata = [
      {
        id: 'incidents',
        color: 'hsl(261, 70%, 50%)',
        data: yearlyIncidents
      }
    ];

    // graph by location
    const locationData = [];
    const gridList = [];
    list.forEach((e) => {
      const filters = gridList.filter(l => l === e.grid);
      if (e.grid && filters.length === 0) {
        gridList.push(e.grid);
      }
    });
    gridList.forEach((e) => {
      const filters = list.filter(l => l.grid === e);
      locationData.push({
        Location: e,
        incidents: filters.length
      });
    });

    // graph by species
    let speciesFound = [];
    list.forEach((e) => {
      const filters = list.filter(l => l.wildlife_species === e.wildlife_species);
      const filter = speciesFound.filter(l => l.species === e.wildlife_species);
      if (filter.length === 0) {
        speciesFound.push({
          species: e.wildlife_species,
          Found: filters.length
        });
      }
    });
    const sortList = speciesFound.sort((a, b) => ((a.Found > b.Found) ? -1 : 1));
    speciesFound = sortList.filter((_, index) => (index < 10));
    speciesFound = speciesFound.reverse();

    this.setState({ Piedata, speciesFound, yearlyIncidents: yearsdata, locationData });
  }

  handleDateChange = (s, f, r) => {
    const { actionFetch } = this.props;
    localStorage.setItem('log_dashboard_start_date', s);
    localStorage.setItem('log_dashboard_end_date', f);
    if (r) {
      localStorage.setItem('log_dashboard_range', r);
    } else {
      localStorage.removeItem('log_dashboard_range');
    }
    const start = new Date(s);
    const first = new Date(f);
    start.setHours(0, 0, 0, 0);
    first.setHours(23, 59, 59, 99);
    const sFinal = start.toISOString();
    const fFinal = first.toISOString();
    this.setState({ range: { s: sFinal, f: fFinal } });
    actionFetch(sFinal, fFinal);
  };

  componentWilUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  render() {
    const { mapList, user } = this.props;
    const { Piedata, speiceList, yearlyIncidents, locationData, range, speciesFound } = this.state;
    const colors = speiceList && speiceList.map(e => (e.Color));
    return (
        <>
          <div>
            <SectionHeader icon={icon} translationID="shortcuts.wildlife.dashboard" defaultTitle="DashBoard" />
          </div>
          {range.s && (
            <Filter dates={[moment(range.s), moment(range.f)]}
              onDateChange={this.handleDateChange}
              listSummary={mapList && mapList.length}
              range={localStorage.getItem('log_dashboard_range')}
            />
          )}
          <div className={`container ${styles.container}`}>
            <div className={styles.firstRow}>
              <Panel title="wildlife.dashboard.species" defaultTitle="Species Found">
                {speiceList && (
                <ResponsivePie
                  data={Piedata || []}
                  margin={{ top: 80, right: 100, bottom: 60, left: 10 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  colors={colors}
                  radialLabelsTextColor="#8C9EA7"
                  enableRadialLabels={false}
                  theme={{
                    legends: {
                      text: {
                        fontSize: 14
                      }
                    }
                  }}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      translateY: 70,
                      translateX: -20,
                      itemWidth: 20,
                      itemHeight: 18,
                      itemTextColor: '#8C9EA7',
                      symbolSize: 10,
                      symbolShape: 'circle'
                    }
                  ]}
                />
                )}
              </Panel>
              <Panel title="wildlife.dashboard.map" defaultTitle="Map">
                {mapList && user.airport.location.coordinates && (
                <Map
                  loglist={mapList}
                  defaultLocation={user.airport.location.coordinates}
                  speiceList={speiceList}
                />
                )}
              </Panel>
            </div>
            <div className={styles.secondRow}>
              <Panel title="wildlife.dashboard.species_found" defaultTitle="Species Found most">
                {speciesFound && (
                <ResponsiveBar
                  data={speciesFound}
                  indexBy="species"
                  keys={['Found']}
                  colors={['#7ED321']}
                  enableLabel={false}
                  borderRadius="5px"
                  padding={0.6}
                  layout="horizontal"
                  margin={{ top: 50, right: 20, bottom: 80, left: 100, width: 60 }}
                  theme={{
                    axis: {
                      textColor: '#8C9EA7',
                      fontSize: '12px'
                    }
                  }}
                />
                )}
              </Panel>
              <Panel title="wildlife.dashboard.location" defaultTitle="Wildlife incidents by location">
                {locationData && (
                <ResponsiveBar
                  data={locationData}
                  indexBy="Location"
                  keys={['incidents']}
                  colors={['#FFBB12']}
                  padding={0.6}
                  enableLabel={false}
                  borderRadius="5px"
                  textColor="#8C9EA7"
                  margin={{ top: 50, right: 10, bottom: 80, left: 60, width: 60 }}
                  theme={{
                    axis: {
                      textColor: '#8C9EA7',
                      fontSize: '12px'
                    }
                  }}
                />
                )}
              </Panel>
            </div>
            <div className={styles.thirdRow}>
              <Panel title="wildlife.dashboard.number_of_incidents" defaultTitle="Total number of wildlife incidents">
                {yearlyIncidents && (
                <ResponsiveLine
                  data={yearlyIncidents}
                  colors={['#6253FF']}
                  enableGridX={false}
                  margin={{ right: 110, bottom: 50, left: 60, top: 40 }}
                  theme={{
                    axis: {
                      textColor: '#8C9EA7',
                      fontSize: '16px'
                    }
                  }}
                  axisBottom={{ tickSize: 8 }}
                />
                )}
              </Panel>
            </div>
          </div>
        </>
    );
  }
}

DashBoard.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  mapList: state.wildlife_log.mapList,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionFetch: (start, end) => {
    dispatch(fetchMapLogList(start, end));
  },
  actionClear: () => {
    dispatch(clearActionResult());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DashBoard));
