/* eslint-disable camelcase */
/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import Permissions from 'react-redux-permissions';
import htmlToImage from 'html-to-image';

/** *********************************************************
 *  Redux import
 * ***************** */
import { searchUser, fetchDataSourceList } from '../../inspections/redux/actions';

import {
  fetchWorkOrder,
  fetchWorkOrderSchema,
  sendMaintenanceReview,
  sendOperationsReview,
  clearActionResult,
  printPdf,
  clearworkorderDetail } from '../redux/actions';

import {
  WORKORDER_STATUS_MAINTENANCE,
  WORKORDER_STATUS_OPERATION,
  WORKORDER_STATUS_COMPLETED
} from '../../../constants/ModelStatus';
import { fetchShiftTypes } from '../../settings/redux/actions';


import { WORKORDERS_HOME_ROUTE } from '../../../constants/RouterConstants';

/** *********************************************************
 *  Components import
 * ***************** */

import SectionHeader from '../../../components/sectionHeader';
import Separator from '../../../components/separator';
import HeaderBack from '../../../components/headerBack';
import Panel from '../../../components/panel';
import Loading from '../../../components/loading';
import WorkorderInfoBox from './components/WorkorderInfoBox';
import StepForm from './components/StepForm';
import StepInfoBox from './components/StepInfoBox';
import Button from '../../../components/button';
import '../../../components/topbar/profile/View/toggle.scss';


/** *********************************************************
 *  Assets import
 * ***************** */

import styles from './workOrderDetail.module.scss';
import workOrder from '../../../icons/WorkOrders.png';
import { GetFileName, S3Uploads } from '../../services';
import { getUserHasPermission, getShiftTypeByDate } from '../../../utils/helpers';

class WorkOrderDetail extends Component {
  state = {
    maintenance: {
      attachments: [],
      completed_on: moment().format(),
      work_description: '',
      completed_by: {}
    },
    operations: {
      attachments: [],
      completed_on: moment().format(),
      work_description: '',
      completed_by: {}
    },
    showFormErrors: false,
    // invalidDate: false,
    requiredMap: {},
    fieldErrors: {
      completed_by: false,
      completed_on: false,
      work_description: true
    },
    workorderDropdown: false,
    work_description: true,
    loadingStatus: false
  }

  firstTime = true;

  async componentDidMount() {
    const {
      actionDetail,
      actionGetSchemas,
      schemas,
      fetchDataSources, shiftTypes, actionFetchShiftTypes,
      match: { params: { id } }
    } = this.props;
    if (!shiftTypes.length) actionFetchShiftTypes();

    actionDetail(id);
    fetchDataSources();
    if (!Object.keys(schemas).length) await actionGetSchemas();
  }

  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.user.id && !state.maintenance.completed_by.id) {
      return { ...state,
        maintenance: {
          ...state.maintenance,
          completed_by: {
            id: props.user.id,
            fullname: props.user.fullname
          }
        },
        operations: {
          ...state.operations,
          completed_by: {
            id: props.user.id,
            fullname: props.user.fullname
          }
        }
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { schemas, workorder, printPdfAction, maintenanceAction, operationsAction } = this.props;
    const { loadingStatus } = this.state;

    if (printPdfAction.success !== prevProps.printPdfAction.success
      || maintenanceAction.success !== prevProps.maintenanceAction.success
      || operationsAction.success !== prevProps.operationsAction.success) {
      this.setState({ loadingStatus: !loadingStatus });
    }
    if (maintenanceAction.loading!==prevProps.maintenanceAction.loading) {
      this.setState({ loadingStatus: true });
    }
    if (operationsAction.message!==prevProps.operationsAction.message) {
      this.setState({ loadingStatus: true });
    }

    if (!this.firstTime || !Object.keys(workorder).length
      || schemas.workorder === undefined) return;
    const sch = (workorder.status === WORKORDER_STATUS_MAINTENANCE)
      ? schemas.maintenance
      : schemas.operations;

    this.processInspectionForState(sch);
    this.firstTime = false;
  }

  componentWillUnmount() {
    /* To clear the Workorder Detail,
     Maintainence Action,
     Operation Action
     */
    const { clear } = this.props;
    clear();
  }

  processInspectionForState = (sectionschema) => {
    if (sectionschema) {
      const { schema } = sectionschema;
      let { fieldErrors, requiredMap } = this.state;
      schema.fields.forEach((f) => {
        requiredMap = { ...requiredMap, [f.id]: f.required };
        fieldErrors = { ...fieldErrors, [f.id]: f.required };
      });
      this.setState({ requiredMap, fieldErrors });
    }
  }

  /* ********************************************* */
  /* ***** MAINTENANCE & OPERATIONS METHODS ****** */
  /* ********************************************* */
  handleSearchForUser = (value) => {
    const { actionSearchUser } = this.props;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  showSendButton= (status) => {
    const { schemas, user } = this.props;
    // check if user is assigned to maintenance
    const canViewMaintenance = schemas.maintenance !== undefined && getUserHasPermission('add_maintenance', user.roles);

    // check if user is assigned to operations
    const canViewOperations = schemas.operations !== undefined && getUserHasPermission('add_operations', user.roles);

    if (status===2&& canViewOperations) {
      return true;
    }
    if (status===1 && canViewMaintenance) {
      return true;
    }
    return false;
  }

  handleAnswerChanged = (type, answer, fieldId) => {
    // const { invalidDate } = this.state;
    // if (fieldId === 'completed_on' && invalidDate) {
    //   this.setState({ invalidDate: false });
    // }
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  handleDropDown = () => {
    this.setState(prevState => ({
      workorderDropdown: !prevState.workorderDropdown
    }));
  }

  handleClick = () => {
    const { history } = this.props;
    history.push(`${WORKORDERS_HOME_ROUTE}`);
  }

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  goToEditWorkOrder = (id) => {
    const { history } = this.props;
    history.push(`${WORKORDERS_HOME_ROUTE}${id}/edit`);
  }

  async sendResponse(status) {
    const {
      actionSendMaintenance,
      actionSendOperations, shiftTypes,
      workorder } = this.props;
    const {
      maintenance,
      operations,
      fieldErrors } = this.state;
    const data = (status === WORKORDER_STATUS_MAINTENANCE)
      ? maintenance
      : operations;
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));
    // let date1 = false;
    // if (status === WORKORDER_STATUS_MAINTENANCE) {
    //   if (workorder.report_date > maintenance.completed_on && noErrors === false) {
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    //   if (workorder.report_date > maintenance.completed_on && noErrors === true) {
    //     noErrors = false;
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    // }
    // if (status === WORKORDER_STATUS_OPERATION) {
    //   if (workorder.report_date > operations.completed_on && noErrors === false) {
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    //   if (workorder.report_date > operations.completed_on && noErrors === true) {
    //     noErrors = false;
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    //   if (workorder.maintenance_answer.completed_on > operations.completed_on && noErrors === false) {
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    //   if (workorder.maintenance_answer.completed_on > operations.completed_on && noErrors === true) {
    //     noErrors = false;
    //     date1=true;
    //     this.setState({ invalidDate: true });
    //   }
    // }
    if (noErrors) {
      const aux = { ...data };
      this.setState({
        loadingStatus: true
      });
      const { completed_by, attachments, completed_on, work_description, ...response } = aux;
      const toSend = {};
      toSend.completed_by = completed_by.id;
      toSend.completed_on = completed_on;
      const shift =getShiftTypeByDate(completed_on, shiftTypes);
      toSend.shift_name=shift.name||' ';
      toSend.attachments = attachments;
      let entityName = '';
      if (status === WORKORDER_STATUS_OPERATION) {
        toSend.review_report = work_description;
        entityName = 'operations';
      } else {
        toSend.work_description = work_description;
        entityName = 'maintenance';
      }
      toSend.response = JSON.stringify(response);

      // transform data into formData
      const formData = new FormData();
      let fileIds = '';
      if (data.attachments.length > 0) {
        fileIds = await this.getFileNames(data.attachments.length);
      }
      Object.keys(toSend).forEach((k) => {
        if (k !== 'attachments') {
          formData.append(k, toSend[k]);
        }
      });
      formData.append('email_date', moment().format());
      if (fileIds) {
        await this.doS3Upload(data.attachments, fileIds, entityName)
          .then(() => {
            fileIds.filename.forEach((fileId) => {
              formData.append('attachments', fileId.toString());
            });
            htmlToImage.toPng(document.getElementsByClassName('leaflet-pane leaflet-map-pane')[0])
              .then((dataUrl) => {
                formData.append('work_order_snapshot', dataUrl);
                if (status === WORKORDER_STATUS_MAINTENANCE) {
                  formData.append('report_date', moment(workorder.report_date).format());
                  actionSendMaintenance(workorder.id, formData);
                } else {
                  formData.append('report_date', moment(workorder.report_date).format());
                  formData.append('maintenance_completed_on', moment(maintenance.completed_on).format());
                  actionSendOperations(workorder.id, formData);
                }
              });
          });
      } else {
        htmlToImage.toPng(document.getElementsByClassName('leaflet-pane leaflet-map-pane')[0])
          .then((dataUrl) => {
            formData.append('work_order_snapshot', dataUrl);
            if (status === WORKORDER_STATUS_MAINTENANCE) {
              formData.append('report_date', moment(workorder.report_date).format());
              actionSendMaintenance(workorder.id, formData);
            } else {
              formData.append('report_date', moment(workorder.report_date).format());
              formData.append('maintenance_completed_on', moment(maintenance.completed_on).format());
              actionSendOperations(workorder.id, formData);
            }
          });
      }
    } else {
      this.setState({ showFormErrors: true });
    }
  }


  printPdf(id) {
    const { actionPrintPdf } = this.props;
    const { workorder } =this.props;
    const { maintenance, operations }= this.state;

    htmlToImage.toPng(document.getElementsByClassName('leaflet-pane leaflet-map-pane')[0])
      .then((dataUrl) => {
        const param = {
          snapshot: dataUrl,
          report_Date: (moment(workorder.report_date)).format('MM/DD/YYYY hh:mm A'),
          completed_on: (moment(maintenance.completed_on)).format('MM/DD/YYYY hh:mm A'),
          completed_by: (moment(operations.completed_on)).format('MM/DD/YYYY hh:mm A')
        };
        actionPrintPdf(id, param);
      });
  }

  render() {
    // const status = {
    //   1: 'workorders.detail.workCompletedBtn',
    //   2: 'workorders.detail.inspectionCompletedBtn'
    // };
    const { match: { params: { id } } } = this.props;
    const {
      workorder,
      userlist,
      maintenanceAction,
      operationsAction,
      schemas,
      user,
      translations,
      notams,
      allDataSources
    } = this.props;

    const {
      maintenance,
      showFormErrors,
      // invalidDate,
      operations,
      requiredMap,
      loadingStatus,
      workorderDropdown } = this.state;

    if (!workorder.id) return <Loading loadingStatus />;
    // check if user is assigned to maintenance
    const canViewMaintenance = schemas.maintenance !== undefined && getUserHasPermission('add_maintenance', user.roles);

    // check if user is assigned to operations
    const canViewOperations = schemas.operations !== undefined && getUserHasPermission('add_operations', user.roles);


    const translationMap = translations ? translations[user.language] : {};
    return (
      <div>
        {(maintenanceAction.success || operationsAction.success) && (
          <Redirect push to={WORKORDERS_HOME_ROUTE} />
        )}
        <SectionHeader icon={workOrder} translationID="workorders.title" defaultTitle="Work Orders">
          <div className={styles.detailHeader}>
            <span className={styles.actionsBtn} onClick={() => this.handleDropDown()}>
              <FormattedMessage id="workorders.detail.actionBtn"
                defaultMessage="Actions"
              />
            </span>
            {workorderDropdown && (
              <ul className={`${styles.dropdown} ${workorderDropdown ? styles.open : ''}`}>
                <li className={styles.item}>
                  <span onClick={() => this.printPdf(workorder.id)} translationID="workorders.detail.pdfBnt"
                    defaultText="Print Pdf" action="secondary"
                  >
                    <FormattedMessage id="workorders.detail.print"
                      defaultMessage="Print"
                    />
                  </span>
                </li>
                  { !workorder.maintenance_answer
                    && (
                      <li className={styles.item}>
                        <span onClick={() => this.goToEditWorkOrder(id)}
                          defaultText="Edit" action="secondary" translationID="workorders.detail.editBtn"
                        >
                          <FormattedMessage id="inspections.step0.edit"
                            defaultMessage="Edit"
                          />
                        </span>
                      </li>
                    )}
              </ul>
            )}
            <Separator />
          </div>
        </SectionHeader>
        <HeaderBack
          translationID="workorders.start.back"
          translationDefault="Back to Work Orders"
          backRoute={WORKORDERS_HOME_ROUTE}
        />
        <Panel
          containerClasses={styles.mainPanel}
          title="workorders.detail.header"
          defaultTitle={'Work Order {id}'}
          translationValues={{ id: workorder.order_number }}
        >

          <div className={styles.panelContent}>
            <WorkorderInfoBox
              workorder={workorder}
              defaultLocation={user.airport.location.coordinates}
              translation={translationMap}
            />
            {workorder.status === WORKORDER_STATUS_MAINTENANCE
              && (
              <Permissions allowed={['add_maintenance']}>
                {canViewMaintenance && allDataSources && (
                <StepForm info={maintenance}
                  schema={schemas.maintenance}
                  step="maintenance"
                  translation={translationMap}
                  userlist={userlist}
                  searchForUser={this.handleSearchForUser}
                  handleAnswerChanged={this.handleAnswerChanged}
                  handleFieldErrorChanged={this.handleFieldErrorChanged}
                  showFormErrors={showFormErrors}
                  // invalidDate={invalidDate}
                  requiredMap={requiredMap}
                  notams={notams}
                  allDataSources={allDataSources}
                />
                )}
              </Permissions>
              )
            }
            {workorder.status === WORKORDER_STATUS_OPERATION && (
              <>
                <StepInfoBox step="maintenance" info={workorder.maintenance_answer}
                  schema={schemas.maintenance} translation={translationMap}
                />
                <Permissions allowed={['add_operations']}>
                  {canViewOperations && allDataSources && (
                  <StepForm info={operations}
                    schema={schemas.operations}
                    step="operations"
                    translation={translationMap}
                    userlist={userlist}
                    searchForUser={this.handleSearchForUser}
                    handleAnswerChanged={this.handleAnswerChanged}
                    handleFieldErrorChanged={this.handleFieldErrorChanged}
                    showFormErrors={showFormErrors}
                    // invalidDate={invalidDate}
                    requiredMap={requiredMap}
                    allDataSources={allDataSources}
                  />
                  )}
                </Permissions>
              </>
            )}
            {workorder.status === WORKORDER_STATUS_COMPLETED && (
              <>
                {workorder.maintenance_answer && <StepInfoBox step="maintenance" info={workorder.maintenance_answer} />}
                {schemas.operations&& (!schemas.operations.disabled || workorder.operations_answer) &&workorder.operations_answer&& <StepInfoBox step="operations" info={workorder.operations_answer} />}
              </>
            )}
          </div>
          <div className={styles.woCancelBtn}>
            <Button onClick={() => this.handleClick()} translationID="inspections.new.cancel"
              defaultText="Cancel" action="secondary"
            />
          </div>
          {/* { canViewMaintenance || canViewMaintenance ? ( */}
          { this.showSendButton(workorder.status) && workorder.status ===1 ? (
            <>
              <div className={styles.woDetailBtn}>
                <Button
                  onClick={() => this.sendResponse(workorder.status)}
                  translationID="workorder.detail.maintainence.completedBtn"
                  defaultText="Resolve"
                  action="secondary"
                />
              </div>
            </>
          ) : ''}
          { this.showSendButton(workorder.status) && workorder.status ===2 ? (
            <>
              <div className={styles.woDetailBtn}>
                <Button
                  onClick={() => this.sendResponse(workorder.status)}
                  translationID="workorder.detail.operations.completedBtn"
                  defaultText="Close Work Orders"
                  action="secondary"
                />
              </div>
            </>
          ) : ''}
        </Panel>
        <Loading loadingStatus={loadingStatus} />
      </div>
    );
  }
}

WorkOrderDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  workorder: PropTypes.shape({}).isRequired,
  action: PropTypes.shape({}).isRequired,
  actionDetail: PropTypes.func.isRequired,
  // maintenance & operations methods
  maintenanceAction: PropTypes.shape({}).isRequired,
  operationsAction: PropTypes.shape({}).isRequired,
  actionGetSchemas: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  actionSendOperations: PropTypes.func.isRequired,
  actionSendMaintenance: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  schemas: PropTypes.shape({}).isRequired,
  clear: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  actionPrintPdf: PropTypes.func.isRequired

};

WorkOrderDetail.defaultProps = {
  userlist: [],
  permissions: []
};

const mapStateToProps = state => ({
  workorder: state.workorders.detail,
  action: state.workorders.action,
  maintenanceAction: state.workorders.maintenanceAction,
  operationsAction: state.workorders.operationsAction,
  userlist: state.inspection.userlist,
  user: state.auth.profile,
  shiftTypes: state.settings.shiftTypes,
  schemas: state.workorders.schemas,
  permissions: state.permissions,
  translations: state.auth.translations,
  printPdfAction: state.workorders.printPdfAction,
  allDataSources: state.inspection.allDataSources
});

const mapDispatchToProps = dispatch => ({
  // Fetch work order detail
  actionDetail: (id) => {
    dispatch(fetchWorkOrder(id));
  },
  // maintenance
  actionGetSchemas: () => {
    dispatch(fetchWorkOrderSchema());
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionSendMaintenance: (id, data) => {
    dispatch(sendMaintenanceReview(id, data));
  },
  actionSendOperations: (id, data) => {
    dispatch(sendOperationsReview(id, data));
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },

  actionClearWorkOrderDetail: () => {
    dispatch(clearworkorderDetail());
  },
  clear: () => {
    dispatch(clearActionResult());
  },
  // To print pdf version of workorder
  actionPrintPdf: (id, data) => {
    dispatch(printPdf(id, data));
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderDetail);
