import React from 'react';

type NewMessageCounterProps = {
  messageCount?: number | null;
  isRead?: boolean;
};

const NewMessageCounter: React.FC<NewMessageCounterProps> = ({
  messageCount,
  isRead
}) => {
  if (messageCount <= 0 || isRead) {
    return null;
  }
  return <span className="counter">{messageCount}</span>;
};

export { NewMessageCounter };
