/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/require-default-props */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './paginator.module.scss';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeValues = [];

  while (i <= to) {
    rangeValues.push(i);
    i += step;
  }

  return rangeValues;
};

class Paginator extends Component {
  constructor(props) {
    super(props);
    const {
      totalRecords = null,
      pageLimit = 30,
      pageNeighbours = 0,
      page = 1
    } = props;
    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    const RecordsLimit = typeof totalRecords === 'number' ? totalRecords : 0;
    const pageNumber = typeof page === 'number' ? page : 1;
    const totalPages = Math.ceil(RecordsLimit / this.pageLimit);

    this.pageNeighbours = typeof (props.pageNeighbours) === 'number'
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0;

    this.state = {
      currentPage: pageNumber,
      totalRecords: RecordsLimit,
      totalPages
    };
  }

  componentDidMount() {
    let page = localStorage.getItem('wpage') || 1;
    if (localStorage.getItem('ipage')) {
      page = localStorage.getItem('ipage');
    }
    this.gotoPage(page);
  }

  componentDidUpdate(prevProps) {
    const { totalRecords, page } = this.props;
    if (prevProps.totalRecords !== totalRecords) {
      const totalPages = Math.ceil(totalRecords / this.pageLimit);
      this.setState({ totalRecords, totalPages });
    }
    if (prevProps.page !== page) {
      this.setState({ currentPage: page });
    }
  }

  gotoPage = (page) => {
    const { onPageChanged = f => f } = this.props;
    const { totalPages, totalRecords } = this.state;
    const currentPage = Math.max(0, Math.min(page, totalPages));
    const paginationData = {
      currentPage,
      totalPages,
      pageLimit: this.pageLimit,
      totalRecords
    };
    this.setState({ currentPage: paginationData.currentPage },
      () => onPageChanged(paginationData.currentPage));
  }

  handleClick = page => (evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  }

  handleMoveLeft = (evt) => {
    const { currentPage } = this.state;
    evt.preventDefault();
    this.gotoPage(currentPage - 1);
  }

  handleMoveRight = (evt) => {
    const { currentPage } = this.state;
    evt.preventDefault();
    this.gotoPage(currentPage + 1);
  }

  fetchPageNumbers = () => {
    const { totalPages, currentPage } = this.state;
    const { pageNeighbours } = this.props;
    const totalNumbers = (this.pageNeighbours * 2) + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  }

  render() {
    const { totalRecords, totalPages } = this.state;
    if (!totalRecords || totalPages === 1) {
      return null;
    }
    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();
    return (
      <Fragment>
        <nav className={styles.paginator}>
          <ul className="pagination">
            { pages.map((page, index) => {
              if (page === LEFT_PAGE) {
                return (
                  <li key={index} className="page-item">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                );
              }
              if (page === RIGHT_PAGE) {
                return (
                  <li key={index} className="page-item">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                );
              }
              return (
                <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="page-link" href="#" onClick={this.handleClick(page)}>{ page }</a>
                </li>
              );
            })}
          </ul>
        </nav>
      </Fragment>
    );
  }
}

Paginator.defaultProps = {
  pageNeighbours: 1
}

Paginator.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default Paginator;
