import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import Separator from '../../../../../../components/separator';

import styles from './workOrderListBox.module.scss';


const WorkOrderListBox = ({ workorder }) => {
  const priorities = {
    0: 'workorders.list.table.priorities.low',
    1: 'workorders.list.table.priorities.medium',
    2: 'workorders.list.table.priorities.high'
  };
  const workorderDes = `${workorder.problem_description.substring(0, 25)}...`;
  const [show, setShow]= useState(false);
  const status = {
    0: 'workorders.list.table.status.new',
    1: 'workorders.list.table.status.maintenance',
    2: 'workorders.list.table.status.operations',
    3: 'workorders.list.table.status.completed'
  };
  const handeldescription= () => {
    setShow(!show);
  };
  return (
    <div className={styles.workOrderInfoContainer}>
      <div className={styles.workorderId}>
        <a target="_blank" rel="noopener noreferrer"
          href={`/ops/workorders/${workorder.order_number}/detail`}
        >
          <FormattedMessage id="workorder.listbox.view"
            defaultMessage="View"
          />
        </a>
      </div>
      <Separator />
      { workorder.status === 1
        ? (
          <>
            <div className={styles.workorderId}>
              <a target="_blank" rel="noopener noreferrer"
                href={`/ops/workorders/${workorder.order_number}/edit`}
              >
                <FormattedMessage id="workorder.listbox.edit"
                  defaultMessage="Edit"
                />
              </a>
            </div>
            <Separator />
          </>
        )
        : ''
      }
      <div className={styles.workorderStatus}>
        <FormattedMessage id={status[workorder.status === undefined ? 1 : workorder.status]} defaultMessage="Unknown Status" />
      </div>
      <Separator />
      <div className={styles.workorderDescription}
        role="menuitem"
        onClick={() => { handeldescription(); }}
        onKeyDown={handeldescription}
        tabIndex="0"
      >
        {show? workorder.problem_description : workorderDes}
      </div>
      <div className={styles.workorderStatus}>
        <FormattedMessage id={priorities[workorder.priority]} defaultMessage="Unknown Priority" />
      </div>
    </div>
  );
};

WorkOrderListBox.propTypes = {
  workorder: PropTypes.shape({}).isRequired
};
export default WorkOrderListBox;
