import { IAction, PostsActionType, PostsState } from './types';
import { defaultMessage } from '../../common/assets';

export const reducer = (state: PostsState, action: IAction): PostsState => {
  switch (action.type) {
    case PostsActionType.setNewMessage: {
      const {
        newPosts,
        channelData: { channelId },
        postList: { items, nextToken }
      } = state;
      const {
        payload: { data }
      } = action;
      const { channelId: chId } = data;

      const newPostsCount = newPosts?.[chId]?.length > 0;

      if (channelId === chId) {
        const insertItem = newPostsCount
          ? [data]
          : [{ ...defaultMessage, createdAt: data.createdAt }, data];

        return {
          ...state,
          newPosts: {
            ...newPosts,
            [chId]: [...((newPosts?.[chId]) || []), data]
          },
          postList: {
            items: [...items, ...insertItem],
            nextToken
          }

        };
      }

      return {
        ...state,
        newPosts: {
          ...newPosts,
          [chId]: [...((newPosts?.[chId]) || []), data]
        }
      };
    }
    case PostsActionType.setChannel: {
      const { payload } = action;

      return {
        ...state,
        channelData: { ...state.channelData, ...payload },
        loading: true
      };
    }
    case PostsActionType.setDeletedPostID: {
      const { payload: { deletedPostID } } = action;

      return { ...state, deletedPostID };
    }
    case PostsActionType.setPostList: {
      const { newPosts, channelData } = state;
      const {
        payload: {
          postList: { nextToken, items },
          channelId
        }
      } = action;

      const isNewMessageExists = !!(newPosts?.[channelId]?.length);

      if (isNewMessageExists) {
        return {
          ...state,
          postList: {
            items: [...items, ...[defaultMessage, ...newPosts[channelId]]],
            nextToken
          },
          channelData: { ...channelData },
          loading: false
        };
      }

      return {
        ...state,
        postList: { nextToken, items },
        channelData: { ...channelData },
        loading: false
      };
    }
    case PostsActionType.setReadMessages: {
      const { newPosts } = state;
      const {
        payload: { prevChannelId }
      } = action;

      return {
        ...state,
        newPosts: {
          ...newPosts,
          [prevChannelId]: []
        }
      };
    }
    case PostsActionType.setTopicsCollection: {
      const {
        payload: { topicsCollectionCount }
      } = action;
      return {
        ...state,
        topicsCollectionCount
      };
    }
    case PostsActionType.updateTopicsCollection: {
      const { topicsCollectionCount } = state;

      const waitForConnection = topicsCollectionCount - 1;
      return {
        ...state,
        topicsCollectionCount: topicsCollectionCount - 1,
        topicsConnected: waitForConnection === 0
      };
    }
    case PostsActionType.deletePost: {
      const {
        postList: { nextToken, items },
        newPosts,
        channelData: { channelId }
      } = state;
      const {
        payload: { postId }
      } = action;

      return {
        ...state,
        postList: {
          items: items
            .filter(message => message.postId !== postId)
            .filter(
              message => !(
                !newPosts?.[channelId]?.filter(msg => msg.postId !== postId)
                    .length && message.channelId === 'channelId'
              )
            ),
          nextToken
        },
        newPosts: {
          ...newPosts,
          [channelId]: [
            ...(newPosts?.[channelId]?.filter(
              message => message.postId !== postId
            ) ?? [])
          ]
        },
        editedPost: null
      };
    }
    case PostsActionType.toggleLikes: {
      const {
        postList: { items }
      } = state;
      const {
        payload: { updatePost, nextToken }
      } = action;

      return {
        ...state,
        postList: {
          items: items.map((message) => {
            const { postId } = message;
            return postId === updatePost.postId ? { ...updatePost } : message;
          }),
          nextToken
        }
      };
    }
    case PostsActionType.updatePost: {
      const {
        postList: { items, nextToken }
      } = state;
      const {
        payload: { updatePost }
      } = action;

      return {
        ...state,
        postList: {
          items: items.map((message) => {
            const { postId } = message;
            return postId === updatePost.postId ? { ...updatePost } : message;
          }),
          nextToken
        },
        editedPost: null
      };
    }
    case PostsActionType.setEditedMessage: {
      const {
        postList: { items }
      } = state;
      const {
        payload: { editedPostID }
      } = action;

      return {
        ...state,
        editedPost: items.filter(({ postId }) => editedPostID === postId)[0]
      };
    }
    case PostsActionType.updateReplies: {
      const {
        postList: { items, nextToken }
      } = state;
      const {
        payload: { postId, reduce }
      } = action;

      return {
        ...state,
        postList: {
          items: items.map(message => (message.postId === postId
            ? { ...message, comments: (reduce ? message.comments - 1 : message.comments + 1) }
            : message)),
          nextToken
        }
      };
    }
    default: {
      return state;
    }
  }
};
