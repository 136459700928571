import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';

import Forbidden from '../../Forbidden';
import Maintenance from './List/MaintenanceList';
import MaintenanceCreate from './create/MaintenanceCreate';
import MaintenanceBuilder from './Builder/MaintenanceBuilder';
import MaintenanceDetail from './Detail/MaintenanceDetail';

const MaintenanceLayout = ({ match }) => (
  <Permissions allowed={['view_workorder']} fallbackElement={<Forbidden />}>
    <Switch>
      <Route exact path={`${match.url}`} component={Maintenance} />
      <Route exact path={`${match.url}/start`} component={MaintenanceCreate} />
      <Route exact path={`${match.url}/settings`} component={MaintenanceBuilder} />
      <Route exact path={`${match.url}/:id/detail`} component={MaintenanceDetail} />
    </Switch>
  </Permissions>
);

MaintenanceLayout.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default MaintenanceLayout;
