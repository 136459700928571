import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';
import Separator from '../../../../../components/separator';
import Clickable from '../../../../../components/clickable/Clickable';
import Modal from '../../../../../components/modal';
import PhotoCarousel from '../../../../../components/photoCarousel';
import styles from '../TenantsDetail.module.scss';
import Collapsible from '../../../../../components/collapsible/Collapsible';


const DocumentDetail = ({ info, handleModal }) => {
  const documentDes = `${info && info.description && info.description.substring(0, 25)}...`;
  const [show, setShow]= useState(false);
  const [modal, setModal] = useState(false);
  const [initialSlide, setInitialSlide]= useState();

  const handledescription= () => {
    setShow(!show);
  };

  const openPhotoModal = (idx) => {
    setModal(true);
    setInitialSlide(idx);
  };

  return (
    <div className={styles.requestInfo}>
      <div className={styles.infoTable}>
        <div className={styles.infoRow}>
          <div className={styles.column}>
            {info.attachments && info.attachments.length > 1 ? (
              <div>
                <Clickable
                  key={0}
                  onClick={() => openPhotoModal(0)}
                  className={styles.documentLink}
                >
                  Documents
                </Clickable>
              </div>
            )
              :(
                <div>
                  {info.attachments.map((e, idx) => (
                    <Clickable
                      key={e.id}
                      onClick={() => openPhotoModal(idx)}
                      className={styles.documentLink}
                    >
                      {e.file_name}
                    </Clickable>
                  ))}
                </div>
              )}
            {info.attachments && info.attachments.length === 0 && (
            <p>
              {' '}
              {'-'}
            </p>
            )}
          </div>
          <Separator />
          <div className={styles.column}>
            <h6>
              <FormattedMessage id="lease.tenants.steps.step3.document_type" defaultMessage="Document Type" />
            </h6>
            <p>
              {info.document_type || '-'}
            </p>
          </div>
          <Separator />
          <div className={styles.column}>
            <h6>
              <FormattedMessage id="operations.create.description" defaultMessage="Description" />
            </h6>
            <div className={styles.Description}
              role="menuitem"
              onClick={() => { handledescription(); }}
              onKeyDown={handledescription}
              tabIndex="0"
            >
              {show? info.description : documentDes}
            </div>
          </div>
          <Separator />
          <div className={styles.column}>
            <h6>
              <FormattedMessage id="lease.tenants.steps.step3.valid_date" defaultMessage="Valid Until" />
            </h6>
            <p>
              {info.valid_date ? moment(info.valid_date).format('DD/MM/YYYY') : '-'}
            </p>
          </div>
          <div className={styles.column}>
            <Collapsible
              title="workorders.detail.actionBtn"
              styleClasses={styles.actionsBtn} dateFilter
            >
              <ul className={`${styles.dropdown} ${styles.open}`}>
                <li className={styles.item}>
                  <Clickable className={styles.action} onClick={() => handleModal('modify', info)}>
                    <FormattedMessage id="tenant.detail.modify" defaultMessage="Modify" />
                  </Clickable>
                </li>
                <li className={styles.item}>
                  <Clickable className={styles.action} onClick={() => handleModal('delete', info)}>
                    <FormattedMessage id="tenant.detail.delete" defaultMessage="Delete" />
                  </Clickable>
                </li>
                <li className={styles.item}>
                  <Clickable className={styles.action} onClick={() => handleModal('duplicate', info)}>
                    <FormattedMessage id="tenant.detail.duplicate" defaultMessage="Duplicate" />
                  </Clickable>
                </li>
              </ul>
            </Collapsible>
          </div>
        </div>
      </div>
      {info.attachments.length >0 && (
      <Modal
        showIn={modal}
        onClose={() => setModal(false)}
        contentStyles={{
          overflow: 'visible',
          background: '#E6EAEE',
          padding: '20px 40px'
        }}
      >
        <div>
          {info.attachments.length > 1
            ? <PhotoCarousel photos={info.attachments} initialSlide={initialSlide} />
            :(
              <div>
                <img src={info.attachments[0].url} className={styles.workimg} alt="" />
              </div>
            )
              }
        </div>
      </Modal>
      )}
    </div>
  );
};

DocumentDetail.propTypes = {
  tenant: PropTypes.shape({}).isRequired
};

export default DocumentDetail;
