/* global navigator */
import axios from 'axios';
import Auth from '../utils/Auth';
import { getBackendBaseUrl } from '../constants';

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
    'Accept-Language': navigator.language.split(/[-_]/)[0]
  },
  baseURL: getBackendBaseUrl(),
  withCredentials: true
});

export const setHeaders = (token) => {
  Object.assign(axiosInstance.defaults, { headers: { authorization: `Bearer ${token}` } });
};

export default axiosInstance;
