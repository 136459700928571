import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment/min/moment-with-locales';
import { FormattedMessage } from 'react-intl';

import Collapsible from '../../../../../components/collapsible/Collapsible';
import Clickable from '../../../../../components/clickable/Clickable';
import PhotoCarousel from '../../../../../components/photoCarousel';
import Modal from '../../../../../components/modal';
import { renderInfoForType } from '../../../../../utils/helpers';
import styles from '../../../../workorders/Detail/workOrderDetail.module.scss';

class WorkorderInfo extends Component {
  state = {
    modal: false
  };

  openPhotoModal = (idx) => {
    this.setState({ modal: true, initialSlide: idx });
  }

  render() {
    const { workorder, translation } = this.props;
    const { modal, initialSlide } = this.state;
    return (
      <Collapsible
        title="workorder.detail.request_header"
        styleClasses={styles.collapsibleHeader}
        offset={400}
        openOnMount
      >
        <div className={styles.sectionHeader}>
          <FormattedMessage id="workorder.detail.request.request_title" defaultMessage="Work details" />
        </div>
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="workorder.detail.request.logged_by" defaultMessage="Logged by" />
              </span>
              <span className={styles.rowContent}>
                {workorder.logged_by.fullname}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="workorder.detail.request.report_date" defaultMessage="Report date" />
              </span>
              <span className={styles.rowContent}>
                {moment(workorder.report_date).format('MM/DD/YYYY hh:mm A')}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="workorder.detail.request.assigned_to" defaultMessage="Assigned to" />
              </span>
              <span className={styles.rowContent}>
                {workorder.assigned_role && workorder.assigned_role.name}
                {workorder.assigned_users && workorder.assigned_users.length > 0 && workorder.assigned_users[0].fullname}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="workorder.detail.request.priority" defaultMessage="Priority" />
              </span>
              <span className={styles.rowContent}>
                {workorder.priority}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="workorder.detail.request.category" defaultMessage="Category" />
              </span>
              <span className={styles.rowContent}>
                {workorder.category}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="tenants.new.name" defaultMessage="Company Name" />
              </span>
              <span className={styles.rowContent}>
                {workorder.tenant_id}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.requestInfoField}>
          <span className={styles.title}>
            <FormattedMessage id="workorder.detail.request.problem_description" defaultMessage="Problem description" />
          </span>
          <div className={styles.content}>
            {workorder.problem_description}
          </div>
        </div>
        {Object.keys(workorder.attachments).length > 0 && (
          <div className={styles.requestInfoField}>
            <span className={styles.title}>
              <FormattedMessage id="workorder.detail.request.photos" defaultMessage="Photos" />
            </span>
            { Object.keys(workorder.attachments).length > 1 ? (
              <div className={styles.photos}>
                {workorder.attachments.map((e, idx) => (
                  <Clickable
                    key={e.id}
                    onClick={() => this.openPhotoModal(idx)}
                  >
                    <img src={e.url} alt={e.id} />
                  </Clickable>
                ))}
              </div>
            )
              :(
                <div className={styles.photo}>
                  {workorder.attachments.map((e, idx) => (
                    <Clickable
                      key={e.id}
                      onClick={() => this.openPhotoModal(idx)}
                    >
                      <img src={e.url} alt={e.id} />
                    </Clickable>
                  ))}
                </div>
              )}
          </div>
        )}

        <div className={`${styles.stepInfo} ${styles.noMarginTop}`}>
          {workorder.answer_schema.fields.map(f => (
            <div key={f.id} className={f.widget && f.widget.type === 'textfield' ? styles.infoFull : styles.infoHalf}>
              <span>{translation && translation[f.title] ? translation[f.title] : f.title}</span>
              <p>{renderInfoForType(f, workorder.response[f.id], workorder)}</p>
            </div>
          ))}
        </div>

        {workorder.attachments.length >0 && (
          <Modal
            showIn={modal}
            onClose={() => this.setState({ modal: false })}
            contentStyles={{
              overflow: 'visible',
              background: '#E6EAEE',
              padding: '20px 40px'
            }}
          >
            <div>
              {workorder.attachments.length > 1
                ? <PhotoCarousel photos={workorder.attachments} initialSlide={initialSlide} />
                :(
                  <div>
                    <img src={workorder.attachments[0].url} className={styles.workimg} alt="" />
                  </div>
                )
              }
            </div>
          </Modal>
        )}
      </Collapsible>
    );
  }
}

WorkorderInfo.propTypes = {
  workorder: PropTypes.shape({}).isRequired
};

export default WorkorderInfo;
