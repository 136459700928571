import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Checkbox from '../checkbox';
import styles from './filteritem.module.scss';

class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.state= {
      renderChild: false
    };
  }

  componentDidUpdate(prevProps) {
    const { filterCategories } = this.props;
    if (prevProps.filterCategories.length && filterCategories.length === 0) {
      this.setState({ renderChild: true }, () => {
        this.setState({ renderChild: false });
      });
    }
  }

onCheckboxChange = (e) => {
  const { handleChange, filter } = this.props;
  handleChange(filter.name, e);
}

render() {
  const { filter, filterCategories, type, subCategories } = this.props;
  const bool = true;
  const { renderChild } = this.state;
  return (

    <div className={styles.checkList}>
      {subCategories
        ? (
          <Fragment>
            { filter.subCategories && filter.subCategories.map(per => (
              <FormattedMessage key={per.id} id={per.key ? per.key : 'invalid'} defaultMessage={per.title}>
                {txt => (
                  <Checkbox id={`${per.title}`} label={txt}
                    checked={filterCategories.includes(per.title) ? bool: !bool}
                    onChange={this.onCheckboxChange}
                  />
                )}
              </FormattedMessage>))
          }
          </Fragment>
        ) : (
          <Fragment>
            { filter.permissions && filter.permissions.map(per => (
              <FormattedMessage key={per.id} id=" " defaultMessage={per.title}>
                {txt => (
                  <Checkbox id={type?`${per.id}`: `${per.title}`} label={txt}
                    key={renderChild}
                    checked={type==='asset' ?filterCategories.includes(typeof per.id==='number'
                      ? JSON.stringify(per.id):(per.id))
                      :!!filterCategories.includes(per.title)}
                    onChange={this.onCheckboxChange}
                  />
                )}

              </FormattedMessage>))
          }
          </Fragment>
        )}

    </div>
  );
}
}

FilterItem.propTypes = {
  filter: PropTypes.shape({}).isRequired
};
export default FilterItem;
