import React from "react";
import { Icon as LibIcon } from 'semantic-ui-react'

import { IconTypes } from '../../../modules/chat/enums';
import { Wrapper } from './Icon.styles'

type IconProps = {
  name: IconTypes;
  onClick: () => void;
};

const Icon: React.FC<IconProps> = ({ name, ...rest }) => {

  return (
    <Wrapper>
      <LibIcon name={name} {...rest}/>
    </Wrapper>
  );
};

export default Icon;
export { Icon };
