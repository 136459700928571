import styled from 'styled-components';

export const Wrapper = styled.div`
  .post-footer {
    padding: -15px 0;
    .avatar-list {
      img {
      }
    }
    .replies {
      padding-right: 15px;
      font-size: 13px;
      font-weight: bold;
      line-height: 2;
      color: ${({ theme }) => theme.colors.text.primary};
      cursor: pointer;
    }
    .last-reply {
      font-size: 13px;
      line-height: 2;
      letter-spacing: 0.09px;
      color: ${({ theme }) => theme.colors.text.light};
    }
  }

  .smile-wrapper {
    display: flex;

    .smile {
      margin-right: 15px;
      min-width: 35px;
      height: 22px;
      border-radius: 9px;
      background-color: #e9eff4;

      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.text.light};
      border: unset;
      padding: 0 3px;
      .smile2{
        height:22px;
        width:20px;
      }
      svg,
      span {
        padding-right: 4px;
      }
    }
  }
`;
