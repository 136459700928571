import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import styles from '../TenantsDetail.module.scss';

const LeaseTermDetail = ({
  answers
}) => (
    <>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.detail.rent" defaultMessage="Rent" />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.annual_rent" defaultMessage="Annual Rent" />
            </span>
            <span className={styles.rowContent}>
              <NumberFormat displayType="text" value={answers && answers.annual_rent}
                thousandSeparator prefix="$" suffix=".00"
              />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.monthly_rent" defaultMessage="Monthly Rent" />
            </span>
            <span className={styles.rowContent}>
              <NumberFormat displayType="text" value={answers && answers.monthly_rent}
                thousandSeparator prefix="$" suffix=".00"
              />
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.detail.lease_period" defaultMessage="Lease Period" />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.start_date" defaultMessage="Start Date" />
            </span>
            <span className={styles.rowContent}>
              {(answers && answers.start_date) && moment(answers.start_date).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.end_date" defaultMessage="End Date" />
            </span>
            <span className={styles.rowContent}>
              {(answers && answers.end_date) && moment(answers.end_date).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.detail.term_details" defaultMessage="Terms Details" />
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.detail.lease_term" defaultMessage="Lease Term" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.lease_term}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.additional_remarks" defaultMessage="Additional Remarks" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.description}
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.annual_adjustment" defaultMessage="Annual Adjustment Date" />
            </span>
            <span className={styles.rowContent}>
              {(answers && answers.adjustment_date) && moment(answers.adjustment_date).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step2.renewal" defaultMessage="Renewal Options" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.renewal_options}
            </span>
          </div>
        </div>
      </div>
  </>
);

export default LeaseTermDetail;
