const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const FETCH_PROPERTIES = asyncActionType('aerosimple/settings/FETCH_PROPERTIES');
export const ADD_PROPERTY = asyncActionType('aerosimple/settings/ADD_PROPERTY');
export const UPDATE_PROPERTY = asyncActionType('aerosimple/settings/UPDATE_PROPERTY');
export const DELETE_PROPERTY = asyncActionType('aerosimple/settings/DELETE_PROPERTY');
export const FETCH_PROPERTY_TYPES = asyncActionType('aerosimple/settings/FETCH_PROPERTY_TYPES');
export const UPLOAD_PROPERTY_TYPES = asyncActionType('aerosimple/settings/UPLOAD_PROPERTY_TYPES');
export const CLEAR_PROPERTY_ACTION = 'aerosimple/lease/CLEAR';
export const FETCH_SCHEMA = asyncActionType('aerosimple/settings/FETCH_SCHEMA');
export const UPDATE_SCHEMA = asyncActionType('aerosimple/settings/UPDATE_SCHEMA');
export const CLEAR_MAINTENANCE = 'aerosimple/lease/CLEAR';
export const FETCH_CATEGORIES = asyncActionType('aerosimple/settings/FETCH_CATEGORIES');
export const ADD_CATEGORIES = asyncActionType('aerosimple/settings/ADD_CATEGORIES');
export const UPDATE_CATEGORIES = asyncActionType('aerosimple/settings/UPDATE_CATEGORIES');
export const FETCH_WORKORDERS = asyncActionType('aerosimple/settings/FETCH_WORKORDERS');
export const ADD_WORKORDERS = asyncActionType('aerosimple/settings/ADD_WORKORDERS');
export const GET_WORKORDER = asyncActionType('aerosimple/settings/GET_WORKORDER');
export const ADD_MAINTENANCE = asyncActionType('aerosimple/settings/ADD_MAINTENANCE');
export const FETCH_ALL_TYPES = asyncActionType('aerosimple/settings/FETCH_ALL_TYPES');
export const UPLOAD_TYPES = asyncActionType('aerosimple/settings/UPLOAD_TYPES');
export const CLEAR_TENANT_TYPES = 'aerosimple/lease/CLEAR_TENANT_TYPES';
export const FETCH_TENANTS = asyncActionType('aerosimple/settings/FETCH_TENANTS');
export const GET_TENANT = asyncActionType('aerosimple/settings/GET_TENANT');
export const ADD_TENANT = asyncActionType('aerosimple/settings/ADD_TENANT');
export const GET_TENANT_NAMES = asyncActionType('aerosimple/settings/GET_TENANT_NAMES');
export const UPDATE_TENANT = asyncActionType('aerosimple/settings/UPDATE_TENANT');
