import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import PulpoField from '../../../../pulpo_visualizer/fields';
import formstyles from '../../../opsSettings/Map/map.module.scss';
import Button from '../../../../components/button';
import Clickable from '../../../../components/clickable/Clickable';
import FormattedMessageWithClass from '../../../../components/formattedMessageWithClass';
import attachmentIcon from '../../../../icons/attachment_icon.png';
import dzstyles from '../../../workorders/Create/components/fixedFields.module.scss';
import styles from './form.module.scss';

class Form extends Component {
  handleRemoveImage = (r) => {
    const { handleAnswerChanged, info } = this.props;
    const res = info.attachments.filter(e => e !== r);
    handleAnswerChanged('info', res, 'attachments');
    handleAnswerChanged('info', [...info.removedAttachments, r.id], 'removedAttachments');
  }

  handleDrop = (dropped) => {
    const { handleAnswerChanged, info } = this.props;
    handleAnswerChanged('info', [...info.attachments, ...dropped], 'attachments');
  }

  render() {
    const {
      info,
      handleAnswerChanged,
      handleFieldErrorChanged,
      onSave,
      onDelete,
      onCancel,
      onEdit,
      showFormErrors,
      intl,
      propertyTypes
    } = this.props;
    return (
    <>
      <div className={formstyles.popup}>
        <div className={formstyles.head}>
          <Clickable onClick={onCancel} className={formstyles.cancel}>
            <FormattedMessage id="surfaces.cancel" defaultMessage=" Cancel" />
          </Clickable>
          <div className={formstyles.actions}>
            { info && info.id
              ? <Button translationID="surfaces.edit" onClick={onEdit} defaultText="Update" action="secondary" />
              : <Button translationID="surfaces.create" onClick={onSave} defaultText="Create" action="secondary" />
          }
          </div>
        </div>
        <PulpoField key="name" id="name" type="string"
          translationID="lease.property.name" isRequired
          title="Property Name"
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.name}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
        />
        <PulpoField key="space_size" id="space_size" type="string"
          translationID="lease.property.space"
          title="Space"
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.space_size}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
        />
        {propertyTypes && (
        <PulpoField key="property_type" id="property_type" type="select"
          title="Property Type"
          translationID="lease.property.property_type"
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.property_type}
          values={propertyTypes.map(
            e => ({ key: `${e.name}`, value: (e.i18n_id ? intl.formatMessage({ id: `lease.property.${e.i18n_id}` }) : e.name) })
          )}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={handleFieldErrorChanged}
        />
        )}
        <PulpoField key="desc" id="description" type="string"
          translationID="lease.property.description"
          widget={{ type: 'textarea' }} title="Additional remarks"
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.description}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
        />
        <div className={styles.float}>
          <div className={styles.fullWidthleft}>
            <label htmlFor="file-input" className={styles.label}>
              <FormattedMessage id="lease.property.floor_plan"
                defaultMessage="Floor plan"
              />
            </label>
            <div className={styles.col}>
              <div className={styles.imageupload}>
                <label htmlFor="file-input">
                  <img src={attachmentIcon} alt="" />
                  <span className={styles.imageName}>
                    {!info.attachments && intl.formatMessage({ id: 'todo.newTask.browse' })}
                  </span>
                </label>
                <input id="file-input" type="file" accept="image/*" onChange={e => handleAnswerChanged('info', e.target.files[0], 'plan_attachment')} />
                <span className={styles.imageName}>
                  {info.plan_attachment && (info.plan_attachment.file_name || info.plan_attachment.name)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={dzstyles.fullInput}>
          <FormattedMessageWithClass className={dzstyles.label}
            id="workorder.create.photos" defaultMessage="Photos"
          />
          <Dropzone onDrop={this.handleDrop} disableClick
            className={dzstyles.dropzone} accept="image/*"
          >
            {({ open }) => (
              <>
                <p className={`${dzstyles.browsetext} ${styles.browsetext}`}>
                  <FormattedMessage id="airport.logo.text1"
                    defaultMessage="Drag an image here or"
                  />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button"
                      defaultMessage="browse"
                    />
                  </button>
                  <FormattedMessage id="airport.logo.text2"
                    defaultMessage="for an image to upload."
                  />
                </p>
              </>
            )}
          </Dropzone>
          {info.attachments && info.attachments.length > 0 && (
          <div className={dzstyles.photos} key={info.id}>
            {info.attachments.map(e => (
              <div key={`${info.id}-${e.name || e.id}`} className={`${dzstyles.wrapper} ${styles.wrapper}`}>
                <Clickable onClick={() => this.handleRemoveImage(e)}>&times;</Clickable>
                <img src={e.preview || e.url} alt={e.name || e.id} />
              </div>
            ))}
          </div>
          )}
        </div>
        <div className={styles.footer}>
          { info && info.id && (
            <Clickable onClick={onDelete} className={styles.delete}>
              <FormattedMessage id="surfaces.delete" defaultMessage="Delete" />
            </Clickable>
          )}
        </div>
      </div>
    </>
    );
  }
}

Form.propTypes = {
  info: PropTypes.shape({}).isRequired,
  handleAnswerChanged: PropTypes.func.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool
};

Form.defaultProps = {
  showFormErrors: false
};

export default injectIntl(Form);
