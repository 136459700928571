import gql from 'graphql-tag';

const COMPOSE_COMMENT = gql`
  mutation createComment(
    $airportId: String!
    $channelId: String!
    $userId: String!
    $content: String!
    $parentCommentId: String
    $postId: String
  ) {
    createComment(
      input: {
        airportId: $airportId
        channelId: $channelId
        userId: $userId
        content: $content
        postId: $postId
        parentCommentId: $parentCommentId
      }
    ) {
      channelId
      commentId
      content
      createdAt
      iropsId
      likes
      parentCommentId
      postId
      updatedAt
      userId
      isLiked
      user {
        firstName
        lastName
        profilePicture
        isActive
        title
        userId  
        airportDetails {
          name
          code
          logo
        }
        airportId
      }
    }
  }
`;

export default COMPOSE_COMMENT;
