import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProgressBar from './components/ProgressBar';
import Button from '../../../../components/button';
import Loading from '../../../../components/loading';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import Step3 from './steps/Step3/Step3';
import Step4 from './steps/Step4/Step4';
import { TENANTS } from '../../../../constants/RouterConstants';
import { fetchProperties, fetchAllTypes, createTenant, clearTenantsTypes } from '../../redux/actions';
import { GetFileName, S3Uploads } from '../../../services';
import Step5 from './steps/Step5/Step5';
import Step6 from './steps/Step6/Step6';
import styles from './create.module.scss';

class TenantsCreate extends Component {
  state = {
    currentStep: 1,
    answerErrors: {
      name: true,
      phone: true,
      address: true,
      business_type: true,
      category: true
    },
    leaseErrors: {
      lease_type: true
    },
    lease: {
      attachments: []
    },
    multipleAircrafts: [{}],
    multipleDocs: [{}],
    multipleFees: [{}],
    showErrors: false,
    loadingStatus: false
  }

  componentDidMount() {
    const { actionFetchProperties, actionFetchTypes } = this.props;
    actionFetchProperties();
    actionFetchTypes();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.createTenantAction && props.createTenantAction.success && state.loadingStatus) {
      return { ...state, loadingStatus: false, currentStep: 6 };
    }
    return state;
  }

  handleAnswerChanged = (section, answer, fieldId) => {
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [fieldId]: answer
      }
    }));
  }

  handleMultipleAnswers = (section, list) => {
    if (list.length > 0 && Object.keys(list[list.length -1]).length > 0) {
      this.setState(prevState => ({
        [section]: [
          ...prevState[section],
          {}
        ]
      }));
    }
  }

  handleAnswerList = (section, answer) => {
    this.setState({
      [section]: answer
    });
  }

  handleFieldErrorChanged = (section, id, value) => {
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [id]: value
      }
    }));
  }

  nextStep = () => {
    const { currentStep, answerErrors, leaseErrors } = this.state;
    const { allProperties } = this.props;
    if (currentStep === 1) {
      const noErrors = Object.keys(answerErrors).every(k => (answerErrors[k] === false));
      if (noErrors) {
        this.setState({ currentStep: Math.max(1, currentStep + 1), showErrors: false });
      } else {
        this.setState({ showErrors: true });
      }
    } else if (currentStep === 2) {
      let noErrors = Object.keys(leaseErrors).every(k => (leaseErrors[k] === false));
      if (allProperties && allProperties.length === 0) noErrors = true;
      if (noErrors) {
        this.setState({ currentStep: Math.max(1, currentStep + 1), showErrors: false });
      } else {
        this.setState({ showErrors: true });
      }
    } else if (currentStep === 3) {
      this.setState({ currentStep: Math.max(1, currentStep + 1) });
    } else if (currentStep === 4) {
      this.setState({ currentStep: Math.max(1, currentStep + 1) });
    } else {
      this.setState({ currentStep: Math.max(1, currentStep + 1) });
    }
  };

  prev = () => {
    const { currentStep } = this.state;
    const { history, actionClear } = this.props;
    if (currentStep === 1) {
      history.push(`${TENANTS}`);
    } else if (currentStep === 6) {
      this.setState({
        currentStep: 1,
        answerErrors: {
          name: true,
          phone: true,
          address: true,
          business_type: true,
          category: true
        },
        leaseErrors: {
          lease_type: true
        },
        lease: {
          attachments: []
        },
        multipleAircrafts: [{}],
        multipleDocs: [{}],
        multipleFees: [{}],
        showErrors: false,
        loadingStatus: false,
        answers: {}
      });
      actionClear();
    } else {
      this.setState({ currentStep: Math.max(1, currentStep -1) });
    }
  };

  handleEdit = (currentStep) => {
    this.setState({ currentStep });
  }

  handleSubmit = async () => {
    const { actionCreateTenant } = this.props;
    const { answers, lease, multipleAircrafts, multipleDocs, multipleFees, fixedFee } = this.state;

    this.setState({ loadingStatus: true });
    const tenant = { ...answers };
    const lease_detail = {};
    if (lease && lease.attachments.length > 0) {
      const attachments = await this.upload_attachments(lease.attachments, 'tenant_lease');
      if (attachments.length > 0) lease_detail.attachments = attachments;
    }
    Object.keys(lease).forEach((l) => {
      if (l !== 'attachments') {
        lease_detail[l] = lease[l];
      }
    });

    const fees = [];
    for (let index = 0; index < multipleFees.length; index++) {
      const fee = {};
      // if (Object.keys(multipleFees[index].length)
      Object.keys(multipleFees[index]).forEach((l) => {
        if (l !== 'receipt_attachments') {
          if (multipleFees[index][l] && multipleFees[index][l].length > 0) fee[l] = multipleFees[index][l];
        }
      });

      if (multipleFees[index].receipt_attachments) {
        // eslint-disable-next-line no-await-in-loop
        const attachments = await this.upload_attachments(multipleFees[index].receipt_attachments, 'tenant_fee_receipts');
        if (attachments.length > 0) fee.receipt_attachments = attachments;
      }
      if (Object.keys(fee).length > 0) fees.push(fee);
    }
    const fixed_fee = {};
    if (fixedFee) {
      Object.keys(fixedFee).forEach((l) => {
        if (fixedFee[l] && fixedFee[l].length > 0) fixed_fee[l] = fixedFee[l];
      });
      if (Object.keys(fixed_fee).length > 0) fees.push(fixed_fee);
    }

    const documents = [];
    for (let index = 0; index < multipleDocs.length; index++) {
      const document = {};
      Object.keys(multipleDocs[index]).forEach((l) => {
        if (l !== 'files') {
          if (multipleDocs[index][l] && multipleDocs[index][l].length > 0) document[l] = multipleDocs[index][l];
        }
      });

      if (multipleDocs[index].files) {
        // eslint-disable-next-line no-await-in-loop
        const attachments = await this.upload_attachments(multipleDocs[index].files, 'tenant_lease_documents');
        if (attachments && attachments.length > 0) document.files = attachments;
      }
      if (Object.keys(document).length > 0) documents.push(document);
    }

    const aircrafts = [];
    multipleAircrafts.forEach((l) => {
      const aircraft = {};
      Object.keys(l).forEach((e) => {
        if (l[e] && (l[e].length > 0)) aircraft[e] = l[e];
      });
      if (Object.keys(aircraft).length > 0) aircrafts.push(aircraft);
    });

    tenant.lease = JSON.stringify(lease_detail);
    tenant.fees = JSON.stringify(fees);
    tenant.documents = JSON.stringify(documents);
    tenant.aircrafts = JSON.stringify(aircrafts);
    const formData = new FormData();
    Object.keys(tenant).forEach((k) => {
      formData.append(k, tenant[k]);
    });
    actionCreateTenant(formData);
  };

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  upload_attachments = async (attachments, entity) => {
    let fileIds = '';
    if (attachments.length > 0) {
      fileIds = await this.getFileNames(attachments.length);
    }
    const list = [];
    if (fileIds) {
      await this.doS3Upload(attachments, fileIds, entity)
        .then(() => {
          fileIds.filename.forEach((id) => {
            list.push(id.toString());
          });
        });
    }
    return list;
  }

  render() {
    const { allProperties, allTypes, user, history } = this.props;
    const { currentStep, showErrors, answers, lease, loadingStatus,
      multipleAircrafts, multipleFees, multipleDocs, fixedFee } = this.state;
    return (
      <>
        <div className={styles.box}>
          <div className={styles.title}>
            <FormattedMessage id="lease.tenants.add_tenant" defaultMessage="Add New Tenant" />
          </div>
          <Loading loadingStatus={loadingStatus} />
          <div className={styles.content}>
            {currentStep < 5 && <ProgressBar step={currentStep} />}
            {currentStep === 1 && (
              <Step1 info={answers}
                allTypes={allTypes}
                handleAnswerChanged={this.handleAnswerChanged}
                handleFieldErrorChanged={this.handleFieldErrorChanged}
                showFormErrors={showErrors}
              />
            )}
            {currentStep === 2 && (
              <Step2 info={lease}
                allProperties={allProperties}
                handleAnswerChanged={this.handleAnswerChanged}
                handleFieldErrorChanged={this.handleFieldErrorChanged}
                showFormErrors={showErrors}
                user={user}
              />
            )}
            {currentStep === 3 && (
              <Step3
                multipleDocs={multipleDocs}
                multipleFees={multipleFees}
                handleMultipleAnswers={this.handleMultipleAnswers}
                handleAnswerList={this.handleAnswerList}
                handleAnswerChanged={this.handleAnswerChanged}
                fixedFee={fixedFee}
              />
            )}
            {currentStep === 4 && (
              <Step4
                allTypes={allTypes}
                multipleAircrafts={multipleAircrafts}
                handleMultipleAnswers={this.handleMultipleAnswers}
                handleAnswerList={this.handleAnswerList}
              />
            )}
            {currentStep === 5 && (
              <Step5
                answers={answers}
                lease={lease}
                fixedFee={fixedFee}
                fees={multipleFees}
                documents={multipleDocs}
                aircrafts={multipleAircrafts}
                handleEdit={this.handleEdit}
                allProperties={allProperties}
              />
            )}
            {currentStep === 6 && (
              <Step6
                title={answers.name}
              />
            )}
          </div>
          <div className={styles.footer}>
            {currentStep < 6 && (
            <div className={styles.footer}>
              <Button action="teritory" onClick={this.prev}
                translationID="tenants.new.cancel" defaultText="Back"
              />
            </div>
            )}
            {currentStep < 4 && (
            <div className={styles.footer} style={{ position: 'relative' }}>
              <div className={styles.stagebtn}>
                <Button action="secondary" onClick={this.nextStep}
                  translationID={`tenants.new.stage${currentStep + 1}`} defaultText={`Continue to Stage${currentStep + 1}`}
                />
              </div>
            </div>
            )}
            {currentStep === 4 && (
            <div className={styles.footer} style={{ position: 'relative' }}>
              <div className={styles.stagebtn}>
                <Button action="secondary" onClick={this.nextStep}
                  translationID="lease,tenants.continue_to_review" defaultText="Continue to Review"
                />
              </div>
            </div>
            )}
            {currentStep === 5 && (
              <div className={styles.footer} style={{ position: 'relative' }}>
                <div className={styles.stage5btn}>
                  <Button onClick={() => this.handleSubmit()}
                    translationID="airport.form.submit" defaultText="Save"
                  />
                </div>
              </div>
            )}
            {currentStep === 6 && (
              <>
                <div className={styles.footer}>
                  <div className={styles.footer}>
                    <Button action="secondary" onClick={this.prev}
                      translationID="lease.tenants.add_another_tenant"
                      defaultText="Add Another Tenant"
                    />
                  </div>
                </div>
                <div className={styles.footer} style={{ position: 'relative' }}>
                  <div className={styles.stagebtn}>
                    <Button
                      action="secondary"
                      onClick={() => history.push(`${TENANTS}`)}
                      translationID="lease.tenants.view_all_tenant"
                      defaultText="View All Tenants"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

TenantsCreate.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionFetchProperties: PropTypes.func.isRequired,
  actionFetchTypes: PropTypes.func.isRequired,
  actionCreateTenant: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  allProperties: state.lease.allProperties,
  allTypes: state.lease.allTenantTypes,
  user: state.auth.profile,
  createTenantAction: state.lease.createTenantAction
});

const mapDispatchToProps = dispatch => ({
  actionFetchProperties: () => {
    dispatch(fetchProperties());
  },
  actionFetchTypes: () => {
    dispatch(fetchAllTypes());
  },
  actionCreateTenant: (data) => {
    dispatch(createTenant(data));
  },
  actionClear: () => {
    dispatch(clearTenantsTypes());
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantsCreate);
