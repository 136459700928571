/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Transition } from 'react-transition-group';

import { connect } from 'react-redux';
import {
  fetchAssets,
  fetchAssetsSchema,
  fetchAssetTypes,
  deleteAsset,
  editAsset,
  clear } from '../redux/actions';
import { fetchSurfaces } from '../../settings/Map/redux/actions';

import styles from './assetList.module.scss';
import editIcon from '../../../icons/inspection-icons/edit.svg';
import AssetForm from '../Builder/components/AssetForm';
import { GetFileName, S3Uploads } from '../../services';
import Modal from '../../../components/modal';
import Button from '../../../components/button';
import Paginator from '../../../components/paginator/paginator';
import Loading from '../../../components/loading';
import Select from '../../../components/select';

class AssetList extends Component {
  state = {
    types: [],
    showEditForm: false,
    selectedAsset: {},
    fieldErrors: {},
    requiredMap: {},
    shouldShowErrors: false,
    removedAttachments: [],
    reload: false,
    customFields: {},
    showDelPopUp: false,
    assets: [],
    filterSurfaces: [],
    surfaces: {},
    currentPage: 1,
    selected: {},
    loadingStatus: false
  }

  slideStyles = {
    entered: { transform: 'translate3d(0px,0px,0px)', marginTop: '10%' }
  }

  async componentDidMount() {
    const {
      actionFetchSurfaces,
      actionFetchTypes,
      surfaces,
      types,
      schemas,
      actionFetchSchemas } = this.props;

    if (!surfaces.length) {
      await actionFetchSurfaces();
      this.setState({ surfaces });
    }
    if (!types.length) await actionFetchTypes();
    if (!schemas.length) await actionFetchSchemas();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.assetList.length && !state.assets.length) {
      const assets = props.assetList.reduce((rv, x) => {
        const aux = rv;
        (aux[x.area] = rv[x.area] || []).push(x);
        return aux;
      }, {});
      return {
        ...state,
        assets
      };
    }
    if (!props.surfaces.length && !state.types.length) {
      const types= [];
      types.push({ id: null, name: 'Other' });
      return { ...state, types };
    }
    if (props.surfaces.length && !state.types.length) {
      const types = props.surfaces.map(s => (
        { id: s.surface_type.id, name: s.surface_type.name }
      )).filter((type, index, self) => self.findIndex(
        t => t.id === type.id && t.name === type.name
      ) === index);
      types.push({ id: null, name: 'Other' });
      const surfaces = props.surfaces.reduce((rv, x) => {
        const aux = rv;
        (aux[x.surface_type.id] = rv[x.surface_type.id] || []).push(x);
        return aux;
      }, {});
      return { ...state, types, surfaces };
    }

    if (!Object.keys(state.requiredMap).length) {
      let requiredMap = {};
      let fieldErrors = {};
      Object.keys(props.schemas).forEach((s) => {
        props.schemas[s].schema.fields.forEach((f) => {
          requiredMap = {
            ...requiredMap,
            [s]: {
              ...requiredMap[s],
              [f.id]: f.required
            }
          };
          fieldErrors = {
            ...fieldErrors,
            [s]: {
              ...fieldErrors[s],
              [f.id]: f.required
            }
          };
        });
      });
      return { ...state, requiredMap, fieldErrors };
    }
    return state;
  }


  componentDidUpdate(prevProps) {
    const { surfaces, assets_action, assetList } = this.props;
    const { loadingStatus, filterSurfaces }= this.state;
    if (prevProps.surfaces !== surfaces) {
      this.handleSurfaceAssets();
    }
    if (assets_action.success !== prevProps.assets_action.success) {
      this.setState({ loadingStatus: !loadingStatus });
    }

    if (assets_action.loading !== prevProps.assets_action.loading) {
      this.setState({ loadingStatus: true });
    }
    if (assets_action.message=== 'success' && loadingStatus=== true) {
      this.setState({ loadingStatus: false });
    }

    if (prevProps.assetList.length !== assetList.length) {
      const assets = assetList.reduce((rv, x) => {
        const aux = rv;
        (aux[x.area] = rv[x.area] || []).push(x);
        return aux;
      }, {});
      this.setState({ assets });
    }
    if (surfaces.length>0&&filterSurfaces.length<=0) {
      const nsurfaces=[{ key: surfaces.length+1, id: -1, name: 'All surfaces' }];
      surfaces.forEach((surf) => {
        surf.key=surf.id;
        nsurfaces.push(surf);
      });
      this.setState({
        filterSurfaces: nsurfaces,
        selected: nsurfaces[0]
      });
    }
  }

  componentWillUnmount() {
    const { actionClear }= this.props;
    actionClear();
  }

  handleSurfaceAssets = () => {
    const { surfaces } = this.props;
    const types = surfaces.map(s => (
      { id: s.surface_type.id, name: s.surface_type.name }
    )).filter((type, index, self) => self.findIndex(
      t => t.id === type.id && t.name === type.name
    ) === index);
    types.push({ id: null, name: 'Other' });

    const newSurfaces = surfaces.reduce((rv, x) => {
      const aux = rv;
      (aux[x.surface_type.id] = rv[x.surface_type.id] || []).push(x);
      return aux;
    }, {});
    this.setState({ types, surfaces: newSurfaces });
  }

  handleTypeChange = (selected) => {
    const { types }= this.props;
    const typeData=[];
    for (let i=0; i< types.length; i+=1) {
      if (selected.category===types[i].category) {
        typeData.push(types[i]);
      }
    }
    this.setState(prevState => ({
      seletedAssetType: [...typeData],
      info: {
        ...prevState.info,
        type: selected
      }
    }));
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  handleInputChange = (value, id, removeId) => {
    const { removedAttachments } = this.state;
    if (removeId) {
      removedAttachments.push(removeId);
    }
    this.setState(prevState => ({
      shouldShowErrors: false,
      info: {
        ...prevState.info,
        [id]: value
      },
      removedAttachments
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    const { info } = this.state;
    const cat = info.type.category.toLowerCase();
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [cat]: {
          ...prevState.fieldErrors[cat],
          [id]: value
        }
      }
    }));
  }

  handleAnswerChange = (value, id, fieldid) => {
    this.setState({ spaceError: false });
    if (fieldid==='customFields') {
      this.setState(prevState => ({
        shouldShowErrors: false,
        info: {
          ...prevState.info,
          response: {
            ...prevState[fieldid],
            [id]: value
          }
        }
      }));
    }
    this.setState(prevState => ({
      shouldShowErrors: false,
      [fieldid]: {
        ...prevState[fieldid],
        [id]: value
      }
    }
    ));
  };

  handleSave = async () => {
    const {
      info: { id, type, ...data },
      removedAttachments,
      customFields,
      fieldErrors } = this.state;
    const {
      actionSave,
      actionFetch,
      actionEdit,
      schemas } = this.props;
    const cat = type.category.toLowerCase();
    const categoryErrors = fieldErrors[cat] ? fieldErrors[cat] : {};
    const noErrors = Object.keys(categoryErrors)
      .every(k => (fieldErrors[cat][k] === false));
    if (noErrors) {
      data.asset_type = type.id;
      data.response = JSON.stringify(customFields);
      if (Object.keys(data.response ? data.response : {}).length === 0) {
        data.response = Object.keys(categoryErrors).reduce((t, e) => {
          const aux = t; aux[e] = '';
          return aux;
        }, {});
      }

      if (data.area) {
        data.area = data.area.id;
      } else {
        delete data.area;
      }
      data.version_schema = schemas[type.category.toLowerCase()].id;
      data.geometry = JSON.stringify(data.geometry);
      if (removedAttachments.length > 0) {
        data.removedAttachments = removedAttachments;
      }
      // transform data into formData
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k === 'photos') {
          data.photos.forEach((e) => {
            formData.append(k, e);
          });
        } else if (k !== 'attachments') {
          formData.append(k, data[k]);
        }
        if (k === 'response') {
          if (Object.keys(data[k]).length<1) {
            formData.append(k, '{}');
          }
        }
      });
      const newAttachments = [];
      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((e) => {
          if (!e.id) {
            newAttachments.push(e);
          }
        });
      }
      if (newAttachments.length) {
        let fileIds = '';
        if (newAttachments.length > 0) {
          fileIds = await this.getFileNames(newAttachments.length);
        }
        if (fileIds) {
          await this.doS3Upload(newAttachments, fileIds, 'assets')
            .then(() => {
              fileIds.filename.forEach((fileId) => {
                formData.append('attachments', fileId.toString());
              });
            });
        }
      }
      if (!data.name) {
        this.setState({ shouldShowErrors: true, spaceError: false });
        return;
      }
      if (!data.name.trim()) {
        this.setState({ spaceError: true });
      } else if (id) {
        await actionEdit(id, formData);
        this.setState({
          reload: true,
          showEditForm: false
        });
        await actionFetch(1);
      } else {
        actionSave(formData);
      }
    } else {
      this.setState({ shouldShowErrors: true });
    }
  }

  showDelPopUp=() => {
    const { delPopup } = this.state;
    this.setState({
      delPopup: !delPopup
    });
  }

  handleDelete = () => {
    const { info, delPopup } = this.state;
    const { actionDelete } = this.props;
    actionDelete(info.id);
    this.setState({ showEditForm: false, delPopup: !delPopup });
  }

  handleCancel = () => {
    this.setState(prevState => ({
      showEditForm: false,
      info: {
        ...prevState.info,
        id: '',
        label: '',
        name: '',
        geometry: ''
      }
    }));
  }

  showModal= (asset) => {
    const { fieldErrors }=this.state;
    const { types, surfaces, schemas }= this.props;
    const info=Object.assign({}, asset);
    info.type = asset.asset_type;
    const inerr={};
    if (Object.keys(schemas).length>=0) {
      for (let len=0; len<schemas[(info.asset_type.category).toLowerCase()].schema.fields.length; len+=1) {
        if (asset.response[schemas[(info.asset_type.category).toLowerCase()].schema.fields[len].id]!==''&&asset.response[schemas[(info.asset_type.category).toLowerCase()].schema.fields[len].id]!==undefined) {
          inerr[schemas[(info.asset_type.category).toLowerCase()].schema.fields[len].id]=false;
        } else {
          inerr[schemas[(info.asset_type.category).toLowerCase()].schema.fields[len].id]=true;
        }
      }
    }

    if (info.area>0) {
      let narea={};
      narea=surfaces.filter(surface => surface.id===info.area);
      info.area=narea[0];
    }

    const typeData=[];
    for (let i=0; i< types.length; i+=1) {
      if (asset.asset_type.category===types[i].category) {
        typeData.push(types[i]);
      }
    }
    this.setState({
      fieldErrors: {
        ...fieldErrors,
        [(info.asset_type.category).toLowerCase()]: inerr
      },
      showEditForm: true,
      selectedAsset: asset,
      seletedAssetType: [...typeData],
      info,
      customFields: info.response
    });
  }

  handleChange = (e) => {
    const { actionFetch } = this.props;
    this.setState({ selected: e });
    actionFetch(1, e.id);
  }

  getlableName=(a, surfaces, op, types) => {
    let returnVal= '';
    Object.keys(surfaces).map((key) => {
      surfaces[key].map((surf) => {
        if (surf.id===a.area) {
          if (op==='sufaceType') {
            types.map((type) => {
              if (type.id!==null) {
                if (type.id.toString() === key) {
                  returnVal=type.name;
                  return type.name;
                }
              }
            });
          } else {
            returnVal= surf.name;
            return surf;
          }
        }
      });
      if (returnVal!=='') return returnVal;
    });
    if (returnVal!=='') return returnVal;
  }

  changePage = (page) => {
    const { actionFetch }= this.props;
    const { selected } = this.state;
    this.setState({ currentPage: page });
    actionFetch(page||1, selected.id||undefined);
    this.setState({
      assetList: []
    });
  }

  render() {
    const { types,
      surfaces,
      showEditForm,
      loadingStatus,
      delPopup,
      selectedAsset,
      assets,
      selected,
      seletedAssetType,
      requiredMap,
      filterSurfaces,
      shouldShowErrors,
      info } = this.state;
    const { assetList, user, translations, schemas } = this.props;
    const translationMap = translations ? translations[user.language] : {};

    return (
      <div styles={{ display: 'flex' }}>
        <div className={styles.filter} style={{ position: 'sticky' }}>
          {loadingStatus&&<Loading loadingStatus={loadingStatus} />}
          {/* <div style={{ width: '50%' }}> */}
          {Object.keys(surfaces).length>0 && (
            <div className={styles.bar}>
              <Select
                minWidth
                value={selected}
                options={filterSurfaces}
                onChange={this.handleChange}

              />
            </div>
          )}
          <span style={{ paddingLeft: '13%', position: 'absolute', top: '30%' }}>
            <b>{assetList.count}</b>
            {' '}
            <FormattedMessage id="assets.assets" defaultMessage="Assets" />
          </span>
          <Link to="/ops/assets">
            <FormattedMessage id="assets.map_view" defaultMessage="Map View" />
          </Link>
        </div>
        <Modal
          width="500px"
          minHeight="150px"
          showIn={delPopup}
          contentStyles={{
            padding: ' 15px 30px 5px 40px'
          }}
          centered
          onClose={this.showDelPopUp}
        >
          <h3 style={{ paddingTop: '20px' }}>
            <FormattedMessage
              id="assets.removeAsset"
              defaultText="Are you sure you want to delete the asset "
            />
            <b style={{ color: 'red' }}>
              {' '}
              {selectedAsset.name}
              {' '}
            </b>
                        ?
          </h3>

          <div className={styles.footer}>
            <Button onClick={this.showDelPopUp}
              translationID="roles.footer.No"
              defaultText="No" action="tertiary"
            />
            <Button onClick={this.handleDelete}
              translationID="roles.footer.Yes"
              defaultText="Yes"
              action="danger"
              style={{ minHeight: '10px' }}
            />
          </div>
        </Modal>
        {showEditForm && (
          <Transition in={showEditForm} timeout={200} unmountOnExit>
            { state => (
              <AssetForm
                types={seletedAssetType}
                info={info}
                schemas={schemas}
                translation={translationMap}
                transition={this.slideStyles[state]}
                requiredMap={requiredMap[info.type.category.toLowerCase()]}
                shouldShowErrors={shouldShowErrors}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
                onDelete={this.showDelPopUp}
                onInfoChange={this.handleTypeChange}
                onInputChange={this.handleInputChange}
                onAnswerChange={this.handleAnswerChange}
                onFieldErrorChanged={this.handleFieldErrorChanged}
                customFields={info.response}
              />
            )}
          </Transition>
        )
      }
        <div className={styles.panel}>
          <div style={{ position: 'relative' }}>

            {assets && (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th><FormattedMessage id="assets.Name" defaultMessage="Name" /></th>
                    <th><FormattedMessage id="assets.Surface.Type" defaultMessage="Surface Type" /></th>
                    <th><FormattedMessage id="assets.Label" defaultMessage="Label" /></th>
                    <th><FormattedMessage id="assets.Type" defaultMessage="Type" /></th>
                    <th><FormattedMessage id="assets.Action" defaultMessage="Action" /></th>
                  </tr>
                </thead>
                <tbody>
                  {assetList.results.map(a => (
                    <tr key={a.id}>
                      <td>{a.name}</td>
                      <td>{this.getlableName(a, surfaces, 'sufaceType', types)||'None'}</td>
                      <td>
                        { this.getlableName(a, surfaces)|| a.label }
                      </td>
                      <td><img src={a.asset_type.icon} alt={a.name} /></td>
                      <td>
                        <img src={editIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.showModal(a)}
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div style={{ position: 'absolute', marginLeft: '65%', left: '3%' }}>
          {/* { assetList.results.length > 0
            ? ( */}
          <Paginator
            page={1}
            totalRecords={assetList.count}
            pageLimit={50}
            pageNeighbours={1}
            onPageChanged={page => this.changePage(page)}
          />
          {/* )
             :''} */}
        </div>
      </div>
    );
  }
}

AssetList.propTypes = {
  actionDelete: PropTypes.func.isRequired,
  assetList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
  user: state.auth.profile,
  assetList: state.assets.assetsList,
  schemas: state.assets.schemas,
  translations: state.auth.translations,
  types: state.assets.types,
  assets_action: state.assets.assets_action,
  surfaces: state.map.surfaces
});

const mapDispatchToProps = dispatch => ({
  // fetch assets
  actionFetch: (page, surface) => {
    dispatch(fetchAssets(page, surface));
  },
  // fetch assets
  actionFetchSurfaces: () => {
    dispatch(fetchSurfaces());
  },
  actionDelete: (id) => {
    dispatch(deleteAsset(id));
  },
  actionFetchSchemas: () => {
    dispatch(fetchAssetsSchema());
  },
  actionEdit: (id, data) => {
    dispatch(editAsset(id, data));
  },
  actionClear: () => {
    dispatch(clear());
  },
  actionFetchTypes: (id) => {
    dispatch(fetchAssetTypes(id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AssetList));
