import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { HOC as Permissions } from 'react-redux-permissions';
import SectionHeader from '../../../../components/sectionHeader';
import HeaderBack from '../../../../components/headerBack';
import Panel from '../../../../components/panel';
import Button from '../../../../components/button';
import Loading from '../../../../components/loading';
import Separator from '../../../../components/separator';
import Modal from '../../../../components/modal';
import Forbidden from '../../../Forbidden';
import icon from '../../../../icons/WorkOrders.png';
import { MAINTENANCE } from '../../../../constants/RouterConstants';
import {
  WORKORDER_STATUS_CLOSED,
  WORKORDER_STATUS_NEW,
  WORKORDER_STATUS_INPROGRESS
} from '../../../../constants/ModelStatus';
import { searchUser, fetchDataSourceList } from '../../../inspections/redux/actions';
import { getLeaseWorkorder, fetchWorkOrderSchema, createLeaseMaintenance,
  clearMaintenance } from '../../redux/actions';
import Wostyles from '../../../workorders/Detail/workOrderDetail.module.scss';
import WorkorderInfo from './components/WorkorderInfo';
import StepInfoBox from '../../../workorders/Detail/components/StepInfoBox';
import StepForm from '../../../workorders/Detail/components/StepForm';
import { GetFileName, S3Uploads } from '../../../services';
import styles from './maintenanceDetail.module.scss';
import Clickable from '../../../../components/clickable/Clickable';

class MaintenanceDetail extends Component {
    state = {
      maintenance: {
        attachments: [],
        completed_on: moment().format(),
        work_description: '',
        completed_by: {}
      },
      showFormErrors: false,
      requiredMap: {},
      fieldErrors: {
        completed_by: false,
        completed_on: false,
        work_description: true
      },
      loadingStatus: false,
      statusSelection: false,
      Popup: false,
      status: undefined
    }

    firstTime = true;

    componentDidMount() {
      const { actionFetchWorkorder, actionGetSchemas, match: { params: { id } } } = this.props;
      actionFetchWorkorder(id);
      actionGetSchemas();
    }

    static getDerivedStateFromProps(props, state) {
      // grab user from state
      if (props.user.id && !state.maintenance.completed_by.id) {
        return { ...state,
          maintenance: {
            ...state.maintenance,
            completed_by: {
              id: props.user.id,
              fullname: props.user.fullname
            }
          }
        };
      }
      if (state.status === undefined && props.leaseWorkorder && Object.keys(props.leaseWorkorder).length > 0) {
        const wo_status = props.leaseWorkorder.status;
        return { ...state, status: wo_status };
      }

      if (props.MaintenanceAction && props.MaintenanceAction.success && state.loadingStatus) {
        props.actionClear();
        props.history.push('/lease/maintenance/');
        return { ...state, loadingStatus: false };
      }
      return state;
    }

    componentDidUpdate() {
      const { leaseMaintenance_schema, leaseWorkorder } = this.props;


      if (!this.firstTime || (leaseWorkorder && !Object.keys(leaseWorkorder).length)
        || (leaseMaintenance_schema === undefined)) return;
      const sch = leaseMaintenance_schema.maintenance;

      this.processInspectionForState(sch);
      this.firstTime = false;
    }

    componentWillUnmount() {
      const { actionClear } = this.props;
      actionClear();
    }

    processInspectionForState = (sectionschema) => {
      if (sectionschema) {
        const { schema } = sectionschema;
        let { fieldErrors, requiredMap } = this.state;
        schema.fields.forEach((f) => {
          requiredMap = { ...requiredMap, [f.id]: f.required };
          fieldErrors = { ...fieldErrors, [f.id]: f.required };
        });
        this.setState({ requiredMap, fieldErrors });
      }
    }

    handleStatusSelection = () => {
      const { statusSelection } = this.state;
      this.setState({ statusSelection: !statusSelection });
    }

    handleSearchForUser = (value) => {
      const { actionSearchUser } = this.props;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        actionSearchUser(value);
      }, 300);
    }

    handleAnswerChanged = (type, answer, fieldId) => {
      this.setState(prevState => ({
        [type]: {
          ...prevState[type],
          [fieldId]: answer
        }
      }));
    }

    handleFieldErrorChanged = (id, value) => {
      this.setState(prevState => ({
        fieldErrors: {
          ...prevState.fieldErrors,
          [id]: value
        }
      }));
    }

    getFileNames = async (count) => {
      const response = await GetFileName.getUploadFileName(count);
      return response;
    };


    doS3Upload = async (files, fileIds, entity) => {
      const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
      return response;
    };

    showMaintenanceForm = (workorder) => {
      const { user } = this.props;
      if (workorder.status === WORKORDER_STATUS_NEW || workorder.status === WORKORDER_STATUS_INPROGRESS) {
        if (workorder.assigned_role) {
          const havingRole = user.roles.filter(e => (e.id === workorder.assigned_role.id));
          if (havingRole.length > 0) return true;
        }
        if (workorder.assigned_users.length > 0) {
          const checkUser = workorder.assigned_users[0].id === user.id;
          const SystemAdmin = user.roles.filter(e => (e.name === 'System Admin'));
          if (SystemAdmin.length > 0) return true;
          return checkUser;
        }
        const SystemAdmin = user.roles.filter(e => (e.name === 'System Admin'));
        if (SystemAdmin.length > 0) return true;
        return false;
      }
      return false;
    }

    async sendResponse(status) {
      const {
        actionSendMaintenance,
        leaseWorkorder } = this.props;
      const {
        maintenance,
        fieldErrors } = this.state;
      const data = maintenance;
      const noErrors = Object.keys(fieldErrors)
        .every(k => (fieldErrors[k] === false));
      if (noErrors || (status === WORKORDER_STATUS_INPROGRESS)) {
        const aux = { ...data };
        this.setState({
          loadingStatus: true
        });
        const { completed_by, attachments, completed_on, work_description, ...response } = aux;
        const toSend = {};
        toSend.completed_by = completed_by.id;
        toSend.completed_on = completed_on;
        toSend.attachments = attachments;
        toSend.response = JSON.stringify(response);
        toSend.work_description = work_description;
        if (status) toSend.status = status;

        // transform data into formData
        const formData = new FormData();
        let fileIds = '';
        if (data.attachments.length > 0) {
          fileIds = await this.getFileNames(data.attachments.length);
        }
        Object.keys(toSend).forEach((k) => {
          if (k !== 'attachments') {
            formData.append(k, toSend[k]);
          }
        });
        formData.append('email_date', moment().format());
        if (fileIds) {
          await this.doS3Upload(data.attachments, fileIds, 'lease_maintenance')
            .then(() => {
              fileIds.filename.forEach((fileId) => {
                formData.append('attachments', fileId.toString());
              });
            });
        }
        formData.append('report_date', moment(leaseWorkorder.report_date).format());
        actionSendMaintenance(leaseWorkorder.id, formData);
      } else {
        if (status) this.setState({ Popup: true });
        this.setState({ showFormErrors: true });
      }
    }

    render() {
      const { leaseWorkorder, translations, user, userlist, allDataSources,
        leaseMaintenance_schema, history } = this.props;
      const { showFormErrors, requiredMap, maintenance, loadingStatus,
        statusSelection, status, Popup } = this.state;
      const translationMap = translations ? translations[user.language] : {};
      return (
    <>
      {loadingStatus && (<Loading loadingStatus={loadingStatus} />)}
      <SectionHeader icon={icon} translationID="shortcuts.lease.maintenance" defaultTitle="Maintenance">
        <div className={Wostyles.detailHeader}>
          {(leaseWorkorder && this.showMaintenanceForm(leaseWorkorder) === true) && (
            status === WORKORDER_STATUS_NEW || status === WORKORDER_STATUS_INPROGRESS) && (
            <Button translationID="lease.maintenance.detail.status"
              defaultText="Update Status" onClick={() => this.handleStatusSelection()}
            />
          )}
          {statusSelection && (
          <ul className={`${styles.dropdown} ${statusSelection ? styles.open : ''}`}>
            <li className={styles.item}>
              <Clickable onClick={() => this.sendResponse(1)}>
                <div className={styles.green} />
                <FormattedMessage id="lease.maintenance.detail.status_mark_inprogress" defaultMessage="Mark In Progress" />
              </Clickable>
            </li>
            <li className={styles.item}>
              <Clickable onClick={() => this.sendResponse(2)}>
                <div className={styles.orange} />
                <FormattedMessage id="lease.maintenance.detail.status_complete_workorder" defaultMessage="Complete Work Order" />
              </Clickable>
            </li>
          </ul>
          )}
          <Separator />
        </div>
      </SectionHeader>
      <HeaderBack
        translationID="inspections.new.prev"
        translationDefault="Back"
        backRoute={MAINTENANCE}
      />
      {leaseWorkorder && (
      <Panel
        containerClasses={Wostyles.mainPanel}
        title="workorders.detail.header"
        defaultTitle={'Work Order {id}'}
        translationValues={{ id: leaseWorkorder.order_number }}
      >
        <div className={Wostyles.panelContent}>
          {leaseWorkorder && Object.keys(leaseWorkorder).length > 0 && (
          <WorkorderInfo
            workorder={leaseWorkorder}
            translation={translationMap}
          />
          )}
          {leaseWorkorder && leaseWorkorder.status === WORKORDER_STATUS_CLOSED && (
            <>
              <StepInfoBox
                step="maintenance"
                info={leaseWorkorder.maintenance_answer}
              />
            </>
          )}
          {leaseWorkorder && this.showMaintenanceForm(leaseWorkorder) === true && leaseMaintenance_schema && (
          <StepForm info={maintenance}
            schema={leaseMaintenance_schema.maintenance}
            step="maintenance"
            translation={translationMap}
            userlist={userlist}
            searchForUser={this.handleSearchForUser}
            handleAnswerChanged={this.handleAnswerChanged}
            handleFieldErrorChanged={this.handleFieldErrorChanged}
            showFormErrors={showFormErrors}
            requiredMap={requiredMap}
            allDataSources={allDataSources}
          />
          )}
        </div>
        {leaseWorkorder && this.showMaintenanceForm(leaseWorkorder) === true && (
            <>
              <div className={Wostyles.woCancelBtn}>
                <Button onClick={() => history.push(MAINTENANCE)} translationID="inspections.new.cancel"
                  defaultText="Cancel" action="secondary"
                />
              </div>
              <div className={Wostyles.woDetailBtn}>
                <Button
                  onClick={() => this.sendResponse()}
                  translationID="workorder.detail.operations.completedBtn"
                  defaultText="Close Work Orders"
                  action="secondary"
                />
              </div>
          </>
        )}
        <Modal
          onClose={() => this.setState({ Popup: false })}
          showIn={Popup}
          width="30%"
          minHeight="20%"
        >
          <div className={styles.confirmContent}>
            <FormattedMessage id="lease.maintenance.detail.status_error"
              defaultMessage="Can you please fill the maintenance form to complete the work_order"
            />
          </div>
          <div className={styles.btn}>
            <Button type="secondary"
              translationID="inspections.list.ok"
              onClick={() => this.setState({ Popup: false })} defaultText="OK"
            />
          </div>
        </Modal>
      </Panel>
      )}
    </>
      );
    }
}

MaintenanceDetail.defaultProps = {
  userlist: [],
  leaseWorkorder: undefined
};

MaintenanceDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  actionFetchWorkorder: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  leaseWorkorder: PropTypes.shape({}),
  userlist: PropTypes.shape({})
};

const mapStateToProps = state => ({
  leaseWorkorder: state.lease.leaseWorkorder,
  user: state.auth.profile,
  userlist: state.inspection.userlist,
  translations: state.auth.translations,
  leaseMaintenance_schema: state.lease.leaseMaintenance_schema,
  allDataSources: state.inspection.allDataSources,
  MaintenanceAction: state.lease.MaintenanceAction
});

const mapDispatchToProps = dispatch => ({
  actionFetchWorkorder: (id) => {
    dispatch(getLeaseWorkorder(id));
  },
  actionGetSchemas: () => {
    dispatch(fetchWorkOrderSchema());
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  },
  actionSendMaintenance: (id, data) => {
    dispatch(createLeaseMaintenance(id, data));
  },
  actionClear: () => {
    dispatch(clearMaintenance());
  }

});

export default Permissions(['view_leaseworkorder'])(
  connect(mapStateToProps, mapDispatchToProps)(MaintenanceDetail),
  <Forbidden />
);
