import gql from 'graphql-tag';

const GET_CHANNEL = gql`
  query getChannel(
    $channelId: String!
  ) {
    getChannel(
      channelId: $channelId
    ) {
        airportId
        channelId
        content
        createdAt
        createdBy
        name
        type
        updatedAt
        userDetails{
            firstName
            lastName
            profilePicture
        }
    }
  }
`;
export default GET_CHANNEL;
