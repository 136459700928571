import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchSafetySelfInspection } from '../../../inspections/redux/actions';
import Button from '../../../../components/button';
import styles from '../../../../components/filters/filteritem.module.scss';
import Collapsible from '../../../../components/collapsible/Collapsible';
import FilterItem from '../../../../components/filters/FilterItem';
import Clickable from '../../../../components/clickable/Clickable';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: []
    };
  }

  componentDidMount() {
    const { types } = this.props;
    const cat=[];
    let catid=0;
    const filters=[];
    types.forEach((e) => {
      if (!cat.includes(e.category)) {
        catid+=1;
        filters.push({ id: catid, name: e.category, permissions: [] });
        cat.push(e.category);
      }
    });
    this.setState({ filters });
  }

  componentDidUpdate() {
    const { types } = this.props;
    const { filters } = this.state;
    for (let i = 0; i < filters.length; i+=1) {
      const perms=[];
      perms.push({ id: filters[i].name.toLowerCase(), title: `Show All ${filters[i].name} ` });
      types.forEach((e) => {
        if (e.category === filters[i].name) {
          perms.push({ id: e.id, title: e.name });
        }
        this.setState((prevState) => {
          prevState.filters.map(
            obj => (
              obj.id === i+1? Object.assign(obj, { permissions: perms }):'')
          );
        });
      });
    }
  }

  render() {
    const { handleFilterChange, filterCategories, closePopUp, update } = this.props;
    const { filters } = this.state;
    return (
      <>
        <div className={styles.popup} style={{ top: '60px', height: 'auto' }}>
          <div className={styles.header}>
            <span className={styles.filterText}>Filters</span>
            <Clickable onClick={closePopUp} className={styles.cancel} style={{ marginTop: '20px', marginLeft: '80px' }}>
              <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
            </Clickable>
            <Button
              action="secondary"
              translationID="workorder.filter.apply"
              defaultText="Apply"
              onClick={update}
            />
          </div>
          <div className={styles.list}>
            {filters.map(e => (
              <div key={e.id}>
                <Collapsible title={e.name} styleClasses={styles.rol} autoheight={false} openOnMount={e.name === 'Signs'}>
                  <FilterItem
                    id={e.id}
                    filter={e}
                    handleChange={(id, targetId) => handleFilterChange(id, targetId)}
                    filterCategories={filterCategories}
                    type="asset"
                  />
                </Collapsible>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

FilterForm.propTypes = {
  selfInspection: PropTypes.shape({})
};

FilterForm.defaultProps = {
  selfInspection: {}
};

const mapStateToProps = state => ({
  selfInspection: state.inspection.selfInspection
});

const mapDispatchToProps = dispatch => ({
  // Fetch Safety Self Inspection List
  actionGetSelfInpection: (id) => {
    dispatch(fetchSafetySelfInspection(id));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
