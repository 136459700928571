import { CommentMessage, PostMessageType, DirectMessageType } from "../../types";
import { ChatTypes } from "../../enums";
import { SubscribeToMoreOptions } from "apollo-client/core/watchQueryOptions";
import { IActionTypes as IActionPostsTypes } from "../channelsState/posts/types";
import { IActionTypes as IActionCommentsTypes } from "../channelsState/comments/types";
import { ActionTypes as IActionDirectMessagesTypes } from "../directMessagesState/directMessages/types";

export type SubscribeArgs<T> = {
  subscribeTo: (options: SubscribeToMoreOptions) => void;
  type: ChatTypes;
  variables: T;
};

export type Actions = {
  [ChatTypes.operations]: IActionPostsTypes;
  [ChatTypes.comment]: IActionCommentsTypes;
  [ChatTypes.directMessages]: IActionDirectMessagesTypes;
};

export enum OnCreate {
  onCreatePost = "onCreatePost",
  onCreateComment = "onCreateComment",
  onCreateMessage = "onCreateMessage"
}

export type IncomePost = {
  [OnCreate.onCreatePost]: PostMessageType;
};
export type IncomeComment = {
  [OnCreate.onCreateComment]: CommentMessage;
};
export type IncomeDirectMessage = {
  [OnCreate.onCreateMessage]: DirectMessageType;
};

export type IncomeMessageUnion = IncomePost | IncomeComment | IncomeDirectMessage;

export type IncomeMessage = {
  [ChatTypes.operations]?: IncomePost;
  [ChatTypes.comment]?: IncomeComment;
  [ChatTypes.directMessages]?: IncomeDirectMessage;
};

export type ResponseData = {
  [ChatTypes.operations]: OnCreate.onCreatePost;
  [ChatTypes.comment]: OnCreate.onCreateComment;
  [ChatTypes.directMessages]: OnCreate.onCreateMessage;
};
