import { useMutation } from '../../../graphql/hooks';
import { LikePost } from './hooks.types';
import LIKE_POST from '../mutations/likeChannelPost';

export const useLikePost = <L>() => {
  const { error, mutate, saving } = useMutation<LikePost, L>(LIKE_POST);

  return {
    error,
    likePost: mutate,
    saving
  };
};
