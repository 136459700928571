import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Permissions from 'react-redux-permissions';
import styles from './menu.module.scss';
import { uiRouts } from '../../../constants';
// import { getUserHasPermission } from '../../../utils/helpers';


const Menu = ({ transition, onItemClick, intl, profile }) => (
  <div style={{ ...transition }} className={styles.menu}>
    <ul>
      <li>
        <div className={styles.item}>
          <h4 className={styles.home}>{intl.formatMessage({ id: 'topbar.home' })}</h4>
          <Link to={uiRouts.root} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.aerobot' })}</Link>
          { profile.filter(type => type.name === 'Tasks')[0].enabled === 'true'
            ? <Link to={uiRouts.todo} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.todo' })}</Link>
            : ''
          }
          { profile.filter(type => type.name === 'Messenger')[0].enabled === 'true'
            ? <Link to={uiRouts.messenger} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.chat' })}</Link>
            : ''
          }
          <Permissions allowed={['can_modify_airport_settings']}>
            <Link to={uiRouts.settingsOrganization} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.settings' })}</Link>
          </Permissions>
        </div>
      </li>
      <li>
        <div className={styles.item}>
          <h4 className={styles.operations}>{intl.formatMessage({ id: 'topbar.operations' })}</h4>
          { profile.filter(type => type.name === 'AirFields').length > 0
          && profile.filter(type => type.name === 'AirFields')[0].enabled === 'true' ? (
            <Link to={uiRouts.opsAirfieldConditions} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.airfieldconditons' })}</Link>
            )
            : ''
          }
          <Permissions allowed={['view_dashboard']}>
            { profile.filter(type => type.key==='operations_dashboard').length > 0
              && profile.filter(type => type.key==='operations_dashboard')[0].enabled === 'true'
              ? <Link to={uiRouts.opsOperations_Dashboard} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.operations_dashboard' })}</Link>
              :''
        }
          </Permissions>
          {profile.filter(type => type.name === 'Inspections')[0].enabled === 'true'
            ? (
              <Link to={uiRouts.opsInspections} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.inspections' })}</Link>
            )
            : ''
          }
          { profile.filter(type => type.name === 'Work Orders')[0].enabled === 'true'
            ? (
              <Permissions allowed={['view_workorder']}>
                <Link to={uiRouts.opsWorkorders} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.workorders' })}</Link>
              </Permissions>
            )
            : ''
          }
          { profile.filter(type => type.name === 'Assets')[0].enabled === 'true'
            ? (
              <Permissions allowed={['view_asset']}>
                <Link to={uiRouts.opsAssets} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.assets' })}</Link>
              </Permissions>
            )
            : ''
          }
          <Permissions allowed={['view_log']}>
            <Link to={uiRouts.opsLogs} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.opslog' })}</Link>
          </Permissions>
          <Permissions allowed={['view_surfaceshape', 'can_modify_airport_settings']}>
            <Link to={uiRouts.opsSettings} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.settings' })}</Link>
          </Permissions>
        </div>
      </li>
      { profile.filter(
        type => type.name === 'Wildlife Log'
      ).length > 0 && profile.filter(type => type.name === 'Wildlife Log')[0].enabled === 'true' ? (
            <>
              <Permissions allowed={['view_wildlifelogs', 'add_wildlifelogs', 'can_modify_airport_settings']}>
                <li>
                  <div className={styles.item}>
                    <h4 className={styles.wildlife}>{intl.formatMessage({ id: 'topbar.wildlife' })}</h4>
                    <Permissions allowed={['view_wildlifelogs']}>
                      <Link to={uiRouts.wildlifeDashboard} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.wildlife.dashboard' })}</Link>
                    </Permissions>
                    <Permissions allowed={['view_wildlifelogs']}>
                      <Link to={uiRouts.wildlife} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.wildlife' })}</Link>
                    </Permissions>
                    <Permissions allowed={['add_wildlifelogs']}>
                      <a href="https://wildlife.faa.gov/add" onClick={onItemClick} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="shortcuts.wildlife.FAAStrikeReport" defaultMessage="FAA Strike Report" />
                      </a>
                    </Permissions>
                    <Permissions allowed={['can_modify_airport_settings']}>
                      <Link to={uiRouts.wildlifeSettings} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.settings' })}</Link>
                    </Permissions>
                  </div>
                </li>
              </Permissions>
          </>
        ) : '' }
      { profile.filter(
        type => type.name === 'Lease Management'
      ).length > 0 && profile.filter(type => type.name === 'Lease Management')[0].enabled === 'true' ? (
        <Permissions allowed={['view_leaseproperties', 'view_leaseworkorder', 'view_tenants', 'can_modify_airport_settings']}>
          <li>
            <div className={styles.item}>
              <h4 className={styles.lease}>{intl.formatMessage({ id: 'topbar.lease_management' })}</h4>
              <Permissions allowed={['view_leaseproperties']}>
                <Link to={uiRouts.leaseProperties} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.lease.properties' })}</Link>
              </Permissions>
              <Permissions allowed={['view_leaseworkorder']}>
                <Link to={uiRouts.leaseMaintenance} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.lease.maintenance' })}</Link>
              </Permissions>
              <Permissions allowed={['view_tenants']}>
                <Link to={uiRouts.leaseTenants} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.lease.tenants' })}</Link>
              </Permissions>
              <Permissions allowed={['can_modify_airport_settings']}>
                <Link to={uiRouts.leaseSettings} onClick={onItemClick}>{intl.formatMessage({ id: 'shortcuts.settings' })}</Link>
              </Permissions>
            </div>
          </li>
        </Permissions>
        ) : '' }
    </ul>
  </div>
);

export default injectIntl(Menu);
