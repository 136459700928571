import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from '../../../../styles/general.module.scss';

const LogTable = ({ data }) => (
  <table className={`${styles.table} ${styles.evenWidth}`}>
    <thead>
      <tr>
        <th>
          <FormattedMessage id="operations.loglist.date" defaultMessage="Date" />
        </th>
        <th>
          <FormattedMessage id="workorder.detail.request.logged_by" defaultMessage="Logged by" />
        </th>
        <th>
          <FormattedMessage id="wildlife.table.type" defaultMessage="Type" />
        </th>
        <th>
          <FormattedMessage id="wildlife.table.species" defaultMessage="Species" />
        </th>
        <th>
          <FormattedMessage id="wildlife.table.number" defaultMessage="Number" />
        </th>
        <th>
          <FormattedMessage id="wildlife.table.location" defaultMessage="Location" />
        </th>
        <th width="90" />
      </tr>
    </thead>
    <tbody>
      {
        Object.keys(data).map(e => (
          <tr>
            <td className={styles.date}>{(moment(data[e].incident_date)).format('MM/DD/YYYY hh:mm A')}</td>
            <td>{data[e].logged_by && data[e].logged_by.fullname}</td>
            <td>{data[e].wildlife_type}</td>
            <td>{data[e].wildlife_species}</td>
            <td>{data[e].spotted_count}</td>
            <td>{data[e].grid}</td>
            <td>
              <Link to={`/wildlife/${data[e].id}/detail`}>
                <FormattedMessage id="workorders.list.table.action" defaultMessage="View" />
              </Link>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);

LogTable.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default LogTable;
