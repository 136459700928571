import {
  reducer as directMessagesReducer,
  types as directMessagesTypes
} from "./directMessages";
import { GlobalReducer } from "./types";

export const reducer: GlobalReducer = ({ directMessages }, action) => ({
  directMessages: directMessagesReducer(
    directMessages,
    action as directMessagesTypes.IAction
  )
});
