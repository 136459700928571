import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styles from './notams.module.scss';

const NotamTabFilter = ({ onClick }) => {
  const [activeTab, setActiveTab] = useState('4');
  const toggle = (tab, filter) => {
    if (activeTab !== tab) setActiveTab(tab);
    onClick(filter);
  };

  return (
    <div className="float-right">
      <Nav className={styles.notamsLink}>
        <NavItem>
          <NavLink
            className={activeTab === '4' ? styles.active : ''}
            onClick={() => { toggle('4', 'ALL'); }}
          >
            <div className={styles.surfacenotams}>
                        All
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? styles.active : ''}
            onClick={() => { toggle('1', 'TWY'); }}
          >
            <div className={styles.surfacenotams}>
                        Taxiway
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? styles.active : ''}
            onClick={() => { toggle('2', 'RWY'); }}
          >
            <div className={styles.surfacenotams}>
                        Runway
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' ? styles.active : ''}
            onClick={() => { toggle('3', 'OBST'); }}
          >
            <div className={styles.surfacenotams}>
                        Obstructions
            </div>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};


export default NotamTabFilter;
