/* global document */

import { hostName } from '../constants';

function importAll(r) {
  const files = {};
  r.keys().forEach((item) => { files[item.replace('./', '')] = r(item); });
  return files;
}

const images = importAll(require.context('.', true, /\.(png|jpe?g|svg|ico)$/));
const docs = importAll(require.context('.', true, /\.(title|txt)$/));

const titles = [];
const logos = [];
const favicons = [];

Object.keys(images).forEach((image) => {
  const info = image.split('/');
  const domain = info[0];
  const name = info[1].split('.')[0];
  if (name === 'favicon') {
    favicons[domain] = images[image];
  } else if (name === 'logo') {
    logos[domain] = images[image];
  }
});

Object.keys(docs).forEach((doc) => {
  const info = doc.split('/');
  const domain = info[0];
  const name = info[1].split('.')[0];
  titles[domain] = name;
});

export const setTitle = () => {
  if (titles[hostName] === undefined) {
    document.title = titles.default;
  } else {
    document.title = titles[hostName];
  }
};

export const setFavicon = () => {
  let favicon = null;
  if (favicons[hostName] === undefined) {
    favicon = favicons.default;
  } else {
    favicon = favicons[hostName];
  }
  document.getElementById('favicon').href = favicon;
};

export const getLogo = () => {
  let logo = '';
  if (logos[hostName] === undefined) {
    logo = logos.default;
  } else {
    logo = logos[hostName];
  }
  return logo;
};

export const getTitle = () => {
  let title = '';
  if (titles[hostName] === undefined) {
    title = titles.default;
  } else {
    title = titles[hostName];
  }
  return title;
};
