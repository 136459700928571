import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment/min/moment-with-locales';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { getShiftTypeByDate } from '../../../../../utils/helpers';

import defaultAvatar from '../../../../../icons/default_avatar.jpg';
import styles from '../../../inspections.module.scss';


const DataSummary = ({ inspection, answer, shiftTypes }) => {
  const shift = getShiftTypeByDate(moment(inspection.inspection_date).format('YYYY-MM-DDTHH:mm:ssz'), shiftTypes);

  const renderWeatherObs = (obs) => {
    return obs !== undefined ? obs.metar.temperature : '';
  };
  return (
    <div className={styles.datasources}>
      <div className={styles.userInfo}>
        {/* <img src={inspection.inspected_by.picture} alt="" /> */}
        <img src={inspection.inspected_by.image?inspection.inspected_by.image:defaultAvatar} alt="" />
        <div>
          <h2>{inspection.inspected_by.fullname}</h2>
          <p>{inspection.inspected_by.email}</p>
          {/* <a href="/#">View Route</a>
        <a href="/#">More Detail</a> */}
        </div>
      </div>
      <div className={styles.column}>
        <h3>
          <FormattedDate
            value={inspection.inspection_date}
            month="short"
            day="numeric"
            year="numeric"
          />
        </h3>
        <p><FormattedMessage id="inspection.inspection_date" defaultMessage="INSPECTION-DATE" /></p>
        {/* <p>start {shift.start.slice(0, 5)} {parseInt(shift.start) < 12 ? 'AM' : 'PM'}</p>
      <p>end {shift.end.slice(0, 5)} {parseInt(shift.end) < 12 ? 'AM' : 'PM'}</p> */}
      </div>
      <div className={styles.column}>
        <h3>
          <FormattedDate
            value={inspection.inspection_date}
            hour="numeric"
            minute="numeric"
          />
        </h3>
        <p><FormattedMessage id="inspection.inspection_time" defaultMessage="INSPECTION-TIME" /></p>
        {/* <p>start {shift.start.slice(0, 5)} {parseInt(shift.start) < 12 ? 'AM' : 'PM'}</p>
        <p>end {shift.end.slice(0, 5)} {parseInt(shift.end) < 12 ? 'AM' : 'PM'}</p> */}
      </div>
      <div className={styles.column}>
        <h2>{shift && shift.name}</h2>
        <p><FormattedMessage id="inspection.inspection_shift" defaultMessage="Shift" /></p>
      </div>
      <div className={styles.column}>
        <div dangerouslySetInnerHTML={{ __html: renderWeatherObs(answer.weather_conditions.current_obs) }}/>
        <p>--</p>
      </div>
      <div className={styles.column}>
        <h2 className={styles.danger}>
          {inspection.issues}
        </h2>
        <p><FormattedMessage id="inspection.oppen_issues" defaultMessage="OPEN ISSUES" /></p>
      </div>
    </div>
  );
};

DataSummary.propTypes = {
  inspection: PropTypes.shape({}).isRequired,
  answer: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired
};
export default DataSummary;
