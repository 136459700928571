import styled from 'styled-components';
import { ChatTypes } from '../../../enums';

type WrapperProps = {
  viewType?: ChatTypes;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;

  .user-name {
    display: flex;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text.darkest};

    .time {
      padding: 0 15px;
      color: ${({ theme }) => theme.colors.text.default};
    }
  }
`;
