import styled from 'styled-components';

type WrapperProps = {
  noMargin?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  margin-bottom: ${({ noMargin }): string => (noMargin ? 'unset' : '8px')};
  padding-top: ${({ noMargin }): string => (noMargin ? 'unset' : '19px')};

  &.no-margin {
    margin-bottom: 0px;
  }
`;
