import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import ProfileView from '../components/topbar/profile/View/ProfileView';
import ProfileEdit from '../components/topbar/profile/View/ProfileEdit';
import ChangePasswordForm from '../components/topbar/profile/View/ChangePasswordForm';
import ChangePinForm from '../components/topbar/profile/View/ChangePinForm';

// eslint-disable-next-line react/prefer-stateless-function
class ProfileLayout extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}`} component={ProfileView} />
        <Route exact path={`${match.url}/edit`} component={ProfileEdit} />
        <Route exact path={`${match.url}/changePassword`} component={ChangePasswordForm} />
        <Route exact path={`${match.url}/changePin`} component={ChangePinForm} />
      </Switch>
    );
  }
}


const mapStateToProps = state => ({
  currentModule: state.general.currentModule
});

export default connect(
  mapStateToProps
)(ProfileLayout);
