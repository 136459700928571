import React from 'react';

import { Wrapper } from './ActiveChat.styles';
import { DirectChatBox } from '../ChatBox';
import { ActiveDirectMessagesHeader } from '../../components';
import { ChatListUser, DirectChatBoxProps } from '../../types';

type ActiveChatProps = {
  fromUser: ChatListUser;
  roomID?: string;
} & DirectChatBoxProps;

const ActiveDirectChat = React.memo<ActiveChatProps>(
  ({
    fromUser,
    type,
    topicsConnected,
    roomID,
    createMsg,
    currentUser,
    messagesList,
    loadMore,
    deleteMessage,
    hasMore,
    editedMessage,
    updateMessage,
    editedMessageId
  }) => (
    <>
      <Wrapper>
        {fromUser && (
          <ActiveDirectMessagesHeader
            type={type}
            roomID={roomID}
            userDetails={fromUser}
          />
        )}
        <DirectChatBox
          type={type}
          createMsg={createMsg}
          currentUser={currentUser}
          toUser={fromUser}
          messagesList={messagesList}
          channelId={roomID}
          topicsConnected={topicsConnected}
          loadMore={loadMore}
          deleteMessage={deleteMessage}
          hasMore={hasMore}
          editedMessage={editedMessage}
          editedMessageId={editedMessageId}
          updateMessage={updateMessage}
        />
      </Wrapper>
    </>
  )
);

export default ActiveDirectChat;
export { ActiveDirectChat };
