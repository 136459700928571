import React from 'react';
import { MessageOptions } from '../../components';
import { MENU_IDS } from '../../components/MessageOptions/assets';
import { directMessagesActions } from '../../state/directMessagesState/actions';
import { useDirectMessagesState } from '../../state/directMessagesState';
import { DeleteMessagesTypes } from '../../types';
import { ChatTypes } from '../../enums';

type DirectMessageActionsProps = {
  type: ChatTypes;
  messageId: string;
  deleteMessage: DeleteMessagesTypes;
};


const DirectMessageActions: React.FC<DirectMessageActionsProps> = ({
  type,
  messageId,
  deleteMessage
}) => {
  const { dispatch } = useDirectMessagesState();
  const onClick = (key: MENU_IDS) => {
    switch (key) {
      case MENU_IDS.edit: {
        directMessagesActions.setEditedMessage(dispatch, {
          editedDMID: messageId
        });
        break;
      }
      case MENU_IDS.delete: {
        deleteMessage[type]({ variables: { messageId } });
        break;
      }
      default:
    }
  };

  return (
    <MessageOptions
      directMessage
      onClick={onClick}
      isAuthor
      hideIcons={[MENU_IDS.insertSmile, MENU_IDS.comment]}
    />
  );
};

export default DirectMessageActions;
export { DirectMessageActions };
