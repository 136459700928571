import { ChatTypes } from '../../enums';
import DELETE_COMMENT from '../mutations/deleteComment';
import DELETE_POST from '../mutations/deletePost';
import { useMutation } from '../../../graphql/hooks';
import { PostMessageType } from '../../types';

export const useDeletePostMutation = <T>(type: ChatTypes) => {
  const obj = {
    [ChatTypes.comment]: DELETE_COMMENT,
    [ChatTypes.operations]: DELETE_POST
  };

  const {
    error,
    mutate
    // saving
  } = useMutation<PostMessageType, { variables: T }>(obj[type]);

  return {
    error,
    deleteItem: mutate
    // test,
    // setTest
  };
};
