import * as yup from 'yup';
import { toast } from 'react-toastify';
import { MessageFormValues } from '../types';

export const SUPPORTED_FORMATS = '.jpg, .jpeg, .gif, .png, .doc, .docx, .pdf, .svg, .odp, .pps, .ppt, .pptx, .ods, .xlr, .xls, .xlsx, .odt';
const FILE_SIZE = 10;
export const MAX_FILE_COUNT = 5;

export const messageSchema = yup.object().shape({
  content: yup.string().label('Content')
});

export const validateAttachments = (attachment: File): boolean => {
  if (attachment.size / 1024 / 1024 > FILE_SIZE) {
    toast.error(`Each file upto ${FILE_SIZE}MB of size`);
    return false;
  }

  return true;
};

export const validateMessage = ({ content }: MessageFormValues): boolean => content?.trim().length !== 0;
