import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './fixedfields.module.scss';
import { fetchLocationGridImage } from '../../redux/actions';

class GridMap extends Component {
  componentDidMount() {
    const { actionFetchGridMap } = this.props;
    actionFetchGridMap();
  }

  render() {
    const { gridMapImage, showIn, onClose } = this.props;
    return (
    <>
      {gridMapImage && (
      <Modal isOpen={showIn} toggle={onClose} className={styles.GridMapModal}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <button onClick={onClose} type="button">&times;</button>
          </div>
          <iframe
            src={gridMapImage[0].attachments[0].url}
            title="grid map"
            width="100%"
            height="800"
          />
        </div>
      </Modal>
      )}
    </>
    );
  }
}

GridMap.propTypes = {
  showIn: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

GridMap.defaultProps = {
  showIn: false
};

const mapStateToProps = state => ({
  gridMapImage: state.wildlife_log.gridMapImage
});

const mapDispatchToProps = dispatch => ({
  actionFetchGridMap: () => {
    dispatch(fetchLocationGridImage());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridMap);
