import {Component} from "react";
import React from "react";
import styles from "./ChatBox.module.scss";
import {Link} from "react-router-dom";
import hamburger from '../../../components/topbar/icons/menu.svg';
import Dropdown from 'react-bootstrap/Dropdown';

class Messages extends Component {

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const {messages} = this.props;
    console.log(messages); 
    return (
        <div className={styles.chatboxContainer}> 
          <ul className={styles.messagesList} key={Date.now()}>          
           {messages.map(m => this.renderMessage(m))}            
          </ul>
          <div ref={el => {this.el = el; }} />
        </div>         
    );
  }

  renderMessage(message) {
    const { member, user, text} = message;
    const messageFromMe = user === member.username;
    return (
      <li className={styles.messagesMessage} key={text} style={!messageFromMe ? {flexDirection: "row-reverse"} : {}}>     
        <div className={styles.messageContent}>          
          {member.username === "You"?
            <div className={styles.currentMember}>
              {text}
            </div>:
            text.map((message)=> 
              <>
                {message.type === "link_options"? 
                <><span className={styles.bot} style={{marginRight: '50%'}}>{message.message}</span>
                  {message.dateOptions.map(option => 
                  <Link to={option.link} target="_blank" style={{cursor:"pointer"}} className={`${styles.dates} ${styles.bot}`} onClick={() => {
                      localStorage.setItem('startdate', option.fdate);                    
                      localStorage.setItem('enddate', option.tdate);
                      localStorage.setItem('range', option.name);
                      window.open(option.link, "_blank");                     
                  }}>{option.name}</Link>)}                  
                </>:message.type === "dropdown"? 
                <Dropdown style={{width:'220px', display: 'contents'}}> 
                  <Dropdown.Toggle bsPrefix={`${styles.messagesMessage} ${styles.messageContent} ${styles.options}`}> <img src={hamburger} alt="menu" style={{marginBottom: '3px', marginRight:'4px'}}/> {message.message}</Dropdown.Toggle> 
                    <Dropdown.Menu className={styles.dropdown} id={styles.menu}>                       
                       {message.options.map((option, index)=>                        
                        <>                          
                          <Dropdown.Item  style={{padding: '10px'}} className={styles.dropdown} as="button" eventKey={index} onClick={()=>{this.props.selectMessage(option + ' ' + message.message,option)}}>{option}</Dropdown.Item>                                                   
                          {index !== message.options.length - 1 ? <Dropdown.Divider />:<div></div>}
                        </>
                      )}                    
                    </Dropdown.Menu>
                  </Dropdown>:message.type === "text"?
                  <span className={styles.bot}>{message.message}</span>:
                  message.type === "plaintext"?<p>{message.message}</p>:message.type === "link"?
                  <span style={{cursor:"pointer"}} ><span className={styles.bot}>Here is a link</span><Link to={message.link} target="_blank" className={`${styles.messageContent} ${styles.links}`}>{message.message}</Link></span>:                
                  <span onClick={()=>{
                    this.props.selectMessage(message.message,message.dis_message);                    
                  }} style={{cursor:"pointer"}} className={`${styles.messageContent} ${styles.options}`}>{message.message}</span>                                 
                }                                                                           
              </>      
            )             
          }            
        </div>       
      </li>
    );    
  }
}

export default Messages;