import { ApolloError } from 'apollo-client';
import { useMemo } from 'react';
import { useQuery } from '../../../graphql/hooks';
import { ChannelArray, ChannelsVar, ChannelType } from '../../types';
import FETCH_CHANNELS_QUERY from '../queries/userChannels';
import { useRDetails } from './useRDetails';

type UseChannelsType = {
  (
    variablesData: {
      limit: number,
      nextToken: string
    },
    onCompleted: (data: { listUserChannels: { items: ChannelType[] } }) => void
  ): {
    data: ChannelArray;
    error: ApolloError;
    loading: boolean;
    currentUser: { userId: string };
  };
};

export const useChannels: UseChannelsType = (variablesData, onCompleted) => {
  const { userId, airportId } = useRDetails();

  const { data, error, loading } = useQuery<ChannelArray, ChannelsVar>(
    FETCH_CHANNELS_QUERY,
    {
      variables: {
        userId,
        airportId,
        ...variablesData
      },
      onCompleted
    }
  );

  const currentUser = useMemo(() => ({ userId }), [userId]);

  return {
    data,
    error,
    loading,
    // TODO needed rework user handling & getting user data
    // tslint:disable-next-line: object-literal-sort-keys
    currentUser
  };
};
