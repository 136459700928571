import {
  FETCH_WORKORDER_LIST,
  FETCH_WORKORDER,
  CREATE_WORKORDER,
  CLEAR_WORKORDER,
  FETCH_WORKORDER_SCHEMA,
  CREATE_MAINTENANCE,
  CREATE_OPERATIONS,
  UPDATE_SCHEMAS,
  SAVE_ASSIGNMENT,
  FETCH_NOTAMS,
  PRINT_PDF,
  CLEAR_WORKORDER_DETAIL,
  EDIT_WORKORDER,
  FETCH_WORKORDER_LIST_SUMMARY
} from './types';

const BACKEND_API = 'BACKEND_API';

export const fetchWorkOrderList = (page, query, filters) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/work_orders/?${page ? 'page=' : ''}${page || ''}${query ? '&query=' : ''}${query || ''}${filters ? '&filters=' : ''}${filters || ''}`,
    ...FETCH_WORKORDER_LIST
  }
});

export const fetchWorkOrderListSummary = (query, filters, shifts, s, f) => ({
  // http://localhost:8000/api/work_orders/list_workorders/?filterslist=Maintenance~
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/work_orders/list_workorders/?${query ? 'query=' : ''}${'&filterslist='}${filters || ''}${shifts? '&shifts=':''}${shifts || ''}${s ? '&s=': ''}${s||''}${f?'&f=':''}${f||''}`,
    ...FETCH_WORKORDER_LIST_SUMMARY
  }
})

export const fetchWorkOrder = id => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/work_orders/${id}/`,
    ...FETCH_WORKORDER
  }
});

export const createWorkOrder = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/work_orders/',
    ...CREATE_WORKORDER
  }
});

export const clearActionResult = () => ({
  type: CLEAR_WORKORDER
});

export const clearworkorderDetail = () => ({
  type: CLEAR_WORKORDER_DETAIL
});

export const fetchWorkOrderSchema = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/work_orders/get_schema/',
    ...FETCH_WORKORDER_SCHEMA
  }
});

export const updateWorkOrderSchemas = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/work_orders/update_schemas/',
    ...UPDATE_SCHEMAS
  }
});

export const sendMaintenanceReview = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: `/work_orders/${id}/maintenance_review/`,
    ...CREATE_MAINTENANCE
  }
});

export const sendOperationsReview = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: `/work_orders/${id}/operations_review/`,
    ...CREATE_OPERATIONS
  }
});

export const saveAssignment = (type, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data: { type, ...data },
    url: '/work_orders/assignment/',
    ...SAVE_ASSIGNMENT
  }
});

export const fetchNotams = (icaoId) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/work_orders/get_notams/`,
    ...FETCH_NOTAMS
  }
});

export const printPdf = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    url: `/work_orders_data/${id}/workorder_data_view/`,
    data,
    ...PRINT_PDF
  }
});

export const editWorkOrder = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'patch',
    data,
    url: `/work_orders/${id}/`,
    ...EDIT_WORKORDER
  }
}) 
