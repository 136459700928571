import styled, { css } from 'styled-components';
import { ChatTypes } from '../../enums';

type WrapperProps = {
  viewType: ChatTypes;
  bordered?: boolean;
  isAttachments?: boolean;
  isMessageOwner?: boolean;
  savingMessage?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  ${({ savingMessage }) => savingMessage
    && css`
      filter: blur(3px);
    `};
  ${({ isMessageOwner = false, viewType }) => (isMessageOwner
    ? css`
          align-items: flex-end;
        `
    : viewType === ChatTypes.directMessages
        && css`
          align-items: flex-start;
        `)};

  .message-container {
    ${({ bordered, isAttachments }) => bordered
      && css`
        margin: 5px 0;
        height: ${isAttachments ? '230px' : 'auto'};
        overflow: auto;
      `};
    position: relative;
    ${({ isMessageOwner = false }) => isMessageOwner
      && css`
        direction: rtl;
      `};

    padding: ${({ viewType }) => (viewType === ChatTypes.comment ? '5px' : '5px')}
      20px;
    max-width: ${({ viewType }) => (viewType === ChatTypes.directMessages ? '867px' : '100%')};

    :hover {
      background-color: ${({ theme }) => theme.colors.background.default};
    }
  }

  .info {
    font-size: 12px;
    line-height: 2.08;
    letter-spacing: 0.08px;
    color: ${({ theme }) => theme.colors.text.light};
  }
  .content-wrapper {
    padding-left: 40px;
  }

  .replies {
    display: flex;
    align-items: center;

    span {
      padding: 0 5px 1px 0;
      font-size: 12px;
      line-height: 2.08;
      letter-spacing: 0.08px;
      color: #8c9ea7;
    }

    hr {
      margin: unset;
      zoom: 2;
      background: ${({ theme }) => theme.colors.border.light};
      flex-grow: 1;
    }
  }
`;
