/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HOC as Permissions } from 'react-redux-permissions';
import moment from 'moment';
import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';

import {
  addInspection, fetchInspectionListForEdit,
  clearActionResult, fetchInspectionForEdit,
  editInspection, clearInspection, discardInspectionDraft,
  fetchTemplates, fetchTemplate, updateTemplateVersion,
  updateInspectionVersion, searchUser
} from '../redux/actions';

import { fetchRules } from '../../toDo/redux/actions';
import { fetchShiftTypes, fetchRoles, fetchUsers } from '../../settings/redux/actions';
import { showConfirmModal, hideModal } from '../../../general_redux/actions';
/** ******************************************************************
 *  Components import
 * ***************** */

import Step0 from './steps/Step0/Step0';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import Step3 from './steps/Step3/Step3';
import Step4 from './steps/Step4/Step4';

import Button from '../../../components/button';
import Clickable from '../../../components/clickable/Clickable';
import ProgressBar from './components/ProgressBar';
import Forbidden from '../../Forbidden';
import Loading from '../../../components/loading';
/** ******************************************************************
 *  Assets import
 * ************* */
import styles from './create.module.scss';

const initialInspectionData = {
  info: {
    name: '',
    icon: 'icon-2'
  },
  details: {
    fields: [],
    additionalInfo: ''
  },
  inspectionChecklist: []
};

class InspectionBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      lastIdAdded: 0,
      apiStatus: undefined,
      errors: false,
      fielderror: false,
      airport_changes: {},
      loadingStatus: false,
      assignee: {
        assigned_user: undefined,
        assigned_role: undefined,
        currentUser: '',
        assigneeType: 'user'
      },
      inspectionAssignee: {
        assigned_user: undefined,
        assigned_role: undefined,
        currentUser: '',
        assigneeType: 'none'
      },
      toggle: false,
      assignees: [{ key: 'none', name: this.props.intl.formatMessage({ id: 'Asset.settings.None' }) },
        { key: 'user', name: this.props.intl.formatMessage({ id: 'inspections.step1.user' }) },
        { key: 'role', name: this.props.intl.formatMessage({ id: 'inspections.step1.role' }) }],
      sectionError: false,
      form_access: undefined,
      itemError: false,
      inspectionError: '',
      showFieldErrors: false,
      sendEmail: false,
      emailFieldError: false,
      ...initialInspectionData
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToInspectionEdit = this.goToInspectionEdit.bind(this);
    this.handleInfoChange = this.handleInfoChange.bind(this);
    this.handleFieldsChange = this.handleFieldsChange.bind(this);
    this.handleNewItem = this.handleNewItem.bind(this);
    this.handleAddEntry = this.handleAddEntry.bind(this);
    this.handleFieldsOrderChange = this.handleFieldsOrderChange.bind(this);
    this.handleChangeChecklistName = this.handleChangeChecklistName.bind(this);
    this.handleChangeEntry = this.handleChangeEntry.bind(this);
    this.finish = this.finish.bind(this);
    this.handleGoInspections = this.handleGoInspections.bind(this);
    this.discardDraft = this.discardDraft.bind(this);
    this.openConfirmDialog = this.openConfirmDialog.bind(this);
    this.handleAirportChanges = this.handleAirportChanges.bind(this);
    this.handleUpdateVersion = this.handleUpdateVersion.bind(this);
    this.onSelectShifts = this.onSelectShifts.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if ((props.inspection.id && props.inspection.id !== state.id)
      || (props.fetchInspectionAction.success)) {
      props.actionClear();
      const maxChecklistId = props.inspection.checklist.reduce(
        (max, i) => (Number(i.id) > max ? Number(i.id) : max),
        Number(props.inspection.checklist[0].id)
      );
      let inspectionAssignee = {};
      let emailStatus = false;
      if (props.inspection.send_email) {
        if (props.inspection.send_email ===1) {
          emailStatus = true;
          if (props.inspection.inspection_assignee.type === 'user') {
            const user = props.inspection.inspection_assignee.assigned_user;
            inspectionAssignee = {
              assigneeType: 'user',
              assigned_user: user,
              assigned_role: undefined,
              currentUser: ''
            };
          } else if (props.inspection.inspection_assignee.type==='role') {
            const role = props.inspection.inspection_assignee.assigned_role;
            inspectionAssignee = {
              assigneeType: 'role',
              assigned_user: undefined,
              assigned_role: role,
              currentUser: ''
            };
          }
        } else if (props.inspection.send_email === 0) {
          emailStatus = false;
          inspectionAssignee = state.inspectionAssignee;
        }
      } else {
        emailStatus = false,
        inspectionAssignee= {
          assigned_user: undefined,
          assigned_role: undefined,
          currentUser: '',
          assigneeType: 'user'
        };
      }
      return {
        ...state,
        id: props.inspection.id,
        info: {
          name: props.inspection.info.title,
          icon: props.inspection.info.icon
        },
        toggle: props.inspection.access_type === 1,
        form_access: props.inspection.form_access,
        assignee: {
          assigned_user: props.inspection.task.assigned_user
            ? props.inspection.task.assigned_user.id
            : undefined,
          assigned_role: props.inspection.task.assigned_role
            ? props.inspection.task.assigned_role.id
            : undefined,
          currentUser: props.inspection.task.assigned_user
            ? props.inspection.task.assigned_user.fullname
            : undefined,
          // assigneeType: 'none'
          // eslint-disable-next-line no-nested-ternary
          assigneeType: props.inspection.task.assigned_user ? 'user' : props.inspection.task.assigned_role ? 'role' : 'none'
        },
        details: { ...props.inspection.details },
        inspectionChecklist: [...props.inspection.checklist],
        airport_changes: props.inspection.airport_changes,
        currentStep: 1,
        lastIdAdded: maxChecklistId,
        sendEmail: emailStatus,
        inspectionAssignee
      };
    }
    if (props.template.id && props.templateAction.success) {
      props.actionClear();
      const maxChecklistId = props.template.checklist.reduce(
        (max, i) => (Number(i.id) > max ? Number(i.id) : max),
        Number(props.template.checklist[0].id)
      );
      return {
        id: props.template.id,
        info: {
          name: props.template.info.title,
          icon: props.template.info.icon
        },
        details: { ...props.template.details },
        inspectionChecklist: [...props.template.checklist],
        currentStep: (props.template.id !== state.id ? 1 : state.currentStep),
        lastIdAdded: maxChecklistId
      };
    }

    if (props.createInspectionAction) {
      if (props.createInspectionAction.success === true) {
        return { ...state, apiStatus: props.createInspectionAction.success, loadingStatus: false, inspectionError: props.createInspectionAction.data };
      }
      return { ...state, apiStatus: props.createInspectionAction.success, inspectionError: props.createInspectionAction.data };
    }
    return state;
  }

  componentDidMount() {
    const {
      actionFetchForEdit,
      actionFetchRules,
      actionFetch,
      actionFetchShifts,
      shiftTypes,
      actionSearchUser,
      userlist,
      actionFetchUsers,
      history: { location: { state } },
      match: { params: { id } } } = this.props;

    actionSearchUser('');
    if (id) {
      this.setState({ currentStep: 1 });
      actionFetchForEdit(id);
    } else {
      actionFetch();
      // actionFetchTemplates();
      if (state && state.intent === 'CreateInspectionForm') {
        this.setState({ currentStep: 1 });
      }
    }
    if (!shiftTypes.length) actionFetchShifts();
    if (!userlist.length) actionFetchUsers();
    actionFetchRules();
    actionFetch();
  }


  componentWillUnmount() {
    const { actionClear, inspectionClear } = this.props;
    actionClear();
    inspectionClear();
  }

  // eslint-disable-next-line react/sort-comp
  next() {
    const { currentStep, info: { name }, assignee: { assigned_user, assigned_role, currentUser, assigneeType } } = this.state;
    if (currentStep === 1 && name === '') {
      this.setState({ errors: true });
    } else if (currentStep === 1 && assigneeType !== 'none' && (((assigneeType === 'user' && assigned_user === undefined) || (assigned_user && currentUser === '')) || (assigneeType === 'role' && (assigned_role === undefined || assigned_role === '')))) {
      this.setState({ fielderror: true });
    } else {
      this.setState(prevState => (
        {
          currentStep: Math.min(4, prevState.currentStep + 1),
          errors: false,
          fielderrors: false
        }
      ));
    }
  }

  prev = () => {
    this.setState((prevState) => {
      let newState = {
        currentStep: Math.max(0, prevState.currentStep - 1)
      };
      if (prevState.currentStep === 1) {
        newState = {
          ...newState,
          ...initialInspectionData
        };
      }
      return newState;
    }, () => {
      const { currentStep } = this.state;
      const { history, inspectionClear } = this.props;

      if (currentStep === 0) {
        inspectionClear();
        history.push('/ops/inspections/new');
        this.setState({ id: undefined, form_access: undefined });
        this.setState({ errors: false, fielderror: false, toggle: false });
      }
    });
  }

  goToInspectionEdit(id) {
    const { history, actionFetchForEdit } = this.props;
    actionFetchForEdit(id);
    history.push(`/ops/inspections/${id}/edit`);
  }

  handleAssigneeChanged = (value) => {
    this.setState(prevState => ({
      ...prevState,
      assignee: {
        ...prevState.assignee,
        currentUser: value
      }
    }));
  }

  onAssignTypeChanged = (value) => {
    this.setState(prevState => ({
      ...prevState,
      assignee: {
        ...prevState.assignee,
        assigneeType: value
      }
    }));
    if (value === 'none') {
      this.setState(prevState => ({
        ...prevState,
        assignee: {
          ...prevState.assignee,
          assigneeType: value,
          assigned_user: undefined,
          assigned_role: undefined,
          currentUser: ''
        }
      }));
    }
  }

  handleAssigneeSelected = (value, userId) => {
    this.setState(prevState => ({
      assignee: {
        ...prevState.assignee,
        assigned_user: userId,
        assigned_role: undefined,
        currentUser: value
      }
    }));
  }

  handleRoleChange = (value) => {
    this.setState(prevState => ({
      assignee: {
        ...prevState.assignee,
        assigned_user: undefined,
        assigned_role: value,
        currentUser: ''
      }
    }));
  }

  handleEmailNotifications = () => {
    const { sendEmail } = this.state;
    this.setState({ sendEmail: !sendEmail });
  };

  handleInfoChange(type, value) {
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        [type]: value
      },
      airport_changes: {
        ...prevState.airport_changes,
        [type]: value
      }
    }));
  }

  handleFieldsChange(type, value) {
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [type]: value
      }
    }));
  }

  handleAirportChanges(type, value) {
    this.setState(prevState => ({
      airport_changes: {
        ...prevState.airport_changes,
        [type]: value
      }
    }));
  }

  handleFieldsOrderChange(inspectionChecklist) {
    this.setState(prevState => ({
      inspectionChecklist,
      airport_changes: {
        ...prevState.airport_changes,
        inspectionChecklist
      }
    }));
  }

  handleNewItem() {
    const { lastIdAdded } = this.state;
    const newItem = {
      id: `${lastIdAdded + 1}`,
      type: 'inspection',
      title: `Inspection Field ${lastIdAdded + 1}`,
      status_options: {
        pass: 'Satisfactory',
        fail: 'Unsatisfactory'
      },
      checklist: [
        { key: 'CH1', value: 'Checklist 1', shifts: [] },
        { key: 'CH2', value: 'Checklist 2', shifts: [] }
      ],
      required: true,
      order: lastIdAdded
    };

    this.setState(prevState => ({
      inspectionChecklist: [
        ...prevState.inspectionChecklist,
        newItem
      ],
      airport_changes: {
        ...prevState.airport_changes,
        inspectionChecklist: {
          ...prevState.airport_changes.inspectionChecklist,
          newItem
        }
      },
      lastIdAdded: prevState.lastIdAdded + 1
    }));
  }

  handleAddEntry(itemid) {
    const { inspectionChecklist } = this.state;

    const index = inspectionChecklist.map(e => e.id).indexOf(itemid);
    const initialMax = inspectionChecklist[index].checklist[0] ? Number(
      inspectionChecklist[index].checklist[0].key.slice(2)
    ) : 0;
    const maxItemId = inspectionChecklist[index].checklist.reduce(
      (max, i) => (Number(i.key.slice(2)) > max ? Number(i.key.slice(2)) : max),
      initialMax
    );

    inspectionChecklist[index].checklist.push(
      { key: `CH${maxItemId + 1}`, value: 'New checklist item' }
    );

    this.setState(prevState => ({
      inspectionChecklist,
      airport_changes: {
        ...prevState.airport_changes,
        inspectionChecklist
      }
    }));
  }

  handleChange = () => {
    const { toggle } = this.state;
    if (toggle === false) {
      this.setState({ form_access: undefined });
    }
    this.setState({
      toggle: !toggle
    });
  }

  handleChangeOption = (event) => {
    this.setState({ form_access: event });
  }

  handleOnclick = (data, id, type) => {
    const { form_access } = this.state;
    const option = form_access.filter(e => e.id === id && e.type === type);
    const index = option[0].permissions.indexOf(data);
    if (index !== -1) {
      option[0].permissions.splice(index, 1);
    } else {
      option[0].permissions.push(data);
    }
    const view = option[0].permissions.includes('view');
    if (data === 'edit' && !view) {
      const ViewIndex = option[0].permissions.indexOf('view');
      if (ViewIndex !== -1) {
        option[0].permissions.splice(ViewIndex, 1);
      } else {
        option[0].permissions.push('view');
      }
    }
    const filteredData = form_access.map(obj => option.find(o => o.id === obj.id && o.type === obj.type) || obj);
    this.setState({ form_access: filteredData });
  }

  handleChangeEntry(fieldId, entryId, value) {
    const { inspectionChecklist, lastIdAdded } = this.state;
    const index = inspectionChecklist.map(e => e.id).indexOf(fieldId);
    let changes = []; let lastID = lastIdAdded;

    if (value === '') {
      inspectionChecklist[index].checklist = inspectionChecklist[index]
        .checklist.filter(e => e.key !== entryId);
      changes = [...inspectionChecklist, { id: fieldId, hidden: true, template: true }];
    } else {
      const index2 = inspectionChecklist[index].checklist.map(e => e.key).indexOf(entryId);
      inspectionChecklist[index].checklist[index2].value = value;
      inspectionChecklist[index].id = `${lastIdAdded + 1}`;
      delete inspectionChecklist[index].template;
      lastID += 1;
      changes = [...inspectionChecklist, { id: fieldId, hidden: true, template: true }];
    }
    this.setState(prevState => ({
      inspectionChecklist,
      lastIdAdded: lastID,
      airport_changes: {
        ...prevState.airport_changes,
        inspectionChecklist: changes
      }
    }));
  }

  onSelectShifts(fieldId, entryId, shifts) {
    const { inspectionChecklist } = this.state;
    const index = inspectionChecklist.map(e => e.id).indexOf(fieldId);
    const index2 = inspectionChecklist[index].checklist.map(e => e.key).indexOf(entryId);
    inspectionChecklist[index].checklist[index2].shifts = shifts;
    this.setState({
      inspectionChecklist
    });
  }

  handleChangeChecklistName(itemid, value) {
    let { inspectionChecklist } = this.state;
    const { lastIdAdded } = this.state;

    let changes = [];
    let lastID = lastIdAdded;

    if (value === '') {
      inspectionChecklist = inspectionChecklist.filter(e => e.id !== itemid);
      changes = [...inspectionChecklist, { id: itemid, hidden: true, template: true }];
    } else {
      const index = inspectionChecklist.map(e => e.id).indexOf(itemid);
      inspectionChecklist[index].title = value;
      inspectionChecklist[index].id = `${lastIdAdded + 1}`;
      delete inspectionChecklist[index].template;
      lastID += 1;
      changes = [...inspectionChecklist, { id: itemid, hidden: true, template: true }];
    }

    this.setState(prevState => ({
      inspectionChecklist,
      lastIdAdded: lastID,
      airport_changes: {
        ...prevState.airport_changes,
        inspectionChecklist: changes
      }
    }));
  }

  // eslint-disable-next-line no-dupe-class-members
  next(id) {
    const { currentStep, info: { name }, inspectionChecklist,
      assignee: { assigned_user, assigned_role, currentUser, assigneeType },
      sendEmail,
      inspectionAssignee
    } = this.state;
    if (id && (currentStep === 0)) {
      // actionFetchTemplate(id);
    }

    if (currentStep === 0) {
      this.setState({ assignee: {
        assigned_user: undefined,
        assigned_role: undefined,
        currentUser: '',
        assigneeType: 'none'
      },
      form_access: undefined,
      sendEmail: false,
      inspectionAssignee: {
        assigned_user: undefined,
        assigned_role: undefined,
        currentUser: '',
        assigneeType: 'user'
      }
      });
    }
    if (currentStep === 1 && name === '') {
      this.setState({ errors: true });
    } else if (currentStep === 1 && assigneeType !== 'none' && (((assigneeType === 'user' && assigned_user === undefined) || (assigned_user && currentUser === '')) || (assigneeType === 'role' && (assigned_role === undefined || assigned_role === '')))) {
      this.setState({ fielderror: true });
    } else if (currentStep === 1 && (sendEmail === true && inspectionAssignee.assigneeType !== 'none' && ((inspectionAssignee.assigneeType === 'user' && inspectionAssignee.assigned_user === undefined) || (inspectionAssignee.assigneeType === 'role' && inspectionAssignee.assigned_role === undefined)))) {
      this.setState({ emailFieldError: true });
    } else if (currentStep === 3 && inspectionChecklist.length === 0) {
      this.setState({ sectionError: true });
    } else if (currentStep === 3 && inspectionChecklist.length > 0) {
      let flag = false;
      inspectionChecklist.forEach((category) => {
        if (!category.checklist.length) {
          this.setState({ itemError: true });
          flag= true;
        }
      });
      if (!flag) {
        this.setState(prevState => (
          { currentStep: Math.min(4, prevState.currentStep + 1),
            sectionError: false,
            itemError: false
          }
        ));
      }
    } else {
      this.setState(prevState => (
        { currentStep: Math.min(4, prevState.currentStep + 1),
          errors: false,
          sectionError: false,
          fielderror: false,
          emailFieldError: false
        }
      ));
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  prev() {
    this.setState((prevState) => {
      let newState = {
        currentStep: Math.max(0, prevState.currentStep - 1)
      };
      if (prevState.currentStep === 1) {
        newState = {
          ...newState,
          ...initialInspectionData
        };
      }
      return newState;
    }, () => {
      const { currentStep } = this.state;
      const { history, inspectionClear } = this.props;

      if (currentStep === 0) {
        inspectionClear();
        history.push('/ops/inspections/new');
        this.setState({ id: undefined });
        this.setState({ errors: false, fielderror: false });
      }
    });
  }

  // eslint-disable-next-line no-dupe-class-members
  goToInspectionEdit(id) {
    const { history, actionFetchForEdit } = this.props;
    actionFetchForEdit(id);
    history.push(`/ops/inspections/${id}/edit`);
  }

  finish(isDraft) {
    this.setState({ loadingStatus: true });
    const {
      info: { name, icon, rule },
      assignee: { assigned_role, assigned_user },
      form_access, toggle,
      details: { fields, additionalInfo },
      inspectionChecklist,
      airport_changes,
      sendEmail,
      inspectionAssignee
    } = this.state;
    const { actionSave, actionEdit,
      match: { params: { id } } } = this.props;
      
    const { template, inspection } = this.props;
    const res = {
      id,
      title: name,
      icon,
      additionalInfo,
      task: {
        rule,
        assigned_role,
        assigned_user,
        email_date: moment().format()
      },
      status: (isDraft ? 0 : 1),
      email_status: (sendEmail ? 1 : 0),
      access_type: (toggle ? 1: 0),
      form_access
    };

    if (sendEmail ===true) {
      if (inspectionAssignee.assigneeType === 'role') {
        res.inspection_assignee = {
          assigned_role: inspectionAssignee.assigned_role,
          type: inspectionAssignee.assigneeType
        };
      } else {
        const users = inspectionAssignee.assigned_user.map(user => user.value);
        res.inspection_assignee = {
          assigned_user: users,
          type: inspectionAssignee.assigneeType
        };
      }
    }
    if (template.id || inspection.template) {
      // Removes irrelevant data when a template field change its order
      const field_changes = airport_changes.fields && airport_changes.fields.map((f, i) => {
        if (f.template) return { id: f.id, order: i, ...(f.hidden && { hidden: true }) };
        return { ...f, order: i };
      });

      // Same with checklist
      const checklist_changes = airport_changes.inspectionChecklist && (
        airport_changes.inspectionChecklist.map((f, i) => {
          if (f.template) return { id: f.id, order: i, ...(f.hidden && { hidden: true }) };
          return { ...f, order: i };
        }));

      res.airport_changes = {
        ...airport_changes,
        fields: field_changes || [],
        inspectionChecklist: checklist_changes || []
      };
      res.template = template.selected_version_id;
    } else {
      res.schema = {
        id: name,
        version: 1,
        fields: [
          ...fields.map((f) => { const { order, ...r } = f; return r; }),
          ...inspectionChecklist.map((f) => { const { order, ...r } = f; return r; })
        ],
        sections: [
          {
            id: 'SEC1',
            title: 'Details',
            fields: [...fields.map(e => e.id)]
          },
          {
            id: 'SEC2',
            title: 'Checklist',
            fields: [...inspectionChecklist.map(e => e.id)]
          }
        ],
        pages: [
          {
            id: 'PAGE1',
            title: 'Inspection',
            sections: ['SEC1']
          },
          {
            id: 'PAGE2',
            title: 'Inspection',
            sections: ['SEC2']
          }
        ]
      };
    }

    const {
      match: { params: { id: editId } }
    } = this.props;

    if (editId) {
      actionEdit(res);
    } else {
      actionSave(res);
    }
  }

  handleGoInspections() {
    const { history } = this.props;
    history.push(INSPECTIONS_HOME_ROUTE);
  }

  discardDraft() {
    const { actionDiscard, actionHideModal, history } = this.props;
    const { id } = this.state;
    if (id) {
      actionDiscard(id);
    }
    actionHideModal();
    history.push(INSPECTIONS_HOME_ROUTE);
  }

  openConfirmDialog() {
    const { actionConfirm, actionHideModal } = this.props;
    const content = {
      title: '',
      body: <div className={styles.submissionError}>
        <FormattedMessage
          id="inspections.edit.discard_draft_confirmation_message"
          defaultMessage="Are you sure you want to discard the current draft version?"
        />
      </div>
    };
    actionConfirm(content, this.discardDraft, actionHideModal);
  }


  handleUpdateVersion(updateType) {
    const {
      template,
      actionUpdateTemplate,
      actionUpdateVersion
    } = this.props;

    const { id } = this.state;

    if (updateType === 'template') {
      actionUpdateTemplate(template.id);
    } else {
      actionUpdateVersion(id);
    }
  }

  handleEmailRoleChange = (value) => {
    this.setState(prevState => ({
      inspectionAssignee: {
        ...prevState.inspectionAssignee,
        assigned_user: [],
        assigned_role: value,
        currentUser: ''
      }
    }));
  }

  handleEmailAssigneeSelected = (user) => {
    this.setState(prevState => ({
      inspectionAssignee: {
        ...prevState.inspectionAssignee,
        assigned_user: user,
        assigned_role: ''
      }
    }));
  }

  handleEmailAssigneeChanged = (value) => {
    this.setState(prevState => ({
      ...prevState,
      inspectionAssignee: {
        ...prevState.inspectionAssignee,
        currentUser: value
      }
    }));
  }

  handleEmailAssigneeTypeChanged = (value) => {
    this.setState(prevState => ({
      ...prevState,
      inspectionAssignee: {
        ...prevState,
        assigneeType: value
      }
    }));
  };

  render() {
    const { id,
      currentStep,
      info,
      details,
      inspectionChecklist,
      apiStatus,
      errors,
      fielderror,
      assignee,
      sectionError,
      inspectionError,
      assignees,
      toggle,
      form_access,
      showFieldErrors,
      loadingStatus,
      itemError,
      sendEmail,
      inspectionAssignee,
      emailFieldError } = this.state;
    const {
      inspection,
      permissions,
      rules,
      template,
      roles,
      inspectionList: { results },
      templateList: { results: templateList },
      shiftTypes,
      intl
    } = this.props;
    const { formatMessage } = intl;
    if (id && !permissions.includes('change_inspection')) {
      return <Forbidden />;
    }
    return (
      <>
        <Loading loadingStatus={loadingStatus} />
        <div className={styles.box}>
          <div className={styles.title}>
            {id ? (
              <FormattedMessage id="inspections.edit.title"
                defaultMessage="Edit Inspection"
              />
            ) : (
              <FormattedMessage id="inspections.new.newInspection"
                defaultMessage="New Inspection"
              />
            )}
            {currentStep !== 0 && (
              results.map(e => (
                <div>
                  {e.id === inspection.id && e.version_status.map(status => (
                    <div>
                      {status === 0 && (
                      <Clickable onClick={this.openConfirmDialog} className={styles.discard}>
                        <FormattedMessage id="inspections.edit.discard_draft" defaultText="Discard draft" />
                      </Clickable>
                      )}
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>
          <div className={styles.content}>
            {currentStep !== 0 && currentStep !== 4 && <ProgressBar step={currentStep} />}
            {currentStep === 0 && (
            <Step0 goToInspectionEdit={this.goToInspectionEdit}
              goToNext={this.next} list={results} templates={templateList} exportInspection={this.exportInspection}
            />
            )}
            {currentStep === 1 && (
            <Step1
              onInfoChange={this.handleInfoChange}
              info={info} rules={rules}
              sendEmail={sendEmail}
              emailFieldError={emailFieldError}
              assignee={assignee}
              userList={this.props.userList}
              inspectionEmail={inspectionAssignee}
              assignees={assignees}
              handleChange={this.handleChange}
              handleChangeOption={this.handleChangeOption}
              form_access={form_access}
              handleOnclick={this.handleOnclick}
              toggle={toggle}
              roles={roles}
              intl={intl}
              userlist={this.props.userlist}
              hasTemplateUpdate={template.new_version_available}
              hasVersionUpdate={inspection.new_version_available}
              onUpdateVersion={this.handleUpdateVersion}
              errors={errors}
              formatMessage={formatMessage}
              fielderror={fielderror}
              showFieldErrors={showFieldErrors}
              recurrence={inspection.task}
              handleAssigneeChanged={this.handleAssigneeChanged}
              handleAssigneeSelected={this.handleAssigneeSelected}
              handleRoleChange={this.handleRoleChange}
              onAssignTypeChanged={this.onAssignTypeChanged}
              handleEmailNotify={this.handleEmailNotifications}
              onEmailAssignTypeChanged={this.handleEmailAssigneeTypeChanged}
              handleEmailAssigneeChanged={this.handleEmailAssigneeChanged}
              handleEmailAssigneeSelected={this.handleEmailAssigneeSelected}
              handleEmailRoleChange={this.handleEmailRoleChange}
            />
            )}
            {currentStep === 2 && (
            <Step2 details={details}
              onFieldsChange={this.handleFieldsChange}
              onAirportChange={this.handleAirportChanges}
            />
            )}
            {currentStep === 3 && (
            <Step3 checklist={inspectionChecklist}
              addNewItem={this.handleNewItem}
              onFieldsOrderChange={this.handleFieldsOrderChange}
              onChangeChecklistName={this.handleChangeChecklistName}
              onAddEntry={this.handleAddEntry}
              onChangeEntry={this.handleChangeEntry}
              onSelectShifts={this.onSelectShifts}
              shifts={shiftTypes}
            />
            )}

            {currentStep === 4 && <Step4 icon={info.icon.includes('.png') ? `${info.icon}`: `${info.icon}.svg`} status={apiStatus} error={inspectionError} title={info.name} />}
          </div>

          {currentStep !== 0 && !apiStatus && (
          <div className={styles.footer}>
            <Button action="secondary" onClick={this.prev}
              translationID="inspections.new.prev" defaultText="Back"
            />
            <div className={styles.errors}>
              {sectionError ? <FormattedMessage tagName="p" id="inspections.new.section_error" defaultMessage="You Have to Add Atleast One Section" />: ''}
              {itemError ? <FormattedMessage tagName="p" id="inspections.new.item_error" defaultMessage="You have to add Atleast one item for every Section" />: ''}
              {/* {errors && <FormattedMessage tagName="p" id="inspections.new.error" defaultMessage="Field 'Inspection Name' can't be blank" />} */}
            </div>
            {currentStep !== 4 && (
              <Button action="secondary" onClick={this.next}
                translationID="inspections.new.next" defaultText="Next"
              />
            )}
            {currentStep === 4 && (
              <div className={styles.saveBtns}>
                <Button action="tertiary" onClick={() => this.finish(true)}
                  translationID="inspections.new.finishDraft" defaultText="Save as Draft"
                />
                <Button action="secondary" onClick={() => this.finish(false)}
                  translationID="inspections.new.finish" defaultText="Publish"
                />
              </div>
            )}
          </div>
          )}
          {apiStatus && (
          <div className={styles.footer}>
            <div />
            <Button
              action="secondary"
              onClick={this.handleGoInspections}
              translationID="inspections.complete_inspections.go_to_inspections"
              defaultText="Go to Inspections"
            />
            <div />
          </div>
          )}
        </div>
      </>
    );
  }
}


InspectionBuilder.propTypes = {
  actionSave: PropTypes.func.isRequired,
  actionEdit: PropTypes.func.isRequired,
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  inspectionClear: PropTypes.func.isRequired,
  actionDiscard: PropTypes.func.isRequired,
  actionFetchForEdit: PropTypes.func.isRequired,
  actionConfirm: PropTypes.func.isRequired,
  actionHideModal: PropTypes.func.isRequired,
  inspectionList: PropTypes.shape({}),
  templateList: PropTypes.arrayOf(PropTypes.shape({})),
  template: PropTypes.shape({}),
  inspection: PropTypes.shape({}),
  match: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  actionFetchShifts: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  actionFetchUsers: PropTypes.func.isRequired,
  actionGetRoles: PropTypes.func.isRequired,
  shiftTypes: PropTypes.arrayOf(PropTypes.shape())
};

InspectionBuilder.defaultProps = {
  inspectionList: [],
  templateList: [],
  inspection: {},
  template: {},
  match: {},
  permissions: [],
  userlist: []
};

const mapStateToProps = state => ({
  createInspectionAction: state.inspection.createInspectionAction,
  inspectionList: state.inspection.inspectionList,
  templateList: state.inspection.templateList,
  template: state.inspection.template,
  inspection: state.inspection.inspection,
  permissions: state.permissions,
  rules: state.tasks.rules,
  templateAction: state.inspection.templateAction,
  fetchInspectionAction: state.inspection.fetchInspectionAction,
  shiftTypes: state.settings.shiftTypes,
  userList: state.inspection.userlist,
  userlist: state.settings.users,
  roles: state.settings.roles
});

const mapDispatchToProps = dispatch => ({
  // Save inspection
  actionSave: (insp) => {
    dispatch(addInspection(insp));
  },
  actionEdit: (insp) => {
    dispatch(editInspection(insp));
  },
  actionFetch: () => {
    dispatch(fetchInspectionListForEdit());
  },
  actionFetchRules: () => {
    dispatch(fetchRules());
  },
  actionFetchTemplates: () => {
    dispatch(fetchTemplates());
  },
  actionFetchTemplate: (id) => {
    dispatch(fetchTemplate(id));
  },
  actionUpdateTemplate: (id) => {
    dispatch(updateTemplateVersion(id));
  },
  actionUpdateVersion: (id) => {
    dispatch(updateInspectionVersion(id));
  },
  actionFetchForEdit: (id) => {
    dispatch(fetchInspectionForEdit(id));
  },
  actionClear: () => {
    dispatch(clearActionResult());
  },
  inspectionClear: () => {
    dispatch(clearInspection());
  },
  actionFetchUsers: () => {
    dispatch(fetchUsers());
  },
  actionDiscard: (insp) => {
    dispatch(discardInspectionDraft(insp));
  },
  actionHideModal: (insp) => {
    dispatch(hideModal(insp));
  },
  actionGetRoles: () => {
    dispatch(fetchRoles());
  },
  // modals
  actionConfirm: (body, accept, cancel) => {
    dispatch(showConfirmModal(body, accept, cancel));
  },
  actionFetchShifts: () => {
    dispatch(fetchShiftTypes());
  },
  actionSearchUser: (query, type) => {
    dispatch(searchUser(query, type));
  }
});

export default Permissions(['add_inspectionparent', 'change_inspection'])(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(InspectionBuilder)),
  <Forbidden />
);
