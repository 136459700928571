import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HOC as Permissions } from 'react-redux-permissions';
import { FormattedMessage, injectIntl } from 'react-intl';
import SectionHeader from '../../../../components/sectionHeader';
import HeaderBack from '../../../../components/headerBack';
import Button from '../../../../components/button';
import Spinner from '../../../../components/spinner';
import { TENANTS } from '../../../../constants/RouterConstants';
import Clickable from '../../../../components/clickable/Clickable';
import Forbidden from '../../../Forbidden';
import icon from '../../../../components/settingsHeader/icons/settings.svg';
import EditableText from '../../../inspections/Builder/steps/Step3/EditableText';
import styles from '../../../operations-logs/Builder/operationLogBuilder.module.scss';
import createStyles from '../../../inspections/Builder/create.module.scss';
import stepStyles from '../../../inspections/Builder/steps/steps.module.scss';
import style from './tenantsBuilder.module.scss';

import { fetchAllTypes, uploadTypes, clearTenantsTypes } from '../../redux/actions';

class TenantsBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessTypesLength: 0,
      saveStatus: false,
      view: 'business_types'
    };
  }

  componentDidMount() {
    const { fetchTypes } = this.props;
    fetchTypes();
  }

  componentWillUnmount() {
    const { actionsClear } = this.props;
    actionsClear();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.businessTypes && props.allTypes) {
      const businessTypes = props.allTypes.business_types.map((t) => {
        const title = t.i18n_id
          ? props.intl.formatMessage({ id: `lease.${t.i18n_id}` })
          : t.name;
        return ({
          pk: `${t.id}`,
          id: `${t.id}`,
          i18n_id: t.i18n_id,
          title
        });
      });
      return { ...state, businessTypes, selected: businessTypes[0] };
    }
    if (!state.aircraftTypes && props.allTypes) {
      const aircraftTypes = props.allTypes.aircraft_types.map((t) => {
        const title = t.i18n_id
          ? props.intl.formatMessage({ id: `lease.${t.i18n_id}` })
          : t.name;
        return ({
          pk: `${t.id}`,
          id: `${t.id}`,
          i18n_id: t.i18n_id,
          title
        });
      });
      return { ...state, aircraftTypes, selected: aircraftTypes[0] };
    }
    if (!state.categoryTypes && props.allTypes) {
      const categoryTypes = props.allTypes.category_types.map((t) => {
        const title = t.i18n_id
          ? props.intl.formatMessage({ id: `lease.${t.i18n_id}` })
          : t.name;
        return ({
          pk: `${t.id}`,
          id: `${t.id}`,
          i18n_id: t.i18n_id,
          title
        });
      });
      return { ...state, categoryTypes, selected: categoryTypes[0] };
    }
    if (props.uploadAction && props.uploadAction.success) {
      props.actionsClear();
      return { ...state, saveStatus: true };
    }
    return state;
  }

  handleChangeBusinessType = (id, val) => {
    const { businessTypes } = this.state;
    this.setState({ saveStatus: false });
    const cat = businessTypes.find(f => f.id === id);
    if (val) {
      cat.title = val;
      cat.i18n_id = '';
      this.setState({ businessTypes: [...businessTypes] });
    } else {
      this.setState({ businessTypes: businessTypes.filter(c => c.id !== id) });
    }
  };

  handleChangeCategoryType = (id, val) => {
    const { categoryTypes } = this.state;
    this.setState({ saveStatus: false });
    const cat = categoryTypes.find(f => f.id === id);
    if (val) {
      cat.title = val;
      cat.i18n_id = '';
      this.setState({ categoryTypes: [...categoryTypes] });
    } else {
      this.setState({ categoryTypes: categoryTypes.filter(c => c.id !== id) });
    }
  };

  handleChangeAircraftType = (id, val) => {
    const { aircraftTypes } = this.state;
    this.setState({ saveStatus: false });
    const cat = aircraftTypes.find(f => f.id === id);
    if (val) {
      cat.title = val;
      cat.i18n_id = '';
      this.setState({ aircraftTypes: [...aircraftTypes] });
    } else {
      this.setState({ aircraftTypes: aircraftTypes.filter(c => c.id !== id) });
    }
  };

  handleSave = (view) => {
    const { businessTypes, categoryTypes, aircraftTypes } = this.state;
    const { actionUpdateTypes } = this.props;
    let types = '';
    if (view === 'business_types') {
      types = businessTypes;
    }
    if (view === 'category_types') {
      types = categoryTypes;
    }
    if (view === 'aircraft_types') {
      types = aircraftTypes;
    }
    // Updates the types
    const parsedTypes = types.map((e) => {
      if (e.pk) {
        return {
          id: e.pk,
          name: (e.i18n_id ? '' : e.title),
          i18n_id: (e.i18n_id || '')
        };
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return {
          id: e.pk,
          name: (e.i18n_id ? '' : e.title),
          i18n_id: (e.i18n_id || '')
        };
      }
    });
    actionUpdateTypes({ [view]: parsedTypes });
  };

  handleAdd = (view) => {
    const { businessTypesLength, businessTypes, categoryTypes, categoryTypesLength,
      aircraftTypesLength, aircraftTypes } = this.state;
    if (view === 'business_types') {
      this.setState({ businessTypesLength: businessTypesLength + 1 });
      this.setState({ businessTypes: [...businessTypes, { id: businessTypesLength, title: '' }] });
    }
    if (view === 'category_types') {
      this.setState({ categoryTypesLength: categoryTypesLength + 1 });
      this.setState({ categoryTypes: [...categoryTypes, { id: categoryTypesLength, title: '' }] });
    }
    if (view === 'aircraft_types') {
      this.setState({ aircraftTypesLength: aircraftTypesLength + 1 });
      this.setState({ aircraftTypes: [...aircraftTypes, { id: aircraftTypesLength, title: '' }] });
    }
  }

  handleViewChange = (view) => {
    this.setState({ view });
  }

  render() {
    const { uploadAction } = this.props;
    const { businessTypes, selected, saveStatus, view, aircraftTypes, categoryTypes } = this.state;
    let titleId = 'lease.tenants.settings.business_types';
    let titleDefault = 'Business Types';
    if (view === 'aircraft_types') {
      titleId = 'lease.tenants.settings.aicraft_types';
      titleDefault = 'Aircraft Types';
    }
    if (view === 'category_types') {
      titleId = 'lease.tenants.settings.category_types';
      titleDefault = 'Category Types';
    }
    return (
    <>
      <div className={style.builder}>
        <SectionHeader icon={icon} translationID="lease.teanants.settings"
          defaultTitle="Tenants Settings"
        />
        <HeaderBack
          translationID="inspections.new.prev"
          translationDefault="Back to Operations Log"
          backRoute={`${TENANTS}`}
        >
          <div className={style.links}>
            <Clickable onClick={() => this.handleViewChange('business_types')}
              className={view === 'business_types' ? style.active : ''}
            >
              <FormattedMessage id="lease.tenants.settings.business_types" defaultMessage="Business Types" />
            </Clickable>
            <Clickable onClick={() => this.handleViewChange('category_types')}
              className={view === 'category_types' ? style.active : ''}
            >
              <FormattedMessage id="lease.tenants.settings.category_types" defaultMessage="Category Types" />
            </Clickable>
            <Clickable onClick={() => this.handleViewChange('aircraft_types')}
              className={view === 'aircraft_types' ? style.active : ''}
            >
              <FormattedMessage id="lease.tenants.settings.aicraft_types" defaultMessage="Aircraft Types" />
            </Clickable>
          </div>
        </HeaderBack>
        <div className={createStyles.box}>
          <div className={`${stepStyles.step2} ${styles.content}`}>
            <div className={stepStyles.title}>
              <FormattedMessage tagName="h5" id={titleId}
                defaultMessage={titleDefault}
              />
            </div>
            <div className={`${styles.categories} ${style.business}`}>
              <div>
                {/* <FormattedMessageWithClass className={styles.colName}
                  id="lease.tenants.settings.business_types" defaultMessage="Business Types"
                /> */}
                {view === 'business_types' && businessTypes && businessTypes.map(c => (
                  <div key={c.id} className={`${styles.category} ${selected && (c.id === selected.id) ? styles.active : ''}`}
                    onClick={() => this.setState({ selected: c })} onKeyPress={() => ({})} role="button" tabIndex="0"
                  >
                    <EditableText item={c} ops defaultFocused={!c.title} changeField={this.handleChangeBusinessType} />
                  </div>
                ))}
                {view === 'category_types' && categoryTypes && categoryTypes.map(c => (
                  <div key={c.id} className={`${styles.category} ${selected && (c.id === selected.id) ? styles.active : ''}`}
                    onClick={() => this.setState({ selected: c })} onKeyPress={() => ({})} role="button" tabIndex="0"
                  >
                    <EditableText item={c} ops defaultFocused={!c.title} changeField={this.handleChangeCategoryType} />
                  </div>
                ))}
                {view === 'aircraft_types' && aircraftTypes && aircraftTypes.map(c => (
                  <div key={c.id} className={`${styles.category} ${selected && (c.id === selected.id) ? styles.active : ''}`}
                    onClick={() => this.setState({ selected: c })} onKeyPress={() => ({})} role="button" tabIndex="0"
                  >
                    <EditableText item={c} ops defaultFocused={!c.title} changeField={this.handleChangeAircraftType} />
                  </div>
                ))}
                <div className={styles.addBtn}>
                  <Button
                    action="secondary"
                    onClick={() => this.handleAdd(view)}
                    translationID="operations.builder.add" defaultText="Add"
                  />
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.saveWrapper}>
              <Spinner active={uploadAction && uploadAction.loading} />
              {saveStatus && (
              <FormattedMessage id="operations.builder.saved"
                defaultMessage="Saved"
              />
              )}
              <Button action="secondary" onClick={() => this.handleSave(view)}
                translationID="logs.builder.save" defaultText="Save"
              />
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
}

TenantsBuilder.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionUpdateTypes: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  actionsClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  allTypes: state.lease.allTenantTypes,
  uploadAction: state.lease.uploadAction,
  uploadedTypes: state.lease.uploadedTypes
});

const mapDispatchToProps = dispatch => ({
  actionUpdateTypes: (types) => {
    dispatch(uploadTypes(types));
  },
  fetchTypes: () => {
    dispatch(fetchAllTypes());
  },
  actionsClear: () => {
    dispatch(clearTenantsTypes());
  }
});

export default Permissions(['can_modify_airport_settings'])(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(TenantsBuilder)),
  <Forbidden />
);
