import React from 'react';

import { Wrapper } from './ActiveChatHeader.styles';
import { Avatar } from '../../../../components/avatar';
import { ReactComponent as MoreHoriz } from '../../../../icons/more-horiz.svg';
import { ChatName } from '../ChatName';
import { ChatTypes } from '../../enums';
import { ListChannelUsersType, ChanelTypes } from '../../types';
import { commentsActions } from '../../state/channelsState';
import CloseIcon from '../../../../icons/close.svg';
import { EditOption } from './EditOption';
import { useProfileState } from './useProfileState';

type ActiveChatHeaderProps = {
  type: ChanelTypes;
  listChannelUsers: ListChannelUsersType[];
  channelId: string;
  channelName: string;
};

const ActiveChatHeader: React.FC<ActiveChatHeaderProps> = ({
  type,
  listChannelUsers,
  channelId,
  channelName
}) => {
  const { dispatch, handleClick, showMenu } = useProfileState();
  const isPostChat = type === ChatTypes.operations;


  return (
    <Wrapper isGrey={!isPostChat} viewType={type}>
      {isPostChat ? (
        <ChatName
          name={channelName}
          details={`${listChannelUsers.length} People`}
        />
      ) : (
        <ChatName name="Thread" />
      )}
      <div className="chat_users">
        {isPostChat ? (
          <>
            {listChannelUsers.map(
              ({
                userDetails: { profilePicture, firstName, lastName },
                isActive,
                userId
              }) => (
                <Avatar
                  key={userId}
                  src={profilePicture}
                  isActive={isActive}
                  name={`${firstName} ${lastName}`}
                />
              )
            )}
            <MoreHoriz onClick={handleClick} />
          </>
        ) : (
          <img
            src={CloseIcon}
            alt="CloseIcon"
            onClick={() => commentsActions.hide(dispatch)}
          />
        )}
        {showMenu && <EditOption editId={channelId} type={type} />}
      </div>
    </Wrapper>
  );
};

export default ActiveChatHeader;
export { ActiveChatHeader };
