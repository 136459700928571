import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from '../../../workorders/List/components/toolbar.module.scss';
import Separator from '../../../../components/separator';

const WorkorderListFilter = ({ handleDropdown, filterCategories, workorderslistSummary }) => (
  <div style={{ display: 'flex' }} className={styles.toolbar}>
    <div className={styles.detailHeader}>
      <Separator />
      <span className={styles.actionsBtn} onClick={() => handleDropdown()}>
        <FormattedMessage id="assets.list.filters" defaultMessage="Filters " />
      </span>
    </div>
  </div>
);

export default WorkorderListFilter;

WorkorderListFilter.propTypes = {
  handleDropdown: PropTypes.func.isRequired
};
