import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../steps.module.scss';
import Button from '../../../../../components/button';
import editIcon from '../../../../../icons/inspection-icons/edit.svg';
import { ReactComponent as Bin } from '../../../../../icons/bin.svg';

class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showsave: false,
      value: '',
      flag: false
    };

    this.titleinput = React.createRef();
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    const { defaultFocused } = this.props;
    if (defaultFocused && this.titleinput.current) {
      this.setState(prevState => ({ edit: !prevState.edit }),
        () => {
          if (this.titleinput.current) this.titleinput.current.focus();
        });
    }
  }

  onBlur(e, a) {
    const { item, item: { id }, changeField } = this.props;
    const { showsave, value } = this.state;
    this.setState({ edit: true });
    if (value === '') {
      changeField(id, item.title);
    } else if (showsave||a==='ops') {
      changeField(id, value);
    }
    this.toggleEdit(e, a);
  }

  handelValueChange(e) {
    e.stopPropagation();
    this.setState({
      edit: true,
      flag: true,
      value: e.nativeEvent.target.value
    });
  }


  async toggleEdit(e, a) {
    if (a==='p') {
      const { showsave, edit } = this.state;
      await this.setState({ showsave: !showsave, edit: !edit });
      return;
    }
    e.stopPropagation();
    this.setState(prevState => ({ showsave: !prevState.showsave, edit: !prevState.edit }),
      () => {
        if (this.titleinput.current) this.titleinput.current.focus();
      });
  }

  remove(e) {
    e.stopPropagation();
    const { item: { id }, changeField } = this.props;
    changeField(id, '');
  }

  render() {
    const { item, inspections, additionalStyles, ops } = this.props;
    const { edit, showsave, value, flag } = this.state;
    return (
      <div style={{ display: 'flex', width: '80%', position: !ops?'absolute':'relative', left: !ops? additionalStyles.left: '0' }}>
        <input
          ref={this.titleinput}
          onChange={e => this.handelValueChange(e)}
          onBlur={!inspections?e => this.onBlur(e, 'ops'):''}
          type="text"
          style={!ops?{
            width: '40%',
            position: 'relative'
          }:{}}
          disabled={edit ? '' : 'disabled'}
          placeholder={value||item.title}
          value={value === '' && !flag ? item.title : value}
          className={styles.input}
        />
        {inspections && showsave? (
          <div style={{ display: 'flex',
            position: 'absolute',
            right: !ops
              ? additionalStyles.topText: -120 }}
          >
            <Button type="submit" onClick={e => this.onBlur(e, 'p')}
              translationID="profile.form.submit" defaultText="Save"
            />
            <button className={`${styles.editBtn} ${styles.removeBtn}`} type="button"
              onClick={e => this.onBlur(e, 'p')}
            >
              &times;
            </button>
          </div>
        ): (
          <div style={{
            display: 'flex',
            position: 'absolute',
            marginRight: !ops? '10px': '20px',
            right: !ops
              ? additionalStyles.topText: -60,
            alignItems: 'center'
          }}
          >
            <button className={styles.editBtn} type="button" onClick={e => this.toggleEdit(e, 'p')}>
              <img src={editIcon} alt="" />
            </button>
            <span>

              <Bin height="18px"
                opacity="0.4"
                onClick={this.remove}
              />
            </span>
          </div>
        )}
      </div>
    );
  }
}

EditableText.propTypes = {
  item: PropTypes.shape({}).isRequired,
  changeField: PropTypes.func.isRequired,
  defaultFocused: PropTypes.bool,
  additionalStyles: PropTypes.number
};

EditableText.defaultProps = {
  defaultFocused: false,
  additionalStyles: {
    topText: 185
  }
};
export default EditableText;
