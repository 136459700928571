/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import drawLocales from 'leaflet-draw-locales';

import styles from '../../../../pulpo_visualizer/fields/fields.module.scss';
import { uniqueID } from '../../../../utils/helpers';
import * as utils from '../../../settings/Map/mapUtils';


const language = localStorage.getItem('lang') ||'en';
drawLocales(language);
const drawnItems = new L.FeatureGroup();

const locale = drawLocales(language);
locale.draw.toolbar.buttons.polygon = 'Draw a New Surface!';
L.drawLocal = locale;

class Multilocation extends Component {
  constructor(props) {
    super(props);
    this.ID = uniqueID('multimap');
    this.state = {
      edit: true,
      layer: undefined,
      geometry: {},
      layerAdded: false,
      deleted: false
    };
  }

  componentDidMount() {
    this.initializeMap(true);
    this.map.eachLayer((layer) => {
      if (layer._layers && Object.keys(layer._layers).length>0) {
        Object.keys(layer._layers).map((l) => {
          this.map.removeLayer(layer._layers[l]);
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { type, assetTypeID, assets } = this.props;
    const { layer, geometry, layerAdded }=this.state;
    if (layer && layerAdded===false) {
      this.handleAnswerChanged([geometry], 'Polygon');
      this.setState({ layerAdded: true });

      this.map.on('draw:deleted', () => {
        this.setState({ layer: undefined, deleted: true, layerAdded: false });
        this.handleAnswerChanged([], 'Polygon');
      });

      let l = L.geoJSON(geometry)._layers;
      [l] = Object.keys(l).map(ob => l[ob]);
      const customlayer = this.addPopupToLayer(l);
      this.map.addLayer(drawnItems.addLayer(customlayer));
      this.map.on('draw:edited', (e) => {
        const layers = e.layers;
        layers.eachLayer((layerz) => {
          const coordinates = layerz._latlngs[0].map(o => [o.lng, o.lat]);
          this.setState({
            layer: e.layer,
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            }
          });
          this.handleAnswerChanged([{ type: 'Polygon', coordinates: [coordinates] }], 'Polygon');
        });
      });
    }
    if (prevProps.type !== type
      || prevProps.assets.length !== assets.length
      || prevProps.assetTypeID !== assetTypeID) {
      if (this.initializeMap) {
        this.map.remove();
        this.initializeMap(true);
      }
    }
  }

  addPopupToLayer = (layer) => {
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: 'blue',
      opacity: 1,
      fillOpacity: 0.48
    });
    return customlayer;
  };

  handleAnswerChanged = (value, fieldId) => {
    const { handleValueChange } = this.props;
    handleValueChange(value, fieldId);
  }

  initializeMap = (createMap) => {
    const { assets, handleSelectedAsset, handleZoomLevel, shape } = this.props;
    if (createMap) {
      this.map = L.map(this.ID, {
        zoom: 14,
        editable: true
      });
      L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);

      this.map.on(L.Draw.Event.CREATED, async (e) => {
        const coordinates = e.layer._latlngs[0].map(o => [o.lng, o.lat]);

        await this.setState({
          layer: e.layer,
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
          }
        });
      });
      const drawControl=this.addDrwer();
      this.map.addControl(drawControl);
      if (this.state.deleted) {
        const layerGroup = L.layerGroup().addTo(this.map);
        layerGroup.clearLayers();
      }

      if (shape && shape.length>0&&this.state.deleted===false) {
        shape.map((o) => {
          let l = L.geoJSON(o.geometry)._layers;
          [l] = Object.keys(l).map(ob => l[ob]);
          this.setState({ layer: l, geometry: o.geometry, layerAdded: false });
        });
      }

      const bounds = [];
      assets.forEach((a) => {
        let l = L.geoJSON(a.geometry)._layers;
        const that = this;
        [l] = Object.keys(l).map(ob => l[ob]);
        const icon = new utils.IconImage({
          className: 'asset-icon',
          iconAnchor: [15, 10],
          icon: a.asset_type.icon,
          id: parseInt(a.id, 10)
        });
        const coordinates = JSON.parse(JSON.stringify(a.geometry.coordinates));
        bounds.push(coordinates.reverse());
        l.setIcon(icon).addTo(this.map);
        l.on('click', (e) => {
          const { edit } = this.state;
          if (edit) {
            that.layer = e.target;
            that.map.eachLayer((layer) => {
              if (layer.editing && layer.editing.enabled()) {
                layer.editing.disable();
              }
            });
            e.target.editing.enable();
            //  To disable the dragging functionality of Asset in Workorders Map
            e.target.dragging._draggable._enabled = false;
            // get the asset associated
            e.target._icon.classList.add('leaflet-edit-marker-selected');
            const { assets: newAsset } = this.props;
            const { asset_type: atype, ...selectedAsset } = newAsset.find(
              b => b.id === e.target.options.icon.options.id
            );
            selectedAsset.type = atype;
            handleSelectedAsset(selectedAsset.id);
            const position = l.getLatLng();
            this.handleAnswerChanged([position.lng, position.lat], 'location');
            const zoomLevel = this.map.getZoom();
            handleZoomLevel(zoomLevel);
          }
        });
        // To send the ZoomLevel of Map while in creating or Editing Workorder
        const zoomLevel = this.map.getZoom();
        handleZoomLevel(zoomLevel);
        if (bounds.length) {
          this.map.fitBounds([bounds]);
        }
      });
    } else {
      this.map.eachLayer((layer) => {
        if (layer.feature !== undefined) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  addDrwer=() => {
    this.map.addLayer(drawnItems);
    return new L.Control.Draw({
      editable: true,
      edit: { featureGroup: drawnItems,
        remove: true,
        edit: true
      },
      draw: {
        marker: false,
        circle: false,
        rectangle: false,
        polyline: false,
        circlemarker: false,
        polygon: {
          shapeOptions: {
            color: 'blue',
            opacity: 1,
            fillOpacity: 0.48
          },
          icon: new utils.CustomIcon({ color: 'blue' })
        }
      }
    });
  }


  render() {
    const { className } = this.props;
    return (
      <div className={`${className} ${styles.field}`}>
        <div id={this.ID} className={styles.map} />
      </div>
    );
  }
}

Multilocation.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

Multilocation.defaultProps = {
  className: ''
};
export default Multilocation;
