import React, { useState } from 'react';
import { Sticky, Grid, Responsive, Segment } from 'semantic-ui-react';
import styles from '../../chat.module.scss';
import { Wrapper } from './EditChannel.styles';
import { EditChannelForm } from './EditChannelForm';
import {
  useGetListUsers,
  useUpdateChanel,
  useGetChannelDetails,
  useGetListChannelUsers
} from '../../graphql/hooks';
import { EditLocation, OnUserSelectFunc } from '../../types';

type EditChannelProps = {
  userId: string;
  airportId: string;
  location: EditLocation;
};

const EditChannel = React.memo<EditChannelProps>((props) => {
  const [userIDs, setUserID] = useState([]);
  const [channelId] = useState(props.location.state.channelId);
  // TODO handle loading and error cases
  const { usersData } = useGetListUsers();
  const { updateChannel } = useUpdateChanel();

  const { data: getChannel } = useGetChannelDetails(channelId);

  const { data: listChannelUsers } = useGetListChannelUsers(channelId);

  listChannelUsers.map((element) => {
    if (userIDs.filter(user => user !== element.userId).length > 0) {
      userIDs.push(element.userId);
    } else {
      userIDs.push(element.userId);
    }
    return userIDs;
  });

  const onUserSelect: OnUserSelectFunc = (userID) => {
    const userIDsArr: string[] = Array.isArray(userID) ? userID : [userID];
    setUserID(prevState => [...new Set([...prevState, ...userIDsArr])]);
  };

  const onRemove = (userID: string): void => {
    setUserID(prevState => prevState.filter(user => user !== userID));
  };

  return (
    <Wrapper>
      <Grid className="home-screen">
        <Grid.Column
          width={12}
          className={`channels-posts no-padding-botton ${styles.chat_wrapper}`}
        >
          <Responsive as={Segment} className="no-padding-botton">
            <Sticky>
              <h1>Edit Channel</h1>
              <hr />
              <EditChannelForm
                onUserSelect={onUserSelect}
                onRemove={onRemove}
                updateChannel={updateChannel}
                userIDs={userIDs}
                usersData={usersData}
                channelDetails={getChannel}
              />
            </Sticky>
          </Responsive>
        </Grid.Column>
      </Grid>
    </Wrapper>
  );
});
export default EditChannel;
