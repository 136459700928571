/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../steps.module.scss';

const Step6 = () => (
  <div className={styles.step0}>
    <h3 className={styles.headline}>
      <FormattedMessage
        id="lease.tenants.create.success.message"
        defaultMessage="Congratulations! You have successfully added a new Tenant."
      />
    </h3>
  </div>
);

export default Step6;
