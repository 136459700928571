import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import InspectionChecklistItem from './components/InspectionChecklistItem';
import InspectionFieldsContainer from './components/InspectionFieldsContainer';

import styles from './checklist.module.scss';

import { getShiftTypeByDate } from '../../../../../utils/helpers';

class InspectionChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftName: ''
    };

    this.handleChecklistItemStatusChange = this.handleChecklistItemStatusChange.bind(this);
    this.handleChecklistItemRemarkChange = this.handleChecklistItemRemarkChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.shiftName === '' && props.shiftTypes && props.shiftTypes.length > 0) {
      const shift = getShiftTypeByDate(props.answers.date, props.shiftTypes);
      return { ...state, shiftName: shift.name };
    }
    return state;
  }

  shiftWorkOrders = (shiftName, workorders) => {
    const works = workorders.filter(workorder => (workorder.shift_name === '' || workorder.shift_name === shiftName));
    return works;
  };

  handleChecklistItemStatusChange(itemKey, pass) {
    const { handleChecklistItemStatusChange, checklist, activeIdx } = this.props;
    handleChecklistItemStatusChange(checklist[activeIdx].id, itemKey, pass);
  }

  handleChecklistItemRemarkChange(itemKey, value) {
    const { handleChecklistItemRemarkChange, checklist, activeIdx } = this.props;
    handleChecklistItemRemarkChange(checklist[activeIdx].id, itemKey, value);
  }

  render() {
    const {
      remarks,
      checklist,
      activeIdx,
      answers,
      handleInspectionFieldChanged,
      apiStatus,
      incompleteChecklist,
      selfInspection,
      translations,
      answer,
      draftdata,
      id,
      showFeedBackModal,
      workorders,
      handleWorkorderCreate
    } = this.props;

    const { shiftName } = this.state;
    if (shiftName && workorders) {
      // eslint-disable-next-line no-unused-vars
      const work_orders = this.shiftWorkOrders(shiftName, workorders);
    }
    return (
      <div className={styles.inspectionChecklistContainer}>
        {apiStatus === false && (
          <div className={styles.submissionError}>
            <FormattedMessage
              id="inspections.complete_inspections.server_error_msg"
              defaultMessage="There was a problem with your submission. Please try again or contact support."
            />
          </div>
        )}
        {incompleteChecklist && (
          showFeedBackModal()
        )}
        <div className={styles.inspectionChecklist}>
          <InspectionFieldsContainer
            checklist={checklist}
            translations={translations}
            activeIdx={activeIdx}
            handleInspectionFieldChanged={handleInspectionFieldChanged}
            answers={answers}
            answer={answer}
            draftdata={draftdata}
            shiftName={answers.shiftName}
          />
          <div className={styles.checklist}>
            <span className={styles.title}>
              {(translations && translations[checklist[activeIdx].title])
               || checklist[activeIdx].title}
            </span>
            <div>
              {checklist[activeIdx].checklist.map(
                item => (!item.shifts || (item.shifts.indexOf(answers.shiftName) !== -1 || item.shifts.length === 0))
                  && (
                  <InspectionChecklistItem
                    id={id}
                    answerId={draftdata && draftdata.id}
                    updateInspection={answer}
                    inspReportDate={answers.date}
                    draftdata={draftdata && Object.keys(draftdata.response).length > 0 && draftdata.response[checklist[activeIdx].id]? draftdata.response[checklist[activeIdx].id] : {}}
                    title={(translations && translations[item.value]) || item.value}
                    key={`${activeIdx}-${item.key}`}
                    category={checklist[activeIdx].id}
                    itemKey={item.key}
                    selfInspection={selfInspection}
                    remark={remarks[checklist[activeIdx].id] ? remarks[
                      checklist[activeIdx].id][item.key] : ''}
                    handleItemStatusChange={this.handleChecklistItemStatusChange}
                    handleItemRemarkChange={this.handleChecklistItemRemarkChange}
                    handleWorkorderCreate={handleWorkorderCreate}
                    workorders={workorders ? workorders.filter(e => (
                      checklist[activeIdx].id === e.category_id
                  && item.key === e.subcategory_id
                    )) : []}
                    pass={answers[checklist[activeIdx].id] ? answers[checklist[activeIdx].id][item.key]:undefined}
                // eslint-disable-next-line no-nested-ternary
                    answerText={answer ? (answer.remarks[checklist[activeIdx].id] && answer.remarks[checklist[activeIdx].id][item.key] ? answer.remarks[checklist[activeIdx].id][item.key] : '') : ''}
                // eslint-disable-next-line no-nested-ternary
                    draftText={draftdata ? (draftdata.remarks[checklist[activeIdx].id] && draftdata.remarks[checklist[activeIdx].id][item.key] ? draftdata.remarks[checklist[activeIdx].id][item.key] : '') : ''}
                  />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InspectionChecklist.defaultProps = {
  apiStatus: undefined,
  selfInspection: false,
  workorders: []
};

InspectionChecklist.propTypes = {
  checklist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeIdx: PropTypes.number.isRequired,
  handleChecklistItemStatusChange: PropTypes.func.isRequired,
  handleChecklistItemRemarkChange: PropTypes.func.isRequired,
  handleInspectionFieldChanged: PropTypes.func.isRequired,
  answers: PropTypes.shape({}).isRequired,
  remarks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incompleteChecklist: PropTypes.bool.isRequired,
  apiStatus: PropTypes.bool,
  workorders: PropTypes.arrayOf(PropTypes.shape({})),
  selfInspection: PropTypes.bool,
  answer: PropTypes.shape({})
};

export default InspectionChecklist;
