import {
  // CommentMessage,
  // DirectMessageType,
  MessageFormValues, MessageUnionType,
  // PostMessageType,
  SetListHeightFunc
} from "../../types";

export const onHeightChange = (setListHeight: SetListHeightFunc) => (
  height: number
) => {
  setListHeight(Math.floor(height / 20) - 2);
};

export const makeInitValues = (
  initValues: MessageFormValues,
  editedPost: MessageUnionType
) => {
  if (!editedPost) {
    return initValues;
  }

  if ('fromUser' in editedPost) {
    return { content: editedPost.message };
  }

  return { content: editedPost.content };
};
