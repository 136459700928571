import gql from 'graphql-tag';

const FETCH_CHANNELS_POSTS = gql`
  query getChannelPosts(
    $channelId: String!
    $userId: String!
    $limit: Int
    $nextToken: String
  ) {
    getChannelPosts(
      channelId: $channelId
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        attachments {
          name
          url
          type
          size
        }
        content
        createdAt
        likes
        postId
        userId
        comments
        user {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId  
          airportDetails {
            name
            code
            logo
          }
          roles{
            name
            id
            permissions { 
              name
            }
          }
          airportId
        }
        airportId
        channelId
        updatedAt
        isLiked
        lastReplay
      }
      nextToken
    }
  }
`;
export default FETCH_CHANNELS_POSTS;
