import {
  CREATE_INSPECTION, FETCH_INSPECTION_LIST, FETCH_INSPECTION, CLEAR_INSPECTION,
  CREATE_INSPECTION_ANSWER,
  FETCH_INSPECTION_ANSWER_LIST,
  FETCH_INSPECTION_ANSWER,
  CLEAR_INSPECTION_ANSWER,
  CLEAR_INSPECTION_ONLY,
  CLEAR_ACTION_RESULT,
  FETCH_INSPECTION_FOR_EDIT,
  SEARCH_USER,
  CREATE_REMARK,
  FETCH_SAFETY_SELF_INSPECTION,
  FETCH_TEMPLATES,
  FETCH_TEMPLATE,
  EXPORT_INSPECTION,
  EXPORT_INSPECTION_DATA,
  FETCH_SUMMARY,
  CREATE_DRAFT_INSPECTION_ANSWER,
  FETCH_DRAFT_INSPECTION,
  CLEAR_OPEN_WORKORDERS,
  FETCH_OPEN_WORKORDERS,
  FETCH_DATA_SOURCES,
  FETCH_DATA_SOURCE_VALUES,
  CLEAR_ACTION_DATA_SOURCE
} from './types';

import { actionForState } from '../../../utils/helpers';

const INITIAL_STATE = {
  inspection: {},
  selfInspection: {},
  createInspectionAction: {},
  fetchInspectionAction: {},
  inspectionList: { results: [] },
  answers: [],
  answer: {
    version: {},
    response: {},
    open_workorders: []
  },
  inspection_permission: false,
  userlist: [],
  draftAction: {
    success: false, pending: false, error: false
  },
  fetchDraftInspectionAction: {
    success: false, pending: false, error: false
  },
  inspection_list: {},
  fetchSumaryAction: {
    success: false, pending: false, error: false
  }
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_INSPECTION.success:
      return {
        ...state,
        createInspectionAction: actionForState(action, 'success', 'success')
      };
    case CREATE_INSPECTION.error:
      return {
        ...state,
        createInspectionAction: actionForState(action.payload.response, 'failed')
      };
    case FETCH_INSPECTION.success:
    case FETCH_INSPECTION_FOR_EDIT.success: {
      let detailFields; let checklistFields;

      if (action.payload.template) {
        // IF THERE IS A TEMPLATE ASOCIATED WE MERGE THE TEMPLATE FIELDS
        // WITH THE INSPECTION CHANGES
        const { sections } = action.payload.template.schema;
        const templatedetailFields = sections[0].fields.map(
          f => action.payload.template.schema.fields.find(fi => fi.id === f)
        ).map(f => ({ ...f, template: true }));

        const templatechecklistFields = sections[1].fields.map(
          f => action.payload.template.schema.fields.find(fi => fi.id === f)
        ).map(f => ({ ...f, template: true }));

        // MERGE AIRPORT CHANGES IN FIELDS WITH TEMPLATES
        let airportFields = action.payload.airport_changes.fields.filter(f => !f.hidden);
        airportFields = airportFields.map((f) => {
          if (sections[0].fields.includes(f.id)) {
            return templatedetailFields.find(tf => tf.id === f.id);
          }
          return f;
        });

        // IF THERE AREN'T CHANGES IN THE SCHEMA, WE MUST RETURN THE TEMPLATE FIELDS
        if (action.payload.airport_changes.fields.length > 0) {
          detailFields = airportFields.sort((a, b) => (a.order < b.order));
        } else {
          detailFields = templatedetailFields;
        }

        // MERGE AIRPORT CHANGES IN CHECKLIST WITH TEMPLATES
        let airportChecklist = action.payload.airport_changes.inspectionChecklist.filter(f => !f.hidden);
        airportChecklist = airportChecklist.map((f) => {
          if (sections[1].fields.includes(f.id)) {
            return templatechecklistFields.find(tf => tf.id === f.id);
          }
          return f;
        });

        // IF THERE AREN'T CHANGES IN THE SCHEMA, WE MUST RETURN THE TEMPLATE FIELDS
        if (action.payload.airport_changes.inspectionChecklist.length > 0) {
          checklistFields = airportChecklist.sort((a, b) => (a.order < b.order));
        } else {
          checklistFields = templatechecklistFields;
        }
      } else {
        const { sections } = action.payload.form.schema;
        detailFields = sections[0].fields.map(
          f => action.payload.form.schema.fields.find(fi => fi.id === f)
        );

        checklistFields = sections[1].fields.map(
          f => action.payload.form.schema.fields.find(fi => fi.id === f)
        );
      }

      return {
        ...state,
        inspection: {
          id: action.payload.id,
          workorders: action.payload.open_workorders,
          template: action.payload.template,
          new_version_available: action.payload.new_version_available,
          version_id: action.payload.form.id,
          send_email: action.payload.send_email ? action.payload.send_email : undefined,
          inspection_assignee: action.payload.inspection_assignee ? action.payload.inspection_assignee: undefined,
          info: {
            title: action.type === FETCH_INSPECTION.success
              ? action.payload.title
              : action.payload.form.title,
            icon: action.type === FETCH_INSPECTION.success
              ? action.payload.icon
              : action.payload.form.icon
          },
          airport_changes: action.payload.airport_changes,
          task: action.payload.task,
          access_type: action.payload.access_type,
          form_access: action.payload.form_access,
          details: {
            fields: [...detailFields],
            additionalInfo: action.payload.additionalInfo
          },
          checklist: [...checklistFields]
        },
        fetchInspectionAction: actionForState(action, 'success', 'success')
      };
    }
    case EXPORT_INSPECTION.success: {
      const link = document.createElement('a');
      link.href = action.payload;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // eslint-disable-next-line no-fallthrough
    case EXPORT_INSPECTION_DATA.success: {
      const link = document.createElement('a');
      link.href = action.payload;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // eslint-disable-next-line no-fallthrough
    case CLEAR_INSPECTION:
      return {
        ...state,
        inspection: {},
        templateList: [],
        template: undefined,
        createInspectionAction: {},
        fetchInspectionAction: {},
        createAction: {},
        draftdata: {}
      };
    case CLEAR_INSPECTION_ONLY:
      return {
        ...state,
        inspectionList: { results: [] }
      };
      
    case FETCH_INSPECTION_LIST.pending:
      return {
        ...state,
        action: actionForState('loading')
      };
    case FETCH_INSPECTION_LIST.success:
      return {
        ...state,
        inspectionList: action.payload,
        action: actionForState(action, 'success', 'success')
      };
    case FETCH_INSPECTION_LIST.error:
      if (action.payload.request.status === 403) {
        return {
          ...state,
          action: {
            ...state.action,
            success: false,
            loading: false,
            message: 'failed'
          },
          inspection_permission: true
        };
      }
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        },
        inspection_permission: false
      };

    case FETCH_SUMMARY.pending:
      return {
        ...state,
        action: actionForState('loading')
      };
    case FETCH_SUMMARY.success:
      return {
        ...state,
        summary: action.payload,
        action: actionForState(action, 'success', 'success')
      };
    case FETCH_SUMMARY.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };
    case FETCH_DRAFT_INSPECTION.pending: {
      return {
        ...state,
        fetchDraftInspectionAction: actionForState(state.action, 'pending')
      };
    }

    case FETCH_DRAFT_INSPECTION.success: {
      const theremarks = {};
      const theremarkIds = {};
      const theremarksEdit = [];
      // eslint-disable-next-line no-unused-expressions
      if (action.payload.remarks) {
        action.payload.remarks.forEach((r) => {
          theremarks[r.field_reference] = {
            ...theremarks[r.field_reference],
            [r.item_reference]: {
              text: r.text,
              attachments: r.attachments,
              id: r.id,
              field_reference: r.field_reference,
              item_reference: r.item_reference
            }
          };
          theremarksEdit[r.field_reference] = {
            ...theremarksEdit[r.field_reference],
            [r.item_reference]: {
              text: r.text,
              attachments: r.attachments
            }
          };
          theremarkIds[r.field_reference] = {
            ...theremarkIds[r.field_reference],
            [r.item_reference]: r.id
          };
        });
      }
      return {
        ...state,
        draftdata: {
          ...action.payload,
          remarks: theremarks,
          remarksIDs: theremarkIds,
          remarksEdit: theremarksEdit
        },
        fetchDraftInspectionAction: actionForState(state.action, 'success')
      };
    }
    case FETCH_DRAFT_INSPECTION.error:
      return {
        ...state,
        fetchDraftInspectionAction: actionForState(state.action, 'error')
      };

    case CREATE_INSPECTION_ANSWER.success:
      return {
        ...state,
        answerCreated: action.payload,
        createAction: actionForState(action, 'success', 'success')
      };
    case CREATE_INSPECTION_ANSWER.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };

    case CREATE_DRAFT_INSPECTION_ANSWER.success:
      return {
        ...state,
        draft_answer: action.payload,
        draftAction: actionForState(state.action, 'success')
      };
    case CREATE_DRAFT_INSPECTION_ANSWER.error:
      return {
        ...state,
        draftAction: actionForState(state.action, 'error')
      };
    case CREATE_DRAFT_INSPECTION_ANSWER.pending:
      return {
        ...state,
        draftAction: actionForState(state.action, 'pending')
      };

    case FETCH_DATA_SOURCE_VALUES.success:
      return {
        ...state,
        dataSourceValues: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_DATA_SOURCE_VALUES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_DATA_SOURCE_VALUES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };


    case FETCH_DATA_SOURCES.success:
      return {
        ...state,
        allDataSources: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_DATA_SOURCES.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_DATA_SOURCES.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };

    case FETCH_OPEN_WORKORDERS.success:
      return {
        ...state,
        OpenWorkOrders: action.payload,
        OpenWorkOrdersAction: actionForState(state.action, 'success')
      };

    case FETCH_INSPECTION_ANSWER_LIST.success:
      return {
        ...state,
        inspection_list: actionForState(state.action, 'success'),
        answers: action.payload
      };
    case FETCH_INSPECTION_ANSWER_LIST.pending:
      return {
        ...state,
        inspection_list: actionForState(state.action, 'pending')
      };
    case FETCH_INSPECTION_ANSWER_LIST.error:
      return {
        ...state,
        inspection_list: actionForState(state.action, 'failed')
      };

    case FETCH_INSPECTION_ANSWER.success: {
      const theremarks = {};
      const theremarkIds = {};
      const theremarksEdit = [];
      action.payload.remarks.forEach((r) => {
        theremarks[r.field_reference] = {
          ...theremarks[r.field_reference],
          [r.item_reference]: {
            text: r.text,
            attachments: r.attachments,
            id: r.id,
            field_reference: r.field_reference,
            item_reference: r.item_reference
          }
        };
        theremarksEdit[r.field_reference] = {
          ...theremarksEdit[r.field_reference],
          [r.item_reference]: {
            text: r.text,
            attachments: r.attachments
          }
        };
        theremarkIds[r.field_reference] = {
          ...theremarkIds[r.field_reference],
          [r.item_reference]: r.id
        };
      });
      const theresponse = action.payload.response;
      action.payload.version.schema.fields.forEach((item) => {
        if (item.type === 'inspection' && action.payload.response[item.id]) {
          Object.keys(action.payload.response[item.id]).forEach((key) => {
            if (!theresponse[item.id][key]) {
              let wo = [];
              if (action.payload.open_workorders) {
                wo = action.payload.open_workorders.filter(e => (
                  item.id === e.category_id
                  && key === e.subcategory_id
                ));
              }
              if (wo.length === 0 && (!theremarks.length || (Object.keys(theremarks).length > 0 && !theremarks[item.id]) || (Object.keys(theremarks).length > 0 && theremarks[item.id] && !theremarks[item.id][key]))) {
                theresponse[item.id][key] = true;
              }
              if (wo.length > 0 || (Object.keys(theremarks).length > 0 && theremarks[item.id] && theremarks[item.id][key])) {
                theresponse[item.id][key] = false;
              }
            }
          });
        }
      });
      return {
        ...state,
        answer: {
          ...action.payload,
          response: theresponse,
          remarks: theremarks,
          remarksIDs: theremarkIds,
          remarksEdit: theremarksEdit
        }
      };
    }
    case CLEAR_INSPECTION_ANSWER:
      return {
        ...state,
        answer: {
          version: {},
          response: {}
        },
        answerCreated: {}
      };
    case CLEAR_OPEN_WORKORDERS:
      return {
        ...state,
        OpenWorkOrders: {},
        OpenWorkOrdersAction: ''
      };
    case CLEAR_ACTION_RESULT:
      return {
        ...state,
        action: {},
        templateAction: {},
        fetchInspectionAction: {}
      };
    case CLEAR_ACTION_DATA_SOURCE:
      return {
        ...state,
        dataSourceValues: {}
      };
    case SEARCH_USER.success:
      return {
        ...state,
        userlist: action.payload
      };
    case CREATE_REMARK.success: {
      let remarksdata = {};
      let remarksIds = {};
      let remarkEdit = [];
      if (state.answer.id) {
        remarksdata = state.answer.remarks;
        remarksIds = state.answer.remarksIDs;
        remarkEdit = state.answer.remarksEdit;
      } else {
        remarksdata = state.draftdata.remarks;
        remarksIds = state.draftdata.remarksIDs;
        remarkEdit = state.draftdata.remarksEdit;
      }

      const theremarks= {};
      const theremarkIds = {};
      const theremarksEdit = [];
      const r = action.payload;
      // eslint-disable-next-line no-unused-expressions
      if (r) {
        theremarks[r.field_reference] = {
          ...remarksdata[r.field_reference],
          [r.item_reference]: {
            text: r.text,
            attachments: r.attachments,
            id: r.id,
            field_reference: r.field_reference,
            item_reference: r.item_reference
          }
        };
        theremarksEdit[r.field_reference] = {
          ...theremarksEdit[r.field_reference],
          [r.item_reference]: {
            text: r.text,
            attachments: r.attachments
          }
        };
        theremarkIds[r.field_reference] = {
          ...theremarkIds[r.field_reference],
          [r.item_reference]: r.id
        };
      }
      const remarkData = Object.assign(remarksdata, theremarks);
      const remarkIds = Object.assign(remarksIds, theremarkIds);
      const remarks_Edit = [...remarkEdit, theremarksEdit];
      if (state.answer.id) {
        return {
          ...state,
          answer: {
            ...state.answer,
            remarks: remarkData,
            remarksIDs: remarkIds,
            remarksEdit: remarks_Edit
          }
        };
      }
      return {
        ...state,
        draftdata: {
          ...state.draftdata,
          remarks: remarkData,
          remarksIDs: remarkIds,
          remarksEdit: remarks_Edit
        },
        remarkCreated: action.payload
      };
    }
    case FETCH_SAFETY_SELF_INSPECTION.pending:
      return {
        ...state,
        action: actionForState('loading')
      };

    case FETCH_SAFETY_SELF_INSPECTION.success: {
      if (!action.payload.form) return state;
      const filtered = action.payload.form.schema.fields.filter(e => e.type === 'inspection');
      const insp =[];
      // eslint-disable-next-line no-return-assign
      filtered.map(e => (
        insp[e.id]= {
          title: e.title,
          checklist: e.checklist.map(el => ({ key: el.key, value: el.value })) }
      ));
      return {
        ...state,
        selfInspection: insp,
        action: actionForState(action, 'success', 'success')
      };
    }
    /* ************************** */
    /*          TEMPLATES         */
    /* ************************** */
    case FETCH_TEMPLATES.success:
      return {
        ...state,
        templateList: action.payload,
        action: actionForState(action, 'success', 'success')
      };
    case FETCH_TEMPLATES.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };
    /* ************************** */
    /*          TEMPLATE          */
    /* ************************** */
    case FETCH_TEMPLATE.success: {
      const { sections } = action.payload.schema;

      const detailFields = sections[0].fields.map(
        f => action.payload.schema.fields.find(fi => fi.id === f)
      ).map((f, i) => ({ ...f, order: i, template: true }));

      const checklistFields = sections[1].fields.map(
        f => action.payload.schema.fields.find(fi => fi.id === f)
      ).map((f, i) => ({ ...f, order: i, template: true }));

      return {
        ...state,
        template: {
          id: action.payload.id,
          new_version_available: action.payload.new_version_available,
          selected_version_id: action.payload.selected_version_id,
          info: {
            title: action.payload.title,
            icon: 'icon-1'
          },
          details: {
            fields: [...detailFields],
            additionalInfo: action.payload.additionalInfo
          },
          checklist: [...checklistFields]
        },
        templateAction: actionForState(action, 'success', 'success')
      };
    }
    case FETCH_TEMPLATE.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };
    default:
      return state;
  }
}
