import styled, { css } from 'styled-components';
import { ChatTypes } from '../../enums';

type WrapperProps = {
  viewType: ChatTypes;
};

export const MainWrapper = styled.div`
  display: flex;
`;

export const ChannelsWrapper = styled.div<WrapperProps>`
  width: ${({ viewType }) => (viewType === ChatTypes.comment ? '60%' : '100%')};

  ${({ viewType }) => viewType !== ChatTypes.operations
    && css`
      border-left: solid 1px #e9eff4;
    `};
  transform: translate3d(0%, 0px, 0px);

  .ui.segment {
    padding: unset !important;
    border-radius: unset;
    border-left: unset;
    border-bottom: unset;
    box-shadow: unset;

    border-top: 1px solid ${({ theme }) => theme.colors.background.darker};
    border-right: 1px solid ${({ theme }) => theme.colors.background.darker};
  }
  .ui[class*="vertically divided"].grid > .column:not(.row) {
    margin: unset;
  }

  .chat-container {
    display: flex;
  }

  .sidebar-left-nav {
    transition: all 0.5s ease;
    transform: translate3d(100%, 0px, 0px);
  }
  .sidebar-left-nav {
    transition-duration: 1s;
    transform: translate3d(0%, 0px, 0px);
    transition: width 1s;
  }
  .closed {
    width: 64%;
  }
`;
