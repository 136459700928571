import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from '../maintenanceList.module.scss';

const Status = ({ item }) => {
  const options = {
    0: {
      style: styles.new,
      name: 'New'
    },
    1: {
      style: styles.InProgress,
      name: 'In Progress'
    },
    2: {
      style: styles.closed,
      name: 'Closed'
    }
  };
  return (
    <div className={options[item].style}>
      <FormattedMessage id={`lease.maintenance.list.table.status.${options[item].name}`} defaultMessage={options[item].name} />
    </div>
  );
};

Status.propTypes = {
  item: PropTypes.number.isRequired
};
export default Status;
