import { isLocal, getBackendBaseUrl } from './';

// tslint:disable-next-line: max-line-length
const uploadEndPoint = isLocal ? process.env.REACT_APP_UPLOAD_ENDPOINT
          // tslint:disable-next-line: prefer-template
          : "https://api.chat." + window.location.hostname + "/upload/";
const getFileNameEndPoint = process.env.REACT_APP_GLOBAL_API;
const uploadsEndPoint = getBackendBaseUrl();
export const apiRouts = {
  uploadFile: uploadEndPoint,
  getUploadFileName: getFileNameEndPoint,
  aeroUploads: uploadsEndPoint
};
