import { useState } from 'react';
import {MutationFunctionOptions, OperationVariables, QueryResult} from '@apollo/react-common';
import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation as useMutationBase,
  useQuery as useQueryBase,
} from "@apollo/react-hooks";
import { DocumentNode, GraphQLError } from "graphql";
import { FetchPolicy } from "apollo-client";
import isEmpty from "lodash/isEmpty";

const cacheAndNetwork = "cache-and-network" as FetchPolicy;

export const isUppyFileId = (fileId: string) => {
  return fileId && fileId.startsWith("uppy");
};

export const isCacheId = (id: string) => isUppyFileId(id);

const hasCacheId = <TData, TVariables>(
  options: QueryHookOptions<TData, TVariables>
) => {
  if (!options || !options.variables) {
    return false;
  }

  return isCacheId(options.variables["id"]);
};

export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const errorPolicy = (options && options.errorPolicy) || "all";
  const fetchPolicy = (options && options.fetchPolicy) || cacheAndNetwork;
  const pollInterval = options && options.pollInterval;
  const queryOptions: QueryHookOptions<TData, TVariables> = {
    ...options,
    errorPolicy,
    fetchPolicy: !hasCacheId(options) ? fetchPolicy : "cache-only",
    pollInterval: pollInterval || undefined
  };
  const result = useQueryBase(query, queryOptions);
  let loading = result.loading;
  if (
    queryOptions.fetchPolicy === cacheAndNetwork &&
    !isEmpty(result.data) &&
    isEmpty(result.error)
  ) {
    loading = false;
  }

  return {
    ...result,
    loading
  };
};

type MutationResult<TData, TVariables> = {
  mutate: (options?: MutationFunctionOptions<TData, TVariables>) => Promise<{data?: TData; error?: GraphQLError}>;
  error: GraphQLError;
  saving: boolean;
};

export const useMutation = <TData, TVariables = OperationVariables>(
    mutation: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>
): MutationResult<TData, TVariables> => {
  const [mutateGQL] = useMutationBase(mutation, {errorPolicy: 'all', ...options});
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const mutate = async (options?: MutationFunctionOptions<TData, TVariables>) => {
    setSaving(true);
    try {
      const {data, errors} = await mutateGQL(options);

      if (errors) {
        const error = errors[0];
        setError(error);

        return {
          error,
        };
      }

      return {
        data,
      };
    } finally {
      setSaving(false);
    }
  };

  return {
    error,
    mutate,
    saving,
  };
};
