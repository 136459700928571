import React from 'react';

import { Channels } from './Channels';
import { Comment } from './Comment';
import { StateProvider } from '../../state/channelsState';
import { MainWrapper } from './Channels.styles';
import { ChatTypes } from '../../enums';

const WithProvider = () => (
  <StateProvider>
    <MainWrapper>
      <Channels type={ChatTypes.operations} />
      <Comment type={ChatTypes.comment} />
    </MainWrapper>
  </StateProvider>
);

export default WithProvider;
export { WithProvider as Channels };
