import {
  FETCH_OPERATIONSDASHBOARD,
  CLEAR_OPERATIONSDASHBOARD
} from './types';

const INITIAL_STATE = {
  error: {},
  WorkorderAction: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  loglist: []
};
const actionForState = (action, state) => {
  return {
    ...action,
    success: state === 'success',
    loading: state === 'pending',
    message: state
  };
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_OPERATIONSDASHBOARD.pending:
      return {
        ...state,
        WorkorderAction: actionForState(state.action, 'pending')
      };
    case FETCH_OPERATIONSDASHBOARD.success:
      return {
        ...state,
        loglist: action.payload,
        WorkorderAction: actionForState(state.action, 'success')
      };
    case FETCH_OPERATIONSDASHBOARD.error:
      return {
        ...state,
        WorkorderAction: actionForState(state.action, 'error')
      };
    case CLEAR_OPERATIONSDASHBOARD:
      return {
        ...state,
        LogCreateAction: {},
        gridAction: {},
        loglist: undefined,
        summary: undefined,
        gridList: undefined,
        mapList: undefined,
        log: undefined
      };
    default:
      return state;
  }
}
