const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const FETCH_WILDLIFELOGS = asyncActionType('aerosimple/settings/FETCH_WILDLIFELOGS');
export const GET_WILDLIFELOG = asyncActionType('aerosimple/settings/GET_WILDLIFELOG');
export const ADD_WILDLIFELOGS = asyncActionType('aerosimple/settings/ADD_WILDLIFELOGS');
export const FETCH_LOCATIONGRIDS = asyncActionType('aerosimple/settings/FETCH_LOCATIONGRIDS');
export const ADD_LOCATIONGRIDS = asyncActionType('aerosimple/settings/ADD_LOCATIONGRIDS');
export const FETCH_WILDLIFELOGS_LIST = asyncActionType('aerosimple/settings/FETCH_WILDLIFELOGS_LIST');
export const FETCH_LOCATIONGRIDIMAGE = asyncActionType('aerosimple/settings/FETCH_LOCATIONGRIDIMAGE');
export const ADD_LOCATIONGRIDIMAGE = asyncActionType('aerosimple/settings/ADD_LOCATIONGRIDIMAGE');
export const CLEAR_WILDLIFELOG = 'aerosimple/workorders/CLEAR';
