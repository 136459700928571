/*
 * Global variables.
 */

/* eslint-disable sort-keys */

import { DefaultTheme } from 'styled-components';
import {
  darken,
  saturate,
  desaturate,
  lighten,
  // transparentize
} from '../utils/colors';

// Base values for deriving variations
const borderRadius = 3;
const borderWidth = 1;

export const size = 14;

const colorPrimary = '#3a61a8';
const colorSecondary = '#fff';
const colorText = '#242e42';
const colorBackground = '#fafbfd';
const colorBorder = '#dce0e6';
const white = '#fff';
const lightText = '#8c9ea7';
const lightGreen = '#a6e50f';
const red = '#ef0000';

const theme: DefaultTheme = {
  colors: {
    primary: {
      default: colorPrimary,
      darker: darken(saturate(colorPrimary, 1), 9),
      darkest: darken(saturate(colorPrimary, 2), 13),
      lighter: lighten(desaturate(colorPrimary, 8), 13),
      lightest: lighten(desaturate(colorPrimary, 13), 34)
    },
    secondary: {
      default: colorSecondary,
      darker: colorPrimary,
      darkest: colorPrimary,
      lighter: colorPrimary,
      lightest: colorPrimary
    },
    text: {
      default: colorText,
      primary: colorPrimary,
      darkest: darken(colorText, 50),
      lighter: darken(saturate(white, 2), 43),
      lightest: darken(saturate(white, 2), 13),
      inverse: white,
      red,
      light: lightText
    },
    background: {
      default: colorBackground,
      darker: lighten(colorBorder, 4),
      darkest: darken(colorBackground, 1),
      white,
      blue: colorPrimary,
      lightGreen,
      lightRed: lighten(red, 50)
    },
    border: {
      default: colorBorder,
      light: lighten(colorBorder, 60),
      blue: colorPrimary
    },
    state: {
      attention: 'hsl(50, 89%, 62%)',
      danger: 'hsl(1, 76%, 58%)',
      error: 'hsl(1, 76%, 58%)',
      info: 'hsl(200, 100%, 86%)',
      success: 'hsl(102, 76%, 55%)'
    },
    inherit: 'inherit',
    transparent: 'transparent'
  },
  border: {
    default: `${borderWidth}px solid ${colorBorder}`,
    radius: {
      default: `${borderRadius}px`,
      tiny: `${borderRadius - 2}px`,
      small: `${borderRadius - 1}px`,
      large: `${borderRadius * 2}px`,
      huge: `${borderRadius * 3}px`,
      circle: '50%',
      pill: '999px'
    },
    width: {
      default: `${borderWidth}px`,
      strong: `${borderWidth * 2}px`,
      superStrong: `${borderWidth * 4}px`
    }
  },
  font: {
    size: {
      inherit: 'inherit',
      default: `${size}px`,
      tiny: '0.714rem',
      small: '0.857rem',
      large: '1.5rem',
      huge: '2rem',
      pageTitle: '3rem'
    },
    weight: {
      normal: 400,
      bold: 600
    }
  },
  size: {
    default: `${size}px`,
    tiny: `${size / 4}px`,
    small: `${size / 2}px`,
    large: `${size * 2}px`,
    huge: `${size * 4}px`,
    inherit: 'inherit'
  }
};

export default theme;
