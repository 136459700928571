import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Permissions from 'react-redux-permissions';
import { Transition } from 'react-transition-group';
import { fetchWorkOrderList, fetchWorkOrderListSummary, fetchWorkOrderSchema, clearActionResult } from '../redux/actions';
import { fetchUserProfile } from '../../../components/topbar/profile/redux/actions';
import SectionHeader from '../../../components/sectionHeader';
import IconButton from '../../../components/iconButton';
import Button from '../../../components/button';
import Separator from '../../../components/separator';
import Panel from '../../../components/panel';
import Table from './components/Table';
import Map from './components/Map';
import Paginator from '../../../components/paginator/paginator';
/** ******************************************************************
 *  Assets import
 * ************* */

import settings from '../../../icons/settings.svg';
import Search from '../../../components/search/Search';
import styles from './workOrderList.module.scss';
import workOrder from '../../../icons/WorkOrders.png';
import { WORKORDERS_HOME_ROUTE } from '../../../constants/RouterConstants';
import Loading from '../../../components/loading';
import { fetchShiftTypes } from '../../settings/redux/actions';

import Toolbar from './components/Toolbar';
import FilterForm from './components/FilterForm';


class WorkOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workorderdatapriority: 0,
      workorderData: [],
      currentSortState: '',
      currentSortDirection: null,
      loadingStatus: false,
      filterPopup: false,
      searchQuery: '',
      initialfilter: false,
      filterCategories: localStorage.getItem('workorder_filters') ? JSON.parse(localStorage.getItem('workorder_filters')) : ['Maintenance'],
      filterShifts: localStorage.getItem('workorder_shifts') !== null ? JSON.parse(localStorage.getItem('workorder_shifts')) : [],
      filterDates: '',
      currentPage: 1,
      selectionRange: {
        startDate: localStorage.getItem('fstart') || moment().subtract(30, 'days').utc().format(),
        endDate: localStorage.getItem('fend') || moment().utc().format(),
        key: 'selection'
      },
      range: localStorage.getItem('wrange')||'Last 30 days'
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.work_order_action.success && state.loadingStatus) {
      return {
        ...state,
        loadingStatus: false
      };
    }
    return state;
  }

  async componentDidMount() {
    const {
      actionList,
      workorders,
      actionListSummary,
      profile,
      actionfetchProfile,
      actionGetSchemas,
      schemas,
      actionFetchShiftTypes,
      shiftTypes } = this.props;
    if (Object.keys(schemas).length===0) {
      await actionGetSchemas();
    }
    const { filterCategories, filterShifts, searchQuery, selectionRange } = this.state;
    if (!shiftTypes.length) actionFetchShiftTypes();
    let s= localStorage.getItem('fstart')||localStorage.getItem('wstart_date')||'';
    let f= localStorage.getItem('fend')||localStorage.getItem('wend_date')||'';
    if (!profile.id) actionfetchProfile();
    let result='';
    if (filterCategories.length===1) {
      result=`${filterCategories[0]},,`;
    } else {
      result = filterCategories.join(',,');
    }
    const page= localStorage.getItem('wpage') || 1;
    let result1='';
    if (filterShifts.length>0) {
      let result2='';
      result2 = filterShifts.join(',,');
      if (filterShifts.length === 1) {
        result2= `${filterShifts[0]},,`;
        if (s!==''&&f!=='') {
          actionListSummary(searchQuery, result, result2, s, f);
        } else {
          s=new Date(moment(selectionRange.startDate));
          f =new Date(moment(selectionRange.endDate));
          s.setHours(0, 0, 0, 0);
          f.setHours(23, 59, 59, 999);
          actionListSummary(searchQuery, result, result2);
          localStorage.setItem('wstart_date', s);
          localStorage.setItem('wend_date', f);
          s='';
          f='';
        }
      } else {
        if (s!==''&&f!=='') {
          actionListSummary(searchQuery, result, result2, s, f);
        } else {
          s=moment().subtract(30, 'days').utc().format();
          f=moment().utc().format();
          // s.setHours(0, 0, 0, 0);
          // f.setHours(23, 59, 59, 999);
          actionListSummary(searchQuery, result, result2, s, f);
          localStorage.setItem('fstart', s);
          localStorage.setItem('fend', f);
          s='';
          f='';
        }
        s=new Date(moment(selectionRange.startDate));
        f =new Date(moment(selectionRange.endDate));
        s.setHours(0, 0, 0, 0);
        f.setHours(23, 59, 59, 999);
        if (localStorage.getItem('aerobot_workorders')) {
          actionListSummary(searchQuery, result, undefined);
        } else {
          actionListSummary(searchQuery, result, result2);
        }
        localStorage.setItem('fstart', s);
        localStorage.setItem('fend', f);
        s='';
        f='';
      }
    } else if (s!==''&&f!=='') {
      if (localStorage.getItem('aerobot_workorders')) {
        actionListSummary(searchQuery, result, undefined);
      } else {
        actionListSummary(searchQuery, result, undefined, s, f);
      }
    } else {
      s=moment().subtract(30, 'days').utc().format();
      f=moment().utc().format();
      // s.setHours(0, 0, 0, 0);
      // f.setHours(23, 59, 59, 999)
      if (localStorage.getItem('aerobot_workorders')) {
        actionListSummary(searchQuery, result, undefined);
      } else {
        actionListSummary(searchQuery, result, undefined, s, f);
      }
      localStorage.setItem('fstart', s);
      localStorage.setItem('fend', f);
      s='';
      f='';
    }
    if (filterShifts.length>0) {
      if (filterShifts.length===1) {
        result1= `,,--~${filterShifts[0]},,`;
        result+= result1;
        localStorage.setItem('sresult1', `--~${filterShifts[0]},,`);
      }
      if (filterShifts.length>1) {
        result1=filterShifts.join(',,--~');
        result+= `,,--~${result1}`;
        localStorage.setItem('sresult1', `--~${result1},,`);
      }
      if (s!==''&& f!=='') {
        result1= `,,${`[${s}(*^)${f}]`},,`;
        result+= result1;
        localStorage.setItem('result1', result1);
        actionList(page, searchQuery, result);
      } else {
        s=moment().subtract(30, 'days').utc().format();
        f=moment().utc().format();
        // s.setHours(0, 0, 0, 0);
        // f.setHours(23, 59, 59, 999);
        result1= `,,${`[${s.toISOString()}(*^)${f.toISOString()}]`},,`;
        this.setState({ filterDates: result1 });
        actionList(page, searchQuery, result);
        s='';
        f='';
      }
    } else if (s!==''&& f!=='') {
      if (localStorage.getItem('aerobot_workorders')) {
        actionList(page, searchQuery, result);
      } else {
        result1= `,,${`[${s}(*^)${f}]`},,`;
        result+= result1;
        localStorage.setItem('result1', result1);
        actionList(page, searchQuery, result);
      }
    } else {
      s=moment().subtract(30, 'days').utc().format();
      f=moment().utc().format();
      // s.setHours(0, 0, 0, 0);
      if (localStorage.getItem('aerobot_workorders')) {
        actionList(page, searchQuery, result);
      } else {
        result1= `,,${`[${s}(*^)${f}]`},,`;
        result+= result1;
        actionList(page, searchQuery, result);
      }
    }
    if (workorders.length === 0) {
      this.setState({ loadingStatus: true });
    }
  }


  componentDidUpdate(prevProps) {
    const { work_order_action, actionGetSchemas, schemas, workorder_list_summary } = this.props;
    const { filterCategories, filterShifts, initialfilter }= this.state;
    if (prevProps.work_order_action.loading === true && work_order_action.loading === false) {
      this.setState({ loadingStatus: work_order_action.loading });
    }
    if (prevProps.workorder_list_summary.loading === true && workorder_list_summary.loading === false) {
      this.setState({ loadingStatus: workorder_list_summary.loading });
    }
    if (Object.keys(schemas).length===0) {
      actionGetSchemas();
    }

    if (Object.keys(prevProps.schemas).length===0 && Object.keys(schemas).length>0 && !initialfilter) {
      const extcat = localStorage.getItem('workorder_filters')
        ? JSON.parse(localStorage.getItem('workorder_filters')):filterCategories;
      extcat.forEach((a, i) => {
        if (a==='Operations') {
          extcat.splice(i, 1);
        }
      });
      if (!schemas.operations.disabled) {
        if (extcat.indexOf('Operations')<0) {
          this.setState({
            filterCategories: [
              ...extcat,
              'Operations'
            ],
            initialfilter: true
          });
          if (!extcat.indexOf('Operations')) localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));

          this.updateFilter(undefined, [...filterCategories], filterShifts);
        } else {
          const ind=extcat.indexOf('Operations');
          if (ind>=0) {
            extcat.splice(ind, 1);
          }
          this.setState({
            filterCategories: [
              ...extcat,
              'Operations'
            ],
            initialfilter: true
          });
          if (!extcat.indexOf('Operations')) localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));

          this.updateFilter(undefined, [...filterCategories], filterShifts);
        }
      } else if (extcat.indexOf('Operations')>=0) {
        extcat.forEach((a, i) => {
          if (a==='Operations') {
            extcat.splice(i, 1);
          }
        });
        const ind=extcat.indexOf('Operations');
        if (ind>=0) {
          extcat.splice(ind, 1);
        }
        localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));
        this.updateFilter(undefined, [...extcat], filterShifts);
      } else {
        localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));
        this.updateFilter(undefined, [...extcat], filterShifts);
      }
    }
  }

  componentWillUnmount() {
    this.setState({ initialfilter: false });
  }

  goToStart = () => {
    const { history } = this.props;
    history.push(`${WORKORDERS_HOME_ROUTE}start`);
  }

  handleFiltersPopup = () => {
    const { filterPopup } = this.state;
    const { schemas } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const filterCategories = localStorage.getItem('workorder_filters')
      ? JSON.parse(localStorage.getItem('workorder_filters'))
      :schemas.operations.disabled? ['Maintenance']:['Maintenance', 'Operations'];
    this.setState({ filterPopup: !filterPopup, filterCategories });
  }


   sortWorkorderData= (key) => {
     const { workorders }=this.props;
     const { currentSortState, currentSortDirection }= this.state;
     if (key==='order_number') {
       if (currentSortState===key && currentSortDirection === 'descending') {
         this.setState({
           workorderData: workorders.sort((a, b) => ((a.order_number > b.order_number) ? 1 : -1)),
           currentSortState: 'order_number',
           currentSortDirection: 'ascending'
         });
         return;
       }
       this.setState({
         workorderData: workorders.sort((a, b) => ((a.order_number > b.order_number) ? -1 : 1)),
         currentSortState: 'order_number',
         currentSortDirection: 'descending'
       });
     }
     if (key==='category') {
       if (currentSortState===key && currentSortDirection === 'descending') {
         this.setState({
           workorderData: workorders.sort((a, b) => ((a.category > b.category) ? 1 : -1)),
           currentSortState: 'category',
           currentSortDirection: 'ascending'

         });
         return;
       }
       this.setState({
         workorderData: workorders.sort((a, b) => ((a.category > b.category) ? -1 : 1)),
         currentSortState: 'category',
         currentSortDirection: 'descending'

       });
     }
     if (key==='Status') {
       if (currentSortState===key && currentSortDirection === 'descending') {
         this.setState({
           workorderData: workorders.sort((a, b) => ((a.status > b.status) ? 1 : -1)),
           currentSortState: 'Status',
           currentSortDirection: 'ascending'

         });
         return;
       }
       this.setState({
         workorderData: workorders.sort((a, b) => ((a.status > b.status) ? -1 : 1)),
         currentSortState: 'Status',
         currentSortDirection: 'descending'

       });
     }
     if (key==='report_date') {
       if (currentSortState===key && currentSortDirection === 'descending') {
         this.setState({
           workorderData: workorders.sort((a, b) => ((a.report_date > b.report_date) ? 1 : -1)),
           currentSortState: 'report_date',
           currentSortDirection: 'ascending'

         });
         return;
       }
       this.setState({
         workorderData: workorders.sort((a, b) => ((a.report_date > b.report_date) ? -1 : 1)),
         currentSortState: 'report_date',
         currentSortDirection: 'descending'

       });
     }
     if (key==='Priority') {
       if (currentSortState===key && currentSortDirection === 'descending') {
         this.setState({
           workorderData: workorders.sort((a, b) => ((a.priority > b.priority) ? 1 : -1)),
           currentSortState: 'Priority',
           currentSortDirection: 'ascending'

         });
         return;
       }
       this.setState({
         workorderData: workorders.sort((a, b) => ((a.priority > b.priority) ? -1 : 1)),
         currentSortState: 'Priority',
         currentSortDirection: 'descending'

       });
     }
   }

  handelShowSettings= () => {
    const { profile }= this.props;
    for (let i=0; i<profile.roles.length; i+=1) {
      for (let j=0; j<profile.roles[i].permissions.length; j+=1) {
        if (profile.roles[i].permissions[j].codename==='can_modify_airport_settings') {
          return true;
        }
      }
    }
  }

  handleDateChange = (sd, fd, range) => {
    this.setState({ filterDates: [] });
    localStorage.setItem('wstart_date', sd);
    localStorage.setItem('wend_date', fd);
    if (range) {
      localStorage.setItem('wrange', range);
    } else {
      localStorage.removeItem('wrange');
    }
    let s = new Date(sd);
    let f = new Date(fd);
    s.setHours(0, 0, 0, 0);
    f.setHours(23, 59, 59, 999);
    s = s.toISOString();
    f = f.toISOString();
    localStorage.setItem('fstart', s);
    localStorage.setItem('fend', f);
    const date= `[${s}(*^)${f}]`;
    this.setState({ filterDates: date });
    localStorage.setItem('filterdate', date);
    this.updateFilter(date, this.state.filterCategories, this.state.filterShifts);
  }

  updateFilter = (date, filterCategories, filterShifts) => {
    const { searchQuery, filterDates } = this.state;
    const { actionList, actionListSummary } = this.props;
    const s = localStorage.getItem('fstart');
    const f = localStorage.getItem('fend');
    const filterCategoriess = filterCategories;
    for (let i=0; i<filterShifts.length; i+=1) {
      if (filterCategoriess.includes(filterShifts[i])) {
        const index = filterCategoriess.indexOf(filterShifts[i]);
        filterCategoriess.splice(index, 1);
      }
    }
    localStorage.setItem('workorder_filters', JSON.stringify(filterCategories));
    let result = filterCategoriess.join(',,');
    let result1= '';
    if (filterCategoriess.length === 1) {
      result= `${filterCategoriess[0]},,`;
    }
    if (filterShifts.length===1) {
      result1= `,,--~${filterShifts[0]},,`;
      result+= result1;
      localStorage.setItem('sresult1', `--~${filterShifts[0]}`);
    }
    if (filterShifts.length>1) {
      result1=filterShifts.join(',,--~');
      result+= `,,--~${result1}`;
      localStorage.setItem('sresult1', `--~${result1}`);
    }
    if (filterDates !== ' ' && s && f) {
      const l= filterDates.length;
      result1= `,,${`[${s}(*^)${f}]` || filterDates[l]},,`;
      result+= result1;
      localStorage.setItem('result1', result1);
    }
    actionList(1, searchQuery, result);
    result = filterCategoriess.join(',,');
    if (filterCategoriess.length === 1) {
      result= `${filterCategoriess[0]},,`;
    }
    localStorage.setItem('workorder_shifts', JSON.stringify(filterShifts));
    result1 = filterShifts.join(',,');
    if (filterShifts.length === 1) {
      result1= `${filterShifts[0]},,`;
    }
    actionListSummary(searchQuery, result, result1, s, f);
    this.setState({ filterPopup: false,
      currentPage: 1,
      filterCategories: [...filterCategoriess],
      workorderData: [],
      filterShifts: [...filterShifts]
    });
  }

  changePage = (page) => {
    this.setState({ currentPage: page });
    const { searchQuery, filterCategories, filterShifts } = this.state;
    const { shiftTypes, schemas, actionList } = this.props;
    const extcat = localStorage.getItem('workorder_filters')
      ? JSON.parse(localStorage.getItem('workorder_filters')):filterCategories;
    if (schemas.operations&&!schemas.operations.disabled) {
      if (extcat.indexOf('Operations')<0) {
        extcat.forEach((a, i) => {
          if (a==='Operations') {
            extcat.splice(i, 1);
          }
        });
        this.setState({
          filterCategories: [
            ...extcat,
            'Operations'
          ],
          initialfilter: true
        });
        if (!extcat.indexOf('Operations')) localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));
      } else {
        extcat.forEach((a, i) => {
          if (a==='Operations') {
            extcat.splice(i, 1);
          }
        });
        const ind=extcat.indexOf('Operations');
        if (ind>=0) {
          extcat.splice(ind, 1);
        }
        this.setState({
          filterCategories: [
            ...extcat,
            'Operations'
          ],
          initialfilter: true
        });
        if (!extcat.indexOf('Operations'))localStorage.setItem('workorder_filters', JSON.stringify([...extcat, 'Operations']));
      }
    }
    const filterCategoriess=localStorage.getItem('workorder_filters') ? JSON.parse(localStorage.getItem('workorder_filters')) : filterCategories;
    for (let s=0; s<shiftTypes.length; s+=1) {
      if (filterCategoriess.indexOf(shiftTypes[s])>0) {
        const index=filterCategoriess.indexOf(shiftTypes[s].name);
        filterCategoriess.splice(index, 1);
      }
    }
    let result = filterCategoriess.join(',,');
    if (filterCategoriess.length === 1) {
      result= `${filterCategoriess[0]},,`;
    }
    if (filterShifts.length) {
      if (localStorage.getItem('sresult1')) {
        result += `,,${localStorage.getItem('sresult1')}`;
      }
    }

    if (localStorage.getItem('result1') && localStorage.getItem('fstart') && localStorage.getItem('fend')) {
      result += localStorage.getItem('result1');
      if (localStorage.getItem('aerobot_workorders')) {
        result = localStorage.getItem('aerobot_workorders');
      }
    } else if (localStorage.getItem('aerobot_workorders')) {
      result = localStorage.getItem('aerobot_workorders');
    } else {
      const s=moment().subtract(30, 'days').utc().format();
      const f=moment().utc().format();
      const result1= `,,${`[${s}(*^)${f}]`},,`;
      result+= result1;
    }
    if (searchQuery.length > 0 || filterCategories.length > 0) {
      localStorage.setItem('wpage', page);
      actionList(page, searchQuery, result);
    } else {
      actionList(page);
    }
    this.setState({
      workorderData: []
    });
  }

  displaySearchResults = (query) => {
    const { actionList, actionListSummary } = this.props;
    this.setState({ searchQuery: query });
    actionList(1, query);
    actionListSummary(query);
  }

  handleOutsideClick = () => {
    const { filterPopup } = this.state;
    const { schemas } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const filterCategories = localStorage.getItem('workorder_filters')
      ? JSON.parse(localStorage.getItem('workorder_filters'))
      :schemas.operations.disabled? ['Maintenance']:['Maintenance', 'Operations'];
    if (filterPopup) {
      this.setState({ filterPopup: false, filterCategories });
    }
  }

  handleRemoveFilter = (val) => {
    const { filterCategories, filterShifts } = this.state;
    if (filterShifts.includes(val)) {
      const filteredItems = filterShifts.filter(item => item !== val);
      this.setState({ filterShifts: filteredItems }, () => {
        localStorage.setItem('workorder_shifts', JSON.stringify(filteredItems));
        this.updateFilter(undefined, filterCategories, filteredItems);
      });
    } else {
      const filteredItems = filterCategories.filter(item => item !== val);
      this.setState({ filterCategories: filteredItems }, () => {
        localStorage.setItem('workorder_filters', JSON.stringify(filteredItems));
        this.updateFilter(undefined, filteredItems, filterShifts);
      });
    }
  }

  render() {
    const {
      workorders,
      user,
      history,
      translations,
      profile,
      workorderslist,
      shiftTypes,
      workorderslistSummary,
      schemas,
      intl
    } = this.props;
    const { filterPopup: popup }=this.state;
    const { workorderdatapriority,
      loadingStatus,
      filterCategories,
      filterShifts,
      range,
      workorderData,
      currentPage,
      currentSortState,
      // eslint-disable-next-line no-unused-vars
      selectionRange }= this.state;


    const translationMap = (translations && translations[user.language])
      ? translations[user.language] : {};


    return (
      <div className={styles.list} ref={(node) => { this.node = node; }}>
        <Loading loadingStatus={loadingStatus} />
        <SectionHeader icon={workOrder} translationID="workorders.title" defaultTitle="Work Orders">
          <div className={styles.detailHeader}>
            <Search onSubmit={query => this.displaySearchResults(query)} />
            {profile.id!=='' && this.handelShowSettings()===true ? (
              <IconButton
                icon={settings}
                onClick={() => { history.push(`${WORKORDERS_HOME_ROUTE}settings`); }}
              />
            ):''}
            <Separator />
            <Permissions allowed={['add_workorder']}>
              <Button translationID="workorders.newWorkOrder"
                defaultText="New Work order" onClick={this.goToStart}
              />
              <Separator />
            </Permissions>
          </div>
        </SectionHeader>
        <div>
          <Toolbar
            // updateFilter={this.updateFilter}
            handleDropdown={this.handleFiltersPopup}
            filterCategories={filterCategories.concat(filterShifts)}
            workorderslistSummary={workorderslistSummary}
            range={range}
            dates={[moment(selectionRange.startDate), moment(selectionRange.endDate)]}
            intl={intl}
            onDateChange={this.handleDateChange}
            onFilterChange={this.handleFilterChange}
          />
        </div>
        <div className={styles.filter}>
          {[...filterCategories, ...filterShifts].map(category => (
            <span className={styles.user} key={category}>
              {category}
              <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
                onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
              />
            </span>
          ))}
        </div>
        <div className={`container ${styles.container}`}>
          <div className={styles.info}>
            <Panel title="workorders.list.summary" defaultTitle="Work Order Summary">
              <div className={styles.stats}>
                <span className={styles.statsMaintenance}>
                  {workorderslistSummary && workorderslistSummary.filter(e => e.status === 1).length}
                </span>
                <FormattedMessage id="workorders.list.maintenance_review" defaultMessage="Maintenance Review" />
              </div>
              <div className={styles.stats}>
                <span className={styles.statsOperations}>
                  {workorderslistSummary && workorderslistSummary.filter(e => e.status === 2).length}
                </span>
                <FormattedMessage id="workorders.list.operations_review" defaultMessage="Operations Review" />
              </div>
              <div className={styles.stats}>
                <span className={styles.statsCompleted}>
                  {workorderslistSummary && workorderslistSummary.filter(e => e.status === 3).length}
                </span>
                <FormattedMessage id="workorders.list.completed" defaultMessage="Completed" />
              </div>
            </Panel>
            { user.airport.location.coordinates
            && (
            <Panel title="workorders.list.open" defaultTitle="Open Work Orders">
              { workorderdatapriority !== 0
                ? (
                  <Map
                    workorders={workorderslistSummary.filter(e => e.status === workorderdatapriority)}
                    defaultLocation={user.airport.location.coordinates}
                    paginatedWorkorders={workorders}
                  />
                )
                :(
                  <Map
                    workorderList
                    workorders={workorderslistSummary}
                    paginatedWorkorders={workorders}
                    defaultLocation={user.airport.location.coordinates}
                  />
                )
               }
            </Panel>
            )
            }
          </div>
          { workorderdatapriority !== 0 ? (
            <Table
              info={workorders.filter(e => e.status === workorderdatapriority)}
              translations={translations}
            />
          )
            : (
              <Table info={workorderData.length>0 ? workorderData : workorders}
                sortData={key => this.sortWorkorderData(key)}
                translations={translationMap}
                sortKey={currentSortState}
              />)}
          { Object.keys(workorders).length > 0
            && (
              <Paginator
                page={currentPage}
                totalRecords={workorderslist.count}
                pageLimit={20}
                pageNeighb ours={1}
                onPageChanged={page => this.changePage(page)}
              />
            )
          }
        </div>
        {popup && (
          <Transition in={popup} timeout={200} unmountOnExit>
            {state => (
              <FilterForm
                schemas={schemas}
                handleOutsideClickFilter={this.handleOutsideClick}
                transition={this.slideStyles[state]}
                update={this.updateFilter}
                filterCategories={filterCategories}
                filterShifts={filterShifts}
                shifts={shiftTypes}
                closePopUp={this.handleFiltersPopup}
              />
            )}
          </Transition>
        )}
      </div>
    );
  }
}


WorkOrderList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  workorders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  work_order_action: PropTypes.shape({}).isRequired,
  actionList: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  workorders: state.workorders.workorders,
  workorderslist: state.workorders.workorderlist,
  workorderslistSummary: state.workorders.workordersSummary,
  work_order_action: state.workorders.work_order_action,
  workorder_list_summary: state.workorders.workorder_list_summary_action,
  user: state.auth.profile,
  translations: state.auth.translations,
  profile: state.auth.profile,
  schemas: state.workorders.schemas,
  shiftTypes: state.settings.shiftTypes
});

const mapDispatchToProps = dispatch => ({
  // Fetch Workorder List
  actionList: (page, query, action) => {
    dispatch(fetchWorkOrderList(page, encodeURIComponent(query), encodeURIComponent(action)));
  },
  actionfetchProfile: () => {
    dispatch(fetchUserProfile());
  },
  // Fetch Workorder List Summary
  actionListSummary: (query, action, result1, s, f) => {
    dispatch(fetchWorkOrderListSummary(query, encodeURIComponent(action), encodeURIComponent(result1), s, f));
  },
  actionGetSchemas: () => {
    dispatch(fetchWorkOrderSchema());
  },
  clear: () => {
    dispatch(clearActionResult());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  }

});

WorkOrderList.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  history: {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WorkOrderList));
