import React from 'react';
import { Wrapper } from './NoChat.styles';

type NoChatProps = {};

const NoChat: React.FC<NoChatProps> = () => (
  <Wrapper>
    <h3>No Channel Are Created</h3>
    <h3>Please Do One</h3>
  </Wrapper>
);

export default NoChat;
export { NoChat };
