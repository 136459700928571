import { CREATE_MAP, LOAD_MAP, CREATE_TYPE, LOAD_TYPE, UPDATE_MAP, REMOVE_MAP, CLEAR_SURFACE_ACTION } from './types';

const INITIAL_STATE = {
  surfaces: [],
  types: [],
  action: {},
  createAction: {
    success: false,
    loading: false,
    error: false
  },
  list_action: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  updateAction: {
    success: false,
    loading: false,
    error: false
  },
  deleteAction: {
    success: false,
    loading: false,
    error: false
  }
};

const actionForState = (action, state) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: state
});


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_MAP.success: {
      const surfaces = state.surfaces.filter(e => e.id !== action.payload.id);
      const aux = action.payload;
      aux.surface_type = state.types.find(e => e.key === action.payload.surface_type);
      aux.surface_type.id = aux.surface_type.key;
      surfaces.push(aux);
      return {
        ...state,
        surfaces,
        createAction: actionForState(state.action, 'success')
      };
    }
    case CREATE_MAP.error:
      return {
        ...state,
        createAction: actionForState(state.action, 'error')
      };
    case LOAD_MAP.success:
      return {
        ...state,
        surfaces: action.payload.results,
        action: {
          ...state.action,
          success: true,
          loading: false,
          message: 'success'
        },
        list_action: actionForState(state.action, 'success')
      };
    case LOAD_MAP.pending:
      return {
        ...state,
        list_action: actionForState(state.action, 'pending')
      };
    case LOAD_MAP.error:
      return {
        ...state,
        action: {
          ...state.action,
          sucess: false,
          loading: false,
          message: 'failed'
        },
        list_action: actionForState(state.action, 'error')
      };
    case CREATE_TYPE.success:
      return {
        ...state,
        action: {
          ...state.action,
          success: true,
          loading: false,
          message: 'success'
        }
      };
    case CREATE_TYPE.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };
    case LOAD_TYPE.success:
      return {
        ...state,
        types: action.payload.results.map(
          o => ({ key: o.id, name: o.name, color: o.color })
        )
      };
    case LOAD_TYPE.error:
      return state;
    case UPDATE_MAP.pending:
      return {
        ...state,
        updateAction: actionForState(state.action, 'pending')
      };
    case UPDATE_MAP.success: {
      const surfaces = state.surfaces.filter(e => e.id !== action.payload.id);
      const aux = action.payload;
      aux.surface_type = state.types.find(e => e.key === action.payload.surface_type);
      aux.surface_type.id = aux.surface_type.key;
      surfaces.push(aux);
      return {
        ...state,
        surfaces,
        updateAction: actionForState(state.action, 'success')
      };
    }

    case UPDATE_MAP.error:
      return {
        ...state,
        updateAction: actionForState(state.action, 'error')
      };
    case REMOVE_MAP.pending:
      return {
        ...state,
        deleteAction: actionForState(state.action, 'pending')
      };
    case REMOVE_MAP.success:
      return {
        ...state,
        deleteAction: actionForState(state.action, 'success')
      };
    case REMOVE_MAP.error:
      return {
        ...state,
        deleteAction: actionForState(state.action, 'error')
      };

    case CLEAR_SURFACE_ACTION:
      return {
        ...state,
        surfaces: [],
        createAction: {
          success: false, loading: false, error: false
        },
        deleteAction: {
          success: false, loading: false, error: false
        },
        updateAction: {
          success: false, loading: false, error: false
        }
      };
    default:
      return state;
  }
}
