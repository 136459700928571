/* eslint-disable react-hooks/exhaustive-deps */
/* global localStorage */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import { HOC as Permissions } from 'react-redux-permissions';
import FilterForm from './FilterForm';

/** ******************************************************************
 *  Redux import
 * ************* */
import { fetchLogs, clear, fetchLogFormSchema } from '../redux/actions';
import { LOGS_HOME_ROUTE } from '../../../constants/RouterConstants';
import { fetchShiftTypes } from '../../settings/redux/actions';

/** ******************************************************************
 *  Components import
 * ************* */
import SectionHeader from '../../../components/sectionHeader';
import IconButton from '../../../components/iconButton';
import Separator from '../../../components/separator';
import Button from '../../../components/button';
import Table from './components/LogTable';
import Loading from '../../../components/loading';

/** ******************************************************************
 *  Assets import
 * ************* */

import styles from './OperationLogList.module.scss';
import settings from '../../../icons/settings.svg';
import preset from '../../../icons/Preset.svg';
import Paginator from '../../../components/paginator/paginator';
import Filter from './components/Filter';
import Modal from '../../../components/modal';
import OperationLogDetails from '../Details/OperationLogDetails';
import Search from '../../../components/search/Search';
import { getDatesFromRange } from '../../../utils/helpers';
import Forbidden from '../../Forbidden';

const OperationLogList = ({ actionFetchShiftTypes, actionFetch, loglist, shiftTypes, history, actionClear, profile }) => {
  const [range, setRange] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState();
  const [query, setquery] = useState();
  const [popup, setfilterpopup] = useState(false);
  const [filterCategories, setfilterCategories] = useState([]);
  const [sortType, setSortType] = useState('asc');
  const [filterdate, setfilterdate] = useState(false);
  const [filterGroupBy,setFilterGroupBy] = useState({ key:0 , name:"Shift" })
  const slideStyles = {
    entered: { transform: 'translate3d(0px,0px)' }
  };
  const groupByFilterOptions = [
    { key: 0, name: 'Shift' },
    { key: 1, name: 'None' }
  ];


  // gets items from localstorage and sets up the dates.
  useEffect(() => {
    const storedRange = localStorage.getItem('log_range');
    let s; let f;
    if (storedRange) {
      [s, f] = getDatesFromRange(storedRange);
      s = s.toISOString();
      f = f.toISOString();
    } else {
      s = localStorage.getItem('log_start_date');
      f = localStorage.getItem('log_end_date');
      if (s && f) {
        s = new Date(s);
        f = new Date(f);
      } else {
        s = new Date();
        s.setDate(s.getDate() - 30);
        f = new Date();
        localStorage.setItem('log_range', 'Last 30 days');
      }
      s.setHours(0, 0, 0, 0);
      f.setHours(23, 59, 59, 99);
      s = s.toISOString();
      f = f.toISOString();
    }

    if (s && f) {
      setRange({ s, f });
    } else {
      setRange({ s, f });
    }
    setInitialized(true);
    actionFetchShiftTypes();
  }, []);

  const handleFiltersList = (value) => {
    let filterslist = '';
    value.forEach((item) => {
      const str = `${item},`;
      filterslist += str;
    });
    return filterslist;
  };

  useEffect(() => {
    if (initialized) {
      let filter;
      if (localStorage.getItem('ops_log_filter')) {
        filter = localStorage.getItem('ops_log_filter');
        filter = filter.split(',');
        const filterslist = handleFiltersList(filter);
        setfilterCategories(filter);
        if (localStorage.getItem('log_start_date')) {
          actionFetch(1, range.s, range.f, undefined, filterslist);
        } else {
          actionFetch(1, undefined, undefined, undefined, filterslist);
        }
      } else {
        actionFetch(1, range.s, range.f);
      }
    }
  }, [actionFetch, initialized, range]);

  const handleRowSelect = (e) => {
    setModal(true);
    setSelected(e);
  };

  const updateFilter = (filters) => {
    setfilterCategories([...filters]);
    if (filters.length > 0) {
      const filterslist = handleFiltersList(filters);
      actionClear();
      if (filterdate) {
        actionFetch(1, range.s, range.f, undefined, filterslist);
      } else {
        actionFetch(1, undefined, undefined, undefined, filterslist);
        localStorage.removeItem('log_start_date');
        localStorage.removeItem('log_end_date');
      }
      localStorage.setItem('ops_log_filter', filters);
    } else {
      setfilterCategories([]);
      actionClear();
      actionFetch(1, range.s, range.f);
      localStorage.removeItem('ops_log_filter');
    }
    setfilterpopup(false);
  };

  const handleDateChange = (s, f, r) => {
    localStorage.setItem('log_start_date', s);
    localStorage.setItem('log_end_date', f);
    if (r) {
      localStorage.setItem('log_range', r);
    } else {
      localStorage.removeItem('log_range');
    }
    const start = s.toDate();
    const first = f.toDate();
    start.setHours(0, 0, 0, 0);
    first.setHours(23, 59, 59, 99);
    const sFinal = start.toISOString();
    const fFinal = first.toISOString();
    setRange({ s: sFinal, f: fFinal });
    setfilterdate(true);
    actionClear();
  };

  const handleFiltersPopup = () => {
    setfilterpopup(!popup);
  };

  const handleOutsideClick = () => {
    if (popup) setfilterpopup(false);
  };

  const handleSort = () => {
    if (sortType==='asc') {
      setSortType('desc');
      loglist.results.sort((a, b) => {
        if (a.type < b.type) { return -1; }
        if (a.type > b.type) { return 1; }
        return 0;
      });
    } if (sortType==='desc') {
      setSortType('asc');
      return loglist.results.reverse();
    }
  };

  const handleRemoveFilter = (val) => {
    const filteredItems = filterCategories.filter(item => item !== val);
    if (filteredItems.length > 0) {
      setfilterCategories(filteredItems);
      const filterslist = handleFiltersList(filteredItems);
      localStorage.setItem('ops_log_filter', filteredItems);
      actionClear();
      if (filterdate) {
        actionFetch(1, range.s, range.f, undefined, filterslist);
      } else {
        actionFetch(1, undefined, undefined, undefined, filterslist);
      }
    } else {
      setfilterCategories([]);
      actionClear();
      actionFetch(1, range.s, range.f);
      localStorage.removeItem('ops_log_filter');
    }
    setfilterpopup(false);
  };

  const changePage = (e) => {
    if (query) {
      actionFetch(e, undefined, undefined, query, undefined);
      return;
    }
    if (localStorage.getItem('ops_log_filter')) {
      let filter = localStorage.getItem('ops_log_filter');
      filter = filter.split(',');
      const filterslist = handleFiltersList(filter);
      if (filterdate || localStorage.getItem('log_start_date')) actionFetch(e, range.s, range.f, undefined, filterslist);
      else actionFetch(e, undefined, undefined, undefined, filterslist);
    } else {
      actionFetch(e, range.s, range.f);
    }
  };

  const handelShowSettings= () => {
    let status = false;
    for (let i=0; i<profile.roles.length; i+=1) {
      for (let j=0; j<profile.roles[i].permissions.length; j+=1) {
        if (profile.roles[i].permissions[j].codename==='can_modify_airport_settings') {
          status = true;
        }
      }
    }
    return status;
  };

  const CreateSettings = () => {
    let status1 = false;
    for (let i=0; i<profile.roles.length; i+=1) {
      for (let j=0; j<profile.roles[i].permissions.length; j+=1) {
        if (profile.roles[i].permissions[j].codename==='add_log') {
          status1 = true;
        }
      }
    }
    return status1;
  };

  const handleQuery = (searchQuery) => {
    setquery(searchQuery);
    actionFetch(1, undefined, undefined, searchQuery);
  };

  const handleChangeGroupBy = (filterGroupBy) => {
    setFilterGroupBy(filterGroupBy)
  }

  return (
    <div className={styles.parent}>
      {!loglist && <Loading loadingStatus />}
      <SectionHeader icon={preset} translationID="operations.loglist.title" defaultTitle="Operations log">
        <div className={styles.detailHeader}>
          <Search onSubmit={searchQuery => handleQuery(searchQuery)} />
          {profile.id!=='' && handelShowSettings()===true ? (
            <IconButton icon={settings} onClick={() => { history.push(`${LOGS_HOME_ROUTE}settings`); }} />
          ):''}
          <Separator />
          {profile.id!=='' && CreateSettings()===true ? (
            <Button translationID="operations.loglist.new"
              defaultText="New Log" onClick={() => (history.push(`${LOGS_HOME_ROUTE}new`))}
            />
          ):''}
          <Separator />
        </div>
      </SectionHeader>
      {range.s && (
      <Filter dates={[moment(range.s), moment(range.f)]}
        onDateChange={handleDateChange}
        listSummary={loglist && loglist.count}
        handleDropdown={handleFiltersPopup}
        filterCategories={filterCategories}
        groupByFilterOptions={groupByFilterOptions}
        handleChangeGroupBy={handleChangeGroupBy}
      />
      )}
      <div className={styles.filter}>
        {filterCategories.map(category => (
          <span className={styles.user} key={category}>
            {category}
            <span onKeyPress={() => handleRemoveFilter(category)} tabIndex={0} role="button"
              onClick={() => handleRemoveFilter(category)} className={styles.btnRemove}
            />
          </span>
        ))}
      </div>
      <div className="container">
        {loglist && loglist.results && Object.keys(loglist.results).length > 0
          ? (
            <Table onSort={handleSort} onSelect={handleRowSelect} data={filterGroupBy.key === 1 ?
                [{ label: null, entries: loglist.results }] :shiftTypes.map(
              e => ({ label: e.name, entries: loglist.results.filter(f => f.shift_name === e.name) })

            )} filterGroupBy={filterGroupBy}
            />
          ) : (
            <span className={styles.spanStyle}>
              <FormattedMessage id="operation.logs_selected_criteria" defaultMessage="There are no logs with the selected criteria." />
            </span>
          )}
        { initialized && loglist && loglist.results && Object.keys(loglist.results).length > 0 && (
        <Paginator totalRecords={loglist.count} key={range.s} pageLimit={10}
          pageNeighbours={1} onPageChanged={e => changePage(e)}
        />
        )}
      </div>
      <Modal showIn={modal} onClose={() => { setModal(false); actionClear(); }}>
        <OperationLogDetails logID={selected} onClose={() => { setModal(false); actionClear(); }} />
      </Modal>
      {popup && (
      <Transition in={popup} timeout={200} unmountOnExit>
        <FilterForm
          transition={slideStyles}
          handleOutsideClickFilter={handleOutsideClick}
          update={updateFilter}
          cancel={handleFiltersPopup}
          filterCategories={filterCategories}
        />
      </Transition>
      )}

    </div>
  );
};

OperationLogList.propTypes = {
  actionFetch: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  loglist: state.opslogs.loglist,
  profile: state.auth.profile,
  shiftTypes: state.settings.shiftTypes
});

const mapDispatchToProps = dispatch => ({
  // Fetch Log list
  actionFetch: (page, start, end, query, filters) => {
    dispatch(fetchLogs(page, start, end, query, filters));
  },
  // Fetch Log list
  actionFetchForm: (page, query) => {
    dispatch(fetchLogFormSchema(page, query));
  },
  actionClear: (query) => {
    dispatch(clear(query));
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  }
});

export default Permissions(['view_log'])(
  connect(mapStateToProps, mapDispatchToProps)(OperationLogList),
  <Forbidden />
);
