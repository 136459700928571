import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PulpoField from '../../../../pulpo_visualizer/fields';
import camera from '../../../../icons/camera.svg';
import styles from './fixedFields.module.scss';
import InspectionMap from './InspectionMap';

let currentPhoto;
const FixedFieldsForInspections = ({
  info,
  assetTypes,
  handleAnswerChanged,
  handleSelectedAsset,
  showFormErrors,
  handleFieldErrorChanged,
  assetsTypesname,
  handleWorkOrderZoomLevel,
  assetsTypedataloading,
  assetsTypesdata,
  spaceError
}) => {
  const handleAssetData = (assetsTypesName, assettypes) => {
    const assetData=[];
    assetsTypesName.filter(asset => asset.category.toLowerCase() === assettypes).map(e => (
      assetData.push({ key: JSON.stringify(e.id), value: e.name })));
    if (assetData.length>1) {
      assetData.push({ key: assettypes, value: 'Show All Assets' });
    }
    return assetData;
  };
  const [photo] = useState({ status: false });
  return (
    <div className={styles.fixedForInspection}>
      <label className={styles.camera}>
        <img src={camera} alt="" />
        <input type="file" accept="image/*" multiple onChange={(e) => {
          handleAnswerChanged('info', [...e.target.files], 'photos');
          currentPhoto = '';
          if (e.target.files.length > 1) {
            currentPhoto = `${e.target.files[0].name} and more`;
            photo.status = true;
          } else if (e.target.files.length === 1) {
            currentPhoto = e.target.files[0].name;
            photo.status = true;
          } else {
            currentPhoto = info.photos[0].name;
            photo.status = true;
          }
        }}
        />
        {photo.status === true ? currentPhoto: ''}
      </label>
      {/* Form Field for Selecting Asset */}
      {assetTypes && assetTypes[info.category]
         && assetTypes[info.category][info.subcategory]
        ? (
          <PulpoField key="assetName"
            id="assetName"
            type="select"
            title="Asset Type Name" isRequired
            translationID="workorder.create.Assets" className={styles.fullInput}
            handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
            answer={info.assetName}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={handleFieldErrorChanged}
            values={handleAssetData(assetsTypesname, assetTypes[info.category][info.subcategory])}
          />
        ):''
      }
      {
       assetTypes
       && assetTypes[info.category]
       && assetTypes[info.category][info.subcategory]
       && assetsTypesdata.length>0
       && assetsTypesdata[0].asset_type.category.toLowerCase() === assetTypes[info.category][info.subcategory].toLowerCase()
         ? (
           <InspectionMap
             className={styles.location}
             handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
             assetTypeID={assetsTypesdata[0].asset_type.id}
             handleFieldErrorChanged={handleFieldErrorChanged}
             answer={info.location}
             loading={assetsTypedataloading.loading}
             type={assetTypes[info.category][info.subcategory]}
             assets={assetsTypesdata.filter(
               e => e.asset_type.category.toUpperCase()
               === assetTypes[info.category][info.subcategory].toUpperCase()
             )}
             handleZoomLevel={val => handleWorkOrderZoomLevel(val)}
             handleSelectedAsset={handleSelectedAsset}
           />
         )
         : (
           info.location && (
           <PulpoField key="location" id="location" type="location"
             translationID="workorder.create.location"
             className={styles.location} title="Location" isRequired
             handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
             answer={info.location} showFieldErrors={showFormErrors}
             handleFieldErrorChanged={handleFieldErrorChanged}
             handleZoomLevel={val => handleWorkOrderZoomLevel(val)}
           />
           )
         )
      }

      <PulpoField key="desc" id="problem_description" type="string"
        translationID="workorder.create.description" className={styles.field}
        widget={{ type: 'textarea' }} title="Problem description" isRequired
        handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
        answer={info.problem_description}
        rows={9}
        showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
      />
      { spaceError ? <h6 className={styles.para}>this field is required</h6> : ''}
      <PulpoField key="priority" id="priority" type="select" title="Priority" isRequired
        translationID="workorder.create.priority" className={styles.field}
        handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
        answer={info.priority}
        showFieldErrors={showFormErrors}
        handleFieldErrorChanged={handleFieldErrorChanged}
        values={[
          { key: '0', value: 'Low' },
          { key: '1', value: 'Medium' },
          { key: '2', value: 'High' }
        ]}
      />
    </div>
  );
};

FixedFieldsForInspections.propTypes = {
  info: PropTypes.shape({}).isRequired,
  handleAnswerChanged: PropTypes.func.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool
};

FixedFieldsForInspections.defaultProps = {
  showFormErrors: false
};

export default FixedFieldsForInspections;
