export const BASENAME = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : '';

// Auth Routes
export const LOGIN_ROUTE = '/auth/login';
export const FORCE_PASSWORD_CHANGE = '/auth/force_password_change';

export const OPERATIONS_DASHBOARD_HOME_ROUTE = '/ops/operations_dashboard/';
export const INSPECTIONS_HOME_ROUTE = '/ops/inspections/';
export const WORKORDERS_HOME_ROUTE = '/ops/workorders/';
export const ASSET_HOME_ROUTE = '/ops/assets/';
export const LOGS_HOME_ROUTE = '/ops/logs/';

export const PROFILE_VIEW = '/profile';
export const PROFILE_EDIT = '/profile/edit';
export const WORK_ORDERS_DETAIL = 'detail';
export const WILD_LIFE_LOG_HOME = '/wildlife';
export const LEASE_HOME = '/lease';
export const TENANTS = '/lease/tenants';
export const MAINTENANCE = '/lease/maintenance';
