import React, { useState } from "react";
import { Wrapper } from "./Message.styles";
import { ChatTypes } from "../../enums";

type MessageWrapperProps = {
  type: ChatTypes;
  isAttachments: boolean;
  isMessageOwner?: boolean;
  bordered?: boolean;
  styles?: object;
  savingMessage?: boolean;
  children: ({ showOption }: { showOption: boolean }) => JSX.Element;
};

const MessageWrapper = React.memo<MessageWrapperProps>(
  ({
    type,
    isAttachments,
    isMessageOwner = false,
    bordered = false,
    savingMessage = false,
    styles,
    children
  }) => {
    const [showOption, setShowOption] = useState(false);

    return (
      <Wrapper
        viewType={type}
        isAttachments={isAttachments}
        onMouseEnter={() => setShowOption(true)}
        onMouseLeave={() => setShowOption(false)}
        isMessageOwner={isMessageOwner}
        bordered={bordered}
        savingMessage={savingMessage}
        style={styles}
      >
        {children({ showOption })}
      </Wrapper>
    );
  }
);

export default MessageWrapper;
export { MessageWrapper };
