import styled from 'styled-components';
import { ChatTypes } from '../../../enums';

type WrapperProps = {
  viewType: ChatTypes;
};

const getMaxWidth = (viewType: ChatTypes) => {
  switch (viewType) {
    case ChatTypes.comment:
      return '399px';
    case ChatTypes.directMessages:
      return '867px';
    case ChatTypes.operations:
      return '801px';
    default:
      return '801px';
  }
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding: 1px 0;
  max-width: ${({ viewType }) => getMaxWidth(viewType)};
  width: 100%;
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.text.default};

  button {
    background-color: inherit;
    border: unset;
    font-size: 15px;
    line-height: 1.73;
    text-align: center;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
