import gql from 'graphql-tag';

const DELETE_POST = gql`
  mutation deletePost($postId: String!) {
    deletePost(input: { postId: $postId }) {
      airportId
      attachments {
        name
        url
        type
        size
      }
      channelId
      content
      createdAt
      iropsId
      likes
      postId
      updatedAt
      userId
      comments
      isLiked
      user {
        firstName
        lastName
        airportId
        profilePicture
        airportDetails {
          name
          code
          logo
        }
      }
    }
  }
`;

export default DELETE_POST;
