import { useRef, useEffect, useCallback } from 'react';
import mqtt from 'mqtt';
import { MqttClient } from 'mqtt/types/lib/client';
import { ChatTypes } from '../../enums';
import NEW_CHANNEL_POSTS from '../../graphql/subscriptions/newChannelPosts';
import NEW_POST_COMMENT from '../../graphql/subscriptions/newPostComments';
import ON_CREATE_MESSAGE from '../../graphql/subscriptions/onCreateMessage';
import { postActions, commentsActions } from '../channelsState/actions';
import { directMessagesActions } from '../directMessagesState/actions';

import {
  Actions,
  IncomeMessageUnion,
  OnCreate,
  ResponseData,
  SubscribeArgs,
  IncomeMessage
} from './types';

const mqttClient = (url: string, clientId: string): MqttClient => mqtt.connect(url, { clientId });

const actions: Actions = {
  [ChatTypes.operations]: postActions,
  [ChatTypes.comment]: commentsActions,
  [ChatTypes.directMessages]: directMessagesActions
};

const responseData: ResponseData = {
  [ChatTypes.operations]: OnCreate.onCreatePost,
  [ChatTypes.comment]: OnCreate.onCreateComment,
  [ChatTypes.directMessages]: OnCreate.onCreateMessage
};

const graphqlSchema = {
  [ChatTypes.operations]: NEW_CHANNEL_POSTS,
  [ChatTypes.comment]: NEW_POST_COMMENT,
  [ChatTypes.directMessages]: ON_CREATE_MESSAGE
};

export const useCreateSubscriptionUpdater = (dispatch: any) => {
  const mqttObj = useRef<{ topic: any; client: mqtt.MqttClient }[]>([]);

  const unsubscribe = () => {
    mqttObj.current.forEach(({ client, topic }) => {
      client.unsubscribe(topic);
    });
  };

  const subscribe = useCallback(
    <T>({ type, subscribeTo, variables }: SubscribeArgs<T>) => {
        // if (type === ChatTypes.hide && mqttObj.current.length > 0) {
        //   unsubscribe();
        // }

      subscribeTo({
        document: graphqlSchema[type],
        variables,
        updateQuery: (prev, { subscriptionData: { extensions } }: any) => {

          const { topic } = extensions.subscription.newSubscriptions[
            responseData[type]
          ];
          const {
            url,
            client: wsClient
          } = extensions.subscription.mqttConnections.find(item => item.topics.includes(topic));

          const client = mqttClient(url, wsClient);

          client.on('connect', () => {
            mqttObj.current.push({ client, topic });
            actions[type].updateTopicsCollection(dispatch);

            client.subscribe(topic);
          });
          client.on('message', (topicData, message) => {
            const { data }: { data: IncomeMessageUnion } = JSON.parse(
              message.toString()
            );

            const newMessage: IncomeMessage = { [type]: data };

            if (newMessage[type][responseData[type]]) {       
              actions[type].setNewMessage(dispatch, {
                data: newMessage[type][responseData[type]]
              });

              if (type === ChatTypes.comment) {
                postActions.updateReplies(dispatch, {
                  postId: newMessage[type][responseData[type]].postId
                });
              }
            }
          });
          client.on('disconnect', () => {
            client.unsubscribe(topic);
          });
        }
      });
    },
    [dispatch]
  );

  useEffect(
    () => () => {
      if (mqttObj.current.length > 0) {
        unsubscribe();
      }
    },
    []
  );

  return { subscribe };
};
