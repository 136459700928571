import React from 'react';
import moment from 'moment/min/moment-with-locales';

export const importAllImages = (r) => {
  const images = {};
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item);
    return true;
  });
  return images;
};

let lastId = 0;

export const uniqueID = (prefix = 'id') => {
  lastId += 1;
  return `${prefix}-${lastId}`;
};

export const renderInfoForType = (field, value, info, OperationLog) => {
  switch (field.type) {
    case 'date':
      return moment(value).format('MM/DD/YYYY');
    case 'datetime':
      return moment(value).format('MM/DD/YYYY hh:mm A');
    case 'string':
      return value;
    case 'dataSource':
      return value;
    case 'dataSourceMultiselect':
      return value && value.map(val => (
        <i key={val}>{`${val} `}</i>
      ));
    case 'number':
      return value;
    case 'select': {
      let f = [];
      if (OperationLog) {
        f = info.schema.fields.find(e => e.id === field.id);
      } else {
        f = info.answer_schema.fields.find(e => e.id === field.id);
      }
      return f.values.find(e => e.key === value).value;
    }
    case 'multiselect': {
      let f = [];
      if (OperationLog) {
        f = info.schema.fields.find(e => e.id === field.id);
      } else {
        f = info.answer_schema.fields.find(e => e.id === field.id);
      }
      return value.map(val => (
        <i key={val}>{f.values.find(e => e.key === val).value}</i>
      ));
    }
    default:
      return null;
  }
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: message || state
});


export const getDatesFromRange = (range) => {
  let date = new Date();
  let date2 = new Date();
  date.setHours(0, 0, 0, 0);
  date2.setHours(23, 59, 99, 99);
  switch (range) {
    case 'Today':
      date = moment().startOf('day');
      date2 = moment().endOf('day');
      break;
    case 'Yesterday':
      date = moment().subtract(1, 'days').startOf('day');
      date2 = moment().subtract(1, 'days').endOf('day');
      break;
    case 'This Week':
      date = moment().startOf('week');
      date2 = moment().endOf('week');
      break;
    case 'Last Week':
      date = moment().subtract(1, 'weeks').startOf('week');
      date2 = moment().subtract(1, 'weeks').endOf('week');
      break;
    case 'This Month':
      date = moment().startOf('month');
      date2 = moment().endOf('month');
      break;
    case 'Last Month':
      date = moment().subtract(1, 'months').startOf('month');
      date2 = moment().subtract(1, 'months').endOf('month');
      break;
    case 'This Year':
      date = moment().startOf('year');
      date2 = moment().endOf('year');
      break;
    case 'Last 30 days':
      date = moment().subtract(30, 'days').startOf('days');
      date2 = moment().endOf('day');
      break;
    default:
      break;
  }
  return [
    date,
    date2
  ];
};

export const getShiftTypeByDate = (date, shiftTypes) => {
  if (!shiftTypes) return {};
  const hour = typeof date === 'object' ? date.hours() + 1 : new Date(date).getHours();
  const result = shiftTypes.filter(
    item => parseInt(item.start, 0) <= hour && parseInt(item.end, 0) > hour
  ).reduce((acc, shift) => { return shift; }, {});
  return Object.entries(result).length === 0 ? shiftTypes[shiftTypes.length - 1]: result;
};

export const getUserHasPermission = (permission, roles) => {
  const permissions = roles.map(e => e.permissions.map(p => p.codename)).reduce((p, n) => p.concat(n), []);
  return permissions.filter((a, b) => permissions.indexOf(a) === b).includes(permission);
};
