import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { validateMessage } from '../../validators/messageSchema';
import {
  MessageFormValues,
  CurrentUser,
  DirectMessageType,
  CreateMessagesTypes,
  SetListHeightFunc,
  SetFilesType,
  SetOpenModalType,
  ChatListUser,
  UpdateDMFunc
} from '../../types';
import { useGetUserDetails } from '../../graphql/hooks';
import { ChatTypes } from '../../enums';
import Loading from '../../../../components/loading/index';
import { MessageForm as Form } from '../../components';
import { useDirectMessagesState } from '../../state/directMessagesState';
import { onHeightChange, makeInitValues } from './utils';
import { directMessagesActions } from '../../state/directMessagesState/actions';

type DirectMessageFormProps = {
  setListHeight: SetListHeightFunc;
  // TODO add types definitions
  createMessages: CreateMessagesTypes;
  updateMessages?: UpdateDMFunc;
  editedMessage?: DirectMessageType;
  // editedMessage?: MessageUnionType;
  currentUser: CurrentUser;
  type?: ChatTypes.directMessages;
  uploadFileMode?: boolean;
  topicsConnected: boolean;
  setFiles?: SetFilesType;
  attachedFiles?: File[];
  setOpenModal?: SetOpenModalType;
  toUser?: ChatListUser;
};

const initialValues: MessageFormValues = { content: '' };

const DirectMessageForm: React.FC<DirectMessageFormProps> = ({
  updateMessages,
  // setOpenModal,
  // attachedFiles: attachments,
  setListHeight,
  createMessages,
  editedMessage,
  currentUser,
  uploadFileMode = false,
  type,
  toUser,
  topicsConnected
}) => {
  const {
    state: { directMessages: activeChat },
    dispatch
  } = useDirectMessagesState();
  const [attachmentArr, setAttachmentArr] = useState<File[]>(null);
  const [loading] = useState(false);
  const { userId } = currentUser;
  const { data: userDetails } = useGetUserDetails(userId);

  if (!userDetails) {
    return <Loading loadingStatus />;
  }

  const closeEdit = () => {
    directMessagesActions.setEditedMessage(dispatch, { editedDMID: null });
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const { content: message }: MessageFormValues = values;
    const isValid = validateMessage(values);

    if (!isValid) {
      toast.error('Please enter message content');
      setSubmitting(false);
      return;
    }

    if (editedMessage) {
      const { messageId, attachments } = editedMessage;
      updateMessages({ messageId, attachments, message });
      return;
    }

    createMessages[type]({
      message,
      toUserId: toUser.userId
      // toUserId: 'a52b1d06-5fb1-4d45-97f2-893904c903d6'
      // toUserId: '252c3869-5dbf-4e2b-bbb7-165b851acba4'
      // toUserId: '4d1f3fd6-26cc-4012-95e7-3e0390e474e9'
    });

    resetForm();
    setSubmitting(false);
  };

  const initValues = makeInitValues(initialValues, editedMessage);

  return (
    <Form
      onHeightChange={onHeightChange(setListHeight)}
      onSubmit={onSubmit}
      uploadFileMode={uploadFileMode}
      loading={loading}
      isComment={false}
      type={type}
      userDetails={userDetails}
      initValues={initValues}
      currentUser={currentUser}
      channelName={activeChat?.activeChat?.chatName || 'chatName'}
      createMessages={createMessages}
      setAttachmentArr={setAttachmentArr}
      attachedFiles={attachmentArr}
      topicsConnected={topicsConnected}
      closeEdit={closeEdit}
      editedMessage={editedMessage}
    />
  );
};

export default DirectMessageForm;
export { DirectMessageForm };
