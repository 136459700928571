/* global FormData */
import React, { useState, useEffect , useRef} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styles from './search.module.scss';
import search from '../../icons/search.svg';
import IconButton from '../iconButton';

const Search = ({ onSubmit, intl }) => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target).get('query');
    onSubmit(data);
  };

  const handleOutsideClick = () => {
    if (active) setActive(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      handleOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={styles.search} ref={wrapperRef}>
      <form onSubmit={handleSubmit} className={`${styles.form} ${active ? styles.active : ''}`}>
        <div className={styles.left}>
          <input type="text" placeholder={intl.formatMessage({ id: 'translation.search' })} name="query" autoComplete="off" />
        </div>
        <div className={styles.right}>
          <button onClick={() => setActive(true)} className={styles.button} type="button">
            <img src={search} className={styles.image} alt="" />
          </button>
        </div>
      </form>
      {!active && <IconButton icon={search} onClick={() => setActive(true)} />}
    </div>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
export default injectIntl(Search);
