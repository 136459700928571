import { toast } from 'react-toastify';
import axios from 'axios';
import { apiRouts } from '../../constants';

class GetWildLifeInfo {
  getWildLifeInfo = async () => {
    try {
      const { data } = await axios.get(
        apiRouts.getUploadFileName + '/wildlife/species',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      return data;
    } catch (err) {
      toast.error(err?.response?.data.message || err.message);
      return null;
    }
  };
}

const Service = new GetWildLifeInfo();

export default Service;
export { Service as GetWildLifeInfo };
