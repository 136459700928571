import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchInspectionList } from '../../../redux/actions';
import styles from '../../../../../components/filters/filteritem.module.scss';
import Button from '../../../../../components/button';
import Collapsible from '../../../../../components/collapsible/Collapsible';
import FilterItem from '../../../../../components/filters/FilterItem';
import Clickable from '../../../../../components/clickable/Clickable';

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [
        { id: 1,
          name: 'Type of Inspections',
          permissions: []
        },
        { id: 2,
          name: 'Issues',
          permissions: [{ id: 1, title: 'With Issues' }, { id: 3, title: 'Without Issues' }]
        },
        {
          id: 3,
          name: 'Shifts',
          permissions: []
        },
        {
          id: 4,
          name: 'Custom Fields',
          permissions: []
        }
      ]
    };
  }

  componentDidMount() {
    const { actionGetInpectionList, shifts } = this.props;
    actionGetInpectionList(undefined, 'view');
    const filterShiftsData = [];
    Object.keys(shifts).forEach((k) => {
      filterShiftsData.push({ id: shifts[k].id, title: shifts[k].name, type: 'shifts' });
    });
    this.setState(prevState => ({
      filters: prevState.filters.map(
        obj => (obj.id === 3 ? Object.assign(obj, { permissions: filterShiftsData }) : obj)
      )
    }));
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { inspections } = this.props;
    if (prevProps.inspections !== inspections) {
      const filterinspectionData = [];
      Object.keys(inspections).forEach((k) => {
        filterinspectionData.push({ id: inspections[k], title: inspections[k].title });
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        filters: prevState.filters.map(
          obj => (obj.id === 1 ? Object.assign(obj, { permissions: filterinspectionData }) : obj)
        )
      }));
      const filtercustomdata = [];
      Object.keys(inspections).forEach((k) => {
        inspections[k].form.schema.fields.map(t => t.search_enable === true && t.type !== 'inspection'&& filtercustomdata.push({ id: t.id, title: t.title }))
      });
      this.setState(prevState => ({
        filters: prevState.filters.map(
          obj => (obj.id === 4 ? Object.assign(obj, { permissions: filtercustomdata }) : obj)
        )
      }));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { handleOutsideClickFilter } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      handleOutsideClickFilter();
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  render() {
    const { handleFilterChange, filterCategories, update, cancel, clear } = this.props;
    const { filters } = this.state;
    return (
      <>
        <div className={styles.popup} ref={this.setWrapperRef}>
          <div className={styles.header}>
            <span className={styles.filterText}>Filters</span>
            <Clickable onClick={clear} className={styles.cancel} style={{ marginTop: '20px', marginLeft: '70px' }}>
              <FormattedMessage id="filters.form.clear" defaultMessage=" Clear" />
            </Clickable>
            <Clickable onClick={cancel} className={styles.cancel} style={{ marginTop: '20px' }}>
              <FormattedMessage id="inspections.new.cancelBtn" defaultMessage=" Cancel" />
            </Clickable>
            <Button
              action="secondary"
              translationID="workorder.filter.apply"
              defaultText="Apply"
              onClick={update}
            />
          </div>
          <div className={styles.list}>
            {filters.map(e => (
              <div key={e.id}>
                <Collapsible title={e.name} styleClasses={styles.rol} autoheight={false} openOnMount={e.name === 'Status' ? true : false}>
                  <FilterItem
                    id={e.id}
                    filter={e}
                    handleChange={(id, targetId) => handleFilterChange(id, targetId)}
                    filterCategories={filterCategories}
                  />
                </Collapsible>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

FilterForm.propTypes = {
  inspections: PropTypes.arrayOf(PropTypes.shape({}))
};

FilterForm.defaultProps = {
  inspections: {}
};

const mapStateToProps = state => ({
  inspections: state.inspection.inspectionList.results
});

const mapDispatchToProps = dispatch => ({
  actionGetInpectionList: (id, value) => {
    dispatch(fetchInspectionList(id, value));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
