import gql from 'graphql-tag';

const LIKE_POST = gql`
  mutation likeChannelPost(
    $postId: String!
    $userId: String!
    $channelId: String!
    $airportId: String!
  ) {
    likeChannelPost(
      input: {
        postId: $postId
        userId: $userId
        channelId: $channelId
        airportId: $airportId
      }
    ) {
      airportId
      attachments {
        name
        url
        type
        size
      }
      channelId
      content
      createdAt
      iropsId
      likes
      postId
      updatedAt
      userId
      comments
      isLiked
      user {
        firstName
        lastName
        airportId
        profilePicture
        airportDetails {
          name
          code
          logo
        }
      }
    }
  }
`;

export default LIKE_POST;
