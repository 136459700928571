import {
  FETCH_PROPERTIES,
  ADD_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
  CLEAR_PROPERTY_ACTION,
  FETCH_PROPERTY_TYPES,
  UPLOAD_PROPERTY_TYPES,
  FETCH_SCHEMA,
  UPDATE_SCHEMA,
  CLEAR_MAINTENANCE,
  FETCH_CATEGORIES,
  ADD_CATEGORIES,
  UPDATE_CATEGORIES,
  FETCH_WORKORDERS,
  ADD_WORKORDERS,
  GET_WORKORDER,
  ADD_MAINTENANCE,
  FETCH_ALL_TYPES,
  UPLOAD_TYPES,
  CLEAR_TENANT_TYPES,
  FETCH_TENANTS,
  GET_TENANT,
  ADD_TENANT,
  GET_TENANT_NAMES,
  UPDATE_TENANT
} from './types';

const BACKEND_API = 'BACKEND_API';


export const fetchProperties = () => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'get',
      url: '/lease_properties/'
    },
    FETCH_PROPERTIES
  )
});

export const addProperty = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_properties/',
    data,
    ...ADD_PROPERTY
  }
});

export const updateProperty = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'patch',
    url: `/lease_properties/${id}/`,
    data,
    ...UPDATE_PROPERTY
  }
});

export const deleteProperty = id => ({
  type: BACKEND_API,
  payload: {
    method: 'delete',
    url: `/lease_properties/${id}/`,
    ...DELETE_PROPERTY
  }
});

export const fetchPropertyTypes = () => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'get',
      url: '/lease_properties/get_property_types/'
    },
    FETCH_PROPERTY_TYPES
  )
});

export const uploadPropertyTypes = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_properties/upload_property_types/',
    data,
    ...UPLOAD_PROPERTY_TYPES
  }
});

export const fetchWorkOrderSchema = () => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'get',
      url: '/lease_work_orders/get_schema'
    },
    FETCH_SCHEMA
  )
});

export const updateWorkOrderSchema = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_work_orders/update_schemas/',
    data,
    ...UPDATE_SCHEMA
  }
});

export const fetchCategories = () => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'get',
      url: '/maintenance_categories/'
    },
    FETCH_CATEGORIES
  )
});

export const addCategory = data => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'post',
      url: '/maintenance_categories/',
      data,
      ...ADD_CATEGORIES
    }
  )
});

export const updateCategory = (id, data) => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'patch',
      url: `/maintenance_categories/${id}/`,
      data,
      ...UPDATE_CATEGORIES
    }
  )
});

export const fetchLeaseWorkorders = (page, start, end, query, filters) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/lease_work_orders/?
${page ? 'page=' : ''}${page || ''}
${query ? '&query=' : ''}${query || ''}
${start ? '&s=' : ''}${start || ''}
${end ? '&f=' : ''}${end || ''}
${filters ? '&filters=' : ''}${filters || ''}`,
    ...FETCH_WORKORDERS
  }
});

export const createLeaseWokrorder = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_work_orders/',
    data,
    ...ADD_WORKORDERS
  }
});

export const getLeaseWorkorder = id => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: `/lease_work_orders/${id}`,
    ...GET_WORKORDER
  }
});

export const createLeaseMaintenance = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: `/lease_work_orders/${id}/maintenance_review/`,
    data,
    ...ADD_MAINTENANCE
  }
});

export const fetchAllTypes = () => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: '/lease_tenants/get_types/',
    ...FETCH_ALL_TYPES
  }
});

export const uploadTypes = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_tenants/upload_types/',
    data,
    ...UPLOAD_TYPES
  }
});

export const fetchTenants = (page, query, filters) => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: `/lease_tenants/?
${page ? 'page=' : ''}${page || ''}
${query ? '&query=' : ''}${query || ''}
${filters ? '&filters=' : ''}${filters || ''}`,
    ...FETCH_TENANTS
  }
});

export const getTenant = id => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: `/lease_tenants/${id}/`,
    ...GET_TENANT
  }
});

export const createTenant = data => ({
  type: BACKEND_API,
  payload: {
    method: 'post',
    url: '/lease_tenants/',
    data,
    ...ADD_TENANT
  }
});

export const getTenantLookup = () => ({
  type: BACKEND_API,
  payload: {
    method: 'get',
    url: '/lease_tenants/tenant_lookup/',
    ...GET_TENANT_NAMES
  }
});

export const updateTenant = (id, data) => ({
  type: BACKEND_API,
  payload: Object.assign(
    {
      method: 'patch',
      url: `/lease_tenants/${id}/`,
      data,
      ...UPDATE_TENANT
    }
  )
});

export const clear = () => ({
  type: CLEAR_PROPERTY_ACTION
});

export const clearMaintenance = () => ({
  type: CLEAR_MAINTENANCE
});

export const clearTenantsTypes = () => ({
  type: CLEAR_TENANT_TYPES
});
