import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import SectionHeader from '../../components/sectionHeader';
import AirportInformation from './components/airport-information/AirportInformation';
import RunwayCondition from './components/runway-condition/RunwayCondition';
import Notams from './components/notams/Notams';
import Comments from './components/comments/Comments';
import Weather from './components/weather/Weather';
import UsefulLinks from './components/useful-links/UsefulLinks';
import Dashboard from '../../icons/airfield-icons/Dashboard.png';
import styles from './airfields.module.scss';

const AirFieldConditionReporting = () => (
  <Fragment>
    <div>
      <SectionHeader icon={Dashboard} translationID="shortcuts.airfieldconditons" defaultTitle="Airfield Conditions" />
    </div>
          <div className={`container ${styles.container}`}>
            <Col lg={12}>
              <div className={styles.list}>
                <Row className="mt-5 mb-3">
                  <Col lg={8}>
                    <AirportInformation />
                    <RunwayCondition />
                  </Col>
                  <Col lg={4}>
                    <Weather />
                  </Col>
                </Row>
                <Notams />
                    <Row className="mt-5 mb-5">
                      <Col lg={8}>
                        <Comments />
                      </Col>
                      <Col lg={4}>
                        <UsefulLinks />
                      </Col>
                </Row>
              </div>
            </Col>
          </div>
  </Fragment>
);

export default AirFieldConditionReporting;
