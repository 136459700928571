import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styles from './profile.module.scss';
import UserImg from '../../../icons/default_avatar.jpg'


class Profile extends Component {
    state = {
      profileDropdown: false
    };

  handleClick = () => {
    const { profileDropdown } = this.state;
    if (!profileDropdown) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState(prevState => ({
      profileDropdown: !prevState.profileDropdown
    }));
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    try {
      if (this.node.contains(e.target)) {
        return;
      }
      this.handleClick();
    } catch(ex) {
      // nothing
    }
  }

  render() {
    const { profileDropdown } = this.state;
    const { profile, logout } = this.props;

    return (
      <div className={styles.profile} ref={node => { this.node = node; }}>
        <img src={profile.image ? profile.image : UserImg} alt={profile.fullname} />
        <button type="button" onClick={this.handleClick}>
          { profile.fullname }
        </button>
        {profileDropdown && (
          <ul className={`${styles.dropdown} ${profileDropdown ? styles.open : ''}`}>
            <li className={styles.item}>
              <Link to="/profile" onClick={() => this.handleClick()}><FormattedMessage id="profile.settings.profile" defaultMessage="Profile"/></Link>
            </li>
            <li className={styles.item}>
              <Link to="/profile/changePassword" onClick={this.handleClick}><FormattedMessage id="profile.settings.change_password" defaultMessage="Change Password"/></Link>
            </li>
            <li className={styles.item}>
              <Link to="/profile/changePin" onClick={this.handleClick}><FormattedMessage id="profile.settings.change_pin" defaultMessage="Change Pin"/></Link>
            </li>
            <li className={styles.item}>
              <button type="button" onClick={logout} style={{ color: 'black' }}>
                <FormattedMessage id="profile.settings.logout" defaultMessage="Log Out" />
              </button>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  logout: PropTypes.func.isRequired
};

export default Profile;
