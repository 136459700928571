import {
  FETCH_OPERATIONSDASHBOARD,
  CLEAR_OPERATIONSDASHBOARD
} from './types';

const BACKEND_API = 'BACKEND_API';
export const fetchLogs = (query, filters, shifts, s, f) => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: `/work_orders/list_workorders/?${query ? 'query=' : ''}${'&filterslist='}${filters || ''}${shifts? '&shifts=':''}${shifts || ''}${s ? '&s=': ''}${s||''}${f?'&f=':''}${f||''}&dashboard=true`,
    ...FETCH_OPERATIONSDASHBOARD
  }
});
export const clearActionResult = () => ({
  type: CLEAR_OPERATIONSDASHBOARD
});
