import styled, { css } from 'styled-components';
import { ChatTypes } from '../../enums';

type WrapperProps = {
  viewType: ChatTypes;
};

const messageStyles = css`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.text.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
`;

const chatStyles = css`
  color: ${({ theme }) => theme.colors.text.lighter};
`;

export const Wrapper = styled.div<WrapperProps>`
  .ui.segment {
    height: calc(100vh - 188px);
    .thin.left.sidebar {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.background.white};
    }
    h3 {
      color: ${({ theme }) => theme.colors.text.darkest};
    }
    .inverted.icon.menu .item {
      text-align: initial;
      ${({ viewType }) => (viewType === ChatTypes.operations ? chatStyles : messageStyles)}
    }
    .item {
      :hover {
        background-color: ${({ theme }) => theme.colors.background.darker};
        cursor: pointer;
      }
    }
    .active-chat {
      background-color: ${({ theme }) => theme.colors.background.darker};
      font-weight: bold;
      color: ${({ theme }) => theme.colors.text.default} !important;
    }
    
    .search{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 15px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
      p {
        font-size: 19px;
        font-weight: bold;
        letter-spacing: 0.13px;
        color: ${({ theme }) => theme.colors.text.default};
        margin: unset;
      }
      g {
        fill: ${({ theme }) => theme.colors.background.blue};
        cursor: pointer;
      }
    }
  }
  .counter {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text.red};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.background.lightRed};
    margin: 0 9px;
    padding: 5px 10px;
  }
`;
