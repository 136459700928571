/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-polylinedecorator';
import drawLocales from 'leaflet-draw-locales';
import { uniqueID } from '../../../utils/helpers';
import icon from '../../../icons/paw_3.png';
import styles from '../fields.module.scss';

const language = localStorage.getItem('lang') ||'en';
drawLocales(language);

const locale = drawLocales(language);
locale.draw.toolbar.buttons.polyline = 'click on this to start tracing animal route';
L.drawLocal = locale;


class Polyline extends Component {
  constructor(props) {
    super(props);
    this.ID = uniqueID('map');
    const { isRequired, updateFieldErrors, answer } = this.props;
    if (isRequired && (answer === '' || answer === undefined || !answer[0].length)) {
      updateFieldErrors(['pulpoforms.errors.not_blank']);
    }
    this.defaultConfig = {
      marker: false,
      circle: false,
      rectangle: false,
      polyline: true,
      circlemarker: false,
      polygon: false
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  componentDidMount() {
    const { answer } = this.props;
    if (Array.isArray(answer)) {
      this.initializeMap();
    }
  }

  componentDidUpdate(prevProps) {
    const { answer } = this.props;
    if (!prevProps.answer) {
      if (Array.isArray(answer)) {
        this.initializeMap();
      }
    }
  }

  componentWillUnmount() {
    this.map.remove();
  }

  handleAnswerChanged = (value, fieldId) => {
    const {
      handleValueChange,
      handleFieldErrorChanged,
      isRequired,
      updateFieldErrors
    } = this.props;

    handleValueChange(value, fieldId);
    let updatedErrors = [];
    if (value === '' || value === undefined) {
      handleFieldErrorChanged(fieldId, isRequired);
      updatedErrors = [
        ...updatedErrors,
        'pulpoforms.errors.not_blank'
      ];
    } else {
      handleFieldErrorChanged(fieldId, false);
    }
    updateFieldErrors(updatedErrors);
  }

  initializeMap = () => {
    const { answer, fieldId, handleZoomLevel } = this.props;
    const curLocation = answer.reverse() || [0, 0];
    this.map = L.map(this.ID, {
      center: curLocation,
      zoom: 15,
      editable: true
    });
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);

    const drawnItems = new L.FeatureGroup();

    this.map.addLayer(drawnItems);
    const CreatedrawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        remove: false,
        edit: false
      },
      draw: {
        ...this.defaultConfig
      }
    });

    const EditdrawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        remove: false
        // edit: false
      },
      draw: {
        ...this.defaultConfig,
        polyline: false
      }
    });

    this.map.addControl(CreatedrawControl);
    this.map.on(L.Draw.Event.CREATED, (e) => {
      const l = e.layer;
      const latlong = l._latlngs.map(o => [o.lat, o.lng]);
      const longlat = l._latlngs.map(o => [o.lng, o.lat]);
      const polyline = L.polyline(latlong, { color: '#800000' }).addTo(this.map);
      const decorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
              pixelSize: 15,
              polygon: false,
              pathOptions: { color: '#800000', stroke: true, fillOpacity: 0, weight: 7 }
            })
          },
          {
            offset: 0,
            endOffset: 0,
            repeat: 0,
            symbol: L.Symbol.marker({
              rotate: true,
              markerOptions: {
                icon: L.icon({
                  iconUrl: icon,
                  iconAnchor: [25, 35]
                })
              }
            })
          }
        ]
      }).addTo(this.map);

      this.map.addLayer(decorator);
      this.map.addControl(EditdrawControl);
      this.map.removeControl(CreatedrawControl);
      this.handleAnswerChanged(longlat, fieldId);
    });

    this.map.on('draw:edited', (e) => {
      const layer = e.layers;
      layer.eachLayer((l) => {
        const coordinates = l._latlngs.map(o => [o.lng, o.lat]);
        this.handleAnswerChanged(coordinates, fieldId);
      });
    });

    const zoomLevel = this.map.getZoom();
    handleZoomLevel(zoomLevel);
  }


  render() {
    return (
      <div id={this.ID} className={styles.map} />
    );
  }
}


Polyline.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  updateFieldErrors: PropTypes.func.isRequired,
  answer: PropTypes.arrayOf(PropTypes.number)
};

Polyline.defaultProps = {
  answer: undefined
};

export default Polyline;
