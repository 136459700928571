import React from 'react';
import { FormattedMessage } from 'react-intl';
import Clickable from '../../../../../components/clickable/Clickable';
import Collapsible from '../../../../../components/collapsible/Collapsible';
import styles from '../TenantsDetail.module.scss';

const AircraftDetail = ({
  aircrafts,
  handleModal,
  aircraftDetail
}) => (
  <>
    {!aircraftDetail && (
    <div className={styles.requestInfo}>
      <div className={styles.infoTable}>
        <div className={styles.headerRow}>
          <span className={styles.title}>
            <FormattedMessage id="lease.tenants.detail.aircrafts" defaultMessage="Aircraft" />
          </span>
          <span className={styles.rowContent}>
            <Clickable onClick={() => handleModal('new')}>
              <FormattedMessage id="tenant.detail.add_aircraft" defaultMessage="Add new aircraft +" />
            </Clickable>
          </span>
        </div>
      </div>
    </div>
    )}
    {aircrafts && aircrafts.length > 0 && aircrafts.map((aircraft, index) => (
      <>
        {!aircraftDetail && (
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.headerRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.detail.aircrafts" defaultMessage="Aircraft" />
                {'  '}
                {index + 1}
              </span>
              <span className={styles.rowContent}>
                <Collapsible
                  title="workorders.detail.actionBtn"
                  styleClasses={styles.actionsBtn} dateFilter
                >
                  <ul className={`${styles.dropdown} ${styles.open}`}>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('modify', aircraft)}>
                        <FormattedMessage id="tenant.detail.modify" defaultMessage="Modify" />
                      </Clickable>
                    </li>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('delete', aircraft)}>
                        <FormattedMessage id="tenant.detail.delete" defaultMessage="Delete" />
                      </Clickable>
                    </li>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('duplicate', aircraft)}>
                        <FormattedMessage id="tenant.detail.duplicate" defaultMessage="Duplicate" />
                      </Clickable>
                    </li>
                  </ul>
                </Collapsible>
              </span>
            </div>
          </div>
        </div>
        )}
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step4.aircraft_type" defaultMessage="Company Name" />
              </span>
              <span className={styles.rowContent}>
                {aircraft.name}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step4.owner" defaultMessage="Registered Owner" />
              </span>
              <span className={styles.rowContent}>
                {aircraft && aircraft.owner}
              </span>
            </div>
          </div>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step4.model" defaultMessage="Make/Model" />
              </span>
              <span className={styles.rowContent}>
                {aircraft && aircraft.model}
              </span>
            </div>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step4.owner_address" defaultMessage="Registered Owner Address" />
              </span>
              <span className={styles.rowContent}>
                {aircraft && aircraft.owner_address}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step4.n_number" defaultMessage="N Number" />
              </span>
              <span className={styles.rowContent}>
                {aircraft && aircraft.n_number}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.endingBorder} />
      </>
    ))}
    </>
);

export default AircraftDetail;
