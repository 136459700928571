import { MutationFunctionOptions } from '@apollo/react-common';
import { GraphQLError } from 'graphql';
import { useMutation } from '../../../graphql/hooks';
import CREATE_MESSAGE from '../mutations/createMessages';
import { DirectMessageType } from '../../types';
import { CreateMessagesVars } from './hooks.types';

type UseCreateDirectMessage = {
  (): {
    error: GraphQLError;
    createMutate: (
      options?: MutationFunctionOptions<DirectMessageType, CreateMessagesVars>
    ) => Promise<{ data?: DirectMessageType; error?: GraphQLError }>;
    saving: boolean;
  };
};

export const useCreateDirectMessage: UseCreateDirectMessage = () => {
  const { error, mutate, saving } = useMutation<
    DirectMessageType,
    CreateMessagesVars
  >(CREATE_MESSAGE);

  return {
    error,
    createMutate: mutate,
    saving
  };
};
