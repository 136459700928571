import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 16px;
  min-width: 295px;
  width: 100%;
  .title {
    text-align: center;
    color: #707070;
  }

  .menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #000000;
    p {
      padding: 7px 15px;
    }
  }
  .modals.dimmer .ui.scrolling.modal {
    margin: 1rem auto;
} 
  
`;
