import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, CardTitle, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PulpoField from '../../../../pulpo_visualizer/fields';
import Separator from '../../../../components/separator';
import Modal from '../../../../components/modal';
import Loading from '../../../../components/loading';
import EditableLink from './components/EditableLink';
import CloseIcon from '../../../../icons/close.svg';
import { addUsefulLink, fetchUsefulLinks, clearUsefulLinksAction } from '../../redux/action';
import styles from './usefulinks.module.scss';

const UsefulLinks = ({ actionFetchLinks, actionAdd, usefulLinks, usefulLinksAction, clear }) => {
  const [usefulLinkModal, setusefulLinkModal] = useState(false);
  const [answers, setAnswers] = useState({ text: '', url: '' });
  const [fieldErrors, setFieldErrors] = useState({ text: false, url: false });
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    actionFetchLinks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((usefulLinksAction.loading === false && loading === true) || (usefulLinksAction.success === true && loading === true)) {
      setLoading(false);
      actionFetchLinks();
      clear();
    }
  }, [usefulLinksAction.loading, usefulLinksAction.success, loading, actionFetchLinks, clear]);

  const toggleModal = () => {
    setusefulLinkModal(!usefulLinkModal);
  };

  const openModal = () => {
    setusefulLinkModal(true);
  };

  const handleAnswerChanged = (a, b) => {
    setAnswers({ ...answers, [b]: a });
  };


  const handleFieldErrorChanged = (a, b) => {
    setFieldErrors({ ...fieldErrors, [a]: b });
  };

  const addLink = () => {
    const areErrors = Object.values(fieldErrors).every(a => a);
    if (areErrors) {
      setShowErrors(true);
    }
    setLoading(true);
    const data = answers;
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    actionAdd(formData);
    setusefulLinkModal(false);
    setAnswers({ text: '', url: '' });
  };

  const handleSelectedLink = (id) => {
    setSelected(id);
  };

  return (
    <Col lg={12}>
    <Fragment>
      <Loading loadingStatus={loading} />
      <Row>
        <Col lg={12}>
          <Card className={styles.Card}>
            <CardTitle className="mt-3">
              <Col lg={12}>
                <Row>
                  <Col className={styles.title}>
                    <FormattedMessage
                      id="airfields.useful.links"
                      defaultText="Useful Links"
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <button type="button" className={`${styles.savebutton} ${styles.headingEditButton}`} onClick={() => openModal()}> Edit</button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </CardTitle>
            <hr className={styles.hrLine} />
            <CardBody className={styles.usefullinksCardBody}>
              {usefulLinks && usefulLinks.length
                ? usefulLinks.map(link => (
                  <div className={styles.link}>
                    <span className={styles.linkUrl}>{link.url}</span>
                  </div>
                ))
                : (
                  <div className={styles.noLinksText}>
                    No Links Added
                  </div>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        onClose={toggleModal}
        showIn={usefulLinkModal}
        width="943px"
        minHeight="350px"
        contentStyles={{
          maxHeight: '879px'
        }}

      >
        <Fragment>
          <div className={styles.modalTitle}>
            <FormattedMessage
              id="airfields.useful.links"
              defaultText="Useful links"
            />
            <div className={styles.closeIcon} onClick={() => toggleModal()}>
              <img src={CloseIcon} alt="closeIcon" />
            </div>
          </div>
          <hr className={styles.hrLine} />
          <div className={styles.modalBody} style={{ padding: 10 }}>
            {usefulLinks && usefulLinks.length
              ? usefulLinks.map(link => (
                <div>
                  <EditableLink item={link} selected={handleSelectedLink} selectedLink={selected} />
                </div>
              ))
              : 'No Links Added'}
            <Card className={styles.addCard}>
              <CardBody className={styles.addCardBody}>
                <Row>
                  <Col lg={4}>
                    <div>
                      <PulpoField key="text" id="text" type="string"
                        translationID="inspections.step2.newField.text" title="Text"
                        answer={answers.text}
                        className={styles.inputFields}
                        handleValueChange={a => handleAnswerChanged(a, 'text')}
                        handleFieldErrorChanged={handleFieldErrorChanged}
                        showFieldErrors={showErrors}
                      />
                    </div>
                  </Col>
                  <span style={{ marginTop: 16 }}>
                    <Separator />
                  </span>
                  <Col lg={5}>
                    <div>
                      <PulpoField key="url" id="url" type="string"
                        translationID="word.url" title="Url"
                        answer={answers.url}
                        className={styles.inputFields}
                        handleValueChange={a => handleAnswerChanged(a, 'url')}
                        handleFieldErrorChanged={handleFieldErrorChanged}
                        showFieldErrors={showErrors}
                      />
                    </div>
                  </Col>
                  <span style={{ marginTop: 16, marginLeft: '13' }}>
                    <Separator />
                  </span>
                  <Col className={styles.addBtn} lg={1} style={{ marginTop: 26, marginLeft: 26 }}>
                    <button type="button" className={styles.savebutton} onClick={addLink}> Add</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Fragment>
      </Modal>
    </Fragment>
    </Col>
  );
};

const mapStateToProps = state => ({
  usefulLinks: state.airfieldcondition.usefulLinks,
  usefulLinksAction: state.airfieldcondition.usefulLinksAction
});

const mapDispatchToProps = dispatch => ({
  // Fetch UsefulLinks
  actionFetchLinks: () => {
    dispatch(fetchUsefulLinks());
  },
  // Add Link
  actionAdd: (data) => {
    dispatch(addUsefulLink(data));
  },
  // Clear Action
  clear: () => {
    dispatch(clearUsefulLinksAction());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsefulLinks);
