/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import styles from '../dashboard.module.scss';
import 'leaflet-polylinedecorator';
import BirdIcon from '../../../../icons/bird_3.png';
import OthersIcon from '../../../../icons/paw_3.png';

class Map extends Component {
  defaultConfig = {
    marker: false,
    circle: false,
    rectangle: false,
    polyline: false,
    circlemarker: false,
    polygon: false
  };

  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps) {
    const { loglist } = this.props;
    if (prevProps.loglist.length !== loglist.length) {
      if (this.initializeMap) {
        this.map.remove();
        this.initializeMap();
      }
    }
  }

  initializeMap = () => {
    const { defaultLocation, loglist, WildlifeLogDetail } = this.props;
    const that = this;
    const zoomLevel = localStorage.getItem('wildlifelog_Zoom');
    this.map = L.map('map', {
      center: [...defaultLocation].reverse(),
      zoom: zoomLevel !== null ? zoomLevel : 15,
      fullscreenControl: true
    });

    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);

    if (loglist.length) {
      loglist.forEach((o) => {
        const latlang = [];
        o.location.coordinates.forEach(e => (
          latlang.push(e.reverse())
        ));
        const polyline = L.polyline(latlang, { color: '#800000' }).addTo(this.map);
        const decorator = L.polylineDecorator(polyline, {
          patterns: [
            {
              offset: '100%',
              repeat: 0,
              symbol: L.Symbol.arrowHead({
                pixelSize: 15,
                polygon: false,
                pathOptions: { color: '#800000', stroke: true, fillOpacity: 0, weight: 7 }
              })
            },
            {
              offset: 0,
              endOffset: 0,
              repeat: 0,
              symbol: L.Symbol.marker({
                rotate: true,
                markerOptions: {
                  icon: L.icon({
                    iconUrl: o.wildlife_type === 'Birds' ? BirdIcon : OthersIcon,
                    iconAnchor: [25, 35]
                  })
                }
              })
            }
          ]
        }).addTo(this.map);
        if (WildlifeLogDetail) {
          this.map.fitBounds(polyline.getBounds());
        }
        if (!WildlifeLogDetail) {
          polyline.on('click', () => {
            polyline.openPopup();
            const customOptions = { className: styles.tooltip, width: '200' };
            const custompopup = that.addPopupToPoint(o);
            polyline.bindPopup(custompopup, customOptions).openPopup();
          });
        }
        this.map.addLayer(decorator);
      });
    }
    if (!WildlifeLogDetail) {
      that.map.on('zoomend', () => {
        const zoom = that.map.getZoom();
        localStorage.setItem('wildlifelog_Zoom', zoom);
      });
      that.map.on('dragend', () => {
        const zoomlevel = that.map.getZoom();
        localStorage.setItem('wildlifelog_Zoom', zoomlevel);
      });
    }
  }

  handleZoomLevel = (zoomLevel) => {
    const { setZoomLevel } = this.props;
    setZoomLevel(zoomLevel);
  }

  addPopupToPoint = (object) => {
    const customPopup = `
        <p><b>Wildlife Type:</b> : ${object.wildlife_type}</p>
        <p><b>Wildlife Species:</b> : ${object.wildlife_species}</p>
        <p><b>Created on</b> : ${moment(object.incident_date).format('MM/DD/YYYY hh:mm A')}</p>`;

    return customPopup;
  }

  render() {
    return <div id="map" className={styles.map} />;
  }
}

Map.propTypes = {
  loglist: PropTypes.arrayOf(PropTypes.shape({})),
  defaultLocation: PropTypes.arrayOf(PropTypes.number)
};

Map.defaultProps = {
  loglist: [],
  defaultLocation: [0, 0]
};

export default Map;
