import {
  SetActiveChatAction,
  DMType,
  ActionTypes,
  SetTopicsCollectionAction,
  UpdateTopicsCollectionAction,
  SetNewMessageAction,
  SetEditedDMAction,
  DeleteDMAction
} from './types';

export const actions: ActionTypes = {
  setActiveChat: (dispatch, props) => dispatch<SetActiveChatAction>({
    type: DMType.setActiveChat,
    payload: props
  }),
  setDirectMessageList: (dispatch, props) => dispatch<SetActiveChatAction>({
    type: DMType.setDirectMessageList,
    payload: props
  }),
  setTopicsCollection: (dispatch, props) => dispatch<SetTopicsCollectionAction>({
    type: DMType.setTopicsCollection,
    payload: props
  }),
  updateTopicsCollection: dispatch => dispatch<UpdateTopicsCollectionAction>({
    type: DMType.updateTopicsCollection
  }),
  setNewMessage: (dispatch, props) => dispatch<SetNewMessageAction>({
    type: DMType.setNewMessage,
    payload: props
  }),
  setEditedMessage: (dispatch, props) => dispatch<SetEditedDMAction>({
    type: DMType.setEditedDM,
    payload: props
  }),
  deleteMessage: (dispatch, props) => dispatch<DeleteDMAction>({
    type: DMType.deleteMessage,
    payload: props
  })
};
