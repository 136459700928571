import { CommentsActionType, CommentsState, IAction } from './types';
import { initialState } from './state';
import { defaultMessage } from '../../common/assets';

export const reducer = (state: CommentsState, action: IAction): CommentsState => {
  switch (action.type) {
    case CommentsActionType.show: {
      const {
        payload: { channelId, messageData, postId, visible }
      } = action;

      return {
        ...state,
        channelId,
        messageData,
        postId,
        visible
      };
    }
    case CommentsActionType.updateCommentData: {
      const {
        payload: { messageData }
      } = action;

      return {
        ...state,
        messageData
      };
    }
    case CommentsActionType.hide:
      return initialState();
    case CommentsActionType.setCommentsList: {
      const {
        payload: { commentsList }
      } = action;

      return {
        ...state,
        commentsList
      };
    }
    case CommentsActionType.setTopicsCollection: {
      const {
        payload: { topicsCollectionCount }
      } = action;
      return {
        ...state,
        topicsCollectionCount
      };
    }
    case CommentsActionType.setDeletedCommentID: {
      const {
        payload: { deletedCommentID }
      } = action;
      return {
        ...state,
        deletedCommentID
      };
    }
    case CommentsActionType.updateTopicsCollection: {
      const { topicsCollectionCount } = state;
      // const {
      //   payload: { subTopicsCollection }
      // } = action;

      const waitForConnection = topicsCollectionCount - 1;

      return {
        ...state,
        topicsCollectionCount: topicsCollectionCount - 1,
        topicsConnected: waitForConnection === 0
      };
    }
    case CommentsActionType.setNewMessage: {
      const { commentsList, newComments, messageData } = state;
      const {
        payload: { data }
      } = action;
      const { postId } = data;
      const { comments } = messageData|| {};

      return {
        ...state,
        commentsList: {
          items: [
            ...(commentsList?.items || []),
            ...[
              ...(!newComments?.[postId]?.length
                ? [{ ...defaultMessage, createdAt: data.createdAt }, data]
                : [data])
            ]
          ],
          nextToken: commentsList.nextToken
        },
        messageData: {
          ...messageData,
          comments: comments + 1
        },
        newComments: {
          ...newComments,
          [postId]: [...(newComments?.[postId] || []), data]
        }
      };
    }
    case CommentsActionType.setEditedMessage: {
      const {
        commentsList: { items }
      } = state;
      const {
        payload: { editedCommentID }
      } = action;

      const [editedComment] = items.filter(
        ({ commentId }) => editedCommentID === commentId
      );

      return {
        ...state,
        editedComment,
        commentId: editedComment?.commentId
      };
    }
    case CommentsActionType.updateMessage: {
      const {
        commentsList: { items, nextToken }
      } = state;
      const {
        payload: { updateComment }
      } = action;

      return {
        ...state,
        commentsList: {
          items: items.map((message) => {
            const { commentId } = message;
            return commentId === updateComment.commentId
              ? { ...updateComment }
              : message;
          }),
          nextToken
        },
        editedComment: null
      };
    }
    case CommentsActionType.deleteComment: {
      const {
        commentsList: { nextToken, items },
        newComments,
        postId,
        messageData
      } = state;
      const {
        payload: { commentId }
      } = action;
      const { comments } = messageData;

      return {
        ...state,
        commentsList: {
          items: items
            .filter(message => message.commentId !== commentId)
            .filter(message => !(!newComments?.[postId]?.filter(msg => msg.commentId !== commentId).length && message.channelId === 'channelId')),
          nextToken
        },
        messageData: {
          ...messageData,
          comments: !comments ? comments : comments - 1
        },
        newComments: {
          ...newComments,
          [postId]: [
            ...(newComments?.[postId]?.filter(
              message => message.commentId !== commentId
            ) ?? [])
          ]
        },
        editedComment: null
      };
    }
    default: {
      return state;
    }
  }
};
