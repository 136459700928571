import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: auto;

  display: flex;
  position: relative;
  img {
    max-width: 234px;
    max-height: 186px;
    padding-right: 20px;
  }

  .document {
    margin-right: 15px;
    padding: 10px 8px;
    width: 265px;
    height: 72px;
    border-radius: 5px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;

    &-info {
      flex-grow: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
        color: #000000;
      }

      &-size {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
        color: #868686;
      }
    }

    &-actions {
      svg {
        padding: 2px 0;
        width: 26px;
        height: 21px;
        border-radius: 5px;
        border: solid 1px #e9eff4;
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }
`;
