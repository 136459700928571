import React, { memo, useCallback, useMemo } from 'react';

import { ChannelsWrapper } from './Channels.styles';
import { ActiveChat } from '../ActiveChat';
import { useChanelState } from '../../state/channelsState';
import { ChatTypes } from '../../enums';
import { Loader } from '../../components';

type CommentProps = {
  type: ChatTypes.comment;
};

const Comment = memo<CommentProps>(({ type }) => {
  const {
    state: {
      comments: {
        postId,
        topicsConnected,
        deletedCommentID,
        commentsList: { items, nextToken },
        editedComment
      },
      loadingComment,
      updateCommentLoading,
      createComment,
      savingComment,
      updateComment,
      deleteComment,
      fetchMoreComment
    }
  } = useChanelState();

  const loadMore = useCallback(() => fetchMoreComment(nextToken), 
  [fetchMoreComment, nextToken]);

  const deleteMessage = useMemo(
    () => ({
      [type]: deleteComment
    }),
    [deleteComment, type]
  );

  const updateMessages = useMemo(
    () => ({
      [type]: updateComment
    }),
    [updateComment, type]
  );

  if (!postId) {
    return null;
  }

  return (
    <ChannelsWrapper viewType={type}>
      {loadingComment ? (
        <Loader />
      ) : (
        postId && (
          <ActiveChat
            listChannelUsers={[]}
            type={type}
            topicsConnected={topicsConnected}
            deletedMessageID={deletedCommentID}
            updateLoading={updateCommentLoading}
            createMsg={{ [type]: createComment }}
            savingMessage={savingComment}
            deleteMessage={deleteMessage}
            updateMessages={updateMessages}
            loadMore={loadMore}
            hasMore={nextToken}
            messagesList={items}
            editedMessage={editedComment}
            editedMessageId={editedComment?.commentId}
          />
        )
      )}
    </ChannelsWrapper>
  );
});

export default Comment;
export { Comment };
