const asyncActionType = type => ({
  pending: `${type}/PENDING`,
  success: `${type}/SUCCESS`,
  error: `${type}/ERROR`
});

const LOAD_TYPE = asyncActionType('aerosimple/assets/LOAD_TYPE');
const LOAD_TYPE_NAME = asyncActionType('aerosimple/assets/LOAD_TYPE_NAME');
const CREATE_TYPE = asyncActionType('aerosimple/assets/CREATE_TYPE');
const CREATE_ASSET = asyncActionType('aerosimple/assets/CREATE_ASSET');
const DELETE_ASSET = asyncActionType('aerosimple/assets/DELETE_ASSET');
const FETCH_ASSET = asyncActionType('aerosimple/assets/FETCH_ASSET');
const FETCH_FILTER_ASSET = asyncActionType('aerosimple/assets/FETCH_FILTER_ASSET');
const FETCH_ASSET_SCHEMA = asyncActionType('aerosimple/assets/FETCH_ASSET_SCHEMA');
const UPDATE_ASSET_SCHEMA = asyncActionType('aerosimple/assets/UPDATE_ASSET_SCHEMA');
const UPDATE_SELF_INSPECTION = asyncActionType('aerosimple/assets/UPDATE_SELF_INSPECTION');
const UPLOAD_ASSETS = asyncActionType('aerosimple/assets/UPLOAD_ASSETS');
const CLEAR_ASSET_ACTION = 'aerosimple/assets/CLEAR';
const CLEAR_ASSET_TYPES = 'aerosimple/assets/CLEAR_ASSET_TYPES';

export {
  LOAD_TYPE,
  CREATE_TYPE,
  CREATE_ASSET,
  CLEAR_ASSET_ACTION,
  FETCH_ASSET,
  DELETE_ASSET,
  FETCH_ASSET_SCHEMA,
  UPDATE_ASSET_SCHEMA,
  UPDATE_SELF_INSPECTION,
  FETCH_FILTER_ASSET,
  UPLOAD_ASSETS,
  LOAD_TYPE_NAME,
  CLEAR_ASSET_TYPES
};
