import gql from 'graphql-tag';

const ON_CREATE_MESSAGE = gql`
  subscription onCreateMessage($roomId: String!) {
    onCreateMessage(roomId: $roomId) {
      createdAt
      fromUserId
      message
      messageId
      readStatus
      fromRead
      toRead
      fromDelete
      toDelete
      toUserId
      updatedAt
      attachments {
        name
        url
        type
        size
      }
      fromUser {
        firstName
        lastName
        profilePicture
        isActive
        title
        userId
        airportId
      }
      toUser {
        firstName
        lastName
        profilePicture
        isActive
        title
        userId
        airportId
      }
    }
  }
`;
export default ON_CREATE_MESSAGE;
