import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Sticky, Grid, Responsive, Segment } from 'semantic-ui-react';
import styles from '../../chat.module.scss';
import { Wrapper } from './CreateChannel.styles';
import { CreateChannelForm } from './CreateChannelForm';
import { useGetListUsers, useCreateChanel } from '../../graphql/hooks';
import { OnUserSelectFunc } from '../../types';

type CreateChannelProps = {
  userId: string;
  airportId: string;
} & RouteComponentProps;

const CreateChannel = React.memo<CreateChannelProps>(
  ({ history: { push } }) => {
    const [userIDs, setUserID] = useState([]);

    // TODO handle loading and error cases
    const { usersData } = useGetListUsers();
    const { createChanel } = useCreateChanel();

    const onUserSelect: OnUserSelectFunc = (userID) => {
      const userIDsArr: string[] = Array.isArray(userID) ? userID : [userID];
      setUserID(prevState => [...new Set([...prevState, ...userIDsArr])]);
    };

    const onRemove = (userID: string): void => {
      setUserID(prevState => prevState.filter(user => user !== userID));
    };

    return (
      <Wrapper>
        <Grid className="home-screen">
          <Grid.Column
            width={12}
            className={`channels-posts no-padding-botton ${styles.chat_wrapper}`}
          >
            <Responsive as={Segment} className="no-padding-botton">
              <Sticky>
                <h1>New Channel</h1>
                <hr />
                <CreateChannelForm
                  onUserSelect={onUserSelect}
                  onRemove={onRemove}
                  createChannel={createChanel}
                  userIDs={userIDs}
                  usersData={usersData}
                  push={push}
                />
              </Sticky>
            </Responsive>
          </Grid.Column>
        </Grid>
      </Wrapper>
    );
  }
);
export default CreateChannel;
