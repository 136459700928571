import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import Clickable from '../../../../../components/clickable/Clickable';
import Collapsible from '../../../../../components/collapsible/Collapsible';
import styles from '../TenantsDetail.module.scss';
import tableStyles from '../../../../../styles/general.module.scss';

const FeeDetail = ({
  info,
  handleModal
}) => (
    <>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.detail.fee" defaultMessage="Fee" />
            </span>
          </div>
        </div>
      </div>
      {info.map(fee => (
      <>
        {fee && (fee.annual_business_fee || fee.landing_fee || fee.ramp_fee) && (
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step3.business_fee" defaultMessage="Annual Business Fee" />
              </span>
              <span className={styles.rowContent}>
                <NumberFormat displayType="text" value={fee.annual_business_fee}
                  thousandSeparator prefix="$" suffix=".00"
                />
              </span>
            </div>
          </div>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step3.land_fee" defaultMessage="Landing Fee" />
              </span>
              <span className={styles.rowContent}>
                <NumberFormat displayType="text" value={fee.landing_fee}
                  thousandSeparator prefix="$" suffix=".00"
                />
              </span>
            </div>
          </div>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step3.ramp_fee" defaultMessage="Ramp Fee" />
              </span>
              <span className={styles.rowContent}>
                <NumberFormat displayType="text" value={fee.ramp_fee}
                  thousandSeparator prefix="$" suffix=".00"
                />
              </span>
            </div>
          </div>
        </div>
        )}
      </>
      ))}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step3.misc_fee" defaultMessage="Misc Fees" />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleModal('new')}>
                <FormattedMessage id="tenant.detail.add_fee" defaultMessage="Add new fee +" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      <table className={tableStyles.table}>
        <thead>
          <th>
            <FormattedMessage id="lease.tenants.detail.fee" defaultMessage="Fee" />
          </th>
          <th>
            <FormattedMessage id="lease.tenants.steps.step3.amount" defaultMessage="Amount" />
          </th>
          <th />
        </thead>
        <tbody>
          {info.map(fee => (
          <>
            {fee && fee.fee_type && fee.amount && (
            <tr>
              <td>{fee.fee_type}</td>
              <td>
                <NumberFormat displayType="text" value={fee.amount}
                  thousandSeparator prefix="$" suffix=".00"
                />
              </td>
              <td>
                <Collapsible
                  title="workorders.detail.actionBtn"
                  styleClasses={styles.actionsBtn} dateFilter
                >
                  <ul className={`${styles.dropdown} ${styles.open}`}>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('modify', fee)}>
                        <FormattedMessage id="tenant.detail.modify" defaultMessage="Modify" />
                      </Clickable>
                    </li>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('delete', fee)}>
                        <FormattedMessage id="tenant.detail.delete" defaultMessage="Delete" />
                      </Clickable>
                    </li>
                    <li className={styles.item}>
                      <Clickable className={styles.action} onClick={() => handleModal('duplicate', fee)}>
                        <FormattedMessage id="tenant.detail.duplicate" defaultMessage="Duplicate" />
                      </Clickable>
                    </li>
                  </ul>
                </Collapsible>
              </td>
            </tr>
            )}
          </>
          ))}
        </tbody>
      </table>
      </>
);

export default FeeDetail;
