import L from 'leaflet';
import * as shp from 'shpjs';

L.Shapefile = L.GeoJSON.extend({
  initialize(file, options) {
    L.Util.setOptions(this, options);

    L.GeoJSON.prototype.initialize.call(this, {
      features: []
    }, options);
    this.addFileData(file);
  },

  addFileData(file) {
    const self = this;
    this.fire('data:loading');
    if (typeof file !== 'string' && !('byteLength' in file)) {
      const data = this.addData(file);
      this.fire('data:loaded');
      return data;
    }

    shp(file).then((data) => {
      self.addData(data);
      self.fire('data:loaded');
    });
    return this;
  }
});

L.shapefile = function (a, b, c) {
  return new L.Shapefile(a, b, c);
};

export default L;
