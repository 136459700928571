import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

/** ******************************************************************
 *  Redux import
 * ************* */
import {
  fetchUsers,
  fetchRoles,
  editUser,
  createUser,
  deleteUser,
  clear } from '../redux/actions';

import { fetchUserProfile } from '../../../components/topbar/profile/redux/actions';
/** ******************************************************************
 *  Components import
 * ***************** */
import Panel from '../../../components/panel';
import Button from '../../../components/button';
import Checkbox from '../../../components/checkbox';

/** ******************************************************************
 *  Assets import
 * ************** */
import styles from '../Roles/roles.module.scss';
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';


class Users extends Component {
  state = {
    view: 'list',
    modal: false,
    selectedUser: {
      first_name: '',
      last_name: '',
      fullname: '',
      email: '',
      deletestatus: false,
      roles: []
    },
    loadingStatus: false,
    emailError: false,
    spaceErro:false
  };


  static getDerivedStateFromProps(props, state, nextProps) {
    if (props.apiStatusUser.success) {
      props.actionClear();
      props.actionFetch();
      return {
        ...state,
        view: 'list',
        selectedUser: { fullname: '', first_name: '', last_name: '', email: '', roles: [] }
      };
    }
    return state;
  }

  inputStyles={
    marginRight: '10px',
    marginLeft: '37px',
    width: '220px'
  };

  textStyles={
    marginLeft: '5px',
    width: '220px'
  }

  componentDidMount() {
    const {
      users,
      actionFetch,
      location,
      roles,
      actionFetchRoles,
      profileData,
      actionFetchProfile
    } = this.props;

    if (!users.length) actionFetch();
    if (!profileData.length) actionFetchProfile();
    if (location.state && location.state.slots) {
      if (!roles.length) actionFetchRoles();
      this.setState({ view: 'new' });
    }
  }

  componentDidUpdate(prevProps) {
    const { apiStatusUser } = this.props;
    if (prevProps.apiStatusUser.loading && !apiStatusUser.loading) {
      this.callloader();
    }
  }

  callloader= () => {
    this.setState({ loadingStatus: false });
  }

  handleClick = (id) => {
    const { users, roles, permissions, actionFetchRoles, profileData } = this.props;
    const { deletestatus }= this.state;
    if (permissions.includes('change_aerosimpleuser')) {
      const selected = users.find(e => e.id === id);
      if (!roles.length) actionFetchRoles();
      for (let i=0; i < profileData.profile.roles.length; i+=1) {
        if (deletestatus !== true) {
          for (let j=0; j< profileData.profile.roles[i].permissions.length; j+=1) {
            if (profileData.profile.roles[i].permissions[j].codename=== 'add_aerosimpleuser') {
              this.setState({ deletestatus: true });
            }
          }
        }
      }
      this.setState({ selectedUser: selected, view: 'change' });
    }
  }

  handleNew = (view) => {
    const { roles, actionFetchRoles } = this.props;
    if (!roles.length) actionFetchRoles();
    this.setState({ view,
      selectedUser: {
        fullname: '',
        first_name: '',
        last_name: '',
        email: '',
        roles: []
      },
      emailError: false,
      shouldShowErrors: false,
      spaceError: false
    });
  }

  handleFieldChange = (e) => {
    this.setState({ spaceError: false });
    const { id, value } = e.nativeEvent.target;
    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        [id]: value
      }
    }));
  }

  handleCheckboxChange = (e) => {
    const { roles: allRoles } = this.props;
    const { id, checked } = e.nativeEvent.target;
    const { selectedUser: { roles } } = this.state;
    if (checked) {
      const role = allRoles.find(r => r.id === parseInt(id, 10));
      roles.push(role);
    } else {
      roles.splice(roles.findIndex(i => i.id === parseInt(id, 10)), 1);
    }
    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        roles
      }
    }));
  }

  handleCreate = () => {
    const { selectedUser: { id, ...data } } = this.state;
    const { actionEdit, actionCreate } = this.props;
    this.setState({ loadingStatus: true });
    data.roles = data.roles.map(r => r.id);
    if (data.email && data.first_name && data.roles.length>0) {
      delete data.image;
      if (id) {
        actionEdit(id, data);
      } else {
        actionCreate(data);
      }
    } else {
      this.setState({ shouldShowErrors: true, emailError: false, spaceError: false, loadingStatus: false });
    }
    if (this.handleValidation()) {
      // console.log('form submitted');
    }
  }

  handelModalState= () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal
    });
  }

  handleDelete = () => {
    const { selectedUser: { id, ...data } } = this.state;
    const { actionDelete } = this.props;
    this.setState({ loadingStatus: true });
    data.roles = data.roles.map(r => r.id);
    if (data.email && data.first_name && data.roles.length>0) {
      if (id) {
        const formData = { user: id };
        actionDelete(id, formData);
      }
    } else {
      this.setState({ loadingStatus: false });
    }
  }

  handleValidation() {
    const { selectedUser }= this.state;
    const fields = selectedUser;
    const errors = {};
    let formIsValid = true;
    if (typeof fields.first_name !== 'undefined') {
      if (!fields.first_name.trim() && fields.first_name.length > 0) {
        this.setState({ spaceError: true });
      }
    }
    if (typeof fields.last_name !== 'undefined') {
      if (!fields.last_name.trim() && fields.last_name.length > 0) {
        this.setState({ spaceError: true });
      }
    }

    if (typeof fields.email !== 'undefined') {
      const lastAtPos = fields.email.lastIndexOf('@');
      const lastDotPos = fields.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0
        && fields.email.indexOf('@@') === -1
        && lastDotPos > 2
        && (fields.email.length - lastDotPos) > 2)) {
        formIsValid = false;
        this.setState({ emailError: true });
        errors.email = 'Email is not valid';
      }
    }
    return formIsValid;
  }

  render() {
    const { users, roles, error } = this.props;
    const { view,
      selectedUser,
      shouldShowErrors,
      emailError,
      spaceError,
      loadingStatus,
      modal,
      deletestatus } = this.state;
    return (
      <>
        {/* ************************************************ */}
        {/* **************** listing users ***************** */}
        {/* ************************************************ */}
        <div className={styles.list}>
          {view === 'list' && (
            <Panel title="settings.users.title" defaultTitle="Users">
              <div className={styles.newButton}>
                <Button translationID="settings.users.add"
                  onClick={() => this.handleNew('new')}
                  defaultText="New User" action="primary"
                />
              </div>
              <table>
                <thead>
                  <tr>
                    <th width="250"><FormattedMessage id="settings.users.Name" defaultMessage="Contact Name" /></th>
                    <th><FormattedMessage id="settings.users.roles" defaultMessage="ROLES" /></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(e => (
                    <tr key={e.id} className={styles.user}
                      onClick={() => this.handleClick(e.id)} onKeyPress={this.handleClick}
                    >
                      <td>{e.fullname}</td>
                      <td>
                        {e.roles.length > 0 && e.roles.slice(0, 3).map(el => el.name).join(', ')}
                        {e.roles.length > 3 && ` + ${e.roles.length - 2} more`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Panel>
          )}
          {/* ************************************************ */}
          {/* *********** for edit and crete users *********** */}
          {/* ************************************************ */}
          {(view === 'change' || view === 'new') && (
            <div className={styles.newItem}>
              <div className={styles.content}>
                <div className={styles.details}><FormattedMessage id="settings.users.Userdetails" defaultMessage="User Details" /></div>
                <div className={styles[`inputs_${view}`]}>
                  {/* <label className={styles.input}>
                    Full Name
                    <input type="text" id="fullname" readOnly={view === 'change'} defaultValue={selectedUser.fullname}
                      onChange={this.handleFieldChange}
                    />
                    <div className={styles.error}>
                      {shouldShowErrors
                        && selectedUser.fullname === '' && <span>Name cannot be empty</span>}
                    </div>
                  </label> */}
                  <label className={styles.input}>
                    <FormattedMessage id="settings.users.firstname" defaultMessage="First Name" />
                    <input type="text" id="first_name" defaultValue={selectedUser.first_name}
                      onChange={this.handleFieldChange} style={this.textStyles}
                    />
                    <div className={styles.error}>
                      {shouldShowErrors
                        && selectedUser.first_name === ''
                        && (
                          <span>
                            <FormattedMessage id="settings.users.name_error"
                              defaultMessage="Name cannot be empty"
                            />
                          </span>
                        )
                      }
                    </div>
                    {spaceError && selectedUser.first_name.length > 0 &&(
                      <div className={styles.error}>
                        {' '}
                        <FormattedMessage
                          id="roles.error.space_error"
                          defaultMessage="Spaces are not allowed"
                        />
                        {' '}
                      </div>
                    )}
                  </label>
                  <label className={styles.input}>
                    <FormattedMessage id="settings.users.lastname" defaultMessage="Last Name" />
                    <input type="text" id="last_name" defaultValue={selectedUser.last_name}
                      onChange={this.handleFieldChange} style={this.textStyles}
                    />
                    <div className={styles.error}>
                      {shouldShowErrors
                        && selectedUser.last_name === ''
                        &&(
                        <span>
                          <FormattedMessage id="settings.users.name_error"
                            defaultMessage="Name cannot be empty"
                          />
                        </span>
                        )
                      }
                    </div>
                    {spaceError && selectedUser.last_name.length > 0 && (
                      <div className={styles.error}>
                        {' '}
                        <FormattedMessage
                          id="roles.error.space_error"
                          defaultMessage="Spaces are not allowed"
                        />
                        {' '}
                      </div>
                    )}
                  </label>
                  <label className={styles.input}>
                    <FormattedMessage id="settings.users.email" defaultMessage="Email" />
                    <input type="email" id="email" defaultValue={selectedUser.email}
                      onChange={this.handleFieldChange} style={this.inputStyles}
                    />
                    <div className={styles.error}>
                      {error.email && <span>{error.email[0]}</span>}
                      {!shouldShowErrors && emailError
                        ?(
                          <h6 className={styles.para}>
                            <FormattedMessage id="settings.users.email_valid"
                              defaultMessage="Email is not valid"
                            />
                          </h6>) : ''
                      }
                      {shouldShowErrors
                         && selectedUser.email === ''
                         && (
                         <span>
                           <FormattedMessage
                             id="settings.users.email_empty"
                             defaultMessage="Email cannot be empty"
                           />
                         </span>
                         )
                      }
                    </div>
                  </label>
                </div>
                <div>
                  <div>
                    <span className={styles.privileges} style={{ margin: 0 }}>
                      <FormattedMessage id="settings.users.roles" defaultMessage="Roles" />
                    </span>
                    <div className={styles.privilegesList}>
                      {roles.map(r => (
                        <Checkbox id={r.id} key={r.id} label={r.name}
                          checked={selectedUser.roles.find(ro => ro.id === r.id) !== undefined}
                          onChange={this.handleCheckboxChange}
                        />
                      ))}
                      <div className={styles.error}>
                        {shouldShowErrors
                              && selectedUser.roles.length < 1
                              && (
                                <span>
                                  <FormattedMessage
                                    id="settings.users.Roles_error"
                                    defaultMessage="Roles cannot be empty"
                                  />
                                </span>
                              )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <Button onClick={() => this.setState({ view: 'list' })}
                  translationID="roles.footer.cancel" defaultText="Cancel" action="tertiary"
                />
                <div className={styles.save}>
                  <Loading loadingStatus={loadingStatus} />

                  {deletestatus === true ? (
                    <Button onClick={this.handelModalState}
                      translationID="roles.footer.Delete"
                      defaultText="Delete" action="danger"
                    />
                  ): '' }
                  <Modal
                    width="auto"
                    minHeight="auto"
                    showIn={modal}
                    contentStyles={{
                      padding: ' 15px 30px 5px 40px'
                    }}
                    centered
                    onClose={this.handelModalState}
                  >
                    <h3>
                      <FormattedMessage
                        id="settings.users.deleteUser"
                        defaultText="Are you sure you want to delete the user "
                      />
                      <b style={{ color: 'red' }}>
                        {' '}
                        {selectedUser.first_name}
                        {' '}
                      </b>
                        ?
                    </h3>

                    <div className={styles.footer}>
                      <Button onClick={this.handelModalState}
                        translationID="roles.footer.No"
                        defaultText="No" action="tertiary"
                      />
                      <Button onClick={this.handleDelete}
                        translationID="roles.footer.Yes"
                        defaultText="Yes"
                        action="secondary"
                        style={{ minHeight: '10px' }}
                      />
                    </div>
                  </Modal>
                  <Loading loadingStatus={loadingStatus} />
                  <Button onClick={this.handleCreate}
                    translationID="roles.footer.save" defaultText="Save" action="primary"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Users.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  actionFetchRoles: PropTypes.func.isRequired,
  actionEdit: PropTypes.func.isRequired,
  actionCreate: PropTypes.func.isRequired,
  apiStatusUser: PropTypes.shape().isRequired
};

Users.defaultProps = {
  users: []
};

const mapStateToProps = state => ({
  users: state.settings.users,
  roles: state.settings.roles,
  apiStatusUser: state.settings.apiStatusUser,
  error: state.settings.error,
  permissions: state.permissions,
  profileData: state.profile
});

const mapDispatchToProps = dispatch => ({
  // Fetch Users
  actionFetch: () => {
    dispatch(fetchUsers());
  },
  actionEdit: (id, data) => {
    dispatch(editUser(id, data));
  },
  actionCreate: (data) => {
    dispatch(createUser(data));
  },
  actionDelete: (id, data) => {
    dispatch(deleteUser(id, data));
  },
  actionClear: () => {
    dispatch(clear());
  },
  actionFetchProfile: () => {
    dispatch(fetchUserProfile());
  },
  // Fetch Roles
  actionFetchRoles: () => {
    dispatch(fetchRoles());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
