import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import styles from './toDoList.module.scss';

import {
  fetchTasks,
  completeTask,
  fetchDelegatedTasks,
  fetchCompletedTasks,
  clear } from '../redux/actions';

import { showConfirmModal, hideModal } from '../../../general_redux/actions';
/** ******************************************************************
 *  Components import
 * ***************** */

import Button from '../../../components/button';
import SectionHeader from '../../../components/sectionHeader';
import Separator from '../../../components/separator';
import NewTask from './components/NewTask';
import TaskDetail from './components/TaskDetail';
import ToDoTable from './components/toDoTable';
import FilterForm from './FilterForm';
import Shortcuts from '../../../components/topbar/shortcuts/shortcuts';
import Toolbar from './Toolbar';
import Paginator from '../../../components/paginator/paginator';
import Loading from '../../../components/loading/loading';

/** ******************************************************************
 *  Assets import
 * ************* */
import todo from '../../../icons/Tasks.png';
import Collapsible from '../../../components/collapsible/Collapsible';
import Clickable from '../../../components/clickable/Clickable';
import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';

class ToDoList extends Component {
  links = [
    { url: '/', name: this.props.intl.formatMessage({ id: 'shortcuts.aerobot' }), key: 'aerobot' },
    { url: '/todo', name: this.props.intl.formatMessage({ id: 'shortcuts.todo' }), key: 'tasks' },
    { url: '/messenger', name: this.props.intl.formatMessage({ id: 'shortcuts.chat' }), key: 'messenger' },
    { url: '/settings/organization', name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }), permissions: ['can_modify_airport_settings'], key: 'settings' }
  ];

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      tasks: {},
      section: 'my_tasks',
      filterCategories: ['GeneralTask'],
      filterPopup: false,
      GroupBy: false,
      Inspections: false,
      GeneralTask: false,
      currentPage: 1,
      loadingStatus: false,
      toolsection: 'list'
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  componentDidMount = () => {
    const { filterCategories, toolsection } = this.state;
    const { clearActions } = this.props;
    clearActions();
    let filter = filterCategories;
    let taskView = toolsection;
    if (localStorage.getItem('todo_view')) taskView = localStorage.getItem('todo_view');
    this.setState({ loadingStatus: true });
    if (localStorage.getItem('todo_filters')) {
      filter = localStorage.getItem('todo_filters').split(',');
    }
    this.setState({ filterCategories: filter }, () => this.handleToolSection(taskView));
    filter.forEach((item) => {
      if (item === 'Inspections') {
        this.setState({ Inspections: true });
      } else if (item === 'GeneralTask') {
        this.setState({ GeneralTask: true });
      }
    });
    if (localStorage.getItem('sortbytasks') === '0') {
      this.handleTabClick('my_tasks');
      localStorage.removeItem('sortbytasks');
    } else if (localStorage.getItem('sortbytasks') === '1') {
      this.handleTabClick('delegated_tasks');
      localStorage.removeItem('sortbytasks');
    } else if (localStorage.getItem('sortbytasks') === '2') {
      this.handleTabClick('completed_tasks');
      localStorage.removeItem('sortbytasks');
    } else if (localStorage.getItem('sortbytasks') === '3') {
      this.toggleModal('modal', true);
      localStorage.removeItem('sortbytasks');
    } else if (localStorage.getItem('tasks') === '1') {
      this.toggleModal('modal', true);
      localStorage.removeItem('tasks');
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.action.success) {
      let result = '';
      if (state.GroupBy && state.section !== 'completed_tasks') {
        result = {
          today: [],
          overdue: [],
          tomorrow: [],
          thisWeek: [],
          nextWeek: [],
          noDueDate: []
        };
        // Formatting response grouping by date.
        const today = moment();
        const tomorrow = today.clone().add(1, 'day');
        props.tasks.forEach((t) => {
          if ((t.event.label ? state.Inspections : state.GeneralTask)
            || (!state.Inspections && !state.GeneralTask)) {
            // without due date
            if (!t.event.due_date) {
              result.noDueDate.push(t);
              return;
            }
            // overdue tasks
            const due = moment(t.end);
            if (due.isBefore(today, 'day')) {
              result.overdue.push(t);
              return;
            }
            // today tasks
            if (due.isSame(today, 'day')) {
              result.today.push(t);
              return;
            }
            // tomorrow tasks
            if (due.isSame(tomorrow, 'day')) {
              result.tomorrow.push(t);
              return;
            }
            // this week tasks
            if (due.isSame(today, 'week') && due.isAfter(today)) {
              result.thisWeek.push(t);
              return;
            }
            result.nextWeek.push(t);
          }
        });
        let occurrence = false;
        Object.keys(result).forEach((k) => {
          if (result[k].length > 0) {
            occurrence = true;
          }
        });
        if (occurrence === false) {
          result = [];
        }
      } else {
        result = props.tasks;
      }
      // update the selected task when it is updated
      const selected = props.updated_task;
      props.clearActions();
      let page = state.currentPage;
      if (selected) {
        if (state.section === 'my_tasks' && !state.GroupBy) {
          let filterslist = '';
          state.filterCategories.forEach((item) => {
            if (item === 'Inspections') {
              filterslist += 'inspections,';
            } else if (item === 'GeneralTask') {
              filterslist += 'generaltask,';
            }
          });
          if ((props.paginationCount > 10) && (props.paginationCount % 10 === 0)) page -= 1;
          if (props.paginationCount <= 10) page = 1;
          props.actionFetch(page, filterslist);
        }
        return { ...state, tasks: result, selected, currentPage: page, loadingStatus: false };
      }
      return { ...state, tasks: result, loadingStatus: false };
    }
    return state;
  }


  handleFiltersPopup = () => {
    const { filterPopup } = this.state;
    this.setState({ filterPopup: !filterPopup });
  }

  handleOutsideClick = () => {
    const { filterPopup } = this.state;
    if (filterPopup) this.setState({ filterPopup: false });
  }

  handleRemoveFilter = (val) => {
    const { filterCategories } = this.state;
    const filteredItems = filterCategories.filter(item => item !== val);
    const page = 1;
    this.setState({ currentPage: page });
    if (filteredItems.length > 0) {
      this.setState({ filterCategories: filteredItems }, () => {
        this.updateFilter(filteredItems);
      });
      localStorage.setItem('todo_filters', filteredItems);
    } else {
      this.setState({ filterCategories: filteredItems, tasks: {} });
      localStorage.removeItem('todo_filters');
    }
  }

  updateFilter = (filterCategories) => {
    const { section, toolsection } = this.state;
    this.setState({ filterCategories: [...filterCategories], loadingStatus: true, currentPage: 1 });
    if (filterCategories.length > 0) {
      const filterslist = this.handleFiltersList(filterCategories);
      if (filterslist.includes('inspections')) {
        this.setState({ Inspections: true });
      } else {
        this.setState({ Inspections: false });
      }
      if (filterslist.includes('generaltask')) {
        this.setState({ GeneralTask: true });
      } else {
        this.setState({ GeneralTask: false });
      }
      this.handleSections(section, filterslist, 1, toolsection);
      localStorage.setItem('todo_filters', filterCategories);
    } else {
      localStorage.removeItem('todo_filters');
      this.setState({ tasks: {} });
    }
    this.setState({ filterPopup: false });
  }

  handleFiltersList = (filters) => {
    let filterslist = '';
    filters.forEach((item) => {
      if (item === 'Inspections') {
        filterslist += 'inspections,';
      } else if (item === 'GeneralTask') {
        filterslist += 'generaltask,';
      }
    });
    return filterslist;
  }

  handleSections = (section, filterslist, page, toolsection) => {
    const { actionFetch, actionFetchDelegated, actionFetchCompleted } = this.props;
    if (toolsection === 'time') {
      switch (section) {
        case 'my_tasks':
          actionFetch();
          break;
        case 'delegated_tasks':
          actionFetchDelegated();
          break;
        case 'completed_tasks':
          actionFetchCompleted(page, filterslist);
          break;
        default:
          break;
      }
    } else {
      switch (section) {
        case 'my_tasks':
          actionFetch(page, filterslist);
          break;
        case 'delegated_tasks':
          actionFetchDelegated(page, filterslist);
          break;
        case 'completed_tasks':
          actionFetchCompleted(page, filterslist);
          break;
        default:
          break;
      }
    }
  }

  changePage = (page) => {
    this.setState({ currentPage: page });
    const { section, filterCategories, toolsection } = this.state;
    if (filterCategories.length > 0) {
      const filterslist = this.handleFiltersList(filterCategories);
      this.handleSections(section, filterslist, page, toolsection);
    }
  }

  /**
   * Function that handles the tabs state and
   * make the call for the corresponding tasks
   * */
  handleTabClick = (value) => {
    const { clearActions } = this.props;
    const { currentPage, section, filterCategories, toolsection } = this.state;
    this.setState({ section: value, loadingStatus: true });
    let page = currentPage;
    if (section !== value) {
      this.setState({ currentPage: 1, tasks: {} });
      clearActions();
      this.setState({ tasks: {} });
      page = 1;
    }
    if (filterCategories.length > 0) {
      const filterslist = this.handleFiltersList(filterCategories);
      this.handleSections(value, filterslist, page, toolsection);
    }
  }

  toggleModal = (modal, value) => {
    const { section } = this.state;
    this.setState({ [modal]: value });
    if (!value) {
      setTimeout(() => {
        this.handleTabClick(section);
      }, 500);
    }
  }

  goToInspection = (occurrence) => {
    const { history } = this.props;
    history.push({
      pathname: `${INSPECTIONS_HOME_ROUTE}${occurrence.event.inspection.id}/complete`,
      // this info is for indentify the task.
      state: {
        taskid: occurrence.event.id,
        date: occurrence.end,
        email_date: moment().format()
      }
    });
  }

  handleCompleted = (occurrence) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const {
      actionComplete,
      actionConfirm,
      actionHideModal } = this.props;
    let content = '';
    const inspection_task = {
      title: formatMessage({ id: 'todo.completed_task' }),
      body: formatMessage({ id: 'todo.mark_completed_task' })
    };
    const general_task = {
      title: formatMessage({ id: 'todo.completed_task' }),
      body: formatMessage({ id: 'todo.inspection_task' })
    };
    content = occurrence.event.inspection === null ? inspection_task : general_task;
    if (occurrence.event.inspection === null) {
      actionConfirm(content, () => {
        actionComplete(occurrence);
        actionHideModal();
      }, actionHideModal);
    } else {
      actionConfirm(content, () => {
        this.goToInspection(occurrence);
        actionHideModal();
      }, actionHideModal);
    }
  }

  goToDetail = (task) => {
    this.toggleModal('detailModal', true);
    this.setState({ selected: task });
  }

  handleEditModalClose = () => {
    this.toggleModal('detailModal', false);
    this.setState({ selected: undefined });
  }

  handleNewTaskModalClose = () => {
    this.toggleModal('modal', false);
    this.setState({ selected: undefined });
  }

  handleGoToEdit = () => {
    this.toggleModal('modal', true);
    this.toggleModal('detailModal', false);
  }

  handleCreateTask = () => {
    this.toggleModal('modal', true);
    this.setState({ selected: undefined });
  }

  handleToolSection = (value) => {
    const { section, filterCategories, toolsection, currentPage } = this.state;
    const { clearActions } = this.props;
    if (toolsection !== value) {
      this.setState({ tasks: {} });
      clearActions();
    }
    localStorage.setItem('todo_view', value);
    this.setState({ toolsection: value, loadingStatus: true });
    if (value === 'time') this.setState({ GroupBy: true });
    else this.setState({ GroupBy: false });
    const filterslist = this.handleFiltersList(filterCategories);
    this.handleSections(section, filterslist, currentPage, value);
  }

  render() {
    const { actionComplete, history, paginationCount } = this.props;
    const { modal, detailModal, tasks: rawTasks,
      section, selected, filterCategories,
      GroupBy, Inspections, GeneralTask, currentPage, loadingStatus } = this.state;
    const { filterPopup: popup, toolsection } = this.state;
    return (
      <>
        <Shortcuts links={this.links} />
        {loadingStatus && (<Loading loadingStatus={loadingStatus} />)}
        <div className={styles.toDoList} ref={(node) => { this.node = node; }}>
          <SectionHeader icon={todo} translationID="todo.title" defaultTitle="To Do">
            <div className={styles.navigation}>
              <Clickable className={section === 'my_tasks' ? styles.active : ''}
                onClick={() => this.handleTabClick('my_tasks')}
              >
                <FormattedMessage id="todo.my_tasks" defaultMessage="My Tasks" />
              </Clickable>
              <Clickable className={section === 'delegated_tasks' ? styles.active : ''}
                onClick={() => this.handleTabClick('delegated_tasks')}
              >
                <FormattedMessage id="todo.my_delegated_tasks" defaultMessage="My delegated tasks" />
              </Clickable>
              <Clickable className={section === 'completed_tasks' ? styles.active : ''}
                onClick={() => this.handleTabClick('completed_tasks')}
              >
                <FormattedMessage id="todo.my_completed_tasks" defaultMessage="My completed tasks" />
              </Clickable>
            </div>
            <div className={styles.detailHeader}>
              {/* <IconButton icon={settings} onClick={() => {}} />
              <Separator /> */}
              <Button translationID="todo.add" defaultText="Add New Task"
                onClick={this.handleCreateTask}
              />
              <Separator />
            </div>
          </SectionHeader>
          <Toolbar
            handleDropdown={this.handleFiltersPopup}
            filterCategories={filterCategories}
            toolsection={toolsection}
            handleToolSection={this.handleToolSection}
          />
          <div className={styles.filter}>
            {filterCategories.map(category => (
              <span className={styles.user} key={category}>
                {category}
                <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
                  onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
                />
              </span>
            ))}
          </div>
          <div className={styles.list}>
            {/* if section are my tasks or my delegated tasks, the table is
                divided by collapsible panels  */}
            { filterCategories.length > 0 && GroupBy && section !== 'completed_tasks' ? Object.keys(rawTasks).map(k => (
              rawTasks[k].length > 0 && (
              <Collapsible key={k} title={`todo.${k}`}
                styleClasses={`${styles.header} ${k === 'overdue' ? styles.overdue : ''}`}
                openOnMount={!!(k==='overdue' || k==='today')} autoheight={false}
              >
                <ToDoTable taskGroup={rawTasks[k]} section={section}
                  handleCompleted={this.handleCompleted}
                  goToDetail={this.goToDetail}
                  GroupBy={GroupBy}
                  Inspections={Inspections}
                  GeneralTask={GeneralTask}
                />
              </Collapsible>
              )
            )) : (
              filterCategories.length > 0 && rawTasks.length > 0 && (
              <ToDoTable taskGroup={rawTasks} section={section}
                handleCompleted={this.handleCompleted}
                goToDetail={this.goToDetail}
              />
              ))}
            {filterCategories.length > 0 && rawTasks.length === 0 && (
              <span className={styles.noResults}>
                <FormattedMessage id="todo.my_tasks_selected_criteria" defaultMessage="There are no tasks with the selected criteria." />
              </span>
            )}
            {filterCategories.length === 0 && (
              <span className={styles.noResults}>
                <FormattedMessage id="todo.my_tasks_filter_criteria" defaultMessage="There is no selected criteria." />
              </span>
            )}
            {filterCategories.length > 0 && (paginationCount > 10 || rawTasks.length > 0) && (!GroupBy || (GroupBy && section === 'completed_tasks'))
              && (
                <Paginator
                  page={currentPage}
                  totalRecords={paginationCount}
                  pageLimit={10}
                  pageNeighbours={1}
                  onPageChanged={page => this.changePage(page)}
                />
              )
            }
            {/* There are no tasks with the selected criteria. */}
          </div>
          <NewTask showIn={modal} onClose={this.handleNewTaskModalClose} taskEdit={selected} changePage={this.changePage} />
          <TaskDetail showIn={detailModal} task={selected}
            editTask={this.handleGoToEdit}
            onComplete={() => { actionComplete(selected); }}
            onClose={this.handleEditModalClose}
            history={history}
          />
          {popup && (
          <Transition in={popup} timeout={200} unmountOnExit>
            {state => (
              <FilterForm
                transition={this.slideStyles[state]}
                handleOutsideClickFilter={this.handleOutsideClick}
                update={this.updateFilter}
                cancel={this.handleFiltersPopup}
                filterCategories={filterCategories}
              />
            )}
          </Transition>
          )}
        </div>
      </>
    );
  }
}

ToDoList.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  actionFetchDelegated: PropTypes.func.isRequired,
  actionFetchCompleted: PropTypes.func.isRequired,
  actionComplete: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  tasks: state.tasks.tasks,
  paginationCount: state.tasks.paginationCount,
  updated_task: state.tasks.updated_task,
  rules: state.tasks.rules,
  action: state.tasks.action
});

const mapDispatchToProps = dispatch => ({
  actionFetch: (page, filterslist) => {
    dispatch(fetchTasks(page, filterslist));
  },
  actionFetchDelegated: (page, filterslist) => {
    dispatch(fetchDelegatedTasks(page, filterslist));
  },
  actionFetchCompleted: (page, filterslist) => {
    dispatch(fetchCompletedTasks(page, filterslist));
  },
  actionComplete: (id) => {
    dispatch(completeTask(id));
  },
  clearActions: () => {
    dispatch(clear());
  },
  // Confirmation dialog actions
  actionConfirm: (body, accept, cancel) => {
    dispatch(showConfirmModal(body, accept, cancel, 'todo.newTask.complete_task'));
  },
  actionHideModal: (insp) => {
    dispatch(hideModal(insp));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ToDoList));
