/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './assetSchemaBuilder.module.scss';
import { FormattedMessage , injectIntl} from 'react-intl';

const AssetSelfInspection = ({ values, selfInspection, onSelectChange ,intl}) => (
  <table className={styles.panel}>
    <thead>
      <tr>
        <th><FormattedMessage  id="Asset.settings.category" defaultMessage="Category" /></th>
        <th><FormattedMessage  id="Asset.settings.subcategory" defaultMessage="Subcategory" /></th>
        <th><FormattedMessage  id="Asset.settings.asset" defaultMessage="Asset" /></th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(selfInspection).map(id => selfInspection[id].checklist.map(ch => (
        <tr key={`${id}-${ch.key}`}>
          <td>{selfInspection[id].title}</td>
          <td>{ch.value}</td>
          <td>
            <select onChange={e => onSelectChange(id, ch.key, e.target.value)}
              value={values && values[id] ? values[id][ch.key] : ''}
            >
              <option value="">{intl.formatMessage({ id: 'Asset.settings.None' })}</option>
              <option value="signs">{intl.formatMessage({ id: 'Asset.settings.signs' })}</option>
              <option value="lights">{intl.formatMessage({ id: 'Asset.settings.lights' })}</option>
            </select>
          </td>
        </tr>
      )))}
    </tbody>
  </table>
);

AssetSelfInspection.propTypes = {
  selfInspection: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default injectIntl(AssetSelfInspection);
