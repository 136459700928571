import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';

import Forbidden from '../Forbidden';
import OperationsDashBoard from './DashBoard/OperationsDashBoard';


const OperationsDashBoardLayout = ({ match }) => (
  <Permissions allowed={['view_dashboard']} fallbackElement={<Forbidden />}>
    <Switch>
      <Route exact path={`${match.url}`} component={OperationsDashBoard} />

    </Switch>
  </Permissions>
);

OperationsDashBoardLayout.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default OperationsDashBoardLayout;
