import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Autocomplete from 'react-autocomplete';
import Dropzone from 'react-dropzone';
import PulpoField from '../../../../pulpo_visualizer/fields';
import fieldstyles from '../../../../pulpo_visualizer/fields/fields.module.scss';
import attachmentStyles from '../../../workorders/Create/components/fixedFields.module.scss';
import FormattedMessageWithClass from '../../../../components/formattedMessageWithClass';
import Button from '../../../../components/button/button';
import Clickable from '../../../../components/clickable/Clickable';
import icon from '../../../../icons/search.svg';
import styles from './fixedfields.module.scss';
import BrowseSpecies from './BrowseSpecies';
import GridMap from './GridMap';

class FixedFields extends Component {
  state = { currentValue: undefined,
    bird_strike: false,
    dispersal: false,
    browse: false,
    GridMapView: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.info && props.info.logged_by && props.info.logged_by.id && state.currentValue === undefined) {
      return { ...state, currentValue: props.info.logged_by.fullname };
    }
    return state;
  }

  handleAutocompleteChange = (e) => {
    const { searchForUser } = this.props;
    this.setState({ currentValue: e.target.value });
    searchForUser(e.target.value);
  }

  handleSpecies = (section, answer, fieldId) => {
    const { handleAnswerChanged, speciesInfo, info } = this.props;
    if (fieldId === 'wildlife_type') {
      handleAnswerChanged(section, '', 'wildlife_species');
    }
    if (fieldId === 'wildlife_species') {
      const imageURL = speciesInfo.filter(
        s => s.Type === info.wildlife_type
      )[0].Species.filter(e => e.name === answer)[0].image;
      handleAnswerChanged(section, imageURL, 'wildlife_image');
    }
    handleAnswerChanged(section, answer, fieldId);
  }

  handleAutocompleteSelect = (val, item) => {
    const { handleAnswerChanged, handleFieldErrorChanged } = this.props;
    this.setState({ currentValue: val });
    handleAnswerChanged('info', item, 'logged_by');
    handleFieldErrorChanged('logged_by', false);
  }

  handleConditions = (section, value) => {
    const { handleAnswerChanged } = this.props;
    this.setState({ [section]: value });
    handleAnswerChanged('info', value, section);
  }

  handleZoomLevel = (val) => {
    const { handleZoomLevel } = this.props;
    handleZoomLevel(val);
  }

  handleRemoveImage = (r) => {
    const { handleAnswerChanged, info } = this.props;
    const res = info.attachments.filter(e => e !== r);
    handleAnswerChanged('info', res, 'attachments');
  }

  handleDrop = (dropped) => {
    const { handleAnswerChanged, info } = this.props;
    handleAnswerChanged('info', [...info.attachments, ...dropped], 'attachments');
  }

  handleBrowse = () => {
    const { browse } = this.state;
    this.setState({ browse: !browse });
  }

  handleSelectedWildlife = (object) => {
    const { handleAnswerChanged, handleFieldErrorChanged } = this.props;
    handleAnswerChanged('info', object.image, 'wildlife_image');
    handleAnswerChanged('info', object.species, 'wildlife_species');
    handleAnswerChanged('info', object.type, 'wildlife_type');
    handleFieldErrorChanged('wildlife_type', false);
    handleFieldErrorChanged('wildlife_species', false);
    this.setState({ browse: false });
  }

  handleGridView = () => {
    const { GridMapView } = this.state;
    this.setState({ GridMapView: !GridMapView });
  }


  render() {
    const { currentValue, bird_strike, dispersal, browse, GridMapView } = this.state;
    const {
      handleAnswerChanged,
      showFormErrors,
      handleFieldErrorChanged,
      userlist,
      info,
      speciesInfo,
      speciesList,
      speiceError,
      handleSpeciesSearch,
      weather,
      sortData,
      gridList, intl
    } = this.props;
    const { formatMessage } = intl;
    return (
      <>
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.title} id="wildlife.form.title2"
            defaultMessage="General Information"
          />
        </div>
        <div className={styles.float}>
          <div className={styles.column}>
            <div className={fieldstyles.field}>
              <FormattedMessageWithClass
                className={fieldstyles.title} id="wildlife.form.reported_by"
                defaultMessage="Reported by"
              />
              <Autocomplete getItemValue={item => item.fullname} items={userlist}
                wrapperStyle={{ position: 'relative' }}
                value={currentValue}
                onChange={this.handleAutocompleteChange}
                onSelect={this.handleAutocompleteSelect}
                renderMenu={children => (
                  <div className={styles.autocompleteMenu}>
                    {children}
                  </div>
                )}
                renderItem={(item, isHighlighted) => (
                  <div key={item.id} className={`${styles.menuItem} ${isHighlighted && styles.highlighted}`}>
                    {item.fullname}
                  </div>
                )}
              />
              {!currentValue && showFormErrors && (
              <small>
                <FormattedMessage id="pulpoforms.errors.not_blank" defaultMessage="There is an error in this field" />
              </small>
              )}
            </div>
          </div>
          <div className={styles.column}>
            <PulpoField key="date" id="incident_date" type="datetime"
              translationID="wildlife.form.date" title="Incident date"
              isRequired handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.incident_date}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
            />
          </div>
        </div>
        <div>
          <div className={styles.float}>
            <div className={styles.fullWidthleft}>
              <PulpoField key="weather_conditions" id="weather_conditions" type="string"
                translationID="wildlife.form.weather" disabled
                title="Weather Conditions"
                handleValueChange={() => ({})}
                answer={weather && weather.summary}
                showFieldErrors={showFormErrors} handleFieldErrorChanged={() => ({})}
              />
            </div>
            <div className={styles.fullWidthright}>
              <PulpoField key="temperature" id="temperature" type="string"
                translationID="wildlife.form.temperature" disabled
                title="Temperature"
                handleValueChange={() => ({})}
                answer={weather && weather.temperature && weather.temperature.replace(/<[^>]*>/g, '')}
                showFieldErrors={showFormErrors} handleFieldErrorChanged={() => ({})}
              />
            </div>
          </div>
        </div>
        <div className={styles.float}>
          { gridList.length > 0 && (
          <Clickable className={styles.gridmap} onClick={this.handleGridView}>
            <FormattedMessage id="wildlife.form.gridmap" defaultMessage="View Grid Map" />
          </Clickable>
          )}
        </div>
        <PulpoField key="grid" id="grid" type="select"
          title="widlife.form.grid"
          translationID="widlife.form.grid"
          handleValueChange={(a, b) => this.handleSpecies('info', a, b)}
          answer={info.grid} className={styles.fullInput}
          values={gridList.map(e => ({ key: e.name, value: e.name }))}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={handleFieldErrorChanged}
        />
        <PulpoField key="location" id="location" type="polyline"
          translationID="workorder.create.location"
          className={styles.fullInput} title="Location" isRequired
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.location}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
          handleZoomLevel={val => this.handleZoomLevel(val)}
        />
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.title} id="wildlife.form.title3"
            defaultMessage="Wildlife Information"
          />
        </div>
        <div className={styles.float}>
          <div className={styles.column}>
            <Clickable className={styles.browse} onClick={this.handleBrowse}>
              <img src={icon} alt="" />
              <FormattedMessage id="wildlife.form.browse" defaultMessage="Browse" />
            </Clickable>
            <PulpoField key="wildlife_type" id="wildlife_type" type="select"
              title="Wildlife Type" isRequired
              translationID="wildlife.form.type"
              handleValueChange={(a, b) => this.handleSpecies('info', a, b)}
              answer={info.wildlife_type}
              values={speciesInfo.map(e => ({ key: e.Type, value: e.Type }))}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
            />
            {info.wildlife_type && (
              <PulpoField key="wildlife_species" id="wildlife_species" type="select"
                title="Wildlife Species" isRequired
                translationID="wildlife.form.species"
                handleValueChange={(a, b) => this.handleSpecies('info', a, b)}
                answer={info.wildlife_species}
                values={speciesInfo.filter(s => s.Type === info.wildlife_type)[0].Species.map(e => ({ key: e.name, value: e.name }))}
                showFieldErrors={showFormErrors}
                handleFieldErrorChanged={handleFieldErrorChanged}
              />
            )}
            {speiceError && (
              <small style={{ color: 'red' }}>
                <FormattedMessage id="pulpoforms.errors.not_blank" defaultMessage="This field cannot be blank" />
              </small>
            )}
            <PulpoField key="spotted_count" id="spotted_count" type="number"
              translationID="wildlife.form.spotted" isRequired
              title="Number of Spotted"
              handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.spotted_count} className={styles.number}
              showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
            />
          </div>
          {info.wildlife_species && (
            <div className={styles.column}>
              <img src={info.wildlife_image} alt="" />
            </div>
          )}
        </div>
        <div className={styles.float}>
          <div className={styles.column}>
            <PulpoField key="associated_attractant" id="associated_attractant" type="string"
              title="Associated Attractant"
              translationID="wildlife.form.attachments"
              handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.associated_attractant}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
            />
          </div>
          <div className={styles.column}>
            <PulpoField key="wildlife_activity" id="wildlife_activity" type="string"
              title="Activity"
              translationID="wildlife.form.activity" isRequired
              handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.wildlife_activity}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
            />
          </div>
        </div>
        <PulpoField key="desc" id="description" type="string"
          translationID="wildlife.form.description" className={styles.fullInput}
          widget={{ type: 'textarea' }} title="Description" isRequired
          handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
          answer={info.description}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
        />
        <div className={styles.float}>
          <div className={styles.column}>
            <PulpoField key="killed_count" id="killed_count" type="number"
              translationID="wildlife.form.killed" isRequired
              title="Birds killed"
              handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.killed_count} className={styles.number}
              showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
            />
          </div>
        </div>
        <div className={styles.float}>
          <label htmlFor="bird_strike">
            <FormattedMessage
              id="wildlife.form.wildlife_condition"
              defaultMessage="Did the incident result in a bird strike?"
            />
          </label>
          <Button onClick={() => this.handleConditions('bird_strike', true)} translationID="wildlife.form.yes"
            defaultText="Yes" action={bird_strike === true ? 'secondary' : 'teritary'}
          />
          <Button onClick={() => this.handleConditions('bird_strike', false)} translationID="wildlife.form.no"
            defaultText="No" action={bird_strike === false ? 'secondary' : 'teritary'}
          />
          {bird_strike && (
            <a href="https://wildlife.faa.gov/add" target="_blank" rel="noopener noreferrer" className={styles.strike}>
              <FormattedMessage id="wildlife.form.bird_strike" defaultMessage="Please, fill up this from" />
            </a>
          )}
        </div>
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.title} id="wildlife.form.title4"
            defaultMessage="Dispersal Information"
          />
        </div>
        <div className={styles.float}>
          <label htmlFor="Dispersal">
            <FormattedMessage
              id="wildlife.from.condition"
              defaultMessage="were the birds dispersed?"
            />
          </label>
          <Button onClick={() => this.handleConditions('dispersal', true)} translationID="wildlife.form.yes"
            defaultText="Yes" action={dispersal === true ? 'secondary' : 'teritary'}
          />
          <Button onClick={() => this.handleConditions('dispersal', false)} translationID="wildlife.form.no"
            defaultText="No" action={dispersal === false ? 'secondary' : 'teritary'}
          />
        </div>
        {dispersal && (
          <>
            <PulpoField key="method_dispersion" id="method_dispersion" type="select"
              title="Method of dispersion"
              translationID="wildlife.form.method"
              handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
              answer={info.method_dispersion}
              values={[
                { key: 'Cracker Shell', value: formatMessage({ id: 'wildlife.form.cracker_shell' }) },
                { key: 'Banger', value: formatMessage({ id: 'wildlife.form.banger' }) },
                { key: 'Hazing', value: formatMessage({ id: 'wildlife.form.hazing' }) }
              ]}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
            />
            <div style={{ padding: '30px,0px,100px' }}>
              <PulpoField key="gun_count" id="gun_count" type="string"
                translationID="wildlife.form.gun"
                title="Gun Number" className={styles.string}
                handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
                answer={info.gun_count}
                showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
              />
            </div>
            <div style={{ width: 'auto', paddingLeft: '30px' }}>
              <PulpoField key="shells" id="shells" type="number"
                translationID="wildlife.form.shells"
                title="Shells used" className={styles.string}
                handleValueChange={(a, b) => handleAnswerChanged('info', a, b)}
                answer={info.shells}
                showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
              />
            </div>
          </>
        )}
        <div className={attachmentStyles.fullInput}>
          <FormattedMessageWithClass
            className={attachmentStyles.label} id="workorder.create.photos"
            defaultMessage="Photos"
          />
          <Dropzone onDrop={this.handleDrop} disableClick
            className={attachmentStyles.dropzone} accept="image/*"
          >
            {({ open }) => (
              <>
                <p className={attachmentStyles.browsetext}>
                  <FormattedMessage id="airport.logo.text1" defaultMessage="Drag an image here or" />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button" defaultMessage="browse" />
                  </button>
                  <FormattedMessage id="airport.logo.text2" defaultMessage="for an image to upload." />
                </p>
              </>
            )}
          </Dropzone>
          {info.attachments.length > 0 && (
          <div className={attachmentStyles.photos}>
            {info.attachments.map(e => (
              <div key={e.name} className={attachmentStyles.wrapper}>
                <Clickable onClick={() => this.handleRemoveImage(e)}>&times;</Clickable>
                <img src={e.preview} alt={e.name} />
              </div>
            ))}
          </div>
          )}
        </div>
        {browse && (
          <BrowseSpecies showIn={browse} onClose={this.handleBrowse}
            speciesList={speciesList} onSelect={this.handleSelectedWildlife} handleSpeciesSearch={handleSpeciesSearch}
            sortData={sortData}
          />
        )}
        {GridMapView && (
          <GridMap showIn={GridMapView} onClose={this.handleGridView} />
        )}
      </>
    );
  }
}

FixedFields.propTypes = {
  info: PropTypes.shape({}).isRequired,
  handleAnswerChanged: PropTypes.func.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  sortData: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool,
  userlist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchForUser: PropTypes.func.isRequired
};

FixedFields.defaultProps = {
  showFormErrors: false
};

export default injectIntl(FixedFields);
