import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import FieldWidget from '../../components/FieldWidget';
import { fetchDataSourceList, fetchDataSoureValues, clearDataSourceValues } from '../../../redux/actions';

import styles from '../steps.module.scss';

class NewField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
      error: '',
      dataSourceError: false
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
  }

  componentDidMount() {
    const { fetchDataSources } = this.props;
    fetchDataSources();
  }

  componentDidUpdate() {
    const { dataSourceValues, onCreateField } = this.props;
    const { value, dataSourceError } = this.state;
    if (dataSourceValues && dataSourceValues.length > 0) {
      onCreateField(value);
      this.closeModal();
    } else if (dataSourceValues && dataSourceValues.length === 0 && dataSourceError === false) {
      this.setState({ dataSourceError: true });
    }
  }

  handleFieldChange(v) {
    this.setState({ value: v, error: '' });
  }

  handleAddAction() {
    const { value } = this.state;
    const { onCreateField, allDataSources, actionDataSource } = this.props;
    if (!value.title || value.title === undefined || value.title.trim() === '') {
      this.setState({ error: 'inspections.step2.newField.errortitle' });
      return;
    }
    if (value.values && value.values.length === 0) {
      this.setState({ error: 'inspections.step2.newField.errorOptions' });
      return;
    }
    if ((value.type === 'dataSource' && value.required === true) || (
      value.type === 'dataSourceMultiselect' && value.required === true)) {
      const source = allDataSources.filter(e => (e.name === value.dataSourceID));
      if (source.length > 0) {
        actionDataSource(source[0].metadata.method, undefined, source[0].source_url);
      }
      return;
    }
    onCreateField(value);
    this.closeModal();
  }

  handleDeleteAction() {
    const { onCreateField, info } = this.props;
    onCreateField({ ...info, delete: true });
    this.closeModal();
  }

  closeModal() {
    const { onClose, actionClear } = this.props;
    this.setState({ error: '', value: {}, dataSourceError: false });
    onClose();
    actionClear();
  }

  render() {
    const { showIn, info, allDataSources, ins } = this.props;
    const { error, dataSourceError } = this.state;
    return (
      <Modal showIn={showIn} onClose={this.closeModal} width="930px">
        <div className={styles.newField}>
          <div className={styles.title}>
            <FormattedMessage tagName="h5" id="inspections.step2.newField.title"
              defaultMessage="New Field"
            />
          </div>
          { allDataSources && (
            <FieldWidget onChangeField={this.handleFieldChange}
              info={info} inspections={ins} error={error}
              allDataSources={allDataSources}
            />
          )}
          <div className={styles.footer}>
            <Button action="tertiary" translationID="inspections.new.cancelBtn" defaultText="Cancel"
              onClick={this.closeModal}
            />
            <div className={styles.errors}>
              {dataSourceError && (
              <p>
                <FormattedMessage id="inspections.new.dataSource_validation"
                  defaultMessage="The data source that you selected has no data. Try using a selection field instead if you would like to define a list of pre-defined values."
                />
              </p>
              )}
            </div>
            <div className={styles.modalBtnGroup}>
              {info && (
              <Button action="danger" translationID="inspections.new.delete" defaultText="Delete"
                onClick={this.handleDeleteAction}
              />
              )}
              <Button action="primary" translationID="inspections.new.addBtn" defaultText="Add"
                onClick={this.handleAddAction}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

NewField.propTypes = {
  onCreateField: PropTypes.func.isRequired,
  showIn: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  info: PropTypes.shape({})
};

NewField.defaultProps = {
  showIn: false,
  info: undefined
};

const mapStateToProps = state => ({
  allDataSources: state.inspection.allDataSources,
  dataSourceValues: state.inspection.dataSourceValues
});

const mapDispatchToProps = dispatch => ({
  actionDataSource: (methodName, data, sourceURL) => {
    dispatch(fetchDataSoureValues(methodName, data, sourceURL));
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  },
  actionClear: () => {
    dispatch(clearDataSourceValues());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewField);
