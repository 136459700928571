import React from 'react';
import { TextArea as LibTextArea } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

import { TextAreaProps as LibTextAreaProps } from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea';
import { Wrapper } from './TextArea.styles';

type TextAreaProps = {
  error?: boolean;
  name?: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  getScrollHeight?: (height: number) => void;
  onChange?: (
    event: React.SyntheticEvent<HTMLTextAreaElement>,
    data?: LibTextAreaProps
  ) => void;
  onHeightChange?: (value: any) => any;
  placeholder?: string;
  selectName?: string;
  type?: string;
  value?: string;
  size?: "mini" | "small" | "large" | "big" | "huge" | "massive";
  required?: boolean;
  disabled?: boolean;
  bordered?: boolean;
  height?: number;
  minHeight?: number;
  rows?: number;
  minRows?: number;
  maxRows?: number;
};

const TextArea = React.memo<TextAreaProps>(({
  error,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  type,
  selectName,
  required,
  disabled,
  height,
  minHeight,
  bordered = true,
  onHeightChange,
  minRows,
  maxRows
}) => {

  return (
    <Wrapper bordered={bordered} height={height} minHeight={minHeight}>
      {(typeof onHeightChange === 'function' && (
        <TextareaAutosize
          name={name}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={onChange}
          required={required}
          disabled={disabled}
          minRows={minRows}
          maxRows={maxRows}
          onHeightChange={onHeightChange}
        />
      )) || (
        <LibTextArea
          error={error}
          name={name}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={onChange}
          selectName={selectName}
          required={required}
          disabled={disabled}
          minRows={minRows}
          maxRows={maxRows}
          onHeightChange={onHeightChange}
        />
      )}
    </Wrapper>
  );
});

export default TextArea;
export { TextArea };
