import { useMutation } from '../../../graphql/hooks';
import { UnLikePost } from './hooks.types';
import UN_LIKE_POST from '../mutations/unLikeChannelPost';

export const useUnLikePost = <L>() => {
  const { error, mutate, saving } = useMutation<UnLikePost, L>(UN_LIKE_POST);

  return {
    error,
    unLikePost: mutate,
    saving
  };
};
