import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import {
  FETCH_WORKORDER_LIST,
  FETCH_WORKORDER_SCHEMA,
  CREATE_WORKORDER,
  FETCH_WORKORDER,
  CLEAR_WORKORDER,
  CREATE_MAINTENANCE,
  CREATE_OPERATIONS,
  UPDATE_SCHEMAS,
  SAVE_ASSIGNMENT,
  FETCH_NOTAMS,
  PRINT_PDF,
  CLEAR_WORKORDER_DETAIL,
  EDIT_WORKORDER,
  FETCH_WORKORDER_LIST_SUMMARY
} from './types';

const INITIAL_STATE = {
  workorders: [],
  workordersSummary: [],
  detail: {},
  schemas: {},
  action: {},
  createAction: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  editAction: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  printPdfAction: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  errors: [],
  detailAction: {},
  maintenanceAction: {},
  assignmentAction: {},
  operationsAction: {},
  updateSchemaAction: {},
  notams: [],
  work_order_action: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  workorderlist: {
    results: []
  },
  workorder_list_summary_action: {},
  toastId: null
};

const actionForState = (action, state) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_WORKORDER_LIST.pending:
      return {
        ...state,
        work_order_action: actionForState(state.action, 'pending')
      };
    case FETCH_WORKORDER_LIST.success:
      return {
        ...state,
        workorders: action.payload.results,
        work_order_action: actionForState(state.action, 'success'),
        workorderlist: action.payload
      };
    case FETCH_WORKORDER_LIST.error:
      return {
        ...state,
        work_order_action: actionForState(state.action, 'failed')
      };

    case FETCH_WORKORDER_LIST_SUMMARY.success:
      return {
        ...state,
        workordersSummary: action.payload
      };

    case FETCH_WORKORDER_LIST_SUMMARY.pending:
      return {
        ...state,
        workorder_list_summary_action: actionForState(state.action, 'pending')
      };

    case FETCH_WORKORDER_LIST_SUMMARY.failure:
      return {
        ...state,
        workorder_list_summary_action: actionForState(state.action, 'failed')
      };

    /* ******************************** */
    /*         WORKORDER SCHEMA         */
    /* ******************************** */
    case FETCH_WORKORDER_SCHEMA.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_WORKORDER_SCHEMA.success:
      return {
        ...state,
        schemas: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_WORKORDER_SCHEMA.error:
      return {
        ...state,
        action: actionForState(state.action, 'failed')
      };
    case UPDATE_SCHEMAS.pending:
      state.toastId = toast(<FormattedMessage id="Work_order.Settings.Pending.message" defaultMessage="WorkOrder Settings in Pending" />, { type: toast.TYPE.INFO });
      return {
        ...state,
        updateSchemaAction: actionForState(state.action, 'pending')
      };
    case UPDATE_SCHEMAS.success: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="Work_order.Settings.Success.message" defaultMessage="WorkOrders Settings changed." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      return {
        ...state,
        schema: action.payload,
        updateSchemaAction: actionForState(state.action, 'success')
      };
    }
    case UPDATE_SCHEMAS.error:
      toast.update(state.toastId, {
        render: <FormattedMessage id="Work_order.Settings.Error.message" defaultMessage="Error occured while changing WorkOrder settings." />,
        type: toast.TYPE.ERROR,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        updateSchemaAction: actionForState(state.action, 'failed')
      };
    /* ******************************** */
    /*         CREATE WORKORDER         */
    /* ******************************** */
    case CREATE_WORKORDER.pending:
      return {
        ...state,
        createAction: actionForState(state.action, 'pending')
      };
    case CREATE_WORKORDER.success:
      return {
        ...state,
        createdWorkorder: action.payload,
        createAction: actionForState(state.action, 'success')
      };
    case CREATE_WORKORDER.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        createAction: actionForState(state.action, 'failed')
      };
    }

    /* ******************************** */
    /*         EDIT WORKORDER           */
    /* ******************************** */
    case EDIT_WORKORDER.pending:
      return {
        ...state,
        editAction: actionForState(state.action, 'pending')
      };
    case EDIT_WORKORDER.success:
      return {
        ...state,
        createdWorkorder: action.payload,
        editAction: actionForState(state.action, 'success')
      };
    case EDIT_WORKORDER.error: {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        editAction: actionForState(state.action, 'failed')
      };
    }
    /* ******************************** */
    /*         WORKORDER DETAIL         */
    /* ******************************** */
    case FETCH_WORKORDER.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_WORKORDER.success:
      return {
        ...state,
        detail: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_WORKORDER.error:
      return {
        ...state,
        action: actionForState(state.action, 'failed')
      };
    /* ******************************** */
    /*        CREATE MAINTENANCE        */
    /* ******************************** */
    case CREATE_MAINTENANCE.pending:
      return {
        ...state,
        maintenanceAction: actionForState(state.action, 'pending')
      };
    case CREATE_MAINTENANCE.success:
      return {
        ...state,
        maintenanceAction: actionForState(state.action, 'success')
      };
    case CREATE_MAINTENANCE.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false
        },
        maintenanceAction: actionForState(state.action, 'failed')
      };
    case SAVE_ASSIGNMENT.pending:
      return {
        ...state,
        assignmentAction: actionForState(state.action, 'pending')
      };
    case SAVE_ASSIGNMENT.success:
      return {
        ...state,
        assignmentAction: actionForState(state.action, 'success')
      };
    case SAVE_ASSIGNMENT.error:
      return {
        ...state,
        assignmentAction: actionForState(state.action, 'failed')
      };

    /* ******************************** */
    /*        CREATE OPERATIONS        */
    /* ******************************** */
    case CREATE_OPERATIONS.pending:
      return {
        ...state,
        operationsAction: actionForState(state.action, 'pending')
      };
    case CREATE_OPERATIONS.success:
      return {
        ...state,
        operationsAction: actionForState(state.action, 'success')
      };
    case CREATE_OPERATIONS.error:
      return {
        ...state,
        operationsAction: actionForState(state.action, 'failed')
      };
    case CLEAR_WORKORDER:
      return {
        ...state,
        createAction: {},
        action: {},
        operationsAction: {},
        maintenanceAction: {},
        assignmentAction: {},
        detail: {},
        updateSchemaAction: {},
        editAction: {},
        schemas: {}
      };
    /* ******************************** */
    /*         FETCH NOTAMS         */
    /* ******************************** */
    case FETCH_NOTAMS.pending:
      return {
        ...state,
        action: {
          ...state.action,
          success: true,
          loading: false,
          message: 'pending'
        }
      };
    case FETCH_NOTAMS.success:
      return {
        ...state,
        notams: action.payload,
        action: {
          ...state.action,
          success: true,
          loading: false,
          message: 'success'
        }
      };
    case FETCH_NOTAMS.error:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          loading: false,
          message: 'failed'
        }
      };

      /** CLEAR WORKORDER DETAIL */

    case CLEAR_WORKORDER_DETAIL: {
      return {
        ...state,
        detail: {}
      };
    }

    /** PRINT PDF */

    case PRINT_PDF.pending:
      return {
        ...state,
        printPdfAction: actionForState(state.action, 'pending')
      };

    case PRINT_PDF.success: {
      const link = document.createElement('a');
      link.href = action.payload;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        printPdfAction: actionForState(state.action, 'success')
      };
    }

    case PRINT_PDF.error:
      return {
        ...state,
        printPdfAction: actionForState(state.action, 'error')
      };


    default:
      return state;
  }
}
