import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Clickable from '../../../../../../../components/clickable/Clickable';
import Seperator from '../../../../../../../components/separator/Separator';
import styles from '../../steps.module.scss';

const LeaseDetail = ({
  lease,
  handleEdit,
  allProperties
}) => {
  const property = lease && lease.lease_type && allProperties.filter(e => (e.id.toString() === lease.lease_type.toString()));
  return (
    <>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step5.lease_info" defaultMessage="Lease Information"
              />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleEdit(2)}>
                <FormattedMessage id="inspections.step0.edit" defaultMessage="Edit" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      {property && property.length > 0 && (
          <>
            <div className={styles.endingBorder} />
            <div className={styles.requestInfo}>
              <div className={styles.infoTable}>
                <div className={styles.infoRow}>
                  <div className={styles.column}>
                    <h2>{property[0].name}</h2>
                  </div>
                  <Seperator />
                  <div className={styles.column}>
                    <p>
                      <FormattedMessage id="lease.property.space" defaultMessage="Space Size" />
                    </p>
                    {property[0].space_size || '-'}
                  </div>
                  <Seperator />
                  <div className={styles.column}>
                    <p>
                      <FormattedMessage id="lease.property.floor_plan" defaultMessage="Floor Plan" />
                    </p>
                    {property[0].plan_attachment.length > 0 ? (
                      <a href={property[0].plan_attachment[0].url} target="_blank" rel="noopener noreferrer">
                        {property[0].plan_attachment[0].file_name}
                      </a>
                    ): '-' }
                  </div>
                </div>
              </div>
            </div>
          </>
      )}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.annual_rent" defaultMessage="Annual Rent" />
            </span>
            <span className={styles.rent}>
              <NumberFormat displayType="text" value={lease.annual_rent}
                thousandSeparator prefix="$" suffix=".00"
              />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.start_date" defaultMessage="Starting Date" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.start_date && moment(lease.start_date).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.annual_adjustment" defaultMessage="Annual Adjustment Date" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.adjustment_date && moment(lease.adjustment_date).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.monthly_rent" defaultMessage="Monthly Rent" />
            </span>
            <span className={styles.rent}>
              <NumberFormat displayType="text" value={lease.annual_rent}
                thousandSeparator prefix="$" suffix=".00"
              />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.end_date" defaultMessage="Ending Date" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.end_date && moment(lease.end_date).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step2.renewal" defaultMessage="Renewal Options" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.renewal_options}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.lease_terms" defaultMessage="Lease Terms" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.lease_term}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.additional_remarks" defaultMessage="Additional Remarks" />
            </span>
            <span className={styles.rowContent}>
              {lease && lease.description}
            </span>
          </div>
        </div>
      </div>
      {lease && lease.attachments && lease.attachments.length > 0 && (
      <div className={styles.requestInfoField}>
        <span className={styles.title}>
          <FormattedMessage id="lease.tenants.steps.step3.attachment" defaultMessage="Attachments" />
        </span>
        { lease.attachments.length > 1 ? (
          <div className={styles.photos}>
            {lease.attachments.map(e => (
              <Clickable
                key={e.id}
              >
                {(e.type).includes('image') ? (
                  <img src={e.preview} alt={e.name} />
                ) : <p>{e.name}</p>
                }
              </Clickable>
            ))}
          </div>
        )
          :(
            <div className={styles.photo}>
              {lease.attachments.map(e => (
                <Clickable
                  key={e.id}
                >
                  {(e.type).includes('image') ? (
                    <img src={e.preview} alt={e.name} />
                  ) : <p>{e.name}</p>
                  }
                </Clickable>
              ))}
            </div>
          )}
      </div>
      )}
  </>
  );
};

export default LeaseDetail;
