import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

// constants color schema and height
const HEIGHT = 134;
const COLORS = ['#ffffff', '#a6e50f'];
/**
 * Time Clock Chart Component
 * @param  {data} props                     the data of chart
 * @param  {startAngle} props               the start angle of chart
 * @param  {endAngle} props                 the end angle of chart
 */
export default ({ data, startAngle, endAngle }) =>
  <ResponsiveContainer width="100%" height={HEIGHT}>
    <PieChart height={HEIGHT}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={HEIGHT / 2}
        dataKey="value"
        startAngle={startAngle}
        endAngle={endAngle}
        isAnimationActive={false}
      >
      {
        data.map((entry, index) => 
          <Cell key={`cell-${index}`} 
                strokeDasharray="2 6" 
                stroke='#000'             
                fill={COLORS[index % COLORS.length]} 
        />)
      }
      </Pie>
    </PieChart>
  </ResponsiveContainer>;
