import React from 'react';
import { MessageOptions } from '../../components/MessageOptions';
import { MENU_IDS } from '../../components/MessageOptions/assets';
import { ChatTypes } from '../../enums';
import { commentsActions, useChanelState } from '../../state/channelsState';
import { postActions } from '../../state/channelsState/actions';
import {
  CommentMessage,
  DeleteMessagesTypes,
  PostMessageType,
  ToggleLikesFunc
} from '../../types';

type ChannelActionsProps = {
  postId: string;
  channelId: string;
  isAuthor: boolean;
  type: ChatTypes;
  deleteMessage: DeleteMessagesTypes;
  toggleLikes?: ToggleLikesFunc;
  messageData: PostMessageType | CommentMessage;
};

const ChannelActions: React.FC<ChannelActionsProps> = ({
  postId,
  isAuthor,
  deleteMessage,
  messageData,
  channelId,
  type,
  toggleLikes = () => {}
}) => {
  const {
    state: {
      comments: { messageData: openComment }
    },
    dispatch
  } = useChanelState();

  const onClick = (key: MENU_IDS) => {
    switch (key) {
      case MENU_IDS.comment: {
        commentsActions.show(dispatch, {
          postId,
          channelId,
          messageData,
          visible: true
        });
        break;
      }
      case MENU_IDS.edit: {
        if ('commentId' in messageData) {
          const { commentId } = messageData;

          commentsActions.setEditedMessage(dispatch, {
            editedCommentID: commentId
          });
          return;
        }
        postActions.setEditedMessage(dispatch, {
          editedPostID: postId
        });

        break;
      }
      // case MENU_IDS.insertSmile: {
      //   const { isLiked } = messageData;
      //   toggleLikes({ postId, channelId }, isLiked);

      //   break;
      // }
      case MENU_IDS.delete: {
        if ('commentId' in messageData && type === ChatTypes.comment) {
          const { commentId } = messageData;

          deleteMessage[type]({ variables: { commentId, postId } });
          commentsActions.setDeletedCommentID(dispatch, {
            deletedCommentID: commentId
          });
          return;
        }

        if (openComment?.postId === postId) {
          commentsActions.hide(dispatch);
        }
        postActions.setDeletedPost(dispatch, { deletedPostID: postId });
        deleteMessage[type]({ variables: { postId } });
        break;
      }
      default:
    }
  };
  return (
    <MessageOptions
      onClick={onClick}
      isAuthor={isAuthor}
      hideIcons={type === ChatTypes.operations ? [] : [MENU_IDS.comment]}
    />
  );
};

export default ChannelActions;
export { ChannelActions };
