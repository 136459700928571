import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { ResponsivePie } from '@nivo/pie';
import { Rnd } from 'react-rnd';
import SectionHeader from '../../../components/sectionHeader/SectionHeader';
import icon from '../../../icons/Dashboard.png';
import styles from './dashboard.module.scss';
import { clearActionResult, fetchLogs } from '../redux/action';
import Toolbar from './Toolbar';
import { getDatesFromRange } from '../../../utils/helpers';
import { fetchSafetySelfInspection } from '../../inspections/redux/actions';
import Assetable from './AssetTable';
import SurfaceTable from './SurfaceTable';
import OpsLogTrends from './opsLogTrends';
import Forbidden from '../../Forbidden';


class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: '500px',
      width: '98%',
      selectionRange: {
      },
      range: localStorage.getItem('opsrange')
    };
  }

  async componentDidMount() {
    const {
      actionListSummary,
      actionGetSelfInpection
    } = this.props;
    await actionListSummary();
    await actionGetSelfInpection();
    const storedRange = localStorage.getItem('opsrange');
    let s; let f;

    if (storedRange) {
      [s, f] = getDatesFromRange(storedRange);
      s = s.toISOString();
      f = f.toISOString();
    } else {
      s = localStorage.getItem('opsstart');
      f = localStorage.getItem('opsend');
      if (s && f) {
        s = new Date(s);
        f = new Date(f);
      } else {
        s = new Date();
        s.setDate(s.getDate() - 30);
        f = new Date();
        localStorage.setItem('opsrange', 'Last 30 days');
      }
      s.setHours(0, 0, 0, 0);
      f.setHours(23, 59, 59, 99);
      s = s.toISOString();
      f = f.toISOString();
    }
    this.setState({ selectionRange: { startDate: s, endDate: f } });
    await actionListSummary(undefined, undefined, undefined, s, f, true);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.piedata && props.workorderslistSummary && props.workorderslistSummary.length && props.selfInspection.length && props.selfInspection) {
      const piedata = [];
      Object.values(props.selfInspection).forEach((e, index) => {
        const size = Object.values(props.workorderslistSummary).filter(x => (e.title === x.category));
        if (size.length > 0) {
          piedata.push({
            id: e.title,
            label: e.title,
            value: size.length,
            color: ['hsl(1, 70%,53%)',
              'hsl(13,11%, 55%)',
              'hsl(24, 26%, 93%)',
              'hsl(35, 33%, 84%)',
              'hsl(46, 48%, 75%)',
              'hsl(57, 56%, 66%)',
              'hsl(61, 61%, 59%)',
              'hsl(78, 79%, 42%)',
              'hsl(162, 26%, 69%)',
              'hsl(172, 76%, 39%)',
              'hsl(112, 96%, 19%)',
              'hsl(142, 16%, 59%)',
              'hsl(95, 99%, 20%)',
              'hsl(122, 105%, 11%)'
            ][index]
          });
        }
      });
      return { ...state, workorderslistSummary: props.workorderslistSummary, selfInspection: props.selfInspection, piedata };
    }
    return state;
  }

  componentDidUpdate(prevprops) {
    const { piedata } = this.state;
    const { workorderslistSummary, selfInspection } = this.props;
    const data = [];
    if (prevprops.workorderslistSummary !== workorderslistSummary && piedata) {
      Object.values(selfInspection).forEach((e, index) => {
        const size = Object.values(workorderslistSummary).filter(x => (e.title === x.category));
        if (size.length > 0) {
          data.push({
            id: e.title,
            label: e.title,
            value: size.length,
            color: ['hsl(1, 70%,53%)',
              'hsl(13,11%, 55%)',
              'hsl(24, 26%, 93%)',
              'hsl(35, 33%, 84%)',
              'hsl(46, 48%, 75%)',
              'hsl(57, 56%, 66%)',
              'hsl(61, 61%, 59%)',
              'hsl(78, 79%, 42%)',
              'hsl(162, 26%, 69%)',
              'hsl(172, 76%, 39%)',
              'hsl(112, 96%, 19%)',
              'hsl(142, 16%, 59%)',
              'hsl(95, 99%, 20%)',
              'hsl(122, 105%, 11%)'
            ][index]
          });
        }
      });
      this.setState({ piedata: data });
    }
  }

  handleDateChange = async (sd, fd, range) => {
    const { actionListSummary } = this.props;
    localStorage.setItem('opsstart', sd);
    localStorage.setItem('opsend', fd);
    if (range) {
      localStorage.setItem('opsrange', range);
    } else {
      localStorage.removeItem('opsrange');
    }
    let s = new Date(sd);
    let f = new Date(fd);
    s.setHours(0, 0, 0, 0);
    f.setHours(23, 59, 59, 999);
    s = s.toISOString();
    f = f.toISOString();
    localStorage.setItem('opsstart', s);
    localStorage.setItem('opsend', f);
    const date = `[${s}(*^)${f}]`;
    // this.setState({ filterDates: date });
    localStorage.setItem('filterdate', date);
    await actionListSummary(undefined, undefined, undefined, s, f, true);
  }


  render() {
    const {
      intl,
      user,
      workorderAction
    } = this.props;
    const {
      range, piedata,
      selectionRange,
      height, width } = this.state;
    return (
      <div className={styles.bg}>
        {user.id !== '' && user.airport_permissions.modules.filter(type => type.key==='operations_dashboard').length > 0
          && user.airport_permissions.modules.filter(type => type.key==='operations_dashboard')[0].enabled === 'true'
          ? <>
            <div>
              <SectionHeader icon={icon} translationID="shortcuts.operations_dashboard" defaultTitle=" Operations Dashboard" />
            </div>
            <div className={`container ${styles.container}`}>
              <Rnd
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  marginTop: '40xp',
                  marginLeft: '10px',
                  width: width || '700px',
                  backgroundColor: 'white',
                  height: height&& height!=='auto'?height : '500px'
                }}
                size={{ width, height }}
                dragAxis="none"
                onResizeStop={(e, direction, ref, delta, position) => {
                  this.setState({
                    width: ref.style.width,
                    height: ref.style.height&& ref.style.height!=='auto'? ref.style.height:height||'700px',
                    ...position
                  });
                }}
              >
                <div className={styles.firstRow} style={{
                  width: width || '700px',
                  height: height&& height!=='auto'?height : '700px'
                }}
                >
                  <div style={{ width: '100%' }}>
                    {selectionRange && selectionRange.startDate && (
                    <Toolbar
                      range={range}
                      filter
                      formatedMessage="section.toolbar.w"
                      dates={[moment(selectionRange.startDate), moment(selectionRange.endDate)]}
                      intl={intl}
                      Message="Work Orders by Category"
                      onDateChange={this.handleDateChange}
                    />)
                  }
                    {workorderAction.success && piedata && piedata.length<1 ? (
                      <h1>There are no work orders to show in the time period that you selected. Please choose another date range.</h1>
                    ):(
                      <ResponsivePie
                        data={piedata || []}
                        margin={{ top: 80, right: 100, bottom: 60, left: 10 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        radialLabelsTextColor="#8C9EA7"
                        enableRadialLabels
                        colors={c => c.color}
                        theme={{
                          legends: {
                            text: {
                              fontSize: 14
                            }
                          }
                        }}
                        legends={[
                          {
                            anchor: 'top-right',
                            direction: 'column',
                            top: '0',
                            translateY: -10,
                            translateX: -120,
                            itemWidth: 25,
                            itemHeight: 22,
                            itemTextColor: '#8C9EA7',
                            symbolSize: 10,
                            symbolShape: 'circle'
                          }
                        ]}
                      />

                    )}
                  </div>
                </div>
              </Rnd>
              <div style={{ display: 'flex',
                flexDirection: 'row'
              }}
              >
                <div className={`container2 ${styles.container2}`}>
                  <div className={styles.firstRow}>
                    <Assetable />
                  </div>
                </div>
                <div className={`container3 ${styles.container3}`}>
                  <div className={styles.firstRow}>
                    <SurfaceTable />
                  </div>
                </div>
              </div>
              <div style={{ border: '1px solid #e9eff4', backgroundColor: '#FFFFFF' }}>
                <Toolbar
                  fiter={false}
                  formatedMessage="section.toolbar.O"
                  Message="Trends Of Operations Logs"
                />
                <div className={styles.firstRow} style={{ width: '100%' }}>

                  <OpsLogTrends />
                </div>
              </div>
            </div>
            <div />
          </>
          : <Forbidden />
        }
      </div>
    );
  }
}

DashBoard.propTypes = {
  history: PropTypes.shape({}).isRequired,
  selfInspection: PropTypes.shape({}).isRequired,
  actionGetSelfInpection: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
  user: state.auth.profile,
  workorderslistSummary: state.operations_dashboard.loglist,
  selfInspection: state.inspection.selfInspection,
  workorderAction: state.operations_dashboard.WorkorderAction
});

const mapDispatchToProps = dispatch => ({
  actionFetch: (start, end) => {
    dispatch(start, end);
  },
  actionClear: () => {
    dispatch(clearActionResult());
  },
  actionListSummary: (query, action, result1, s, f) => {
    dispatch(fetchLogs(query, action, result1, s, f));
  },
  actionGetSelfInpection: (id) => {
    dispatch(fetchSafetySelfInspection(id));
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DashBoard));
