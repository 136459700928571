import { ApolloError } from 'apollo-client';
import { ApolloQueryResult } from 'apollo-client/core/types';
import { useQuery } from '../../../graphql/hooks';
import {
  ListChannelUsers,
  ListChannelUsersType,
  ListChannelUsersVar
} from '../../types';
import LIST_CHANNEL_USERS from '../queries/getListChannelUsers';

type UseGetListChannelUsers = {
  (channelId: string): {
    data: ListChannelUsersType[];
    error: ApolloError;
    loading: boolean;
    refetch: (
      variables?: ListChannelUsersVar
    ) => Promise<ApolloQueryResult<ListChannelUsers>>;
  };
};

export const useGetListChannelUsers: UseGetListChannelUsers = (channelId) => {
  const { data, error, loading, refetch } = useQuery<
    ListChannelUsers,
    ListChannelUsersVar
  >(LIST_CHANNEL_USERS, {
    variables: {
      channelId,
      limit: 100,
      nextToken: null
    }
  });

  return {
    data: (data && data.listChannelUsers.items) || [],
    error,
    loading,
    refetch
  };
};
