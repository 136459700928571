import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';

import Forbidden from '../../Forbidden';
import TenantsDetail from './Detail/TenantsDetail';
import TenantsBuilder from './Builder/TenantsBuilder';
import CreateTenant from './Create/TenantCreation';
import Tenants from './List/Tenants';

const TenantLayout = ({ match }) => (
  <Permissions allowed={['view_tenants']} fallbackElement={<Forbidden />}>
    <Switch>
      <Route exact path={`${match.url}`} component={Tenants} />
      <Route exact path={`${match.url}/start`} component={CreateTenant} />
      <Route exact path={`${match.url}/:id/detail`} component={TenantsDetail} />
      <Route exact path={`${match.url}/settings`} component={TenantsBuilder} />
    </Switch>
  </Permissions>
);

TenantLayout.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default TenantLayout;
