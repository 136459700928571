export const hostName = window.location.hostname.split(':')[0];
export const isLocal = hostName.indexOf('local') === 0 || process.env.REACT_APP_BACKEND_HOST !== undefined;

export const getBackendBaseUrl = () => {
  return (
    localStorage.getItem("backendBaseUrl") ||
    (isLocal
      ? process.env.REACT_APP_BACKEND_HOST
      : `https://backend.${window.location.hostname}/api`)
  );
};
