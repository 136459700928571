import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../../../components/button';
import styles from '../../../opsSettings/Map/map.module.scss';

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    };
    this.toggleView = this.toggleView.bind(this);
  }


  toggleView() {
    const { changeView } = this.props;
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }));
    const { editMode } = this.state;
    changeView(editMode);
  }

  render() {
    const { editMode } = this.state;
    const { propertyCounter, checkPermission } = this.props;
    const create_permission = checkPermission('add_leaseproperties');
    if (editMode) {
      return (
        <div className={styles.toolbar}>
          <button type="button" onClick={this.toggleView} className={styles.button}>
            <FormattedMessage id="lease.property.back" defaultMessage="Back to Properties view" />
          </button>
        </div>
      );
    }
    return (
      <div className={styles.toolbar}>
        <div className={styles.selector}>
          <div className={styles.counter}>
            <span>{`${propertyCounter} `}</span>
            <FormattedMessage id="shortcuts.lease.properties" defaultMessage="Properties" />
          </div>
        </div>
        {create_permission === true && (
        <Button translationID="lease.property.create" className={styles.button} defaultText="Create Property" onClick={this.toggleView} />
        )}
      </div>
    );
  }
}

Toolbar.propTypes = {
  propertyCounter: PropTypes.number,
  changeView: PropTypes.func.isRequired
};

Toolbar.defaultProps = {
  propertyCounter: 0
};

export default Toolbar;
