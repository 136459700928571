import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import SectionHeader from '../../../components/sectionHeader';
import HeaderBack from '../../../components/headerBack';
import Panel from '../../../components/panel';
import Clickable from '../../../components/clickable/Clickable';
import Loading from '../../../components/loading/loading';
import PhotoCarousel from '../../../components/photoCarousel';
import Modal from '../../../components/modal';
import Map from '../DashBoard/components/Map';
import { WILD_LIFE_LOG_HOME } from '../../../constants/RouterConstants';
import styles from '../../workorders/Detail/workOrderDetail.module.scss';
import icon from '../../../icons/wildlife.png';
import { getLog, clearActionResult } from '../redux/actions';

class WildlifeLogDetail extends Component {
  state = {
    loadingStatus: false,
    modal: false
  }

  componentDidMount() {
    const { actionFetch, match: { params: { id } } } = this.props;
    actionFetch(id);
    this.setState({ loadingStatus: true });
  }

  componentWillUnmount() {
    const { ClearAction } = this.props;
    ClearAction();
  }

  openPhotoModal = (idx) => {
    this.setState({ modal: true, initialSlide: idx });
  }

  render() {
    const { log, user } = this.props;
    const { loadingStatus, modal, initialSlide } = this.state;
    return (
    <>
      <SectionHeader icon={icon} translationID="shortcuts.wildlife"
        defaultTitle="Wildlife Log"
      />
      {!log && <Loading loadingStatus={loadingStatus} />}
      <HeaderBack
        translationID="workorder.settings.back"
        translationDefault="Back"
        backRoute={WILD_LIFE_LOG_HOME}
      />
      <div className="container">
        <Panel
          containerClasses={styles.mainPanel}
          title="willdife.detail"
          defaultTitle="Wildlife Log Details"
        >
          {log && (
              <>
                <div className={styles.panelContent}>
                  <div className={styles.sectionHeader}>
                    <FormattedMessage id="wildlife.form.title2" defaultMessage="General Information" />
                  </div>
                  <div className={styles.requestInfo}>
                    <div className={styles.infoTable}>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="workorder.detail.request.logged_by" defaultMessage="Logged by" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.logged_by.fullname}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.date" defaultMessage="Report date" />
                        </span>
                        <span className={styles.rowContent}>
                          {moment(log.incident_date).format('MM/DD/YYYY hh:mm A')}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="widlife.form.grid" defaultMessage="Location Grid" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.grid}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.weather" defaultMessage="Weather Conditions" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.weather_conditions && log.weather_conditions.summary}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.temperature" defaultMessage="Temperature" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.weather_conditions && log.weather_conditions.temperature}
                        </span>
                      </div>
                    </div>
                    <div className={styles.infoMap}>
                      {user.airport.location.coordinates && (
                      <Map defaultLocation={user.airport.location.coordinates} loglist={[log]} WildlifeLogDetail />
                      )}
                    </div>
                  </div>
                  <div className={styles.requestInfoField}>
                    <span className={styles.title}>
                      <FormattedMessage id="wildlife.form.description" defaultMessage="Description" />
                    </span>
                    <div className={styles.content}>
                      {log.description}
                    </div>
                  </div>
                  {log.attachments.length > 0 && (
                  <div className={styles.requestInfoField}>
                    <span className={styles.title}>
                      <FormattedMessage id="workorder.detail.request.photos" defaultMessage="Photos" />
                    </span>
                    {log.attachments.length > 1 ? (
                      <div className={styles.photos}>
                        {log.attachments.map((e, idx) => (
                          <Clickable
                            key={e.id}
                            onClick={() => this.openPhotoModal(idx)}
                          >
                            <img src={e.url} alt={e.id} />
                          </Clickable>
                        ))}
                      </div>
                    )
                      :(
                        <div className={styles.photo}>
                          {log.attachments.map((e, idx) => (
                            <Clickable
                              key={e.id}
                              onClick={() => this.openPhotoModal(idx)}
                            >
                              <img src={e.url} alt={e.id} />
                            </Clickable>
                          ))}
                        </div>
                      )}
                  </div>
                  )}
                  <div className={styles.sectionHeader}>
                    <FormattedMessage id="wildlife.form.title3" defaultMessage="Wildlife Information" />
                  </div>
                  <div className={styles.requestInfo}>
                    <div className={styles.infoTable}>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.type" defaultMessage="Wildlife Type" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.wildlife_type}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.attachments" defaultMessage="Associated attractant" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.associated_attractant || '-'}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.spotted" defaultMessage="Number of Spotted" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.spotted_count}
                        </span>
                      </div>
                    </div>
                    <div className={styles.infoTable}>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.species" defaultMessage="Wildlife Species" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.wildlife_species}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.activity" defaultMessage="Activity" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.wildlife_activity}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.killed" defaultMessage="Birds killed" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.killed_count}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.requestInfoField}>
                    <span className={styles.title}>
                      <FormattedMessage id="wildlife.form.wildlife_condition" defaultMessage="Did this incident result in a bird strike?" />
                    </span>
                    <div className={styles.content}>
                      {log.bird_strike ? 'True': 'False'}
                    </div>
                  </div>
                  <div className={styles.requestInfoField}>
                    <span className={styles.title}>
                      <FormattedMessage id="wildlife.form.image" defaultMessage="Wildlife Image" />
                    </span>
                    <div className={styles.photos}>
                      <img src={log.wildlife_image} alt="" />
                    </div>
                  </div>
                  <div className={styles.sectionHeader}>
                    <FormattedMessage id="wildlife.form.title4" defaultMessage="Dispersal Information" />
                  </div>
                  <div className={styles.requestInfo}>
                    <div className={styles.infoTable}>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.from.condition" defaultMessage="Were the birds dispersed?" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.dispersal ? 'True': 'False'}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.method" defaultMessage="Method of dispersion" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.method_dispersion}
                        </span>
                      </div>
                    </div>
                    <div className={styles.infoTable}>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.gun" defaultMessage="Gun Number" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.gun_count}
                        </span>
                      </div>
                      <div className={styles.infoRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="wildlife.form.shells" defaultMessage="Shells used" />
                        </span>
                        <span className={styles.rowContent}>
                          {log.shells}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
        </>
          )}
        </Panel>
        {log && log.attachments && log.attachments.length > 0 && (
          <Modal
            showIn={modal}
            onClose={() => this.setState({ modal: false })}
            contentStyles={{
              overflow: 'visible',
              background: '#E6EAEE',
              padding: '20px 40px'
            }}
          >
            <div>
              {log.attachments.length > 1
                ? <PhotoCarousel photos={log.attachments} initialSlide={initialSlide} />
                :(
                  <div>
                    <img src={log.attachments[0].url} className={styles.workimg} alt="" />
                  </div>
                )
              }
            </div>
          </Modal>
        )}
      </div>
    </>
    );
  }
}

WildlifeLogDetail.propTypes = {
  match: PropTypes.shape({}).isRequired
};

// WildlifeLogDetail.defaultProps = {
//   showIn: false
// };

const mapStateToProps = state => ({
  log: state.wildlife_log.log,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionFetch: (id) => {
    dispatch(getLog(id));
  },
  ClearAction: () => {
    dispatch(clearActionResult());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WildlifeLogDetail);
