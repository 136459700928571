/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { TENANTS } from '../../../../../constants/RouterConstants';
import sortIcon from '../../../../../icons/inspection-icons/sort.svg';
import sortActiveIcon from '../../../../../icons/inspection-icons/sort-active.svg';
import styles from '../../../../../styles/general.module.scss';

const Table = ({ info }) => {
  const [tableData, setTableData] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState('');
  const defaultOrderType = {
    name: 'asc',
    business_type: 'asc',
    'lease.start_date': 'asc',
    category: 'asc',
    'lease.end_date': 'asc',
    'lease.days': 'asc'
  };
  const [orderType, setOrderType] =useState(defaultOrderType);

  useEffect(() => {
    const data = [];
    info.forEach((e) => {
      const object = {};
      Object.keys(e).forEach((o) => {
        if (o === 'lease') {
          let days = 0;
          if (e.lease && e.lease.start_date && e.lease.end_date) {
            const second=1000;
            const minute=second*60;
            const hour=minute*60;
            const day=hour*24;
            const start_date = new Date(e.lease.start_date);
            const end_date = new Date(e.lease.end_date);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const expire = end_date - today;
            if (expire >= 0) {
              const diff = end_date - start_date;
              days = Math.floor(diff / day);
            } else {
              days = Math.floor(expire / day);
            }
          }
          object[o] = { start_date: e.lease.start_date, end_date: e.lease.end_date, days };
        } else {
          object[o] = e[o];
        }
      });
      data.push(object);
    });
    setTableData(data);
  }, [info]);

  const handleSortColumn = (field, order) => {
    const sortData = _.orderBy(tableData, [field], [order]);
    setOrderType({ ...orderType, [field]: order === 'asc' ? 'desc':'asc' });
    setActiveSortColumn(field);
    setTableData(sortData);
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="lease.tenants.list.company" defaultMessage="Company" />
            <img
              src={activeSortColumn === 'name' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('name', orderType.name); }}
            />
          </th>
          <th>
            <FormattedMessage id="tenants.new.businesstype" defaultMessage="Business Type" />
            <img
              src={activeSortColumn === 'business_type' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('business_type', orderType.business_type); }}
            />
          </th>
          <th>
            <FormattedMessage id="tenants.new.category" defaultMessage="Category" />
            <img
              src={activeSortColumn === 'category' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('category', orderType.category); }}
            />
          </th>
          <th>
            <FormattedMessage id="lease.tenants.list.lease_start" defaultMessage="Lease Start" />
            <img
              src={activeSortColumn === 'lease.start_date' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('lease.start_date', orderType['lease.start_date']); }}
            />
          </th>
          <th>
            <FormattedMessage id="lease.tenants.list.lease_end" defaultMessage="Lease End" />
            <img
              src={activeSortColumn === 'lease.end_date' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('lease.end_date', orderType['lease.end_date']); }}
            />
          </th>
          <th>
            <FormattedMessage id="lease.tenants.list.days_remaining" defaultMessage="Days Remaining" />
            <img
              src={activeSortColumn === 'lease.days' ? sortActiveIcon : sortIcon}
              alt=""
              onClick={() => { handleSortColumn('lease.days', orderType['lease.days']); }}
            />
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {(!tableData || (tableData && tableData.length === 0)) ? (
          <tr>
            <td className="noTableData" colSpan={7}>
              <FormattedMessage id="lease.tenants.list.no_tenants" defaultMessage="There are no tenants on the selected range" />
            </td>
          </tr>
        )
          : (
            tableData.map(e => (
              <tr key={e.id}>
                <td>{e.name}</td>
                <td>{e.business_type}</td>
                <td>{e.category}</td>
                <td>{e.lease && e.lease.start_date && (moment(e.lease.start_date)).format('MM/DD/YYYY hh:mm A')}</td>
                <td>{e.lease && e.lease.end_date && (moment(e.lease.end_date)).format('MM/DD/YYYY hh:mm A')}</td>
                <td>
                  {e.lease && e.lease.days > 0 && (
                    <div className={styles.green}>
                      {e.lease.days}
                    </div>
                  )}
                  {e.lease && e.lease.days < 0 && (
                    <div className={styles.red}>
                      <FormattedMessage id="lease.tenants.list.expired" defaultMessage="Expired" />
                    </div>
                  )}
                </td>
                <td>
                  <Link to={`${TENANTS}/${e.id}/detail`}>
                    <FormattedMessage id="workorders.list.table.action" defaultMessage="View" />
                  </Link>
                </td>
              </tr>
            ))
          )
        }
      </tbody>
    </table>
  );
};

Table.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default Table;
