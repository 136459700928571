import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './toDoList.module.scss';
import Separator from '../../../components/separator/Separator';
import Clickable from '../../../components/clickable/Clickable';

const Toolbar = ({ handleDropdown, translationID, listSummary, toolsection, handleToolSection }) => (
  <div style={{ display: 'flex' }} className={styles.toolbar}>
    <div className={styles.counter}>
      {listSummary && (
        <div style={{ paddingLeft: '5px' }}>
          <FormattedMessage id={translationID} defaultMessage="All  " />
          (
          {listSummary.length}
          )
        </div>
      )
      }
    </div>
    <div className={styles.detailHeader}>
      <Separator />
      <Clickable className={toolsection === 'list' ? styles.active : styles.actionsBtn} onClick={() => handleToolSection('list')}>
        <FormattedMessage id="todo.toolbar.list" defaultMessage="List" />
      </Clickable>
      <Clickable className={toolsection === 'time' ? styles.active : styles.actionsBtn} onClick={() => handleToolSection('time')}>
        <FormattedMessage id="todo.label.time" defaultMessage="Time" />
      </Clickable>
      <Separator />
      <Clickable className={styles.actionsBtn} onClick={() => handleDropdown()}>
        <FormattedMessage id="workorders.list.filters" defaultMessage="Filters " />
      </Clickable>
    </div>
  </div>
);

export default Toolbar;

Toolbar.propTypes = {
  handleDropdown: PropTypes.func.isRequired
};
