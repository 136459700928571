import styled from 'styled-components';

type WrapperProps = {
  size: "small" | "standard";
};

const sizes = {
  small: {
    height: '26px',
    width: '31px'
  },
  standard: {
    height: '29px',
    width: '35px'
  }
};

export const Wrapper = styled.span <WrapperProps>`
  position: relative;
  img {
    padding: 0px 3px;
    height: ${({ size }) => sizes[size].height} !important;
    width: ${({ size }) => sizes[size].width} !important;
    border-radius: 50% !important;
  }
  .active {
    position: absolute;
    width: 9.2px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.background.lightGreen};
    right: 6px;
    z-index: 2;
    border-radius: 50%;
  }
`;
