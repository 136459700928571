import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';

import Forbidden from '../../Forbidden';
import Properties from './Properties';
import PropertiesSettings from './PropertiesBuilder';

const PropertiesLayout = ({ match }) => (
  <Permissions allowed={['view_leaseproperties']} fallbackElement={<Forbidden />}>
    <Switch>
      <Route exact path={`${match.url}`} component={Properties} />
      <Route exact path={`${match.url}/settings`} component={PropertiesSettings} />
    </Switch>
  </Permissions>
);

PropertiesLayout.propTypes = {
  match: PropTypes.shape({}).isRequired
};

export default PropertiesLayout;
