import gql from 'graphql-tag';

const FETCH_CHANNELS_POSTS = gql`
  query getPostComments(
    $postId: String!
    $userId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPostComments(
      postId: $postId
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        airportId
        channelId
        commentId
        content
        createdAt
        iropsId
        likes
        parentCommentId
        postId
        updatedAt
        userId
        isLiked
        user {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId
          airportDetails {
            name
            code
            logo
          }
          roles{
            name
            id
            permissions {
              name
            }
          }
          airportId
        }
      }
      nextToken
    }
  }
`;
export default FETCH_CHANNELS_POSTS;
