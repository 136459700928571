import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import styles from '../steps.module.scss';
import Clickable from '../../../../../../components/clickable/Clickable';
import LeaseDetail from './components/LeaseDetail';
import AircraftDetail from '../../../Detail/components/AircraftDetail';

const Step5 = ({ answers, lease, fees, aircrafts, documents, handleEdit, fixedFee, allProperties }) => {
  const filterFees = [];
  fees.forEach((l) => {
    const fee = {};
    Object.keys(l).forEach((e) => {
      if (l[e] && (l[e].length > 0)) fee[e] = l[e];
    });
    if (Object.keys(fee).length > 0) filterFees.push(fee);
  });
  const filterDocuments = [];
  documents.forEach((l) => {
    const document = {};
    Object.keys(l).forEach((e) => {
      if (l[e] && (l[e].length > 0)) document[e] = l[e];
    });
    if (Object.keys(document).length > 0) filterDocuments.push(document);
  });
  const filterAircrafts = [];
  aircrafts.forEach((l) => {
    const aircraft = {};
    Object.keys(l).forEach((e) => {
      if (l[e] && (l[e].length > 0)) aircraft[e] = l[e];
    });
    if (Object.keys(aircraft).length > 0) filterAircrafts.push(aircraft);
  });
  return (
    <div className={styles.stepFive}>
      <div className={styles.sectionHeader}>
        <FormattedMessage id="lease.tenants.steps.step5.confirmation" defaultMessage="Confirmation" />
      </div>

      {/* displaying company related information */}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step5.company_info" defaultMessage="Company Information"
              />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleEdit(1)}>
                <FormattedMessage id="inspections.step0.edit" defaultMessage="Edit" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.name" defaultMessage="Company Name" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.name}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.alias" defaultMessage="Alias" />
            </span>
            <span className={styles.rowContent}>
              {(answers && answers.alias) || '-'}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.businesstype" defaultMessage="Business Type" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.business_type}
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.phone" defaultMessage="Company phone" />
            </span>
            <span className={styles.rowContent}>
              <NumberFormat displayType="text" value={answers.phone}
                format="+1 (###) ###-####"
              />
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.category" defaultMessage="Category Type" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.category}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="tenants.new.address" defaultMessage="Company Address" />
            </span>
            <span className={styles.rowContent}>
              {answers && answers.address}
            </span>
          </div>
        </div>
      </div>

      {/* displaying lease related information */}
      <LeaseDetail lease={lease} handleEdit={handleEdit} allProperties={allProperties} />

      {/* displaying fees related information */}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step3.fee" defaultMessage="Fees"
              />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleEdit(3)}>
                <FormattedMessage id="inspections.step0.edit" defaultMessage="Edit" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step3.business_fee" defaultMessage="Annual Business Fee" />
            </span>
            <span className={styles.rowContent}>
              {fixedFee && (
              <NumberFormat displayType="text" value={fixedFee.annual_business_fee}
                thousandSeparator prefix="$" suffix=".00"
              />
              )}
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step3.land_fee" defaultMessage="Landing Fee" />
            </span>
            <span className={styles.rowContent}>
              {fixedFee && (
              <NumberFormat displayType="text" value={fixedFee.landing_fee}
                thousandSeparator prefix="$" suffix=".00"
              />
              )}
            </span>
          </div>
        </div>
        <div className={styles.infoTable}>
          <div className={styles.infoRow}>
            <span className={styles.title}>
              <FormattedMessage id="lease.tenants.steps.step3.ramp_fee" defaultMessage="Ramp Fee" />
            </span>
            <span className={styles.rowContent}>
              {fixedFee && (
              <NumberFormat displayType="text" value={fixedFee.ramp_fee}
                thousandSeparator prefix="$" suffix=".00"
              />
              )}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step3.misc_fee" defaultMessage="Misc Fees"
              />
            </span>
          </div>
        </div>
      </div>
      {filterFees.length > 0 && filterFees.map(fee => (
      <>
        {Object.values(fee).length > 0 && (
        <div className={styles.requestInfo}>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step3.fee_type"
                  defaultMessage="Fee Type"
                />
              </span>
              <span className={styles.rowContent}>
                {fee && fee.fee_type}
              </span>
            </div>
          </div>
          <div className={styles.infoTable}>
            <div className={styles.infoRow}>
              <span className={styles.title}>
                <FormattedMessage id="lease.tenants.steps.step3.amount"
                  defaultMessage="Amount"
                />
              </span>
              <span className={styles.rowContent}>
                {fee && (
                <NumberFormat displayType="text" value={fee.amount}
                  thousandSeparator prefix="$" suffix=".00"
                />
                )}
              </span>
            </div>
          </div>
        </div>
        )}
        {fee && fee.receipt_attachments && fee.receipt_attachments.length > 0 && (
        <div className={styles.requestInfoField}>
          <span className={styles.title}>
            <FormattedMessage id="lease.tenants.steps.step3.attachment" defaultMessage="Attachments" />
          </span>
          { fee.receipt_attachments.length > 1 ? (
            <div className={styles.photos}>
              {fee.receipt_attachments.map(e => (
                <Clickable
                  key={e.id}
                >
                  {(e.type).includes('image') ? (
                    <img src={e.preview} alt={e.name} />
                  ) : <p>{e.name}</p>
              }
                </Clickable>
              ))}
            </div>
          )
            :(
              <div className={styles.photo}>
                {fee.receipt_attachments.map(e => (
                  <Clickable
                    key={e.id}
                  >
                    {(e.type).includes('image') ? (
                      <img src={e.preview} alt={e.name} />
                    ) : <p>{e.name}</p>
                }
                  </Clickable>
                ))}
              </div>
            )}
        </div>
        )}
        <div className={styles.seperation} />
    </>
      ))}

      {/* displaying documents related information */}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step3.document" defaultMessage="Additional Documents"
              />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleEdit(3)}>
                <FormattedMessage id="inspections.step0.edit" defaultMessage="Edit" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      {filterDocuments.length > 0 && filterDocuments.map(document => (
      <>
        {Object.values(document).length > 0 && (
        <>
          <div className={styles.requestInfo}>
            <div className={styles.infoTable}>
              <div className={styles.infoRow}>
                <span className={styles.title}>
                  <FormattedMessage id="lease.tenants.steps.step3.document_type"
                    defaultMessage="Document Type"
                  />
                </span>
                <span className={styles.rowContent}>
                  {document && document.document_type}
                </span>
              </div>
            </div>
            <div className={styles.infoTable}>
              <div className={styles.infoRow}>
                <span className={styles.title}>
                  <FormattedMessage id="lease.tenants.steps.step3.valid_date"
                    defaultMessage="Valid until"
                  />
                </span>
                <span className={styles.rowContent}>
                  {document && document.valid_date && moment(document.valid_date).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.requestInfo}>
            <div className={styles.infoTable}>
              <div className={styles.infoRow}>
                <span className={styles.title}>
                  <FormattedMessage id="operations.create.description" defaultMessage="Description" />
                </span>
                <span className={styles.rowContent}>
                  {document && document.description}
                </span>
              </div>
            </div>
          </div>
        </>
        )}
        {document && document.files && document.files.length > 0 && (
        <div className={styles.requestInfoField}>
          <span className={styles.title}>
            <FormattedMessage id="lease.tenants.steps.step3.attachment" defaultMessage="Attachments" />
          </span>
          { document && document.files.length > 1 ? (
            <div className={styles.photos}>
              {document.files.map(e => (
                <Clickable
                  key={e.id}
                >
                  {(e.type).includes('image') ? (
                    <img src={e.preview} alt={e.name} />
                  ) : <p>{e.name}</p>
                }
                </Clickable>
              ))}
            </div>
          )
            :(
              <div className={styles.photo}>
                {document.files.map(e => (
                  <Clickable
                    key={e.id}
                  >
                    {(e.type).includes('image') ? (
                      <img src={e.preview} alt={e.name} />
                    ) : <p>{e.name}</p>
                  }
                  </Clickable>
                ))}
              </div>
            )}
        </div>
        )}
        <div className={styles.seperation} />
      </>
      ))}

      {/* displaying aircraft related information */}
      <div className={styles.requestInfo}>
        <div className={styles.infoTable}>
          <div className={styles.headerRow}>
            <span className={styles.title}>
              <FormattedMessage
                id="lease.tenants.steps.step4.based_aircraft" defaultMessage="Based Aircraft"
              />
            </span>
            <span className={styles.rowContent}>
              <Clickable onClick={() => handleEdit(4)}>
                <FormattedMessage id="inspections.step0.edit" defaultMessage="Edit" />
              </Clickable>
            </span>
          </div>
        </div>
      </div>
      {filterAircrafts.length > 0 && (
      <>
        <AircraftDetail aircrafts={filterAircrafts} aircraftDetail />
        <div className={styles.seperation} />
        </>
      )}
    </div>
  );
};

export default Step5;
