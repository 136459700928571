import { ApolloError } from 'apollo-client';
import { useQuery } from '../../../graphql/hooks';
import GET_CHANNEL from '../queries/channelEdit';

type UseGetChannelDetails = {
  (channelId: string): { data: any; error: ApolloError; loading: boolean };
};

export const useGetChannelDetails: UseGetChannelDetails = (channelId) => {
  const { data, error, loading } = useQuery(GET_CHANNEL, {
    variables: {
      channelId
    }
  });

  return {
    data: (data && data.getChannel) || [],
    error,
    loading
  };
};
