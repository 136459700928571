import {
  DirectMessageType,
  DirectMessageArrType,
  ListChatArrType,
  ChatListUser,
  NewDirectMessagesType
} from "../../../types";

export enum DMType {
  setActiveChat = "setDMActiveChat",
  setDirectMessageList = "setDirectMessageList",
  setDirectMessage = "setDirectMessage",
  setTopicsCollection = "setDMTopicsCollection",
  updateTopicsCollection = "updateDMTopicsCollection",
  setNewMessage = "setNewDMMessage",
  setEditedDM = "setEditedDM",
  deleteMessage = 'deleteDMMessage'
}

export type DirectMessagesState = {
  visible: boolean;
  topicsConnected: boolean;
  loading: boolean;
  topicsCollectionCount: number;
  editedDMID: string;
  editedDM: DirectMessageType;
  activeChat: Pick<SetActiveChatProps, "chatName" | "chatId">;
  listChats: ListChatArrType;
  fromUser: ChatListUser;
  newDirectMessages: NewDirectMessagesType
} & SetDirectMessageListProps;

export interface SetActiveChatProps {
  chatName: string;
  chatId: string;
  listChats?: ListChatArrType;
}

export interface SetDirectMessageListProps {
  directMessageList: DirectMessageArrType;
}

export interface DeleteDMProps {
  messageId: string
}

export interface SetTopicsCollectionProps {
  topicsCollectionCount: number;
}

export interface SetNewMessageProps {
  data: DirectMessageType;
}

export interface SetEditedMessageProps {
  editedDMID: string;
}

export interface SetDirectMessageProps {
  directMessageList: DirectMessageArrType;
}

export interface SetActiveChatAction {
  type: DMType.setActiveChat;
  payload: SetActiveChatProps;
}

export interface SetDirectMessageListAction {
  type: DMType.setDirectMessageList;
  payload: SetDirectMessageListProps;
}

export interface SetTopicsCollectionAction {
  type: DMType.setTopicsCollection;
  payload: SetTopicsCollectionProps;
}

export interface UpdateTopicsCollectionAction {
  type: DMType.updateTopicsCollection;
}

export interface UpdateTopicsCollectionAction {
  type: DMType.updateTopicsCollection;
}

export interface SetNewMessageAction {
  type: DMType.setNewMessage;
  payload: SetNewMessageProps;
}

export interface DeleteDMAction {
  type: DMType.deleteMessage,
  payload: DeleteDMProps,
}

export interface SetEditedDMAction {
  type: DMType.setEditedDM;
  payload: SetEditedMessageProps;
}

export type ActionTypes = {
  setActiveChat: (
    dispatch: DMDispatch,
    props: SetActiveChatProps
  ) => any;
  setDirectMessageList: (
    dispatch: DMDispatch,
    props: SetDirectMessageListProps
  ) => any;
  setTopicsCollection: (
    dispatch: DMDispatch,
    props: SetTopicsCollectionProps
  ) => any;
  updateTopicsCollection: (dispatch: DMDispatch) => any;
  setNewMessage: (
    dispatch: DMDispatch,
    props: SetNewMessageProps
  ) => any;
  deleteMessage: (
    dispatch: DMDispatch,
    props: DeleteDMProps
  ) => any;
  setEditedMessage: (
    dispatch: DMDispatch,
    props: SetEditedMessageProps
  ) => any;
};

export type IAction =
  | SetActiveChatAction
  | SetDirectMessageListAction
  | UpdateTopicsCollectionAction
  | SetNewMessageAction
  | SetEditedDMAction
  | SetTopicsCollectionAction
  | SetTopicsCollectionAction
  | DeleteDMAction;

export type DMDispatch = <P extends {}>(
  action: Readonly<IAction>
) => any;
