/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import drawLocales from 'leaflet-draw-locales';

import { uniqueID } from '../../../utils/helpers';

import styles from '../fields.module.scss';
import * as utils from '../../../modules/settings/Map/mapUtils';

import iconAsset from '../../../icons/assets/workOrder.svg';

const language = localStorage.getItem('lang') ||'en';
drawLocales(language);
const drawnItems = new L.FeatureGroup();

const locale = drawLocales(language);
locale.draw.toolbar.buttons.polygon = 'Draw a New Surface!';
L.drawLocal = locale;

class Location extends Component {
  constructor(props) {
    super(props);
    this.ID = uniqueID('map');
    this.state = {
      layer: undefined,
      geometry: {},
      layerAdded: false,
      markerDisable: false,
      deleted: false
    };

    const { isRequired, updateFieldErrors, answer } = this.props;

    if (isRequired && (answer === '' || answer === undefined)) {
      updateFieldErrors(['pulpoforms.errors.not_blank']);
    }
  }

  componentDidMount() {
    this.setState({ geometry: {}, layerAdded: true });
    const { answer } = this.props;
    if (Array.isArray(answer)) {
      this.initializeMap();
    }
    this.map.eachLayer((layer) => {
      if (layer._layers && Object.keys(layer._layers).length>0) {
        Object.keys(layer._layers).map((l) => {
          this.map.removeLayer(layer._layers[l]);
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { answer } = this.props;
    const { layer, geometry, layerAdded }=this.state;

    if (layer && layerAdded===false) {
      this.handleAnswerChanged([geometry], 'Polygon');
      this.setState({ layerAdded: true });

      this.map.on('draw:deleted', () => {
        this.setState({ layer: undefined, deleted: true, layerAdded: false, markerDisable: false });
        this.handleAnswerChanged([], 'Polygon');
      });
      let l = L.geoJSON(geometry)._layers;
      [l] = Object.keys(l).map(ob => l[ob]);
      const customlayer = this.addPopupToLayer(l);
      this.map.addLayer(drawnItems.addLayer(customlayer));
      this.map.on('draw:edited', (e) => {
        const layers = e.layers;
        layers.eachLayer((layerz) => {
          const coordinates = layerz._latlngs[0].map(o => [o.lng, o.lat]);
          this.setState({
            layer: e.layer,
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            },
            markerDisable: false
          });
          this.handleAnswerChanged([{ type: 'Polygon', coordinates: [coordinates] }], 'Polygon');
        });
      });
    }
    if (!prevProps.answer) {
      if (Array.isArray(answer)) {
        this.initializeMap();
      }
    }
  }

  componentWillUnmount() {
    this.map.remove();
  }

  addPopupToLayer = (layer) => {
    const customlayer = layer;
    customlayer.setStyle({
      fillColor: 'blue',
      opacity: 1,
      fillOpacity: 0.48
    });
    return customlayer;
  };

  handleAnswerChanged = (value, fieldId) => {
    const {
      handleValueChange,
      handleFieldErrorChanged,
      isRequired,
      updateFieldErrors
    } = this.props;

    handleValueChange(value, fieldId);
    let updatedErrors = [];

    if (value === '' || value === undefined) {
      handleFieldErrorChanged(fieldId, isRequired);
      updatedErrors = [
        ...updatedErrors,
        'pulpoforms.errors.not_blank'
      ];
    } else {
      handleFieldErrorChanged(fieldId, false);
    }
    updateFieldErrors(updatedErrors);
  }

  initializeMap = () => {
    const { answer, fieldId, handleZoomLevel, shape } = this.props;
    const curLocation = answer.reverse() || [0, 0];
    this.map = L.map(this.ID, {
      center: curLocation,
      zoom: 15,
      editable: true
    });
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);
    // eslint-disable-next-line new-cap
    const marker = new L.marker(curLocation, {
      draggable: 'true',
      icon: new L.Icon({
        iconUrl: iconAsset,
        iconSize: [29, 35],
        iconAnchor: [15, 35]
      })
    });

    this.map.on('draw:created', async (e) => {
      const coordinates = e.layer._latlngs[0].map(o => [o.lng, o.lat]);

      await this.setState({
        layer: e.layer,
        layerAdded: false,
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates]
        },
        markerDisable: false
      });
    });

    const drawControl=this.addDrwer();
    this.map.addControl(drawControl);
    if (this.state.deleted) {
      const layerGroup = L.layerGroup().addTo(this.map);
      layerGroup.clearLayers();
    }

    this.map.on('draw:drawstart', () => {
      this.setState({
        markerDisable: true
      });
    });
    this.map.on('draw:deletestart', () => {
      this.setState({
        markerDisable: true
      });
    });

    if (shape && shape.length>0 && this.state.deleted===false) {
      shape.map((o) => {
        let l = L.geoJSON(o.geometry)._layers;
        [l] = Object.keys(l).map(ob => l[ob]);
        this.setState({ layer: l, geometry: o.geometry, layerAdded: false });
      });
    }


    this.map.on('click', (e) => {
      if (this.state.markerDisable === false) {
        const position = e.latlng;
        const zoomLevel = this.map.getZoom();
        handleZoomLevel(zoomLevel);
        marker.setLatLng(position);
        this.handleAnswerChanged([position.lng, position.lat], 'location');
      }
    });
    marker.on('dragend', () => {
      const position = marker.getLatLng();
      const zoomlevel = this.map.getZoom();
      handleZoomLevel(zoomlevel);
      marker.setLatLng(position);
      // send Updated coordinates
      this.handleAnswerChanged([position.lng, position.lat], fieldId);
    });
    const zoomLevel = this.map.getZoom();
    handleZoomLevel(zoomLevel);
    this.map.addLayer(marker);
    this.handleAnswerChanged(curLocation.reverse(), fieldId);
  }

  addDrwer=() => {
    this.map.addLayer(drawnItems);
    return new L.Control.Draw({
      editable: true,
      marker: false,
      edit: { featureGroup: drawnItems,
        remove: true,
        edit: true
      },
      draw: {
        marker: false,
        circle: false,
        rectangle: false,
        polyline: false,
        circlemarker: false,
        polygon: {
          shapeOptions: {
            color: 'blue',
            opacity: 1,
            fillOpacity: 0.48
          },
          icon: new utils.CustomIcon({ color: 'blue' })
        }
      }
    });
  }

  render() {
    return (
      <div id={this.ID} className={styles.map} />
    );
  }
}


Location.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  updateFieldErrors: PropTypes.func.isRequired,
  answer: PropTypes.arrayOf(PropTypes.number)
};

Location.defaultProps = {
  answer: undefined
};

export default Location;
