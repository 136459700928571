import { ChannelType, PostMessageType, NewPostsType } from "../../../types";

export enum PostsActionType {
  setNewMessage = "setNewPost",
  setReadMessages = "setReadMessages",
  setTopicsCollection = "setPostsTopicsCollection",
  updateTopicsCollection = "updatePostsTopicsCollection",
  deletePost = "deletePost",
  setPostList = "setPostList",
  toggleLikes = "toggleLikes",
  updatePost = "updatePost",
  setEditedMessage = "setEditedPostMessage",
  setChannel = "setChannel",
  resetChannel = "resetChannel",
  updateReplies = "updateReplies",
  setDeletedPostID = "setDeletedPostID"
}

export type PostMessageObj = {
  items: PostMessageType[];
  nextToken: string;
};

export interface PostsState {
  newPosts?: NewPostsType;
  postList: PostMessageObj;
  topicsCollectionCount?: number;
  topicsConnected?: boolean;
  editedPost: PostMessageType;
  channelData: SetChannelType;
  loading: boolean;
  deletedPostID: string;
}

export interface PostsProps {
  data: PostMessageType;
}

export interface TopicsCollection {
  topicsCollectionCount?: number;
}

export interface SubTopicsCollection {
  subTopicsCollection?: string[];
}

export interface ToggleLikesType {
  updatePost: PostMessageType;
  nextToken: string;
}

export interface UpdatePostType {
  updatePost: PostMessageType;
}

export interface PostList {
  postList: PostMessageObj;
  channelId: string;
}

export interface ReadMessagesProps {
  prevChannelId: string;
}

export interface DeletePostProps {
  postId: string;
}

export interface EditedPostIDProps {
  editedPostID: string;
}

export type SetChannelType = {
  channelId: string;
  listUserChannels?: ChannelType[];
  channelName: string;
};

export type UpdateReplies = {
  postId: string;
  reduce?: boolean;
};

export type DeletePost = {
  deletedPostID: string;
};

// -------------- Actions --------------

export interface PostsPropsAction {
  type: PostsActionType.setNewMessage;
  payload: PostsProps;
}

export interface ReadMessagesPropsAction {
  type: PostsActionType.setReadMessages;
  payload: ReadMessagesProps;
}

export interface TopicsCollectionAction {
  type: PostsActionType.setTopicsCollection;
  payload: TopicsCollection;
}

export interface SubTopicsCollectionAction {
  type: PostsActionType.updateTopicsCollection;
}

export interface PostListAction {
  type: PostsActionType.setPostList;
  payload: PostList;
}

export interface DeletePostPropsAction {
  type: PostsActionType.deletePost;
  payload: DeletePostProps;
}

export interface ToggleLikesTypeAction {
  type: PostsActionType.toggleLikes;
  payload: ToggleLikesType;
}

export interface ToggleLikesTypeAction {
  type: PostsActionType.toggleLikes;
  payload: ToggleLikesType;
}

export interface SetEditedPostAction {
  type: PostsActionType.setEditedMessage;
  payload: EditedPostIDProps;
}

export interface UpdatePostAction {
  type: PostsActionType.updatePost;
  payload: UpdatePostType;
}

export interface SetChannelAction {
  type: PostsActionType.setChannel;
  payload: SetChannelType;
}

export interface UpdateRepliesAction {
  type: PostsActionType.updateReplies;
  payload: UpdateReplies;
}

export interface DeletedPostAction {
  type: PostsActionType.setDeletedPostID;
  payload: DeletePost;
}

export type IAction =
  | PostsPropsAction
  | ReadMessagesPropsAction
  | TopicsCollectionAction
  | SubTopicsCollectionAction
  | PostListAction
  | DeletePostPropsAction
  | SetEditedPostAction
  | UpdatePostAction
  | SetChannelAction
  | UpdateRepliesAction
  | DeletedPostAction
  | ToggleLikesTypeAction;

export type IActionTypes = {
  setNewMessage: (dispatch: PostsDispatch, props: PostsProps) => any;
  setReadMessages: (dispatch: PostsDispatch, props: ReadMessagesProps) => any;
  setTopicsCollection: (
    dispatch: PostsDispatch,
    props: TopicsCollection
  ) => any;
  setDeletedPost: (dispatch: PostsDispatch, props: DeletePost) => any;
  updateTopicsCollection: (dispatch: PostsDispatch) => any;
  setPostList: (dispatch: PostsDispatch, props: PostList) => any;
  deletePost: (dispatch: PostsDispatch, props: DeletePostProps) => any;
  toggleLikes: (dispatch: PostsDispatch, props: ToggleLikesType) => any;
  setEditedMessage: (dispatch: PostsDispatch, props: EditedPostIDProps) => any;
  updatePost: (dispatch: PostsDispatch, props: UpdatePostType) => any;
  setChannel: (dispatch: PostsDispatch, props: SetChannelType) => any;
  updateReplies: (dispatch: PostsDispatch, props: UpdateReplies) => any;
};

export type PostsDispatch = <P extends {}>(action: Readonly<IAction>) => any;
