import React from 'react';
import { DirectMessageType, DeleteMessagesTypes } from '../../types';
import { DirectMessageActions } from '../MessageActions';
import { MessageHeader } from './MessageHeader';
import { MessageContent } from './MessageContent';
import { Attachment } from '../../components/Attachment';
import { ChatTypes } from '../../enums';
import { MessageWrapper } from './MessageWrapper';

type DirectMessageProps = {
  messageData: DirectMessageType;
  type: ChatTypes;
  currentUserId: string;
  deleteMessage: DeleteMessagesTypes;
};

const DirectMessage = React.memo<DirectMessageProps>(
  ({ messageData, type, currentUserId, deleteMessage }) => {
    const { fromUser, createdAt, message, attachments, messageId } = messageData;
    const { userId } = fromUser;
    const isMessageOwner = currentUserId === userId;

    return (
      <MessageWrapper
        type={type}
        isAttachments={!!attachments}
        isMessageOwner={isMessageOwner}
      >
        {({ showOption }) => (
          <div className="message-container">
            <MessageHeader user={fromUser} createdAt={createdAt} type={type} />
            {showOption && isMessageOwner && (
              <DirectMessageActions
                type={type}
                deleteMessage={deleteMessage}
                messageId={messageId}
              />
            )}
            <div className="content-wrapper">
              <MessageContent content={message} type={type} />
              <Attachment attachments={attachments} />
            </div>
          </div>
        )}
      </MessageWrapper>
    );
  }
);

export default DirectMessage;
export { DirectMessage };
