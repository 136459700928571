import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 23px;
  background-image: linear-gradient(
    to bottom,
    #ffffff 4%,
    rgba(255, 255, 255, 0)
  );

  hr {
    margin: unset;
    zoom: 2;
    background: ${({ theme }) => theme.colors.border.light};
  }
  .message-date {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text.default};
    margin: unset;
    position: absolute;
    background: ${({ theme }) => theme.colors.background.white};
    top: 20px;
    left: 44%;
    transform: translate(-20%, -50%);
    width: 150px;
    text-align: center;
  }
  .message-new {
    position: absolute;
    color: ${({ theme }) => theme.colors.text.red};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.background.lightRed};
    padding: 5px 10px;
    top: 7px;
    right: 63px;
    z-index: 1;
  }
`;
