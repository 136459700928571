import gql from 'graphql-tag';

const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: String!, $postId: String!) {
    deleteComment(input: { commentId: $commentId, postId: $postId }) {
      channelId
      commentId
      content
      createdAt
      iropsId
      likes
      parentCommentId
      postId
      updatedAt
      userId
      isLiked
      user {
        firstName
        lastName
        airportId
        profilePicture
        airportDetails {
          name
          code
          logo
        }
      }
    }
  }
`;

export default DELETE_COMMENT;
