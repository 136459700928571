import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import sortIcon from "../../../../../icons/inspection-icons/sort.svg"
import sortActiveIcon from "../../../../../icons/inspection-icons/sort-active.svg"
import styles from '../../../inspections.module.scss';
import { INSPECTIONS_HOME_ROUTE } from '../../../../../constants/RouterConstants';


const Table =  ({ data , allinspections ,filterGroupBy }) => {
    const [ tableData , setTableData ] = useState([])
    const [ activeSortColumn , setActiveSortColumn ] = useState('')
    const defaultOrderType = {
        inspection :'asc',
        issues:'asc',
        [`inspected_by.fullname`]:'asc',
        inspection_date:'asc',
        [`weather_conditions.title`]:'asc'
    }
    const [orderType,setOrderType] =useState(defaultOrderType)


    useEffect(() => {
        if(filterGroupBy.key === 1){
            const element = document.getElementById("table-0");
            if (window.getComputedStyle(element, null).display === 'none') {
                element.style.display = '';
            }
        }
        setTableData(data);
    }, [data,filterGroupBy]);

    const handleSortColumn = (field,orderType) => {
        const sortData = tableData.map((item) =>{
            item.entries = _.orderBy(item.entries, [field],[orderType]);
            return item
        })
        setOrderType({...orderType,[field]: orderType === "asc" ? "desc":"asc"})
        setActiveSortColumn(field)
        setTableData(sortData)
    }

    const hideSection = (value) => {
            const element = document.getElementById(value);
            if (window.getComputedStyle(element, null).display === 'none') {
                element.style.display = '';
            } else {
                element.style.display = 'none';
            }
  };

  return (
    <table className={`${styles.table} ${styles.evenWidth}`} cellSpacing="0">
      <thead>
          <tr>
              <th width="400"><FormattedMessage id="inspections.list.table.type" defaultMessage="Type of Inspection" /><img src={activeSortColumn === "inspection" ? sortActiveIcon : sortIcon} alt="" onClick={()=>{handleSortColumn('inspection',orderType['inspection'])}}/></th>
              <th width="200"><FormattedMessage id="inspections.list.table.issues" defaultMessage="Issues found" /><img src={activeSortColumn === "issues" ? sortActiveIcon : sortIcon} alt="" onClick={()=>{handleSortColumn('issues',orderType['issues'])}}/></th>
              <th width="200"><FormattedMessage id="inspections.list.table.inspected_by" defaultMessage="Inspected By" /><img src={activeSortColumn === "inspected_by.fullname" ? sortActiveIcon : sortIcon} alt="" onClick={()=>{handleSortColumn('inspected_by.fullname',orderType['inspected_by.fullname'])}}/></th>
              <th><FormattedMessage id="inspections.list.table.date" defaultMessage="Date" /><img src={activeSortColumn === "inspection_date" ? sortActiveIcon : sortIcon} alt=""  onClick={()=>{handleSortColumn('inspection_date',orderType['inspection_date'])}}/></th>
              <th colSpan="2"><FormattedMessage id="inspections.list.table.weather" defaultMessage="Weather Conditions" /><img src={activeSortColumn === "weather_conditions.title" ? sortActiveIcon : sortIcon} alt="" onClick={()=>{handleSortColumn('weather_conditions.title',orderType['weather_conditions.title'])}} /></th>
          </tr>
      </thead>
      {
        Object.keys(data).map(k => (
          <React.Fragment key={k}>
            <thead className={styles.sectionHeader}>
            {filterGroupBy.key === 0 &&
                <tr onClick={() => hideSection(`table-${k}`)}
                    onKeyPress={() => hideSection(`table-${k}`)}
                >
                    {data[k].entries.length > 0 && (
                        <th colSpan="6">
                            <div className={styles.label}>
                                {data[k].label}
                            </div>
                        </th>
                    )}
                </tr>
            }
            </thead>
            {data[k].entries && (
            <tbody id={`table-${k}`} >
                {data[k].entries.map(e => (
                  <tr key={e.id}>
                    <td>{e.inspection}</td>
                    <td>{e.issues}</td>
                    <td>{e.inspected_by.fullname}</td>
                    <td>{(new Date(e.inspection_date)).toLocaleDateString()}</td>
                    <td>{e.weather_conditions.title}</td>
                    <td>
                      <Link to={`${INSPECTIONS_HOME_ROUTE}${e.id}`}><FormattedMessage id="inspections.list.table.view" defaultMessage="View" /></Link>
                    </td>
                  </tr>
                ))}
            </tbody>
            )}
          </React.Fragment>
        ))
      }
      {allinspections.length === 0 && (
        <tbody>
          <tr>
            <td colSpan={6}>
              <FormattedMessage id="inspections.list.table.no_results" defaultMessage="There are no inspections on the selected range" />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired
};
export default Table;
