import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Card, CardTitle, Row, CardBody } from 'reactstrap';
import { fetchsummary, clearActionResult } from '../../../inspections/redux/actions';
import Loading from '../../../../components/loading';
import WeatherIcon from '../../../../icons/airfield-icons/weather.PNG';

import styles from './weather.module.scss';

const Weather = ({ data, actionFetchSummary, summaryAction, clear }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    actionFetchSummary();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading === true && summaryAction.success=== true) {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryAction]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clear(), []);
  return (
    <Col lg={12}>
      <Loading loadingStatus={loading} />
      {data
      && Object.keys(data.weather).length > 0 && (
      <Card className={styles.weatherCard}>
        <CardTitle>
          <Col lg={12} className="text-center">
            <Row className={styles.weatherRow}>
              <img src={WeatherIcon} alt="weather" width={60} height={60} />
              <div className={styles.temperature}>
                { data && data.weather && data.weather.dewpoint && data.weather.dewpoint.slice(0, 6)}
              </div>
            </Row>
            <Row>
              <Col>
                <div className={`text-center ${styles.weatherText}`}>
                  {data && data.weather && data.weather.summary && data.weather.summary.toUpperCase()}
                </div>
              </Col>
            </Row>
          </Col>
          <div className={`text-center ${styles.currentweather}`}>Current Weather</div>
        </CardTitle>
        <hr className={styles.hrLine} />
        <CardBody className={styles.airfieldCard}>
          <Fragment>
            <Col>
              <Row>
                <Col lg={6} className={styles.firstRow}>
                  <div className={styles.label}>
                    <FormattedMessage
                      id="airfields.weather.relativehumidity"
                      defaultText="Relative Humadity"
                    />
                  </div>
                  <div className={` ${styles.weathervalue}`}>
                    {data.weather && data.weather.humidity}
                  </div>
                </Col>
                <Col lg={6} className={styles.firstRow}>
                  <div className={styles.label}>
                    <FormattedMessage
                      id="airfields.weather.wind"
                      defaultText="Wind"
                    />
                  </div>
                  <div className={`${styles.weathervalue}`}>
                    {data.weather && data.weather.wind}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className={styles.secondRow}>
                  <div className={styles.label}>
                    <FormattedMessage
                      id="airfields.weather.windDirection"
                      defaultText="Wind Directions"
                    />
                  </div>
                  <div className={`${styles.weathervalue}`}>
                    {data.weather && data.weather.wind}
                  </div>
                </Col>
                <Col lg={6} className={styles.secondRow}>
                  <div className={styles.label}>
                    <FormattedMessage
                      id="airfields.weather.dewpoint"
                      defaultText="Dewpoint"
                    />
                  </div>
                  <div className={`${styles.weathervalue}`}>
                    {data.weather && data.weather.dewpoint}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className={styles.thirdRow}>
                  <div className={styles.label}>
                    <FormattedMessage
                      id="airfields.weather.visibility"
                      defaultText="Visibility"
                    />
                  </div>
                  <div className={`${styles.weathervalue}`}>
                    {data.weather && data.weather.visibility}
                  </div>
                </Col>
              </Row>
            </Col>
          </Fragment>
        </CardBody>
      </Card>
      )
        }
    </Col>
  );
};

const mapStateToProps = state => ({
  data: state.inspection.summary,
  summaryAction: state.inspection.action
});

const mapDispatchToProps = dispatch => ({
  // Fetch Weather Data
  actionFetchSummary: () => {
    dispatch(fetchsummary());
  },
  clear: () => {
    dispatch(clearActionResult());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Weather));
