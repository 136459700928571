import { FormattedMessage, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import React from 'react';
import * as types from './types';
import { actionForState } from '../../../utils/helpers';

const INITIAL_STATE = {
  types: [],
  assets: [],
  assetsList: {
    results: []
  },
  typesNames: [],
  schemas: {},
  createAction: {
    success: false, loading: false, error: false
  },
  deleteAction: {
    success: false, loading: false, error: false
  },
  updateAction: {
    success: false, loading: false, error: false
  },
  assets_action: {
    success: false,
    loading: false,
    message: false
  },
  actionLoadTypeNames: {
    success: false, loading: false, error: false
  },
  toastId: null
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*         LOAD ASSET TYPES         */
    /* ******************************** */
    case types.LOAD_TYPE.pending:
      return {
        ...state,
        actionLoadTypes: actionForState(state.action, 'pending')
      };
    case types.LOAD_TYPE.success:
      return {
        ...state,
        types: action.payload.results,
        actionLoadTypes: actionForState(state.action, 'success')
      };
    case types.LOAD_TYPE.error:
      return {
        ...state,
        actionLoadTypes: actionForState(state.action, 'error')
      };

    /* ******************************** */
    /*      LOAD ASSET TYPES NAMES      */
    /* ******************************** */
    case types.LOAD_TYPE_NAME.pending:
      return {
        ...state,
        actionLoadTypeNames: actionForState(state.action, 'pending')
      };
    case types.LOAD_TYPE_NAME.success: {
      const res = action.payload.results.map((a) => {
        const aux = a;
        aux.photos = a.images;
        delete aux.images;
        return aux;
      });
      return {
        ...state,
        typesNames: res,
        actionLoadTypeNames: actionForState(state.action, 'success')
      };
    }
    case types.LOAD_TYPE_NAME.error:
      return {
        ...state,
        actionLoadTypeNames: actionForState(state.action, 'error')
      };

    /* ******************************** */
    /*          CREATE ASSETS           */
    /* ******************************** */
    case types.CREATE_ASSET.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.assetcreation" defaultMessage="Asset creation in Progress" />,
        { type: toast.TYPE.INFO });
      return {
        ...state,
        createAction: actionForState(state.action, 'pending')
      };
    case types.CREATE_ASSET.success: {
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.assetadded" defaultMessage="Asset Added successfully." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      const typesNames = state.typesNames.filter(e => e.id !== action.payload.id);
      const aux = action.payload;
      // aux.attachments = aux.attachments;
      delete aux.images;
      typesNames.push(aux);
      return {
        ...state,
        asset: action.payload,
        typesNames,
        createAction: actionForState(state.action, 'success')
      };
    }
    case types.CREATE_ASSET.error:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.erroroasset_delete" defaultMessage="Error occured while creating Asset." />,
        type: toast.TYPE.ERROR,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        createAction: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*          DELETE ASSETS           */
    /* ******************************** */
    case types.DELETE_ASSET.pending:
      state.toastId = toast(<FormattedMessage id="reducer.toast.bar.assetdeletedprogress" defaultMessage="Asset delete in Progress." />, { type: toast.TYPE.INFO });
      return {
        ...state,
        deleteAction: actionForState(state.action, 'pending')
      };
    case types.DELETE_ASSET.success:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.assetdeleted" defaultMessage="Asset Deleted." />,
        type: toast.TYPE.SUCCESS,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        deleteAction: actionForState(state.action, 'success')
      };
    case types.DELETE_ASSET.error:
      toast.update(state.toastId, {
        render: <FormattedMessage id="reducer.toast.bar.erroroccured" defaultMessage="Error occured while asset deleting." />,
        className: 'rotateY animated'
      });
      state.toastId = null;
      return {
        ...state,
        deleteAction: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*          FETCH ASSETS           */
    /* ******************************** */
    case types.FETCH_ASSET.pending:
      return {
        ...state,
        assets_action: actionForState(state.action, 'pending')
      };
    case types.FETCH_ASSET.success: {
      // const res = action.payload.results.map((a) => {
      //   const aux = a;
      //   aux.photos = a.images;
      //   delete aux.images;
      //   return aux;
      // });
      return {
        ...state,
        assetList: action.payload.results,
        assets_action: actionForState(state.action, 'success'),
        assetsList: action.payload
      };
    }
    case types.FETCH_ASSET.error:
      return {
        ...state,
        assets_action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*       FETCH ASSETS  SCHEMA       */
    /* ******************************** */
    case types.FETCH_ASSET_SCHEMA.pending:
      return {
        ...state,
        fetchAction: actionForState(state.action, 'pending')
      };
    case types.FETCH_ASSET_SCHEMA.success:
      return {
        ...state,
        schemas: action.payload,
        fetchAction: actionForState(state.action, 'success')
      };
    case types.FETCH_ASSET_SCHEMA.error:
      return {
        ...state,
        fetchAction: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*       UPDATE ASSETS SCHEMA       */
    /* ******************************** */
    case types.UPDATE_ASSET_SCHEMA.pending:
      return {
        ...state,
        updateAction: actionForState(state.action, 'pending')
      };
    case types.UPDATE_ASSET_SCHEMA.success:
      return {
        ...state,
        updateAction: actionForState(state.action, 'success')
      };
    case types.UPDATE_ASSET_SCHEMA.error:
      return {
        ...state,
        updateAction: actionForState(state.action, 'error')
      };

    /* ******************************** */
    /*      UPDATE SELF INSPECTION      */
    /* ******************************** */
    case types.UPDATE_SELF_INSPECTION.pending:
      return {
        ...state,
        updateAction: actionForState(state.action, 'pending')
      };
    case types.UPDATE_SELF_INSPECTION.success:
      return {
        ...state,
        updateAction: actionForState(state.action, 'success')
      };
    case types.UPDATE_SELF_INSPECTION.error:
      return {
        ...state,
        updateAction: actionForState(state.action, 'error')
      };

    /* ******************************** */
    /*         UPLOAD ASSETS            */
    /* ******************************** */
    case types.UPLOAD_ASSETS.pending:
      return {
        ...state,
        updateAction: actionForState(state.action, 'pending')
      };
    case types.UPLOAD_ASSETS.success:
      return {
        ...state,
        updateAction: actionForState(state.action, 'success')
      };
    case types.UPLOAD_ASSETS.error:
      return {
        ...state,
        updateAction: actionForState(state.action, 'error')
      };


    case types.CLEAR_ASSET_ACTION:
      return {
        ...state,
        assetsList: {
          results: []
        },
        createAction: {
          success: false, loading: false, error: false
        },
        deleteAction: {
          success: false, loading: false, error: false
        },
        updateAction: {
          success: false, loading: false, error: false
        }
      };

      case types.CLEAR_ASSET_TYPES:
        return {
          ...state,
          typesNames: [],
        }

    default:
      return injectIntl(state);
  }
}
