import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import sha1 from 'sha1';
import {
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import Button from '../../../button';
import styles from './ProfileEdit.module.scss';
import Shortcuts from '../../shortcuts/shortcuts';
import { fetchChangePin, clearProfile } from '../redux/actions';
import Collapsible from '../../../collapsible/Collapsible';
import Auth from '../../../../utils/Auth';
import { changeCurrentPage } from '../../../../general_redux/actions';
import Modal from '../../../modal';

class ChangePinForm extends Component {
  links = [
    { url: '/', name: this.props.intl.formatMessage({ id: 'shortcuts.aerobot' }), key: 'aerobot' },
    { url: '/todo', name: this.props.intl.formatMessage({ id: 'shortcuts.todo' }), key: 'tasks' },
    { url: '/messenger', name: this.props.intl.formatMessage({ id: 'shortcuts.chat' }), permissions: ['view_messenger_channel'], key: 'messenger' },
    { url: '/settings/organization',
      name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }),
      permissions: ['can_modify_airport_settings'],
      key: 'settings'
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      newPin: '',
      confirmPin: '',
      modal: false,
      oldPin: '',
      pinMatches: true,
      errors: {
        oldPin: true,
        newPin: true,
        confirmPin: true
      },
      len_errors: {
        oldPin: true,
        newPin: true,
        confirmPin: true
      },
      invalidOldPin: true
    };
    this.handleChangePin = this.handleChangePin.bind(this);
  }

  componentDidUpdate() {
    const { changePinAction, history, actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('home');
    if (changePinAction.success) {
      history.push('/');
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.changePinAction.success === false) {
      return { ...state, invalidOldPin: props.changePinAction.success };
    }
    return {};
  }


  componentWillUnmount() {
    const { actionclearProfile } = this.props;
    actionclearProfile();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.setState(prevState => ({
      errors: Object.assign({}, prevState.errors, {
        [name]: true
      })
    }), () => this.setState(prevState => ({
      len_errors: Object.assign({}, prevState.len_errors, {
        [name]: true
      })
    }))));
  }


  checkPinMatch = () => {
    const { newPin, confirmPin } = this.state;

    if (newPin !== confirmPin && confirmPin !== '') {
      this.setState({
        pinMatches: (newPin === confirmPin)
      });
    } else {
      this.setState({ pinMatches: true });
    }
  }


  checkEmptyFields = () => {
    const { oldPin, newPin, confirmPin } = this.state;
    if (oldPin === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          oldPin: false
        })
      }));
      return;
    }
    if (newPin === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          newPin: false
        })
      }));
      return;
    }

    if (confirmPin === '') {
      this.setState(prevState => ({
        errors: Object.assign({}, prevState.errors, {
          confirmPin: false
        })
      }));
    }
  }

  handleAuthError=() => {
    this.setState({ modal: true });
    this.setState(prevState => ({
      len_errors: Object.assign({}, prevState.len_errors, {
        oldPin: false
      })
    }));
  }

  handleChangePin(e) {
    e.preventDefault();
    const { oldPin, newPin, confirmPin, pinMatches } = this.state;
    const { actionfetchChangePin, profile } = this.props;

    this.checkPinMatch();
    if (!pinMatches) {
      return;
    }

    if (oldPin === '') {
      this.checkEmptyFields();
      return;
    }

    if (oldPin.length < 1) {
      this.setState(prevState => ({
        len_errors: Object.assign({}, prevState.len_errors, {
          oldPin: false
        })
      }));
    }

    if (newPin === '') {
      this.checkEmptyFields();
      return;
    }

    if (newPin.length < 4) {
      this.setState(prevState => ({
        len_errors: Object.assign({}, prevState.len_errors, {
          newPin: false
        })
      }));
      return;
    }

    if (confirmPin === '') {
      this.checkEmptyFields();
      return;
    }
    if (confirmPin.length < 4) {
      this.setState(prevState => ({
        len_errors: Object.assign({}, prevState.len_errors, {
          confirmPin: false
        })
      }));
      return;
    }
    const authDetails = new AuthenticationDetails({
      Username: profile.user.email,
      Password: oldPin
    });

    const cognitoUser = new CognitoUser({
      Username: profile.user.email,
      Pool: Auth.getInstance().getUserPool()
    });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: () => {
        const data = { newpin: sha1(newPin) };
        actionfetchChangePin(data);
      },
      onFailure: this.handleAuthError
    });
  }


  render() {
    const { len_errors, modal, errors, invalidOldPin, newPin, confirmPin, pinMatches } = this.state;

    return (
      <div>
        <Shortcuts links={this.links} />
        <form name="ChangePinForm" className={styles.form} onSubmit={this.handleChangePin}>
          <div className={styles.form}>
            <div className={styles.column}>
              <label htmlFor="oldPin">
                <FormattedMessage id="authentication.force_change_pin.old_pin" defaultMessage="Old Password" />
                <input
                  type="password"
                  name="oldPin"
                  // maxLength="4"
                  onChange={this.handleChange}
                  onBlur={this.checkEmptyFields}
                />
              </label>
              {errors.oldPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </span>
                </div>
              )}
              {len_errors.oldPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.pin_minlen" defaultMessage="Invalid Password" />
                  </span>
                </div>
              )}
              <Modal
                width="auto"
                minHeight="auto"
                showIn={modal}
                contentStyles={{
                  padding: ' 25px 80px 5px 30px'
                }}
                centered
              >
                <h1>
                Invalid Password
                </h1>
                <div style={{marginLeft:'1px'}}>
                  <Button onClick={() => { this.setState({ modal: false }); }}
                    translationID="inspections.list.ok"
                    defaultText="Ok" action="primary"
                  />
                </div>
              </Modal>
              <label htmlFor="newPin">
                <FormattedMessage id="authentication.force_change_pin.new_pin" defaultMessage="New Pin" />
                <input
                  type="password"
                  name="newPin"
                  maxLength="4"
                  value={newPin}
                  onChange={this.handleChange}
                  onBlur={this.checkEmptyFields}
                />
              </label>
              {errors.newPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </span>
                </div>
              )}
              {len_errors.newPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.pin_minlen" defaultMessage="The Minimum length of this field is 4" />
                  </span>
                </div>
              )}
              <label htmlFor="confirmPin">
                <FormattedMessage id="authentication.force_change_pin.confirm_pin" defaultMessage="Confirm Pin" />
                <input
                  type="password"
                  name="confirmPin"
                  maxLength="4"
                  value={confirmPin}
                  onChange={this.handleChange}
                  onBlur={this.checkPinMatch}
                />
              </label>
              {errors.confirmPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
                  </span>
                </div>
              )}
              {len_errors.confirmPin ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.login.error.pin_minlen" defaultMessage="The Minimum length of this field is 4" />
                  </span>
                </div>
              )}
              {pinMatches ? null : (
                <div className={styles.error}>
                  <span>
                    <FormattedMessage id="authentication.force_change_pin.pin_mismatch" defaultMessage="Pin and confirmation do not match" />
                  </span>
                </div>
              )}

              <Collapsible title="pin_policy.title" defaultMessage="Pin Policy" styleClasses={styles.profile} autoheight={false}>
                <span>
                  <FormattedMessage id="pin_policy.intro" defaultMessage="The pin must contain all of the following characteristics" />
                </span>
                <ul>
                  <li>
                    <FormattedMessage id="pin_policy.length" defaultMessage="Must be 4 digits." />
                  </li>
                </ul>
              </Collapsible>
              <br />
              <br />
              {invalidOldPin ? null : (
                <div className={styles.error}>
                  <font>
                    <FormattedMessage id="authentication.login.error.invalid_pin" defaultMessage="Invalid Pin" />
                  </font>
                </div>
              )}
              <br />
              <Button type="submit" translationID="authentication.force_change_pin.header" defaultText="Change Pin" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ChangePinForm.propTypes = {
  actionfetchChangePin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  action: state.profile.action,
  changePinAction: state.profile.action
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  },
  actionfetchChangePin: (data) => {
    dispatch(fetchChangePin(data));
  },
  actionclearProfile: () => {
    dispatch(clearProfile());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChangePinForm));
