import React from 'react';

import {
  CurrentUser,
  PostMessageType,
  CommentMessage,
  DeleteMessagesTypes,
  ToggleLikesFunc
} from '../../types';
// import { Attachment } from '../../components';
import { MessageHeader } from './MessageHeader';
import { ChatTypes } from '../../enums';
// import { MessageInfo } from '../MessageInfo';
// import { MessageContent } from './MessageContent';
import { MessageWrapper } from './MessageWrapper';
import { ChannelActions } from '../MessageActions';

type PostMessageProps = {
  toggleLikes?: ToggleLikesFunc;
  deleteMessage?: DeleteMessagesTypes;
  channelId: string;
  currentUser: CurrentUser;
  messageData: PostMessageType | CommentMessage;
  type: ChatTypes;
  commentedPost?: boolean;
  savingMessage?: boolean;
};
// click ui ID comment

const PostMessage = React.memo<PostMessageProps>(
  ({
    deleteMessage,
    currentUser: { userId: currentUserId },
    messageData,
    toggleLikes,
    type = ChatTypes.operations,
    channelId,
    commentedPost,
    savingMessage
  }) => {
    const {
      content,
      user,
      attachments,
      userId,
      postId,
      createdAt,
      comments
    } = messageData;

    const isComment = type === ChatTypes.comment;

    return (
      <MessageWrapper
        type={type}
        bordered={commentedPost}
        isAttachments={!!attachments}
        savingMessage={savingMessage}
        styles={{height:'10%'}}
      >
        {({ showOption }) => (
          <div className="message-container">
            <MessageHeader
            user={user}
            createdAt={createdAt}
            styles={{minHeight:'5px', maxHeight:'100%' }}
            contents={{
              commentedPost:commentedPost,
              postId:postId,
              userId:userId,
              isComment:isComment,
              deleteMessage:deleteMessage,
              messageData:messageData,
              channelId:channelId,
              type:type,
              toggleLikes:toggleLikes,
              comments:comments,
              content:content,
              attachments:attachments
            }}
            />
              {/* {console.log(messageData,channelId)} */}
            {showOption && !commentedPost && (
              <ChannelActions
                postId={postId}
                isAuthor={currentUserId === userId}
                deleteMessage={deleteMessage}
                messageData={messageData}
                channelId={channelId}
                type={type}
                toggleLikes={toggleLikes}
              />
            )}
            {/* {attachments? <Attachment attachments={attachments} />:''} */}
            {/* <div style={{
              lineHeight:'normal',
            }}>
              <MessageContent
                content={content}
                commentedPost={commentedPost}
                type= {type}
                styles={{lineHeight:'0.01', height:'fit-content'}}
              />
              {console.log(messageData)}
              
              {!commentedPost && !isComment && messageData.comments > 0 && (
                <MessageInfo
                  channelId={channelId}
                  messageData={messageData}
                  toggleLikes={toggleLikes}
                />
              )}
            </div>
            {commentedPost && (
              <div className="replies">
                <span>{`${comments} replies`}</span>
                <hr />
              </div>
            )} */}
          </div>
        )}
      </MessageWrapper>
    );
  }
);

export default PostMessage;
export { PostMessage };
