import React from 'react';
import { Wrapper } from './EditedMessage.styles';
import { MessageUnionType } from '../../types';
import CloseIcon from '../../../../icons/close.svg';
import { contentSubstring } from '../../util/contentSubstring';

type EditedMessageProps = {
  editedMessage?: MessageUnionType;
  closeEdit?: () => void;
};

const EditedMessage: React.FC<EditedMessageProps> = ({
  editedMessage,
  closeEdit
}) => {
  const content = (() => {
    if ('fromUser' in editedMessage) {
      return editedMessage.message;
    }
    return editedMessage.content;
  })();

  return (
    <Wrapper>
      <div>
        <p className="edit-title">Edit message</p>
        <p>{contentSubstring({ content, cutTo: 60 })}</p>
      </div>
      <img src={CloseIcon} alt="CloseIcon" onClick={closeEdit} />
    </Wrapper>
  );
};

export default EditedMessage;
export { EditedMessage };
