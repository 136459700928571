import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../Create/steps/steps.module.scss';

const SpaceDetail = ({
  info
}) => (
  <div className={styles.stepFive}>
    <div className={styles.requestInfo}>
      <div className={styles.infoTable}>
        <div className={styles.infoRow}>
          <span className={styles.title}>
            <FormattedMessage id="lease.property.space" defaultMessage="Space Size (sq.ft)" />
          </span>
          <span className={styles.rowContent}>
            {info.space_size || '-'}
          </span>
        </div>
      </div>
      <div className={styles.infoTable}>
        <div className={styles.infoRow}>
          <span className={styles.title}>
            <FormattedMessage id="lease.property.property_type" defaultMessage="Property Type" />
          </span>
          <span className={styles.rowContent}>
            {info.property_type || '-'}
          </span>
        </div>
      </div>
    </div>
    <div className={styles.requestInfo}>
      <div className={styles.infoTable}>
        <div className={styles.infoRow}>
          <span className={styles.title}>
            <FormattedMessage id="tenants.new.additional_remarks" defaultMessage="Additional Remarks" />
          </span>
          <span className={styles.rowContent}>
            {info.description}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default SpaceDetail;
