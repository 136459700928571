import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconButton from '../../../../../components/iconButton';
import NewField from '../../../../inspections/Builder/steps/Step2/NewField';
import OrderableList from '../../../../workorders/Builder/components/OrderableList/OrderableList';
import FormattedMessageWithClass from '../../../../../components/formattedMessageWithClass';

import addIcon from '../../../../../icons/add.svg';
import styles from '../../../../assets-management/Settings/assetSchemaBuilder.module.scss';


class MaintenanceSchemaBuilder extends Component {
  state = {
    modal: false,
    clickedField: {}
  }


  toggleModal = (value) => {
    this.setState({ modal: value });
  }


  openNewFieldModal = (form) => {
    this.setState({ clickedField: undefined, fieldForm: form });
    this.toggleModal(true);
  }

  handleFieldClick = (id, form) => {
    const { [form]: fields } = this.props;
    const clicked = fields.find(e => e.id === id);
    this.setState({ clickedField: clicked, fieldForm: form, modal: true });
  }

  render() {
    const { modal, clickedField, fieldForm } = this.state;
    const {
      workorder,
      maintenance,
      onFieldOrderChanged,
      onCreateField
    } = this.props;

    return (
      <div className="container">
        <div className={styles.fieldBoxContainer}>
          <div className={styles.formFieldBox}>
            <div className={`${styles.boxHeader} ${styles.signs}`}>
              <FormattedMessage tagName="h3"
                id="workorder.settings.work_order_box"
                defaultMessage="New Work Order"
              />
              <IconButton
                icon={addIcon}
                onClick={() => this.openNewFieldModal('workorder')}
              />
            </div>
            <div>
              <FormattedMessageWithClass tagName="h4" className={styles.sectionTitle}
                id="workorder.settings.work_order_details"
                defaultMessage="Work Order Details"
              />

              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.loggedby" defaultMessage="Logged by"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.reportdate" defaultMessage="Report date"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.priority" defaultMessage="Priority"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.category" defaultMessage="Category"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.location" defaultMessage="Location"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.problem_description" defaultMessage="Problem description"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="Asset.create.photos" defaultMessage="Photos"
              />

              {workorder.length > 0 && (
                <div className={styles.dynamicFields}>
                  <FormattedMessageWithClass tagName="h4" className={styles.sectionTitle}
                    id="assets.field.extra" defaultMessage="Additional Fields"
                  />
                  <OrderableList
                    fields={workorder} form="workorder"
                    handleFieldClick={this.handleFieldClick}
                    handleFieldOrderChanged={onFieldOrderChanged}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.formFieldBox}>
            <div className={`${styles.boxHeader} ${styles.lights}`}>
              <FormattedMessage tagName="h3"
                id="lease.maintenance.settings.complete_workorder"
                defaultMessage="Complete Work Order"
              />
              <IconButton icon={addIcon}
                onClick={() => this.openNewFieldModal('maintenance')}
              />
            </div>
            <div>
              <FormattedMessageWithClass tagName="h4" className={styles.sectionTitle}
                id="lease.maintenance.settings.completion_details"
                defaultMessage="Completion Details"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.workcompleted" defaultMessage="Work Completed By"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.datetime"
                defaultMessage="Date and time work completed"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="workorder.settings.workdone" defaultMessage="Description of work done"
              />
              <FormattedMessageWithClass className={styles.fixedfieldBox}
                id="Asset.create.photos" defaultMessage="Photos"
              />

              {maintenance.length > 0 && (
                <div className={styles.dynamicFields}>
                  <FormattedMessageWithClass tagName="h4" className={styles.sectionTitle}
                    id="assets.field.extra" defaultMessage="Additional Fields"
                  />
                  <OrderableList
                    fields={maintenance}
                    form="maintenance"
                    handleFieldClick={this.handleFieldClick}
                    handleFieldOrderChanged={onFieldOrderChanged}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <NewField onCreateField={field => onCreateField(field, fieldForm)} showIn={modal}
          onClose={() => this.toggleModal(false)} info={clickedField}
        />
      </div>
    );
  }
}

MaintenanceSchemaBuilder.defaultProps = {
  workorder: {},
  maintenance: {}
};

MaintenanceSchemaBuilder.propTypes = {
  onFieldOrderChanged: PropTypes.func.isRequired,
  onCreateField: PropTypes.func.isRequired,
  workorder: PropTypes.shape({}),
  maintenance: PropTypes.shape({})
};

export default MaintenanceSchemaBuilder;
