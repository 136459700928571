import gql from 'graphql-tag';

const CREATE_MESSAGE = gql`
  mutation createMessage(
    $fromUserId: String!
    $message: String!
    $toUserId: String!
    $airportId: String!
    $attachments: [attachmentsInput]
  ) {
    createMessage(
      input: {
        fromUserId: $fromUserId
        message: $message
        toUserId: $toUserId
        airportId: $airportId
        attachments: $attachments
      }
    ) {
      createdAt
      fromUserId
      message
      messageId
      readStatus
      fromRead
      toRead
      fromDelete
      toDelete
      toUserId
      updatedAt
      attachments {
        name
        url
        type
        size
      }
      # TODO rm unused data
      fromUser {
        firstName
        lastName
        profilePicture
        isActive
        title
        userId
        airportDetails {
          name
          code
          logo
        }
        roles {
          name
          id
          permissions {
            name
          }
        }
        airportId
      }
      toUser {
        firstName
        lastName
        profilePicture
        isActive
        title
        userId
        airportDetails {
          name
          code
          logo
        }
        roles {
          name
          id
          permissions {
            name
          }
        }
        airportId
      }
    }
  }
`;

export default CREATE_MESSAGE;
