import styled from 'styled-components';

type Props = {
  left?: boolean;
}

export const Wrapper = styled.div<Props>`
  position: absolute;
  top: -13px;
  ${props => (props.left ? 'left: 30px;' : 'right: 60px;')}

  //width: 151px;
  height: 32px;
  border-radius: 5px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  //border:  #e9eff4;
  border: solid 1px ${({ theme }) => theme.colors.border.light};
  background-color: ${({ theme }) => theme.colors.background.white};
  
  ul {
    display: flex;
    margin: unset;
    padding: unset;
    color: ${({ theme }) => theme.colors.text.default};

    li {
      cursor: pointer;
      padding: 4px 10px;
      list-style-type: none;

      :hover {
        background-color: ${({ theme }) => theme.colors.background.default};
      }
    }
  }
`;
