import React, { useMemo } from 'react';
import { moment } from '../../../../libComponents/moment';

import { DateLine, DirectMessage, PostMessage } from '../../components';
import {
  CurrentUser,
  DeleteMessagesTypes,
  MessageCollection,
  ToggleLikesFunc,
  UserType
} from '../../types';
import { ChatTypes } from '../../enums';
import { defaultMessage } from '../../state/common/assets';

type MessagesListProps = {
  messagesList: MessageCollection[];
  toggleLikes?: ToggleLikesFunc;
  deleteMessage: DeleteMessagesTypes;
  currentUser: CurrentUser;
  userDetails: UserType;
  channelId: string;
  editedMessageId: string;
  deletedMessageID: string;
  savingMessage?: boolean;
  showLoadMsg?: boolean;
  updateLoading?: boolean;
  type: ChatTypes;
};

const MessagesList = React.memo<MessagesListProps>(
  ({
    messagesList,
    deleteMessage,
    currentUser,
    toggleLikes,
    channelId,
    savingMessage,
    editedMessageId,
    deletedMessageID,
    updateLoading,
    showLoadMsg,
    type
  }) => {
    const messages = useMemo(
      () => messagesList.reduce(
        (acc: JSX.Element[], messageData: MessageCollection, index, arr) => {
          if ('fromUser' in messageData) {
            const { messageId, createdAt } = messageData;

            acc.push(
              <DirectMessage
                currentUserId={currentUser.userId}
                type={type}
                key={`${messageId} ${createdAt}`}
                messageData={messageData}
                deleteMessage={deleteMessage}
              />
            );
            return acc;
          }

          let prevMsg = arr[index - 1];
          const isPrevNew = prevMsg?.newMessage;
          if (isPrevNew) {
            prevMsg = arr[index - 2];
          }
          const prevMsgDate = moment.unix(+prevMsg?.createdAt).format('ll');
          const currentMsgDate = moment
            .unix(+messageData?.createdAt)
            .format('ll');

          if (messageData.newMessage === true) {
            const newMsgDate = moment
              .unix(+arr[index + 1].createdAt)
              .format('ll');

            acc.push(
              <DateLine
                currentMsgDate={newMsgDate}
                isNew
                key={newMsgDate}
              />
            );
            return acc;
          }
          const { postId, createdAt } = messageData;
          if (prevMsgDate !== currentMsgDate && !isPrevNew) {
            acc.push(
              <DateLine
                currentMsgDate={currentMsgDate}
                key={`${currentMsgDate}-${postId}`}
              />
            );
          }
          let isTarget: boolean;
          if ('commentId' in messageData) {
            isTarget = (messageData.commentId === editedMessageId && updateLoading)
                || messageData.commentId === deletedMessageID;
          } else {
            isTarget = (messageData.postId === editedMessageId && updateLoading)
                || messageData.postId === deletedMessageID;
          }

          acc.push(
            <PostMessage
              savingMessage={isTarget}
              type={type}
              key={`${postId} ${createdAt}`}
              toggleLikes={toggleLikes}
              channelId={channelId}
              currentUser={currentUser}
              deleteMessage={deleteMessage}
              messageData={messageData}
            />
          );

          return acc;
        },
        []
      ),
      [messagesList, deletedMessageID, editedMessageId, updateLoading, channelId, currentUser, deleteMessage, toggleLikes, type]
    );

    const PostMessageComponent = (
      <PostMessage
        savingMessage
        type={type}
        key={`${Date.now()} savingMessage`}
        channelId={channelId}
        currentUser={currentUser}
        messageData={defaultMessage}
      />
    );

    return <>{showLoadMsg ? [...messages, PostMessageComponent] : messages}</>;
  }
);

export default MessagesList;
export { MessagesList };
