import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Wrapper } from './MessagesList.styles';
import {
  CurrentUser,
  MessageCollection,
  DeleteMessagesTypes,
  ToggleLikesFunc
} from '../../types';
import { ChatTypes } from '../../enums';
import { MessagesList } from './MessagesList';
import { useGetUserDetails } from '../../graphql/hooks';
import { usePreviousState } from '../../hooks';

type MessagesListWrapperProps = {
  listHeight?: number;
  messagesList?: MessageCollection[];
  newPost?: number;
  headerMessageHeight?: number;
  type?: ChatTypes;
  hasMore: string;
  savingMessage?: boolean;
  updateLoading?: boolean;
  isEditedMessage?: boolean;
  channelId: string;
  editedMessageId?: string;
  deletedMessageID?: string;
  loadMore: () => void;
  // loadMore: FetchMoreFunc;
  toggleLikes?: ToggleLikesFunc;
  deleteMessage?: DeleteMessagesTypes;
  currentUser: CurrentUser;
};

const INITIAL_LIST_HEIGHT = {
  [ChatTypes.operations]: isEditedMessage => (isEditedMessage ? 327 + 58 : 327),
  [ChatTypes.directMessages]: isEditedMessage => (isEditedMessage ? 310 + 58 : 310),
  [ChatTypes.comment]: isEditedMessage => (isEditedMessage ? 436 + 58 : 436)
};

const MessagesListWrapper = React.memo<MessagesListWrapperProps>(
  ({
    listHeight = 0,
    messagesList = [],
    type,
    newPost,
    hasMore,
    loadMore,
    channelId,
    currentUser,
    deleteMessage,
    headerMessageHeight,
    savingMessage,
    editedMessageId,
    deletedMessageID,
    updateLoading,
    isEditedMessage,
    toggleLikes
  }) => {
    const listWrapperRef = useRef<HTMLDivElement>(null);
    const [shouldLoad, setShouldLoad] = useState(false);
    const [showLoadMsg, setShowLoadMsg] = useState(false);
    const prevHasMore = usePreviousState({
      hasMore,
      messageLength: messagesList.length
    });

    useEffect(() => {
      setShowLoadMsg(false);
    }, [messagesList.length]);

    useEffect(() => {
      if (savingMessage) {
        setShowLoadMsg(true);
      }
    }, [savingMessage]);

    const { data: userDetails } = useGetUserDetails(currentUser.userId);

    const scrollToBottom = () => {
      if (!listWrapperRef.current) return;
      const { scrollHeight, clientHeight } = listWrapperRef.current;
      listWrapperRef.current.scrollTop = scrollHeight - clientHeight;
    };

    const smoothScroll = () => {
      if (!listWrapperRef.current) return;
      const { scrollHeight } = listWrapperRef.current;
      listWrapperRef.current.scroll({
        top: scrollHeight,
        behavior: 'smooth'
      });
    };

    useEffect(() => {
      if (prevHasMore?.hasMore === hasMore) {
        scrollToBottom();
        setShouldLoad(true);
      }
    }, [channelId, prevHasMore, hasMore]);

    // TODO handle directMessages loading
    useEffect(() => {
      if (messagesList?.length > 10 || newPost === 0) return;

      scrollToBottom();
    }, [messagesList.length, newPost, messagesList]);

    useLayoutEffect(() => {
      if (prevHasMore?.hasMore === hasMore) {
        scrollToBottom();
      }
    }, [messagesList.length, hasMore, channelId, prevHasMore]);

    useLayoutEffect(() => {
      if (
        prevHasMore?.messageLength + 1 > messagesList.length
        && (savingMessage || showLoadMsg)
      ) {
        smoothScroll();
      }
    }, [messagesList.length, prevHasMore, savingMessage, showLoadMsg]);

    if (!userDetails) {
      return null;
    }

    return (
      <Wrapper
        listHeight={
          INITIAL_LIST_HEIGHT[type](isEditedMessage)
          + listHeight * 20
          + (headerMessageHeight - 103 || 0)
        }
        ref={listWrapperRef}
      >
        <InfiniteScroll
          threshold={50}
          pageStart={0}
          loadMore={shouldLoad ? loadMore : () => {}}
          hasMore={!!hasMore && shouldLoad}
          isReverse
          useWindow={false}
        >
          <>
            <MessagesList
              type={type}
              messagesList={messagesList}
              currentUser={currentUser}
              deleteMessage={deleteMessage}
              toggleLikes={toggleLikes}
              channelId={channelId}
              userDetails={userDetails}
              savingMessage={savingMessage}
              showLoadMsg={showLoadMsg}
              editedMessageId={editedMessageId}
              deletedMessageID={deletedMessageID}
              updateLoading={updateLoading}
            />
          </>
        </InfiniteScroll>
      </Wrapper>
    );
  }
);

export default MessagesListWrapper;
export { MessagesListWrapper };
