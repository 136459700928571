import gql from 'graphql-tag';

const UPDATE_DM = gql`
  mutation updateMessage(
    $messageId: String!
    $message: String!
    $attachments: [attachmentsInput]
  ) {
    updateMessage(
      input: {
        messageId: $messageId
        message: $message
        attachments: $attachments
      }
    ) {
      createdAt
      fromUserId
      message
      messageId
    }
  }
`;

export default UPDATE_DM;
