import React, { Component} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AWS from 'aws-sdk';

import { connect } from 'react-redux';
import Messages from './ChatBox';
import styles from './home.module.scss';
import image from '../../icons/aerobot.png';
import Spinner from '../../components/spinner';
import Auth from '../../utils/Auth';
import Shortcuts from '../../components/topbar/shortcuts/shortcuts';
import chatStyles from './ChatBox/ChatBox.module.scss';
import logo from '../../brand/default/favicon.png';

import { changeCurrentPage } from '../../general_redux/actions';
import Recorder from './Recorder';
import { isLocal } from '../../constants';

import Modal from 'react-bootstrap/Modal';
import {Icon} from '@iconify/react';
import mdClose from '@iconify/icons-ion/md-close';

class Home extends Component {
  state = {
    input: '',
    spinner: false,
    searchTextBoxReadOnly: false,
    messages: [],
    member: {
      color: '#f2a520',
      username: 'You'
    },
    disableVoice: false,
    isPressed: false,
    helpPressed: false,
  };

  links = [
    { url: '/', name: this.props.intl.formatMessage({ id: 'shortcuts.aerobot' }), key: 'aerobot' },
    { url: '/todo', name: this.props.intl.formatMessage({ id: 'shortcuts.todo' }), key: 'tasks' },
    { url: '/messenger', name: this.props.intl.formatMessage({ id: 'shortcuts.chat' }), key: 'messenger' },
    { url: '/settings/organization',
      name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }),
      permissions: ['can_modify_airport_settings'],
      key: 'settings' }
  ];

  lexUserId = `chatbot-demo${Date.now()}`;

  sessionAttributes = {};

  componentDidMount() {
    const { actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('home');

    // Initialize the Amazon Cognito credentials provider
    const key = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;
    AWS.config.region = process.env.REACT_APP_REGION; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      Logins: {
        [key]: Auth.getInstance().getIDToken()
      }
    });

    this.botName = `${process.env.REACT_APP_PREFIX}Aerobot`;
    this.lexruntime = new AWS.LexRuntime();
    this.host = isLocal ? process.env.REACT_APP_EXTERNAL_BACKEND_HOST
      : `https://backend.${window.location.hostname}/api`;
    // FOR DEBUGGING
    // this.host = "https://backend.us.dev.aerosimple.com/api";
    this.sessionAttributes = {
      token: Auth.getInstance().getAuthToken(),
      env: 'dev',
      host: this.host
    };
  }

  onInputChange = (e) => {
    this.setState({
      input: e.target.value
    });
  }

  pushChat = (event) => {
    event.preventDefault();
    const { input } = this.state;
    this.onSendMessage(input, 'human', 'text');
    this.setState({ spinner: true });
    if (input !== '') {
      // send it to the Lex runtime
      const params = {
        botAlias: '$LATEST',
        botName: this.botName,
        inputStream: input,
        contentType: 'text/plain; charset=utf-8',
        accept: 'text/plain; charset=utf-8'
      };
      this.aeroBotRes(params, 'text');
    }
    if (input === '') {
      this.setState({ input: '', disableVoice: false, spinner: false });
    }
  }

  aeroBotRes = (params, searchType) => {
    // eslint-disable-next-line no-param-reassign
    params.sessionAttributes = this.sessionAttributes;
    // eslint-disable-next-line no-param-reassign
    params.userId = this.lexUserId;
    this.setState({ input: '', disableVoice: true, spinner: true });
    this.lexruntime.postContent(params, (err, data) => {
      if (data) {
        // capture the sessionAttributes for the next cycle
        let response = {};
        if (data.messageFormat === 'CustomPayload') {
          response = JSON.parse(data.message);
        } else {
          response = data;
          response.link = data.sessionAttributes.link;
          if (searchType === 'voice') {
            this.onSendMessage(response.inputTranscript, 'human', 'text');
            this.setState({
              searchTextBoxReadOnly: false
            });
          }
          const botMessages = [];
          if (data.dialogState === 'ReadyForFulfillment' || data.dialogState === 'Fulfilled' || data.dialogState === 'ElicitSlot') {
            try {
              const messages = JSON.parse(response.message);
              if (messages.messages) {
                messages.messages.forEach((message) => {
                  const msg = message;                
                  botMessages.push({ message: msg.message,
                    type: msg.type,
                    link: msg.link,
                    dis_message: msg.dis_message?msg.dis_message:msg.message,
                    options: msg.options?msg.options: null
                  });
                });
              } else if (messages.type === 'link_options') {
                if (messages.inspectionType && messages.inspectionType !== 'null') {
                  localStorage.setItem('inspectionFilter', messages.inspectionType);
                } else {
                  localStorage.removeItem('inspectionFilter');
                }
                botMessages.push({ 
                  message: messages.message,
                  type: messages.type,
                  link: '',
                  dis_message: messages.message,
                  dateOptions: messages.dateOptions?messages.dateOptions: null
                });
              } else {
                if (messages.type === 'link') {
                  if (messages.fdate) {
                    localStorage.setItem('startdate', messages.fdate);
                    localStorage.setItem('enddate', messages.tdate);
                    if (messages.inspectionType && messages.inspectionType !== 'null') {
                      localStorage.setItem('inspectionFilter', messages.inspectionType);
                    } else {
                      localStorage.removeItem('inspectionFilter');
                    }
                    localStorage.removeItem('range');
                  } else if (messages.operationfdate) {
                    localStorage.setItem('log_start_date', messages.operationfdate);
                    localStorage.setItem('log_end_date', messages.operationtdate);
                    localStorage.removeItem('log_range');
                  } else if (messages.todotasks) {
                    localStorage.setItem('sortbytasks', messages.todotasks);
                  } else if (messages.tasks) {
                    localStorage.setItem('tasks', messages.tasks);
                  } else if (messages.workorders) {
                    localStorage.setItem('aerobot_workorders', messages.workorders);
                    if (messages.workorders === 'Maintenance,,') {
                      localStorage.setItem('workorder_filters', JSON.stringify(['Maintenance']));
                    } else if (messages.workorders === 'Operations,,') {
                      localStorage.setItem('workorder_filters', JSON.stringify(['Operations']));
                    }
                  }
                }
                botMessages.push({ message: messages.message,
                  type: messages.type,
                  link: messages.link,
                  dis_message: messages.dis_message?messages.dis_message:messages.message,
                });
              }
            } catch (e) {
              const { message } = response;
              botMessages.push({ message, type: 'text', link: '' });
            }
          } else {
            botMessages.push({ message: response.message, type: 'text', link: '' });
          }
          this.onSendMessage(botMessages, 'AeroBot', response.intentName);
        }
        this.sessionAttributes = data.sessionAttributes;
        this.setState({ input: '', spinner: false, disableVoice: false });
      }
      if (err) {
        const botMessages =[];
        botMessages.push({ message: "Sorry, I don't think I know the answer to that at the moment", type: 'text', link: '' });
        this.onSendMessage(botMessages, 'AeroBot');
        this.setState({ input: '', spinner: false, disableVoice: false });
      }
    });
  }

  changeSearchTextFieldReadOnlyStatus = (status) => {
    this.setState({
      searchTextBoxReadOnly: status
    });
  }

  onSendMessage = (message, userType, linkTitle='') => {
    const { messages, member } = this.state;
    if (userType === 'human') {
      messages.push({
        text: message,
        user: 'human',
        member,
        linkTitle
      });
    } else {
      messages.push({
        text: message,
        user: 'AeroBot',
        member: {
          color: '#e22b4b',
          username: 'AeroBot'
        },
        linkTitle
      });
    }
    this.setState({ messages });
  }

  selectMessage(message) {
    const params = {
      botAlias: '$LATEST',
      botName: this.botName,
      inputStream: message,
      contentType: 'text/plain; charset=utf-8',
      accept: 'text/plain; charset=utf-8'
    };
    this.aeroBotRes(params, 'text');
  }

  helpClick(option) {
    let isPressed = this.state.isPressed;
    let helpPressed = this.state.helpPressed;
    this.setState({ isPressed: !isPressed, helpPressed: !helpPressed });
    this.setState({ input: option });
  }
  
  render() {
    const { intl: { formatMessage } } = this.props;
    const { spinner, input, messages, member, searchTextBoxReadOnly, disableVoice, isPressed, helpPressed } = this.state;
    return (
      <>
        <Shortcuts links={this.links} />
        <div className={styles.home}>
          <div className={styles.content}>          
            <div className={styles.botInfo}>                   
              <h2>              
                <img src={logo} alt="logo" style={{width: '6%', marginRight: '4px', marginBottom: '7px'}}/>              
                AeroBot
              </h2>
              <img src={image} alt="aerobot" style={{width: '100%'}}/>              
            </div>     
              <p className={chatStyles.bot} style={{marginRight:'312px'}}>Hi, {member.username}</p>
              <p className={chatStyles.bot}>My name is Aerosimple Bot and with my help you can do a lot of things. Press 
                START and ask your question.
              </p>
            <Modal size="lg" show={isPressed} style= {{top:"2rem"}} centered dialogClassName={styles.modalContent}>            
              <Modal.Header className={styles.chatHeader}>                
               <div className={styles.title}>
                  <img src={logo} alt="logo" style={{width: '6%', marginRight: '4px', marginBottom: '7px'}}/> AeroBot
               </div>                
                <Icon icon={mdClose} onClick={() => {this.setState({isPressed:!isPressed});}} height="30" color="white" style={{cursor:"pointer"}} />
              </Modal.Header>               
               <Modal.Body className={styles.chatBody}>               
                  <div className={styles.response}>
                    <Messages
                      messages={messages}
                      currentMember={member}
                      selectMessage={message => this.selectMessage(message)}
                    />
                  </div>
                  <Spinner active={spinner} className={styles.spinner} />
                </Modal.Body>
                <form onSubmit={this.pushChat}> 
                    <Modal.Footer className={styles.chatFooter}>
                        <input
                          onChange={(event) => { this.onInputChange(event); return true; }}
                          type={chatStyles.text}
                          value={input}
                          placeholder={formatMessage({ id: 'aerobot.placeholder' })} 
                          className={styles.AeroBotInput}
                          readOnly={searchTextBoxReadOnly}
                        />
                        <span
                          className={chatStyles.avatar}
                          style={{backgroundColor: member.color}}
                        />
                        {!disableVoice 
                          ? (
                            <Recorder
                              changeSearchTextFieldReadOnlyStatus={status => this.changeSearchTextFieldReadOnlyStatus(status)}
                              aeroBotRes={(params, searchType) => this.aeroBotRes(params, searchType)}
                            />)
                          : ''
                        }   
                      </Modal.Footer>                      
                  </form>                                          
                </Modal>        
              <Modal size="sm" show={helpPressed} style= {{width:"35rem", left: "inherit", top:"20%", right: '3%'}} centered dialogClassName={`${styles.modalContent} ${styles.helpModal}`}>          
                <Modal.Body className={styles.chatBody} style={{borderRadius: "27px", boxShadow:"0px 6px 10px #365DAD45"}}>
                  <Modal.Header>
                    <div className={styles.helpTitle}>What can I ask AeroBot?</div>
                    <Icon icon={mdClose} onClick={() => {this.setState({helpPressed:!helpPressed});}} height="30" color="gray" style={{cursor:"pointer"}} />
                  </Modal.Header>
                  <ul style={{paddingLeft: '15px', margin: '8px'}}>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("View all inspections")}}>View all inspections?</li>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("View all work orders")}}>View all work orders?</li>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("View all work orders in the last month")}}>View all work orders in the last month?</li>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("View assets on a map")}}>View assets on a map?</li>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("Create new operations log")}}>Create new operations log</li>
                    <li className={chatStyles.bot} onClick={() => {this.helpClick("Create new task")}}>Create new task</li>
                  </ul>
                </Modal.Body>                
              </Modal>    
                <button className={styles.start} type="submit" onClick={() => {this.setState({isPressed:!isPressed})}}>Start</button>                       
                <button className={styles.help} type="submit" onClick={() => {this.setState({helpPressed:!helpPressed})}}>?</button> 
          </div>                     
        </div>
      </>
    );
  }
}

Home.propTypes = {
  intl: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  currentModule: state.general.currentModule
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Home));
