import styled from 'styled-components';
import { ChatTypes } from '../../enums';

type WrapperProps = {
  isGrey: boolean;
  viewType: ChatTypes;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px
    ${({ viewType }) => (viewType === ChatTypes.comment ? '7px' : '4px')};
  border: 1px solid ${({ theme }) => theme.colors.background.darker};

  background-color: ${({ theme, isGrey }) => (isGrey ? theme.colors.background.darker : 'unset')};

  &_users, .chat-name {
    display: flex;
  }

  img {
    cursor: pointer;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;

  button {
    cursor: pointer;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin-right: 12px;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.text.darkest};
    letter-spacing: 0.46px;
    outline: none;
    &:after {
      position: relative;
      content: "";
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      border-right: 0.15rem solid ${({ theme }) => theme.colors.text.inverse};
      border-top: 0.15rem solid ${({ theme }) => theme.colors.text.inverse};
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      margin-left: 0.5rem;
      top: -2px;
    }
  }

  .dropdown {
    position: absolute;
    top: 50px;
    right: 1%;
    margin: 0;
    color: ${({ theme }) => theme.colors.text.darkest};
    background: ${({ theme }) => theme.colors.text.inverse};
    list-style: none;
    padding: 0;
    border-radius: 0 0 4px 4px;
    max-height: 0;
    width: 100px;
    transition: max-height 0.1s ease-in-out;
    overflow: hidden;
    &.open {
      max-height: 180px;
      z-index: 9;
    }

    .item {
      cursor: pointer;
      padding: 7px 10px;
      &:hover {
        font-weight: bold;
        background: ${({ theme }) => theme.colors.background.darkest};
      }
      & a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text.darkest};
        letter-spacing: 0.46px;
        font-size: 13px;
      }
    }
  }
  img {
    border-radius: 50%;
    margin-right: 8px;
    width: 45px;
    float: left;
    height: 45px;
  }
`;
