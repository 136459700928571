import { PostsState } from './types';

export const initialState = (): PostsState => ({
  newPosts: null,
  postList: {
    items: [],
    nextToken: null
  },
  topicsCollectionCount: null,
  topicsConnected: false,
  editedPost: null,
  channelData: {
    channelId: null,
    listUserChannels: null,
    channelName: null
  },
  loading: false,
  deletedPostID: null
});
