import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Collapsible from '../../../components/collapsible/Collapsible';

import styles from './change_password.module.scss';


const ForceChangePasswordForm = ({
  handleInputChange,
  handleForcedPasswordChange,
  checkPasswordMatch,
  newPasswordEmpty,
  confirmPasswordEmpty,
  checkPinMatch,
  passwordMatches,
  checkEmptyFields,
  checkLengthFields,
  pinMatches,
  newPinEmpty,
  newPinLength,
  confirmPinLength,
  confirmPinEmpty,
  InvalidPin,
  invalidPassword
}) => (
  <form name="forceResetPasswordForm" className={styles.form} onSubmit={handleForcedPasswordChange} noValidate>
    <div className={styles.header}>
      <FormattedMessage id="authentication.force_reset_password.header" defaultMessage="Reset your password" />
    </div>
    <div>
      <FormattedMessage id="authentication.force_reset_password.info" defaultMessage="You need to set a new password to complete the sign up process" />
    </div>
    <label htmlFor="newPassword">
      <FormattedMessage id="authentication.force_reset_password.new_password" defaultMessage="New password" />
      <input
        type="password"
        name="newPassword"
        onChange={(e) => { handleInputChange('newPassword', e.target.value); }}
        onBlur={(e) => { checkEmptyFields(e); }}
      />
    </label>
    {newPasswordEmpty ? null: (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
        </small>
      </div>)}
    <label htmlFor="confirmPassword">
      <FormattedMessage id="authentication.force_reset_password.confirm_password" defaultMessage="Confirm password" />
      <input
        type="password"
        name="confirmPassword"
        onChange={(e) => { handleInputChange('confirmPassword', e.target.value); }}
        onBlur={(e) => { checkPasswordMatch(e.target.value); checkEmptyFields(e); }}
      />
    </label>
    {confirmPasswordEmpty ? null: (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
        </small>
      </div>)}
    {passwordMatches ? null : (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.force_reset_password.password_mismatch" defaultMessage="Password and confirmation do not match" />
        </small>
      </div>)}
    {invalidPassword ? (
      <div className={`${styles.error} ${styles.invalidPassword}`}>
        <small>
          <FormattedMessage id="authentication.force_reset_password.invalid_password" defaultMessage="The password is invalid" />
        </small>
      </div>
    ) : null}
    <Collapsible title="password_policy.title" styleClasses={styles.profile} autoheight={false}>
      <span style={{ marginTop: 8 }}>
        <FormattedMessage id="password_policy.intro" defaultMessage="The password must contain all of the following characteristics" />
      </span>
      <ul>
        <li>
          <FormattedMessage id="password_policy.length" defaultMessage="At least 8 characters" />
        </li>
        <li>
          <FormattedMessage id="password_policy.uppercase" defaultMessage="At least one uppercase character" />
        </li>
        <li>
          <FormattedMessage id="password_policy.lowercase" defaultMessage="At least one lowercase character" />
        </li>
        <li>
          <FormattedMessage id="password_policy.special_char" defaultMessage="At least one special character" />
        </li>
        <li>
          <FormattedMessage id="password_policy.numbers" defaultMessage="At least one number" />
        </li>
      </ul>
    </Collapsible>
    <div style={{ marginTop: 20 }}>
      <FormattedMessage id="authentication.force_reset_pin.info" defaultMessage="You need to set a PIN for use in the Aerosimple Navigator app." />
    </div>
    <label htmlFor="newPin">
      <FormattedMessage id="authentication.force_change_pin.new_pin" defaultMessage="New pin" />
      <input
        type="password"
        name="newPin"
        maxLength="4"
        onBlur={(e) => { checkEmptyFields(e); checkLengthFields(e); }}
        onChange={(e) => { handleInputChange('newPin', e.target.value); }}
      />
    </label>
    {newPinEmpty ? null: (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
        </small>
      </div>)}
    {newPinLength ? null : (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.pin_minlen" defaultMessage="The Minimum length of this field is 4" />
        </small>
      </div>
    )}
    <label htmlFor="confirmPin">
      <FormattedMessage id="authentication.force_change_pin.confirm_password" defaultMessage="Confirm pin" />
      <input
        type="password"
        name="confirmPin"
        maxLength="4"
        onChange={(e) => { handleInputChange('confirmPin', e.target.value); }}
        onBlur={(e) => {
          checkEmptyFields(e);
          checkLengthFields(e);
          checkPinMatch(e.target.value);
        }}
      />
    </label>
    {confirmPinEmpty ? null : (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.password_empty" defaultMessage="This field cannot be empty" />
        </small>
      </div>)}
    {confirmPinLength ? null : (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.login.error.pin_minlen" defaultMessage="The Minimum length of this field is 4" />
        </small>
      </div>
    )}
    {pinMatches ? null : (
      <div className={styles.error}>
        <small>
          <FormattedMessage id="authentication.force_change_pin.pin_mismatch" defaultMessage="Pin and confirmation do not match" />
        </small>
      </div>
    )}
    {InvalidPin? (
      <div className={`${styles.error} ${styles.invalidPassword}`}>
        <small>
          <FormattedMessage id="authentication.login.error.invalid_pin" defaultMessage="The new pin is invalid" />
        </small>
      </div>
    ) : null}
    <Collapsible title="pin_policy.title" defaultMessage="Pin Policy" styleClasses={styles.profile} autoheight={false}>
      <span style={{ marginTop: 8 }}>
        <FormattedMessage id="pin_policy.intro" defaultMessage="The pin must contain all of the following characteristics" />
      </span>
      <ul>
        <li>
          <FormattedMessage id="pin_policy.length" defaultMessage="Must be 4 digits." />
        </li>
      </ul>
    </Collapsible>
    <div className={styles.button_wrapper}>
      <button
        type="submit"
        className={styles.button}
      >
        <FormattedMessage id="authentication.force_reset_password.change_password" defaultMessage="Change password" />
      </button>
    </div>
  </form>
);


ForceChangePasswordForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleForcedPasswordChange: PropTypes.func.isRequired,
  checkPasswordMatch: PropTypes.func.isRequired,
  checkPinMatch: PropTypes.func.isRequired,
  checkEmptyFields: PropTypes.func.isRequired,
  checkLengthFields: PropTypes.func.isRequired,
  passwordMatches: PropTypes.bool.isRequired,
  pinMatches: PropTypes.bool.isRequired,
  newPinEmpty: PropTypes.bool.isRequired,
  newPinLength: PropTypes.bool.isRequired,
  confirmPinLength: PropTypes.bool.isRequired,
  confirmPinEmpty: PropTypes.bool.isRequired,
  InvalidPin: PropTypes.bool.isRequired,
  invalidPassword: PropTypes.bool.isRequired,
  newPasswordEmpty: PropTypes.bool.isRequired,
  confirmPasswordEmpty: PropTypes.bool.isRequired
};

export default ForceChangePasswordForm;
