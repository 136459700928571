import React, { useMemo } from 'react';
import { Menu } from 'semantic-ui-react';
import {
  ActiveChannelFunc,
  MenuItemTypes,
  MessengerModeTypes,
  NewPostsType
} from '../../../types';
import { NewMessageCounter } from './NewMessageCounter';
import { Avatar } from '../../../../../components/avatar';
import { ChatTypes } from '../../../enums';

type MenuItemProps = {
  item: MenuItemTypes;
  handleActiveChannel: ActiveChannelFunc;
  newPosts?: NewPostsType;
  channelId?: string;
  type: MessengerModeTypes;
};

const isActiveChat = (isEqual: boolean): string | null => {
  if (isEqual) {
    return 'active-chat';
  }
  return null;
};

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  handleActiveChannel,
  channelId,
  newPosts,
  type
}) => {
  const { itemTitle, itemId } = useMemo(() => {
    if (type === ChatTypes.operations) {
      const {
        channelId: id,
        channels: { name }
      } = item[type];
      return { itemTitle: name, itemId: id };
    }

    const {
      roomId,
      fromUser: { firstName, lastName, profilePicture: profilePic }
    } = item[type];
    return {
      itemTitle: `${firstName} ${lastName}`,
      itemId: roomId,
      profilePic
    };
  }, [item, type]);

  const userAvatar: () => JSX.Element = () => {
    if (type !== ChatTypes.directMessages) {
      return null;
    }

    const {
      fromUser: { profilePicture, isActive }
    } = item[type];
    return <Avatar src={profilePicture} isActive={isActive} />;
  };

  return (
    <Menu.Item
      as="div"
      onClick={() => handleActiveChannel(itemTitle, itemId)}
      className={isActiveChat(itemId === channelId)}
    >
      {userAvatar()}
      {itemTitle}
      <NewMessageCounter
        messageCount={newPosts?.[itemId]?.length ?? null}
        isRead={channelId === itemId}
      />
    </Menu.Item>
  );
};

export { MenuItem };
