import {GraphQLError} from "graphql";
import { useCallback } from "react";
import { useMutation } from "../../../graphql/hooks";
import CREATE_CHANNEL from "../mutations/createChannel";
import { useRDetails } from "./useRDetails";

type UseCreateChanel = {
  (): {
    error: GraphQLError;
    createChanel: ({ ...rest }: any, push: any) => void;
    saving: boolean;
  };
};

export const useCreateChanel: UseCreateChanel = () => {
  const {
    error: closeError,
    mutate: closeGQL,
    saving: closeSaving
  } = useMutation(CREATE_CHANNEL);
  const { userId, airportId } = useRDetails();

  const withIDs = useCallback(
    ({ ...rest }, push) => {
      closeGQL({
        variables: {
          userId,
          airportId,
          ...rest
        }
      }).then(() => {
        push("/messenger/channels");
      });
    },
    [closeGQL, userId, airportId]
  );

  return {
    error: closeError,
    createChanel: withIDs,
    saving: closeSaving
  };
};
