import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';

import SelectInput from '../SelectInput';

import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import styles from './fieldWidget.module.scss';

class FieldWidget extends Component {
  constructor(props) {
    super(props);
    const { formatMessage } = this.props.intl;

    this.fieldOptions = [
      { key: 'string', name: formatMessage({ id: 'inspections.step2.newField.text' }) },
      { key: 'number', name: formatMessage({ id: 'inspections.step2.newField.number' }) },
      { key: 'boolean', name: formatMessage({ id: 'inspections.step2.newField.checkbox' }) },
      { key: 'date', name: formatMessage({ id: 'inspections.step2.newField.date' }) },
      { key: 'select', name: formatMessage({ id: 'inspections.step2.newField.selection' }) },
      { key: 'dataSource', name: formatMessage({ id: 'inspections.step2.newField.dataSource' }) }
    ];

    this.requiredOptions = [
      { key: 'required', name: formatMessage({ id: 'inspections.step2.newField.mandatory' }) },
      { key: 'optional', name: formatMessage({ id: 'inspections.step2.newField.optional' }) }
    ];

    this.availableWidgets = {
      string: [
        { key: 'charfield', name: formatMessage({ id: 'inspections.step2.newField.text' }) },
        { key: 'textfield', name: formatMessage({ id: 'inspections.step2.newField.textarea' }) }
      ],
      select: [
        { key: 'dropdown', name: formatMessage({ id: 'inspections.step2.newField.dropdown' }) },
        { key: 'radio', name: formatMessage({ id: 'inspections.step2.newField.radio' }) }
      ],
      dataSource: [
        { key: 'dropdown', name: formatMessage({ id: 'inspections.step2.newField.dropdown' }) },
        { key: 'radio', name: formatMessage({ id: 'inspections.step2.newField.radio' }) }
      ]
    };
    const sources = props.allDataSources.map(
      e => ({ id: e.name, key: e.title, name: e.title })
    );
    if (props.info) {
      const select_source = sources.filter(e => (e.id === props.info.dataSourceID));
      this.state = {
        fieldType: this.getFieldType(props.info),
        fieldRequired: props.info.required ? this.requiredOptions[0] : this.requiredOptions[1],
        fieldWidget: props.info.widget && this.availableWidgets[
          props.info.type].find(e => e.key === props.info.widget.type),
        name: props.info.title,
        dropdownOptions: props.info.values ? props.info.values.map(e => e.value) : [],
        datetime: (props.info.type === 'datetime'),
        multiselect: (props.info.type === 'multiselect' || props.info.type === 'dataSourceMultiselect'),
        addInfo: props.inspections && props.info.addInfo ? props.info.addInfo: '',
        search_enable: props.inspections && props.info.search_enable ? props.info.search_enable: '',
        dataSources: sources,
        dataSourceType: select_source.length > 0 && select_source[0]
      };
    } else {
      this.state = {
        fieldType: this.fieldOptions[0],
        fieldRequired: this.requiredOptions[0],
        fieldWidget: this.availableWidgets[this.fieldOptions[0].key][0],
        name: '',
        dropdownOptions: [],
        datetime: false,
        multiselect: false,
        addInfo: props.inspections ? false : '',
        search_enable: props.inspections ? false : '',
        dataSources: sources,
        dataSourceType: sources[0]
      };
    }
    this.updateFieldValue();
  }

  getFieldType(info) {
    if (info) {
      switch (info.type) {
        case 'datetime':
          return this.fieldOptions.find(e => e.key === 'date');
        case 'multiselect':
          return this.fieldOptions.find(e => e.key === 'select');
        case 'dataSourceMultiselect':
          return this.fieldOptions.find(e => e.key === 'dataSource');
        default:
          return this.fieldOptions.find(e => e.key === info.type);
      }
    }
    return undefined;
  }

  updateFieldValue() {
    const fieldInfo = this.state;
    const { onChangeField, info, inspections } = this.props;
    const ret = {
      id: info && info.id ? info.id : '',
      type: fieldInfo.fieldType.key,
      title: fieldInfo.name,
      required: (fieldInfo.fieldRequired.key === 'required')
    };
    if (inspections) {
      ret.addInfo = fieldInfo.addInfo;
      ret.search_enable = fieldInfo.search_enable;
    }

    if (ret.type === 'select') {
      ret.values = fieldInfo.dropdownOptions.map((o, i) => ({ key: `${i}`, value: o }));
      if (fieldInfo.multiselect) {
        ret.type = 'multiselect';
      } else if (!fieldInfo.fieldWidget) {
        const [firstWidget] = this.availableWidgets.select;
        fieldInfo.fieldWidget = firstWidget;
      }
    }
    if (ret.type === 'dataSource') {
      if (fieldInfo.multiselect) {
        ret.type = 'dataSourceMultiselect';
      } else if (!fieldInfo.fieldWidget) {
        const [firstWidget] = this.availableWidgets.select;
        fieldInfo.fieldWidget = firstWidget;
      }
      ret.dataSourceID = fieldInfo.dataSourceType && fieldInfo.dataSourceType.id;
    }
    if (ret.type === 'date' && fieldInfo.datetime) {
      ret.type = 'datetime';
    }
    if (this.availableWidgets[ret.type]) {
      ret.widget = { type: fieldInfo.fieldWidget.key };
    }
    onChangeField(ret);
  }

  handleChange(t, v) {
    if (t === 'fieldType') {
      this.setState({
        [t]: v,
        multiselect: false,
        datetime: false,
        fieldWidget: this.availableWidgets[v.key] && this.availableWidgets[v.key][0]
      }, this.updateFieldValue);
    } else {
      this.setState({ [t]: v }, this.updateFieldValue);
    }
  }

  render() {
    const {
      fieldType,
      fieldRequired,
      fieldWidget,
      name,
      dropdownOptions,
      datetime,
      addInfo,
      search_enable,
      multiselect,
      dataSourceType,
      dataSources
    } = this.state;
    const {
      error,
      inspections
    } = this.props;
    return (
      <div className={styles.fieldWidget}>
        <div className={styles.col}>
          <label htmlFor="Name">
            <font style={{ color: 'red' }}>*</font>
            <span className={styles.selectStyle}><FormattedMessage id="inspections.step2.newField.name" defaultMessage="FieldName" /></span>
            <input name="Name" className={styles.spaceStyle} defaultValue={name} onChange={e => this.handleChange('name', e.nativeEvent.target.value)} />
          </label>
          <div className={styles.errors}>
            {error !== '' && <FormattedMessage tagName="p" id={error} defaultMessage="Field has empty values." />}
          </div>
          <label htmlFor="Type">
            <span className={styles.FieldStyle}><FormattedMessage id="inspections.step2.newField.type" defaultMessage="Field Type" /></span>

            <SelectInput value={fieldType} options={this.fieldOptions}
              onChange={e => this.handleChange('fieldType', e)}
            />
          </label>
          { fieldType.key === 'dataSource' && dataSources && (
            <label htmlFor="widget">
              <span style={{ padding: '6px' }}>
                <FormattedMessage id="inspections.step2.newField.select_source" defaultMessage="Select Source" />
              </span>
              <SelectInput style={{ margin: '10px' }} value={dataSourceType || {}} options={dataSources}
                onChange={e => this.handleChange('dataSourceType', e)}
              />
            </label>
          )}
          {(fieldType.key === 'select' || fieldType.key === 'dataSource') && (
            <div className={styles.inlineCheckbox}>
              <label>
                <FormattedMessage id="inspections.step2.newField.invalid" defaultMessage="Allow multiple selection" />
                <input defaultChecked={multiselect} type="checkbox" className={styles.inputStyle}
                  onChange={e => this.handleChange('multiselect', e.nativeEvent.target.checked)}
                />
              </label>
            </div>
          )}
        </div>
        <div className={styles.col}>
          <label htmlFor="required">
            <span className={styles.FieldStyle}><FormattedMessage id="inspections.step2.newField.required" defaultMessage="Field Is" /></span>
            <SelectInput className={styles.selectStyle} value={fieldRequired} options={this.requiredOptions}
              onChange={e => this.handleChange('fieldRequired', e)}
            />
          </label>
          {fieldType.key === 'date' && (
            <div className={styles.showTime}>
              <label>
                <FormattedMessage id="inspections.step2.newField.time" defaultMessage="Show time" />
                <input defaultChecked={datetime} type="checkbox" className={styles.inputStyle}
                  onChange={e => this.handleChange('datetime', e.nativeEvent.target.checked)}
                />
              </label>
            </div>
          )}
          <br />
          { this.availableWidgets[fieldType.key] && !multiselect && (
            <label htmlFor="widget">
              <span style={{ padding: '6px' }}><FormattedMessage id="inspections.step2.newField.widget" defaultMessage="Field Widget" /></span>
              <SelectInput style={{ margin: '10px' }} value={fieldWidget || {}} options={this.availableWidgets[fieldType.key]}
                onChange={e => this.handleChange('fieldWidget', e)}
              />
            </label>
          )}
        </div>
        {fieldType.key === 'select' && (
        <div className={styles.extra}>
          <label htmlFor="Name">
            <FormattedMessage id="inspections.step2.newField.dwnOptions" defaultMessage="Dropdown options" />
            <TagsInput value={dropdownOptions} onlyUnique inputProps={{ placeholder: 'add option' }}
              onChange={tags => this.handleChange('dropdownOptions', tags)} addKeys={[9, 13, 188]}
            />
          </label>

        </div>
        )}
        { inspections && (
          <div className={styles.inlineCheckbox}>
            <label>
              <FormattedMessage id="inspections.step2.newField.addInfo" defaultMessage="Show field on print template?" />
              <input defaultChecked={addInfo} type="checkbox" className={styles.inputStyle}
                onChange={e => this.handleChange('addInfo', e.nativeEvent.target.checked)}
              />
            </label>
          </div>
        )}
        { inspections && fieldType.key !== 'string' && fieldType.key !== 'text' && (
          <div className={styles.inlineCheckbox}>
            <label>
              <FormattedMessage id="inspections.step2.newField.search_enable" defaultMessage="Show this field in Filter?" />
              <input defaultChecked={search_enable} type="checkbox" className={styles.inputStyle}
                onChange={e => this.handleChange('search_enable', e.nativeEvent.target.checked)}
              />
            </label>
          </div>
        )}
      </div>
    );
  }
}

FieldWidget.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  info: PropTypes.shape({
    type: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object),
    widget: PropTypes.shape({
      type: PropTypes.string
    })
  })
};

FieldWidget.defaultProps = {
  info: undefined
};

export default injectIntl(FieldWidget);
