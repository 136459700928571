import { ApolloError } from 'apollo-client';
import { useQuery } from '../../../graphql/hooks';
import { AirportUsersListVar, UsersArray, UserType } from '../../types';
import LIST_AIRPORT_USERS from '../queries/listAirportUsers';
import { useRDetails } from './useRDetails';

type UseGetListUsers = {
  (): { usersData: UserType[]; error: ApolloError; loading: boolean };
};

export const useGetListUsers: UseGetListUsers = () => {
  const { airportId, userId } = useRDetails();

  const { data, error, loading } = useQuery<UsersArray, AirportUsersListVar>(
    LIST_AIRPORT_USERS,
    {
      variables: {
        airportId,
        limit: 100,
        nextToken: null
      }
    }
  );

  return {
    usersData:
      (data
        && data.listUsers.items.filter(user => !(user.userId === userId)))
      || [],
    error,
    loading
  };
};
