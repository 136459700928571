import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { injectIntl } from 'react-intl';
import Forbidden from './Forbidden';
import { changeCurrentPage } from '../general_redux/actions';
import Shortcuts from '../components/topbar/shortcuts/shortcuts';
import WildLifeLogList from './wildlife-logs/List/WildLifeLogList';
import WildLifeLogDashboard from './wildlife-logs/DashBoard/WildLifeLogDashboard';
import WildLifeLogCreate from './wildlife-logs/Create/WildLifeLogCreate';
import WildLifeLogBuilder from './wildlife-logs/Builder/WildLifeLogBuilder';
import Detail from './wildlife-logs/Detail/WildlifeLogDetail';

class WildLifeLayout extends Component {
  links = [
    { url: '/wildlife/dashboard', name: this.props.intl.formatMessage({ id: 'shortcuts.wildlife.dashboard' }), key: 'Dashboard', permissions: ['view_wildlifelogs'] },
    { url: '/wildlife', name: this.props.intl.formatMessage({ id: 'shortcuts.wildlife' }), key: 'WildLife Log', permissions: ['view_wildlifelogs'] },
    { url: 'https://wildlife.faa.gov/add', redirect: true, name: this.props.intl.formatMessage({ id: 'shortcuts.wildlife.FAAStrikeReport' }), key: 'FAA Strike Report', permissions: ['add_wildlifelogs'] },
    { url: '/wildlife/settings',
      name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }),
      key: 'settings',
      permissions: ['can_modify_airport_settings'] }
  ];


  componentDidMount() {
    const { actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('Wildlife');
  }

  render() {
    const { match, user } = this.props;
    return (
  <>
    {user && user.airport_permissions && user.airport_permissions.modules.filter(type => type.name === 'Wildlife Log').length > 0 &&
    user.airport_permissions.modules.filter(type => type.name === 'Wildlife Log')[0].enabled === 'true' && (
      <Shortcuts links={this.links} />
    )}
    <Switch>
      <Route exact path={`${match.url}/settings`} component={WildLifeLogBuilder} />
      <Permissions allowed={['view_wildlifelogs']} fallbackElement={<Forbidden />}>
        <Route exact path={`${match.url}/dashboard`} component={WildLifeLogDashboard} />
        <Route exact path={`${match.url}`} component={WildLifeLogList} />
        <Route exact path={`${match.url}/create`} component={WildLifeLogCreate} />
        <Route exact path={`${match.url}/:id/detail`} component={Detail} />
      </Permissions>
    </Switch>
  </>
    );
  }
}

WildLifeLayout.propTypes = {
  match: PropTypes.shape({}).isRequired,
  actionUpdateCurrentPage: PropTypes.func
};

const mapStateToProps = state => ({
  currentModule: state.general.currentModule,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WildLifeLayout));
