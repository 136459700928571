/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Input, Row, Table } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loading from '../../../../components/loading';
import Modal from '../../../../components/modal';
import CloseIcon from '../../../../icons/close.svg';
import { fetchRunwayConditions, fetchAirportRunways, updateRunwayConditions, clear } from '../../redux/action';
import styles from './runway.module.scss';

const RunwayCondition = ({
  editAction,
  fetchAction,
  actionClear,
  runwayConditions,
  actionFetchRunwayConditions,
  actionFetchAirportRunways,
  actionEditRunway
}) => {
  const [runways, setRunways] = useState([]);
  const [runway, setRunway] = useState({});
  const [loading, setLoading] = useState(false);
  const [runwayModal, setRunwayModal] = useState(false);

  useEffect(() => {
    async function AirportRunways() {
      await actionFetchAirportRunways();
    }
    async function RunwayConditions() {
      await actionFetchRunwayConditions();
    }
    AirportRunways();
    RunwayConditions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchAction.success) {
      setRunways(runwayConditions);
      actionClear();
    }
  }, [runwayConditions, fetchAction]);

  useEffect(() => {
    if (editAction.success) {
      setLoading(false);
      actionFetchRunwayConditions();
      actionClear();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAction]);

  const handleRowClick = (RWY) => {
    setRunway(RWY);
    setRunwayModal(true);
  };

  const toggleRunwayModal = () => {
    setRunwayModal(!runwayModal);
  };

  const handleChange = (e) => {
    setRunway({ ...runway, [e.target.name]: e.target.value });
  };


  const saveRunwayConditions = () => {
    setLoading(true);
    const data = runway;
    data.td = parseInt(runway.td);
    data.mp = parseInt(runway.mp);
    data.ro = parseInt(runway.ro);
    // eslint-disable-next-line no-unneeded-ternary
    data.status = runway.status === 'true' ? true : false;
    actionEditRunway(runway.id, data);
    setRunwayModal(false);
  };
  return (
    <Fragment>
      <Loading status={loading} />
      <Col lg={12} className="mt-5 mb-3">
        <Card className={styles.runwayCard}>
          <CardTitle className={styles.airfieldscardtitle}>
            <Col lg={12}>
              <Row>
                <Col>
                  <FormattedMessage
                    id="airfields.runway.runwayconditions"
                    defaultText="Runway Conditions"
                  />
                </Col>
              </Row>
            </Col>
          </CardTitle>
          <hr className={styles.hrLine} />
          <CardBody className={styles.runwayCardBody}>
            <Col lg={12} className={styles.runwayconditionscard}>
              <Fragment>
                <div className={styles.runwayconditions}>
                  <Table borderless>
                    <thead>
                      <tr className={`text-center  ${styles.rnwyTableHeader}`}>
                        <th className={`${styles.runwaylist} ${styles.tableRunwayHeading}`}>
                          <FormattedMessage
                            id="airfields.runway.runway"
                            defaultText="Runway"
                          />
                        </th>
                        <th className={`${styles.runwaylist} ${styles.tableCol}`}>
                          <FormattedMessage
                            id="airfields.runway.td"
                            defaultText="TD"
                          />
                        </th>
                        <th className={` ${styles.runwaylist} ${styles.tableCol}`}>
                          <FormattedMessage
                            id="airfields.runway.mp"
                            defaultText="MP"
                          />
                        </th>
                        <th className={`${styles.runwaylist} ${styles.tableCol}`}>
                          <FormattedMessage
                            id="airfields.runway.ro"
                            defaultText="RO"
                          />
                        </th>
                        <th className={`${styles.runwaylist}  ${styles.tableStatus}`}>
                          <FormattedMessage
                            id="airfields.runway.status"
                            defaultText="Status"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {runways && runways.length
                        ? runways.map(rnwy => (
                          <>
                            <tr className={styles.runwayDataRow} onClick={() => handleRowClick(rnwy)}>
                              <td className={`float-left lg-4 ${styles.runwaylist} ${styles.name}`}><span>{rnwy.name}</span></td>
                              <td className={`text-center lg-2 ${styles.runwaylist} ${styles.tableCol}`}>{rnwy.td}</td>
                              <td className={`text-center lg-2 ${styles.runwaylist}  ${styles.tableCol}`}>{rnwy.mp}</td>
                              <td className={`text-center lg-2 ${styles.runwaylist}  ${styles.tableCol}`}>{rnwy.ro}</td>
                              <td className={`${styles.runwaylist}  ${styles.tableStatus}`}>
                                {
                                rnwy.status === true
                                  ? <button type="button" className={`text-center ${styles.savebutton}`}>Open</button>
                                  :<button type="button" className={`text-center ${styles.closedbutton}`}>Closed</button>
                                }

                              </td>
                            </tr>
                          </>
                        ))
                        : (
                          <div className={styles.noCommentsText}>
                            There are no runways in Airport
                          </div>
                        )
                      }
                    </tbody>
                  </Table>

                </div>
              </Fragment>
            </Col>
          </CardBody>
        </Card>
      </Col>
      <Modal
        onClose={() => toggleRunwayModal()}
        showIn={runwayModal}
        width="40%"
        minHeight="30%"
      >
        <Fragment>
          <div className={styles.modal}>
            <div className={styles.modalTitle}>
              <span className={styles.runwayName}>{runway.name}</span>
              <div className={styles.closeIcon} onClick={() => toggleRunwayModal()}>
                <img src={CloseIcon} alt="closeIcon" />
              </div>
            </div>
            <hr className={styles.hrLine} />
            <Row className={`${styles.firstRow} ${styles.runwayModalRow}`}>
              <Col lg={6} className={styles.runwayFieldName}>
                <FormattedMessage
                  id="airfields.runway.td"
                  defaultText="TD"
                />
                <Input
                  type="number"
                  name="td"
                  id="td"
                  value={runway.td}
                  onChange={handleChange}
                />
              </Col>
              <Col lg={6} className={styles.runwayFieldName}>
                <FormattedMessage
                  id="airfields.runway.ro"
                  defaultText="RO"
                />
                <Input type="number" name="ro" id="ro" value={runway.ro} onChange={handleChange} />
              </Col>
            </Row>
            <Row className={`${styles.runwayModalRow} ${styles.lastRow}`}>
              <Col lg={6} className={styles.runwayFieldName}>
                <FormattedMessage
                  id="airfields.runway.mp"
                  defaultText="MP"
                />
                <Input type="number" name="mp" id="mp" value={runway.mp} onChange={handleChange} />
              </Col>
              <Col lg={6} className={styles.runwayFieldName}>
                <FormattedMessage
                  id="airfields.runway.status"
                  defaultText="First Name"
                />
                <Input type="select" name="status" id="status" value={runway.status} onChange={handleChange}>
                  <option value={false}>Closed</option>
                  <option value>Open</option>
                </Input>
              </Col>
            </Row>
            <hr className={styles.hrLine} />
            <Col>
              <Row className={styles.modalFooter}>
                <Col>
                  <button type="button" className={`float-left mt-3 mb-5 ${styles.cancelbutton}`} onClick={() => toggleRunwayModal()}>Cancel</button>
                </Col>
                <Col>
                  <button type="button" className={`float-right mt-3 mb-5 ${styles.savebutton}`} onClick={() => saveRunwayConditions()}>Save</button>
                </Col>
              </Row>
            </Col>
          </div>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

RunwayCondition.propTypes = {
  actionFetchRunwayConditions: PropTypes.func.isRequired,
  actionFetchAirportRunways: PropTypes.func.isRequired,
  actionEditRunway: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  runwayConditions: state.airfieldcondition.runwayConditions,
  surfaces: state.map.surfaces,
  editAction: state.airfieldcondition.editAction,
  fetchAction: state.airfieldcondition.fetchAction
});


const mapDispatchToProps = dispatch => ({
  // Fetch RunwayConditions
  actionFetchRunwayConditions: () => {
    dispatch(fetchRunwayConditions());
  },

  actionFetchAirportRunways: () => {
    dispatch(fetchAirportRunways());
  },

  actionEditRunway: (id, data) => {
    dispatch(updateRunwayConditions(id, data));
  },

  actionClear: () => {
    dispatch(clear());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RunwayCondition));
