type SubstringArgs = {
  content: string;
  cutTo: number;
  cutFrom?: number;
};

interface ContentSubstringFunc {
  (args: SubstringArgs): string;
}

export const contentSubstring: ContentSubstringFunc = ({
  content,
  cutTo,
  cutFrom = 0
}) => {
  if (content.length > cutTo) {
    return `${content.substring(cutFrom, cutTo)} ...`;
  }

  return content;
};
