import React from 'react';
import { moment } from '../../../../../libComponents/moment';

import { Wrapper } from './MessageHeader.styles';
import { User } from '../../../types';
import { Avatar } from '../../../../../components/avatar';
import { ChatTypes } from '../../../enums';
import { MessageInfo } from '../../MessageInfo';
import { Attachment } from '../../../components';
import { MessageContent } from '../MessageContent';

type MessageHeaderProps = {
  user: User;
  type?: ChatTypes;
  styles?:object;
  createdAt: string;
  contents?:object;
};

const filteredAirport = (airportId, airportDetails, title) => {
  if (!airportDetails) {
    return '';
  }
  const filtered = airportDetails.filter(airport => airport.code === airportId);

  // tslint:disable-next-line: prefer-template
  return `${title} at ${filtered[0]?.name}`;
};

const MessageHeader = React.memo<MessageHeaderProps>(
  ({
    user: {
      profilePicture,
      isActive,
      firstName,
      lastName,
      title,
      airportId,
      airportDetails
    },
    contents,
    styles,
    createdAt,
    type = ChatTypes.operations
  }) => (
    <Wrapper viewType={type} style={styles}>
      <Avatar src={profilePicture} isActive={isActive} />
      <div>
        <div className="user-name">
          <span>{`${firstName} ${lastName}`}</span>
          <div className="time">
            {moment(+createdAt * 1000).format('hh:mm')}
          </div>
        </div> 

        <div
            style={{
              margin:'-1px',
            }}
            >

          <MessageContent
                content={contents["content"]}
                commentedPost={contents['commentedPost']}
                type= {type}
                styles={{
                  paddingTop:'-10px',
                }}
              />
              {contents['attachments']? <Attachment attachments={contents['attachments']} />:''}
              
              {!contents['commentedPost'] && !contents['isComment'] && contents['messageData'].comments > 0 && (
                <MessageInfo
                  channelId={contents['channelId']}
                  messageData={contents['messageData']}
                  toggleLikes={contents['toggleLikes']}
                />
              )}
          </div>
            {contents['commentedPost'] && contents['comments'] ?(
              <div className="replies">
                <span>{`${contents['comments']} replies`}</span>
                <hr />
              </div>
            ):''}
        {title?.trim() && (
          <span className="info">
            {filteredAirport(airportId, airportDetails, title)}
          </span>
        )}
      </div>
    </Wrapper>
  )
);

export default MessageHeader;
export { MessageHeader };
