import React from 'react';

import { Wrapper } from './MessageOptions.styles';

import { MENU_IDS, menuItems } from './assets';

import { ReactComponent as Edit } from '../../../../icons/edit.svg';
import { ReactComponent as ChatComment } from '../../../../icons/chat-comment.svg';
// import { ReactComponent as InsertSmile } from '../../../../icons/insert-smile.svg';
import { ReactComponent as Delete } from '../../../../icons/bin.svg';

type MessageOptionsProps = {
  directMessage?: boolean;
  onClick: (key: MENU_IDS) => void;
  isAuthor: boolean;
  hideIcons: MENU_IDS[]
};
//add id commit
const icons = {
  // [MENU_IDS.insertSmile]: <InsertSmile />,
  [MENU_IDS.comment]: <ChatComment />,
  [MENU_IDS.edit]: <Edit />,
  [MENU_IDS.delete]: <Delete />
};

const MessageOptions = React.memo<MessageOptionsProps>(
  ({ onClick, isAuthor, hideIcons, directMessage }) => (
    <Wrapper left={directMessage}>
      <ul>
        {menuItems.map(({ key, isPrivate }) => {
          if (
            (isPrivate && !isAuthor)
            || (hideIcons.includes(key))
          ) {
            return null;
          }

          return (
            <li key={key} onClick={() => onClick(key)}>
              {icons[key]}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  )
);

export default MessageOptions;
export { MessageOptions };
