import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 591px;
  min-height: 100px;
  border-radius: 5px;
  background-color: #e9eff4;
  margin: 15px 0;

  .image-preview {
    margin: 5px;
    position: relative;
    width: 176px;
    height: 72px;
    overflow: hidden;
    transition: background-color 0.3s;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;

      :hover {
        background: transparent;
      }
    }

    .delete-icon {
      z-index: 2;
      cursor: pointer;
      display: none;
      position: absolute;
      left: 50%;
      top: 39%;
      transform: translate(-50%, -50%);

      svg {
        path {
          fill: #ff4949;
        }
      }
    }

    &:before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      content: " ";
    }

    :hover {
      span {
        display: block;
      }
      background: transparent;

      &:before {
        opacity: 1;
      }
    }
  }
`;
