import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './dashboard.module.scss';
import { fetchAssets } from '../../assets-management/redux/actions';
import Toolbar from './Toolbar';
import Loading from '../../../components/loading';


export default function AssetTable() {
  const assets = useSelector(state => state.assets.assetsList.results);
  const assets_action=useSelector(state => state.assets.assets_action);

  const apidispatch = useDispatch();
  const [calls, setCalls]=useState(false);
  useEffect(() => {
    const call = async () => {
      await apidispatch(fetchAssets(undefined, undefined, undefined, '6'));
      setCalls(true);
    };
    if (!assets.length && calls===false) call();
  }, [apidispatch, assets, calls]);
  return (
    <div className={styles.assetTable}>
      <Loading loadingStatus={assets_action.loading} />
      <div>
        <Toolbar
          fiter={false}
          formatedMessage="section.toolbar.A"
          Message="Assets with Recurring Issues"
        />
      </div>
      <table className={styles.table} style={{ border: 'None' }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="assets.field.icon" defaultMessage="ICON" />
            </th>
            <th>
              <FormattedMessage id="assets.field.lable" defaultMessage="LABEL" />
            </th>
            <th>
              <FormattedMessage id="Asset.field.area" defaultMessage="AREA" />
            </th>
            <th>
              <FormattedMessage id="inspections.list.table.issues" defaultMessage="ISSUES" />
            </th>
          </tr>
        </thead>
        {assets.length>0 && assets.map(a => (
          <tr>
            <td>
              <img src={a.asset_type.icon} alt={a.name} />
            </td>
            <td>
              {a.name}
            </td>
            <td>
              {a.area?a.area.name:''}
            </td>
            <td>
              {a.issues}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}
