/* eslint-disable react/no-did-update-set-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Input, Card, CardBody, Row, Col, FormGroup, Label } from 'reactstrap';
import styles from '../usefulinks.module.scss';
import { updateUsefulLink, deleteUsefulLink, clearUsefulLinksAction } from '../../../redux/action';
import Button from '../../../../../components/button';
import Loading from '../../../../../components/loading';
import Modal from '../../../../../components/modal';
import Separator from '../../../../../components/separator';
import editIcon from '../../../../../icons/inspection-icons/edit.svg';
import { ReactComponent as Bin } from '../../../../../icons/bin.svg';

class EditableLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showsave: false,
      value: '',
      item: props.item,
      loading: false,
      confirmDelete: false
    };

    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidUpdate() {
    const { usefulLinksAction, clear } = this.props;
    const { loading } =this.state;
    if (usefulLinksAction.loading === false && loading === true) {
      this.setState({ loading: false });
      clear();
    }
  }

  onBlur = () => {
    this.setState({ edit: false, showsave: false });
  }

  handleInputChange = (e) => {
    const { name, value } =e.target;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      },
      edit: true
    }));
  }

  remove = (id) => {
    const { deleteLink } = this.props;
    this.setState({ loading: true });
    deleteLink(id);
    this.setState({ confirmDelete: false });
  }

  handleConfirmDelete = () => {
    this.setState({ confirmDelete: true });
  }

  toggleConfirmDelete = () => {
    const { confirmDelete } = this.state;
    this.setState({ confirmDelete: !confirmDelete });
  }

  onSave = (id) => {
    const data = {};
    const { item } = this.state;
    const { actionEditLink } = this.props;
    this.setState({ loading: true });
    data.text = item.text;
    data.url=item.url;
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    actionEditLink(id, formData);
    this.setState({ showsave: false, edit: false });
  }

  toggleEdit(id) {
    const { selected } = this.props;
    this.setState({ showsave: true, edit: true });
    selected(id);
  }

  render() {
    const { edit, showsave, value, item, loading, confirmDelete } = this.state;
    const { selectedLink } = this.props;
    return (
      <Fragment>
        <Loading loadingStatus={loading} />
        <Card className={styles.editLinkCard}>
          <CardBody className={styles.editLinkCardBody}>
            <Row>
              <Col lg={4}>
                <div>
                  {selectedLink === item.id && showsave
                    ? (
                      <FormGroup className={styles.pl16}>
                        <Label for="text">Text</Label>
                        <Input
                          onChange={e => this.handleInputChange(e)}
                          type="text"
                          name="text"
                          id="text"
                          disabled={selectedLink === item.id && edit ? '' : 'disabled'}
                          placeholder={value||item.title}
                          value={item.text}
                          className={styles.Input}
                        />
                      </FormGroup>
                    )
                    : (
                      <Fragment>
                        <div className={styles.linkData}>
                          <p className={styles.linkTextHeading}> Text</p>
                          <p className={styles.linkTextAns}>
                            {item.text}
                          </p>
                        </div>
                      </Fragment>
                    )}
                </div>
              </Col>
              <span style={{ marginTop: 16 }}>
                <Separator />
              </span>
              <Col lg={5}>
                <div>
                  {selectedLink === item.id && showsave
                    ? (
                      <FormGroup className={styles.pl16}>
                        <Label for="url">Url</Label>
                        <Input
                          onChange={e => this.handleInputChange(e)}
                          type="text"
                          name="url"
                          id="url"
                          className={styles.Input}
                          disabled={selectedLink === item.id && edit ? '' : 'disabled'}
                          placeholder={value||item.title}
                          value={item.url}
                        />
                      </FormGroup>
                    )
                    : (
                      <Fragment>
                        <div className={styles.linkData}>
                          <p className={styles.linkTextHeading}> Url</p>
                          <p className={styles.linkTextAns}>
                            {item.url}
                          </p>
                        </div>
                      </Fragment>
                    ) }
                </div>
              </Col>
              <span style={{ marginTop: 16, marginLeft: 13 }}>
                <Separator />
              </span>
              <Col
                lg={1}
              >
                {selectedLink === item.id && showsave? (
                  <div className={styles.showSaveButtons}>
                    <Button type="submit" onClick={() => this.onSave(item.id)}
                      translationID="airport.form.submit." defaultText="Save"
                    />
                    <button className={`${styles.editBtn} ${styles.removeBtn}`} type="button"
                      onClick={() => this.onBlur()}
                    >
              &times;
                    </button>
                  </div>
                ): (
                  <div className={styles.linkEditButtons}>
                    <button className={styles.editBtn} type="button" onClick={() => this.toggleEdit(item.id)}>
                      <img src={editIcon} alt="" className={styles.EditIcon} />
                    </button>
                    <span>
                      <Bin height="18px"
                        opacity="0.4"
                        className={styles.deleteIcon}
                        onClick={() => this.handleConfirmDelete(item.id)}
                      />
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          width="500px"
          minHeight="125px"
          showIn={confirmDelete}
          centered
          onClose={this.toggleConfirmDelete}
        >
          <h3 style={{ padding: '15px 30px 5px 40px' }}>
            <FormattedMessage
              id="airfields.usefullinks.removeLink"
              defaultText="Are you sure you want to delete this link ?"
            />
          </h3>
          <hr className={styles.hrLine} />
          <div className={styles.footer}>
            <button type="button" onClick={() => this.toggleConfirmDelete()} className={`text-center ${styles.closedbutton}`}>Cancel</button>
            <button type="button" onClick={() => this.remove(item.id)} className={`float-right ${styles.savebutton}`}>Delete</button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
EditableLink.propTypes = {
  item: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  usefulLinksAction: state.airfieldcondition.usefulLinksAction
});


const mapDispatchToProps = dispatch => ({
  // Update Link
  actionEditLink: (id, data) => {
    dispatch(updateUsefulLink(id, data));
  },

  deleteLink: (id) => {
    dispatch(deleteUsefulLink(id));
  },
  // Clear Action
  clear: () => {
    dispatch(clearUsefulLinksAction());
  }
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableLink);
