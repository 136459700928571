import gql from 'graphql-tag';

const UPDATE_POST = gql`
  mutation updatePost(
    $postId: String!
    $userId: String!
    $content: String!
    $attachments: [attachmentsInput]
  ) {
    updatePost(
      input: {
        postId: $postId
        userId: $userId
        content: $content
        attachments: $attachments
      }
    ) {
      airportId
      attachments {
        name
        url
        type
        size
      }
      channelId
      content
      createdAt
      iropsId
      likes
      postId
      updatedAt
      userId
      comments
      isLiked
      user {
        firstName
        lastName
        profilePicture
        airportId
        airportDetails {
          name
          code
          logo
        }
      }
    }
  }
`;

export default UPDATE_POST;
