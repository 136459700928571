/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { FormattedMessage } from 'react-intl';

/** ******************************************************************
 *  Redux import
 * ***************** */

import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';
import {
  fetchInspection, clearInspection, completeInspection, searchUser,
  createRemark, editRemark, draftInspection, fetchDraftInspection, fetchOpenWorkOrders, clearOpenWorkOrders,
  fetchDataSourceList
} from '../redux/actions';
import { showConfirmModal, hideModal } from '../../../general_redux/actions';
import { fetchAssets } from '../../assets-management/redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';


/** ******************************************************************
 *  Components import
 * ************* */

import SectionHeader from '../../../components/sectionHeader';
import Button from '../../../components/button';
import HeaderBack from '../../../components/headerBack';
import InspectionDetails from './components/InspectionDetails';
import InspectionChecklist from './components/InspectionChecklist';
import InspectionSubmitted from './components/InspectionSubmitted';

/** ******************************************************************
 *  Assets import
 * ************* */

import { importAllImages } from '../../../utils/helpers';
import styles from './inspection.module.scss';
import Modal from '../../../components/modal';
import Loading from '../../../components/loading/loading';
import { GetFileName, S3Uploads } from '../../services';

class CompleteInspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      apiStatus: undefined,
      showFormErrors: false,
      answers: {
        date: moment().format()
      },
      remarksIDs: {},
      remarks: [],
      activeInspectionField: 0,
      requiredMap: {},
      fieldErrors: {},
      incompleteChecklist: false,
      inspection: {
        info: {
          name: '',
          icon: ''
        },
        details: {
          fields: [],
          additionalInfo: ''
        },
        checklist: []
      },
      showModal: false,
      showErrorModal: false,
      inspection_type: '',
      loadingStatus: false,
      shiftTypes: [],
      unsatisfactoryError: false,
      DateChanged: false,
      open_workorders: {},
      draftLoading: false
    };

    this.processInspectionForState = this.processInspectionForState.bind(this);
    this.handleGoToChecklist = this.handleGoToChecklist.bind(this);
    this.handleGoInspections = this.handleGoInspections.bind(this);
    this.handleAnswerChanged = this.handleAnswerChanged.bind(this);
    this.handleFieldErrorChanged = this.handleFieldErrorChanged.bind(this);
    this.handleInspectionItemAnswerChanged = this.handleInspectionItemAnswerChanged.bind(this);
    this.handleInspectionItemRemarkChanged = this.handleInspectionItemRemarkChanged.bind(this);
    this.handleInspectionFieldChanged = this.handleInspectionFieldChanged.bind(this);

    this.isChecklistComplete = this.isChecklistComplete.bind(this);
    this.handleGoBackToDetails = this.handleGoBackToDetails.bind(this);
    this.handleCompleteInspection = this.handleCompleteInspection.bind(this);
    this.handleDraftInspection = this.handleDraftInspection.bind(this);
    this.handleSearchForUser = this.handleSearchForUser.bind(this);
    this.isUnsatisfactory = this.isUnsatisfactory.bind(this);

    this.images = importAllImages(require.context('../../../icons/inspection-icons', false, /\.(png|jpe?g|svg)$/));
  }

  componentDidMount() {
    const { actionFetch, actionFetchDraft, match: { params: { id } },
      assets, actionFetchAssets, shiftTypes, actionFetchShiftTypes, ClearOpenWorkOrders, fetchDataSources } = this.props;
    actionFetch(id);
    actionFetchDraft(id);
    ClearOpenWorkOrders();
    fetchDataSources();
    // createEmptyInspection(id);
    // get_draftdata(id);
    if (!shiftTypes.length) actionFetchShiftTypes();
    if (!assets.length) actionFetchAssets();
    this.setState({
      draftLoading: true
    });
  }

  componentDidUpdate(prevProps) {
    const { inspection, draftdata, draftAction, fetchDraftInspectionAction } = this.props;
    const { answers } = this.state;
    if (!prevProps.inspection.id && inspection.id) {
      this.processInspectionForState(inspection);
    }

    if (prevProps.fetchDraftInspectionAction.loading === true && !fetchDraftInspectionAction.loading) {
      this.setState({ draftLoading: fetchDraftInspectionAction.loading });
    }

    if (JSON.stringify(prevProps.draftdata)!== JSON.stringify(draftdata)) {
      this.setState({ answers: {
        ...answers, ...draftdata.response
      } });
    }
    if (prevProps.draftAction.success !== draftAction.success) {
      this.setState({ showModal: true });
    }
  }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.user.id && !state.answers.inspected_by) {
      return {
        ...state,
        answers: {
          ...state.answers,
          inspected_by: {
            id: props.user.id,
            fullname: props.user.fullname
          }
        }
      };
    }
    // update state when answer is completed.
    if (props.createAction && props.answerCreated && props.answerCreated.status === 1) {
      return { ...state, apiStatus: props.createAction.success };
    }

    if (!state.open_workorders.length && props.OpenWorkOrders && props.OpenWorkOrdersAction.success) {
      return { ...state, open_workorders: props.OpenWorkOrders };
    }

    if (Object.keys(props.draftdata).length && state.remarks.length === 0) {
      return { ...state,
        remarksIDs: props.draftdata.remarksIDs,
        remarks: props.draftdata.remarksEdit
      };
    }

    // update remarks IDs when a new remark is created;
    const r = props.remarkCreated;
    if (r) {
      const thestate = state;
      thestate.remarksIDs[r.field_reference] = {
        ...thestate.remarksIDs[r.field_reference],
        [r.item_reference]: r.id
      };
      return thestate;
    }

    return state;
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  showFormErrorsModal = () => {
    const { actionConfirm, actionHideModal } = this.props;
    const content = {
      title: '',
      body:
  <div className={styles.submissionError}>
    <FormattedMessage
      id="inspections.complete_inspections.incomplete_checklist"
      defaultMessage="There was a problem with your submission. Please make sure you've answered all of the inspection checklists."
    />
  </div>
    };
    actionConfirm(content, () => {
      actionHideModal();
    }, actionHideModal);
  }

  handleWorkorderCreate = (newWorkOrder) => {
    const { open_workorders } = this.state;
    if (!open_workorders.length) {
      this.setState(prevState => ({
        inspection: { ...prevState.inspection,
          workorders: [...prevState.inspection.workorders, newWorkOrder] }
      }));
    } else {
      this.setState(prevState => ({
        open_workorders: [...prevState.open_workorders, newWorkOrder]
      }));
    }
  }

  processInspectionForState(inspection) {
    if (inspection) {
      const { details } = inspection;
      let requiredMap = {};
      let fieldErrors = {};

      details.fields.forEach((detailField) => {
        requiredMap = {
          ...requiredMap,
          [detailField.id]: detailField.required
        };

        fieldErrors = {
          ...fieldErrors,
          [detailField.id]: detailField.required
        };
      });

      fieldErrors.date = false;
      // fieldErrors.type = true;
      fieldErrors.inspected_by = false;
      // fieldErrors.weather = false;
      this.setState({
        inspection,
        requiredMap,
        fieldErrors
      });
    }
  }

  handleGoToChecklist() {
    const { fieldErrors, answers, DateChanged, answers: { shiftName } } = this.state;
    const { actionFetchOpenWorkOrders, inspection } = this.props;
    const noErrors = Object.keys(fieldErrors)
      .every(k => (answers[k] && inspection.details.fields.map(l => l.type === 'string') === true && answers[k].trim() !== ' ' ? true : fieldErrors[k] === false));
    if (DateChanged && inspection.workorders) {
      const date = new Date(answers.date);
      actionFetchOpenWorkOrders(date.toISOString());
    }
    if (noErrors) {
      this.setState({ stage: 2 });
      const response = {};
      const ids = {};
      let flag = true;
      inspection.checklist.forEach((inspectionField, idx) => {
        ids[inspectionField.id] = idx;
        const checklist_length = Object.keys(inspectionField.checklist.filter(
          item => !item.shifts || item.shifts.indexOf(shiftName) !== -1 || item.shifts.length === 0
        )).length;
        if (checklist_length > 0 && flag) {
          this.setState({ activeInspectionField: idx });
          flag = false;
        }
      });
      Object.keys(answers).forEach((FieldId) => {
        if (FieldId in ids) {
          const checklist_length = Object.keys(inspection.checklist[ids[FieldId]].checklist.filter(
            item => !item.shifts || item.shifts.indexOf(shiftName) !== -1 || item.shifts.length === 0
          )).length;
          if (checklist_length > 0) {
            const item_list = {};
            inspection.checklist[ids[FieldId]].checklist.forEach((item) => {
              if (!item.shifts || (item.shifts.indexOf(answers.shiftName) !== -1 || item.shifts.length === 0)) {
                if (item.key in answers[FieldId]) item_list[item.key] = answers[FieldId][item.key];
              }
            });
            if (Object.keys(item_list).length > 0) response[FieldId] = item_list;
          }
        } else {
          response[FieldId] = answers[FieldId];
        }
      });
      this.setState({ answers: { ...response } });
    } else {
      this.setState({ showFormErrors: true });
    }
  }

  handleGoInspections() {
    const { history } = this.props;
    history.push(INSPECTIONS_HOME_ROUTE);
  }

  handleAnswerChanged(answer, fieldId) {
    // const { answers } = this.state;
    if (fieldId === 'date') {
      // delete answers.shiftName;
      this.setState({ DateChanged: true });
    }
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged(fieldId, errorValue) {
    // Updates field error map with value passed from field component
    this.setState(prevState => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [fieldId]: errorValue
      }
    }));
  }

  handleInspectionItemAnswerChanged(inspectionFieldId, itemKey, value) {
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [inspectionFieldId]: {
          ...prevState.answers[inspectionFieldId],
          [itemKey]: value
        }
      },
      incompleteChecklist: false
    }));
  }

  async handleInspectionItemRemarkChanged(inspectionFieldId, itemKey, value) {
    this.setState(prevState => ({
      remarks: {
        ...prevState.remarks,
        [inspectionFieldId]: {
          ...prevState.remarks[inspectionFieldId],
          [itemKey]: value
        }
      }
    }));

    const { actionCreateRemark, actionEditRemark, draftdata } = this.props;
    const { remarksIDs } = this.state;

    const formData = new FormData();
    formData.append('answer', draftdata.id);
    formData.append('field_reference', inspectionFieldId);
    formData.append('item_reference', itemKey);
    formData.append('text', value.text);
    // if (value.photo) formData.append('image', value.photo);
    if (value.attachments) {
      let fileIds = '';
      fileIds = await this.getFileNames(1);
      if (fileIds) {
        await this.doS3Upload([value.attachments], fileIds, 'remark')
          .then(() => {
            fileIds.filename.forEach((fileId) => {
              formData.append('attachments', fileId.toString());
            });
          });
      }
    }

    if (remarksIDs[inspectionFieldId]
      && remarksIDs[inspectionFieldId][itemKey]) {
      actionEditRemark(remarksIDs[inspectionFieldId][itemKey], formData);
    } else {
      actionCreateRemark(formData);
    }
  }

  handleInspectionFieldChanged(idx) {
    const { answers, inspection: { checklist, workorders }, open_workorders, activeInspectionField, remarks } = this.state;
    let noError = false;
    const work_orders = !open_workorders.length ? workorders : open_workorders;
    if (answers[checklist[activeInspectionField].id]) {
      Object.keys(answers[checklist[activeInspectionField].id]).forEach((key) => {
        if (!answers[checklist[activeInspectionField].id][key]) {
          let wo = [];
          if (work_orders) {
            wo = work_orders.filter(e => (
              checklist[activeInspectionField].id === e.category_id
              && key === e.subcategory_id
            ));
          }
          if ((Object.keys(remarks).length > 0 && remarks[checklist[activeInspectionField].id]
          && remarks[checklist[activeInspectionField].id][key]) || (wo.length > 0)) {
            this.setState({ unsatisfactoryError: false });
          } else {
            noError = true;
            this.setState({ unsatisfactoryError: true });
          }
        }
      });
    }
    if (!noError) {
      this.setState({
        activeInspectionField: idx,
        incompleteChecklist: false
      });
    } else {
      this.setState({ unsatisfactoryError: true });
    }
  }

  handleGoBackToDetails() {
    this.setState({ stage: 1 });
  }

  isChecklistComplete() {
    const { answers, inspection: { checklist } } = this.state;
    const allAnswered = checklist.reduce((accumulator, currentItem) => {
      const checklist_length = Object.keys(currentItem.checklist.filter(
        item => !item.shifts || item.shifts.indexOf(answers.shiftName) !== -1 || item.shifts.length === 0
      )).length;
      if (checklist_length > 0) {
        const allCurrentItem = answers[currentItem.id] && checklist_length > 0 && (
          checklist_length === Object.keys(answers[currentItem.id]).length);
        return !!accumulator && !!allCurrentItem;
      }
      return true;
    }, true);
    return allAnswered;
  }

  isUnsatisfactory() {
    const { answers, inspection: { checklist, workorders }, open_workorders, remarks } = this.state;
    let noError = false;
    const work_orders = !open_workorders.length ? workorders : open_workorders;
    checklist.forEach((item) => {
      if (answers[item.id]) {
        Object.keys(answers[item.id]).forEach((key) => {
          if (!answers[item.id][key]) {
            let wo = [];
            if (work_orders) {
              wo = work_orders.filter(e => (
                item.id === e.category_id
                && key === e.subcategory_id
              ));
            }
            if ((Object.keys(remarks).length > 0 && remarks[item.id]
              && remarks[item.id][key]) || (wo.length > 0)) {
              this.setState({ unsatisfactoryError: false });
            } else {
              noError = true;
              return noError;
            }
          }
        });
      }
    });
    return noError;
  }

  handleCompleteInspection() {
    const {
      answers: {
        // eslint-disable-next-line camelcase
        date, type, inspected_by, shiftName,
        ...rest
      }
    } = this.state;
    this.setState({ incompleteChecklist: false, loadingStatus: true });
    const isChecklistError = this.isChecklistComplete();
    let isUnsatisfactoryError = false;
    if (isChecklistError) isUnsatisfactoryError = this.isUnsatisfactory();

    if (isChecklistError && !isUnsatisfactoryError) {
      const {
        actionSubmit,
        match: { params: { id } },
        draftdata,
        location } = this.props;

      actionSubmit(
        id,
        {
          answer_id: draftdata.id,
          response: rest,
          date: new Date(date),
          email_inspection_date: moment().format('YYYY-MM-DD HH:mm'),
          weather: {},
          // type: this.state.inspection_type,
          inspected_by: inspected_by.id,
          created_by: inspected_by.id,
          task_details: {
            ...location.state
          },
          shift_name: shiftName,
          email_date: moment().format()
        }
      );
    }
    // this.setState({ incompleteChecklist: true });
    if (!isChecklistError) this.setState({ showErrorModal: true, loadingStatus: false });
    if (isUnsatisfactoryError) this.setState({ unsatisfactoryError: true, loadingStatus: false });
  }


  handleDraftInspection() {
    const {
      answers: {
        // eslint-disable-next-line camelcase
        date, type, inspected_by, shiftName,
        ...rest
      } } = this.state;
    const {
      actiondraft,
      match: { params: { id } },
      draftdata,
      location } = this.props;


    actiondraft(
      id,
      {
        answer_id: draftdata.id,
        response: rest,
        date: new Date(date),
        weather: {},
        // type: this.state.inspection_type,
        inspected_by: inspected_by.id,
        created_by: inspected_by.id,
        task_details: {
          ...location.state
        },
        shift_name: shiftName
      }
    );
  }

  handleSearchForUser(value) {
    const { actionSearchUser } = this.props;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }


  render() {
    const {
      stage,
      answers,
      requiredMap,
      showFormErrors,
      apiStatus,
      incompleteChecklist,
      inspection: {
        info,
        details,
        checklist
      },
      activeInspectionField,
      remarks,
      showModal,
      showErrorModal,
      loadingStatus,
      unsatisfactoryError,
      DateChanged,
      open_workorders,
      draftLoading
    } = this.state;

    const { userlist, inspection, user, translations, draftdata, shiftTypes, OpenWorkOrders,
      OpenWorkOrdersAction, allDataSources } = this.props;
    const translationMap = (translations && translations[user.language])
      ? translations[user.language] : {};
    return (
      <>
        <Loading loadingStatus={draftLoading} />
        <SectionHeader
          icon={info.icon.includes('.png') ? this.images[info.icon]: this.images[`${info.icon}.svg`]}
          translationID="invalid"
          defaultTitle={info.title}
          title={info.title}
        >
          {stage === 2 && !apiStatus && (
            <>
              <div className={styles.completeBtn}>
                <Button action="tertiary" onClick={this.handleGoBackToDetails}
                  translationID="inspections.complete_inspections.back_to_details" defaultText="Go back to Details"
                />
              </div>
              <div className={styles.completeBtn}>
                <Button action="tertiary" onClick={this.handleDraftInspection}
                  translationID="inspections.complete_inspections.save_as_draft" defaultText="Save as Draft"
                />
                <Modal
                  onClose={() => this.setState({ showModal: false })}
                  showIn={showModal}
                  width="35%"
                  minHeight="20%"
                >
                  <div className={styles.confirmContent}>
                    <FormattedMessage id="inspections.draft_inspection.saved" defaultMessage="Successfully Saved" />
                  </div>
                  <div className={styles.btn}>
                    <Button type="secondary" translationID="inspections.list.ok" onClick={() => this.setState({ showModal: false })} defaultText="OK" />
                  </div>
                </Modal>
              </div>
              <div className={styles.completeBtn}>
                <Button action="secondary" onClick={this.handleCompleteInspection}
                  translationID="inspections.complete_inspections.complete" defaultText="Complete Inspection"
                />
              </div>
              {(!showErrorModal
                 && <Loading loadingStatus={loadingStatus} />
              )}
              <Modal
                onClose={() => this.setState({ showErrorModal: false })}
                showIn={showErrorModal}
                width="40%"
                minHeight="30%"
              >
                <div className={styles.confirmContent}>
                  <FormattedMessage id="inspections.complete_inspections.incomplete_checklist"
                    defaultMessage="There was a problem with your submission. Please make sure you've answered all of the inspection checklists."
                  />
                </div>
                <div className={styles.btn}>
                  <Button type="secondary"
                    translationID="inspections.list.ok"
                    onClick={() => this.setState({ showErrorModal: false })} defaultText="OK"
                  />
                </div>
              </Modal>
              <Modal
                onClose={() => this.setState({ unsatisfactoryError: false })}
                showIn={unsatisfactoryError}
                width="40%"
                minHeight="30%"
              >
                <div className={styles.confirmContent}>
                  <FormattedMessage id="inspections.complete_inspections.unsatisfactory_error"
                    defaultMessage="Please add a remark or create a Work Order for all Unsatisfactory items"
                  />
                </div>
                <div className={styles.btn}>
                  <Button type="secondary"
                    translationID="inspections.list.ok"
                    onClick={() => this.setState({ unsatisfactoryError: false })} defaultText="OK"
                  />
                </div>
              </Modal>
            </>
          )}
        </SectionHeader>
        <HeaderBack
          translationID="inspections.start.inspections.back"
          translationDefault="Back to Inspections"
          backRoute={INSPECTIONS_HOME_ROUTE}
        />
        <div className={styles.container}>
          {stage === 1 && !apiStatus && draftdata.id && allDataSources && (
            <InspectionDetails
              answers={answers}
              shiftTypes={shiftTypes}
              translations={translationMap}
              handleAnswerChanged={this.handleAnswerChanged}
              detailsForm={details}
              handleGoToChecklist={this.handleGoToChecklist}
              requiredMap={requiredMap}
              handleFieldErrorChanged={this.handleFieldErrorChanged}
              showFormErrors={showFormErrors}
              userlist={userlist}
              searchForUser={this.handleSearchForUser}
              allDataSources={allDataSources}
            />
          )}
          {stage === 2 && !apiStatus && checklist.length > 0
          && (DateChanged ? ((OpenWorkOrders && OpenWorkOrdersAction && OpenWorkOrdersAction.success) || !inspection.workorders) : true) && (
            <InspectionChecklist
              id={inspection.id}
              answers={answers}
              remarks={remarks}
              draftdata={draftdata}
              translations={translationMap}
              workorders={!open_workorders.length ? inspection.workorders : open_workorders}
              selfInspection={user.airport.safety_self_inspection === inspection.id}
              handleAnswerChanged={this.handleAnswerChanged}
              checklist={checklist}
              handleWorkorderCreate={this.handleWorkorderCreate}
              activeIdx={activeInspectionField}
              handleChecklistItemStatusChange={this.handleInspectionItemAnswerChanged}
              handleChecklistItemRemarkChange={this.handleInspectionItemRemarkChanged}
              handleInspectionFieldChanged={this.handleInspectionFieldChanged}
              apiStatus={apiStatus}
              incompleteChecklist={incompleteChecklist}
              showFeedBackModal={this.showFormErrorsModal}
              shiftTypes={shiftTypes}
            />
          )}
          {apiStatus && (
            <InspectionSubmitted icon={info.icon} name={info.title}
              handleGoInspections={this.handleGoInspections}
            />
          )}
        </div>
      </>
    );
  }
}

CompleteInspection.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  actionFetchDraft: PropTypes.func.isRequired,
  actionCreateRemark: PropTypes.func.isRequired,
  actionEditRemark: PropTypes.func.isRequired,
  actionSubmit: PropTypes.func.isRequired,
  actiondraft: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  answerCreated: PropTypes.shape({}),
  draft_answer: PropTypes.shape({}),
  inspection: PropTypes.shape({}).isRequired,
  draftdata: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  shiftTypes: PropTypes.arrayOf(PropTypes.shape({}))
};

CompleteInspection.defaultProps = {
  userlist: [],
  answerCreated: {},
  draft_answer: {},
  draftdata: {},
  shiftTypes: {}
};

const mapStateToProps = state => ({
  // createAction: state.workorders.createAction,
  inspection: state.inspection.inspection,
  createAction: state.inspection.createAction,
  answerCreated: state.inspection.answerCreated,
  userlist: state.inspection.userlist,
  user: state.auth.profile,
  remarkCreated: state.inspection.remarkCreated,
  translations: state.auth.translations,
  draft_answer: state.inspection.draft_answer,
  draftdata: state.inspection.draftdata,
  draftAction: state.inspection.draftAction,
  assets: state.assets.assets,
  shiftTypes: state.settings.shiftTypes,
  OpenWorkOrders: state.inspection.OpenWorkOrders,
  OpenWorkOrdersAction: state.inspection.OpenWorkOrdersAction,
  fetchDraftInspectionAction: state.inspection.fetchDraftInspectionAction,
  allDataSources: state.inspection.allDataSources
}); // Please review your data

const mapDispatchToProps = dispatch => ({
  // Fetch inspection
  actionFetch: (id) => {
    dispatch(fetchInspection(id));
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionClear: () => {
    dispatch(clearInspection());
  },
  actionFetchDraft: (id) => {
    dispatch(fetchDraftInspection(id));
  },
  actionSubmit: (id, data) => {
    dispatch(completeInspection(id, data));
  },
  actiondraft: (id, data) => {
    dispatch(draftInspection(id, data));
  },
  actionCreateRemark: (data) => {
    dispatch(createRemark(data));
  },
  actionEditRemark: (id, data) => {
    dispatch(editRemark(id, data));
  },
  actionConfirm: (body, accept, cancel) => {
    dispatch(showConfirmModal(body, accept, cancel));
  },
  actionHideModal: (insp) => {
    dispatch(hideModal(insp));
  },
  actionFetchAssets: () => {
    dispatch(fetchAssets());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  actionFetchOpenWorkOrders: (date) => {
    dispatch(fetchOpenWorkOrders(date));
  },
  ClearOpenWorkOrders: () => {
    dispatch(clearOpenWorkOrders());
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteInspection);
