import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HOC as Permissions } from 'react-redux-permissions';
import { FormattedMessage, injectIntl } from 'react-intl';
import SectionHeader from '../../../components/sectionHeader';
import HeaderBack from '../../../components/headerBack';
import FormattedMessageWithClass from '../../../components/formattedMessageWithClass';
import Button from '../../../components/button';
import Spinner from '../../../components/spinner';
import { LEASE_HOME } from '../../../constants/RouterConstants';
import Forbidden from '../../Forbidden';
import icon from '../../../components/settingsHeader/icons/settings.svg';
import EditableText from '../../inspections/Builder/steps/Step3/EditableText';
import styles from '../../operations-logs/Builder/operationLogBuilder.module.scss';
import createStyles from '../../inspections/Builder/create.module.scss';
import stepStyles from '../../inspections/Builder/steps/steps.module.scss';

import { uploadPropertyTypes, fetchPropertyTypes, clear } from '../redux/actions';

class PropertiesBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typesLength: 0,
      saveStatus: false
    };
  }

  componentDidMount() {
    const { fetchTypes } = this.props;
    fetchTypes();
  }

  componentWillUnmount() {
    const { actionsClear } = this.props;
    actionsClear();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.types && props.allTypes) {
      const newTypes = props.allTypes.map((t) => {
        const title = t.i18n_id
          ? props.intl.formatMessage({ id: `lease.property.${t.i18n_id}` })
          : t.name;
        return ({
          pk: `${t.id}`,
          id: `${t.id}`,
          i18n_id: t.i18n_id,
          title
        });
      });
      return { ...state, types: newTypes, selected: newTypes[0] };
    }
    if (props.saveAction && props.saveAction.success) {
      props.actionsClear();
      const newTypes = props.allTypes.map((t) => {
        const title = t.i18n_id
          ? props.intl.formatMessage({ id: `lease.property.${t.i18n_id}` })
          : t.name;
        return ({
          pk: `${t.id}`,
          id: `${t.id}`,
          i18n_id: t.i18n_id,
          title
        });
      });
      return { ...state, types: newTypes, selected: newTypes[0], saveStatus: true };
    }
    return state;
  }

    handleChangeType = (id, val) => {
      const { types } = this.state;
      this.setState({ saveStatus: false });
      const cat = types.find(f => f.id === id);
      if (val) {
        cat.title = val;
        cat.i18n_id = '';
        this.setState({ types: [...types] });
      } else {
        this.setState({ types: types.filter(c => c.id !== id) });
      }
    };

    handleSave = () => {
      const { types } = this.state;
      const { actionUpdateTypes } = this.props;
      // Updates the types
      const parsedTypes = types.map((e) => {
        if (e.pk) {
          return {
            id: e.pk,
            name: (e.i18n_id ? '' : e.title),
            i18n_id: (e.i18n_id || '')
          };
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return {
            id: e.pk,
            name: (e.i18n_id ? '' : e.title),
            i18n_id: (e.i18n_id || '')
          };
        }
      });
      actionUpdateTypes({ types: parsedTypes });
    };

    render() {
      const { saveAction } = this.props;
      const { types, selected, typesLength, saveStatus } = this.state;
      return (
    <>
      <SectionHeader icon={icon} translationID="lease.property.settings" defaultTitle="Properties Settings" />
      <HeaderBack
        translationID="inspections.new.prev"
        translationDefault="Back to Operations Log"
        backRoute={`${LEASE_HOME}/properties`}
      />
      <div className={createStyles.box}>
        <div className={`${stepStyles.step2} ${styles.content}`}>
          <div className={stepStyles.title}>
            <FormattedMessage tagName="h5" id="lease.property.list"
              defaultMessage="Property Types List"
            />
          </div>
          <div className={styles.categories}>
            <div>
              <FormattedMessageWithClass className={styles.colName}
                id="lease.property.types" defaultMessage="Types"
              />
              {types && types.map(c => (
                <div key={c.id} className={`${styles.category} ${selected && (c.id === selected.id) ? styles.active : ''}`}
                  onClick={() => this.setState({ selected: c })} onKeyPress={() => ({})} role="button" tabIndex="0"
                >
                  <EditableText item={c} ops defaultFocused={!c.title} changeField={this.handleChangeType} />
                </div>
              ))}
              <div className={styles.addBtn}>
                <Button
                  action="secondary"
                  onClick={() => { this.setState({ typesLength: typesLength + 1 }); this.setState({ types: [...types, { id: typesLength, title: '' }] }); }}
                  translationID="operations.builder.add" defaultText="Add"
                />
              </div>
            </div>
            <div />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.saveWrapper}>
            <Spinner active={saveAction && saveAction.loading} />
            {saveStatus && (
            <FormattedMessage id="operations.builder.saved"
              defaultMessage="Saved"
            />
            )}
            <Button action="secondary" onClick={this.handleSave}
              translationID="logs.builder.save" defaultText="Save"
            />
          </div>
        </div>
      </div>
    </>
      );
    }
}

PropertiesBuilder.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionUpdateTypes: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  actionsClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  allTypes: state.lease.allTypes,
  saveAction: state.lease.saveAction
});

const mapDispatchToProps = dispatch => ({
  actionUpdateTypes: (types) => {
    dispatch(uploadPropertyTypes(types));
  },
  fetchTypes: () => {
    dispatch(fetchPropertyTypes());
  },
  actionsClear: () => {
    dispatch(clear());
  }
});

export default Permissions(['change_leaseproperties'])(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(PropertiesBuilder)),
  <Forbidden />
);
