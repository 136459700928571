import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Permissions from 'react-redux-permissions';
import ColorSelect from '../../../components/colorSelect';
import styles from './map.module.scss';
import Button from '../../../components/button';
import Clickable from '../../../components/clickable/Clickable';


const SurfaceForm = ({ types,
  info,
  onTypeChange,
  onInputChange,
  onSave,
  onDelete,
  onCancel,
  onEdit,
  shouldShowErrors,
  spaceError,
  nameLengthError,
  createAction
}) => (
  <div className={styles.popup}>
    <div className={styles.head}>
      <Clickable onClick={onCancel} className={styles.cancel}>
        <FormattedMessage id="surfaces.cancel" defaultMessage=" Cancel" />
      </Clickable>
      <div className={styles.actions}>
        { info.id && (
        <Clickable onClick={onDelete} className={styles.delete}>
          <FormattedMessage id="surfaces.delete" defaultMessage="Delete" />
        </Clickable>
        )}
        { info.id
          ? (
            <Permissions allowed={['can_modify_airport_settings']}>
              <Button translationID="surfaces.edit" onClick={onEdit} defaultText="Update" action="secondary" />
            </Permissions>
          )
          : (
            <Permissions allowed={['can_modify_airport_settings']}>
              <Button translationID="surfaces.create" onClick={onSave} defaultText="Create" action="secondary" />
            </Permissions>
          )
          }
      </div>
    </div>
    <label htmlFor="name" className={styles.name}>
      <span className={styles.paddingStyle}>
        <small style={{ color: 'red' }}>*</small>
        <FormattedMessage id="Surface.settings.name" defaultMessage="Name" />
      </span>
      <input
        id="name"
        name="name"
        type="text"
        value={info.name}
        onChange={e => onInputChange(e.target.value, e.target.id)} required
      />
      <br />
      {shouldShowErrors && !info.name && (
      <span className={styles.error}>
        <FormattedMessage id="surfaces.name.error"
          defaultMessage="Name cannot be Empty"
        />
      </span>
      )}
      {spaceError && !info.name.trim() && (
      <span className={styles.error}>
        <FormattedMessage id="surfaces.name.space_error"
          defaultMessage="Spaces are not allowed"
        />
      </span>
      )}
      {nameLengthError && info.name.length > 250 && (
      <span className={styles.error}>
        <FormattedMessage id="surfaces.name.length_error"
          defaultMessage="Name cannot be more than 250 characters"
        />
      </span>
      )}
    </label>
    <label htmlFor="type">
      <FormattedMessage id="Surface.settings.type" defaultMessage="Type" />
      <ColorSelect options={types}
        value={info.surface_type}
        bordered
        onChange={onTypeChange}
      />
    </label>
  </div>
);

export default injectIntl(SurfaceForm);

SurfaceForm.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object).isRequired
};
