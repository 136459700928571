import React, { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { EditedMessage } from "../EditedMessage";
import { Wrapper } from "./MessageForm.styles";
import { Avatar } from "../../../../components/avatar";
import { messageSchema } from "../../validators/messageSchema";
import { TextArea } from "../../../../components/forms";
// import { ReactComponent as InsertMention } from "../../../../icons/insert-mention.svg";
// import { ReactComponent as InsertSmile } from "../../../../icons/insert-smile.svg";
import { UploadFiles } from "../../containers/UploadFiles";
import { ReactComponent as Send } from "../../../../icons/send.svg";
import Loading from "../../../../components/loading";
import { TextAreaProps as LibTextAreaProps } from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea';
import {
    CurrentUser,
    MessageFormValues,
    UserType,
    CreateMessagesTypes,
    SetAttachmentArrFunc,
    MessageUnionType
} from "../../types";
import { ChatTypes } from "../../enums";

type MessageFormProps = {
  uploadFileMode: boolean;
  loading: boolean;
  topicsConnected: boolean;
  isComment: boolean;
  minRows?: number;
  onChange?:(
    event: React.SyntheticEvent<HTMLTextAreaElement>,
    data?: LibTextAreaProps
  ) => void;
  maxRows?: number;
  type?: ChatTypes;
  userDetails: UserType;
  initValues: MessageFormValues;
  currentUser: CurrentUser;
  channelName: string;
  onHeightChange: (height: number) => void;
  onSubmit: (
    values: any,
    { resetForm, setSubmitting }: { resetForm: any; setSubmitting: any }
  ) => Promise<void> | void;
  createMessages: CreateMessagesTypes;
  setAttachmentArr: SetAttachmentArrFunc;
  attachedFiles: File[];
  editedMessage: MessageUnionType;
  closeEdit: () => void;
};

const MIN_ROWS = 3;
const MAX_ROWS = 9;

const MessageForm = React.memo<MessageFormProps>(
  ({
    uploadFileMode,
    loading,
    isComment,
    initValues,
    onChange,
    onSubmit,
    currentUser,
    type,
    minRows = MIN_ROWS,
    maxRows = MAX_ROWS,
    channelName,
    userDetails: { profilePicture, firstName, lastName },
    onHeightChange,
    createMessages,
    setAttachmentArr,
    attachedFiles,
    editedMessage,
    closeEdit,
    topicsConnected
  }) => {
    const submitBtn = useRef<HTMLButtonElement>(null);
    // const submitEvent = useRef<KeyboardEventHandler>(null);
    const [color, setColor] = useState({backgroundColor: 'white'})
    const entersend = (e) =>{
      if (e.eventPhase===1){
        setColor({backgroundColor: 'white'})
      }else{
      setColor({backgroundColor: '#E8E8E8'})
    }
      if(e.nativeEvent.key==="Enter"&& !e.shiftKey) {
        e.preventDefault();
          submitBtn.current.click()
      }
    }
    return (
      <>
        {editedMessage && (
          <EditedMessage editedMessage={editedMessage} closeEdit={closeEdit} />
        )}
        <Wrapper uploadFileMode={uploadFileMode}>
          {loading && <Loading loadingStatus={loading} />}
          {!uploadFileMode && (
            <Avatar src={profilePicture} name={`${firstName} ${lastName}`} />
          )}
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={messageSchema}
            onSubmit={onSubmit}
            render={({ isSubmitting }) => (
              <Form
              onKeyPress={entersend}
              onClick={entersend}
              onBlur={entersend}
              >
                <Field
                  key="content"
                  name="content"
                  render={formProps => (
                    <div className="text-area">
                      <div >
                        <TextArea
                          placeholder={`Send a message to #${channelName}`}
                          onHeightChange={onHeightChange}
                          minRows={minRows} 
                          maxRows={maxRows}
                          noMargin
                          bordered={false}
                          disabled={isSubmitting || topicsConnected}
                          {...formProps}
                        />
                      </div>

                      <div className="options"  style={color}>
                        {/* <InsertMention /> */}
                        {/* <InsertSmile /> */}
                        <Send
                          onClick={() => submitBtn.current.click()}
                          className="submit-btn"
                          />
                        {!(uploadFileMode || isComment) && (
                          <UploadFiles
                          type={type}
                          currentUser={currentUser}
                          createMessages={createMessages}
                          setAttachmentArr={setAttachmentArr}
                          attachedFiles={attachedFiles}
                          />
                          )}
                    </div>
                    </div>
                  )}
                />
                <button
                  type="submit"
                  ref={submitBtn}
                  hidden
                  disabled={isSubmitting || topicsConnected}
                />
              </Form>
            )}
          />
        </Wrapper>
      </>
    );
  }
);

export default MessageForm;
export { MessageForm };
