import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { LEASE_HOME } from '../../../../../constants/RouterConstants';

import Status from './Status';

import styles from '../../../../../styles/general.module.scss';

const Table = ({ info }) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th>
          <FormattedMessage id="workorder.detail.request.logged_by" defaultMessage="Logged by" />
        </th>
        <th>
          <FormattedMessage id="workorders.list.table.reported" defaultMessage="Reported" />
        </th>
        <th>
          <FormattedMessage id="workorder.create.location" defaultMessage="Location" />
        </th>
        <th>
          <FormattedMessage id="workorders.list.table.category" defaultMessage="Category" />
        </th>
        <th>
          <FormattedMessage id="workorders.list.table.status" defaultMessage="Status" />
        </th>
        <th>
          <FormattedMessage id="workorder.detail.request.assigned_to" defaultMessage="Assigned to" />
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      {!info.length > 0 ? (
        <tr>
          <td className="noTableData" colSpan={7}>
            <FormattedMessage id="WorkOrders.list.table.no_results" defaultMessage="There are no workorders on the selected range" />
          </td>
        </tr>
      )
        : (
          info.map(e => (
            <tr key={e.id}>
              <td>{e.logged_by && e.logged_by.fullname}</td>
              <td className={styles.date}>{(moment(e.report_date)).format('MM/DD/YYYY hh:mm A')}</td>
              <td>{e.location}</td>
              <td>{e.category}</td>
              <td><Status item={e.status} /></td>
              <td>
                {e.assigned_role && e.assigned_role.name}
                {e.assigned_users && e.assigned_users.length > 0 && e.assigned_users[0].fullname}
              </td>
              <td>
                <Link to={`${LEASE_HOME}/maintenance/${e.order_number}/detail`}>
                  <FormattedMessage id="workorders.list.table.action" defaultMessage="View" />
                </Link>
              </td>
            </tr>
          ))
        )
        }
    </tbody>
  </table>
);

Table.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default Table;
