import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';

/** ******************************************************************
 *  Redux import
 * ***************** */
import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';
import { fetchInspectionAnswer, clearInspectionAnswer, exportInspection, exportInspectionData } from '../redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';
import { fetchUserProfile } from '../../../components/topbar/profile/redux/actions';

/** ******************************************************************
 *  Components import
 * ***************** */
import HeaderBack from '../../../components/headerBack';
import SectionHeader from '../../../components/sectionHeader';
import IconButton from '../../../components/iconButton';
import DataSummary from './components/DataSummary/DataSummary';
import AdditionalInformation from './components/AdditionalInformation/AdditionalInformation';
import Selector from './components/Selector/Selector';
import FieldDetail from './components/FieldDetail/FieldDetail';
import Separator from '../../../components/separator';
import { getShiftTypeByDate } from '../../../utils/helpers';

/** ******************************************************************
 *  Assets import
 * ************* */

import styles from '../inspections.module.scss';
// import icon from '../../../icons/icon.svg';
// import search from '../../../icons/search.svg';
// import settings from '../../../icons/settings.svg';
import listview from '../../../icons/listview.svg';
import allview from '../../../icons/allview.svg';
import editIcon from '../../../icons/inspection-icons/edit.svg';

class SubmissionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeInspectionField: undefined,
      view: 'list',
      inspectionDropdown: false,
    };

    this.changeSelectedField = this.changeSelectedField.bind(this);
    this.exportInspection = this.exportInspection.bind(this);
    this.exportInspectionData = this.exportInspectionData.bind(this);
  }

  componentDidMount() {
    const { actionFetch, match: { params: { id } }, shiftTypes, actionFetchShiftTypes, actionFetchUserProfile, profile } = this.props;
    actionFetch(id);
    if (!profile.length) actionFetchUserProfile();
    if (!shiftTypes.length) actionFetchShiftTypes();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.activeInspectionField === undefined && Object.keys(props.answer.version).length) {
      let flag = true;
      let activeIdx = 0;
      props.answer.version.schema.fields.forEach((field) => {
        if (field.type === 'inspection') {
          const checklist_length = Object.keys(field.checklist.filter(
            item => !item.shifts || item.shifts.indexOf(props.answer.shift_name) !== -1 || item.shifts.length === 0
          )).length;
          if (checklist_length > 0 && flag) {
            flag = false;
            activeIdx = field.id;
          }
        }
      });
      return {
        ...state,
        activeInspectionField: activeIdx
      };
    }
    return state;
  }

  // componentWillReceiveProps(nextProps) {
  //   const { answer: { id: answerId } } = this.props;
  //   const { answer: { id: nextAnswerId, version } } = nextProps;
  //   if (!answerId && nextAnswerId) {
  //     this.setState({
  //       activeInspectionField: version.schema.sections[1].fields[0]
  //     });
  //   }
  // }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  // getuserPerm() {
  //   const { profile }= this.props;
  //   for (let i=0; i<profile.roles.length; i+=1) {
  //     for (let j=0; j<profile.roles[i].permissions.length; j+=1) {    
  //       if (profile.roles[i].permissions[j].codename === 'change_inspectionanswer') {
  //         return true;
  //       }
  //     }
  //   }
  // }

  handleDropDown = () => {
    this.setState(prevState => ({
      inspectionDropdown: !prevState.inspectionDropdown
    }));
  }

  changeSelectedField(fieldId) {
    this.setState({
      activeInspectionField: fieldId
    });
  }

  exportInspection(id) {
    const { actionexportInspection } = this.props;
    actionexportInspection(id);
  }

  exportInspectionData(id, data) {
    const { actionexportInspectionData, answer, shiftTypes } = this.props;
    const additionalFields = answer.version.schema.fields.filter(f => f.type !== 'inspection' && f.addInfo === true);
    const data1 =[];
    if (additionalFields.length > 0) {
      additionalFields.forEach((e) => {
        if (e.type === 'date') {
          data1.push({ id: e.title, data: moment(answer.response[e.id]).format('MM/DD/YYYY'), type: e.type });
        } else if (e.type === 'datetime') {
          data1.push({ id: e.title, data: moment(answer.response[e.id]).format('MM/DD/YYYY hh:mm A'), type: e.type });
        } else if (e.type === 'select') {
          const f = answer.version.schema.fields.find(x => x.id === e.id);
          const val = f.values.find(x => x.key === answer.response[e.id]).value;
          data1.push({ id: e.title, data: val, type: e.type });
        } else if (e.type === 'multiselect') {
          const v1 =[];
          const f = answer.version.schema.fields.find(x => x.id === e.id);
          answer.response[e.id].map(val => v1.push(f.values.find(x => x.key === val).value));
          data1.push({ id: e.title, data: v1, type: e.type });
        } else {
          data1.push({ id: e.title, data: answer.response[e.id], type: e.type });
        }
      });
    }
    const shift =getShiftTypeByDate(moment(answer.inspection_date).format('YYYY-MM-DDTHH:mm:ssz'), shiftTypes);
    data1.push({ shift_name: shift.name });
    data1.push({ inspection_date: (moment(answer.inspection_date)).format('MM/DD/YYYY hh:mm A') }); 
    actionexportInspectionData(id, data1);
  }

  toggle(view) {
    this.setState({ view });
  }

  goToInspection(id, answerId) {
    const { history } = this.props;
    history.push(`${INSPECTIONS_HOME_ROUTE}${id}/${answerId}/edit-inspection`);
  }

  goToInspectionRoute(id) {
    const { history } = this.props;
    history.push(`${INSPECTIONS_HOME_ROUTE}${id}/inspection-route`);
  }

  render() {
    const { shiftTypes } = this.props;
    const { answer, match: { params: { id } } } = this.props;
    const {
      answer: {
        open_workorders,
        version,
        response,
        remarks,
        shift_name,
        ...inspection
      }
    } = this.props;
    let icon = '';
    if (inspection.icon) {
      icon = inspection.icon.includes('.png') ? require(`../../../icons/inspection-icons/${inspection.icon}`)
        : require(`../../../icons/inspection-icons/${inspection.icon}.svg`);
    }

    const { activeInspectionField, view, inspectionDropdown } = this.state;
    if (!version.form_id) return null;
    let inspFields = version.schema.fields.filter(f => f.type === 'inspection');
    if (inspFields.length > 0) inspFields = inspFields.reduce((all, field) => ({ ...all, [field.id]: field }), {});

    let additionalFields = version.schema.fields.filter(f => f.type !== 'inspection');
    if (additionalFields.length > 0) additionalFields = additionalFields.reduce((all, field) => ({ ...all, [field.id]: field }), {});
    return (
      <>
        <SectionHeader icon={icon} translationID="invalid" defaultTitle={answer.inspection}>
          {/* <div className={styles.detailHeader}>
            <IconButton icon={search} />
            <IconButton icon={settings} />
            <Separator />
          </div> */}
          <div className={styles.detailHeader}>
            <span className={styles.actionsBtn} onClick={() => this.handleDropDown()}>
                <FormattedMessage id="workorders.detail.actionBtn"
                defaultMessage="Actions"
            />
            </span>
            {inspectionDropdown && (
              <ul className={`${styles.dropdown} ${inspectionDropdown ? styles.open : ''}`}>
                {answer.response.path_taken && (
                <li className={styles.item}>
                  <span onClick={() => this.goToInspectionRoute(id)}
                    defaultText="View Route" action="secondary" translationID="inspections.answer_details.ViewRoute"
                  >
                    <FormattedMessage id="inspections.answer_details.ViewRoute"
                      defaultMessage="View Route"
                    />
                  </span>
                </li>
                )}
                <li className={styles.item}>
                  <span onClick={() => this.exportInspectionData(id)} translationID="workorders.detail.pdfBnt"
                    defaultText="Print Pdf" action="secondary"
                  >
                    <FormattedMessage id="workorders.detail.print"
                      defaultMessage="Print"
                    />
                  </span>
                </li>
              </ul>
            )}
            <Separator />
          </div>
          
        </SectionHeader>
        <HeaderBack
          translationID="inspections.new.prev"
          translationDefault="Back"
          backRoute={INSPECTIONS_HOME_ROUTE}
        />
        <div className={styles.container}>
          <DataSummary answer={answer} inspection={inspection} shiftTypes={shiftTypes} />
          <div className={styles.additional}>
            <AdditionalInformation answer={answer} fields={additionalFields} active />
          </div>
          <div className={styles.viewSelector}>
            <FormattedMessage tagName="h4"
              id="inspections.details.title"
              defaultMessage="Inspections Details"
            />
            <div className={styles.buttons}>
              {inspection.access_permission=== true
                ?<IconButton icon={editIcon} onClick={() => { this.goToInspection(version.form_id, id); }} />:''}
              <IconButton icon={listview} onClick={() => this.toggle('all')} />
              <IconButton icon={allview} onClick={() => this.toggle('list')} />
            </div>
          </div>

          <div className={styles.view}>
            {view === 'list' && (
            <Selector form={version.schema} answers={response} shift={shift_name}
              activeInspectionField={activeInspectionField}
              changeSelectedField={this.changeSelectedField}
            />
            )}
            <FieldDetail
              full={view === 'all'} fields={inspFields} remarks={remarks}
              selected={activeInspectionField} answers={response} shift={shift_name}
              workorders={open_workorders} exportInspection={this.exportInspection}
              exportInspectionData={this.exportInspectionData} answerId={id}
              inspectionid={version}
            />
          </div>
        </div>

      </>
    );
  }
}


SubmissionDetail.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  answer: PropTypes.shape({}).isRequired,
  actionexportInspection: PropTypes.func.isRequired,
  actionexportInspectionData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  shiftTypes: PropTypes.arrayOf(PropTypes.shape({})),
  exportInspectionData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  answer: state.inspection.answer,
  shiftTypes: state.settings.shiftTypes,
  profile: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  // Fetch inspection
  actionFetch: (id) => {
    dispatch(fetchInspectionAnswer(id));
  },
  actionClear: () => {
    dispatch(clearInspectionAnswer());
  },
  actionexportInspection: (id) => {
    dispatch(exportInspection(id));
  },
  actionexportInspectionData: (id, data,data2) => {
    dispatch(exportInspectionData(id, data, data2));
  },
  actionFetchUserProfile: () => {
    dispatch(fetchUserProfile());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionDetail);

// const Separator = () => <div className={styles.separator} />;
