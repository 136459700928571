import {
  IActionTypes,
  CommentsActionType,
  SetComments,
  SetListComments,
  HideSidebar,
  SubTopicsCollectionAction,
  TopicsCollectionAction,
  CommentPropsAction,
  SetEditedCommentAction,
  UpdateCommentAction,
  DeleteCommentAction,
  UpdateCommentDataAction,
  DeletedMessageAction
} from './types';

export const actions: IActionTypes = {
  show: (dispatch, props) => dispatch<SetComments>({ type: CommentsActionType.show, payload: props }),
  hide: dispatch => dispatch<HideSidebar>({ type: CommentsActionType.hide }),
  setCommentsList: (dispatch, props) => dispatch<SetListComments>({
    type: CommentsActionType.setCommentsList,
    payload: props
  }),
  setDeletedCommentID: (dispatch, props) => dispatch<DeletedMessageAction>({
    type: CommentsActionType.setDeletedCommentID,
    payload: props
  }),
  setTopicsCollection: (dispatch, props) => dispatch<TopicsCollectionAction>({
    type: CommentsActionType.setTopicsCollection,
    payload: props
  }),
  updateTopicsCollection: dispatch => dispatch<SubTopicsCollectionAction>({
    type: CommentsActionType.updateTopicsCollection
  }),
  setNewMessage: (dispatch, props) => dispatch<CommentPropsAction>({
    type: CommentsActionType.setNewMessage,
    payload: props
  }),
  setEditedMessage: (dispatch, props) => dispatch<SetEditedCommentAction>({
    type: CommentsActionType.setEditedMessage,
    payload: props
  }),
  updateMessage: (dispatch, props) => dispatch<UpdateCommentAction>({
    type: CommentsActionType.updateMessage,
    payload: props
  }),
  deleteMessage: (dispatch, props) => dispatch<DeleteCommentAction>({
    type: CommentsActionType.deleteComment,
    payload: props
  }),
  updateCommentData: (dispatch, props) => dispatch<UpdateCommentDataAction>({
    type: CommentsActionType.updateCommentData,
    payload: props
  })
};
