import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

class NotamsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selectedNotams
    };
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
  }

  handleOnSelect = async (row, isSelect) => {
    const { notamSelect } = this.props;
    const { selected } = this.state;
    if (isSelect) {
      this.setState({
        selected: [...selected, row.id].sort()
      });
      notamSelect(row, isSelect, 's');
    } else {
      await this.setState({ selected: selected.filter(it => it !== row.id) });
      notamSelect(row, isSelect, 's');
    }
    return false;
  }
  
  handleOnSelectAll = (isSelect, rows) => {
    const { notamSelect } = this.props;
    notamSelect(rows, isSelect, 'a');
    if (!isSelect) {
      this.setState({ selected: [] });
    }
    return false;
  }

  render() {
    const { SearchBar } = Search;
    const { notamData, tableHeader } = this.props;
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      style: { backgroundColor: '#c8e6c9' },
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      selected: this.state.selected
    };
    const options = {
      // pageStartIndex: 0,
    };
    return (
      <div>
        <ToolkitProvider
          keyField="id"
          data={notamData}
          columns={tableHeader}
          search
        >
          {
            props => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <BootstrapTable
                  striped
                  hover
                  keyField='id' 
                  data={notamData}
                  columns={tableHeader}
                  selectRow={selectRow}
                  pagination={paginationFactory(options)}
                  style={{ width: 'auto' }}
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }
}

NotamsTable.propTypes = {
  selectedNotams: PropTypes.shape([])
};

NotamsTable.defaultProps = {
  selectedNotams: []
};

export default NotamsTable;
