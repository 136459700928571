const prefixes = {
  messenger: '/messenger',
  settings: '/settings',
  ops: '/ops',
  wildlife: '/wildlife',
  lease: '/lease'
};

export const uiRouts = {
  root: '/',
  todo: '/todo',
  wildlife: prefixes.wildlife,
  wildlifeSettings: `${prefixes.wildlife}${prefixes.settings}`,
  wildlifeDashboard: `${prefixes.wildlife}/dashboard`,
  settings: prefixes.settings,
  settingsOrganization: `${prefixes.settings}/organization`,
  profile: '/profile',
  ops: prefixes.ops,
  opsOperations_Dashboard: `${prefixes.ops}/operations_dashboard`,
  opsAirfieldConditions:`${prefixes.ops}/airfields`,
  opsInspections: `${prefixes.ops}/inspections`,
  opsWorkorders: `${prefixes.ops}/workorders`,
  opsAssets: `${prefixes.ops}/assets`,
  opsLogs: `${prefixes.ops}/logs`,
  opsSettings: `${prefixes.ops}/settings`,
  messenger: prefixes.messenger,
  channels: `${prefixes.messenger}/channels`,
  directMessages: `${prefixes.messenger}/direct-messages`,
  createNewMessage: `${prefixes.messenger}/create-new-messages`,
  documents: `${prefixes.messenger}/documents`,
  irrops: `${prefixes.messenger}/irrops`,
  createNewChannel: `${prefixes.messenger}/create-new-channel`,
  editChannel: `${prefixes.messenger}/edit-channel`,
  leaseProperties: `${prefixes.lease}/properties`,
  lease: `${prefixes.lease}`,
  leaseTenants: `${prefixes.lease}/tenants`,
  leaseMaintenance: `${prefixes.lease}/maintenance`,
  leaseSettings: `${prefixes.lease}/settings`
};
