import React from 'react';
import { FormattedMessage } from 'react-intl';
import PulpoField from '../../../../../../pulpo_visualizer/fields/PulpoField';
import FeeForm from './components/FeeForm';
import Documents from './components/Documents';
import styles from '../steps.module.scss';
import Clickable from '../../../../../../components/clickable/Clickable';

const Step3 = ({
  multipleDocs,
  multipleFees,
  handleMultipleAnswers,
  handleAnswerList,
  handleAnswerChanged,
  fixedFee
}) => {
  const handleAnswer = (section, answer, fieldID, index, list) => {
    const change = list.filter((e, i) => i === index);
    change[0][fieldID.valueOf()] = answer;
    const newList = [];
    list.forEach((e, i) => {
      if (i === index) {
        newList.push(change[0]);
      } else {
        newList.push(e);
      }
    });
    handleAnswerList(section, newList);
  };

  return (
    <div className={styles.stepTwo}>
      <div className={styles.lease}>
        <FormattedMessage id="lease.tenants.steps.step3.fee" defaultMessage="Fees" />
      </div>
      <div className={styles.sectionHead}>
        <FormattedMessage id="lease.tenants.steps.step3.fixed_fee" defaultMessage="Fixed Fees" />
      </div>
      <div className={styles.displayFlex}>
        <PulpoField
          key="annual_business_fee" id="annual_business_fee" type="currency-phone"
          translationID="lease.tenants.steps.step3.business_fee"
          title="Annual Business Fee" thousandSeparator
          placeHolderValue="$" suffix=".00" prefix="$"
          handleValueChange={(a, b) => handleAnswerChanged('fixedFee', a, b)}
          answer={fixedFee && fixedFee.annual_business_fee}
          handleFieldErrorChanged={() => {}}
        />
        <PulpoField
          key="landing_fee" id="landing_fee" type="currency-phone"
          translationID="lease.tenants.steps.step3.land_fee"
          title="Landing Fee" thousandSeparator
          placeHolderValue="$" suffix=".00" prefix="$"
          handleValueChange={(a, b) => handleAnswerChanged('fixedFee', a, b)}
          answer={fixedFee && fixedFee.landing_fee}
          handleFieldErrorChanged={() => {}}
        />
      </div>
      <div className={styles.displayFlex}>
        <PulpoField
          key="ramp_fee" id="ramp_fee" type="currency-phone"
          translationID="lease.tenants.steps.step3.ramp_fee"
          title="Ramp Fee" thousandSeparator
          placeHolderValue="$" suffix=".00" prefix="$"
          handleValueChange={(a, b) => handleAnswerChanged('fixedFee', a, b)}
          answer={fixedFee && fixedFee.ramp_fee}
          handleFieldErrorChanged={() => {}}
        />
      </div>
      {multipleFees && multipleFees.length > 0 && multipleFees.map((e, index) => (
      <>
        <FeeForm
          handleAnswerChanged={(ans, field) => handleAnswer('multipleFees', ans, field, index, multipleFees)}
          info={e}
        />
    </>
      ))}
      <Clickable className={styles.addLink}
        onClick={() => handleMultipleAnswers('multipleFees', multipleFees)}
      >
        <FormattedMessage id="lease.tenants.steps.step3.add_fee" defaultMessage="Add new fee  +" />
      </Clickable>


      <div className={styles.lease}>
        <FormattedMessage id="lease.tenants.steps.step3.document" defaultMessage="Additional Documents" />
      </div>
      {multipleDocs && multipleDocs.length > 0 && multipleDocs.map((e, index) => (
      <>
        <Documents
          handleAnswerChanged={(ans, field) => handleAnswer('multipleDocs', ans, field, index, multipleDocs)}
          info={e}
        />
      </>
      ))}
      <Clickable className={styles.addLink}
        onClick={() => handleMultipleAnswers('multipleDocs', multipleDocs)}
      >
        <FormattedMessage id="lease.tenants.steps.step3.add_document" defaultMessage="Add new document  +" />
      </Clickable>
    </div>
  );
};

export default Step3;
