import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { injectIntl } from 'react-intl';

import { changeCurrentPage } from '../general_redux/actions';
import Shortcuts from '../components/topbar/shortcuts/shortcuts';
import OperationsDashBoardLayout from './operations_dashboard/OperationsDashBoardlayout';
import InspectionLayout from './inspections/InspectionLayout';
import WorkOrdersLayout from './workorders/WorkOrdersLayout';
import AssetsLayout from './assets-management/AssetsLayout';
// import Settings from './opsSettings/Settings';
import SettingsLayout from './opsSettings/SettingsLayout';
import Forbidden from './Forbidden';
import OperationsLogBuilder from './operations-logs/Builder/OperationLogBuilder';
import OperationsLogList from './operations-logs/List/OperationLogList';
import OperationsLogCreate from './operations-logs/Create/OperationLogCreate';
import AirfieldCondition from './airfields-condition-reporting/AirFieldCondition';

class OperationsLayout extends Component {
  links = [
    { url: '/ops/operations_dashboard/', name: this.props.intl.formatMessage({ id: 'shortcuts.operations_dashboard' }), permissions: ['view_dashboard'], key: 'operations_dashboard'},
    { url: '/ops/inspections/', name: this.props.intl.formatMessage({ id: 'shortcuts.inspections' }), permissions: [], key: 'inspections' },
    { url: '/ops/workorders/', name: this.props.intl.formatMessage({ id: 'shortcuts.workorders' }), permissions: ['view_workorder'], key: 'work_orders' },
    { url: '/ops/assets/', name: this.props.intl.formatMessage({ id: 'shortcuts.assets' }), permissions: ['view_asset'], key: 'assets' },
    { url: '/ops/logs', name: this.props.intl.formatMessage({ id: 'shortcuts.opslog' }), permissions: ['view_log'], key: 'operation_logs' },
    { url: '/ops/settings', name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }), permissions: ['view_surfaceshape', 'can_modify_airport_settings'], key: 'settings' }
  ];

  componentDidMount() {
    const { actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('operations');
  }

  render() {
    const { match, user } = this.props;
    return (
      // <Permissions allowed={['view_workorder']} fallbackElement={<Forbidden />}>
      <Permissions fallbackElement={<Forbidden />}>
        { user.language && <Shortcuts links={this.links} /> }
        <Switch>
          <Route path={`${match.url}/operations_dashboard`} component={OperationsDashBoardLayout} />
          <Route path={`${match.url}/inspections`} component={InspectionLayout} />
          <Route path={`${match.url}/workorders`} component={WorkOrdersLayout} />
          <Route path={`${match.url}/assets`} component={AssetsLayout} />
          <Route path={`${match.url}/settings`} component={SettingsLayout} />
          <Route path={`${match.url}/airfield-condition-report`} component={AirfieldCondition} />
          <Route exact path={`${match.url}/logs`} component={OperationsLogList} />
          <Route exact path={`${match.url}/logs/settings`} component={OperationsLogBuilder} />
          <Route exact path={`${match.url}/logs/new`} component={OperationsLogCreate} />
          <Route exact path={`${match.url}/logs/:id`} component={OperationsLogCreate} />
        </Switch>
      </Permissions>
    );
  }
}

WorkOrdersLayout.propTypes = {
  match: PropTypes.shape({}).isRequired,
  actionUpdateCurrentPage: PropTypes.func
};

const mapStateToProps = state => ({
  currentModule: state.general.currentModule,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OperationsLayout));
