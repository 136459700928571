/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { Redirect } from 'react-router-dom';
import { HOC as Permissions } from 'react-redux-permissions';
import { FormattedMessage } from 'react-intl';
import htmlToImage from 'html-to-image';
import { getShiftTypeByDate } from '../../../utils/helpers';

/** ******************************************************************
 *  Redux import
 * ***************** */
import { WORKORDERS_HOME_ROUTE, WORK_ORDERS_DETAIL } from '../../../constants/RouterConstants';
import { createWorkOrder, fetchWorkOrderSchema, clearActionResult, fetchNotams } from '../redux/actions';
import { searchUser, fetchSafetySelfInspection, fetchDataSourceList } from '../../inspections/redux/actions';
import { fetchAssets, fetchAssetTypes, fetchAssetsNames, clearAssetTypes } from '../../assets-management/redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';
/** ******************************************************************
 *  Components import
 * ***************** */

import HeaderBack from '../../../components/headerBack';
import SectionHeader from '../../../components/sectionHeader';
import Button from '../../../components/button';
import Panel from '../../../components/panel';
import FixedFields from './components/FixedFields';
import FixedFieldsForInspections from './components/FixedFieldsForInspections';
import PulpoField from '../../../pulpo_visualizer/fields/PulpoField';
import Forbidden from '../../Forbidden';
import NotamsTable from './components/NotamsTable';
import Loading from '../../../components/loading';
/** ******************************************************************
 *  Assets import
 * ***************** */
import styles from './workOrderCreate.module.scss';
import wo from '../../../icons/WorkOrders.png';

/** ******************************************************************
 * Get Upload File Names And  Upload Files to S3
 * **************************************************************** */
import { GetFileName, S3Uploads } from '../../services';
import Modal from '../../../components/modal';

class WorkOrderCreate extends Component {
  state = {
    info: {
      report_date: moment().format(),
      photos: [],
      logged_by: {},
      assets: [],
      priority: '0',
      Polygon: []
    },
    answers: {},
    shifts: undefined,
    requiredMap: {},
    assetSelected: false,
    showFormErrors: false,
    fieldErrors: {
      logged_by: false,
      report_date: false,
      category: true,
      subcategory: true,
      priority: true,
      problem_description: true,
      location: true
    },
    notamSelectedList: {},
    notamStatus: false,
    zoomLevel: '',
    locationModal: false,
    spaceError: false,
    selectedAsset: [],
    loadingStatus: false,
    assettype: []
  }

  firstTime = true;

  async componentDidMount() {
    const {
      selfInspection,
      actionGetSelfInpection,
      actionGetWorkOrder,
      schemas,
      assetsTypesname,
      actionfetchAssetTypes,
      category,
      subcategory,
      notams,
      actionFetchShiftTypes,
      user, actionFetchNotams,
      shiftTypes,
      reportdate,
      fetchDataSources
    } = this.props;
    const { info }= this.state;
    fetchDataSources();
    if (category && subcategory) {
      this.handleAnswerChanged('info', category, 'category');
      this.handleAnswerChanged('info', subcategory, 'subcategory');
      this.handleFieldErrorChanged('category', false);
      this.handleFieldErrorChanged('subcategory', false);
    }
    if (reportdate) {
      this.handleAnswerChanged('info', reportdate, 'report_date');
    }
    if (!selfInspection.length) actionGetSelfInpection();
    if (!schemas.length) await actionGetWorkOrder();
    // if (!assets.length) actionFetchAssets();
    if (notams.length) actionFetchNotams(user.airport.code);
    if (!assetsTypesname.length) actionfetchAssetTypes();

    if (!shiftTypes.length) actionFetchShiftTypes();
    this.getshifts(info.report_date, shiftTypes);
    if (schemas.workorder && schemas.workorder.id) {
      this.processInspectionForState(schemas.workorder);
    }
  }

  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.user.id && !state.info.logged_by.id) {
      return {
        ...state,
        info: {
          ...state.info,
          logged_by: {
            id: props.user.id,
            fullname: props.user.fullname
          },
          location: [...props.user.airport.location.coordinates]
          // airportCode: props.user.airport.code,
        }
      };
    }
    return state;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      schemas,
      clear,
      createAction,
      onCreate,
      createdWorkorder,
      shiftTypes,
      fromInspection,
      callInspections } = this.props;
    const { info, shifts } = this.state;
    if (fromInspection === true) {
      if (createAction.success === true) {
        callInspections();
      }
    }
    if (prevState.info.report_date!==info.report_date || shifts===undefined) {
      this.getshifts(info.report_date, shiftTypes);
    }
    if (this.firstTime && schemas.workorder && schemas.workorder.id) {
      this.processInspectionForState(schemas.workorder);
      this.firstTime = false;
    }
    if (prevProps.createAction.loading && createAction.success) {
      clear();
      if (fromInspection) {
        onCreate({
          id: createdWorkorder.item.id,
          problem_description: createdWorkorder.item.problem_description,
          priority: createdWorkorder.item.priority,
          order_number: createdWorkorder.item.order_number,
          status: 1,
          category_id: info.category,
          subcategory_id: info.subcategory
        });
      }
    }
    if (prevProps.createAction.loading === true && createAction.loading === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loadingStatus: false }, () => {
        clear();
      });
    }
  }

  getshifts=(report_date, shiftTypes) => {
    const shift= getShiftTypeByDate(report_date, shiftTypes);
    if (shift!== undefined) {
      this.setState({ shifts: shift.name });
    }
  }

  processInspectionForState = (workorder) => {
    if (workorder) {
      const { schema } = workorder;
      let { fieldErrors, requiredMap } = this.state;

      schema.fields.forEach((f) => {
        requiredMap = { ...requiredMap, [f.id]: f.required };
        fieldErrors = { ...fieldErrors, [f.id]: f.required };
      });
      this.setState({ requiredMap, fieldErrors });
    }
  }

    handleAnswerChanged = async (section, answer, fieldId) => {
      const { actionfetchAssetsNames, user }= this.props;
      const { fieldErrors, info } = this.state;
      const assetTypes = user.airport.types_for_self_inspection;
      if (fieldId==='category'&&info.subcategory) {
        if (assetTypes[answer]&&assetTypes[answer][info.subcategory]) {
          await actionfetchAssetsNames(undefined, assetTypes[answer][info.subcategory]);
          this.setState({
            fieldErrors: {
              ...fieldErrors,
              [fieldId]: false,
              assetName: false
            },
            info: {
              ...info,
              [fieldId]: answer,
              assetName: assetTypes[answer][info.subcategory]
            }
          });
        } else {
          this.setState({ spaceError: false });
          if (answer || section==='answers') {

            await this.setState(prevState => ({
              [section]: {
                ...prevState[section],
                [fieldId]: answer
              }
            }));
          }
        }
      } else {
        this.setState({ spaceError: false });
        if (answer || section==='answers') {

          await this.setState(prevState => ({
            [section]: {
              ...prevState[section],
              [fieldId]: answer
            }
          }));
        }
        if (assetTypes[info.category] && assetTypes[info.category][answer] &&(!info.assetName||info.assetName!==assetTypes[info.category][answer])) {
          if (assetTypes[info.category][answer]) {
            await actionfetchAssetsNames(undefined, assetTypes[info.category][answer]);
            this.setState({
              fieldErrors: {
                ...fieldErrors,
                [fieldId]: false,
                assetName: false
              },
              info: {
                ...info,
                [fieldId]: answer,
                assetName: assetTypes[info.category][answer]
              }
            });
          } else {
            await this.setState({
              fieldErrors: {
                ...fieldErrors,
                [fieldId]: false,
                assetName: true
              },
              info: {
                ...info,
                [fieldId]: answer,
                assetName: undefined
              }
            });
          }
        } else if (assetTypes[info.category] && assetTypes[info.category][answer]) {
          await this.setState({
            fieldErrors: {
              ...fieldErrors,
              assetName: false
            }
          });
        }
        // }
        if (fieldId === 'assetName') {
          if (answer >=0) {
            await actionfetchAssetsNames(answer);
          } else {
            answer.replace(' ', '_');
            await actionfetchAssetsNames('', answer);
          }
          if (answer!==''||answer!==undefined) {
            this.setState({
              fieldErrors: {
                ...fieldErrors,
                [fieldId]: false
              },
              info: {
                ...info,
                [fieldId]: answer
              }
            });
          }
        }
        if (fieldId === 'location' && answer) {
          this.setState({
            fieldErrors: {
              ...fieldErrors,
              [fieldId]: false
            }
          });
        }
      }
    }

  handleSelectedAsset = (answer) => {
    const answerArr = answer=== '' ? [] : [answer];
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        assets: answerArr
      },
      assetSelected: true
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  handleSearchForUser = (value) => {
    const { actionSearchUser } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  componentWillUnmount() {
    const { clearAssetData } = this.props;
    clearAssetData();
  }


  create = async () => {
    const {
      actionCreate,
      selfInspection,
      answerId,
      updateInspection,
      user } = this.props;

    const {
      info,
      fieldErrors,
      answers,
      zoomLevel,
      shifts,
      notamStatus,
      assetSelected } = this.state;
    let { notamSelectedList } = this.state;
    const assetTypes= user.airport.types_for_self_inspection;
    this.setState({ loadingStatus: true });

    if (info.priority) {
      fieldErrors.priority = false;
    }
    const problemDescription = info.problem_description;
    if (problemDescription && !problemDescription.trim()) {
      fieldErrors.problem_description = true;
      this.setState({ spaceError: true, loadingStatus: false, showFormErrors: true });
      return;
    }
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));

    if (noErrors) {
      if (assetTypes[info.category] && assetTypes[info.category][info.subcategory] && !assetSelected) {
        fieldErrors.location = false;
        this.setState({
          locationModal: true,
          loadingStatus: false
        });
        return;
      }
      const data = { ...info };
      data.logged_by = data.logged_by.id;
      data.zoom_level = zoomLevel;
      data.Polygon=JSON.stringify(data.Polygon);
      const location = {
        type: 'Point',
        coordinates: data.location
      };
      data.location = JSON.stringify(location);
      data.shift_name= shifts;
      if (!data.assets && data.assets.length === 0) {
        delete data.assets;
      }
      data.response = JSON.stringify(answers);
      data.category_id = data.category;
      data.subcategory_id = data.subcategory;

      if (updateInspection) {
        const filtered = updateInspection.version.schema.fields.filter(e => e.type === 'inspection');
        const insp = filtered.reduce((acc, e) => ({
          ...acc,
          [e.id]: {
            title: e.title,
            checklist: e.checklist.map(el => ({ key: el.key, value: el.value }))
          }
        }), {});
        data.subcategory = insp[data.category].checklist.find(
          e => e.key === data.subcategory
        ).value;
        data.category = insp[data.category].title;
      } else {
        data.subcategory = selfInspection[data.category].checklist.find(
          e => e.key === data.subcategory
        ).value;
        data.category = selfInspection[data.category].title;
      }
      if (!notamStatus) {
        notamSelectedList = {};
      }
      data.notams = JSON.stringify(Object.values(notamSelectedList));
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k === 'assets') {
          const assets = [];
          info.assets.map(asset => assets.push(parseInt(asset, 10)));
          formData.append(k, JSON.stringify(assets));
        } else if (k !== 'photos') {
          formData.append(k, data[k]);
        }
      });
      if (answerId) {
        formData.append('inspection_answer_reference', String(answerId));
      }
      if (updateInspection) {
        formData.append('inspection_answer_reference', String(updateInspection.id));
      }
      let fileIds = '';
      if (data.photos.length > 0) {
        fileIds = await this.getFileNames(data.photos.length);
      }
      formData.append('email_date', moment().format());
      if (fileIds) {
        await this.doS3Upload(info.photos, fileIds, 'work_orders')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attachments', id.toString());
            });
            htmlToImage.toPng(document.getElementsByClassName('leaflet-pane leaflet-map-pane')[0])
              .then((dataUrl) => {
                formData.append('work_order_snapshot', dataUrl);
                actionCreate(formData);
              });
          });
      } else {
        htmlToImage.toPng(document.getElementsByClassName('leaflet-pane leaflet-map-pane')[0])
          .then((dataUrl) => {
            formData.append('work_order_snapshot', dataUrl);
            actionCreate(formData);
          });
      }
    } else {
      this.setState({ showFormErrors: true, loadingStatus: false });
    }
  }

  // save selected notams
  notamSelect = async (selectedNotam, isSelect, selectType, notamMap) => {
    const { notamSelectedList } = this.state;
    if (selectType === 's') {
      const notam_id = selectedNotam.id;
      if (!isSelect) {
        delete notamSelectedList[notam_id];
        this.setState({ notamSelectedList });
      } else {
        notamSelectedList[notam_id] = notamMap[notam_id];
        this.setState({ notamSelectedList });
      }
    } else if (!isSelect) {
      selectedNotam.forEach((notam) => {
        delete notamSelectedList[notam.id];
      });
      this.setState({ notamSelectedList });
    } else {
      selectedNotam.forEach((notam) => {
        notamSelectedList[notam.id] = notamMap[notam.id];
      });
      this.setState({ notamSelectedList });
    }
  }

  openNotams = () => {
    const { actionFetchNotams } = this.props;
    const { info, notamStatus }= this.state;
    actionFetchNotams(info.airportCode);
    this.setState({ notamStatus: !notamStatus });
  }

  handleZoomLevel = (value) => {
    this.setState({ zoomLevel: value });
  }

  render() {
    const {
      info,
      showFormErrors,
      answers,
      spaceError,
      shifts,
      requiredMap,
      loadingStatus,
      locationModal,
      notamStatus } = this.state;
    const {
      userlist,
      selfInspection,
      createAction,
      schemas,
      errors,
      fromInspection,
      assets,
      assetsTypesname,
      translations,
      user,
      notams,
      action,
      assetsTypesdata,
      onCancel,
      assetsTypedataloading,
      createdWorkorder,
      allDataSources } = this.props;

    // Remove panel if is rendered from inspections.
    const Tag = fromInspection
      ? { name: 'div', props: '' }
      : {
        name: Panel,
        props: {
          title: 'workorders.start.title',
          defaultTitle: 'Create Work Order'
        }
      };

    // Notams table start
    const header = [
      { text: 'ID', dataField: 'id', sortable: true },
      { text: 'Text', dataField: 'notam_text', sortable: true, filterable: true },
      { text: 'Expiration Date', dataField: 'notam_expire_dtg', sortable: true, filterable: true },
      { text: 'Last Modified Date', dataField: 'notam_lastmod_dtg', sortable: true, filterable: true },
      { text: 'Effective Date', dataField: 'notam_effective_dtg', sortable: true, filterable: true }
    ];

    const notamsMap = {};
    notams.forEach((notam) => {
      notamsMap[notam.notam_id] = notam;
    });
    const notamData = notams.map(notam => ({
      id: `${notam.notam_id}`,
      notam_text: `${notam.notam_text}`,
      notam_expire_dtg: `${moment(notam.notam_expire_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`,
      notam_lastmod_dtg: `${moment(notam.notam_lastmod_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`,
      notam_effective_dtg: `${moment(notam.notam_effective_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`
    }));
    const assetTypes = user.airport.types_for_self_inspection;
    const translationMap = translations ? translations[user.language] : {};
    return (
      <>
        {!fromInspection && createAction.success
          && <Redirect push to={`${WORKORDERS_HOME_ROUTE}${createdWorkorder.item.order_number}/${WORK_ORDERS_DETAIL}`} />}
        {!fromInspection && (
          <>
            <SectionHeader icon={wo} translationID="workorders.title"
              defaultTitle="Work Orders"
            />
            <HeaderBack
              translationID="workorders.start.back"
              translationDefault="Back to Work Orders"
              backRoute={WORKORDERS_HOME_ROUTE}
            />
          </>
        )}
        <div className={`container ${styles.form}`}>
          <Tag.name {...Tag.props}>
            <div className={`${styles.content} ${styles.embedded}`}>
              {locationModal
                ?(
                  <Modal
                    width="auto"
                    minHeight="auto"
                    showIn={locationModal}
                    contentStyles={{
                      padding: ' 25px 40px 5px 30px'
                    }}
                    centered
                    onClose={() => { this.setState({ locationModal: false }); }}
                  >
                    <h3>
                      <FormattedMessage
                        id="workorders.start.location_modal"
                        defaultText="Select the Asset in the Map field"
                      />
                    </h3>

                    <div className={styles.foter}>
                      <Button onClick={() => { this.setState({ locationModal: false }); }}
                        translationID="inspections.list.ok"
                        defaultText="Ok" action="primary"
                      />
                    </div>
                  </Modal>
                )
                :''}
              {!fromInspection && (
                <FixedFields
                  info={info}
                  assetTypes={assetTypes}
                  translation={translationMap}
                  assetsTypedataloading={assetsTypedataloading}
                  assets={assets}
                  userlist={userlist}
                  shifts={shifts}
                  assetsTypesname={assetsTypesname}
                  selectedUserList={info.assets}
                  categories={selfInspection}
                  searchForUser={this.handleSearchForUser}
                  handleAnswerChanged={this.handleAnswerChanged}
                  handleFieldErrorChanged={this.handleFieldErrorChanged}
                  handleWorkOrderZoomLevel={value => this.handleZoomLevel(value)}
                  showFormErrors={showFormErrors}
                  handleSelectedAsset={this.handleSelectedAsset}
                  assetsTypesdata={assetsTypesdata}
                />
              )}
              {!fromInspection && (
                <div className="fixedFields_fullInput__3zuDC" style={{ marginTop: '15px' }}>
                  <span className={styles.notamsText}>
                    <FormattedMessage
                      id="workorders.start.associate notams"
                      defaultMessage="Associate Notams with this work order ?"
                    />
                    <input
                      className={styles.notamsCheckbox}
                      type="checkbox"
                      onChange={this.openNotams}
                      checked={notamStatus}
                      value={notamStatus}
                    />
                  </span>
                    { notamStatus
                      ? (
                        <div className="fixedFields_fullInput__3zuDC">
                          { schemas.notamsEnabled
                            ? (
                              <div>
                                { action.message === 'success'
                                  ?(
                                    <div>
                                      <NotamsTable
                                        selectedNotam={[]}
                                        notamData={notamData}
                                        notamsMap={notamsMap}
                                        tableHeader={header}
                                        notamSelect={(notam, isSelect, selectType) => this.notamSelect(notam, isSelect, selectType, notamsMap)}
                                      />
                                    </div>
                                  )
                                  :(
                                    <div>
                                      <p className={styles.notamsLoading}>
                                        ... Loading... Please Hold ....
                                      </p>
                                    </div>
                                  )
                                }
                              </div>
                            ) : (
                              <div>
                                <p className={styles.notamsDenied}>
                                  *** Access Denied!!!! please contact admin for Access ***
                                </p>
                              </div>
                            )}
                        </div>
                      ): ''
                  }

                </div>
              )}
              {fromInspection && info.category && info.subcategory && (
                <FixedFieldsForInspections
                  assetsTypesdata={assetsTypesdata}
                  info={info}
                  assets={assets}
                  assetTypes={assetTypes}
                  assetsTypesname={assetsTypesname}
                  assetsTypedataloading={assetsTypedataloading}
                  handleAnswerChanged={this.handleAnswerChanged}
                  handleFieldErrorChanged={this.handleFieldErrorChanged}
                  showFormErrors={showFormErrors}
                  onCancel={onCancel}
                  spaceError={spaceError}
                  handleWorkOrderZoomLevel={value => this.handleZoomLevel(value)}
                  handleSelectedAsset={this.handleSelectedAsset}
                />
              )}

              <div className={styles.separator} />
              {Object.keys(requiredMap).length > 0 && allDataSources
                && schemas.workorder.schema.fields.map(field => (
                  <PulpoField key={field.id} {...field}
                    translation={translationMap && translationMap[field.title]}
                    handleValueChange={(a, b) => this.handleAnswerChanged('answers', a, b)}
                    isRequired={requiredMap[field.id]} answer={answers[field.id]}
                    showFieldErrors={showFormErrors}
                    handleFieldErrorChanged={this.handleFieldErrorChanged}
                    allDataSources={allDataSources}
                  />
                ))}
            </div>
            {/* <PulpoField key="desc" id="work_description" type="string"
              className={styles.fullInput} widget={{ type: 'textarea' }}
              translationID={`workorder.detail.${step}.description`} title="Description of Work done"
              isRequired handleValueChange={(a, b) => handleAnswerChanged(step, a, b)}
              answer={info.work_description}
              showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
            /> */}
            <div className={`${styles.footer} ${styles.embedded}`}>
              <Loading loadingStatus={loadingStatus} />
              <div className={styles.errors}>
                { errors && errors.length > 0 && (
                  errors.map(e => (
                    <>
                      <span>{`${e.id}: `}</span>
                      <FormattedMessage key={e.id} id={e.message}
                        defaultMessage="this field is required"
                      />
                    </>
                  ))
                )}
              </div>
              { fromInspection && info.category && info.subcategory
                && (
                  <div className={styles.cancelButton}>
                    <Button onClick={onCancel} translationID="inspections.new.cancel"
                      defaultText="Cancel" action="secondary"
                    />
                  </div>
                )
              }
              <Button onClick={this.create} translationID="inspections.new.create"
                defaultText="Create" action="secondary"
              />
            </div>
          </Tag.name>
        </div>
      </>
    );
  }
}

WorkOrderCreate.propTypes = {
  selfInspection: PropTypes.shape({}),
  actionCreate: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  notams: PropTypes.arrayOf(PropTypes.shape({})),
  actionGetSelfInpection: PropTypes.func.isRequired,
  actionGetWorkOrder: PropTypes.func.isRequired,
  schemas: PropTypes.shape({}).isRequired,
  createAction: PropTypes.shape({}),
  clear: PropTypes.func.isRequired,
  actionFetchNotams: PropTypes.func.isRequired
};

WorkOrderCreate.defaultProps = {
  userlist: [],
  selfInspection: {},
  createAction: {},
  notams: []
};

const mapStateToProps = state => ({
  workorders: state.workorders.workorders,
  selfInspection: state.inspection.selfInspection,
  schemas: state.workorders.schemas,
  action: state.workorders.action,
  createAction: state.workorders.createAction,
  createdWorkorder: state.workorders.createdWorkorder,
  errors: state.workorders.errors,
  user: state.auth.profile,
  userlist: state.inspection.userlist,
  assets: state.assets.assets,
  translations: state.auth.translations,
  notams: state.workorders.notams,
  assetsTypesname: state.assets.types,
  assetsTypesdata: state.assets.typesNames,
  assetsTypedataloading: state.assets.actionLoadTypeNames,
  shiftTypes: state.settings.shiftTypes,
  allDataSources: state.inspection.allDataSources
});
const mapDispatchToProps = dispatch => ({
  // Create work order.
  actionCreate: (data) => {
    dispatch(createWorkOrder(data));
    return Promise.resolve();
  },
  actionGetWorkOrder: () => {
    dispatch(fetchWorkOrderSchema());
  },
  actionGetSelfInpection: (id) => {
    dispatch(fetchSafetySelfInspection(id));
  },
  actionFetchAssets: () => {
    dispatch(fetchAssets());
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionFetchNotams: (query) => {
    dispatch(fetchNotams(query));
  },
  actionfetchAssetTypes: () => {
    dispatch(fetchAssetTypes());
  },
  actionfetchAssetsNames: (Names, categories) => {
    dispatch(fetchAssetsNames(Names, categories));
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  clear: () => {
    dispatch(clearActionResult());
  },
  clearAssetData: () => {
    dispatch(clearAssetTypes());
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});


export default Permissions(['add_workorder'])(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrderCreate),
  <Forbidden />
);
