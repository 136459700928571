import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import Autocomplete from 'react-autocomplete';
import PulpoField from '../../../../pulpo_visualizer/fields';
import fieldstyles from '../../../../pulpo_visualizer/fields/fields.module.scss';
import Clickable from '../../../../components/clickable/Clickable';
import FormattedMessageWithClass from '../../../../components/formattedMessageWithClass';
import Multilocation from './Multilocation';
import styles from './fixedFields.module.scss';
import Loading from '../../../../components/loading';

class EditFixedFields extends Component {
  state = { currentValue: undefined }

  static getDerivedStateFromProps(props, state) {
    if (props.editWorkOrder.logged_by.id && state.currentValue === undefined) {
      return { ...state, currentValue: props.editWorkOrder.logged_by.fullname };
    }
    return state;
  }

  handleAutocompleteChange = (e) => {
    const { searchForUser } = this.props;
    this.setState({ currentValue: e.target.value });
    searchForUser(e.target.value);
  }

  handleAssetData = (assetsTypesname, assetTypes) => {
    const assetData=[];
    assetsTypesname.filter(asset => asset.category.toLowerCase() === assetTypes).map(e => (
      assetData.push({ key: JSON.stringify(e.id), value: e.name })));
    if (assetData.length>1) {
      assetData.push({ key: assetTypes, value: 'Show All Assets' });
    }
    return assetData;
  }

  handleAutocompleteSelect = (val, item) => {
    const { handleAnswerChanged, handleFieldErrorChanged } = this.props;
    this.setState({ currentValue: val });
    handleAnswerChanged('editWorkOrderDetails', item, 'logged_by');
    handleFieldErrorChanged('logged_by', false);
  }

  handleDrop = (dropped) => {
    const { handleAnswerChanged, editWorkOrder } = this.props;
    handleAnswerChanged('editWorkOrderDetails', [...editWorkOrder.attachments, ...dropped], 'attachments');
  }

  removeImage = (r) => {
    const { handleRemoveImage, editWorkOrder, handleAnswerChanged } = this.props;
    if ('id' in r) {
      const del = editWorkOrder.attachments.filter(e => e === r);
      const res = editWorkOrder.attachments.filter(e => e !== r);
      const id = del[0].id;
      handleRemoveImage('editWorkOrderDetails', res, 'removedAttachments', id);
    } else {
      const res = editWorkOrder.attachments.filter(e => e !== r);
      handleAnswerChanged('editWorkOrderDetails', res, 'attachments');
    }
  }

  handleZoomLevel = (val) => {
    const { handleWorkOrderZoomLevel } = this.props;
    handleWorkOrderZoomLevel(val);
  }

  render() {
    const { currentValue } = this.state;
    const {
      handleAnswerChanged,
      showFormErrors,
      handleFieldErrorChanged,
      userlist,
      categories,
      assetTypes,
      shifts,
      handleSelectedAsset,
      editWorkOrder,
      assetsTypesname,
      defaultAsset,
      assetsTypesdata,
      assetsTypedataloading
    } = this.props;
    return (
      <>
        <div className={fieldstyles.field}>
          <Loading loadingStatus={assetsTypedataloading.loading} />
          <FormattedMessageWithClass
            className={fieldstyles.title} id="workorder.create.by"
            defaultMessage="Inspected by"
          />
          <Autocomplete getItemValue={item => item.fullname} items={userlist}
            wrapperStyle={{ position: 'relative' }}
            value={currentValue}
            onChange={this.handleAutocompleteChange}
            onSelect={this.handleAutocompleteSelect}
            renderMenu={children => (
              <div className={styles.autocompleteMenu}>
                {children}
              </div>
            )}
            renderItem={(item, isHighlighted) => (
              <div key={item.id} className={`${styles.menuItem} ${isHighlighted && styles.highlighted}`}>
                {item.fullname}
              </div>
            )}
          />
          {!currentValue && showFormErrors && (
          <small>
            <FormattedMessage id="pulpoforms.errors.not_blank" defaultMessage="There is an error in this field" />
          </small>
          )}
        </div>
        <div style={{ padding: '30px,0px,100px' }}>
          <PulpoField key="date" id="report_date" type="datetime"
            translationID="workorder.create.date" title="Report date"
            isRequired handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
            answer={editWorkOrder.report_date}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={handleFieldErrorChanged}
          />
        </div>
        <div style={{ width: 'auto', paddingLeft: '30px' }}>
          <PulpoField key="shift" id="shift" type="string"
            translationID="workorder.detail.request.shift" title="Shift"
            disabled isRequired
            answer={shifts}
            showFieldErrors={showFormErrors}
            handleFieldErrorChanged={handleFieldErrorChanged}
          />
        </div>
        <br />

        <PulpoField key="category" id="category" type="select" title="Category" isRequired
          translationID="workorder.create.category" className={styles.category}
          handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
          answer={editWorkOrder.category}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={handleFieldErrorChanged}
          values={Object.keys(categories).map(e => (
            { key: e, value: categories[e].title }
          ))}
        />


        <PulpoField key="priority" id="priority" type="select" title="Priority" isRequired
          translationID="workorder.create.priority" className={styles.priority}
          handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
          answer={editWorkOrder.priority}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={handleFieldErrorChanged}
          values={[
            { key: '0', value: 'Low' },
            { key: '1', value: 'Medium' },
            { key: '2', value: 'High' }
          ]}
        />
        {editWorkOrder.category && (
        <PulpoField key="subcategory" id="subcategory" type="select" title="Sub Category" isRequired
          translationID="workorder.create.subcategory" className={styles.fullInput}
          handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
          answer={editWorkOrder.subcategory}
          showFieldErrors={showFormErrors}
          handleFieldErrorChanged={handleFieldErrorChanged}
          values={categories[editWorkOrder.category].checklist.map(e => (
            { key: e.key, value: e.value }
          ))}
        />
        )}

        {assetTypes && assetTypes[editWorkOrder.category]
         && assetTypes[editWorkOrder.category][editWorkOrder.subcategory]
          ? (
            <PulpoField key="assetName" id="assetName" type="select" title="Asset Type Name"
              translationID="workorder.create.Assets" className={styles.fullInput}
              handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
              answer={editWorkOrder.assetName}
              showFieldErrors={showFormErrors}
              handleFieldErrorChanged={handleFieldErrorChanged}
              values={this.handleAssetData(assetsTypesname, assetTypes[editWorkOrder.category][editWorkOrder.subcategory])}
            />
          ):''
        }
        {
       assetTypes
       && assetTypes[editWorkOrder.category]
       && assetTypes[editWorkOrder.category][editWorkOrder.subcategory]
       && (assetsTypesdata.length > 0 || defaultAsset.length > 0)
         ? (
           <Multilocation
             defaultAsset={editWorkOrder.assets}
             editWorkOrderMap
             className={styles.fullInput}
             assetTypeID={editWorkOrder.assetName}
             handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
             handleZoomLevel={val => this.handleZoomLevel(val)}
             answer={editWorkOrder.location}
             loading={assetsTypedataloading.loading}
             shape={editWorkOrder.shapes}
             handleFieldErrorChanged={handleFieldErrorChanged}
             handleSelectedAsset={handleSelectedAsset}
             type={assetTypes[editWorkOrder.category][editWorkOrder.subcategory]}
             assets={defaultAsset.length > 0 ? defaultAsset : assetsTypesdata.filter(
               e => e.asset_type.category.toUpperCase()
              === assetTypes[editWorkOrder.category][editWorkOrder.subcategory].toUpperCase()
             )}
           />
         ) : (

           <PulpoField key="location" id="location" type="location"
             translationID="workorder.create.location"
             className={styles.fullInput} title="Location" isRequired
             handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
             answer={editWorkOrder.location}
             shape={editWorkOrder.shapes}
             showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
             handleZoomLevel={val => this.handleZoomLevel(val)}
           />
         )
        }

        <PulpoField key="desc" id="problem_description" type="string"
          translationID="workorder.create.description" className={styles.fullInput}
          widget={{ type: 'textarea' }} title="Problem description" isRequired
          handleValueChange={(a, b) => handleAnswerChanged('editWorkOrderDetails', a, b)}
          answer={editWorkOrder.problem_description}
          showFieldErrors={showFormErrors} handleFieldErrorChanged={handleFieldErrorChanged}
        />
        <div className={styles.fullInput}>
          <FormattedMessageWithClass
            className={styles.label} id="workorder.create.photos"
            defaultMessage="Photos"
          />
          <Dropzone onDrop={this.handleDrop} disableClick
            className={styles.dropzone} accept="image/*"
          >
            {({ open }) => (
              <>
                <p className={styles.browsetext}>
                  <FormattedMessage id="airport.logo.text1" defaultMessage="Drag an image here or" />
                  <button type="button" onClick={() => open()}>
                    <FormattedMessage id="airport.logo.button" defaultMessage="browse" />
                  </button>
                  <FormattedMessage id="airport.logo.text2" defaultMessage="for an image to upload." />
                </p>
              </>
            )}
          </Dropzone>
          {editWorkOrder.attachments.length > 0 && (
            <div className={styles.photos}>
              {editWorkOrder.attachments.map(e => (
                <div key={e.id} className={styles.wrapper}>
                  <Clickable onClick={() => this.removeImage(e)}>&times;</Clickable>
                  <img src={e.preview} alt={e.name} />
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

EditFixedFields.propTypes = {
  editWorkOrder: PropTypes.shape({}).isRequired,
  handleAnswerChanged: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  handleWorkOrderZoomLevel: PropTypes.func.isRequired,
  handleSelectedAsset: PropTypes.func.isRequired,
  handleFieldErrorChanged: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool,
  userlist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchForUser: PropTypes.func.isRequired,
  categories: PropTypes.shape({}).isRequired
};

EditFixedFields.defaultProps = {
  showFormErrors: false
};

export default EditFixedFields;
