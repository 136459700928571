import styled from "styled-components";

export const Wrapper = styled.span`
  display: flex;

  .people_count {
    font-size: 13px;
    line-height: 2;
    color: #8c9ea7;
    text-decoration: underline;
    padding: 0 15px;
  }

  h3 {
    margin: unset;
  }
`;
