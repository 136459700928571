import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 20px 5px 60px;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  border-left: 3px solid ${({ theme }) => theme.colors.border.blue};
  .edit-title {
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: bold;
    margin-bottom: 5px;
  }
  img {
   cursor: pointer;
  }
`;