import {
  initialState as directMessagesState,
  types as directMessagesTypes
} from './directMessages';
import { CurrentUser, FetchMoreFunc, DeleteDMFunc } from '../../types';
import { CreateMessagesArgs, UpdateDMVars } from '../../graphql/hooks/hooks.types';

export type GlobalState = {
  directMessages: directMessagesTypes.DirectMessagesState;
  currentUser?: CurrentUser;
  createMessages?: (args: CreateMessagesArgs) => void;
  fetchMore?: FetchMoreFunc;
  updateMessage?: (args: UpdateDMVars) => void;
  listChatsLoading?: boolean;
  loadingDM?: boolean;
  deleteMessage?: DeleteDMFunc;
};

export const globalState: GlobalState = {
  directMessages: directMessagesState(),
  currentUser: null
};
