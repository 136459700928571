import React from 'react';
import { Avatar } from '../../../../components/avatar';
import { ReactComponent as MoreHoriz } from '../../../../icons/more-horiz.svg';
import { ChatTypes } from '../../enums';
import { ChatListUser } from '../../types';
import { ChatName } from '../ChatName';
import { Wrapper } from './ActiveChatHeader.styles';
import { EditOption } from './EditOption';
import { useProfileState } from './useProfileState';

type ActiveDirectMessagesHeaderProps = {
  type: ChatTypes.directMessages;
  roomID: string;
  userDetails: ChatListUser;
};

const ActiveDirectMessagesHeader: React.FC<ActiveDirectMessagesHeaderProps> = ({
  type,
  roomID,
  userDetails: { userId, profilePicture, isActive, firstName, lastName }
}) => {
  const { showMenu, handleClick } = useProfileState();
  const userFullName = `${firstName} ${lastName}`;

  return (
    <Wrapper isGrey={false} viewType={type}>
      <div className="chat-name">
        <Avatar
          key={userId}
          src={profilePicture}
          isActive={isActive}
          name={userFullName}
        />
        <ChatName name={userFullName} />
      </div>
      <div>
        <MoreHoriz onClick={handleClick} />
        {showMenu && <EditOption editId={roomID} type={type} />}
      </div>
    </Wrapper>
  );
};

export default ActiveDirectMessagesHeader;
export { ActiveDirectMessagesHeader };
