import React from 'react';

import { Location } from 'history';
import styles from '../../chat.module.scss';

import settings from '../../../../icons/settings.svg';
import search from '../../../../icons/search.svg';
import preset from '../../../../icons/Preset.svg';
import Channels from '../../../../icons/Channels.png';
import Direct_Messaging from '../../../../icons/Direct_Messaging.png';
import Shortcuts from '../../../../components/topbar/shortcuts/shortcuts';
import IconButton from '../../../../components/iconButton';
import Separator from '../../../../components/separator';
import Button from '../../../../components/button';
import SectionHeader from '../../../../components/sectionHeader';
import { headerConfig, links } from './assets';

type ChatHeaderProps = {
  push: (location: string) => void;
  location: Location<any>;
};

const defaultPath = { createRoute: 'createRoute', defaultText: 'defaultText', defaultTitle: 'defaultTitle' };

const ChatHeader: React.FC<ChatHeaderProps> = ({ push, location: { pathname } }) => {
  const { createRoute, defaultText, defaultTitle } = headerConfig[pathname] || defaultPath;

  return (
    <>
      <Shortcuts links={links} />
      <SectionHeader
        icon={defaultTitle==='Channels'? Channels:defaultTitle==='Direct Messages'? Direct_Messaging:preset}
        translationID="todo_add_translationID"
        defaultTitle={defaultTitle}
      >
      </SectionHeader>
    </>
  );
};

export default ChatHeader;
export { ChatHeader };
