/* global FormData File */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
/** ******************************************************************
 *  Redux import
 * ************** */
import { searchUser } from '../../../inspections/redux/actions';
import { fetchRoles, fetchShiftTypes } from '../../../settings/redux/actions';
import {
  fetchRules, addTask, updateTask, updateTaskOccurrence
} from '../../redux/actions';

/** ******************************************************************
 *  Components import
 * ***************** */
import Modal from '../../../../components/modal';
import PulpoField from '../../../../pulpo_visualizer/fields/PulpoField';
import Button from '../../../../components/button';
import Scheduler from '../../../../components/scheduler/Scheduler';
import Assignee from '../../../../components/assignee/Assignee';
import attachmentIcon from '../../../../icons/attachment_icon.png';
import Loading from '../../../../components/loading/loading';
/** ******************************************************************
 *  Assets import
 * ***************** */
import styles from '../toDoList.module.scss';
import { GetFileName, S3Uploads } from '../../../services';

class NewTask extends Component {
  state = {
    info: {
      name: '',
      additional_info: ''
    },
    currentUser: '',
    typeSelected: 'user',
    showFieldErrors: false,
    isEditing: false,
    showEditConfirmationModal: false,
    shouldChangeFuture: false,
    loading: false
  };

  componentDidMount = () => {
    const { actionGetRoles, actionFetchRules, actionFetchShiftTypes } = this.props;
    actionGetRoles();
    actionFetchRules();
    actionFetchShiftTypes();
  }

  componentDidUpdate(prevProps) {
    const { taskEdit } = this.props;
    if (taskEdit !== undefined && prevProps.taskEdit === undefined) {
      // If the Detail modal was opened, this modal will receive the task's detail
      // as a prop, which means that if this modal is opened, it will do it in
      // Edit Mode.
      const isUserAssigned = taskEdit.event.assigned_user;
      this.setState({
        info: {
          name: taskEdit.title,
          assigned_user: isUserAssigned && taskEdit.event.assigned_user ? taskEdit.event.assigned_user.id : undefined,
          assigned_role: !isUserAssigned && taskEdit.event.assigned_role ? taskEdit.event.assigned_role.id : undefined,
          additional_info: taskEdit.description,
          end_recurring_period: taskEdit.event.end_recurring_period,
          due_time: taskEdit.event.due_time !== null && moment(
            taskEdit.event.due_time, 'HH:mm'
          ).format(),
          due_date: taskEdit.event.due_date !== null && moment(
            taskEdit.end
          ).format('YYYY-MM-DD'),
          rule: { ...taskEdit.event.rule },
          attachments: taskEdit.event.attachments[0],
          shift_name: taskEdit.event.shift_name ? taskEdit.event.shift_name : 'None'
        },
        currentUser: isUserAssigned && taskEdit.event.assigned_user.fullname,
        typeSelected: isUserAssigned ? 'user' : 'role',
        isEditing: true,
        loading: false
      });
    } else if (taskEdit === undefined && prevProps.taskEdit !== undefined) {
      // If there was a taskEdit, but it was removed, then this modal will
      // open in Create Mode.
      this.setState({
        info: {
          name: '',
          additional_info: '',
          due_date: '',
          due_time: ''
        },
        currentUser: undefined,
        typeSelected: 'user',
        isEditing: false,
        loading: false
      });
    }
  }


  handleFieldChange = (value, fieldId) => {
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        [fieldId]: value
      }
    }));
    if (fieldId === 'assigned_role') {
      this.setState(prevState => ({
        info: {
          ...prevState.info,
          assigned_user: undefined
        },
        currentUser: undefined,
        shouldChangeFuture: true
      }));
    }
  }

  getFormData = () => {
    const { info } = this.state;
    info.label = '';
    if (info.due_date) {
      info.due_date = moment(info.due_date).format('YYYY-MM-DDZ');
    } else {
      info.due_date = moment().add(1, 'days').startOf('day').format('YYYY-MM-DDZ');
    }
    if (info.due_time) {
      info.due_time = moment(info.due_time).format('HH:mmZ');
    }
    if (info.end_recurring_period) {
      info.end_recurring_period = moment(info.end_recurring_period).format('YYYY-MM-DD');
    } else {
      delete info.end_recurring_period;
    }
    const formData = new FormData();
    Object.keys(info).forEach((k) => {
      if (info[k] instanceof File) {
        formData.append(k, info[k]);
      } else if (typeof (info[k]) === 'object') {
        formData.append(k, JSON.stringify(info[k]));
      } else if (info[k]) {
        formData.append(k, info[k]);
      }
    });
    formData.append('email_date', moment().format());
    return formData;
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  handleEditConfirmation = async (updateOption) => {
    const { shouldChangeFuture, info } = this.state;
    const formData = this.getFormData();
    const { actionUpdate, actionUpdateOccurrence, taskEdit, changePage } = this.props;

    if (!shouldChangeFuture) {
      formData.delete('rule');
      if ('assigned_user' in info) formData.delete('assigned_user');
      if ('assigned_role' in info) formData.delete('assigned_role');
    }
    formData.append('date', taskEdit.original_end);
    // formData.delete('attached');
    formData.delete('attachments');
    if (info.attachments && !(info.attachments.id)) {
      this.setState({ loading: true });
      let fileIds = '';
      fileIds = await this.getFileNames(1);
      if (fileIds) {
        await this.doS3Upload([info.attachments], fileIds, 'tasks')
          .then(() => {
            fileIds.filename.forEach((id) => {
              formData.append('attached', id.toString());
            });
          });
      }
    }
    if (updateOption === 'all') {
      actionUpdate(taskEdit.event.id, formData);
      changePage(1);
    } else {
      actionUpdateOccurrence(taskEdit.event.id, formData);
    }
    this.closeModal();
  }

  handleAddAction = async () => {
    const { info, isEditing } = this.state;
    const { actionCreate } = this.props;

    if (!info.name || (!info.assigned_role && !info.assigned_user)) {
      this.setState({ showFieldErrors: true });
    } else {
      if (isEditing) {
        this.setState({ showEditConfirmationModal: true });
        return;
      }
      const formData = this.getFormData();
      if (info.attachments) {
        this.setState({ loading: true });
        formData.delete('attachments');
        let fileIds = '';
        fileIds = await this.getFileNames(1);
        if (fileIds) {
          await this.doS3Upload([info.attachments], fileIds, 'tasks')
            .then(() => {
              fileIds.filename.forEach((id) => {
                formData.append('attached', id.toString());
              });
            });
        }
      }
      actionCreate(formData);
      this.closeModal();
    }
  }

  /**
   * Autocomplete Functions
   */
  handleAutocompleteChange = (value) => {
    this.setState({ currentUser: value });
  }

  handleAutocompleteSelect = (value, userId) => {
    this.setState(prevState => ({
      currentUser: value,
      info: {
        ...prevState.info,
        assigned_user: userId,
        assigned_role: undefined
      },
      shouldChangeFuture: true
    }));
  }

  closeModal = () => {
    const { onClose } = this.props;
    this.setState({
      showFieldErrors: false,
      info: {
        name: '',
        additional_info: ''
      },
      currentUser: '',
      typeSelected: 'user',
      isEditing: false,
      showEditConfirmationModal: false,
      loading: false
    });
    onClose();
  }

  onAssignTypeChanged = (value) => {
    this.setState({ typeSelected: value });
  }

  handleScheduleChange = ({ id, endPeriod, frequency, params }) => {
    if (id) {
      this.handleFieldChange({ id }, 'rule');
    } else if (frequency && params) {
      this.handleFieldChange({ frequency, params }, 'rule');
    } else {
      this.handleFieldChange({ }, 'rule');
    }
    this.handleFieldChange(endPeriod, 'end_recurring_period');

    const { isEditing } = this.state;
    if (isEditing) {
      const { taskEdit } = this.props;
      let change = false;
      if (taskEdit.event.rule) {
        if (id && taskEdit.event.rule.id) {
          change = taskEdit.event.rule.id !== parseInt(id, 10);
          if (!change) {
            if (taskEdit.event.end_recurring_period) {
              change = JSON.stringify(taskEdit.event.end_recurring_period) !== JSON.stringify(moment(endPeriod).format());
            } else if (endPeriod) change = true;
          }
        } else if (frequency && params) {
          change = taskEdit.event.rule.frequency !== frequency;
          if (!change) {
            change = taskEdit.event.rule.params !== params;
          }
        } else {
          change = true;
        }
      } else if (!taskEdit.event.rule) {
        if (id || frequency || params || endPeriod) {
          change = true;
        }
      }
      this.setState({
        shouldChangeFuture: change
      });
    }
  }

  render() {
    const { showIn, rules, intl, shiftTypes } = this.props;
    const {
      showFieldErrors, info, currentUser, typeSelected, isEditing,
      showEditConfirmationModal, shouldChangeFuture, loading
    } = this.state;
    const { formatMessage } = intl;
    let theshifts = [];
    if (shiftTypes && shiftTypes.length > 0) {
      shiftTypes.forEach((e) => {
        theshifts = [...theshifts, { key: e.name, value: e.name }];
      });
    }
    return (
      <Modal showIn={showIn} onClose={this.closeModal} width="auto">
        <div className={styles.newTask}>
          <div className={styles.title}>
            {isEditing ? (
              <FormattedMessage tagName="h5" id="todo.editTask.title"
                defaultMessage="Edit Task"
              />
            ) : (
              <FormattedMessage tagName="h5" id="todo.newTask.title"
                defaultMessage="New Task"
              />
            )}
          </div>
          <div className={styles.body}>
            <div>
              <PulpoField key="name" id="name" type="string" title="Write a task name" isRequired
                translationID="todo.newTask.task_name"
                handleValueChange={this.handleFieldChange}
                answer={info.name}
                showFieldErrors={showFieldErrors}
                handleFieldErrorChanged={() => ({})}
                className={styles.fullWidth}
              />
              {/* {showFieldErrors && !info.name && (
                <label>
                  <small>
                    <FormattedMessage id="pulpoforms.errors.not_blank"
                      defaultMessage="There is an error in this field"
                    />
                  </small>
                </label>
              )} */}
            </div>
            <div style={{ display: 'flex' }}>

              <PulpoField key="role" id="" type="select"
                title="Assigned to"
                translationID="todo.newTask.user"
                handleValueChange={this.onAssignTypeChanged}
                answer={typeSelected}
                values={[
                  { key: 'user', value: 'User' },
                  { key: 'role', value: 'Role' }
                ]}
                showFieldErrors={showFieldErrors}
                handleFieldErrorChanged={() => ({})}
                className={styles.assignee}
              />
              <Assignee
                typeSelected={typeSelected}
                updateUserValue={this.handleAutocompleteChange}
                onAssigneeSelected={this.handleAutocompleteSelect}
                onRoleChange={this.handleFieldChange}
                userValue={currentUser}
                showFieldErrors={showFieldErrors}
                assignedRole={info.assigned_role}
                assignedUser={info.assigned_user}
              />
            </div>
            <div>
              <div className={styles.float}>
                <div className={styles.fullWidthleft}>
                  <PulpoField key="date" id="due_date" type="date" title="Du Date"
                    translationID="todo.newTask.date"
                    answer={info.due_date ? info.due_date : (moment().add(1, 'day').toDate())}
                    handleValueChange={this.handleFieldChange}
                    showFieldErrors={showFieldErrors}
                    handleFieldErrorChanged={() => ({})}
                  />
                </div>
                <div className={styles.fullWidthright}>
                  <PulpoField key="time" id="due_time" type="datetime" title="Due Time"
                    translationID="todo.newTask.time"
                    onlyTime
                    answer={info.due_time}
                    handleValueChange={this.handleFieldChange}
                    showFieldErrors={showFieldErrors}
                    handleFieldErrorChanged={() => ({})}
                  />
                </div>
              </div>
            </div>
            <div>
              <Scheduler tasks title="schedule.repeat.maintitle" onScheduleChange={this.handleScheduleChange}
                rules={rules.map(r => ({ key: `${r.id}`, name: r.name, frequency: r.frequency, params: r.params }))}
                selected={info}
              />
            </div>
            <div className={styles.float}>
              <div className={styles.fullWidthleft}>
                {/* <label className={styles.fullWidth}> */}
                <FormattedMessage id="todo.newTask.attach"
                  defaultMessage="Attach a file"
                />
                <div className={styles.col}>
                  <div className={styles.imageupload}>
                    <label htmlFor="file-input">
                      <img src={attachmentIcon} alt="" />
                      {!info.attachments && formatMessage({ id: 'todo.newTask.browse' })}
                    </label>
                    <input id="file-input" type="file" onChange={e => this.handleFieldChange(e.target.files[0], 'attachments')} />
                    {info.attachments && info.attachments.name}
                  </div>
                </div>
                {/* </label> */}
              </div>
              <div className={styles.fullWidthright}>
                <PulpoField key="shift" id="shift_name" type="select"
                  title="Shift Type"
                  translationID="inspection.inspection_shift"
                  handleValueChange={this.handleFieldChange}
                  answer={info.shift_name}
                  values={theshifts}
                  showFieldErrors={showFieldErrors}
                  handleFieldErrorChanged={() => ({})}
                />
              </div>
            </div>
            <div>
              <PulpoField key="addInfo" id="additional_info" type="string" title="Additional Information"
                translationID="todo.newTask.info"
                widget={{ name: 'textfield' }}
                handleValueChange={this.handleFieldChange}
                answer={info.additional_info}
                showFieldErrors={showFieldErrors}
                handleFieldErrorChanged={() => ({})}
                rows="4"
                className={styles.fullWidth}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              onClick={this.closeModal}
              translationID="todo.newTask.cancel"
              defaultText="Cancel" action="secondary"
            />
            <Button
              onClick={this.handleAddAction}
              translationID="todo.newTask.save"
              defaultText="Save" action="secondary"
            />
          </div>
        </div>
        { loading && (<Loading loadingStatus={loading} zindex="1000" />)}
        <Modal showIn={showEditConfirmationModal} onClose={this.closeModal} width="90%" minHeight="20%">
          <div className={styles.newTask}>
            <div className={styles.title}>
              <FormattedMessage tagName="h5" id="todo.newTask.updateModal.title"
                defaultMessage="Select update type"
              />
            </div>
            <div className={styles.confirmationbody}>
              {!shouldChangeFuture && (
              <FormattedMessage tagName="span" id="todo.newTask.updateModal.description"
                defaultMessage="Do you want to update all occurrences of this task, or only the selected one?"
              />
              )}
              {shouldChangeFuture && (
              <FormattedMessage tagName="span" id="todo.newTask.futureEvents"
                defaultMessage="Your change will apply to all future occurrences of the event. Do you want to continue?"
              />
              )}
            </div>
            <div className={styles.footer}>
              <Button
                onClick={this.closeModal}
                translationID="todo.newTask.cancel"
                defaultText="Cancel" action="tertiary"
              />
              {!shouldChangeFuture && (
              <Button
                onClick={() => this.handleEditConfirmation('selected')}
                translationID="todo.newTask.update.selected_only"
                defaultText="Only selected occurrence" action="secondary"
              />
              )}
              <Button
                onClick={() => this.handleEditConfirmation('all')}
                translationID={shouldChangeFuture ? 'todo.newTask.update.future' : 'todo.newTask.update.update_all'}
                defaultText="Update all" action="secondary"
              />
            </div>
          </div>
        </Modal>
      </Modal>
    );
  }
}

NewTask.propTypes = {
  showIn: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  info: PropTypes.shape({}),
  taskEdit: PropTypes.shape({}),
  rules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actionGetRoles: PropTypes.func.isRequired,
  actionFetchRules: PropTypes.func.isRequired,
  actionCreate: PropTypes.func.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  actionUpdateOccurrence: PropTypes.func.isRequired,
  shiftTypes: PropTypes.arrayOf(PropTypes.shape({}))
};

NewTask.defaultProps = {
  showIn: false,
  info: undefined,
  taskEdit: undefined,
  shiftTypes: {}
};


const mapStateToProps = state => ({
  users: state.inspection.userlist,
  roles: state.settings.roles,
  assignmentAction: state.workorders.assignmentAction,
  rules: state.tasks.rules,
  shiftTypes: state.settings.shiftTypes
});

const mapDispatchToProps = dispatch => ({
  actionSearchUser: (query, type) => {
    dispatch(searchUser(query, type));
  },
  actionGetRoles: () => {
    dispatch(fetchRoles());
  },
  actionFetchRules: () => {
    dispatch(fetchRules());
  },
  actionCreate: (data) => {
    dispatch(addTask(data));
  },
  actionUpdate: (id, data) => {
    dispatch(updateTask(id, data));
  },
  actionUpdateOccurrence: (id, data) => {
    dispatch(updateTaskOccurrence(id, data));
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NewTask));
