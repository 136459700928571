/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import L from 'leaflet';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Map, ZoomControl, LayersControl, FeatureGroup } from 'react-leaflet';
import Control from 'react-leaflet-control';
import Transition from 'react-transition-group/Transition';
import Button from '../../../components/button';

import { fetchAssetTypes, fetchAssetsSchema, updateAssetsSchema, uploadAssets } from '../redux/actions';

import ShapeFile from '../ShapeFile';

import 'leaflet/dist/leaflet.css';
import styles from './AssetDataImporter.module.scss';
import ColorSelect from '../../../components/colorSelect';
import Loading from '../../../components/loading';

// constants
const ZOOM = 13;
const COLORS = ['#3288BD', '#f46d43', '#fdae61', '#5e4fa2', '#ffffbf', '#d53e4f', '#66c2a5'];

const fieldsAssetOptions = [
  { key: 'field_new', name: '---' },
  { key: 'field_label', name: 'Name' }
];

/** Multi-data Import of assets from shape file */
class AssetDataImporter extends React.Component {
  // Transition of mapping panels
  slide = {
    entering: {
      transform: 'translate(-100%)'
    },
    entered: {
      transform: 'translate(0%)',
      transition: 'transform 300ms ease-in-out'
    },
    exiting: {
      transform: 'translate(0%)'
    },
    exited: {
      transform: 'translate(-100%)',
      transition: 'transform 300ms ease-in-out'
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      geoData: null, // geo JSON data
      isAdded: false, // geo Data is loaded or not
      features: [], // geo points data include all info of fields and geometries
      fieldsFile: [], // fields data from file loaded
      fieldsAsset: [], // fields data from aerosimple (include custom fields and fields from asset table)
      currentCategory: 'signs', // current asset category selected (load different custom fields depending category)
      currentTypeIndex: 3, // index of currently picked field from file for asset type field
      mappingView: 'fields', // transition step panel's view name [e.g. "fields" or "state"]
      uniqueFeatures: [], // unique ones of fields value from file
      types: [], // asset types from aerosimple
      fields: [], // fields mapped to save into db at last
      schemaUpdated: false

    };
    this.features = [];
  }

  componentDidMount() {
    const { actionFetchTypes, actionGetSchemas } = this.props;
    actionFetchTypes();
    actionGetSchemas();
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.refs.map.leafletElement);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { features, currentTypeIndex, fieldsAsset, currentCategory, shapesaveSchema } = this.state;
    const { types, upAction, actionGetSchemas } =this.props;
    if (prevProps.upAction.loading===true && upAction.success===true && !shapesaveSchema) {
      await actionGetSchemas();
    }
    if (fieldsAsset !== prevState.fieldsAsset || currentTypeIndex !== prevState.currentTypeIndex) {
      // unique rows from shape file data
      const seen = Object.create(null);

      // eslint-disable-next-line array-callback-return
      const uniqueFeatures = features.filter((item) => {
        const key = Object.values(item.properties)[currentTypeIndex];
        if (!seen[key]) {
          seen[key] = true;
          return true;
        }
        return false;
      }, Object.create(null)).map((item, index) => {
        const specificTypes = types.filter(sitem => sitem.category.toLowerCase() === currentCategory);
        const assetIndex = index % specificTypes.length;
        const key = Object.values(item.properties)[currentTypeIndex].length ? Object.values(item.properties)[currentTypeIndex] : 'N/A';
        return { key, value: specificTypes[assetIndex].name };
      });

      this.setState({ uniqueFeatures });
    }
  }

  // mapping panel's select change event
  handleSelectChange = (e, index, key) => {
    let selectedState = this.state[key];
    Array.isArray(selectedState) ? selectedState[index].name = e.name : selectedState = e.key.split('-')[0];

    this.setState({ [`${key}`]: selectedState });
  }

  // mapping panel transition
  toggleMappingView = (key, value) => {
    this.setState({ [`${key}`]: value });
  }

  // fit map accoring to shp file's geometry
  updateFitBounds = () => {
    if (this.refs.group) {
      const bounds = this.refs.group.leafletElement.getBounds();
      if (Object.entries(bounds).length !== 0) {
        this.refs.map.leafletElement.fitBounds(bounds);

        const features = this.features;
        const fieldsFile = features.length ? Object.keys(features[0].properties).map((item, index) => ({ key: index, name: item })) : [];

        this.setState({ features, fieldsFile });

        this.handleCategoryChange(null);
      }
    }
  }

  // iterator for features of shp file
  onEachFeature = (feature, layer) => {
    if (feature.properties) {
      this.features.push(feature);

      layer.bindPopup(Object.keys(feature.properties).map(k => `${k}: ${feature.properties[k]}`).join('<br />'), {
        maxHeight: 200,
        closeButton: true,
        offset: L.point(0, -20)
      });
    }
  }

  // should load different custom fields depending on asset category
  handleCategoryChange = (e) => {
    const { schemas } = this.props;
    const { fieldsFile } = this.state;
    const currentCategory = e ? e.key : this.state.currentCategory;

    const customFields = schemas[currentCategory].schema.fields.map((item, index) => ({ key: `${item}-${index}`, name: item.title }));

    const fieldsAsset = [...fieldsAssetOptions, ...customFields];
    const fields = fieldsFile.map((item, index) => ({ key: item.name, name: fieldsAssetOptions[0].name }));

    this.setState({ currentCategory, fieldsAsset, fields });
  }

  handleAssetTypeMapping = (e, index, key) => {
    const selectedState = this.state[key];
    selectedState[index].value = e.name;

    this.setState({ [`${key}`]: selectedState });
  }

  readerLoad = (e) => {
    this.setState({
      geoData: e.target.result,
      isAdded: true,
      features: []
    });
  }

  // read shp file
  handleFile = (files) => {
    this.features = [];
    const reader = new FileReader();
    const file = files[0];
    const uploadFn = upload => this.readerLoad(upload);
    reader.onload = uploadFn.bind(this);
    reader.readAsArrayBuffer(file);
  }

  // display circles for each feature of shp file
  pointToLayer = (feature, latlng) => {
    const index = Math.floor(Math.random() * COLORS.length);
    const circlePointOption = {
      radius: 5,
      color: COLORS[index],
      fillColor: COLORS[index],
      fillOpacity: 0.7
    };
    return L.circleMarker(latlng, circlePointOption);
  }


  SaveSchema = async () => {
    const {
      fields,
      currentTypeIndex,
      currentCategory } = this.state;
    const {
      schemas,
      actionSaveSchema
    } = this.props;
    const maxId = schemas[currentCategory].schema.fields.length ? schemas[currentCategory].schema.fields.reduce(
      (max, i) => (Number(i.id.substr(3)) > max ? Number(i.id.substr(3)) : max), 1
    ) : 1;
    const mappedFields = fields.filter((item, index) => index !== currentTypeIndex).map((item, index) => ({ id: `${currentCategory.substring(0, 3)}${maxId + index + 1}`, type: 'string', title: item.key, widget: { type: 'charfield' }, required: true }));

    const newFields = [];
    [...schemas[currentCategory].schema.fields, ...mappedFields].filter((item) => {
      const i = newFields.findIndex(x => x.title === item.title);
      if (i <= -1) {
        newFields.push(item);
      }
      return null;
    });
    const newCurrentSchema = newFields.length ? ({
      id: '',
      version: 1,
      fields: [...newFields],
      sections: [{
        id: 'SEC1',
        fields: newFields.map(field => field.id),
        title: currentCategory
      }],
      pages: [{
        id: 'PAGE1',
        sections: ['SEC1'],
        title: currentCategory
      }]
    }) : { id: '', version: 1, fields: [], sections: [], pages: [] };

    const sideCategory = Object.keys(schemas).filter(item => item !== currentCategory)[0];
    const sideSchema = schemas[sideCategory].schema;

    await actionSaveSchema({
      [`${currentCategory}`]: newCurrentSchema,
      [`${sideCategory}`]: sideSchema
    });
    this.setState({ schemaUpdated: true });
  }

  // once done mapping
  finishMapping = async () => {
    const {
      uniqueFeatures,
      fields,
      currentTypeIndex,
      currentCategory,
      schemaUpdated } = this.state;
    const {
      profile,
      types,
      schemas,
      actionSaveSchema,
      actionUploadAssets } = this.props;
    const mappedAssetTypes = uniqueFeatures.map(item => ({ id: types.filter(type => type.name === item.value)[0].id, value: item.key === 'N/A' ? '' : item.key }));
    const maxId = schemas[currentCategory].schema.fields.length ? schemas[currentCategory].schema.fields.reduce(
      (max, i) => (Number(i.id.substr(3)) > max ? Number(i.id.substr(3)) : max), 1
    ) : 1;
    const mappedFields = fields.filter((item, index) => index !== currentTypeIndex).map((item, index) => ({ id: `${currentCategory.substring(0, 3)}${maxId + index + 1}`, type: 'string', title: item.key, widget: { type: 'charfield' }, required: true }));

    const newFields = [];
    [...schemas[currentCategory].schema.fields, ...mappedFields].filter((item) => {
      const i = newFields.findIndex(x => x.title === item.title);
      if (i <= -1) {
        newFields.push(item);
      }
      return null;
    });
    const newCurrentSchema = newFields.length ? ({
      id: '',
      version: 1,
      fields: [...newFields],
      sections: [{
        id: 'SEC1',
        fields: newFields.map(field => field.id),
        title: currentCategory
      }],
      pages: [{
        id: 'PAGE1',
        sections: ['SEC1'],
        title: currentCategory
      }]
    }) : { id: '', version: 1, fields: [], sections: [], pages: [] };

    const sideCategory = Object.keys(schemas).filter(item => item !== currentCategory)[0];
    const sideSchema = schemas[sideCategory].schema;

    if (!schemaUpdated) {
      await actionSaveSchema({
        [`${currentCategory}`]: newCurrentSchema,
        [`${sideCategory}`]: sideSchema
      });
    }
    const assets = this.features.map((item) => {
      const uniqueName = item.properties.USERFLAG && item.properties.USERFLAG.search(/\d{2}-\d{2}/) === 0
        ? `${item.properties.OBJECTID}-${item.properties.USERFLAG}` : `${item.properties.NAME}`;
      const resp= {};
      schemas[currentCategory].schema.fields.map((filed) => {
        resp[filed.id] = item.properties[filed.title];
      });
      return {
        name: uniqueName,
        geometry: [item.geometry.coordinates[1], item.geometry.coordinates[0]],
        airport_id: profile.airport.id,
        area_id: '',
        asset_type_id: mappedAssetTypes.filter(type => type.value === Object.values(item.properties)[currentTypeIndex])[0].id,
        label: fields.filter(field => field.name.toLowerCase() === 'name').length
          ? item.properties[fields.filter(field => field.name.toLowerCase() === 'name')[0].key] : '',
        date: '',
        response: resp,
        version_schema_id: schemas[currentCategory].id
      };
    });
    const timeInterval = setInterval(() => {
      if (!assets.length) {
        clearInterval(timeInterval);
      }
      const assets_segment = assets.length > 1000 ? assets.splice(0, 1000) : assets.splice(0);
      actionUploadAssets({ assets: assets_segment });
    }, 1000);
  }

  render() {
    const {
      isAdded,
      geoData,
      mappingView,
      fieldsFile,
      fieldsAsset,
      features,
      currentTypeIndex,
      currentCategory,
      uniqueFeatures,
      fields } = this.state;
    const { profile, types, schemas, upAction } = this.props;

    const fieldsFileOptions = features.length ? Object.keys(features[0].properties).map((item, index) => ({ key: `${index}-${item}`, name: item })) : [];

    let typeOptions = [];
    // asset type mapping
    if (fieldsAsset.length) {
      typeOptions = types.slice().filter(item => item.category.toLowerCase() === currentCategory).map((item, index) => ({ key: `${index}-${item.name}`, name: item.name, icon: item.icon }));
    }

    const categoryOptions = Object.keys(schemas).map((item, index) => ({ key: item, name: item.toUpperCase() }));

    return (
      <div className={styles.importer}>
        <Loading loadingStatus={upAction.loading} />
        <div className={styles.form}>
          <div className={styles.header}>
            <FormattedMessage tagName="span" id="importer.form.formTitle"
              defaultMessage="Mapping Fields"
            />
          </div>
          <div className={styles.body}>
            <Transition
              in={mappingView === 'fields'}
              timeout={0}
            >
              {state => (
                <div className={styles.transitionContainer}>
                  <div style={this.slide[state]}>
                    {geoData && (
                    <div className={styles.statusFields}>
                      { categoryOptions.length !== 0 && (
                      <div className={styles.statusSelect}>
                        <h6>Asset Category</h6>
                        <ColorSelect value={categoryOptions.filter(item => item.key === currentCategory)[0]} options={categoryOptions}
                          onChange={e => this.handleCategoryChange(e)} bordered
                        />
                      </div>
                      ) }
                      { fieldsFileOptions.length !== 0 && (
                      <div className={styles.statusSelect}>
                        <h6>Asset Type Field</h6>
                        <ColorSelect value={fieldsFileOptions[currentTypeIndex]} options={fieldsFileOptions}
                          onChange={e => this.handleSelectChange(e, currentTypeIndex, 'currentTypeIndex')} bordered
                        />
                      </div>
                      ) }
                      <div className={styles.metaFields}>
                        <div className={styles.metaSection}>
                          <h5>Sign types from uploaded file</h5>
                          {
                          uniqueFeatures.map(item => (<p key={item.key}>{item.key}</p>))
                        }
                        </div>
                        <div className={styles.divider} />
                        <div className={[styles.metaSection, styles.mappingSelects].join(' ')}>
                          <h5>Asset categories from Aerosimple</h5>
                          { typeOptions.length !== 0
                          && uniqueFeatures.map((item, index) => (
                            <ColorSelect
                              key={item.key}
                              value={typeOptions.filter(type => type.name === item.value)[0]}
                              options={typeOptions}
                              onChange={e => this.handleAssetTypeMapping(e, index, 'uniqueFeatures')}
                              bordered
                            />
                          ))
                        }
                        </div>
                      </div>
                    </div>
                    )}
                    <div className={styles.nextBtn}>
                      <Button
                        action="secondary"
                        onClick={() => this.toggleMappingView('mappingView', 'status')}
                        translationID="importer.assets.nextBtn"
                        defaultText="Next"
                      />
                    </div>
                  </div>
                  <div style={this.slide[state]}>
                    {geoData && (
                    <div className={styles.metaFields}>
                      <div className={styles.metaSection}>
                        <h5>Fields from uploaded file</h5>
                        {
                        fieldsFile.map((item, index) => (<p key={item.key}>{item.name}</p>))
                      }
                      </div>
                      <div className={styles.divider} />
                      <div className={styles.metaSection}>
                        <h5>Fields from Aerosimple</h5>
                        {
                        fields.map((item, index) => {
                          if (index === currentTypeIndex) {
                            return (<p key={item.key}>Asset Type</p>);
                          }
                          return (
                            <ColorSelect key={item.key} value={item} options={fieldsAsset}
                              onChange={e => this.handleSelectChange(e, index, 'fields')} bordered
                            />
                          );
                        })
                      }
                      </div>
                    </div>
                    ) }
                    <div className={styles.prevBtn}>
                      <Button action="secondary" className={styles.prevBtn} onClick={() => this.toggleMappingView('mappingView', 'fields')}
                        translationID="importer.assets.prevBtn" defaultText="Back"
                      />
                    </div>
                    <div className={styles.finishBtn}>
                      <Button action="secondary" className={styles.finishBtn} onClick={() => this.SaveSchema()}
                        translationID="asserts.settings.shapesaveSchema" defaultText="Save Schema"
                      />
                    </div>
                    <div className={styles.finishBtn}>
                      <Button action="secondary" className={styles.finishBtn} onClick={() => this.finishMapping()}
                        translationID="importer.assets.finishBtn" defaultText="Finish"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Map id="map" className={styles.map} center={[...profile.airport.location.coordinates].reverse()} zoom={ZOOM} zoomControl={false} ref="map">

            <LayersControl checked name="Background Layer">
              <ZoomControl position="bottomright" />
              <Control position="topleft">
                <Dropzone className={styles.dropzone} onDrop={this.handleFile}>
                  {({ open }) => (
                    <p>
                      <FormattedMessage id="map.dropzone.text1" defaultMessage="Drag an shape file or" />
                      <button type="button" onClick={() => open()}>
                        <FormattedMessage id="map.dropzone.button" defaultMessage="browse" />
                      </button>
                      <FormattedMessage id="map.dropzone.text2" defaultMessage="for a file to display." />
                    </p>
                  )}
                </Dropzone>
              </Control>

              { isAdded && (
                <LayersControl.Overlay checked name="Shape Layer">
                  <FeatureGroup color="purple" ref="group">
                    <ShapeFile
                      data={geoData}
                      updateFitBounds={this.updateFitBounds}
                      onEachFeature={this.onEachFeature}
                      pointToLayer={this.pointToLayer}
                      isArrayBufer
                    />
                  </FeatureGroup>
                </LayersControl.Overlay>
              )}
            </LayersControl>
          </Map>
        </div>
      </div>
    );
  }
}

AssetDataImporter.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  schemas: PropTypes.shape({})
};

const mapStateToProps = state => ({
  profile: state.auth.profile,
  types: state.assets.types,
  schemas: state.assets.schemas,
  upAction: state.assets.updateAction
});


const mapDispatchToProps = dispatch => ({
  // fetch asset types
  actionFetchTypes: () => {
    dispatch(fetchAssetTypes());
  },
  // fetch asset schema
  actionGetSchemas: () => {
    dispatch(fetchAssetsSchema());
  },
  // save asset custom fields
  actionSaveSchema: (data) => {
    dispatch(updateAssetsSchema(data));
  },
  // save assets
  actionUploadAssets: (data) => {
    dispatch(uploadAssets(data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDataImporter);
