import React from 'react';
import styles from './Notams.module.scss';
import Collapsible from '../../../../components/collapsible/Collapsible';


const Notams = ({ notams }) => (
  <div className={styles.appPriviliges}>
    <div className={styles.list}>
      {(Array.isArray(notams) ? notams : Object.values(notams)).filter(notam => notam !== null && notam !== undefined).map(notam => (
        <Collapsible
          openOnMount={true}
          title={notam['notam_id'] !== undefined ? notam['notam_id'] : notam['id']}
          autoheight={false}
          styles="0px 20px"
        >
          <p>{notam['notam_text']}</p>
        </Collapsible>
      ))}
    </div>
  </div>
);

export default Notams;
