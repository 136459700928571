import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PulpoField from '../../../../../pulpo_visualizer/fields/PulpoField';
import IconButton from '../../../../../components/iconButton';
import Button from '../../../../../components/button/button';
import Modal from '../../../../../components/modal/modal';
import Assignee from '../../../../../components/assignee/Assignee';
import addIcon from '../../../../../icons/add.svg';
import tableStyles from '../../../../../styles/general.module.scss';
import styles from './MaintenanceAsignee.module.scss';
import modelStyles from '../../../../inspections/Builder/steps/steps.module.scss';
import { addCategory, updateCategory, clearMaintenance, fetchCategories } from '../../../redux/actions';
import Clickable from '../../../../../components/clickable/Clickable';

class MaintenanceAsigneeBuilder extends Component {
  state = {
    openModal: false,
    showFormErrors: false,
    info: {},
    fieldErrors: {
      name: true
    },
    currentUser: '',
    typeSelected: 'user'
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.categories && props.allCategories) {
      return { ...state, categories: props.allCategories };
    }
    return {};
  }

  componentDidUpdate(prevProps) {
    const { openModal } = this.state;
    const { createCategoryAction, updateCategoryAction, actionClear, actionFetchCategories, allCategories } = this.props;
    if (createCategoryAction && createCategoryAction.success && openModal) {
      this.setState({ openModal: false });
      actionFetchCategories();
      actionClear();
    }
    if (updateCategoryAction && updateCategoryAction.success && openModal) {
      this.setState({ openModal: false });
      actionFetchCategories();
      actionClear();
    }
    if (prevProps.allCategories !== allCategories) {
      this.setState({ categories: allCategories });
    }
  }

  openNewAsigneeModal = (status) => {
    if (status === 'new') {
      this.setState({
        openModal: true,
        info: {},
        showFormErrors: false,
        currentUser: ''
      });
    }
  }

  closeModal = () => {
    const { actionClear } = this.props;
    this.setState({
      openModal: false,
      info: {},
      showFormErrors: false,
      currentUser: '',
      typeSelected: 'user'
    });
    actionClear();
  }

  handleAddAssignee = () => {
    const { info, fieldErrors } = this.state;
    const { actionCreateCategory, actionUpdateCategory } = this.props;

    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));

    if (!info.assigned_user && !info.assigned_role) {
      this.setState({ showFormErrors: true });
      return;
    }

    if (noErrors) {
      const data = {
        name: info.name,
        assigned_user: info.assigned_user,
        assigned_role: info.assigned_role ? info.assigned_role : undefined
      };
      data.i18n_id = undefined;
      if ('id' in info) {
        actionUpdateCategory(info.id, data);
      } else {
        actionCreateCategory(data);
      }
    } else {
      this.setState({ showFormErrors: true });
    }
  }

  handleEditModal = (category) => {
    const user = category.assigned_users && category.assigned_users.length > 0 ? (
      category.assigned_users[0]
    ) : undefined;
    this.setState({
      info: {
        id: category.id,
        name: category.name,
        assigned_role: category.assigned_role ? category.assigned_role.id : undefined,
        assigned_user: user && user.id
      },
      currentUser: user && user.fullname,
      fieldErrors: {
        name: false
      },
      openModal: true,
      typeSelected: user ? 'user': 'role'
    });
  }

  handleAnswerChanged = (section, answer, fieldId) => {
    if (answer === 'assigned_role') {
      this.setState(prevState => ({
        info: {
          ...prevState.info,
          assigned_user: undefined,
          [answer]: section
        },
        currentUser: undefined
      }));
      return;
    }
    this.setState(prevState => ({
      [section]: {
        ...prevState[section],
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  onAssignTypeChanged = (value) => {
    this.setState({ typeSelected: value });
  }

  handleAutocompleteChange = (value) => {
    this.setState({ currentUser: value });
  }

  handleAutocompleteSelect = (value, userId) => {
    this.setState(prevState => ({
      currentUser: value,
      info: {
        ...prevState.info,
        assigned_user: userId,
        assigned_role: undefined
      }
    }));
  }

  render() {
    const { openModal, showFormErrors, info, typeSelected, currentUser, categories } = this.state;
    return (
    <>
      <div className={`container ${styles.container}`}>
        <div className={styles.formFieldBox}>
          <div className={`${styles.boxHeader} ${styles.signs}`}>
            <FormattedMessage tagName="h3"
              id="lease.maintenance.settings.asignee_title"
              defaultMessage="Categories and Assignee"
            />
            <IconButton
              icon={addIcon}
              onClick={() => this.openNewAsigneeModal('new')}
            />
          </div>
          <table className={tableStyles.table}>
            <thead>
              <th>
                <FormattedMessage id="lease.maintenance.settings.categories"
                  defaultMessage="Categories"
                />
              </th>
              <th>
                <FormattedMessage id="lease.maintenance.settings.asignee"
                  defaultMessage="Assignee"
                />
              </th>
              <th />
            </thead>
            <tbody>
              {categories && categories.map(e => (
                <tr>
                  <td>{e.name}</td>
                  <td>
                    <div className={tableStyles.assignee}>
                      {e.assigned_role && e.assigned_role.name}
                      {e.assigned_users && e.assigned_users.length > 0 && e.assigned_users[0].fullname}
                    </div>
                  </td>
                  <td>
                    <Clickable onClick={() => this.handleEditModal(e)}>
                      <FormattedMessage id="workorder.listbox.edit" defaultMessage="Edit" />
                    </Clickable>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal showIn={openModal} onClose={this.closeModal} width="900px" minHeight="30%">
        <div className={modelStyles.newField}>
          <div className={modelStyles.title}>
            <FormattedMessage tagName="h5" id="lease.maintenance.settings.asignee_title"
              defaultMessage="Categories and Assignee"
            />
          </div>
          <div className={styles.form}>
            <div className={styles.category}>
              <PulpoField key="name" id="name" type="string"
                translationID="workorders.list.table.category"
                title="Category" isRequired
                handleValueChange={(a, b) => this.handleAnswerChanged('info', a, b)}
                answer={info.name}
                showFieldErrors={showFormErrors} handleFieldErrorChanged={this.handleFieldErrorChanged}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <PulpoField key="role" id="" type="select"
                title="Assigned to"
                translationID="todo.newTask.user"
                handleValueChange={this.onAssignTypeChanged}
                answer={typeSelected}
                values={[
                  { key: 'user', value: 'User' },
                  { key: 'role', value: 'Role' }
                ]}
                showFieldErrors={showFormErrors}
                handleFieldErrorChanged={() => ({})}
                className={styles.asignee}
              />
              <Assignee
                typeSelected={typeSelected}
                updateUserValue={this.handleAutocompleteChange}
                onAssigneeSelected={this.handleAutocompleteSelect}
                onRoleChange={this.handleAnswerChanged}
                userValue={currentUser}
                showFieldErrors={showFormErrors}
                assignedRole={info.assigned_role}
                assignedUser={info.assigned_user}
                className={styles.asignee}
              />
            </div>
          </div>
          <div className={modelStyles.footer}>
            <Button action="tertiary" translationID="inspections.new.cancelBtn" defaultText="Cancel"
              onClick={this.closeModal}
            />
            <div className={modelStyles.modalBtnGroup}>
              <Button action="primary" translationID="inspections.new.addBtn" defaultText="Add"
                onClick={this.handleAddAssignee}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
    );
  }
}

MaintenanceAsigneeBuilder.propTypes = {
  actionCreateCategory: PropTypes.func.isRequired,
  actionUpdateCategory: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  allCategories: state.lease.allCategories,
  createCategoryAction: state.lease.createCategoryAction,
  updateCategoryAction: state.lease.updateCategoryAction
});

const mapDispatchToProps = dispatch => ({
  actionCreateCategory: (data) => {
    dispatch(addCategory(data));
  },
  actionUpdateCategory: (id, data) => {
    dispatch(updateCategory(id, data));
  },
  actionClear: () => {
    dispatch(clearMaintenance());
  },
  actionFetchCategories: () => {
    dispatch(fetchCategories());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceAsigneeBuilder);
