/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import sortIcon from '../../../../icons/inspection-icons/sort.svg';
import sortActiveIcon from '../../../../icons/inspection-icons/sort-active.svg';

import { WORK_ORDERS_DETAIL, WORKORDERS_HOME_ROUTE } from '../../../../constants/RouterConstants';

import Status from './Status';
import styles from '../../../../styles/general.module.scss';

const Table = ({ info, translations, sortData, sortKey }) => {
  const priorities = {
    0: 'workorders.list.table.priorities.low',
    1: 'workorders.list.table.priorities.medium',
    2: 'workorders.list.table.priorities.high'
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="workorders.list.table.work_order" defaultMessage="Work Order #" />
            <img src={sortKey === 'order_number' ? sortActiveIcon : sortIcon} alt="" onClick={() => sortData('order_number')} />
          </th>
          <th>
            <FormattedMessage id="workorders.list.table.category" defaultMessage="Category" />
            <img src={sortKey === 'category' ? sortActiveIcon : sortIcon} alt="" onClick={() => sortData('category')} />
          </th>
          <th>
            <FormattedMessage id="workorders.list.table.status" defaultMessage="Status" />
            <img src={sortKey === 'Status' ? sortActiveIcon : sortIcon} alt="" onClick={() => sortData('Status')} />

          </th>
          <th>
            <FormattedMessage id="workorders.list.table.reported" defaultMessage="Reported" />
            <img src={sortKey === 'report_date' ? sortActiveIcon : sortIcon} alt="" onClick={() => sortData('report_date')} />

          </th>
          <th>
            <FormattedMessage id="workorders.list.table.priority" defaultMessage="Priority" />
            <img src={sortKey === 'Priority' ? sortActiveIcon : sortIcon} alt="" onClick={() => sortData('Priority')} />

          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!info.length>0 ? (
          <tr>
            <td className="noTableData" colSpan={5}>
              <FormattedMessage id="WorkOrders.list.table.no_results" defaultMessage="There are no workorders on the selected range" />
            </td>
          </tr>
        )
          : (
            info.map(e => (
              <tr key={e.id}>
                <td>{e.order_number}</td>
                <td>{translations[e.category] || e.category}</td>
                <td><Status item={e.status} /></td>
                <td className={styles.date}>{(moment(e.report_date)).format('MM/DD/YYYY hh:mm A')}</td>
                <td>
                  <FormattedMessage id={priorities[e.priority]} defaultMessage="Unknown Priority" />
                </td>
                <td>
                  <Link to={`${WORKORDERS_HOME_ROUTE}${e.order_number}/${WORK_ORDERS_DETAIL}`}>
                    <FormattedMessage id="workorders.list.table.action" defaultMessage="View" />
                  </Link>
                </td>
              </tr>
            ))
          )
        }
      </tbody>
    </table>
  );
};

Table.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default Table;
