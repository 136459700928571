/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { FormattedMessage } from 'react-intl';
/** ******************************************************************
 *  Redux import
 * ***************** */

import { INSPECTIONS_HOME_ROUTE } from '../../../constants/RouterConstants';
import { fetchInspection, clearInspection, completeInspection, searchUser, createRemark,
  editRemark, fetchInspectionAnswer, clearInspectionAnswer, fetchOpenWorkOrders, clearOpenWorkOrders,
  fetchDataSourceList } from '../redux/actions';
import { fetchShiftTypes } from '../../settings/redux/actions';
/** ******************************************************************
 *  Components import
 * ************* */

import SectionHeader from '../../../components/sectionHeader';
import Button from '../../../components/button';
import HeaderBack from '../../../components/headerBack';
import InspectionDetails from './components/InspectionDetails';
import InspectionChecklist from './components/InspectionChecklist';
import InspectionSubmitted from './components/InspectionSubmitted';
import Modal from '../../../components/modal';
/** ******************************************************************
 *  Assets import
 * ************* */

import { importAllImages } from '../../../utils/helpers';
import styles from './inspection.module.scss';
import { GetFileName, S3Uploads } from '../../services';

class EditInspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      apiStatus: undefined,
      showFormErrors: false,
      answers: {
        date: moment()
      },
      remarksIDs: {},
      remarks: [],
      activeInspectionField: 0,
      requiredMap: {},
      fieldErrors: {},
      open_workorders: [],
      incompleteChecklist: false,
      inspection: {
        info: {
          name: '',
          icon: ''
        },
        details: {
          fields: [],
          additionalInfo: ''
        },
        checklist: []
      },
      inspectionVersion: {
        checklist: {},
        details: {
          fields: [],
          additionalInfo: ''
        }
      },
      DateChanged: false,
      invalidShift: false
    };

    this.processInspectionForState = this.processInspectionForState.bind(this);
    this.handleGoToChecklist = this.handleGoToChecklist.bind(this);
    this.handleGoInspections = this.handleGoInspections.bind(this);
    this.handleAnswerChanged = this.handleAnswerChanged.bind(this);
    this.handleFieldErrorChanged = this.handleFieldErrorChanged.bind(this);
    this.handleInspectionItemAnswerChanged = this.handleInspectionItemAnswerChanged.bind(this);
    this.handleInspectionItemRemarkChanged = this.handleInspectionItemRemarkChanged.bind(this);
    this.handleInspectionFieldChanged = this.handleInspectionFieldChanged.bind(this);

    // this.isChecklistComplete = this.isChecklistComplete.bind(this);
    this.handleGoBackToDetails = this.handleGoBackToDetails.bind(this);
    this.handleCompleteInspection = this.handleCompleteInspection.bind(this);
    this.handleSearchForUser = this.handleSearchForUser.bind(this);

    this.images = importAllImages(require.context('../../../icons/inspection-icons', false, /\.(png|jpe?g|svg)$/));
  }

  componentDidMount() {
    const { actionFetch, actionFetchAnswer, match: { params: { id, aid } },
      shiftTypes, actionFetchShiftTypes, ClearOpenWorkOrders, fetchDataSources } = this.props;
    actionFetch(id);
    actionFetchAnswer(aid);
    ClearOpenWorkOrders();
    fetchDataSources();
    if (!shiftTypes.length) actionFetchShiftTypes();
  }

  componentDidUpdate(prevProps) {
    const { inspection } = this.props;
    if (!prevProps.inspection.id && inspection.id) {
      this.processInspectionForState(inspection);
    }
  }

  componentWillUnmount() {
    const { actionClear, actionClearAnswer } = this.props;
    actionClear();
    actionClearAnswer();
  }

  static getDerivedStateFromProps(props, state) {
    // grab user from state
    if (props.answer.inspected_by && !state.answers.inspected_by) {
      let thefieldErrors = {};
      let thecheckList = [];
      let thedetails=[];
      const bool = true;
      if (props.answer.response) {
        Object.entries(props.answer.response).forEach(([key, value]) => {
          thefieldErrors = {
            ...thefieldErrors,
            [key]: value ? !bool : bool
          };
        });
      }
      if (props.answer.version && props.answer.response) {
        props.answer.version.schema.fields.forEach((value) => {
          if (value.type === 'inspection') {
            thecheckList = [
              ...thecheckList,
              value
            ];
          } else {
            thedetails = [
              ...thedetails,
              value
            ];
          }
        });
      }
      return { ...state,
        answers: {
          date: moment(props.answer.inspection_date).format(),
          inspected_by: props.answer.inspected_by,
          shiftName: props.answer.shift_name,
          // type: props.answer.inspection_type,
          ...props.answer.response
        },
        remarksIDs: props.answer.remarksIDs,
        remarks: props.answer.remarksEdit,
        fieldErrors: {
          date: props.answer.inspection_date ? !bool : bool,
          // type: props.answer.inspection_type ? !bool : bool,
          ...thefieldErrors
        },
        inspectionVersion: {
          checklist: thecheckList,
          details: {
            fields: thedetails
          }
        }
      };
    }

    // update state when answer is completed.
    if (props.createAction && props.answerCreated && props.answerCreated.status === 1) {
      return { ...state, apiStatus: props.createAction.success };
    }
    if (!state.open_workorders.length && props.OpenWorkOrders && props.OpenWorkOrdersAction.success) {
      return { ...state, open_workorders: props.OpenWorkOrders };
    }

    // update remarks IDs when a new remark is created;
    const r = props.remarkCreated;
    if (r) {
      const thestate = state;
      thestate.remarksIDs[r.field_reference] = {
        ...thestate.remarksIDs[r.field_reference],
        [r.item_reference]: r.id
      };
      return thestate;
    }
    return state;
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  handleWorkorderCreate = (newWorkOrder) => {
    const { open_workorders } = this.state;
    if (!open_workorders.length) {
      this.setState(prevState => ({
        inspection: { ...prevState.inspection,
          workorders: [...prevState.inspection.workorders, newWorkOrder] }
      }));
    } else {
      this.setState(prevState => ({
        open_workorders: [...prevState.open_workorders, newWorkOrder]
      }));
    }
  }

  processInspectionForState(inspection) {
    if (inspection) {
      // const { details } = inspection;
      const { answers, inspectionVersion } = this.state;
      let requiredMap = {};
      let fieldErrors = {};

      inspectionVersion.details.fields.forEach((detailField) => {
        requiredMap = {
          ...requiredMap,
          [detailField.id]: detailField.required
        };

        fieldErrors = {
          ...fieldErrors,
          [detailField.id]: detailField.required
        };
        if (answers[detailField.id]) {
          fieldErrors = {
            ...fieldErrors,
            [detailField.id]: !detailField.required
          };
        }
      });

      fieldErrors.date = false;
      // fieldErrors.type = false;
      fieldErrors.inspected_by = false;
      // fieldErrors.weather = false;
      this.setState({
        inspection,
        requiredMap,
        fieldErrors
      });
    }
  }

  handleGoToChecklist() {
    const { fieldErrors, answers, DateChanged, inspectionVersion } = this.state;
    const { actionFetchOpenWorkOrders, answer } = this.props;
    const noErrors = Object.keys(fieldErrors)
      .every(k => (fieldErrors[k] === false));
    if (noErrors) {
      if (answers.shiftName !== answer.shift_name) {
        this.setState({ invalidShift: true });
        return;
      }
      if (DateChanged && answer.open_workorders) {
        const date = new Date(answers.date);
        actionFetchOpenWorkOrders(date.toISOString());
        this.setState({ open_workorders: answer.open_workorders });
      }
      this.setState({ stage: 2 });
      let flag = true;
      inspectionVersion.checklist.forEach((inspectionField, idx) => {
        const checklist_length = Object.keys(inspectionField.checklist.filter(
          item => !item.shifts || item.shifts.indexOf(answers.shiftName) !== -1 || item.shifts.length === 0
        )).length;
        if (checklist_length > 0 && flag) {
          this.setState({ activeInspectionField: idx });
          flag = false;
        }
      });
    } else {
      this.setState({ showFormErrors: true });
    }
  }

  handleGoInspections() {
    const { history, actionClear, actionClearAnswer } = this.props;
    actionClear();
    actionClearAnswer();
    history.push(INSPECTIONS_HOME_ROUTE);
  }

  handleAnswerChanged(answer, fieldId) {
    const { answers } = this.state;
    if (fieldId === 'date') {
      delete answers.shiftName;
      this.setState({ DateChanged: true });
    }
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [fieldId]: answer
      }
    }));
  }

  handleFieldErrorChanged(fieldId, errorValue) {
    // Updates field error map with value passed from field component
    this.setState(prevState => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [fieldId]: errorValue
      }
    }));
  }

  handleInspectionItemAnswerChanged(inspectionFieldId, itemKey, value) {
    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [inspectionFieldId]: {
          ...prevState.answers[inspectionFieldId],
          [itemKey]: value
        }
      }
    }));
  }

  async handleInspectionItemRemarkChanged(inspectionFieldId, itemKey, value) {
    this.setState(prevState => ({
      remarks: {
        ...prevState.remarks,
        [inspectionFieldId]: {
          ...prevState.remarks[inspectionFieldId],
          [itemKey]: value
        }
      }
    }));

    const {
      actionCreateRemark, actionEditRemark,
      actionFetchAnswer, match: { params: { aid } }
    } = this.props;
    const { remarksIDs } = this.state;

    const formData = new FormData();
    formData.append('answer', aid);
    formData.append('field_reference', inspectionFieldId);
    formData.append('item_reference', itemKey);
    formData.append('text', value.text);
    // if (value.photo) formData.append('image', value.photo);
    if (value.attachments) {
      let fileIds = '';
      fileIds = await this.getFileNames(1);
      if (fileIds) {
        await this.doS3Upload([value.attachments], fileIds, 'remark')
          .then(() => {
            fileIds.filename.forEach((fileId) => {
              formData.append('attachments', fileId.toString());
            });
          });
      }
    }
    if (remarksIDs[inspectionFieldId] && remarksIDs[inspectionFieldId][itemKey]) {
      actionEditRemark(remarksIDs[inspectionFieldId][itemKey], formData);
    } else {
      actionCreateRemark(formData);
    }
    actionFetchAnswer(aid);
  }

  handleInspectionFieldChanged(idx) {
    this.setState({
      activeInspectionField: idx
    });
  }

  handleGoBackToDetails() {
    this.setState({ stage: 1 });
  }

  // isChecklistComplete() {
  //   const { answers, inspection: { checklist } } = this.state;

  //   const allAnswered = checklist.reduce((accumulator, currentItem) => {
  //     const allCurrentItem = answers[currentItem.id] && (
  //     Object.keys(currentItem.checklist).length === Object.keys(answers[currentItem.id]).length);

  //     return !!accumulator && !!allCurrentItem;
  //   }, true);

  //   return allAnswered;
  // }

  handleCompleteInspection() {
    const {
      answers: {
        // eslint-disable-next-line camelcase
        date, type, inspected_by, shiftName,
        ...rest
      }
    } = this.state;
    // this.setState({ incompleteChecklist: false });

    // if (this.isChecklistComplete()) {
    const {
      actionSubmit,
      match: { params: { id, aid } },
      location } = this.props;

    actionSubmit(
      id,
      { answer_id: aid,
        response: rest,
        date: new Date(date),
        weather: { },
        type,
        inspected_by: inspected_by.id,
        created_by: inspected_by.id,
        task_details: {
          ...location.state
        },
        shift_name: shiftName
      }
    );
    // }
    // } else {
    //   this.setState({ incompleteChecklist: true });
    // }
  }

  handleSearchForUser(value) {
    const { actionSearchUser } = this.props;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  render() {
    const {
      stage,
      answers,
      requiredMap,
      showFormErrors,
      apiStatus,
      activeInspectionField,
      remarks,
      inspectionVersion,
      DateChanged,
      invalidShift
    } = this.state;

    const { userlist, inspection, user, answer, translations, shiftTypes,
      OpenWorkOrders, OpenWorkOrdersAction, allDataSources } = this.props;
    const { open_workorders }= this.state;
    const translationMap = (translations && translations[user.language])
      ? translations[user.language] : {};
    return (
      <>
        <SectionHeader
          icon={answer.icon && answer.icon.includes('.png') ? this.images[answer.icon] : this.images[`${answer.icon}.svg`]}
          translationID="invalid"
          defaultTitle={answer.inspection}
        >
          { stage === 2 && !apiStatus && (
            <>
              <div className={styles.completeBtn}>
                <Button action="tertiary" onClick={this.handleGoBackToDetails}
                  translationID="inspections.complete_inspections.back_to_details" defaultText="Go back to Details"
                />
              </div>
              <div className={styles.completeBtn}>
                <Button action="secondary" onClick={this.handleCompleteInspection}
                  translationID="inspections.complete_inspections.Update" defaultText="Update Inspection"
                />
              </div>
            </>
          )}
        </SectionHeader>
        <HeaderBack
          translationID="inspections.start.inspections.back"
          translationDefault="Back to Inspections"
          backRoute={INSPECTIONS_HOME_ROUTE}
        />
        <div className={styles.container}>
          { stage === 1 && !apiStatus && allDataSources && (
            <InspectionDetails
              answers={answers}
              translations={translationMap}
              shiftTypes={shiftTypes}
              handleAnswerChanged={this.handleAnswerChanged}
              detailsForm={inspectionVersion.details}
              handleGoToChecklist={this.handleGoToChecklist}
              requiredMap={requiredMap}
              handleFieldErrorChanged={this.handleFieldErrorChanged}
              showFormErrors={showFormErrors}
              userlist={userlist}
              searchForUser={this.handleSearchForUser}
              answer={answer}
              allDataSources={allDataSources}
            />
          )}
          { stage === 2 && !apiStatus && (DateChanged ? OpenWorkOrders
          && ((OpenWorkOrdersAction && OpenWorkOrdersAction.success) || !answer.open_workorders) : true) && (
            <InspectionChecklist
              id={inspection.id}
              answers={answers}
              translations={translationMap}
              remarks={remarks}
              workorders={!open_workorders.length ? answer.open_workorders : OpenWorkOrders}
              selfInspection={user.airport.safety_self_inspection === inspection.id}
              handleAnswerChanged={this.handleAnswerChanged}
              checklist={inspectionVersion.checklist}
              handleWorkorderCreate={this.handleWorkorderCreate}
              activeIdx={activeInspectionField}
              handleChecklistItemStatusChange={this.handleInspectionItemAnswerChanged}
              handleChecklistItemRemarkChange={this.handleInspectionItemRemarkChanged}
              handleInspectionFieldChanged={this.handleInspectionFieldChanged}
              apiStatus={apiStatus}
              // incompleteChecklist={incompleteChecklist}
              answer={answer}
            />
          )}
          <Modal
            onClose={() => this.setState({ invalidShift: false })}
            showIn={invalidShift}
            width="35%"
            minHeight="20%"
          >
            <div className={styles.confirmContent}>
              <FormattedMessage id="edit.inspections.inspection.saved" defaultMessage="select inspection date within the inspection shift." />
            </div>
            <div className={styles.btn}>
              <Button type="secondary" translationID="inspections.list.ok" onClick={() => this.setState({ invalidShift: false })} defaultText="OK" />
            </div>
          </Modal>
          { apiStatus && (
            <InspectionSubmitted icon={answer.icon} name={answer.inspection}
              handleGoInspections={this.handleGoInspections}
            />
          )}
        </div>
      </>
    );
  }
}

EditInspection.propTypes = {
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired,
  actionCreateRemark: PropTypes.func.isRequired,
  actionEditRemark: PropTypes.func.isRequired,
  actionSubmit: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  answerCreated: PropTypes.shape({}),
  inspection: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  actionFetchAnswer: PropTypes.func.isRequired,
  actionClearAnswer: PropTypes.func.isRequired,
  answer: PropTypes.shape({}).isRequired,
  shiftTypes: PropTypes.arrayOf(PropTypes.shape({}))
};

EditInspection.defaultProps = {
  userlist: [],
  answerCreated: {},
  shiftTypes: {}
};

const mapStateToProps = state => ({
  inspection: state.inspection.inspection,
  createAction: state.inspection.createAction,
  answerCreated: state.inspection.answerCreated,
  userlist: state.inspection.userlist,
  user: state.auth.profile,
  remarkCreated: state.inspection.remarkCreated,
  answer: state.inspection.answer,
  translations: state.auth.translations,
  shiftTypes: state.settings.shiftTypes,
  OpenWorkOrders: state.inspection.OpenWorkOrders,
  OpenWorkOrdersAction: state.inspection.OpenWorkOrdersAction,
  allDataSources: state.inspection.allDataSources
}); // Please review your data

const mapDispatchToProps = dispatch => ({
  // Fetch inspection
  actionFetch: (id) => {
    dispatch(fetchInspection(id));
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionClear: () => {
    dispatch(clearInspection());
  },
  actionSubmit: (id, data) => {
    dispatch(completeInspection(id, data));
  },
  actionCreateRemark: (data) => {
    dispatch(createRemark(data));
  },
  actionEditRemark: (id, data) => {
    dispatch(editRemark(id, data));
  },
  // Fetch inspection Answer
  actionFetchAnswer: (id) => {
    dispatch(fetchInspectionAnswer(id));
  },
  actionClearAnswer: () => {
    dispatch(clearInspectionAnswer());
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  actionFetchOpenWorkOrders: (date) => {
    dispatch(fetchOpenWorkOrders(date));
  },
  ClearOpenWorkOrders: () => {
    dispatch(clearOpenWorkOrders());
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInspection);
