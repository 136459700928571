import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import SectionHeader from '../../../../components/sectionHeader';
import HeaderBack from '../../../../components/headerBack';
import PanelContainer from '../../../../components/panelcontainer';
import Clickable from '../../../../components/clickable/Clickable';
import Loading from '../../../../components/loading/loading';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Panel from '../../../../components/panel';
import icon from '../../../../icons/Contracts.png';
import { TENANTS } from '../../../../constants/RouterConstants';
import styles from './TenantsDetail.module.scss';
import LeaseTermDetail from './components/LeaseTermDetail';
import AircraftDetail from './components/AircraftDetail';
import PropertiesMap from '../Map/PropertiesMap';
import { getTenant, fetchAllTypes, updateTenant, clearTenantsTypes } from '../../redux/actions';
import SpaceDetail from './components/SpaceDetail';
import FeeDetail from './components/FeeDetail';
import DocumentDetail from './components/DocumentDetail';
import FeeForm from '../Create/steps/Step3/components/FeeForm';
import DocumentForm from '../Create/steps/Step3/components/Documents';
import AircraftForm from '../Create/steps/Step4/components/AircraftForm';
import { GetFileName, S3Uploads } from '../../../services';

class TenantsDetail extends Component {
  state = {
    section: 'lease_terms',
    showIn: false,
    info: {},
    loadingStatus: false
  }

  componentDidMount() {
    const { actionFetchTenant, actionFetchTypes, match: { params: { id } } } = this.props;
    actionFetchTenant(id);
    actionFetchTypes();
    this.setState({ loadingStatus: true });
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.tenant_detail && props.tenant && Object.keys(props.tenant).length > 0) {
      return {
        ...state,
        tenant_detail: props.tenant,
        loadingStatus: false
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { TenantUpdateAction, actionFetchTenant, actionClear, tenant } = this.props;
    const { tenant_detail } = this.props;

    if (prevProps.TenantUpdateAction.success === false && TenantUpdateAction.success === true) {
      actionFetchTenant(tenant.id);
      actionClear();
      this.setState({ tenant_detail: {}, info: {}, showIn: false });
    }
    if (!tenant_detail && (prevProps.tenant !== tenant)) {
      this.setState({ tenant_detail: tenant, loadingStatus: false });
    }
  }

  handleTabClick = (section) => {
    this.setState({ section });
  }

  handleModal = (status, data) => {
    const { actionUpdateTenant, tenant } = this.props;
    const { section } = this.state;
    if (data && status === 'modify') {
      this.setState({ info: data, showIn: true });
    } else if (status === 'delete') {
      const removedItem = {};
      removedItem.removed = data.id;
      this.setState({ loadingStatus: true });
      actionUpdateTenant(tenant.id, { [section]: removedItem });
    } else if (status === 'duplicate') {
      const addedItem = {};
      addedItem.duplicate = data.id;
      this.setState({ loadingStatus: true });
      actionUpdateTenant(tenant.id, { [section]: addedItem });
    }
    if (status === 'new') this.setState({ showIn: true });
  }

  handleAnswerChanged = (answer, id) => {
    this.setState(prevState => ({
      info: {
        ...prevState.info,
        [id]: answer
      }
    }));
  }

  handleCancel = () => {
    this.setState({ showIn: false, info: {} });
  }

  handleSave = async () => {
    const { info, section } = this.state;
    const { actionUpdateTenant, tenant } = this.props;
    const sendResponse = {};
    this.setState({ loadingStatus: true });
    Object.keys(info).forEach((e) => {
      if (e !== 'attachments' && e !== 'receipt_attachments') {
        sendResponse[e] = info[e];
      }
    });
    const attachments = info.attachments || info.receipt_attachments;
    const entity = info.attachments ? 'tenant_lease_documents' : 'tenant_fee_receipts';
    const newAttachments = [];
    if (attachments && attachments.length > 0) {
      attachments.forEach((e) => {
        if (!e.id) {
          newAttachments.push(e);
        }
      });
    }
    let fileIds = '';
    if (newAttachments.length > 0) {
      fileIds = await this.getFileNames(newAttachments.length);
    }
    const list = [];
    if (fileIds) {
      await this.doS3Upload(newAttachments, fileIds, entity)
        .then(() => {
          fileIds.filename.forEach((id) => {
            list.push(id.toString());
          });
        });
    }
    if (list.length > 0) sendResponse.attachments = list;
    actionUpdateTenant(tenant.id, { [section]: sendResponse });
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  render() {
    const { section, showIn, info, loadingStatus, tenant_detail } = this.state;
    const { user, allTypes } = this.props;
    return (
    <>
      <SectionHeader icon={icon} translationID="shortcuts.lease.tenants" defaultTitle="Tenants" />
      <HeaderBack
        translationID="inspections.new.prev"
        translationDefault="Back"
        backRoute={`${TENANTS}/`}
      />
      <Loading loadingStatus={loadingStatus} />
      <div className="container">
        {tenant_detail && Object.keys(tenant_detail).length > 0 && (
        <div className={styles.detail}>
          <div className={styles.firstcolumn}>
            <div className={styles.titlePanel}>
              <h3>{tenant_detail.name}</h3>
            </div>
            <Panel
              containerClasses={styles.mainPanel}
              title="lease.tenants.detail.company_details"
              defaultTitle="Company Details"
            >
              <div className={styles.requestInfo}>
                <div className={styles.infoTable}>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.name" defaultMessage="Company Name" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.name}
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.alias" defaultMessage="Alias Name" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.alias || '-'}
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.address" defaultMessage="Company Address" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.address}
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.phone" defaultMessage="Company Phone" />
                    </span>
                    <span className={styles.rowContent}>
                      <NumberFormat displayType="text" value={tenant_detail.phone}
                        format="+1 (###) ###-####"
                      />
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.businesstype" defaultMessage="Business Type" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.business_type}
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="tenants.new.category" defaultMessage="Category" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.category}
                    </span>
                  </div>
                  <div className={styles.infoRow}>
                    <span className={styles.title}>
                      <FormattedMessage id="lease.tenants.steps.step2.lease_type" defaultMessage="Type of Lease" />
                    </span>
                    <span className={styles.rowContent}>
                      {tenant_detail.lease_term && tenant_detail.lease_term.lease_type && tenant_detail.lease_term.lease_type.name}
                    </span>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
          <div className={styles.secondcolumn}>
            <PanelContainer containerClasses={styles.sidePanel}>
              <div className={styles.navigation}>
                <Clickable className={section === 'lease_terms' ? styles.active : ''}
                  onClick={() => this.handleTabClick('lease_terms')}
                >
                  <FormattedMessage id="lease.tenants.lease_terms" defaultMessage="Lease Terms" />
                </Clickable>
                <Clickable className={section === 'lease_space' ? styles.active : ''}
                  onClick={() => this.handleTabClick('lease_space')}
                >
                  <FormattedMessage id="lease.tenants.detail.lease_space" defaultMessage="Leased Space" />
                </Clickable>
                <Clickable className={section === 'fee' ? styles.active : ''}
                  onClick={() => this.handleTabClick('fee')}
                >
                  <FormattedMessage id="lease.tenants.steps.step3.fee" defaultMessage="fees" />
                </Clickable>
                <Clickable className={section === 'document' ? styles.active : ''}
                  onClick={() => this.handleTabClick('document')}
                >
                  <FormattedMessage id="lease.tenants.detail.documentation" defaultMessage="Documentation" />
                </Clickable>
                <Clickable className={section === 'aircraft' ? styles.active : ''}
                  onClick={() => this.handleTabClick('aircraft')}
                >
                  <FormattedMessage id="lease.tenants.steps.step4.based_aircraft" defaultMessage="Based Aircrafts" />
                </Clickable>
              </div>
              {section === 'lease_terms' && (
              <LeaseTermDetail answers={tenant_detail.lease_term} />
              )}
              {section === 'lease_space' && tenant_detail && tenant_detail.lease_term && tenant_detail.lease_term.lease_type && (
              <SpaceDetail info={tenant_detail.lease_term.lease_type} />
              )}
              {section === 'document' && (
                <>
                  <div className={styles.requestInfo}>
                    <div className={styles.infoTable}>
                      <div className={styles.headerRow}>
                        <span className={styles.title}>
                          <FormattedMessage id="tenant.detail.documents" defaultMessage="Documents" />
                        </span>
                        <span className={styles.rowContent}>
                          <Clickable onClick={() => this.handleModal('new')}>
                            <FormattedMessage id="tenant.detail.add_doc" defaultMessage="Add new document +" />
                          </Clickable>
                        </span>
                      </div>
                    </div>
                  </div>
                  {tenant_detail.documents && tenant_detail.documents.length > 0 && tenant_detail.documents.map(e => (
                    <DocumentDetail info={e} handleModal={this.handleModal} />
                  ))}
                </>
              )}
              {section === 'aircraft' && (
                <>
                  {tenant_detail.aircrafts && (
                  <AircraftDetail aircrafts={tenant_detail.aircrafts} handleModal={this.handleModal} />
                  )}
                </>
              )}
              {section === 'fee' && (
                  <>
                    {tenant_detail.fees && (
                      <FeeDetail info={tenant_detail.fees} handleModal={this.handleModal} />
                    )}
                </>
              )}
            </PanelContainer>
            {tenant_detail && tenant_detail.lease_term && tenant_detail.lease_term.lease_type && (
            <PanelContainer containerClasses={styles.sidePanel}>
              <div className={styles.mapTitle}>
                <span>
                  <FormattedMessage id="lease.tenants.detail.property_map" defaultMessage="Property Map" />
                </span>
              </div>
              <PropertiesMap
                allProperties={[tenant_detail.lease_term.lease_type]}
                defaultLocation={user.airport.location.coordinates}
                mapStyles={styles.map}
              />
            </PanelContainer>
            )}
          </div>
        </div>
        )}
        <Modal showIn={showIn} onClose={this.handleCancel} width="930px">
          <div className={styles.newField}>
            <div className={styles.title}>
              {!info.id ? (
                <FormattedMessage tagName="h5" id={`tenant.detail.new_${section}_title`}
                  defaultMessage={`Add ${section}`}
                />
              ) : (
                <FormattedMessage tagName="h5" id={`tenant.detail.edit_${section}_title`}
                  defaultMessage={`Edit ${section}`}
                />
              )}
            </div>
            {section === 'fee' && (
              <>
                <div className={styles.content}>
                  <FeeForm info={info} FeeDetail handleAnswerChanged={this.handleAnswerChanged} />
                </div>
              </>
            )}
            {section === 'document' && (
              <>
                <div className={styles.content}>
                  <DocumentForm info={info} handleAnswerChanged={this.handleAnswerChanged} />
                </div>
              </>
            )}
            {section === 'aircraft' && (
              <>
                <div className={styles.content}>
                  <AircraftForm allTypes={allTypes} info={info} handleAnswerChanged={this.handleAnswerChanged} />
                </div>
              </>
            )}
            <div className={styles.footer}>
              <Button action="tertiary" translationID="inspections.new.cancelBtn" defaultText="Cancel"
                onClick={this.handleCancel}
              />
              <div className={styles.modalBtnGroup}>
                <Button action="primary" translationID="inspections.new.addBtn" defaultText="Add"
                  onClick={this.handleSave}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
    );
  }
}

TenantsDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  actionFetchTenant: PropTypes.func.isRequired,
  actionFetchTypes: PropTypes.func.isRequired,
  actionUpdateTenant: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.profile,
  tenant: state.lease.tenant,
  allTypes: state.lease.allTenantTypes,
  TenantUpdateAction: state.lease.TenantUpdateAction,
  TenantGetAction: state.lease.TenantGetAction
});

const mapDispatchToProps = dispatch => ({
  actionFetchTenant: (id) => {
    dispatch((getTenant(id)));
  },
  actionFetchTypes: () => {
    dispatch(fetchAllTypes());
  },
  actionUpdateTenant: (id, data) => {
    dispatch(updateTenant(id, data));
  },
  actionClear: () => {
    dispatch(clearTenantsTypes());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantsDetail);
