import React, { memo, useMemo } from 'react';
import { Menu, Segment, Sidebar as LibSidebar } from 'semantic-ui-react';
import { Wrapper } from './PostComments.styles';
import {
  ChannelType,
  ActiveChannelFunc,
  MessengerModeTypes,
  ChannelsGroupTypes,
  NewPostsType
} from '../../types';
import { MenuItem } from './MessageItem';
import { ChatTypes } from '../../enums';
import { ReactComponent as Search } from '../../../../icons/search.svg';

type SortedChannels = {
  privateChannels: JSX.Element[];
  publicChannels: JSX.Element[];
};

type PostCommentsProps = {
  visible?: boolean;
  privateChannels?: ChannelType[];
  publicChannels?: ChannelType[];
  newPosts?: NewPostsType;
  channelId?: string;
  setActiveChannel?: ActiveChannelFunc;
  channelsGroup?: ChannelsGroupTypes;
  type: MessengerModeTypes;
};

const PostComments = memo<PostCommentsProps>(
  ({ channelsGroup, channelId, setActiveChannel, newPosts, type }) => {

    const listItems = useMemo(() => {
      function handleActiveChannel<ActiveChannelFunc>(name, id){
        if (channelId === id) {
          return;
        }
        setActiveChannel(name, id);
      }
      if (type === ChatTypes.operations) {
        const sortedChannels = channelsGroup[type].reduce(
          (acc: SortedChannels, item, index) => {
            const { publicChannels, privateChannels } = acc;
            const {
              channels: { type: listType }
            } = item;

            if (!privateChannels.length) {
              privateChannels.push(
                <Menu.Item as="div" key="privateChannels">
                  <h3>Private Channels</h3>
                </Menu.Item>
              );
            }
            if (!publicChannels.length) {
              publicChannels.push(
                <Menu.Item as="div" key="publicChannels">
                  <h3>Public Channels</h3>
                </Menu.Item>
              );
            }

            if (listType) {
              privateChannels.push(
                <MenuItem
                  handleActiveChannel={handleActiveChannel}
                  item={{ [type]: item }}
                  channelId={channelId}
                  newPosts={newPosts}
                  key={item.channelId}
                  type={type}
                />
              );
              return acc;
            }

            publicChannels.push(
              <MenuItem
                handleActiveChannel={handleActiveChannel}
                item={{ [type]: item }}
                channelId={channelId}
                newPosts={newPosts}
                key={item.channelId}
                type={type}
              />
            );
            return acc;
          },
          {
            privateChannels: [],
            publicChannels: []
          }
        );
        const { privateChannels, publicChannels } = sortedChannels;
        return [
          ...(privateChannels.length > 1 ? [privateChannels] : []),
          ...(publicChannels.length > 1 ? [publicChannels] : [])
        ];
      }

      return channelsGroup[type].map(item => (
        <MenuItem
          handleActiveChannel={handleActiveChannel}
          item={{ [type]: item }}
          channelId={channelId}
          newPosts={newPosts}
          key={item.roomId}
          type={type}
        />
      ));
    }, [channelId, newPosts, channelsGroup, setActiveChannel, type]);

    return (
      <Wrapper viewType={type}>
        <LibSidebar.Pushable as={Segment}>
          <LibSidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            vertical
            visible
            width="thin"
          >
            {type === ChatTypes.directMessages && (
              <div className="search">
                <p>All Chats</p>
                <Search />
              </div>
            )}
            {listItems}
          </LibSidebar>
        </LibSidebar.Pushable>
      </Wrapper>
    );
  }
);

export default PostComments;
export { PostComments };
