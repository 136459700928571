import React, { memo, useCallback, useMemo } from 'react';

import { Grid, Responsive, Segment } from 'semantic-ui-react';
import { PostComments, NoChat, Loader } from '../../components';
import { ChannelsWrapper } from './Channels.styles';
import { ActiveChat } from '../ActiveChat';
import { commentsActions, useChanelState } from '../../state/channelsState';
import { postActions } from '../../state/channelsState/actions';
import { useGetListChannelUsers } from '../../graphql/hooks';
import { ChatTypes } from '../../enums';

type ChannelsProps = {
  type: ChatTypes.operations;
};

const Channels = memo<ChannelsProps>(({ type }) => {
  const {
    state: {
      posts: {
        postList: { nextToken, items },
        newPosts,
        channelData: { channelId, listUserChannels, channelName },
        loading,
        topicsConnected,
        deletedPostID,
        editedPost
      },
      updatePostLoading,
      channelsLoading,
      loadingPost,
      createMessages,
      savingPost,
      updatePost,
      deletePost,
      fetchMore,
      toggleLikes
    },
    dispatch
  } = useChanelState();

  const loadMore = useCallback(() => fetchMore(nextToken), 
  [fetchMore, nextToken]);
  const deleteMessage = useMemo(
    () => ({
      [type]: deletePost
    }),
    [deletePost, type]
  );

  const updateMessages = useMemo(
    () => ({
      [type]: updatePost
    }),
    [updatePost, type]
  );

  const {
    data: listChannelUsers
    // TODO handle error cases
    // error: listChannelUsersError,
    // refetch
  } = useGetListChannelUsers(channelId);

  const setActiveChannel = (chnName: string, selectChannelId: string) => {
    postActions.setChannel(dispatch, {
      channelName: chnName,
      channelId: selectChannelId
    });
    postActions.setReadMessages(dispatch, {
      prevChannelId: channelId
    });
    postActions.setEditedMessage(dispatch, {
      editedPostID: null
    });
    commentsActions.hide(dispatch);
  };

  if (channelsLoading) {
    return <Loader />;
  }

  if (!listUserChannels?.length) {
    return <NoChat />;
  }

  return (
    <ChannelsWrapper viewType={ChatTypes.operations}>
      <Grid columns={2} divided="vertically" className="home-screen">
        <Grid.Column width={3} className="channels-posts">
          <Responsive as={Segment} className="no-border">
            <PostComments
              type={type}
              channelsGroup={{
                [type]: listUserChannels
              }}
              channelId={channelId}
              setActiveChannel={setActiveChannel}
              newPosts={newPosts}
            />
          </Responsive>
        </Grid.Column>
        <Grid.Column width={13} className="channels-posts">
          <Responsive as={Segment} className="no-border chat-container">
            {loadingPost || loading ? (
              <Loader />
            ) : (
              channelId && (
                <ActiveChat
                  listChannelUsers={listChannelUsers}
                  type={type}
                  channelId={channelId}
                  channelName={channelName}
                  topicsConnected={topicsConnected}
                  deletedMessageID={deletedPostID}
                  updateLoading={updatePostLoading}
                  hasMore={nextToken}
                  messagesList={items}
                  editedMessage={editedPost}
                  editedMessageId={editedPost?.postId}
                  createMsg={{
                    [type]: createMessages
                  }}
                  savingMessage={savingPost}
                  deleteMessage={deleteMessage}
                  updateMessages={updateMessages}
                  loadMore={loadMore}
                  toggleLikes={toggleLikes}
                  newPost={newPosts?.[channelId]?.length || null}
                />
              )
            )}
          </Responsive>
        </Grid.Column>
      </Grid>
    </ChannelsWrapper>
  );
});

export default Channels;
export { Channels };
