import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Permissions from 'react-redux-permissions';
import { fetchLog } from '../redux/actions';
import styles from './operationLogDetails.module.scss';
import toDoStyles from '../../../src/modules/toDo/List/toDoList.module.scss';
import { LOGS_HOME_ROUTE } from '../../../constants/RouterConstants';
import FormattedMessageWithClass from '../../../components/formattedMessageWithClass';
import editIcon from '../../../icons/inspection-icons/edit.svg';
import { renderInfoForType } from '../../../utils/helpers';


const OperationLogDetails = ({ logID, onClose, translation, history, actionFetch, ...redux }) => {
  // Fetch log on first mount
  useEffect(() => {
    actionFetch(logID);
  }, [logID, actionFetch]);
  // const goToEdit = () => history.push(`${LOGS_HOME_ROUTE}${logID}`);

  const { log } = redux;

  return log ? (
    <div className={toDoStyles.taskDetail}>
      <div className={toDoStyles.title}>
        <h5><FormattedMessage id="operations.builder.log_details" defaultMessage="Log Details" /></h5>
        <div style={{ display: 'flex' }}>
          {log.type === 'Inspections'? '' : (
            <Permissions allowed={['add_log']}>
              <div className={toDoStyles.headerActionContainer}>
                <img src={editIcon} style={{ marginLeft: '150px', cursor: 'pointer' }} onClick={() => history.push(`${LOGS_HOME_ROUTE}${logID}`)} alt="" />
                <div className={toDoStyles.actionsSeparator} />
              </div>
            </Permissions>)}
          <button type="button" className={toDoStyles.closeBtn} onClick={onClose}> &times; </button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.item}>
          <FormattedMessageWithClass className={styles.label}
            id="operations.builder.logged_by" defaultMessage="Logged By"
          />
          {log.logged_by && log.logged_by.fullname}
        </div>
        <div className={styles.item}>
          <FormattedMessageWithClass className={styles.label}
            id="operations.builder.shifts" defaultMessage="Shift"
          />
          {log.shift_name}
        </div>
        <div className={styles.item}>
          <FormattedMessageWithClass className={styles.label}
            id="operations.builder.date" defaultMessage="Report Date"
          />
          <span>{moment(log.report_date).format('MM/DD/YYYY hh:mm A')}</span>
        </div>
        <div className={styles.item}>
          <FormattedMessageWithClass className={styles.label}
            id="operations.builder.types" defaultMessage="Category"
          />
          <span>{log.type}</span>
        </div>

        <div className={styles.item}>
          <FormattedMessageWithClass className={styles.label}
            id="operations.builder.subcategory" defaultMessage="Sub-Category"
          />
          <span>{log.subtype}</span>
        </div>
        <div className={styles.fullItem}>
          <span className={styles.label}>
            <FormattedMessageWithClass id="operations.builder.description" defaultMessage="description" />
          </span>
          <span>{log.description}</span>
        </div>
        {log.tags.length>0 &&
        <>
          <div className={styles.fullItem}>
            <span className={styles.label}>
              <FormattedMessageWithClass id="operations.create.tags" defaultMessage="Tags" />
            </span>
            {log.tags.map(f => (
              <div key={f.id} className={styles.slot}>
                <span style={{ backgroundColor: '#3A61A8', color: 'white', border: 'none', borderRadius: '20px', padding: '2px 15px' }}>
                  {f}
                </span>
              </div>
            ))}
          </div>
        </>
        }
        {log.attachments.length>0 
        && <div className={styles.fullItem}>
              <FormattedMessageWithClass className={styles.label} id="workorder.create.photos" defaultMessage="Photos" />
              </div> 
            }
        {log.attachments.map(image => (
          <img src={image.image} style={{ width: '100px', opacity: '1', marginTop: '10px' }} alt="" />
        ))}
      </div>
      {log.form.schema.fields.length > 0 && log.response
        && <h5 className={styles.subtitle}><FormattedMessageWithClass id="operations.builder.otherfields" defaultMessage="Other fields" /></h5>
      }
      <div className={styles.content}>
        {log.response && log.form.schema.fields.map(f => (
          <div key={f.id} className={styles.item}>
            <span className={styles.label}>{translation && translation[f.title] ? translation[f.title]: f.title}</span>
            <span>{log.response[f.id] && renderInfoForType(f, log.response[f.id], log, true)}</span>
          </div>
        ))}
      </div>
    </div>
  )
    : null;
};

OperationLogDetails.propTypes = {
  // eslint-disable-next-line react/require-default-props
  log: PropTypes.shape({})
};

const mapStateToProps = state => ({
  log: state.opslogs.log
});

const mapDispatchToProps = dispatch => ({
  // Fetch Log list
  actionFetch: (id) => {
    dispatch(fetchLog(id));
  }
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(OperationLogDetails));
