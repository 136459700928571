/* eslint-disable radix */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { Redirect } from 'react-router-dom';
import { HOC as Permissions } from 'react-redux-permissions';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { WORKORDERS_HOME_ROUTE } from '../../../constants/RouterConstants';
import { fetchWorkOrder, fetchWorkOrderSchema, clearActionResult, fetchNotams, editWorkOrder } from '../redux/actions';
import { searchUser, fetchSafetySelfInspection, fetchDataSourceList } from '../../inspections/redux/actions';
import { fetchAssets, fetchAssetsNames, fetchAssetTypes, clearAssetTypes } from '../../assets-management/redux/actions';
import { getShiftTypeByDate } from '../../../utils/helpers';
import { fetchShiftTypes } from '../../settings/redux/actions';

import HeaderBack from '../../../components/headerBack';
import SectionHeader from '../../../components/sectionHeader';
import Button from '../../../components/button';
import Panel from '../../../components/panel';
import EditWorkOrder from './components/EditFixedFields';
import PulpoField from '../../../pulpo_visualizer/fields/PulpoField';
import Forbidden from '../../Forbidden';
import NotamsTable from './components/NotamsTable';
import Loading from '../../../components/loading';

import styles from './workOrderCreate.module.scss';
import wo from '../../../icons/WorkOrders.png';
import { GetFileName, S3Uploads } from '../../services';
import Modal from '../../../components/modal';
class WorkOrderEdit extends Component {
  firstTime = true;

  constructor(props) {
    super(props);
    this.state = {
      answers: {},
      requiredMap: {},
      assetSelected: false,
      showFormErrors: false,
      shifts: undefined,
      fieldErrors: {
        logged_by: false,
        report_date: false,
        category: false,
        subcategory: false,
        priority: false,
        problem_description: false,
        location: true
      },
      notamStatus: false,
      notams: {},
      zoomLevel: '',
      locationModal: false,
      spaceError: false,
      editWorkOrderDetails: {},
      selectedAsset: [],
      loadingStatus: false,
      defaultAsset: []
    };
  }

  componentDidMount() {
    const {
      actionGetSelfInspection,
      actionGetWorkOrder,
      actionFetchAssets,
      notams,
      schemas,
      assets,
      user,
      actionFetchNotams,
      actionFetchShiftTypes,
      actionfetchAssetTypes,
      shiftTypes,
      actionDetail,
      workorder,
      match,
      fetchDataSources
    } = this.props;
    actionDetail(match.params.id);
    actionGetSelfInspection();
    fetchDataSources();
    if (!schemas.length) actionGetWorkOrder();
    if (!assets.length) actionFetchAssets();
    if (notams.length) actionFetchNotams(user.airport.code);
    actionFetchShiftTypes();
    actionfetchAssetTypes();   
    this.getshifts(workorder.report_date, shiftTypes);
  }


  componentDidUpdate(prevProps) {
    const { schemas, workorder, editAction } = this.props;
    const { answers } = this.state;
    if (this.firstTime && workorder.id && schemas.workorder && schemas.workorder.id && !Object.keys(answers).length) {
      this.processInspectionForState(schemas.workorder);
      this.setState({

        answers: { ...workorder.response }
      });
      this.firstTime = false;
    }
    if (prevProps.editAction.loading === true && editAction.loading === false) {
      this.setState({ loadingStatus: false });
    }
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.workorder.id && !Object.keys(state.editWorkOrderDetails).length) {
      const priorityCategory = [{ id: 0, value: 'Low' }, { id: 1, value: 'Medium' }, { id: 2, value: 'High' }];
      const priorityVal = _.filter(priorityCategory, ['value', props.workorder.priority]);
      const editLocation = Object.values(props.workorder.location[0]);
      const notamStatus = props.workorder.notams.length > 0;
      const addedNotams = [];
      if (notamStatus === true) {
        props.actionFetchNotams(props.workorder.airportCode);
        props.workorder.notams.forEach((notam) => {
          addedNotams.push(notam.notam_id);
        });
      }

      const attachments = [];
      const selectedAsset = [];
      let selectedAssetName = '';
      selectedAsset.push(props.workorder.assets.length > 0 ? props.workorder.assets[0] : []);
      selectedAssetName = props.workorder.assets.length > 0 ? props.workorder.assets[0].asset_type.id : '';
      props.workorder.attachments.forEach((item) => {
        attachments.push(
          _.mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === 'image') {
              newKey = 'preview';
            }
            return newKey;
          })
        );
      });
      let assetSelected = false;
      if (selectedAsset.length>0) {
        assetSelected = true;
      } else {
        assetSelected= false;
      }
      return {
        ...state,
        editWorkOrderDetails: ({
          ...props.workorder,
          category: props.workorder.category_id,
          subcategory: props.workorder.subcategory_id,
          priority: priorityVal[0].id,
          location: editLocation,
          removedAttachments: [],
          selectedNotams: addedNotams,
          assetName: selectedAssetName,
          attachments,
          assets: selectedAsset
        }),
        notamStatus,
        notams: props.workorder.notams,
        defaultAsset: props.workorder.assets,
        assetSelected

      };
    }
    return state;
  }


  getshifts=(report_date, shiftTypes) => {
    const shift= getShiftTypeByDate(report_date, shiftTypes);
    if (shift!== undefined) {
      this.setState({ shifts: shift.name });
    }
  }

  handleAnswerChanged = async (section, answer, fieldId) => {
    const { shiftTypes, actionfetchAssetsNames, user }= this.props;
    const { editWorkOrderDetails: info, fieldErrors } = this.state;
    const assetTypes = user.airport.types_for_self_inspection;
    this.getshifts(info.report_date, shiftTypes);
    this.setState({ spaceError: false });
    if (fieldId==='category'&&info.subcategory) {
      this.setState({ defaultAsset: [] });
      if (assetTypes[answer]&&assetTypes[answer][info.subcategory]) {
        await actionfetchAssetsNames(undefined, assetTypes[answer][info.subcategory]);
        this.setState({
          fieldErrors: {
            ...fieldErrors,
            [fieldId]: false,
            assetName: false
          },
          editWorkOrderDetails: {
            ...info,
            [fieldId]: answer,
            assetName: assetTypes[answer][info.subcategory]
          }
        });
      } else {
        this.setState({ spaceError: false });
        if (answer || section==='answers') {
          await this.setState(prevState => ({
            [section]: {
              ...prevState[section],
              [fieldId]: answer
            }
          }));
        }
      }
    } else {
      this.setState({ spaceError: false });
      if (answer || section==='answers') {
        await this.setState(prevState => ({
          [section]: {
            ...prevState[section],
            [fieldId]: answer
          }
        }));
      }
      if (assetTypes[info.category] && assetTypes[info.category][answer] &&(!info.assetName||info.assetName!==assetTypes[info.category][answer])) {
        this.setState({ defaultAsset: [] });
        if (assetTypes[info.category][answer]) {
          await actionfetchAssetsNames(undefined, assetTypes[info.category][answer]);
          this.setState({
            fieldErrors: {
              ...fieldErrors,
              [fieldId]: false,
              assetName: false
            },
            editWorkOrderDetails: {
              ...info,
              [fieldId]: answer,
              assetName: assetTypes[info.category][answer]
            }
          });
        } else {
          await this.setState({
            fieldErrors: {
              ...fieldErrors,
              [fieldId]: false,
              assetName: true
            },
            editWorkOrderDetails: {
              ...info,
              [fieldId]: answer,
              assetName: undefined
            }
          });
        }
      } else if (assetTypes[info.category] && assetTypes[info.category][answer]) {
        await this.setState({
          fieldErrors: {
            ...fieldErrors,
            assetName: false
          }
        });
      }
      if (fieldId === 'assetName') {
        if (answer >=0) {
          this.setState({ defaultAsset: [] });
          await actionfetchAssetsNames(answer);
        } else {
          answer.replace(' ', '_');
          this.setState({ defaultAsset: [] });
          await actionfetchAssetsNames('', answer);
        }
        if (answer!==''||answer!==undefined) {
          this.setState({
            fieldErrors: {
              ...fieldErrors,
              [fieldId]: false
            },
            editWorkOrderDetails: {
              ...info,
              [fieldId]: answer
            }
          });
        }
      }
      if (fieldId === 'location' && answer) {
        this.setState({
          fieldErrors: {
            ...fieldErrors,
            [fieldId]: false
          }
        });
      }
    }
  }

  processInspectionForState = (workorder) => {
    if (workorder) {
      const { schema } = workorder;
      let { requiredMap } = this.state;

      schema.fields.forEach((f) => {
        requiredMap = { ...requiredMap, [f.id]: f.required };
      });
      this.setState({ requiredMap });
    }
  }

  handleSelectedAsset = (answer) => {
    const answerArr = answer === '' || answer === undefined ? [] : [answer];
    this.setState(prevState => ({
      editWorkOrderDetails: {
        ...prevState.editWorkOrderDetails,
        assets: answerArr
      },
      assetSelected: true
    }));
  }

  handleRemoveImage = (section, answer, fieldId, id) => {
    const { editWorkOrderDetails } = this.state;
    const removeImage = editWorkOrderDetails.removedAttachments;
    removeImage.push(id);
    this.setState(prevState => ({
      editWorkOrderDetails: {
        ...prevState.editWorkOrderDetails,
        [fieldId]: removeImage,
        attachments: answer
      }
    }));
  }

  handleFieldErrorChanged = (id, value) => {
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [id]: value
      }
    }));
  }

  handleSearchForUser = (value) => {
    const { actionSearchUser } = this.props;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      actionSearchUser(value);
    }, 300);
  }

  componentWillUnmount() {
    const { clear, clearAssetData } = this.props;
    clear();
    clearAssetData();
  }


  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  create = async () => {
    const { actionCreate, selfInspection, user } = this.props;
    // const { id }= match.params;
    const { editWorkOrderDetails, fieldErrors, answers, zoomLevel, shifts, assetSelected } = this.state;
    let { notams } = this.state;
    const assetTypes = user.airport.types_for_self_inspection;
    const problemDescription = editWorkOrderDetails.problem_description;
    this.setState({ loadingStatus: true });
    if (editWorkOrderDetails.location) {
      fieldErrors.location = false;
    }

    if (editWorkOrderDetails.priority) {
      fieldErrors.priority = false;
    }

    if (problemDescription && !problemDescription.trim()) {
      fieldErrors.problem_description = false;
      this.setState({ showFormErrors: true, loadingStatus: false });
      return;
    }

    const noErrors = Object.keys(fieldErrors).every(k => (fieldErrors[k] === false));
    if (noErrors) {
      if (assetTypes[editWorkOrderDetails.category] && assetTypes[editWorkOrderDetails.category][editWorkOrderDetails.subcategory] && !assetSelected) {
        fieldErrors.location = false;
        this.setState({
          locationModal: true,
          loadingStatus: false
        });
        return;
      }
      const data = { ...editWorkOrderDetails };
      data.logged_by = data.logged_by.id;
      data.shift_name= shifts;
      data.zoom_level = zoomLevel;
      const location = {
        type: 'Point',
        coordinates: data.location
      };
      data.location = JSON.stringify(location);
      if (!data.assets && data.assets.length === 0) {
        delete data.assets;
      }
      data.response = JSON.stringify(answers);
      data.Polygon= JSON.stringify(data.Polygon);
      delete data.shapes;

      data.category_id = data.category;
      data.subcategory_id = data.subcategory;
      data.subcategory = selfInspection[data.category].checklist.find(
        e => e.key === data.subcategory
      ).value;
      data.category = selfInspection[data.category].title;
      if (!notams) {
        notams = {};
      }
      data.notams = JSON.stringify(notams);
      // transform data into formData
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k === 'assets') {
          const assets = [];
          editWorkOrderDetails.assets.map(asset => assets.push(parseInt(asset)));
          formData.append(k, JSON.stringify(assets));
        } else if (k === 'removedAttachments') {
          const removedAttachments = [];
          editWorkOrderDetails.removedAttachments.map(image => removedAttachments.push(parseInt(image)));
          if (removedAttachments.length > 0) {
            formData.append('removedAttachments', JSON.stringify(removedAttachments));
          }
        } else if (k !== 'images' && k !== 'attachments') {
          formData.append(k, data[k]);
        }
      });
      const newAttachments = [];
      if (editWorkOrderDetails.attachments.length > 0) {
        editWorkOrderDetails.attachments.forEach((e) => {
          if (!e.id) {
            newAttachments.push(e);
          }
        });
      }
      if (newAttachments.length > 0) {
        let fileIds = '';
        if (newAttachments.length > 0) {
          fileIds = await this.getFileNames(newAttachments.length);
        }
        if (fileIds) {
          await this.doS3Upload(newAttachments, fileIds, 'work_order')
            .then(() => {
              fileIds.filename.forEach((fileId) => {
                formData.append('attachments', fileId.toString());
              });
            });
        }
      }
      actionCreate(editWorkOrderDetails.id, formData);
    } else {
      this.setState({ showFormErrors: true, loadingStatus: false });
    }
  }

      // save selected notams
      notamSelect = async (selectedNotam, isSelect, selectType) => {
        const { notams } = this.state;
        if (selectType === 's') {
          const notamId = selectedNotam.id;
          if (!isSelect) {
            const filterNotams = notams.filter(obj => obj.notam_id !== notamId);
            await this.setState({ notams: filterNotams });
          } else {
            const filteredNotam = this.props.notams.filter(notam => notam.notam_id === notamId);
            notams.push(filteredNotam[0]);
            await this.setState({ notams });
          }
        } else if (!isSelect) {
          selectedNotam.map((notam => delete notams[notam.id]));
          await this.setState({
            notams
          });
        } else {
          selectedNotam.map((notam) => {
            notams[notam.id] = notam;
            return notams;
          });
          await this.setState({ notams });
        }
      }

  openNotams = () => {
    const { actionFetchNotams } = this.props;
    const { editWorkOrderDetails, notamStatus } = this.state;
    actionFetchNotams(editWorkOrderDetails.airportCode);
    this.setState({ notamStatus: !notamStatus });
  }

  handleZoomLevel = (value) => {
    this.setState({ zoomLevel: value });
  }

  render() {
    const {
      showFormErrors,
      answers,
      requiredMap,
      shifts,
      editWorkOrderDetails,
      loadingStatus,
      notamStatus,
      locationModal,
      defaultAsset,
      fieldErrors } = this.state;
    const {
      userlist,
      selfInspection,
      editAction,
      schemas,
      errors,
      fromInspection,
      assets,
      translations,
      user,
      notams,
      assetsTypesname,
      assetsTypesdata,
      assetsTypedataloading,
      action,
      allDataSources } = this.props;

    // Remove panel if is rendered from inspections.
    const Tag = fromInspection
      ? { name: 'div', props: '' }
      : {
        name: Panel,
        props: {
          title: 'workorders.edit.title',
          defaultTitle: 'Edit Work Order'
        }
      };
    // Notams table start
    const header = [
      { text: 'ID', dataField: 'id', sortable: true, filterable: true },
      { text: 'Text', dataField: 'notam_text', sortable: true, filterable: true },
      { text: 'Expiration Date', dataField: 'notam_expire_dtg', sortable: true, filterable: true },
      { text: 'Last Modified Date', dataField: 'notam_lastmod_dtg', sortable: true, filterable: true },
      { text: 'Effective Date', dataField: 'notam_effective_dtg', sortable: true, filterable: true }
    ];

    const notamData = notams.map(notam => ({
      id: `${notam.notam_id}`,
      notam_text: `${notam.notam_text}`,
      notam_expire_dtg: `${moment(notam.notam_expire_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`,
      notam_lastmod_dtg: `${moment(notam.notam_lastmod_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`,
      notam_effective_dtg: `${moment(notam.notam_effective_dtg, 'YYYYMMDDHHmm').format('YYYY-MM-DD hh:mm A')}`
    }));

    const assetTypes = user.airport.types_for_self_inspection;
    const translationMap = translations ? translations[user.language] : {};
    return (
      <>
        {editAction.success && <Redirect push to={WORKORDERS_HOME_ROUTE} />}
        <SectionHeader icon={wo} translationID="workorders.edit.title"
          defaultTitle="Edit Work Orders"
        />
        <HeaderBack
          translationID="workorders.start.back"
          translationDefault="Back to Work Orders"
          backRoute={WORKORDERS_HOME_ROUTE}
        />
        <Loading loadingStatus={loadingStatus} />
        <div className={`container ${styles.form}`}>
          <Tag.name {...Tag.props}>
            <div className={`${styles.content} ${styles.embedded}`}>
              {locationModal ? (
                <Modal
                  width="auto"
                  minHeight="auto"
                  showIn={locationModal}
                  contentStyles={{
                    padding: '25px 40px 5px 30px'
                  }}
                  centered
                  onClose={() => { this.setState({ locationModal: false }); }}
                >
                  <h3>
                    <FormattedMessage
                      id="workorders.start.location_modal"
                      defaultText="Select the Asset in the Map field"
                    />
                  </h3>
                  <div className={styles.foter}>
                    <Button onClick={() => { this.setState({ locationModal: false }); }}
                      translationID="inspections.list.ok"
                      defaultText="Ok" action="primary"
                    />
                  </div>
                </Modal>
              ):''}
              { Object.keys(editWorkOrderDetails).length > 0
                && Object.keys(selfInspection).length > 0 ? (
                  <EditWorkOrder
                    editWorkOrder={editWorkOrderDetails}
                    assetTypes={assetTypes}
                    assetsTypesname={assetsTypesname}
                    assetsTypesdata={assetsTypesdata}
                    assetsTypedataloading={assetsTypedataloading}
                    defaultAsset={defaultAsset}
                    translation={translationMap}
                    assets={assets}
                    userlist={userlist}
                    shifts={shifts}
                    categories={selfInspection}
                    searchForUser={this.handleSearchForUser}
                    handleAnswerChanged={this.handleAnswerChanged}
                    handleFieldErrorChanged={this.handleFieldErrorChanged}
                    handleWorkOrderZoomLevel={value => this.handleZoomLevel(value)}
                    showFormErrors={showFormErrors}
                    handleSelectedAsset={this.handleSelectedAsset}
                    handleRemoveImage={this.handleRemoveImage}
                  />
                ) : 'Loading...'}
              { Object.keys(editWorkOrderDetails).length > 0 && (
                <div className="fixedFields_fullInput__3zuDC" style={{ marginTop: '15px' }}>
                  <span className={styles.notamsText}>
                    <FormattedMessage
                      id="workorders.start.associate notams"
                      defaultMessage="Associate Notams with this work order ?"
                    />
                    <input
                      className={styles.notamsCheckbox}
                      type="checkbox"
                      onChange={this.openNotams}
                      checked={notamStatus}
                      value={notamStatus}
                    />
                  </span>
                    { notamStatus || Object.keys(editWorkOrderDetails.notams).length > 0 ? (
                      <div className="fixedFields_fullInput__3zuDC">
                        {schemas.notamsEnabled ? (
                          <div>
                            {action.message === 'success' ? (
                              <div>
                                <NotamsTable
                                  editWorkOrder
                                  selectedNotams={editWorkOrderDetails.selectedNotams.length
                                    ? editWorkOrderDetails.selectedNotams: []}
                                  notamData={notamData}
                                  tableHeader={header}
                                  notamSelect={
                                    (notam, isSelect, selectType) => this.notamSelect(notam, isSelect, selectType)
                                  }
                                />
                              </div>
                            ) : (
                              <div>
                                <p style={{ textAlign: 'center', color: 'red', fontWeight: 'bold', width: '100%' }}>
                                  ... Loading... Please Hold ....
                                </p>
                              </div>
                            )
                            }
                          </div>
                        ) : (
                          <div>
                            <p style={{ textAlign: 'center', color: '#cf1020', fontWeight: 'bold', width: '100%' }}>
                              *** Access Denied!!!! please contact admin for Access ***
                            </p>
                          </div>
                        )
                      }
                      </div>
                    ): ''
                  }
                </div>
              )}
              <div className={styles.separator} />
              {Object.keys(requiredMap).length > 0 && allDataSources
                && schemas.workorder.schema.fields.map(field => (
                  <PulpoField key={field.id} {...field}
                    translation={translationMap && translationMap[field.title]}
                    handleValueChange={(a, b) => this.handleAnswerChanged('answers', a, b)}
                    isRequired={requiredMap[field.id]} answer={answers[field.id]}
                    showFieldErrors={showFormErrors}
                    handleFieldErrorChanged={this.handleFieldErrorChanged}
                    allDataSources={allDataSources}
                  />
                ))}
            </div>
            <div className={`${styles.footer} ${styles.embedded}`}>
              <div className={styles.errors}>
                {fieldErrors && fieldErrors.length > 0 && (
                  errors.map(e => (
                    <>
                      <span>{`${e.id}: `}</span>
                      <FormattedMessage key={e.id} id={e.message}
                        defaultMessage="this field is required"
                      />
                    </>
                  ))
                )}
              </div>
              <Button onClick={this.create} translationID="workorders.edit.update"
                defaultText="Create" action="secondary"
              />
            </div>
          </Tag.name>
        </div>
      </>
    );
  }
}

WorkOrderEdit.propTypes = {
  selfInspection: PropTypes.shape({}),
  actionCreate: PropTypes.func.isRequired,
  actionSearchUser: PropTypes.func.isRequired,
  userlist: PropTypes.arrayOf(PropTypes.shape({})),
  notams: PropTypes.arrayOf(PropTypes.shape({})),
  actionGetSelfInspection: PropTypes.func.isRequired,
  actionGetWorkOrder: PropTypes.func.isRequired,
  schemas: PropTypes.shape({}).isRequired,
  editAction: PropTypes.shape({}),
  clear: PropTypes.func.isRequired,
  actionFetchNotams: PropTypes.func.isRequired
};

WorkOrderEdit.defaultProps = {
  userlist: [],
  selfInspection: {},
  editAction: {},
  notams: []
};

const mapStateToProps = state => ({
  workorder: state.workorders.detail,
  workorders: state.workorders.workorders,
  selfInspection: state.inspection.selfInspection,
  schemas: state.workorders.schemas,
  action: state.workorders.action,
  editAction: state.workorders.editAction,
  createdWorkorder: state.workorders.createdWorkorder,
  assetsTypesname: state.assets.types,
  assetsTypesdata: state.assets.typesNames,
  assetsTypedataloading: state.assets.actionLoadTypeNames,
  errors: state.workorders.errors,
  user: state.auth.profile,
  userlist: state.inspection.userlist,
  assets: state.assets.assets,
  translations: state.auth.translations,
  notams: state.workorders.notams,
  shiftTypes: state.settings.shiftTypes,
  allDataSources: state.inspection.allDataSources
});

const mapDispatchToProps = dispatch => ({
  // Edit Work Order
  actionCreate: (id, data) => {
    dispatch(editWorkOrder(id, data));
    return Promise.resolve();
  },

  // Fetch work order detail
  actionDetail: (id) => {
    dispatch(fetchWorkOrder(id));
  },


  actionGetWorkOrder: () => {
    dispatch(fetchWorkOrderSchema());
  },
  actionGetSelfInspection: () => {
    dispatch(fetchSafetySelfInspection());
  },
  actionFetchAssets: () => {
    dispatch(fetchAssets());
  },
  actionSearchUser: (query) => {
    dispatch(searchUser(query));
  },
  actionFetchNotams: (query) => {
    dispatch(fetchNotams(query));
  },
  actionFetchShiftTypes: () => {
    dispatch(fetchShiftTypes());
  },
  clear: () => {
    dispatch(clearActionResult());
  },
  actionfetchAssetTypes: () => {
    dispatch(fetchAssetTypes());
  },
  actionfetchAssetsNames: (Names, categories) => {
    dispatch(fetchAssetsNames(Names, categories));
  },
  clearAssetData: () => {
    dispatch(clearAssetTypes())
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});


export default Permissions(['add_workorder'])(
  connect(mapStateToProps, mapDispatchToProps)(WorkOrderEdit),
  <Forbidden />
);
