import React from 'react';
import { FormattedMessage } from 'react-intl';
import AircraftForm from './components/AircraftForm';
import Clickable from '../../../../../../components/clickable/Clickable';
import styles from '../steps.module.scss';

const Step4 = ({
  handleMultipleAnswers,
  allTypes,
  multipleAircrafts,
  handleAnswerList
}) => {
  const handleAnswer = (section, answer, fieldID, index, list) => {
    const change = list.filter((e, i) => i === index);
    change[0][fieldID.valueOf()] = answer;
    const newList = [];
    list.forEach((e, i) => {
      if (i === index) {
        newList.push(change[0]);
      } else {
        newList.push(e);
      }
    });
    handleAnswerList(section, newList);
  };

  return (
    <div className={styles.stepTwo}>
      <div className={styles.lease}>
        <FormattedMessage id="lease.tenants.steps.step4.based_aircraft" defaultMessage="Based Aircraft" />
      </div>
      {multipleAircrafts && multipleAircrafts.length > 0 && multipleAircrafts.map((e, index) => (
      <>
        <AircraftForm
          handleAnswerChanged={(ans, field) => handleAnswer('multipleAircrafts', ans, field, index, multipleAircrafts)}
          info={e}
          allTypes={allTypes}
        />
    </>
      ))}
      <Clickable className={styles.addLink}
        onClick={() => handleMultipleAnswers('multipleAircrafts', multipleAircrafts)}
      >
        <FormattedMessage id="lease.tenants.steps.step3.add_aircraft" defaultMessage="Add aircraft  +" />
      </Clickable>
    </div>
  );
};

export default Step4;
