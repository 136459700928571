import gql from 'graphql-tag';

const GET_MESSAGES = gql`
  query getMessages(
    $roomId: String!,
    $limit: Int,
    $nextToken: String
  ) {
    getMessages(
      roomId: $roomId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        fromUserId
        message
        messageId
        readStatus
        fromRead
        toRead
        fromDelete
        toDelete
        toUserId
        updatedAt
        attachments {
          name
          url
          type
          size
        }
        # TODO rm unused data
        fromUser {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId
          airportDetails {
            name
            code
            logo
          }
          roles {
            name
            id
            permissions {
              name
            }
          }
          airportId
        }
        toUser {
          firstName
          lastName
          profilePicture
          isActive
          title
          userId
          airportDetails {
            name
            code
            logo
          }
          roles {
            name
            id
            permissions {
              name
            }
          }
          airportId
        }
      }
      nextToken
    }
  }
`;
export default GET_MESSAGES;
