import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { createStaticRanges } from 'react-date-range/src/defaultRanges';
import * as rdrLocales from 'react-date-range/dist/locale';
import moment from 'moment';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
  endOfYear,
  differenceInCalendarDays
} from 'date-fns';

import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../../components/button';
import Collapsible from '../../../../../components/collapsible/Collapsible';
import styles from './filter.module.scss';
import Clickable from '../../../../../components/clickable/Clickable';
import Separator from '../../../../../components/separator/Separator';

export const formatDate = date => date.toLocaleDateString(
  'en-US',
  {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }
);


const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLast30Days: addDays(new Date(), -29),
  endOfLast30Days: endOfDay(new Date())
};

class Filter extends Component {
  child = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      title: new Date().toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
      selectionRange: {
        startDate: new Date(props.dates[0]),
        endDate: new Date(props.dates[1]),
        key: 'selection'
      },
      init: false
    };
  }


  static getDerivedStateFromProps(props, state) {
    const { range, dates } = props;
    if (!state.init) {
      if (!range && (formatDate(new Date(dates[0])) === formatDate(new Date(moment())))) return { title: 'Today', init: true };
      if (range) {
        return { title: range, init: true };
      }
      if (dates[0]) {
        if (formatDate(new Date(dates[0])) === formatDate(new Date(dates[1]))) {
          return {
            title: `${formatDate(new Date(dates[0]))}`,
            init: true
          };
        }
        return {
          title: `${formatDate(new Date(dates[0]))} -  ${formatDate(new Date(dates[1]))}`,
          init: true
        };
      }
    }
    return state;
  }

  handleDateChange = (ranges) => {
    this.setState({
      selectionRange: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: 'selection'
      }
    });
  }

  equalDates = (d1, d2) => (
    d1.getFullYear() === d2.getFullYear()
    && d1.getDate() === d2.getDate()
    && d1.getMonth() === d2.getMonth()
  )

  applyFilters = () => {
    const { onDateChange } = this.props;
    const { selectionRange: { startDate, endDate } } = this.state;
    const range = document.getElementsByClassName('rdrStaticRange rdrStaticRangeSelected')[0];
    let rangeName;
    if (range) {
      rangeName = range.childNodes[0].textContent;
    } else {
      const sd = formatDate(startDate);
      const ed = formatDate(endDate);
      if (this.equalDates(startDate, endDate)) {
        rangeName = sd;
      } else {
        rangeName = `${sd} -  ${ed}`;
      }
    }
    this.setState({ title: rangeName });

    onDateChange(startDate, endDate, range && range.childNodes[0].textContent);
    this.child.current.onItemClick();
  }

  render() {
    const language = localStorage.getItem('lang') ||'en';
    const { selectionRange, title } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;

    const staticRanges = createStaticRanges([

      {
        label: formatMessage({ id: 'todo.today' }),
        range: () => ({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday
        })
      },
      {
        label: formatMessage({ id: 'todo.Yesterday' }),
        range: () => ({
          startDate: defineds.startOfYesterday,
          endDate: defineds.endOfYesterday
        })
      },
      {
        label: formatMessage({ id: 'todo.thisWeek' }),
        range: () => ({
          startDate: defineds.startOfWeek,
          endDate: defineds.endOfWeek
        })
      },
      {
        label: formatMessage({ id: 'todo.LastWeek' }),
        range: () => ({
          startDate: defineds.startOfLastWeek,
          endDate: defineds.endOfLastWeek
        })
      },
      {
        label: formatMessage({ id: 'todo.ThisMonth' }),
        range: () => ({
          startDate: defineds.startOfMonth,
          endDate: defineds.endOfMonth
        })
      },
      {
        label: formatMessage({ id: 'todo.LastMonth' }),
        range: () => ({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth
        })
      },
      {
        label: intl.formatMessage({ id: 'todo.ThisYear' }),
        range: () => ({
          startDate: defineds.startOfYear,
          endDate: defineds.endOfYear
        })
      },
      {
        label: formatMessage({ id: 'todo.lastThirtyDays' }),
        range: () => ({
          startDate: defineds.startOfLast30Days,
          endDate: defineds.endOfLast30Days
        })
      }
    ]);
    const inputRanges=[
      {
        label: formatMessage({ id: 'todo.days up to today' }),
        range(value) {
          return {
            startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) -1) * -1),
            endDate: defineds.endOfToda
          };
        },
        getCurrentValue(range) {
          if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
          if (!range.startDate) return '∞';
          return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
        }
      },
      {
        label: formatMessage({ id: 'todo.days starting today' }),
        range(value) {
          const today = new Date();
          return {
            startDate: today,
            endDate: addDays(today, Math.max(Number(value), 1) - 1)
          };
        },
        getCurrentValue(range) {
          if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
          if (!range.endDate) return '∞';
          return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
        }
      }
    ];
    const { handleDropdown, listSummary } = this.props;
    return (
      <div style={{ display: 'flex' }} className={styles.toolbar}>
        <div className={styles.counter}>
          <div className={styles.count}>
            <FormattedMessage id="lease.maintenance.list.all" defaultMessage="All Maintenance " />
            <span>
              {' '}
            (
              {listSummary}
            )
            </span>
          </div>
        </div>
        <div className={`${styles.filter} ${styles.onlyRight}`}>
          <div className={styles.right}>
            <Collapsible ref={this.child} styleClasses={styles.dateHeader} title={title} dateFilter>
              <div className={styles.pickerWrapper}>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleDateChange}
                  rangeColors={['rgba(58, 97, 168, 0.67)']}
                  color="rgba(58, 97, 168, 0.67)"
                  inputRanges={inputRanges}
                  locale={rdrLocales[language]}
                  staticRanges={staticRanges}
                />
                <Button onClick={this.applyFilters}
                  translationID="inspections.filter.apply"
                  defaultText="Apply"
                  action="secondary"
                />
              </div>
            </Collapsible>
            {handleDropdown && (
            <>
              <Separator />
              <Clickable className={styles.actionsBtn} onClick={() => handleDropdown()}>
                <FormattedMessage id="workorders.list.filters" defaultMessage="Filters " />
              </Clickable>
            </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

/**
 *  Proptypes and default props
 */
Filter.propTypes = {
  onDateChange: PropTypes.func.isRequired
};

Filter.defaultProps = {

};

export default injectIntl(Filter);
