import gql from 'graphql-tag';

const GET_USER = gql`
    query getUser(
        $userId: String!,
    ){
        getUser(userId: $userId) {
            firstName
            lastName
            profilePicture
            userId
            isActive
        }
    }
`;
export default GET_USER;
