/* eslint-disable radix */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import leafletPip from '@mapbox/leaflet-pip';

/** ****************************************************************************
 *  Leaflet import
 * ***************** */
import 'leaflet/dist/leaflet.css';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
/** ****************************************************************************
 *  Redux import
 * ************* */
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Toolbar from './components/Toolbar';
import FilterForm from './components/FilterForm';
import Loading from '../../../components/loading';
import {
  fetchAssetTypes,
  addAsset,
  clear,
  fetchAssets,
  editAsset,
  deleteAsset,
  fetchAssetsNames,
  fetchAssetsSchema
} from '../redux/actions';
import { fetchSurfaces } from '../../settings/Map/redux/actions';
import { fetchDataSourceList } from '../../inspections/redux/actions';

/** ****************************************************************************
 *  Components import
 * ****************** */
import TypesToolbar from './components/TypesToolbar';
import AssetForm from './components/AssetForm';

/** ****************************************************************************
 *  Asset import
* ************* */
import * as utils from '../../settings/Map/mapUtils';
import styles from './assetBuilder.module.scss';
import { GetFileName, S3Uploads } from '../../services';
import Modal from '../../../components/modal';
import Button from '../../../components/button';

class AssetBuilder extends Component {
  slideStyles = {
    entered: { transform: 'translate3d(0px,0px,0px)' }
  }

  constructor(props) {
    super(props);
    this.state = {
      form: false,
      edit: true,
      info: {},
      shouldShowErrors: false,
      requiredMap: {},
      removedAttachments: [],
      filterPopup: false,
      searchQuery: '',
      loadingStatus: props.assetsTypedataloading.loading,
      filterCategories: localStorage.getItem('assetsFilter')?JSON.parse(localStorage.getItem('assetsFilter')):['signs'],
      currentPage: 1,
      seletedAssetType: [],
      fieldErrors: {},
      spaceError: false,
      removeMarker: false,
      customFields: {}
    };
  }

  componentDidMount() {
    const {
      user,
      actionFetchTypes,
      actionFetchSurfaces,
      actionFetchSchemas,
      actionfetchAssetsNames,
      schemas,
      assets,
      fetchDataSources
    } = this.props;
    fetchDataSources();
    // initialize map and data structures
    if (!this.map && user.id) {
      actionFetchTypes();
      if (localStorage.getItem('assetsFilter')) {
        this.updateFilter();
      } else actionfetchAssetsNames('', 'signs');
      // actionFetch();
      actionFetchSurfaces();
      this.initializeMap();
    }
    if (!Object.keys(schemas).length) {
      actionFetchSchemas();
    }
    if (assets===undefined|| assets.length<=0) {
      if (localStorage.getItem('assetsFilter')) {
        this.updateFilter();
      } else {
        actionfetchAssetsNames('', 'signs');
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user,
      assets,
      actionFetchTypes,
      actionFetchSurfaces,
      actionfetchAssetsNames,
      createAction,
      actionClear,
      assetsTypedataloading,
      updateAction } = this.props;

    const { removeMarker } = this.state;

    if (prevProps.assets.length !== assets.length) {
      if (this.initializeMap) {
        this.map.remove();
        this.initializeMap(true);
      }
    }

    if (updateAction.success !== prevProps.updateAction.success) {
      // actionFetch();
      actionfetchAssetsNames('', 'signs');
    }
    if (assetsTypedataloading&& prevProps.assetsTypedataloading && assetsTypedataloading.loading ===true&& prevProps.assetsTypedataloading.loading ===false) {
      this.setState({ loadingStatus: true });
    }
    if (assetsTypedataloading.loading ===false&& prevProps.assetsTypedataloading.loading ===true&& assetsTypedataloading.success===true) {
      this.setState({ loadingStatus: false });
    }

    if (createAction.success !== prevProps.createAction.success) {
      this.setState({ form: false, edit: true, info: {} }, () => {
        actionClear();
        if (this.marker) {
          this.marker.disable();
        }
      });
    }
    if (prevProps.createAction.loading === true && createAction.loading === false) {
      this.setState({ loadingStatus: false }, () => {
        actionClear();
      });
    }
    if (removeMarker === true && prevState.removeMarker === false) {
      this.map.remove();
      this.initializeMap();
      this.setState({ edit: true });
    }

    // initialize map and data structures
    if (!this.map && user.id) {
      actionFetchTypes();
      if (localStorage.getItem('assetsFilter')) {
        this.updateFilter();
      } else actionfetchAssetsNames('', 'signs');
      // actionFetch();
      actionFetchSurfaces();
      this.initializeMap();
    }

    const that = this;
    let count = 1;
    if (this.map) {
      count = Object.keys(this.map._layers).length;
    }

    if (count === 1 || assets.length !== prevProps.assets.length) {
      assets.forEach((a) => {
        // load all assets in map
        let l = L.geoJSON(a.geometry)._layers;
        [l] = Object.keys(l).map(ob => l[ob]);
        const icon = new utils.IconImage({
          className: 'asset-icon',
          iconAnchor: [15, 10],
          icon: a.asset_type.icon,
          id: a.id
        });
        l.setIcon(icon).addTo(this.map);

        // On click Handler
        l.on('click', (e) => {
          const { surfaces } = this.props;
          const { edit } = this.state;
          if (edit) {
            that.layer = e.target;
            that.map.eachLayer((layer) => {
              if (layer.editing && layer.editing.enabled()) {
                layer.editing.disable();
              }
            });
            e.target.editing.enable();

            // get the asset associated
            const { assets: newAsset } = this.props;
            const { asset_type: atype, ...theAsset } = newAsset.find(
              b => b.id === e.target.options.icon.options.id
            );
            theAsset.type = atype;
            theAsset.area = surfaces.find(s => s.id === theAsset.area);

            const cat = theAsset.type.category.toLowerCase();
            const { fieldErrors } = this.state;
            const categoryErrors = fieldErrors[cat] ? fieldErrors[cat] : {};
            const errors = Object.keys(categoryErrors).map(() => false);
            this.setState({
              form: true,
              info: theAsset,
              customFields: theAsset.response,
              fieldErrors: {
                [cat]: errors
              }
            });
          }
        });
      });
    }
  }


  static getDerivedStateFromProps(props, state) {
    if (props.createAction.success || props.deleteAction.success) {
      props.actionClear();
      return {
        ...state,
        form: false,
        info: {
          ...state.info,
          id: '',
          label: '',
          name: '',
          geometry: ''
        },
        removedAttachments: []
      };
    }

    if (!Object.keys(state.requiredMap).length) {
      let requiredMap = {};
      let fieldErrors = {};
      Object.keys(props.schemas).forEach((s) => {
        props.schemas[s].schema.fields.forEach((f) => {
          requiredMap = {
            ...requiredMap,
            [s]: {
              ...requiredMap[s],
              [f.id]: f.required
            }
          };
          fieldErrors = {
            ...fieldErrors,
            [s]: {
              ...fieldErrors[s],
              [f.id]: f.required
            }
          };
        });
      });
      return { ...state, requiredMap, fieldErrors };
    }
    return state;
  }

  handleFiltersPopup = () => {
    const { filterPopup } = this.state;
    this.setState({ filterPopup: !filterPopup });
  }

  updateFilter = () => {
    const { filterCategories } = this.state;
    const { actionfetchAssetsNames } = this.props;
    localStorage.setItem('assetsFilter', JSON.stringify(filterCategories));
    let result;
    const catfilter=[];
    const namefilter=[];
    let result2;
    for (let i=0; i<= filterCategories.length; i+=1) {
      const a=parseInt(filterCategories[i]);
      if ((a > 0)) {
        namefilter.push(filterCategories[i]);
      } else if (filterCategories[i]) {
        catfilter.push(filterCategories[i]);
      }
    }
    localStorage.setItem('nfilter', JSON.stringify(namefilter));
    localStorage.setItem('cfilter', JSON.stringify(catfilter));
    if (JSON.parse(localStorage.getItem('nfilter')).length>0) {
      result= `${JSON.parse(localStorage.getItem('nfilter')).join(',,')}`;
    }
    if (JSON.parse(localStorage.getItem('nfilter')).length === 1) {
      result= `${JSON.parse(localStorage.getItem('nfilter'))[0]}`;
    }
    this.setState({ filterPopup: false });
    if (JSON.parse(localStorage.getItem('cfilter')).length>1) {
      result2= `${JSON.parse(localStorage.getItem('cfilter')).join(',,')}`;
    } else {
      result2=`${JSON.parse(localStorage.getItem('cfilter'))[0]}`;
    }
    if (result !== undefined && result2 !== undefined) {
      actionfetchAssetsNames(result, result2);
    } else {
      if (result!==undefined) {
        actionfetchAssetsNames(result);
      }
      if (result2!==undefined) {
        actionfetchAssetsNames('', result2);
      }
    }
  }

  handleFilterChange = (name, event) => {
    const { filterCategories } = this.state;
    const check = event.target.checked;
    const checkedValue = event.target.id;
    if (check) {
      this.setState({
        filterCategories: [...filterCategories, event.target.id]
      });
    } else {
      const index = filterCategories.indexOf(checkedValue);
      if (index > -1) {
        filterCategories.splice(index, 1);
        this.setState({ filterCategories });
      }
    }
  }


  initializeMap = () => {
    const { user } = this.props;
    const zoomLevel = localStorage.getItem('assetsZoom');
    const assetBounds = localStorage.getItem('assetsBounds');
    if (user.additional_configuration && user.additional_configuration.bounds && Object.keys(user.additional_configuration.bounds)) {
      const southWest = L.latLng(user.additional_configuration.bounds.southwest.lat, user.additional_configuration.bounds.southwest.lan);
      const northEast = L.latLng(user.additional_configuration.bounds.northeast.lat, user.additional_configuration.bounds.northeast.lan);
      const airportBounds = L.latLngBounds(southWest, northEast);
      this.map = L.map('map', {
        center: [...user.airport.location.coordinates].reverse(),
        zoom: zoomLevel !== null ? zoomLevel : 18
      }).setMaxBounds(airportBounds);
    } else {
      this.map = L.map('map', {
        center: [...user.airport.location.coordinates].reverse(),
        zoom: zoomLevel !== null ? zoomLevel : 18
      });
    }
    if (assetBounds !== null) {
      const [west, south, east, north] = localStorage.getItem('assetsBounds').split(',').map(parseFloat);
      const bounds = new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
      this.map.fitBounds(bounds);
    }
    L.gridLayer.googleMutant({ type: 'satellite', maxZoom: 20 }).addTo(this.map);

    this.map.on(L.Draw.Event.CREATED, (e) => {
      this.onMarkerCreated(e);
    });

    this.map.on('draw:editmove', (e) => {
      this.onMarkerEdited(e);
    });


    this.map.on('zoomend', () => {
      const zoom = this.map.getZoom();
      localStorage.setItem('assetsZoom', zoom);
      localStorage.setItem('assetsBounds', this.map.getBounds().toBBoxString());
    });
  }

  handleAssetClick = (value) => {
    this.handleTypeChange(value, false);
    utils.disableEditMarkers(this.map);
    this.setState(prevState => ({
      removeMarker: false,
      form: false,
      edit: false,
      info: {
        ...prevState.info,
        id: '',
        label: '',
        name: '',
        geometry: ''
      },
      customFields: {}
    }));

    if (this.marker) this.marker.disable();
    this.marker = new L.Draw.Marker(this.map, {
      icon: new utils.IconImage({ className: 'asset-icon', iconAnchor: [15, 10], icon: value.icon })
    });
    this.marker.enable();
  }

  handleEditClick = () => {
    this.setState({ edit: true });
    this.marker.disable();
  }

  handleTypeChange = (selected, changeIcon = true) => {
    const { types }= this.props;
    if (changeIcon) {
      const icon = new utils.IconImage({ className: 'asset-icon', iconAnchor: [15, 10], icon: selected.icon });
      this.layer.setIcon(icon);
    }
    const typeData=[];
    for (let i=0; i< types.length; i+=1) {
      if (selected.category===types[i].category) {
        typeData.push(types[i]);
      }
    }
    this.setState(prevState => ({
      seletedAssetType: [...typeData],
      info: {
        ...prevState.info,
        type: selected
      }
    }));
  }

  handleInputChange = (value, id, removeId) => {
    const { removedAttachments } = this.state;
    if (removeId) {
      removedAttachments.push(removeId);
    }
    this.setState(prevState => ({
      shouldShowErrors: false,
      info: {
        ...prevState.info,
        [id]: value
      },
      removedAttachments
    }));
  }

  handleAnswerChange = (value, id, fieldid) => {
    this.setState({ spaceError: false });
    if (fieldid==='response') {
      this.setState(prevState => ({
        shouldShowErrors: false,
        info: {
          ...prevState.info,
          [fieldid]: {
            ...prevState[fieldid],
            [id]: value
          }
        }
      }));
    }
    this.setState(prevState => ({
      shouldShowErrors: false,
      [fieldid]: {
        ...prevState[fieldid],
        [id]: value
      }
    }
    ));
  };

  handleFieldErrorChanged = (id, value) => {
    const { info } = this.state;
    const cat = info.type.category.toLowerCase();
    this.setState(prevState => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        [cat]: {
          ...prevState.fieldErrors[cat],
          [id]: value
        }
      }
    }));
  }

  onMarkerCreated = (e) => {
    // Add a new marker when one is placed.
    e.layer.addTo(this.map);
    const { info } = this.state;
    this.layer = e.layer;
    this.marker = new L.Draw.Marker(this.map, {
      icon: new utils.IconImage({ className: 'asset-icon', iconAnchor: [15, 10], icon: info.type.icon })
    });
    this.marker.disable();
    const { surfaces } = this.props;
    let area;

    surfaces.forEach((s) => {
      const surfaceLayer = L.geoJSON(s.geometry);
      const inside = leafletPip.pointInLayer(
        e.layer.getLatLng(), surfaceLayer
      ).length > 0;
      if (inside) area = s;
    });

    // Open form
    this.setState(prevState => ({
      form: true,
      info: {
        ...prevState.info,
        geometry: {
          type: 'Point',
          coordinates: [
            e.layer.getLatLng().lng,
            e.layer.getLatLng().lat
          ]
        },
        area
      }
    }));
  }

  onMarkerEdited = (e) => {
    const { surfaces } = this.props;
    let area;
    surfaces.forEach((s) => {
      const surfaceLayer = L.geoJSON(s.geometry);
      const inside = leafletPip.pointInLayer(
        e.layer.getLatLng(), surfaceLayer
      ).length > 0;
      if (inside) area = s;
    });


    this.setState(prevState => ({
      info: {
        ...prevState.info,
        geometry: {
          type: 'Point',
          coordinates: [
            e.layer.getLatLng().lng,
            e.layer.getLatLng().lat
          ]
        },
        area: area || null
      }
    }));
  }

  handleCancel = () => {
    this.setState(prevState => ({
      delPopup: false,
      form: false,
      info: {
        ...prevState.info,
        id: '',
        label: '',
        name: '',
        geometry: ''
      },
      removeMarker: true
    }));
    utils.disableEditMarkers(this.map);
  }

  showDelPopUp=() => {
    const { delPopup } = this.state;
    this.setState({
      delPopup: !delPopup
    });
  }

  handleDelete = () => {
    const { info, delPopup } = this.state;
    const { actionDelete } = this.props;

    actionDelete(info.id);
    this.map.eachLayer((layer) => {
      if (layer.editing && layer.editing.enabled()) {
        this.map.removeLayer(layer);
      }
    });
    this.setState({ form: false, delPopup: !delPopup });
  }

  handleRemoveFilter = (val) => {
    const { filterCategories } = this.state;
    const filteredItems = filterCategories.filter(item => item !== val);
    this.setState({ filterCategories: filteredItems }, () => {
      this.updateFilter();
    });
  }

  getSetlectedType=(asset) => {
    const { types }= this.props;
    const typeData=[];
    for (let i=0; i< types.length; i+=1) {
      if (asset.type.category===types[i].category) {
        typeData.push(types[i]);
      }
    }
    this.setState({
      seletedAssetType: [...typeData]
    });
  }

  getFileNames = async (count) => {
    const response = await GetFileName.getUploadFileName(count);
    return response;
  };

  doS3Upload = async (files, fileIds, entity) => {
    const response = await S3Uploads.uploadFiles(files, fileIds.filename, entity);
    return response;
  };

  handleSave = async () => {
    const { info: { id, type, area, ...data }, removedAttachments, customFields, fieldErrors } = this.state;
    const { actionSave, actionEdit, schemas } = this.props;
    this.setState({ loadingStatus: true });
    const cat = type.category.toLowerCase();
    const categoryErrors = fieldErrors[cat] ? fieldErrors[cat] : {};
    const noErrors = Object.keys(categoryErrors)
      .every(k => (fieldErrors[cat][k] === false));
    if (noErrors) {
      data.asset_type = type.id;
      data.response = JSON.stringify(customFields);
      if (Object.keys(data.response ? data.response : {}).length === 0) {
        data.response = Object.keys(categoryErrors).reduce((t, e) => {
          const aux = t; aux[e] = '';
          return aux;
        }, {});
      }

      if (data.area) {
        data.area = data.area.id;
      } else {
        delete data.area;
      }
      data.version_schema = schemas[type.category.toLowerCase()].id;
      data.geometry = JSON.stringify(data.geometry);
      if (removedAttachments.length > 0) {
        data.removedAttachments = removedAttachments;
      }
      // transform data into formData
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        if (k === 'photos') {
          data.photos.forEach((e) => {
            formData.append(k, e);
          });
        } else if (k !== 'attachments') {
          formData.append(k, data[k]);
        }
      });
      const newAttachments = [];
      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((e) => {
          if (!e.id) {
            newAttachments.push(e);
          }
        });
      }
      if (newAttachments.length) {
        let fileIds = '';
        if (newAttachments.length > 0) {
          fileIds = await this.getFileNames(newAttachments.length);
        }
        if (fileIds) {
          await this.doS3Upload(newAttachments, fileIds, 'assets')
            .then(() => {
              fileIds.filename.forEach((fileId) => {
                formData.append('attachments', fileId.toString());
              });
            });
        }
      }
      if (!data.name) {
        this.setState({ shouldShowErrors: true, spaceError: false, loadingStatus: false });
        return;
      }
      if (!data.name.trim()) {
        this.setState({ spaceError: true, loadingStatus: false });
      } else if (id) {
        actionEdit(id, formData);
      } else {
        actionSave(formData);
        this.marker.enable();
        this.layer = undefined;
      }
      utils.disableEditMarkers(this.map);
    } else {
      this.setState({ shouldShowErrors: true, loadingStatus: false });
    }
  }

  handelclickclose=() => {
    this.setState({ filterPopup: false });
  }

  render() {
    const { types, schemas, translations, user, allDataSources } = this.props;
    const { form,
      info,
      shouldShowErrors,
      requiredMap,
      customFields,
      spaceError,
      delPopup,
      seletedAssetType,
      filterPopup: popup,
      loadingStatus,
      filterCategories } = this.state;
    const translationMap = translations ? translations[user.language] : {};
    return (

      <div className={styles.assetMap}>
        <Loading loadingStatus={loadingStatus} />
        <div className={styles.backToolbar}>
          <Link to="/ops/assets/table">
            <FormattedMessage id="assets.table_view" defaultMessage="Table View" />
          </Link>
          <div>
            <Toolbar
              handleDropdown={this.handleFiltersPopup}
              filterCategories={filterCategories}
            />
            <Modal
              width="500px"
              minHeight="150px"
              showIn={delPopup}
              contentStyles={{
                padding: ' 15px 30px 5px 40px'
              }}
              centered
              onClose={this.showDelPopUp}
            >
              <h3 style={{ paddingTop: '20px' }}>
                <FormattedMessage
                  id="assets.removeasset"
                  defaultText="Are you sure you want to delete this asset ?"
                />
              </h3>

              <div className={styles.footer}>
                <Button onClick={this.showDelPopUp}
                  translationID="roles.footer.No"
                  defaultText="No" action="tertiary"
                />
                <Button onClick={this.handleDelete}
                  translationID="roles.footer.Yes"
                  defaultText="Yes"
                  action="danger"
                  style={{ minHeight: '10px' }}
                />
              </div>
            </Modal>
            {popup && (
              <Transition in={popup} timeout={200} unmountOnExit>
                {state => (
                  <FilterForm
                    transition={this.slideStyles[state]}
                    update={this.updateFilter}
                    handleFilterChange={this.handleFilterChange}
                    types={types}
                    filterCategories={filterCategories}
                    closePopUp={this.handelclickclose}
                  />
                )}
              </Transition>
            )}
          </div>
        </div>


        <div className={styles.filter}>
          {filterCategories.map(category => (
            <span className={styles.user} key={category}>
              { types[category]? types[category-1].name:category }
              <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
                onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
              />
            </span>
          ))}
        </div>
        <div id="map" className={styles.map} />
        <TypesToolbar types={types} onAssetClick={this.handleAssetClick}
          onEditClick={this.handleEditClick}
        />

        { form && allDataSources && (
        <Transition in={form} timeout={200} unmountOnExit>
          { state => (
            <AssetForm
              types={seletedAssetType.length>0? seletedAssetType:this.getSetlectedType(info)}
              info={info}
              schemas={schemas}
              translation={translationMap}
              transition={this.slideStyles[state]}
              requiredMap={requiredMap[info.type.category.toLowerCase()]}
              shouldShowErrors={shouldShowErrors}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              onDelete={this.showDelPopUp}
              onInfoChange={this.handleTypeChange}
              onInputChange={this.handleInputChange}
              onAnswerChange={this.handleAnswerChange}
              onFieldErrorChanged={this.handleFieldErrorChanged}
              spaceError={spaceError}
              customFields={customFields}
              allDataSources={allDataSources}
            />
          )}
        </Transition>
        )}
      </div>
    );
  }
}

AssetBuilder.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({}).isRequired,
  surfaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}).isRequired,
  // Redux actions
  actionFetchTypes: PropTypes.func.isRequired,
  actionDelete: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  types: state.assets.types,
  user: state.auth.profile,
  // assets: state.assets.assets,
  surfaces: state.map.surfaces,
  schemas: state.assets.schemas,
  actionLoadTypes: state.assets.actionLoadTypes,
  createAction: state.assets.createAction,
  deleteAction: state.assets.deleteAction,
  translations: state.auth.translations,
  assets: state.assets.typesNames,
  updateAction: state.assets.updateAction,
  assetsTypedataloading: state.assets.actionLoadTypeNames,
  allDataSources: state.inspection.allDataSources
});

const mapDispatchToProps = dispatch => ({
  // fetch assets
  actionFetch: (query) => {
    dispatch(fetchAssets(query));
  },
  // fetch assets
  actionFetchSurfaces: () => {
    dispatch(fetchSurfaces());
  },
  // Save asset
  actionSave: (data) => {
    dispatch(addAsset(data));
  },
  // Edit asset
  actionEdit: (id, data) => {
    dispatch(editAsset(id, data));
  },
  // delete asset
  actionDelete: (id) => {
    dispatch(deleteAsset(id));
  },
  // fetch asset types
  actionFetchTypes: (id) => {
    dispatch(fetchAssetTypes(id));
  },
  actionfetchAssetsNames: (Names, categories) => {
    dispatch(fetchAssetsNames(Names, categories));
  },
  actionFetchSchemas: () => {
    dispatch(fetchAssetsSchema());
  },
  actionClear: () => {
    dispatch(clear());
  },
  fetchDataSources: () => {
    dispatch(fetchDataSourceList());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AssetBuilder));
