import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import { WILD_LIFE_LOG_HOME } from '../../../constants/RouterConstants';
import styles from './WildLifeLog.module.scss';
import Button from '../../../components/button';
import SectionHeader from '../../../components/sectionHeader';
import Separator from '../../../components/separator';
import Search from '../../../components/search/Search';
import icon from '../../../icons/wildlife.png';
import { fetchLogs, clearActionResult } from '../redux/actions';
import LogTable from './components/LogTable';
import { getDatesFromRange } from '../../../utils/helpers';
import Filter from './components/Filter';
import Paginator from '../../../components/paginator/paginator';
import IconButton from '../../../components/iconButton';
import settings from '../../../icons/settings.svg';
import FilterForm from './components/FilterForm';

class WildLifeLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: {},
      popup: false,
      filterCategories: [],
      currentPage: 1,
      query: ''
    };
    this.slideStyles = {
      entered: { transform: 'translate3d(0px,0px)' }
    };
  }

  componentDidMount() {
    const { actionFetch } = this.props;
    const storedRange = localStorage.getItem('wildlifelog_range');
    const filters = localStorage.getItem('wildlifelog_filters');
    let s; let f;
    if (storedRange) {
      [s, f] = getDatesFromRange(storedRange);
      s = s.toISOString();
      f = f.toISOString();
    } else {
      s = localStorage.getItem('wildlifelog_start_date');
      f = localStorage.getItem('wildlifelog_end_date');
      if (s && f) {
        s = new Date(s);
        f = new Date(f);
      } else {
        s = new Date();
        s.setDate(s.getDate() - 30);
        f = new Date();
        localStorage.setItem('wildlifelog_range', 'Last 30 days');
      }
      s.setHours(0, 0, 0, 0);
      f.setHours(23, 59, 59, 99);
      s = s.toISOString();
      f = f.toISOString();
    }
    this.setState({ range: { s, f } });
    if (filters) {
      this.setState({ filterCategories: filters.split(',') });
      actionFetch(1, undefined, undefined, undefined, filters);
    } else {
      actionFetch(1, s, f, filters);
    }
  }

  componentWillUnmount() {
    const { actionClear } = this.props;
    actionClear();
  }

  handleDateChange = (s, f, r) => {
    const { actionFetch } = this.props;
    localStorage.setItem('wildlifelog_start_date', s);
    localStorage.setItem('wildlifelog_end_date', f);
    if (r) {
      localStorage.setItem('wildlifelog_range', r);
    } else {
      localStorage.removeItem('wildlifelog_range');
    }
    let start_date = new Date(s);
    let end_date = new Date(f);
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(23, 59, 59, 999);
    start_date = start_date.toISOString();
    end_date = end_date.toISOString();
    this.setState({ range: { s: start_date, f: end_date } });
    actionFetch(1, start_date, end_date);
  }

  handleFiltersPopup = () => {
    const { popup } = this.state;
    this.setState({ popup: !popup });
  };

  handleFiltersList = (filters) => {
    let filterslist = '';
    filters.forEach((item) => {
      filterslist += `${item},`;
    });
    return filterslist;
  }

  updateFilter = (filterCategories) => {
    const { actionFetch } = this.props;
    this.setState({ filterCategories: [...filterCategories], currentPage: 1 });
    if (filterCategories.length > 0) {
      localStorage.setItem('wildlifelog_filters', filterCategories);
      const filters = this.handleFiltersList(filterCategories);
      actionFetch(1, undefined, undefined, undefined, filters);
    } else {
      localStorage.removeItem('wildlifelog_filters');
    }
    this.setState({ popup: false });
  }

  handleRemoveFilter = (val) => {
    const { filterCategories, range } = this.state;
    const { actionFetch } = this.props;
    const filteredItems = filterCategories.filter(item => item !== val);
    const page = 1;
    this.setState({ currentPage: page });
    if (filteredItems.length > 0) {
      this.setState({ filterCategories: filteredItems }, () => {
        this.updateFilter(filteredItems);
      });
      localStorage.setItem('wildlifelog_filters', filteredItems);
    } else {
      this.setState({ filterCategories: filteredItems });
      localStorage.removeItem('wildlifelog_filters');
      actionFetch(1, range.s, range.f);
    }
  }

  changePage = (e) => {
    const { actionFetch } = this.props;
    const { query, range, filterCategories } = this.state;
    this.setState({ currentPage: e });
    if (query) {
      actionFetch(e, undefined, undefined, query, undefined);
      return;
    }
    if (filterCategories.length > 0) {
      const filters = this.handleFiltersList(filterCategories);
      actionFetch(1, undefined, undefined, undefined, filters);
    } else {
      actionFetch(e, range.s, range.f);
    }
  };

  handleQuery = (searchQuery) => {
    const { actionFetch } = this.props;
    this.setState({ query: searchQuery });
    actionFetch(1, undefined, undefined, searchQuery);
  };


  render() {
    const { history, loglist } = this.props;
    const { range, filterCategories, currentPage, popup } = this.state;
    return (
      <>
        <div className={styles.list}>
          <SectionHeader icon={icon} translationID="shortcuts.wildlife" defaultTitle="Wildlife Log">
            <div className={styles.detailHeader}>
              <Search onSubmit={searchQuery => this.handleQuery(searchQuery)} />
              <IconButton icon={settings} onClick={() => { history.push(`${WILD_LIFE_LOG_HOME}/settings`); }} />
              <Separator />
              <Button translationID="surfaces.create" defaultText="Create"
                onClick={() => { history.push(`${WILD_LIFE_LOG_HOME}/create`); }}
              />
              <Separator />
            </div>
          </SectionHeader>
          {range.s && (
            <Filter dates={[moment(range.s), moment(range.f)]}
              onDateChange={this.handleDateChange}
              listSummary={loglist && loglist.count}
              handleDropdown={this.handleFiltersPopup}
              filterCategories={filterCategories}
              StorageVariable="wildlifelog_range"
              range={localStorage.getItem('wildlifelog_range')}
            />
          )}
          <div className={styles.filter}>
            {filterCategories.map(category => (
              <span className={styles.user} key={category}>
                {category}
                <span onKeyPress={() => this.handleRemoveFilter(category)} tabIndex={0} role="button"
                  onClick={() => this.handleRemoveFilter(category)} className={styles.btnRemove}
                />
              </span>
            ))}
          </div>
          <div className="container">
            {loglist && loglist.results && Object.keys(loglist.results).length > 0
              ? (
                <LogTable data={loglist.results} />
              ) : (
                <span className={styles.spanStyle}>
                  <FormattedMessage id="operation.logs_selected_criteria" defaultMessage="There are no logs with the selected criteria." />
                </span>
              )}
            { loglist && Object.keys(loglist.results).length > 0 && (
              <Paginator totalRecords={loglist && loglist.count} key={range.s} pageLimit={10}
                pageNeighbours={1} onPageChanged={e => this.changePage(e)} page={currentPage}
              />
            )}
          </div>
          {popup && (
          <Transition in={popup} timeout={200} unmountOnExit>
            {state => (
              <FilterForm
                transition={this.slideStyles[state]}
                update={this.updateFilter}
                cancel={this.handleFiltersPopup}
                filterCategories={filterCategories}
              />
            )}
          </Transition>
          )}
        </div>
      </>
    );
  }
}

WildLifeLogList.propTypes = {
  history: PropTypes.shape({}).isRequired,
  actionFetch: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  loglist: state.wildlife_log.loglist
});

const mapDispatchToProps = dispatch => ({
  actionFetch: (page, start, end, query, filters) => {
    dispatch(fetchLogs(page, start, end, query, filters));
  },
  actionClear: () => {
    dispatch(clearActionResult());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WildLifeLogList));
