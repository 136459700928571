import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { uiRouts } from '../../../../constants';

const CreateChannel = lazy(() => import('../CreateChannel'));
const EditChannel = lazy(() => import('../EditChannel'));
const Channels = lazy(() => import('../Channels'));
const DirectMessages = lazy(() => import('../DirectMessages'));

// TODO (refactor) create components in separate files
const IRROPs = () => <h1>IRROPs</h1>;
const Documents = () => <h1>Documents</h1>;

const ChatNavigation: React.FC = () => (
  <Suspense fallback={<div />}>
    <Switch>
      <Route exact path={uiRouts.channels} component={Channels} />
      <Route exact path={uiRouts.directMessages} component={DirectMessages} />
      <Route exact path={uiRouts.irrops} component={IRROPs} />
      <Route exact path={uiRouts.documents} component={Documents} />
      <Route exact path={uiRouts.createNewChannel} component={CreateChannel} />
      <Route exact path={uiRouts.editChannel} component={EditChannel} />

      <Redirect from={uiRouts.messenger} exact to={uiRouts.channels} />
    </Switch>
  </Suspense>
);

export default ChatNavigation;
export { ChatNavigation };
