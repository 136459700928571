import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { airportInformation, fetchAirportInformation, updateAirportInformation } from '../../redux/action';
import styles from './airportinfo.module.scss';
import Modal from '../../../../components/modal';
import CloseIcon from '../../../../icons/close.svg';

import '../../airfields.css';

const AirportInformation = ({ actionAirportInformation, actionFetchAirportInformation, airportInformation, actionUpdate }) => {
  const [airportInfo, setAirportInfo] = useState('');
  const [airportModal, setAirportModal] = useState(false);
  useEffect(() => {
    actionFetchAirportInformation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (airportInformation.length) {
      setAirportInfo(airportInformation[0].airport_information);
    }
  }, [airportInformation]);

  const handleChange = (value) => {
    setAirportInfo(value);
  };

  const onSubmitAirportInfo = () => {
    const formData = new FormData();
    formData.append('airport_information', airportInfo);
    if (airportInformation.length && airportInformation[0].id) {
      actionUpdate(airportInformation[0].id, formData);
    } else {
      actionAirportInformation(formData);
    }
    setAirportModal(false);
  };

  const openAirportModal = () => {
    setAirportModal(true);
  };

  const toggleAirportModal = () => {
    setAirportModal(!airportModal);
  };

  return (
    <Fragment>
      <Col lg={12}>
        <Card className={styles.airportInfoCard}>
          <CardTitle className={styles.airfieldscardtitle}>
            <Col lg={12}>
              <Row>
                <Col>
                  <FormattedMessage
                    id="airfields.airport.information"
                    defaultText="Airport Information"
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <span className="float-right" onClick={openAirportModal}>...</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </CardTitle>
          <CardBody>
            <Col lg={12} className={styles.airportinformationcard}>
              <Fragment>
                <div className={styles.airportinformation}>
                  <div>
                    <Modal
                      showIn={airportModal}
                      onClose={() => toggleAirportModal()}
                      width="45%"
                      height="35%"
                    >
                      <Fragment>

                        <div className={styles.modal}>
                          <div className={styles.modalTitle}>
                            <FormattedMessage
                              id="airfields.airport.information"
                              defaultText="Airport Information"
                            />
                            <div className={styles.closeIcon} onClick={() => toggleAirportModal()}>
                              <img src={CloseIcon} alt="closeIcon" />
                            </div>
                          </div>
                          <hr className={styles.hrLine} />
                          <div className={styles.modalContent}>
                            <ReactQuill
                              value={airportInfo}
                              name="airportInformation"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <hr className={styles.hrLine} />
                        <div className={styles.modalFooter}>
                          <button type="button" className={`float-left mt-3 mb-3 ml-4 ${styles.cancelbutton}`} onClick={() => toggleAirportModal()}>Cancel</button>
                          <button type="button" className={`float-right mt-3 mb-3 mr-4 ${styles.savebutton}`} onClick={() => onSubmitAirportInfo()}>Save</button>
                        </div>
                      </Fragment>
                    </Modal>
                  </div>
                </div>
              </Fragment>
            </Col>
            { airportInfo && (
            <Col lg={12} className={styles.airportInfoCardDetail}>
              <div dangerouslySetInnerHTML={{ __html: airportInfo }} />
            </Col>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

AirportInformation.propTypes = {
  actionAirportInformation: PropTypes.func.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  actionFetchAirportInformation: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
  airportInformation: state.airfieldcondition.airportInfo
});


const mapDispatchToProps = dispatch => ({
  // Post Airport Information
  actionAirportInformation: (data) => {
    dispatch(airportInformation(data));
  },
  // Fetch Airport Information
  actionFetchAirportInformation: () => {
    dispatch(fetchAirportInformation());
  },
  // Update Airport Information
  actionUpdate: (id, data) => {
    dispatch(updateAirportInformation(id, data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AirportInformation));
