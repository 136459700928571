import { ChatTypes } from '../../enums';
import COMPOSE_COMMENT from '../mutations/createComment';
import COMPOSE_POST from '../mutations/createPosts';
import { useMutation } from '../../../graphql/hooks';
import { PostMessageType, CommentMessage } from '../../types';

export const useCreateComment = <T>(type: ChatTypes) => {
  const obj = {
    [ChatTypes.comment]: COMPOSE_COMMENT,
    [ChatTypes.operations]: COMPOSE_POST
  };
  const { error, mutate, saving: savingMessage } = useMutation<
    PostMessageType | CommentMessage,
    T
  >(obj[type]);

  return {
    error,
    createMutate: mutate,
    savingMessage
  };
};
