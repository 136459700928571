import React, { useRef, useState } from "react";
import { useUnmount } from "react-use";
import { Modal } from "semantic-ui-react";
import { Wrapper } from "./UploadFromPcModal.styles";
import { ChatMessageForm, DirectMessageForm } from "../Forms";
import { ChatTypes } from "../../enums";
import { Button } from "../../components/Button";
import { Search } from "../Search";
import { useGetListUsers } from "../../graphql/hooks";
import { FilePreview } from "../../components/FilePreview";
import {
  MAX_FILE_COUNT,
  SUPPORTED_FORMATS,
  validateAttachments
} from "../../validators/messageSchema";
import { toast } from "react-toastify";
import {
  Attachments,
  CreateMessagesTypes,
  CurrentUser,
  ChanelTypes,
  SetOpenModalType,
  SetAttachmentArrFunc,
  OnUserSelectFunc
} from "../../types";

type UploadFromPcModalProps = {
  setOpenModal: SetOpenModalType;
  openModal: boolean;
  createMessages: CreateMessagesTypes;
  currentUser: CurrentUser;
  type: ChanelTypes | ChatTypes.directMessages;
  setAttachmentArr: SetAttachmentArrFunc;
  attachedFiles: File[];
};

const styles: React.CSSProperties = {
  height: "fit-content",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "620px",
  minHeight: "431px",
  width: "100%",
  padding: "15px"
};

const UploadFromPcModal: React.FC<UploadFromPcModalProps> = ({
  openModal,
  setOpenModal,
  type,
  currentUser,
  createMessages,
  setAttachmentArr,
  attachedFiles
}) => {
  const [userIDs, setUserID] = useState([]);
  const [filesToBlob, setFilesToBlob] = useState<Attachments[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { usersData, loading, error } = useGetListUsers();

  useUnmount(() => {
    setAttachmentArr(() => null);
    setFilesToBlob([]);
  });

  if (loading || error) {
    return null;
  }

  const onUserSelect: OnUserSelectFunc = userID => {
    const userIDsArr: string[] = Array.isArray(userID) ? userID : [userID];
    setUserID(prevState => [...new Set([...prevState, ...userIDsArr])]);
  };

  const selectedUsers = usersData.filter(({ userId }) =>
    userIDs.includes(userId)
  );

  const fileUpload = (): void => fileInputRef.current.click();

  const handleFileUpload = ({
    currentTarget: { files }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const currentCount = filesToBlob?.length || 0;
    const currentMax = MAX_FILE_COUNT - (currentCount > 5 && 5) || currentCount;

    const filesArr = Array.from(files).reduce((acc: File[], item): File[] => {
      if (validateAttachments(item)) {
        if (acc.length + currentCount >= currentMax) {
          toast.error("User can upload upto 5 files");
          return acc;
        }

        const reader = new FileReader();

        reader.readAsDataURL(item);
        reader.onload = () => {
          const { type, name, size } = item;
          const url: string = reader.result as string;

          const convertedFile: Attachments = {
            url,
            type,
            size: `${size}`,
            name
          };
          setFilesToBlob(prevState => [...prevState, convertedFile]);
        };

        acc.push(item);
      }
      return acc;
    }, []);
    if (!filesArr.length) {
      return;
    }

    setAttachmentArr(filesArr);
  };

  let FormComponent;
  if (ChatTypes.directMessages === type) {
    FormComponent = DirectMessageForm;
  } else {
    FormComponent = ChatMessageForm;
  }

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)} style={styles}>
      <Wrapper>
        <Modal.Content>
          <div>
            <div className="title">Upload a file</div>
            <FormComponent
              type={type}
              currentUser={currentUser}
              createMessages={createMessages}
              setOpenModal={setOpenModal}
              setListHeight={() => {}}
              uploadFileMode
              attachedFiles={attachedFiles}
            />
          </div>
          <FilePreview files={filesToBlob} setFilesToBlob={setFilesToBlob} />
          <div className="add-file-btn" onClick={fileUpload}>
            Add File
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept={SUPPORTED_FORMATS}
            hidden
            multiple
            onChange={handleFileUpload}
          />
          <div className="share-with">Share with</div>
          <div className="upload-wrapper">
            <Search
              onUserSelect={onUserSelect}
              listUsers={usersData}
              items={selectedUsers}
              width={437}
            />
            <span>
              <Button
                onClick={() => setOpenModal(false)}
                primary
                width={149}
                borderRadius={5}
                disabled
              >
                UPLOAD
              </Button>
            </span>
          </div>
        </Modal.Content>
      </Wrapper>
    </Modal>
  );
};

export default UploadFromPcModal;
export { UploadFromPcModal };
