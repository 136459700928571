import { useCallback } from 'react';
import { ChatTypes } from '../../enums';
import {
  ToggleLikesType,
  UpdateReplies
} from '../../state/channelsState/posts/types';
import { useQuery } from '../../../graphql/hooks';
import { MessageArray } from '../../containers/ChatBox/ChatBox';
import { ChannelMessageVar } from '../../types';
import FETCH_CHANNELS_POSTS from '../queries/channelPosts';
import FETCH_POST_COMMENTS from '../queries/getPostComments';
import { UseChannelPostTypes } from './hooks.types';
import { fields } from './assets';
import { useUpdateMessageMutation } from './useUpdateMessageMutation';
import { useUnLikePost } from './useUnLikePost';
import { useLikePost } from './useLikePost';
import { useDeletePostMutation } from './useDeletePostMutation';
import { useCreateComment } from './useCreateComment';
import { useRDetails } from './useRDetails';

type UseChannelPostActions = {
  // TODO add type definitions to functions
  setListAction?: (postList: any) => any;
  deleteAction?: (postId: any) => any;
  toggleLikesAction?: (toggleLikesData: ToggleLikesType) => any;
  updateAction?: (updatePost: any) => any;
  updateCommentData?: (updatePost: any) => any;
  updateReplies?: (updateReplies: UpdateReplies) => any;
};

export function useChannelPost <C, U, D, L>(
  type: ChatTypes,
  fetchByID: string,
  {
    deleteAction,
    setListAction,
    updateAction,
    toggleLikesAction,
    updateCommentData,
    updateReplies
  }: UseChannelPostActions
): UseChannelPostTypes<C, U, D, L> {
  const { createMutate, savingMessage } = useCreateComment<C>(type);
  const { updatePost: saveUpdated, updateSaving } = useUpdateMessageMutation<U>(type);

  const {
    likePost
    // error: LikePostError,
    // saving
  } = useLikePost<L>();
  const { unLikePost } = useUnLikePost<L>();
  const { deleteItem } = useDeletePostMutation<D>(type);
  const { userId, airportId, token } = useRDetails();

  const deleteMessage = useCallback(async (variables: D) => {
    try {
      const { deleteID, dlt } = fields[type];
      const { data } = await deleteItem(variables);

      deleteAction({ [deleteID]: data[dlt][deleteID] });
      if (type === ChatTypes.comment) {
        updateReplies({ postId: data[dlt].postId, reduce: true });
      }
    } catch (e) {
      console.log('e', e);
    }
  }, [deleteAction, deleteItem, updateReplies, type]);

  const toggleLikes = useCallback(async (args: L, isLiked: boolean) => {
    const mutateFunc = isLiked ? unLikePost : likePost;
    const objStr = isLiked ? 'unLikeChannelPost' : 'likeChannelPost';

    try {
      const { data } = await mutateFunc({
        variables: {
          userId,
          airportId,
          ...args
        }
      });

      toggleLikesAction({
        updatePost: { ...data[objStr], isLiked: !isLiked },
        nextToken: token
      });
    } catch (e) {
      console.log('e', e);
    }
  }, [toggleLikesAction, userId, airportId, likePost, unLikePost, token]);

  const createMessages = useCallback((args: C) => {
    createMutate({
      variables: {
        userId,
        airportId,
        ...args
      }
    });
  }, [createMutate, userId, airportId]);

  const updateMessage = useCallback((args: U, postID: string) => {
    saveUpdated({
      variables: {
        userId,
        airportId,
        ...args
      }
    }).then(({ data }) => {
      updateAction({ [fields[type].update]: data[fields[type].update] });

      if (postID === data[fields[type].update].postId) {
        updateCommentData({ messageData: data[fields[type].update] });
      }
    });
  }, [saveUpdated, updateAction, updateCommentData, userId, airportId, type]);

  const isOperations = type === ChatTypes.operations;

  const {
    // data,
    error,
    loading,
    subscribeToMore,
    fetchMore: getMore
  } = useQuery<MessageArray, ChannelMessageVar>(
    isOperations ? FETCH_CHANNELS_POSTS : FETCH_POST_COMMENTS,
    {
      variables: {
        userId,
        limit: 10,
        nextToken: null,
        [fields[type].id]: fetchByID
      },
      onCompleted: (messageArray: MessageArray) => {
        if (messageArray) {
          const {
            [fields[type].get]: { items, nextToken: nxToken }
          } = messageArray;
          // TODO add types
          setListAction({
            [fields[type].id]: fetchByID,
            [fields[type].set]: {
              items: [...items].reverse(),
              nextToken: nxToken
            }
          });
        }
      }
    }
  );

  const fetchMore = useCallback(
    nextToken => getMore({
      variables: {
        nextToken
      },
      updateQuery: (prev: MessageArray, { fetchMoreResult }) => {
        if (!fetchMoreResult || !prev[fields[type].get].nextToken) return prev;

        return {
          ...prev,
          [fields[type].get]: {
            items: [
              ...prev[fields[type].get].items,
              ...fetchMoreResult[fields[type].get].items
            ],
            nextToken: fetchMoreResult[fields[type].get].nextToken
          }
        };
      }
    }).catch((e) => {
      console.error(e);
    }),
    [getMore, type]
  );

  return {
    error,
    loading,
    subscribeToMore,
    fetchMore,
    toggleLikes,
    createMessages,
    deleteMessage,
    updateMessage,
    savingMessage,
    updateSaving
  };
}
