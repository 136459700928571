import ReactDOM from 'react-dom';
import React from 'react';

import { Provider } from 'react-redux';
import store from './config/store';
import axios from 'axios';
// import cookie from 'react-cookies';

import './styles/index.scss';
import {ThemeProvider} from 'styled-components';
import theme from './theme';

import * as serviceWorker from './serviceWorker';
import ApolloClinet from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloLink } from "apollo-link";
import Routes from './config/routes';
import Auth from './utils/Auth';
import {setTitle, setFavicon} from './brand';
import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import { isLocal, getBackendBaseUrl } from './constants';
import { apiRouts } from './constants';


import { fetchUserProfile, fetchTranslations } from './modules/authentication/redux/actions';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

if(!isLocal) {
  try {
      Sentry.init({
        dsn: "https://b18fcefef8994a9ab28ad54f5efe03f9@sentry.io/4375022",
        integrations: [
          new ApmIntegrations.Tracing(),
        ],
        tracesSampleRate: 0.25,
        environment: process.env.REACT_APP_PREFIX.split('_')[1],
        beforeSend(event, hint) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id, labelSubmit: 'Submit this Report' });
          }
          return event;
        }
      });
    } catch(e) {
    // do nothing
  }
}

const context = {};
window['reactContext'] = context;
context['isLocal'] = isLocal;
context['apiRoutes'] = apiRouts;

setTitle();
setFavicon();

// axios.defaults.baseURL = 'https://backend.' + window.location.hostname + '/api';
axios.defaults.baseURL = getBackendBaseUrl();

const chatUrl = isLocal ? process.env.REACT_APP_GRQPHQL_HOST
  : 'https://graphql.chat.' + window.location.hostname + '/graphql';

store.dispatch(fetchUserProfile());
store.dispatch(fetchTranslations());

const httpLink = createHttpLink({
  uri: chatUrl
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = Auth.getInstance().getAuthToken();
  operation.setContext({
      headers: {
          Authorization: token ? `${token}` : ''
          // 'X-Api-Key': process.env.REACT_APP_GRQPHQL_API_KEY
      }
  });
  return forward(operation);
});

// use with apollo-client
const link = middlewareLink.concat(httpLink);

const client = new ApolloClinet({
  link: link,
  cache: new InMemoryCache()
});

class ErrorHandler extends React.Component {
  componentDidCatch(error, info) {
    // send email or send xray, etc
    // console.log('Caught error - ' + error + ' - ' + info);
  }

  render() {
    return this.props.children
  }
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorHandler>
          <Routes />
          <ToastContainer autoClose={2000} draggablePercent={60} />
        </ErrorHandler>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// build trigger 1
