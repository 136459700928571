/* eslint-disable array-callback-return */
import { ResponsiveLine } from '@nivo/line';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogs } from '../../operations-logs/redux/actions';
import Loading from '../../../components/loading';
import styles from './dashboard.module.scss';

export default function OpsLogTrends() {
  const logList = useSelector(state => state.opslogs.loglist);
  const apiStatus = useSelector(state => state.opslogs.actionFetch);

  const [selected, setSelected]= useState([]);
  const [calls, setcalls] = useState(false);

  const apidispatch = useDispatch();


  useEffect(() => {
    const call = async () => {
      const e=moment().utc().format();
      const s=moment().subtract(30, 'days').utc().format();
      await apidispatch(fetchLogs(undefined, s, e, undefined, undefined, undefined, true));
      setcalls(true);
    };
    if (logList===undefined && calls===false) call();
  }, [logList, apidispatch, calls]);

  const get_y=(m, shdata, s) => {
    let len=0;
    shdata[s].map((l) => {
      if (moment.utc(l.report_date).format('DD-MM-YYYY')===m) {
        len+=1;
      }
    });
    return len;
  };

  const get_date=(shdata, s) => {
    const returnData=[];
    for (let ind=0; ind<=30; ind+=1) {
      returnData.push({
        x: moment().subtract(30-ind, 'days').utc().format('MM/DD'),
        y: get_y(moment().subtract(30-ind, 'days').utc().format('DD-MM-YYYY'), shdata, s)
      });
    }
    return returnData;
  };

  const getData= () => {
    const returnData=[];
    const data1=[];
    const shdata={};
    if (logList!==undefined && logList.length>0) {
      logList.map((acc) => {
        const key = acc.shift_name;
        if (!shdata[key])shdata[key]=[];
        shdata[key].push(acc);
      });
      if (Object.keys(shdata).length>0) {
        Object.keys(shdata).map((s) => {
          data1.push(
            {
              id: s,
              color: (s==='Day') ? 'hsl(60, 94%, 72%)': (s==='Afternoon') ?'hsl(196, 41%, 49%)' : 'hsl(360, 100%, 45%)',
              data: get_date(shdata, s)
            }
          );
        });
      }
      if (selected.length>0) {
        for (let i=0; i<selected.length; i+=1) {
          if (returnData.length<=0) {
            returnData.push(data1.filter(rec => (
              rec.id !== selected[i]
            )));
          } else {
            returnData[0]=returnData[0].filter(rec => (
              rec.id !== selected[i]
            ));
          }
          returnData[0].push({
            id: selected[i],
            color: (selected[i]==='Day') ? 'hsl(60, 94%, 72%)': (selected[i]==='Afternoon') ?'hsl(196, 41%, 49%)' : 'hsl(360, 100%, 45%)',
            data: []

          });
        }
        return returnData[0];
      }
    }
    return data1;
  };

  return (
    <div style={{ width: '100%' }}>
      {/* <> */}
      <Loading loadingStatus={apiStatus.loading} />
      <ResponsiveLine
        data={logList? getData(): []}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        tooltip={
          v => (
            <div className={styles.tooltip}>
              <p>
                  Date:
                {v.point.data.x}
                {' '}
                  ; Entries:
                {v.point.data.y}
                ; Shifts:
                {v.point.serieId}
              </p>
            </div>
          )
        }
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Days',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: ' No of Entries',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        colors={c => c.color}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            onClick: async (d) => {
              if (selected.indexOf(d.id)<0) {
                await setSelected([...selected, d.id]);
              } else {
                const filteredAry = selected.filter(e => e !== d.id);
                await setSelected([...filteredAry]);
              }
            },
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
      {/* </> */}
    </div>
  );
}
