import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button } from '../../../components/Button';
import { Field as FromField } from '../../../../../components/forms/Field';

import {
  createChannelSchema,
  validateUserIDsSet
} from '../../../validators/createChannelSchema';
import { Input, Select, TextArea } from '../../../../../components/forms';
import { FormFooter } from '../EditChannel.styles';
import { Search } from '../../Search';
import { UserList } from '../../../components/UserList';
import {
  EditChannelFormValues,
  UserType,
  ChannelDetails,
  OnUserRemoveFunc,
  OnUserSelectFunc
} from '../../../types';

const countryOptions = [
  { key: 'prv', value: 'private', text: 'Private' },
  { key: 'pbl', value: 'public', text: 'Public' }
];

export interface EditChannelFormProps {
  onUserSelect: OnUserSelectFunc;
  onRemove: OnUserRemoveFunc;
  updateChannel: (values: object) => void;
  usersData: UserType[] | [];
  userIDs: string[];
  channelDetails: ChannelDetails;
}

const form = {
  reset: null,
  setErrors: null
};

const EditChannelForm: React.FC<EditChannelFormProps> = ({
  updateChannel,
  userIDs,
  usersData,
  onUserSelect,
  onRemove,
  channelDetails: { name, channelId, type, content }
}) => {
  const initialValues: EditChannelFormValues = {
    channelId,
    name,
    privacy: type === 'true' ? 'public' : 'private',
    content,
    invitedPeople: null
  };

  const handleSubmit = ({ name, privacy, content }, actions): void => {
    const isInvited = validateUserIDsSet(userIDs);

    if (isInvited) {
      form.setErrors({
        invitedPeople: isInvited
      });
      actions.setSubmitting(false);
      return;
    }

    updateChannel({
      channelId,
      name,
      content,
      users: userIDs.filter((item, pos, self) => self.indexOf(item) === pos),
      type: privacy === 'private'
    });
    actions.setSubmitting(false);
  };

  const selectedUsers = usersData.filter(({ userId }) => userIDs.includes(userId));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createChannelSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting, resetForm, setErrors, errors }) => {
        form.reset = resetForm;
        form.setErrors = setErrors;

        return (
          <div>
            <Form>
              <div className="form-wrapper">
                <div className="first-row">
                  <Field
                    key="name"
                    name="name"
                    render={formProps => (
                      <Input
                        placeholder="Please Enter the Name of Channel"
                        type="text"
                        labelText="Name of Channel"
                        disabled={isSubmitting}
                        value={name}
                        {...formProps}
                      />
                    )}
                  />
                  <Field
                    key="privacy"
                    name="privacy"
                    render={formProps => (
                      <Select
                        placeholder="Please select Privacy"
                        labelText="Privacy"
                        disabled={isSubmitting}
                        options={countryOptions}
                        value={type}
                        {...formProps}
                      />
                    )}
                  />
                </div>
                <hr />
                <div className="second-row">
                  <Field
                    key="content"
                    name="content"
                    render={formProps => (
                      <div className="text-area">
                        <TextArea
                          minHeight={110}
                          placeholder=""
                          disabled={isSubmitting}
                          labelText="Details"
                          value={content}
                          {...formProps}
                        />
                      </div>
                    )}
                  />
                </div>

                <hr />
                <div className="third-row">
                  <FromField
                    labelText="Invite people"
                    error={errors.invitedPeople}
                  >
                    <Search
                      onUserSelect={onUserSelect}
                      listUsers={usersData}
                      items={selectedUsers}
                    />
                  </FromField>
                  <FromField labelText="Invited people">
                    {usersData && (
                      <UserList items={selectedUsers} onRemove={onRemove} />
                    )}
                  </FromField>
                </div>
              </div>
              <hr />

              <FormFooter>
                <Button type="submit" primary>
                  Update
                </Button>
              </FormFooter>
            </Form>
          </div>
        );
      }}
    />
  );
};
export default EditChannelForm;
