import gql from 'graphql-tag';

const UPDATE_CHANNEL = gql`
  mutation updateChannel(
    $channelId: String!
    $content: String!
    $userId: String!
    $name: String!
    $type: Boolean!
    $users: [String]
  ) {
    updateChannel(
      input: {
        channelId: $channelId
        content: $content
        createdBy: $userId
        name: $name
        type: $type
        users: $users
      }
    ) {
      airportId
      channelId
      content
      createdAt
      createdBy
      name
      type
      updatedAt
    }
  }
`;

export default UPDATE_CHANNEL;
