import {
  FETCH_AIRPORT_INFORMATION,
  ADD_AIRPORT_INFORMATION,
  FETCH_COMMENTS,
  ADD_COMMENTS,
  FETCH_RUNWAY_CONDITIONS,
  FETCH_AIRPORT_RUNWAYS,
  UPDATE_RUNWAY_CONDITIONS,
  CLEAR_AIRFIELD_ACTIONS,
  UPDATE_AIRPORT_INFORMATION,
  FETCH_USEFUL_LINKS,
  CREATE_USEFUL_LINKS,
  UPDATE_USEFUL_LINKS,
  CLEAR_AIRFIELD_USEFULLINKS_ACTIONS,
  DELETE_USEFUL_LINK,
  DELETE_COMMENT
} from './types';

const BACKEND_API = 'BACKEND_API';

export const airportInformation = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/airfield_conditions/',
    ...ADD_AIRPORT_INFORMATION
  }
});

export const fetchAirportInformation = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/airfield_conditions/',
    ...FETCH_AIRPORT_INFORMATION
  }
});

export const updateAirportInformation = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'PATCH',
    data,
    url: `/airfield_conditions/${id}/`,
    ...UPDATE_AIRPORT_INFORMATION
  }
});

// Action to Fetch Comments
export const fetchComments = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/comments/',
    ...FETCH_COMMENTS
  }
});

// Action to Add Comment
export const addComment = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/comments/',
    ...ADD_COMMENTS
  }
});

// Action to get Runway Conditions
export const fetchAirportRunways = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/runway_conditions/creating_runway_surfaces/',
    ...FETCH_AIRPORT_RUNWAYS
  }
});

// Action to get Runway Conditions
export const fetchRunwayConditions = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/runway_conditions/',
    ...FETCH_RUNWAY_CONDITIONS
  }
});

// Action to update Runway conditions
export const updateRunwayConditions = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'PATCH',
    data,
    url: `/runway_conditions/${id}/`,
    ...UPDATE_RUNWAY_CONDITIONS
  }
});

// Action to get usefulLinks
export const fetchUsefulLinks = () => ({
  type: BACKEND_API,
  payload: {
    method: 'GET',
    url: '/useful_links/',
    ...FETCH_USEFUL_LINKS
  }
});

// Action to Add usefulLink
export const addUsefulLink = data => ({
  type: BACKEND_API,
  payload: {
    method: 'POST',
    data,
    url: '/useful_links/',
    ...CREATE_USEFUL_LINKS
  }
});


// Action to update usefulLink
export const updateUsefulLink = (id, data) => ({
  type: BACKEND_API,
  payload: {
    method: 'PATCH',
    data,
    url: `/useful_links/${id}/`,
    ...UPDATE_USEFUL_LINKS
  }
});

// Delete Useful link
export const deleteUsefulLink = id => ({
  type: BACKEND_API,
  payload: {
    method: 'DELETE',
    url: `/useful_links/${id}/`,
    ...DELETE_USEFUL_LINK
  }
});

// Delete Comment
export const deleteComment = id => ({
  type: BACKEND_API,
  payload: {
    method: 'DELETE',
    url: `/comments/${id}/`,
    ...DELETE_COMMENT
  }
});

export const clear = () => ({
  type: CLEAR_AIRFIELD_ACTIONS
});

// Action to clear loading status of useful Links
export const clearUsefulLinksAction = () => ({
  type: CLEAR_AIRFIELD_USEFULLINKS_ACTIONS
});
