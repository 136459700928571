import { toast } from 'react-toastify';
import axios from 'axios';
import { apiRouts } from '../../../constants';
import { Attachments } from '../types';

// const paths = {
//   channel: 'media/chat/channels/',
//   messages: 'media/chat/messages/',
//   document: 'media/chat/documents/'
// };

class UploadFile {
  uploadFiles = async (formData, path) => {
    try {
      const { data } = await axios.post<Attachments[]>(
        apiRouts.uploadFile + path,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      return data;
    } catch (err) {
      toast.error(err?.response?.data.message || err.message);
      return null;
    }
  };
}

const Service = new UploadFile();

export default Service;
export { Service as UploadFile };
