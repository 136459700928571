import React, { useState } from 'react';
import { DirectChatBoxProps } from '../../types';
import { Wrapper } from './ChatBox.styles';
import { MessagesList } from '../MessagesList';
import { DirectMessageForm } from '../Forms';

const DirectChatBox = React.memo<DirectChatBoxProps>(
  ({
    type,
    createMsg,
    topicsConnected,
    toUser,
    messagesList,
    currentUser,
    channelId,
    loadMore,
    deleteMessage,
    hasMore,
    editedMessageId,
    updateMessage,
    editedMessage
  }) => {
    const [listHeight, setListHeight] = useState<number | null>(null);

    return (
      <Wrapper>
        <MessagesList
          isEditedMessage={!!editedMessage}
          listHeight={listHeight}
          messagesList={messagesList ?? []}
          // messagesList={data.items}
          type={type}
          hasMore={hasMore}
          loadMore={loadMore}
          newPost={null}
          deleteMessage={deleteMessage}
          // toggleLikes={toggleLikes}
          channelId={channelId}
          currentUser={currentUser}
          editedMessageId={editedMessageId}
        />
        <DirectMessageForm
          type={type}
          setListHeight={setListHeight}
          createMessages={createMsg}
          toUser={toUser}
          editedMessage={editedMessage}
          currentUser={currentUser}
          topicsConnected={!topicsConnected}
          updateMessages={updateMessage}
        />
      </Wrapper>
    );
  }
);

export default DirectChatBox;
export { DirectChatBox };
