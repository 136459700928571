import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Permissions from 'react-redux-permissions';
import { changeCurrentPage } from '../general_redux/actions';
import Forbidden from './Forbidden';
import Shortcuts from '../components/topbar/shortcuts/shortcuts';
import MaintenanceLayout from './lease-management/Maintenance/MaintenanceLayout';
import PropertiesLayout from './lease-management/properties/PropertiesLayout';
import TenantsLayout from './lease-management/tenants/TenantsLayout';
import Settings from './lease-management/tenants/Builder/TenantsBuilder';

class LeaseLayout extends Component {
  links = [
    { url: '/lease/properties', name: this.props.intl.formatMessage({ id: 'shortcuts.lease.properties' }), key: 'Properties', Permissions: ['view_leaseproperties'] },
    { url: '/lease/tenants', name: this.props.intl.formatMessage({ id: 'shortcuts.lease.tenants' }), key: 'Tenants', Permissions: ['view_tenants'] },
    { url: '/lease/maintenance', name: this.props.intl.formatMessage({ id: 'shortcuts.lease.maintenance' }), key: 'Maintenance', Permissions: ['view_leaseworkorder'] },
    { url: '/lease/settings', name: this.props.intl.formatMessage({ id: 'shortcuts.settings' }), key: 'Settings', Permissions: ['can_modify_airport_settings'] }
  ];


  componentDidMount() {
    const { actionUpdateCurrentPage } = this.props;
    actionUpdateCurrentPage('Lease Management');
  }

  render() {
    const { user, match } = this.props;
    return (
  <>
    {user && user.airport_permissions && user.airport_permissions.modules.filter(type => type.name === 'Lease Management').length > 0
    && user.airport_permissions.modules.filter(type => type.name === 'Lease Management')[0].enabled === 'true' && (
    <>
      <Shortcuts links={this.links} />
      <Switch>
        <Route path={`${match.url}/properties/`} component={PropertiesLayout} />
        <Route path={`${match.url}/maintenance/`} component={MaintenanceLayout} />
        <Route path={`${match.url}/tenants`} component={TenantsLayout} />
        <Permissions allowed={['can_modify_airport_settings']} fallbackElement={<Forbidden />}>
          <Route exact path={`${match.url}/settings`} component={Settings} />
        </Permissions>
      </Switch>
    </>
    )}
  </>
    );
  }
}

LeaseLayout.propTypes = {
  match: PropTypes.shape({}).isRequired,
  actionUpdateCurrentPage: PropTypes.func
};

const mapStateToProps = state => ({
  currentModule: state.general.currentModule,
  user: state.auth.profile
});

const mapDispatchToProps = dispatch => ({
  actionUpdateCurrentPage: (page) => {
    dispatch(changeCurrentPage(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LeaseLayout));
